import { Alert, Anchor, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { UrlFactory } from "../../routing/UrlFactory";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

export const LinkToOldAccountOwnerBanner = () => {
    const { t } = useTranslate();

    return (
        <Alert p={0}>
            <Text align="center" size="sm">
                {t("ACCOUNT_OWNER_HOME_BANNER_TEXT")}&nbsp;
                <Anchor
                    onClick={() => {
                        trackPosthogEvent("ui", "ao_layout", "linktooldao");
                    }}
                    href={UrlFactory.accountOwner.home.create({})}
                >
                    {t("ACCOUNT_OWNER_HOME_BANNER_HERE")}
                </Anchor>
            </Text>
        </Alert>
    );
};
