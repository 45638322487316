import React from "react";
import { Link } from "react-scroll";

interface SlideshowBlockProps extends React.HTMLAttributes<HTMLDivElement> {
    elementId?: string;
    previousId?: string;
    nextId?: string;
    showScrollToTop?: boolean;
}

const SlideshowBlock = ({
    elementId,
    previousId,
    nextId,
    showScrollToTop,
    className,
    children,
    ...rest
}: SlideshowBlockProps): JSX.Element => {
    const isAOHeaderExist = document.getElementsByClassName("context-nav");

    const handleScrollToTop = () => {
        const elm = document.getElementsByClassName("fullscreen")[0];
        elm.scrollIntoView({
            behavior: "smooth",
        });
        if (document.getElementsByClassName("fullscreen-enabled")?.item(0) !== null) {
            elm.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    return (
        <div {...rest} className={`alt-block full-height d-flex flex-column ${className}`} id={elementId}>
            <div className="flex-fill">
                <>
                    {showScrollToTop && showScrollToTop === true && (
                        <button className="btn btn-link float-right" onClick={() => handleScrollToTop()}>
                            Scroll to top
                        </button>
                    )}
                    {children}
                </>
            </div>
            <div className="block-nav d-inline-flex flex-column w-100">
                {previousId && (
                    <div className="align-self-center mb-1">
                        <Link
                            to={previousId}
                            className="btn btn-outline-primary"
                            spy={false}
                            smooth={true}
                            duration={300}
                            offset={isAOHeaderExist && isAOHeaderExist.item(0) === null ? -165 : -200}
                        >
                            <i className="fa fa-angle-double-up"></i>
                        </Link>
                    </div>
                )}
                {nextId && (
                    <div className="align-self-center mt-1">
                        <Link
                            to={nextId}
                            className="btn btn-outline-primary"
                            spy={false}
                            smooth={true}
                            duration={300}
                            offset={isAOHeaderExist && isAOHeaderExist.item(0) === null ? -165 : -200}
                        >
                            <i className="fa fa-angle-double-down"></i>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SlideshowBlock;
