import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { useCurrentClient, useCurrentUser } from "@/common";
import { useBoardContext } from "../../BoardContext";
import { TaskCategoryModal } from "../TaskCategoriesEditor/TaskCategoryModal";
import "./styles.scss";
import { ActionBar, DisplayModes, DisplayModeTranslations } from "../ActionBar/ActionBar";
import { Inbox } from "./Inbox";
import { BoardLanes } from "./BoardLanes";
import { ColumnHeader } from "./ColumnHeader";
import { createTaskCategory } from "../TaskCategoriesEditor/TaskCategoryEditor";
import { onDragAndDropEnd } from "../../dragAndDrop";
import { useZoom } from "./useZoom";
import { ImprovementBoardTask, TaskCategory } from "@/api-client";
import { FullscreenProps } from "../../useFullscreen";
import { useDeleteConfirmationModal } from "@/components";
import { trackPosthogEvent } from "../../../../analytics/customEvents/trackPosthogEvent";

type CategoryEditorData = {
    show: boolean;
    category: TaskCategory;
};

export const Board = ({ fullscreen }: { fullscreen: FullscreenProps }) => {
    const currentUser = useCurrentUser();
    const { t } = useTranslate();
    const client = useCurrentClient();
    const boardContext = useBoardContext();
    const boardContextState = boardContext.state;
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const tasksByStatus = boardContextState.filteredTasksGrouped.all;

    const defaultTaskCategory = useMemo(
        () =>
            createTaskCategory(
                undefined,
                boardContextState.group?.id,
                client?.id,
                boardContextState.categoryFilters.items.map(f => f.item)
            ),
        [boardContextState.group?.id, boardContextState.categoryFilters.items, client?.id]
    );

    const defaultEditorData = { show: false, category: defaultTaskCategory };
    const [showCategoryEditor, setShowCategoryEditor] = useState<CategoryEditorData>(defaultEditorData);

    const [displayMode, setDisplayMode] = useState<DisplayModes>("User");

    const zoom = useZoom();

    const defaultNewTask: ImprovementBoardTask = {
        // @ts-ignore
        clientId: currentUser?.clientId,
        createdByUserId: currentUser?.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        // @ts-ignore
        groupId: boardContextState?.group?.id,
        createdInGroupId: boardContextState?.group?.id,
        sortIndex: boardContextState?.tasks?.length,
        status: "New",
        title: "",
        priority: "Normal",
    };

    const openDeleteModal = (category: TaskCategory) =>
        deleteConfirmationModal.open({
            title: t("BOARD_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CATEGORY"),
            onConfirm: () => boardContext.deleteCategory(category),
        });

    const onDragEnd = async (result: DropResult) => {
        const dragResult = onDragAndDropEnd(result, boardContextState.tasks, tasksByStatus, displayMode);
        if (dragResult === undefined) {
            return;
        }

        await boardContext.handleDragEnd(dragResult);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="board-wrapper">
                <ActionBar
                    displayMode={{
                        currentMode: displayMode,
                        onModeChange: mode => {
                            trackPosthogEvent("ui", "improvementboard", "changedisplaymode", {
                                from: displayMode,
                                to: mode,
                            });
                            setDisplayMode(mode);
                        },
                    }}
                    zoom={{
                        onZoomReset: () => zoom.reset(),
                        onZoomIn: () => zoom.zoomIn(),
                        onZoomOut: () => zoom.zoomOut(),
                    }}
                    fullscreen={fullscreen}
                    categories={{
                        defaultItem: {
                            name: t("WORKSHOP_UNCATEGORIZED_HEADER"),
                            color: "#C0C1C3",
                            isSelected: boardContextState.categoryFilters.nullItemsEnabled,
                            onChange: () => boardContext.toggleUncategorized(),
                        },
                        // @ts-ignore
                        items:
                            boardContextState.categoryFilters.items
                                ?.map(x => ({
                                    // @ts-ignore
                                    id: x.item.id.toString(),
                                    name: x.item.name,
                                    color: x.item.color,
                                    isSelected: x.enabled,
                                    onChange: () => boardContext.toggleCategory(x),
                                    onDelete: () => openDeleteModal(x.item),
                                    onEdit: () => setShowCategoryEditor({ show: true, category: x.item }),
                                }))
                                // @ts-ignore
                                .alphabeticSort(x => x.name) ?? [],
                        onAddCategory: () => setShowCategoryEditor({ show: true, category: defaultTaskCategory }),
                    }}
                />

                <TaskCategoryModal
                    mode={showCategoryEditor.category.id === undefined ? "create" : "edit"}
                    show={showCategoryEditor.show}
                    category={showCategoryEditor.category}
                    handleClose={() => setShowCategoryEditor(defaultEditorData)}
                />

                <div style={{ zoom: `${zoom.zoom}%` }}>
                    <div className="board-headers">
                        <ColumnHeader label={t("BOARDS_INBOX")} column={1} value={tasksByStatus.New.length} />
                        <ColumnHeader label={t(DisplayModeTranslations[displayMode])} column={2} />
                        <ColumnHeader label={t("BOARDS_TODO")} column={3} value={tasksByStatus.Todo.length} />
                        <ColumnHeader label={t("BOARDS_IN_PROGRESS")} column={4} value={tasksByStatus.Active.length} />
                        <ColumnHeader label={t("BOARDS_DONE")} column={5} value={tasksByStatus.Completed.length} />
                        <ColumnHeader label={t("BOARDS_COMPLETED")} column={6} value={tasksByStatus.Archived.length} />
                    </div>
                    <div className="board">
                        <div
                            className="grid-col-1 grid-row-1 inbox p-1"
                            style={{
                                gridRowEnd: `span ${boardContextState.filteredTasksGrouped[displayMode].swimLaneCount}`,
                            }}
                        >
                            <Inbox
                                tasks={boardContextState.filteredTasksGrouped.all.New}
                                defaultNewTask={defaultNewTask}
                            />
                        </div>

                        <BoardLanes
                            displayMode={displayMode}
                            swimLane={boardContextState.filteredTasksGrouped[displayMode]}
                            defaultNewTask={defaultNewTask}
                        />
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
};
