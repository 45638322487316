﻿import { useTranslate } from "@tolgee/react";
import React, { useState } from "react";
import ReactMdeEditor from "../../components/ReactMdeEditor";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { Flex } from "@mantine/core";

interface TeamMessageEditorProps {
    currentMessage: string | null;
    teamId: number;
    clientId: number;
    onSave: (newMessage: string | null) => void;
}

export const TeamMessageEditor = (props: TeamMessageEditorProps) => {
    const { t } = useTranslate();
    const [text, setText] = useState<string | null>(props.currentMessage);

    const save = () => {
        props.onSave(text);
    };

    return (
        <Flex direction={"column"} justify={"space-between"} rowGap={"md"} w={"100%"} h={"100%"}>
            <ReactMdeEditor onChange={setText} initialValue={text === null ? "" : text} />
            <Flex justify={"flex-end"}>
                <PrimaryButton onClick={save} eventName={{ object: "teammessage", action: "save" }}>
                    {t("COMMON_SAVE")}
                </PrimaryButton>
            </Flex>
        </Flex>
    );
};
