import { IReport } from "../IReport";
import { exportBlob } from "../fileUtils";
import { formatYYYYMMDD } from "../../../../common/date";
import { ReportClient } from "@/api-client";

export class UserReport implements IReport {
    public name;
    public description;

    constructor(private reportClient: ReportClient) {
        this.name = "User Report";
        this.description = "Statistics on users and course assignments for billing";
    }

    public downloadReport = () => {
        return this.reportClient.getUsersReport().then(result => {
            const date = formatYYYYMMDD();
            exportBlob(result, `UserReport_${date}.xlsx`);
        });
    };
}
