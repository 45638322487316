import { Chapter } from "@/api-client";
import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { createApi } from "../../../common/api";
import { showToast } from "../../../components/Notify";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { CopyToDialog, CopyToDialogProps, LevelEnum } from "../CopyToDialog";
import { CopyAction } from "../CopyAction";
import { EditAction } from "../EditAction";

export const ItemType = {
    CARD: "chapter",
};

export const RowCard = (props: {
    chapter: Chapter;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    courseId: number;
    id: number;
}) => {
    const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false);

    const ref = useRef(null);

    // @ts-ignore
    const [{ handlerId }, drop] = useDrop({
        accept: ItemType.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        // @ts-ignore

        hover(item: { id: number; index: number }, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // @ts-ignore

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            // @ts-ignore

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemType.CARD,
        item: () => {
            return {
                id: props.id,
                index: props.index,
            };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const containerClass = isDragging ? "dragging-container" : "listing-container";
    drag(drop(ref));

    const api = createApi();
    const sysadminContextDispatch = useSysadminContextDispatch();

    const copyToProps: CopyToDialogProps = {
        initialSelection: {
            courseId: props.courseId,
        },
        level: LevelEnum.Course,
        onSelected: data => {
            // Clone chapter and set Id to zero so we create new instead of updating existing
            // @ts-ignore

            const chapterClone: Chapter = { ...props.chapter, id: 0, courseId: data.course.id };

            // Update local state
            api.chapters.post(chapterClone).then(
                x => {
                    sysadminContextDispatch(s => ({
                        ...s,
                        courses: [
                            // @ts-ignore

                            ...s.courses.map(c => (c.id === x.courseId ? { ...c, chapters: [...c.chapters, x] } : c)),
                        ],
                    }));

                    showToast(
                        `Chapter successfully copied to ${
                            data.course.adminName && data.course.adminName.length > 0
                                ? data.course.adminName
                                : data.course.name
                        }`,
                        "success"
                    );
                },
                error => {
                    console.log(error);
                }
            );

            // Hide modal
            setShowCopyDialog(false);
        },
        show: showCopyDialog,
        onClose: () => setShowCopyDialog(false),
    };

    return (
        <>
            <tr ref={ref} className={containerClass} data-handler-id={handlerId}>
                <td>{props.index + 1}.</td>
                <td>{props.chapter?.name}</td>
                <td>{props.chapter?.modules?.length}</td>
                <td>{props.chapter?.status}</td>
                <td className="col-1">
                    <EditAction link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapter?.id}/edit`} />
                </td>
                <td className="whitespace-nowrap col-1">
                    <CopyAction onClick={() => setShowCopyDialog(true)} />
                </td>
            </tr>
            {showCopyDialog && <CopyToDialog {...copyToProps} />}
        </>
    );
};
