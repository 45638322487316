import React from "react";
import { createStyles } from "@mantine/core";
import { WithChildren } from "../../common/StateWrapper";

const useStyles = createStyles(() => ({ buttonContainer: { display: "flex", justifyContent: "flex-end" } }));

export const ContentBlockButtonContainer = (props: WithChildren): JSX.Element => {
    const { classes } = useStyles();
    return <div className={classes.buttonContainer}>{props.children}</div>;
};
