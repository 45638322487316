import { ActionIcon } from "@mantine/core";
import React, { MouseEventHandler } from "react";

export const CopyAction = (props: { onClick: MouseEventHandler<HTMLButtonElement> }) => (
    <ActionIcon onClick={props.onClick}>
        <div>
            <i className="fa fa-clone" aria-hidden="true" />
        </div>
    </ActionIcon>
);
