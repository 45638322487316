interface Mark {
    label: number;
    value: number;
}

export const Marks = (minVal: number, maxVal: number): Mark[] => {
    const marks = [];
    for (let i = minVal; i <= maxVal; i++) {
        marks.push({
            label: i,
            value: i,
        });
    }
    return marks;
};
