﻿import { Burger, createStyles, Flex, Header, Paper, Transition } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCurrentUser } from "@/common";
import { ProfileHeaderItem } from "./ProfileHeaderItem";

const useStyles = createStyles((theme, props: { headerHeight: number }) => {
    const breakPoint = theme.breakpoints.md;

    return {
        header: {
            position: "sticky",
        },
        burgerContent: {
            flexGrow: 1,
            [theme.fn.largerThan(breakPoint)]: {
                display: "none",
            },
        },
        burger: {
            "&:hover": {
                backgroundColor: theme.colors.gray[0],
            },
        },
        links: {
            flexGrow: 1,
            [theme.fn.smallerThan(breakPoint)]: {
                display: "none",
            },
        },
        dropdown: {
            position: "absolute",
            top: props.headerHeight,
            left: 0,
            right: 0,
            zIndex: 0,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopWidth: 0,
            overflow: "hidden",

            [theme.fn.largerThan(breakPoint)]: {
                display: "none",
            },
        },
    };
});

type HeaderComponentProps = {
    height: number;
    left?: React.ReactNode;
    right?: React.ReactNode;
};

export const HeaderComponent = (props: HeaderComponentProps) => {
    const currentUser = useCurrentUser();
    const { classes } = useStyles({ headerHeight: props.height });
    const [opened, { toggle }] = useDisclosure(false);

    return (
        <Header height={props.height} className={classes.header}>
            <Flex h="100%">
                <Flex className={classes.links} justify="space-between">
                    <Flex>{props.left}</Flex>
                    <Flex gap="xs">{props.right}</Flex>
                </Flex>
                <Flex className={classes.burgerContent} align="center" justify="flex-end">
                    <Burger opened={opened} onClick={toggle} className={classes.burger} h="100%" />

                    <Transition transition="pop-top-right" duration={200} mounted={opened}>
                        {styles => (
                            <Paper className={classes.dropdown} withBorder style={styles}>
                                {props.left}
                                {props.right}
                            </Paper>
                        )}
                    </Transition>
                </Flex>
                <Flex>
                    <ProfileHeaderItem user={currentUser} />
                </Flex>
            </Flex>
        </Header>
    );
};
