import { Container, Flex, SimpleGrid, Stack } from "@mantine/core";
import * as yup from "yup";
import { useTranslate } from "@tolgee/react";
import { createApi } from "@/common";
import { Form, FormInput, ModalConfig, ModalProps } from "@/components";
import { roleCheckBoxItems } from "./roleCheckBoxItems";
import { adminFormValidator } from "./adminFormValidator";

type AddAdminForm = yup.InferType<typeof adminFormValidator>;

const initialValues: AddAdminForm = {
    roles: ["Admin"],
    email: "",
    firstName: "",
    lastName: "",
};

type AddAdminProps = ModalProps<{ onSaved: (userId: number) => void }>;

const AddAdmin = (props: AddAdminProps) => {
    const { t } = useTranslate();

    const submitAdmin = (values: AddAdminForm) => {
        return createApi()
            .users.post({
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                roles: values.roles,
                status: "Active",
                hasAcceptedInvite: false,
            })
            .then(user => {
                props.onClose();
                props.onSaved(user.id!);
            })
            .catchWithToastAsync();
    };

    return (
        <Container>
            <Flex direction="column" rowGap="xl">
                <SimpleGrid cols={2}>
                    <Form<AddAdminForm>
                        initialValues={initialValues}
                        validationSchema={adminFormValidator}
                        onSubmit={submitAdmin}
                        submitLabel="SYSADMIN_SAVE"
                    >
                        <Stack>
                            <FormInput.Text fieldName="email" label="SYSADMIN_EMAIL" />
                            <FormInput.Text fieldName="firstName" label="SYSADMIN_FIRST_NAME" />
                            <FormInput.Text fieldName="lastName" label="SYSADMIN_LAST_NAME" />
                            <FormInput.CheckboxGroup
                                fieldName="roles"
                                label="SYSADMIN_ROLE"
                                options={roleCheckBoxItems(t)}
                            />
                        </Stack>
                    </Form>
                </SimpleGrid>
            </Flex>
        </Container>
    );
};

export const AddAdminModal: ModalConfig<AddAdminProps> = {
    content: AddAdmin,
    title: "SYSADMIN_ADD_ADMIN",
};
