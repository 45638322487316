import { Course } from "@/api-client";

export const handleNextLinkChapters = (course: Course, chapterId: number) => {
    if (course) {
        //@ts-ignore
        const chapterIds = course?.chapters.sort((a, b) => a.order - b.order).map(c => c.id);
        const currentChapterIndex = chapterIds.findIndex(c => c === chapterId);
        return chapterIds[currentChapterIndex + 1] ? chapterIds[currentChapterIndex + 1] : undefined;
    }
};

const mapCoursesAndModules = (course: Course) => {
    return (
        course?.chapters
            .filter(f => f.status === "Active")
            //@ts-ignore
            .map(chapter =>
                chapter.modules
                    .filter(modules => modules.status === "Active")
                    //@ts-ignore
                    .sort((a, b) => a.order - b.order)
                    .map(module =>
                        module.blocks
                            .filter(mb => mb.status === "Active")
                            .sort((a, b) => a.sortIndex - b.sortIndex)
                            .map(block => {
                                return {
                                    chapterId: chapter.id,
                                    moduleId: module.id,
                                    moduleBlockId: block.id,
                                };
                            })
                    )
            )
            .flat(2)
    );
};

export const handleNextModuleBlockAndChapter = (
    course: Course,
    courseId: number,
    chapterId: number,
    moduleId?: number,
    moduleBlockId?: number
): string => {
    let next = "/sysadmin/courses";
    if (course) {
        const courseData = mapCoursesAndModules(course);
        if (courseData) {
            const currentIndex = courseData.findIndex(
                d => d.chapterId === chapterId && d.moduleId === moduleId && d.moduleBlockId === moduleBlockId
            );
            const getNextModuleBlock = courseData[currentIndex + 1] ? courseData[currentIndex + 1] : undefined;
            if (getNextModuleBlock && getNextModuleBlock.chapterId === chapterId) {
                next = `/sysadmin/course-preview/${courseId}/chapter/${getNextModuleBlock.chapterId}/module/${getNextModuleBlock.moduleId}/block/${getNextModuleBlock.moduleBlockId}`;
            } else {
                const nextChapterId = handleNextLinkChapters(course, chapterId);
                if (nextChapterId) {
                    next = `/sysadmin/course-preview/${courseId}/chapter/${nextChapterId}/chapter-detail`;
                }
            }
        }
    }
    return next;
};

export const findChapterDetails = (course: Course, chapterId: number) => {
    const data = course?.chapters.find(c => c.id === chapterId);
    return {
        data,
        next:
            data &&
            data?.modules &&
            data?.modules.length > 0 &&
            data.modules[0].blocks &&
            data.modules[0].blocks.length > 0
                ? `/sysadmin/course-preview/${course.id}/chapter/${data.id}/module/${data?.modules[0].id}/block/${data?.modules[0]?.blocks[0]?.id}`
                : "/sysadmin/courses",
    };
};
