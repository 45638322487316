import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useTranslate } from "@tolgee/react";
import { AdditionalResource } from "@/api-client";
import { useHistory } from "@/common";
import { createApi } from "../../../../../common/api";
import { createPatch } from "../../../../../common/patchHelper";
import { Breadcrumbs } from "../../../../../components/BreadCrumbs";
import { ContentLabel } from "../../../../../components/ContentLabel";
import { AppLoader } from "../../../../../components/Spinner";
import Sidebar from "../../../../components/Sidebar";
import { CourseMenu } from "../../../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../../../helper";
import { PrimaryButton } from "../../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../../components/ContentBlock/ContentBlockButtonContainer";
import { CardBlock } from "../../../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    courseId: string;
    chapterId: string;
    moduleId: string;
    moduleBlockId: string;
    resourceId: string;
};

type ResourceStateType = {
    result: AdditionalResource;
    isPending: boolean;
};

const EditAdditionalResources = (): JSX.Element => {
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore

    const chapterId = parseInt(params.chapterId);
    //@ts-ignore

    const moduleId = parseInt(params.moduleId);
    //@ts-ignore

    const moduleBlockId = parseInt(params.moduleBlockId);
    //@ts-ignore

    const resourceId = parseInt(params.resourceId);
    const [course] = useCurrentCourseFromParams();
    //@ts-ignore
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [resourceState, setResourceState] = useState<ResourceStateType>({
        //@ts-ignore
        result: null,
        isPending: false,
    });

    const api = createApi();
    const history = useHistory();
    useEffect(() => {
        //dispatch(fetchAdminCourseAction(courseId));
    }, []);

    useEffect(() => {
        if (resourceId && !isNaN(resourceId)) {
            setResourceState({ ...resourceState, isPending: true });
            api.additionalResources.get(resourceId).then(
                response => {
                    setResourceState({ ...resourceState, result: response, isPending: false });
                },
                error => {
                    setResourceState({ ...resourceState, isPending: false });
                    console.log(error);
                }
            );
        }
    }, [resourceId]);

    const nav = CourseMenu(course);

    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const moduleDetails = chapterDetails?.modules?.find(m => m.id === moduleId);
    const blockDetails = moduleDetails?.blocks?.find(b => b.id === moduleBlockId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/edit` },
        {
            name: moduleDetails?.name,
            path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/module/${moduleDetails?.id}/edit`,
        },
        {
            name: blockDetails?.title,
            path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/module/${moduleDetails?.id}/block/${blockDetails?.id}/edit`,
        },
        { name: "Edit Additional Resource", path: null },
    ];
    const { isPending, result } = resourceState;

    const initialValues: AdditionalResource = {
        title: result?.title,
        url: result?.url,
        moduleId: moduleId,
        moduleBlockId: moduleBlockId,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: result?.status,
    };

    const validate = () => {
        return Yup.object().shape({
            title: Yup.string().required("Title is required").nullable(),
            url: Yup.string().required("Resource is required").nullable(),
        });
    };

    //@ts-ignore
    const uploadFile = async (selectedFile: File): Promise<string> => {
        setResourceState({ ...resourceState, isPending: true });
        return api.additionalResources
            .uploadResource({ fileName: selectedFile.name, data: selectedFile })
            .then(result => {
                setResourceState({ ...resourceState, isPending: false });
                return result;
            });
    };

    //@ts-ignore
    const handleImageChange = async (image: File, setFieldValue) => {
        await setFieldValue("url", image);
        setSelectedFile(image);
    };

    return (
        <>
            {
                //@ts-ignore
                blockDetails != null && <Breadcrumbs breadCrumb={links} />
            }
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar
                            children={nav}
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlockId={moduleBlockId}
                            name={course?.name}
                        />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <AppLoader loading={!!isPending} className="position-absolute" />
                        <ContentLabel text="Edit Additional Resources" />
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validate()}
                            onSubmit={async values => {
                                let uploadFiles = values.url;
                                if (selectedFile) {
                                    uploadFiles = await uploadFile(selectedFile);
                                }
                                const patch = createPatch(result, x => {
                                    x.id = resourceId;
                                    x.title = values.title;
                                    x.moduleId = moduleId;
                                    x.status = values.status;
                                    x.moduleBlockId = values.moduleBlockId;
                                    x.url = uploadFiles;
                                    x.updatedAt = new Date();
                                });

                                if (patch && patch.length > 0) {
                                    setResourceState({ ...resourceState, isPending: true });
                                    api.additionalResources.patch(resourceId, patch).then(
                                        response => {
                                            setResourceState({ ...resourceState, isPending: false });
                                            history.push(
                                                `/sysadmin/courses/${courseId}/chapter/${chapterId}/module/${moduleId}/block/${moduleBlockId}/edit`
                                            );
                                        },
                                        error => {
                                            setResourceState({ ...resourceState, isPending: false });
                                            console.log(error, "Error in update resources.");
                                        }
                                    );
                                }
                            }}
                        >
                            {formikProps => {
                                const { errors, touched, setFieldValue } = formikProps;
                                return (
                                    <Form name="additional-resources" className="mt-2 additional-resources">
                                        <Row className="mb-4 mt-4">
                                            <Col>
                                                <label>Title</label>
                                                <Field
                                                    name="title"
                                                    type="text"
                                                    className={`form-control${
                                                        errors.title && touched.title ? " is-invalid" : ""
                                                    }`}
                                                />
                                                {errors.title && (
                                                    <span className="clearfix w-100 text-danger text-left displayblock">
                                                        {" "}
                                                        {errors.title}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col />
                                        </Row>
                                        <Row className="mb-4 mt-4">
                                            <Col>
                                                <label>Additional Resources</label>
                                                <div className="file has-name">
                                                    <label className="file-label">
                                                        <input
                                                            type="file"
                                                            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            name="url"
                                                            placeholder={result?.url}
                                                            multiple={false}
                                                            onChange={async e => {
                                                                e.preventDefault();
                                                                await handleImageChange(
                                                                    //@ts-ignore
                                                                    e.target.files[0],
                                                                    setFieldValue
                                                                );
                                                            }}
                                                            className="file-input"
                                                        />
                                                        <span className="file-cta">
                                                            <span className="file-icon">
                                                                <i className="fas fa-upload" />
                                                            </span>
                                                            <span className="file-label">Choose a file…</span>
                                                        </span>
                                                        <span className="file-name">
                                                            {selectedFile ? selectedFile.name : result?.url}
                                                        </span>
                                                    </label>
                                                </div>

                                                {errors.url && (
                                                    <span className="clearfix w-100 text-danger text-left displayblock">
                                                        {" "}
                                                        {errors.url}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col />
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Status</label>
                                                <Field name="status" as="select" className="form-select">
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                    <option value="InProgress">InProgress</option>
                                                    <option value="Deleted">Deleted</option>
                                                    <option value="Invited">Invited</option>
                                                    <option value="Disabled">Disabled (Locked)</option>
                                                </Field>
                                            </Col>
                                            <Col />
                                        </Row>
                                        <ContentBlockButtonContainer>
                                            <PrimaryButton type="submit">Update</PrimaryButton>
                                        </ContentBlockButtonContainer>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBlock>
                </div>
            </div>
        </>
    );
};

export default EditAdditionalResources;
