import { FC } from "react";
import { useParams } from "react-router-dom";

export type PageProps<Path extends string, TProps> = {
    component: FC<TProps>;
    url: {
        pattern: Path;
        parse: (params: any) => TProps;
    };
};

export const PageRouteComponent = <Path extends string, TProps>(props: PageProps<Path, TProps>) => {
    const params = useParams();

    //@ts-ignore
    return <props.component {...props.url.parse(params)} />;
};
