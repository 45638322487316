const darkSmokePalette: [string, string, string, string, string, string, string, string, string, string] = [
    "#e9f3ff",
    "#cfd7e5",
    "#b3bcce",
    "#94a2b7",
    "#7787a1",
    "#5e6d88",
    "#48556b",
    "#323d4d",
    "#1c2431",
    "#030d18",
];

export const theme = { colors: { darkSmoke: darkSmokePalette } };
