import { Module } from "@/api-client";
import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import { ModuleCard } from "./ModuleCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NavButton } from "@/components";

/*  eslint-disable react-hooks/exhaustive-deps */

interface ModuleProps {
    chapterId: number;
    courseId: number;
    modules: Module[];
}

const Modules = (props: ModuleProps) => {
    const api = createApi();
    const [tempModules, setTempModules] = useState<Module[]>(props.modules);

    const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
        setTempModules(c =>
            update(c, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, c[dragIndex]],
                ],
            }).map((x, ix) => ({ ...x, order: ix }))
        );
    }, []);

    useEffect(() => {
        const work = async () => {
            for (const tempModule of tempModules) {
                const patch = createPatch(
                    //@ts-ignore
                    props.modules?.find(module => module.id === tempModule.id),
                    x => {
                        //@ts-ignore
                        x.order = tempModule.order;
                    }
                );

                if (patch.length > 0) {
                    //@ts-ignore
                    await api.modules.patch(tempModule.id, patch);
                }
            }
        };

        work();
    }, [tempModules]);

    useEffect(() => setTempModules(props.modules), [props.modules]);

    const renderModules = (): React.ReactElement[] => {
        return tempModules.map((module, index) => (
            <ModuleCard
                key={module.id}
                index={index}
                moveCard={moveCard}
                module={module}
                courseId={props.courseId}
                //@ts-ignore
                id={module.id}
                chapterId={props.chapterId}
            />
        ));
    };

    return (
        <>
            <ContentLabel text="Modules" />
            <NavButton
                link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/add`}
                textKey="Add Module"
            />
            <table className="items-drag-drop table table-borderless table-striped table-responsive table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th className="whitespace-nowrap">Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <DndProvider backend={HTML5Backend}>
                    <tbody className="sysadmin-drag">{renderModules()}</tbody>
                </DndProvider>
            </table>
        </>
    );
};

export default Modules;
