/**
 * Update an array item in a way reacts pesky state trigger changes on
 * @param array
 * @param item
 * @param update
 */
export function cloneUpdateObjectArray<T>(array: T[], item: T, update: (item: T) => T): Array<T> {
    const itemIndex = array.indexOf(item);

    if (itemIndex === -1) {
        throw new Error("cloneUpdateObjectArray: Item not found in array ");
    }

    const newArray = [...array];
    newArray[itemIndex] = update(item);

    return newArray;
}
