import { Course, Group } from "@/api-client";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../common/api";
import { DisplayInlineError } from "../../DisplayInlineError";
import StyledCheckbox from "../../StyledCheckbox";
import { PrimaryButton } from "../../Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

interface EditGroupProps {
    groups: Group[];
    show: boolean;
    hide: () => void;
    updateGroup: (groupId: number, membershipType: MembershipTypeModel) => void;
    userId: number;
    clientId: number;
}

export interface MembershipTypeModel {
    isParticipant: boolean;
    isGroupLeader: boolean;
}

export const AddMembership = (props: EditGroupProps) => {
    const { groups, show, hide, updateGroup, userId } = props;
    const { t } = useTranslate();
    /* @ts-ignore */
    const [selectedGroup, setSelectedGroup] = useState<number>(null);
    /* @ts-ignore */
    const [error, setError] = useState<string>(null);
    const [courses, setCourses] = useState<Course[]>([]);
    const api = createApi();

    useEffect(() => {
        api.courses.query(false).then(
            result => {
                setCourses(result);
            },
            error => {
                console.log(error, "Error in getting groups.");
            }
        );
    }, []);

    const [membershipType, setMembershipType] = useState<MembershipTypeModel>({
        isParticipant: true,
        isGroupLeader: false,
    });

    const handleGroupValues = (groupId: number) => {
        if (!groupId) {
            setError("Please select group");
            /* @ts-ignore */
            setSelectedGroup(null);
            return;
        }
        if (groupId) {
            const findGroupByClientId = groups.filter(g => g.clientId === props.clientId);
            /* @ts-ignore */
            const memberInGroups = findGroupByClientId
                .find(g => g.id === groupId)
                .members.find(m => m.userId === userId);

            /* @ts-ignore */
            const groupCourseIds = findGroupByClientId.find(g => g.id === groupId).courseIds;
            const courseIds = findGroupByClientId
                .filter(g => g.members.find(m => m.userId === userId))
                ?.flatMap(g => g.courseIds.filter(c => groupCourseIds.includes(c)));
            const getCourseIds = groupCourseIds.filter(i => courseIds.includes(i));
            if (getCourseIds && getCourseIds.length === 0) {
                setError(`Course is not asigned to group.`);
                return false;
            } else if (memberInGroups) {
                setError(
                    `User Already Assigned in same group/course "${
                        /* @ts-ignore */
                        findGroupByClientId.find(g => g.id === groupId).name
                    }"`
                );
                /* @ts-ignore */
                setSelectedGroup(null);
                return false;
            } else {
                setSelectedGroup(groupId);
                /* @ts-ignore */
                setError(null);
            }
        }
    };

    /* @ts-ignore */
    const handleRole = (type: string, value) => {
        setMembershipType({ ...membershipType, [type]: value });
    };

    useEffect(() => {
        if (membershipType.isGroupLeader === false && membershipType.isParticipant === false) {
            setMembershipType({ ...membershipType, isParticipant: true });
        }
    }, [membershipType]);

    const groupsWithCourse =
        groups && groups.length > 0 && courses && courses.length > 0
            ? courses
                  .map(m => {
                      return {
                          name: m.name,
                          courseId: m.id,
                          groups: groups.filter(
                              /* @ts-ignore */
                              g => g.courseIds.includes(m.id) && g.clientId === props.clientId && g.status === "Active"
                          ),
                      };
                  })
                  .filter(a => a.groups.length > 0)
            : [];

    return (
        <Fragment>
            <Modal show={show} onHide={hide} size={"lg"} animation={false}>
                <Modal.Header closeButton aria-label="Hide">
                    <Modal.Title>Add Group Membership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-inline">
                        <label className="form-label" htmlFor="inlineFormInputName2">
                            {t("RESOURCES_DEFINE_PLAN_NAME")}
                        </label>
                        <select
                            name="group"
                            className="form-select"
                            onChange={e => handleGroupValues(parseInt(e.target.value))}
                        >
                            <option value="">Please Select Group</option>
                            {groupsWithCourse &&
                                groupsWithCourse.length > 0 &&
                                groupsWithCourse.map(g => {
                                    return (
                                        <>
                                            <optgroup label={g.name}></optgroup>
                                            {g.groups &&
                                                g.groups.length > 0 &&
                                                g.groups.map(gr => {
                                                    return <option value={gr.id}>{gr.name}</option>;
                                                })}
                                        </>
                                    );
                                })}
                        </select>
                        {error && (
                            <div className="mt-1">
                                <DisplayInlineError error={error} />
                            </div>
                        )}
                    </div>

                    <div className="row mt-3 form-inline">
                        <div className="col-md-3">
                            <StyledCheckbox
                                checked={membershipType.isParticipant}
                                onChange={val => handleRole("isParticipant", val)}
                                label="Is Participant"
                            />
                        </div>
                        <div className="col-md-3">
                            <StyledCheckbox
                                checked={membershipType.isGroupLeader}
                                onChange={val => handleRole("isGroupLeader", val)}
                                label="Is GroupLeader"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton onClick={() => updateGroup(selectedGroup, membershipType as MembershipTypeModel)}>
                        {t("EDITEMPLOYEE_ADD_GROUP_MEMBERSHIP")}
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};
