﻿import { ApiConfiguration } from "./contracts";
import { Get, PostJson } from "./utility";

export class BaseClient {
    constructor(protected config: ApiConfiguration) {}

    protected get = async <T>(url: string, abortController: AbortController): Promise<T> => {
        const response = await Get(
            `${this.config.baseUrl}${url}`,
            this.config.getAuthorizationHeader(),
            abortController
        );

        if (response.ok) {
            return (await response.json()) as T;
        }

        throw new Error(response.statusText);
    };

    protected post = async <TIn, TOut>(url: string, dto: TIn): Promise<TOut> => {
        const response = await PostJson(`${this.config.baseUrl}${url}`, dto, this.config.getAuthorizationHeader());

        if (response.ok) {
            return (await response.json()) as TOut;
        }

        throw new Error(response.statusText);
    };

    protected postVoid = async <TIn>(url: string, dto: TIn): Promise<void> => {
        const response = await PostJson(`${this.config.baseUrl}${url}`, dto, this.config.getAuthorizationHeader());

        if (response.ok) {
            return Promise.resolve();
        }

        throw new Error(response.statusText);
    };
}
