import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
    row: { gap: "0.4rem", minWidth: "100%" },
    container: { maxWidth: "40ch", margin: "1rem 0.5rem" },
    title: {
        fontWeight: 600,
        marginBottom: "0.5rem",
        fontSize: "1.2rem",
    },
    divider: { width: "100%", margin: "0.8rem 0" },
}));
