import { Client } from "@/api-client";
import { Operation } from "fast-json-patch";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import FileUploader from "../../../account-owner/Components/FileUploader";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { useUserContext } from "../../../common/user/context";
import { UserHasOneOfRoles } from "../../../common/user/utils";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify, showToast } from "../../../components/Notify";
import { AppLoader } from "../../../components/Spinner";
import { AGENT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { ClientLogin } from "../ClientLogin";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { CardBlock } from "../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Client Name is required"),
        maxLicenses: Yup.string().required("Licences is required"),
    });
};

interface ImageData {
    isPending: boolean;
    path: string;
}

interface AddClientResponse {
    isPending: boolean;
    client: Client;
}

type EditFormProps = {
    client: Client;
    isPending: boolean;
};

export const EditForm = (props: { clientData: EditFormProps }) => {
    const { client, isPending } = props.clientData;
    const api = createApi();

    const initialValues = {
        name: client ? client.name : "",
        maxLicenses: client && client.maxLicenses ? client.maxLicenses : 0,
        logo: client ? client.logo : "",
        status: client ? client.status : "Active",
        createdAt: client ? client.createdAt : new Date(),
        accountManager: client?.accountManager,
        //Owner Id is just used to populate the value in Select
        accountManagerId: client?.accountManagerId,
        tags: client?.tags,
        //updatedAt: new Date()
    };

    // @ts-ignore
    const [image, setImage] = useState<string>(null);
    const [saveImage, setSaveImage] = useState<ImageData>({
        // @ts-ignore
        isPending: null,
        // @ts-ignore
        path: null,
    });

    const [addClient, setAddClient] = useState<AddClientResponse>({
        // @ts-ignore
        isPending: null,
        // @ts-ignore
        client: null,
    });

    useEffect(() => {
        if (props.clientData && props.clientData.client && props.clientData.client.logo) {
            setImage(props.clientData.client.logo);
        }
    }, [props.clientData]);

    // @ts-ignore
    const fileUploader = (uploadFile: File, setFieldValue) => {
        setImage(URL.createObjectURL(uploadFile));
        setSaveImage({ ...saveImage, isPending: true });

        return api.images.post({ fileName: uploadFile.name, data: uploadFile }).then(
            result => {
                setFieldValue("logo", result, false);
                setSaveImage({ ...saveImage, path: result, isPending: false });
            },
            error => {
                showToast("Something going wrong. Please try again later.", "warning");
                setSaveImage({ ...saveImage, isPending: false });
                console.log(error);
            }
        );
    };

    const userContext = useUserContext();
    // @ts-ignore
    const isSysadmin: boolean = UserHasOneOfRoles(userContext.user, [SYSADMIN_ROLE]);
    const clientsDispatch = useSysadminContextDispatch();

    const updateClient = (values: any) => {
        setAddClient({ ...addClient, isPending: true });
        let patch: Operation[] = [];
        if (isSysadmin) {
            patch = createPatch(initialValues, x => {
                x.name = values.name;
                x.logo = values.logo;
                x.maxLicenses = values.maxLicenses;
                x.status = values.status;
                x.accountManagerId = values.accountManagerId; // ? users.find(u => u.id === parseInt(values.accountManagerId)) : isAgent ? userContext.user.id : null
                x.tags = values.tags;
            });
        } else {
            patch = createPatch(initialValues, x => {
                x.name = values.name;
                x.logo = values.logo;
                x.maxLicenses = values.maxLicenses;
                x.status = values.status;
                x.tags = values.tags;
            });
        }

        // @ts-ignore
        api.clients.patch(client.id, patch).then(
            result => {
                setAddClient({ ...addClient, client: result, isPending: false });
                clientsDispatch(s => ({
                    ...s,
                    // @ts-ignore
                    clients: s.clients.map(m => (m.id === client.id ? result : m)),
                }));
            },
            error => {
                setAddClient({ ...addClient, isPending: false });
                console.log(error);
            }
        );
    };

    return (
        <CardBlock mb="xl">
            <Col className="update-client-login-ao">
                <ContentLabel text="Update Client" />
                <ClientLogin
                    //@ts-ignore
                    clientId={client?.id}
                    componentType={`button`}
                />
            </Col>
            {isPending !== null && <AppLoader className="position-absolute" loading={isPending} />}
            {client ? (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validate()}
                    onSubmit={(values, helpers) => {
                        updateClient(values);
                    }}
                >
                    {formikProps => {
                        let { errors, touched, setFieldValue } = formikProps;
                        return (
                            <Form action="#" name="addCourseForm" className="mt-2 editCourseForm">
                                <AppLoader
                                    loading={saveImage.isPending || addClient.isPending ? true : false}
                                    className="position-absolute"
                                />
                                {addClient.client !== null && (
                                    <Fragment>
                                        <Notify
                                            message="Client updated successfully."
                                            id="registersuccess"
                                            type="success"
                                        />
                                        {/* <Redirect to="/sysadmin/clients"/> */}
                                    </Fragment>
                                )}
                                <Row className="mb-4">
                                    <Col md={5}>
                                        <label>Name</label>
                                        <Field
                                            name="name"
                                            type="text"
                                            className={
                                                "form-control" + (errors.name && touched.name ? " is-invalid" : "")
                                            }
                                        />
                                        <ErrorMessage
                                            className="clearfix w-100 text-danger text-left displayblock"
                                            name="name"
                                            component="span"
                                        />
                                    </Col>
                                    <Col md={6}></Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col md={5}>
                                        <label>Licence</label>
                                        <Field
                                            name="maxLicenses"
                                            type="text"
                                            className={
                                                "licences form-control" +
                                                (errors.maxLicenses && touched.maxLicenses ? " is-invalid" : "")
                                            }
                                        />
                                        <ErrorMessage
                                            className="clearfix w-100 text-danger text-left displayblock"
                                            name="maxLicenses"
                                            component="span"
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>
                                {isSysadmin && (
                                    <Row className="mb-4">
                                        <Col md={5}>
                                            <label>Agent</label>
                                            <RoleBasedUser roleName={AGENT_ROLE} fieldName="accountManagerId" />
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                )}
                                <Row className="mb-4">
                                    <Col md={12}>
                                        <label>Logo</label>
                                        {image !== null && (
                                            <div className="mb-2">
                                                <img
                                                    style={{ maxHeight: "100px" }}
                                                    src={image}
                                                    alt=""
                                                    className="client-logo"
                                                />
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={5}>
                                        <FileUploader
                                            acceptedFiles="image/jpg, image/png, image/jpeg"
                                            handleFile={(uploadFile: any) => fileUploader(uploadFile, setFieldValue)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col md={5}>
                                        <label>Status</label>
                                        <Field name="status" as="select" className="form-select">
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            <option value="Deleted">Deleted</option>
                                        </Field>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col md={5}>
                                        <label className="form-label">Tags</label>
                                        <AutocompleteTags
                                            module={`clients`}
                                            defaultValue={props?.clientData?.client?.tags}
                                            selectedValue={(value: string[]) => setFieldValue("tags", value)}
                                        />
                                    </Col>
                                </Row>
                                <ContentBlockButtonContainer>
                                    <PrimaryButton type="submit">Update</PrimaryButton>
                                </ContentBlockButtonContainer>
                            </Form>
                        );
                    }}
                </Formik>
            ) : null}
        </CardBlock>
    );
};
