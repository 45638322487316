﻿import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
    useId,
    useIsomorphicEffect,
    useMergedRef,
    useReducedMotion,
    useResizeObserver,
    useUncontrolled,
} from "@mantine/hooks";
import { useComponentDefaultProps, useMantineTheme } from "@mantine/styles";
// @ts-ignore
import useStyles from "@mantine/core/esm/SegmentedControl/SegmentedControl.styles";
import { Box, SegmentedControlProps } from "@mantine/core";

/* Fork of https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/SegmentedControl/SegmentedControl.tsx */

export interface SegmentedControlItem {
    value: string;
    label: React.ReactNode;
    disabled?: boolean;
}

const defaultProps: Partial<SegmentedControlProps> = {
    disabled: false,
    size: "sm",
    transitionDuration: 200,
};

export const SegmentedControl = forwardRef<HTMLDivElement, SegmentedControlProps>((props, ref) => {
    const {
        className,
        disabled,
        data: _data,
        name,
        value,
        onChange,
        color,
        fullWidth,
        radius,
        size,
        transitionDuration,
        transitionTimingFunction,
        classNames,
        styles,
        defaultValue,
        orientation,
        unstyled,
        ...others
    } = useComponentDefaultProps("SegmentedControl", defaultProps, props);
    const theme = useMantineTheme();
    const shouldReduceMotion = useReducedMotion();
    const reduceMotion = theme.respectReducedMotion ? shouldReduceMotion : false;

   
    // @ts-ignore
    const data = _data.map(
        (item: string | SegmentedControlItem): SegmentedControlItem =>
            typeof item === "string" ? { label: item, value: item } : item
    );
    const mounted = useRef<Boolean>();

    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [_value, handleValueChange] = useUncontrolled({
        value,
        defaultValue,
        finalValue: Array.isArray(data) ? data.find(item => !item.disabled)?.value ?? data[0]?.value ?? null : null,
        onChange,
    });

    const { classes, cx } = useStyles(
        {
            size,
            fullWidth,
            color,
            radius,
            shouldAnimate: reduceMotion || !shouldAnimate,
            transitionDuration,
            transitionTimingFunction,
            orientation,
        },
        { classNames, styles, unstyled, name: "SegmentedControl" }
    );

    const [activePosition, setActivePosition] = useState({
        width: 0,
        height: 0,
        translate: [0, 0],
    });
    const uuid = useId(name);
    const refs = useRef<Record<string, HTMLLabelElement>>({});
    const [observerRef, containerRect] = useResizeObserver();

    useIsomorphicEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            setShouldAnimate(false);
        } else {
            setShouldAnimate(true);
        }
    });

    useEffect(() => {
        // @ts-ignore
        if (_value in refs.current && observerRef.current) {
            // @ts-ignore
            const element = refs.current[_value];
            const elementRect = element.parentElement!.getBoundingClientRect();

            const wrapperPadding = 4;

            const width = elementRect.width + 1 - wrapperPadding || 0;
            const height = elementRect.height - wrapperPadding || 0;

            const offsetLeft = element.parentElement!.offsetLeft;

            setActivePosition({
                width,
                height,
                translate: [offsetLeft + (elementRect.width - width) / 2, 0],
            });
        }
    }, [_value, containerRect]);

    // @ts-ignore
    const controls = data.map(item => (
        <div className={cx(classes.control, { [classes.controlActive]: _value === item.value })} key={item.value}>
            <input
                className={classes.input}
                disabled={disabled || item.disabled}
                type="radio"
                name={uuid}
                value={item.value}
                id={`${uuid}-${item.value}`}
                checked={_value === item.value}
                onChange={() => handleValueChange(item.value)}
            />

            <label
                className={cx(classes.label, {
                    [classes.labelActive]: _value === item.value,
                    [classes.disabled]: disabled || item.disabled,
                })}
                htmlFor={`${uuid}-${item.value}`}
                ref={node => {
                    // @ts-ignore
                    refs.current[item.value] = node;
                }}
            >
                {item.label}
            </label>
        </div>
    ));

    const mergedRef = useMergedRef(observerRef, ref);

    if (data.length === 0) {
        return null;
    }

    return (
        <div>
            <Box className={cx(classes.root, className)} ref={mergedRef} {...others}>
                {typeof _value === "string" && shouldAnimate && (
                    <Box
                        component="span"
                        className={classes.indicator}
                        sx={{
                            width: activePosition.width,
                            height: activePosition.height,
                            transform: `translate(${activePosition.translate[0]}px, ${activePosition.translate[1]}px )`,
                        }}
                    />
                )}

                {controls}
            </Box>
        </div>
    );
});

SegmentedControl.displayName = "@mantine/core/SegmentedControl";
