import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import PageHeading from "../../components/PageHeading";
import { AppLoader } from "../../components/Spinner";
import { DisplayDemoGraphicQuestions } from "./DemoGraphicQuestions";
import { CardBlock } from "../../components/CardBlock";

export const EditDemographicQuestions = (props: { demographicQuestionId?: number }) => {
    const { t } = useTranslate();

    const questions = useOrganisationContext().demographicQuestions;
    const questionsPending = questions === undefined;

    const currentQuestion = questions?.find(q => q.id === props.demographicQuestionId);
    const breadCrumbs = [
        { name: "Demographic Questions", path: `/account-owner/edit/organisation-planning/demographic-questions` },
        {
            name: currentQuestion?.label,
            path: `/account-owner/edit/organisation-planning/demographic-questions/${currentQuestion?.id}/edit`,
            dropdownItems:
                questions && questions.length > 0
                    ? questions?.map(c => {
                          return {
                              name: c?.label,
                              path: `/account-owner/edit/organisation-planning/demographic-questions/${c?.id}/edit`,
                          };
                      })
                    : undefined,
        },
    ];
    return (
        <React.Fragment>
            {
                //@ts-ignore
                !isNaN(props.demographicQuestionId) && <Breadcrumbs breadCrumb={breadCrumbs} />
            }
            <CardBlock mb="xl">
                <AppLoader loading={false} className="position-absolute" />
                {
                    //@ts-ignore
                    !isNaN(props.demographicQuestionId) ? (
                        <PageHeading
                            contentLabel={t("ONBOARDING_DEMOGRAPHIC_SUB_HEADING")}
                            className="account-settings"
                        />
                    ) : (
                        <PageHeading
                            contentLabel={t("ONBOARDING_DEMOGRAPHIC_SUB_HEADING")}
                            detailText={t("ACCOUNTOWNER_EMPLOYEES_AND_DEMOGRAPHIC_QUESTIONS")}
                            className="account-settings"
                        />
                    )
                }
                <Row className="demographic-slides">
                    <Col md={12}>
                        <DisplayDemoGraphicQuestions
                            isEditComponent={true}
                            demographicQuestionId={props.demographicQuestionId}
                            questions={questions}
                            questionsPending={questionsPending}
                        />
                    </Col>
                </Row>
            </CardBlock>
        </React.Fragment>
    );
};
