import React, { useState } from "react";
import { MultiSelectToggle } from "../../../components/MultiSelectToggle";
import RenderIf from "../../../components/render-if/render-if";
import "./style.scss";

// QA: This helper could be improved. Logic is over-complicated and asc/desc and other toggles could be handled in this component instead of parent

export type SearchField = {
    type: string;
    name: string;
    placeholder?: string;
    onChange?: (value: string | number, checked?: boolean) => void;
    className?: string;
    value?: any;
    id?: number;
    options?: any[];
    isNumericStatus?: boolean;
    onClear?: () => void;
    displayField?: boolean;
} | null;

type TableHeading = {
    id?: number;
    name: string;
    isSearchable?: boolean;
    className?: string;
    isActive?: boolean;
    sorting?: boolean;
    handleSorting?: () => void;
    displayHeading?: boolean;
};

type FiltrationProps = {
    fields: SearchField[];
    tableHeadings: TableHeading[];
    isNumericStatus?: boolean;
};

export const Filtration = (props: FiltrationProps) => {
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [showFilterIcon, setShowFilterIcon] = useState<TableHeading[]>(props.tableHeadings);

    const handleDisplayFilters = (heading: TableHeading) => {
        if (heading.name !== null) {
            setShowFilter(!showFilter);
        }
    };

    const showFilterIcons = (heading: TableHeading, isActive: boolean) => {
        const getStateValues = [...showFilterIcon];
        const getIndex = [...getStateValues]?.findIndex(i => i?.id === heading?.id);
        getStateValues[getIndex].isActive = isActive;
        setShowFilterIcon(getStateValues);
    };

    return (
        <React.Fragment>
            <tr key={`filter-fields-values`}>
                {showFilterIcon &&
                    showFilterIcon.map((h, i) => {
                        const value = props.fields.find(p => p?.id === h?.id)?.value;
                        const displayHeading = props.fields.find(p => p?.id === h?.id)?.displayField;
                        return (
                            <React.Fragment key={`${h?.id}-${i}-${h?.name}`}>
                                {(displayHeading || displayHeading === undefined) && (
                                    <th
                                        className={`${h.className} ${h.name !== null ? "pointer-type" : ""}`}
                                        onMouseOut={() => showFilterIcons(h, false)}
                                        onMouseOver={() => showFilterIcons(h, true)}
                                    >
                                        {h.name}
                                        <RenderIf
                                            // @ts-ignore

                                            show={h.sorting}
                                        >
                                            <i
                                                // @ts-ignore

                                                onClick={() => h.handleSorting()}
                                                className={`fas fa-sort sorting ${
                                                    value !== "" && value !== null && value !== undefined
                                                        ? "selected"
                                                        : !h.isActive
                                                        ? "not-active"
                                                        : "un-selected"
                                                }`}
                                                aria-hidden="true"
                                            />
                                        </RenderIf>
                                        <RenderIf
                                            // @ts-ignore

                                            show={h.isSearchable}
                                        >
                                            <i
                                                onClick={() => handleDisplayFilters(h)}
                                                className={`fa fa-filter ${
                                                    value !== "" && value !== null && value !== undefined
                                                        ? "selected"
                                                        : !h.isActive
                                                        ? "not-active"
                                                        : "un-selected"
                                                }`}
                                                aria-hidden="true"
                                            />
                                        </RenderIf>
                                    </th>
                                )}
                            </React.Fragment>
                        );
                    })}
            </tr>
            <RenderIf show={showFilter}>
                <tr className="filter-data" key={`filter-data-fileds`}>
                    {props.fields.map((f, i) => {
                        return (
                            <React.Fragment key={`${i}-${f?.name}`}>
                                {(f?.displayField || f?.displayField === undefined) && (
                                    <td key={`${i}-${f?.id}`} className={f?.className ? f?.className : ""}>
                                        <RenderIf show={!(f?.type === "anchor" || f === null)}>
                                            <>
                                                <RenderIf show={f !== null && f?.type !== null}>
                                                    <>
                                                        {f?.type === "text" && (
                                                            <input
                                                                className="form-control"
                                                                onChange={e => {
                                                                    //updateStateChange( f.id, e.target.value );
                                                                    // @ts-ignore

                                                                    f.onChange(e.target.value);
                                                                }}
                                                                value={f?.value ?? ""}
                                                                type="text"
                                                                placeholder={f.placeholder}
                                                            />
                                                        )}
                                                        {f?.type === "select" && (
                                                            <select
                                                                defaultValue={f?.value}
                                                                className="form-select"
                                                                aria-label="select"
                                                                onChange={e => {
                                                                    // @ts-ignore

                                                                    f.onChange(e.target.value);
                                                                }}
                                                            >
                                                                {f?.options &&
                                                                    f?.options.map((status, ix) => (
                                                                        <option
                                                                            key={status?.id ?? ix}
                                                                            value={
                                                                                f.isNumericStatus
                                                                                    ? status?.id
                                                                                    : status?.value
                                                                            }
                                                                        >
                                                                            {status?.label}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        )}
                                                        {f?.type === "multi-select" && (
                                                            <MultiSelectToggle
                                                                toggleClassName="filter-tag-btn"
                                                                buttonText={f?.value}
                                                                // @ts-ignore

                                                                options={f?.options}
                                                                onClear={() => {
                                                                    // @ts-ignore

                                                                    f?.onClear();
                                                                }}
                                                                // @ts-ignore
                                                                onChange={(id: number, checked: boolean) =>
                                                                    // @ts-ignore
                                                                    f.onChange(id, checked)
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                </RenderIf>
                                            </>
                                        </RenderIf>
                                        <RenderIf show={f !== null && f.type === "anchor"}>
                                            <button
                                                className="btn btn-link hide-button"
                                                type="button"
                                                onClick={() => setShowFilter(false)}
                                            >
                                                {f?.name}
                                            </button>
                                        </RenderIf>
                                    </td>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tr>
            </RenderIf>
        </React.Fragment>
    );
};
