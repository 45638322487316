import { useTranslate } from "@tolgee/react";
import { FullLogo } from "../../assets/index";
import "./style.scss";

export function ErrorFallback(): JSX.Element {
    const logo = FullLogo;
    const { t } = useTranslate();
    return (
        <div className="error-boundaries-container">
            <section
                className="error-boundaries-data content-block block rounded p-4 mb-5"
                style={{ backgroundColor: "#fff" }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <img src={logo} className="main-login-logo" alt="Hups" />
                            <p className="mt-3">{t("COMMON_ERROR_AN_UNEXPECTED_ERROR_HAS_OCCURED")}</p>
                            <p>
                                {t("COMMON_ERROR_WE_ARE_SORRY_FOR_THE_INCONVENIENCE")}{" "}
                                {t("COMMON_ERROR_PLEASE_CONTACT_SUPPORT_IF_THIS_ERROR_PERSISTS")}
                            </p>
                            <p>{t("COMMON_ERROR_TO_CONTINUE_PLEASE_LOG_IN_AGAIN")}</p>
                        </div>
                    </div>
                </div>
                {/* Need to be hard link to reload react if something goes wrong */}
                <a href="/login" className="btn btn-primary content-block-button">
                    {t("COMMON_LOGIN")}
                </a>
            </section>
        </div>
    );
}
