﻿import { useEffect, useMemo, useState } from "react";
import { Title, Card, createStyles, SegmentedControl, SimpleGrid, Text, Container } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { Navigate } from "react-router-dom";
import { CardBlock, RenderIf, ProgramCard } from "@/components";
import { createApi, UrlFactory } from "@/common";
import { Areas } from "@/api-client";
import { customEvents } from "../../analytics/customEvents/customEvents";
import { FullLogo } from "../../assets";
import { deserializeInviteLink, isInviteLinkValid } from "../SysAdmin/Programs/InviteToProgramOverviewModal";

const useStyles = createStyles(theme => ({
    subareaCard: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    subareaTitle: {
        fontWeight: 700,
    },
}));

export const AllProgramsInvitePage = (props: { data: string }) => {
    const [areas, setAreas] = useState<Areas[]>([]);
    const [selectedArea, setSelectedArea] = useState<Areas | undefined>(undefined);
    const { classes } = useStyles();
    const { t } = useTranslate();

    useEffect(() => {
        const abortController = new AbortController();
        const op = async () => {
            const api = createApi();

            const configuration = await api.configuration.getConfiguration(abortController);
            const programs = await api.allPrograms.getAll(configuration.blobStorageBaseUrl, abortController);

            const filteredAreas = programs.filter(area =>
                area.subareas.some(subarea => subarea.capabilities.some(cap => cap.published))
            );

            setAreas(filteredAreas);
            setSelectedArea(filteredAreas[0]);
        };

        op().catchWithToast();

        return () => {
            abortController.abort();
        };
    }, []);

    const segmentedControlData = useMemo(() => areas.map(area => ({ label: area.name, value: area.name })), [areas]);

    const deserializedData = deserializeInviteLink(props.data);
    if (!isInviteLinkValid(deserializedData)) {
        return <Navigate to={UrlFactory.login.create({})} />;
    }

    return (
        <Container size="xl" p="xl" ml="auto" mr="auto">
            <img style={{ objectFit: "cover", position: "absolute" }} src={FullLogo} height={70} alt="Hups logo" />
            <Title style={{ textAlign: "center" }} size={50}>
                {t("PROGRAM_OVERVIEW")}
            </Title>

            <CardBlock mt="xl">
                <RenderIf show={deserializedData.contact !== undefined}>
                    <Text fz="sm">{t("PROGRAM_OVERVIEW_INVITE_WELCOME_TEXT", { name: deserializedData.contact })}</Text>
                </RenderIf>
                <SegmentedControl
                    size="md"
                    value={selectedArea?.name}
                    fullWidth
                    onChange={value => setSelectedArea(areas.find(x => x.name === value))}
                    data={segmentedControlData}
                    mt="md"
                />
                <RenderIf show={selectedArea !== undefined}>
                    <div>
                        <RenderIf show={selectedArea?.description !== null}>
                            <Card
                                key={selectedArea?.name}
                                withBorder
                                radius="md"
                                className={classes.subareaCard}
                                my="xl"
                            >
                                <div dangerouslySetInnerHTML={{ __html: selectedArea?.description! }} />
                            </Card>
                        </RenderIf>
                        {selectedArea?.subareas.map(subarea => (
                            <RenderIf show={subarea.capabilities.length > 0} key={subarea.name}>
                                <Card withBorder radius="md" className={classes.subareaCard} my="xl">
                                    <Text className={classes.subareaTitle}>{subarea.name}</Text>
                                    <SimpleGrid cols={4} mt="md">
                                        {subarea.capabilities.map(cap => (
                                            <ProgramCard
                                                imageUrl={cap.imageUrl}
                                                title={cap.title}
                                                shortDescription={cap.shortDescription}
                                                experts={cap.experts}
                                                key={cap.title}
                                                description={cap.description}
                                                videoUrl={cap.videoUrl}
                                                languages={cap.languages}
                                                numberOfModules={cap.numberOfModules}
                                                breadText={cap.breadText}
                                                onOpen={() => {
                                                    customEvents.raiseUiProgramOpened(
                                                        cap.id,
                                                        cap.title,
                                                        deserializedData.client
                                                    );
                                                }}
                                            />
                                        ))}
                                    </SimpleGrid>
                                </Card>
                            </RenderIf>
                        ))}
                    </div>
                </RenderIf>
            </CardBlock>
        </Container>
    );
};
