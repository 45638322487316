import * as jsonPatch from "fast-json-patch";
import { DropResult } from "@hello-pangea/dnd";
import { DisplayModes } from "./components/ActionBar/ActionBar";
import { sortTasks } from "./components/CardContainer/CardContainer";
import type { TasksByStatus } from "./types";
import { ImprovementBoardTask, TaskPriority, TasksStatus } from "@/api-client";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

type DragAndDropStrategy = {
    modifyTask: (t: ImprovementBoardTask, track?: boolean) => ImprovementBoardTask;
    swimLaneSelector: (t: ImprovementBoardTask) => boolean;
};

type DragAndDropStrategyFactory = (swimLane: string) => DragAndDropStrategy;

const tryParseInt = (n: string): number | null => {
    const int = parseInt(n);
    if (isNaN(int)) {
        return null;
    }

    return int;
};
export const DragAndDropStrategy: { [_ in DisplayModes]: DragAndDropStrategyFactory } = {
    // @ts-ignore
    User: swimLane => {
        const userId = tryParseInt(swimLane);
        return {
            swimLaneSelector: (t: ImprovementBoardTask) => t.assignedToUserId === userId,
            modifyTask: (t, track) => {
                if (track) {
                    trackPosthogEvent("ui", "improvementboard", "changeassignee", {
                        from: t.assignedToUserId,
                        to: userId,
                    });
                }
                return { ...t, assignedToUserId: userId };
            },
        };
    },
    // @ts-ignore
    Category: swimLane => {
        const categoryId = tryParseInt(swimLane);
        return {
            swimLaneSelector: (t: ImprovementBoardTask) => t.taskCategoryId === categoryId,
            modifyTask: (t, track) => {
                if (track) {
                    trackPosthogEvent("ui", "improvementboard", "changecategory", {
                        from: t.taskCategoryId,
                        to: categoryId,
                    });
                }
                return { ...t, taskCategoryId: categoryId };
            },
        };
    },
    Priority: swimLane => {
        const priority = swimLane as TaskPriority;
        return {
            swimLaneSelector: (t: ImprovementBoardTask) => t.priority === priority,
            modifyTask: (t, track) => {
                if (track) {
                    trackPosthogEvent("ui", "improvementboard", "changepriority", { from: t.priority, to: priority });
                }
                return { ...t, priority: priority };
            },
        };
    },
};

export const Task = {
    // @ts-ignore
    toDraggableId: (id: ImprovementBoardTask["id"]) => id.toString(),
    fromDraggableId: (id: string) => parseInt(id),
} as const;

type TaskStatusWithoutSwimLane = Extract<TasksStatus, "New">;
type TaskStatusWithSwimLane = Exclude<TasksStatus, TaskStatusWithoutSwimLane>;

type DroppableWithoutSwimLane = {
    column: TaskStatusWithoutSwimLane;
};

type DroppableWithSwimLane =
    | {
          column: TaskStatusWithSwimLane;
          swimLane:
              | ImprovementBoardTask["assignedToUserId"]
              | ImprovementBoardTask["taskCategoryId"]
              | TaskPriority
              | "null";
      }
    | {
          column: TaskStatusWithSwimLane;
          swimLane: TaskPriority;
      };

export type DroppableId = DroppableWithoutSwimLane | DroppableWithSwimLane;

export const serializeDroppableId = (droppableId: DroppableId): string => {
    switch (droppableId.column) {
        case "New":
            return droppableId.column;
        default:
            return `${droppableId.column}_${droppableId.swimLane !== "null" ? droppableId.swimLane : ""}`;
    }
};

const parseDroppableId = (droppableId: string): [TasksStatus, string] => {
    const inbox: TasksStatus = "New";

    if (droppableId === inbox) {
        // @ts-ignore

        return ["New", null];
    }

    const parts = droppableId.split("_");
    const column = parts[0] as TasksStatus;
    const swimLane = parts[1];
    return [column, swimLane];
};

export const onDragAndDropEnd = (
    result: DropResult,
    tasks: ImprovementBoardTask[],
    tasksByStatus: TasksByStatus,
    displayMode: DisplayModes
) => {
    const wasCancelled = result.reason === "CANCEL" || result.destination === null;
    if (wasCancelled) {
        return;
    }

    const isSamePlace =
        // @ts-ignore
        result.destination.droppableId === result.source.droppableId &&
        // @ts-ignore
        result.destination.index === result.source.index;
    if (isSamePlace) {
        return;
    }

    const taskId = Task.fromDraggableId(result.draggableId);

    // @ts-ignore
    const [column, swimLane] = parseDroppableId(result.destination.droppableId);
    const { swimLaneSelector, modifyTask } = DragAndDropStrategy[displayMode](swimLane);

    const tasksInStatus = tasksByStatus[column];
    const tasksInBucket = sortTasks(tasksInStatus.filter(swimLaneSelector)).filter(x => x.id !== taskId);
    const task = tasks.find(x => x.id === taskId);
    // @ts-ignore
    const newTask: ImprovementBoardTask = modifyTask({ ...task, status: column }, true);

    // @ts-ignore
    const newBucket = insertTaskAtIndex(tasksInBucket, newTask, result.destination.index);
    const sortedBucket = setSortAndSort(newBucket);

    if (task!.status !== newTask.status) {
        trackPosthogEvent("ui", "improvementboard", "changecolumn", { from: task!.status, to: newTask.status });
    }

    return calculateChanges(tasks, sortedBucket);
};

export type DragResult = ReturnType<typeof onDragAndDropEnd>;

const insertTaskAtIndex = (
    tasks: ImprovementBoardTask[],
    task: ImprovementBoardTask,
    index: number
): ImprovementBoardTask[] => {
    const start = tasks.slice(0, index);
    const end = tasks.slice(index);
    const newBucket = [...start, task, ...end].map((t, i) => ({ ...t, sortIndex: i }));

    return sortTasks(newBucket);
};

const setSortAndSort = (tasks: ImprovementBoardTask[]) => sortTasks(tasks.map((t, i) => ({ ...t, sortIndex: i })));

const calculateChanges = (tasks: ImprovementBoardTask[], changedTasks: ImprovementBoardTask[]) => {
    const newBucketTaskIds = changedTasks.map(t => t.id);
    const newTasks = [...tasks.filter(x => newBucketTaskIds.indexOf(x.id) === -1), ...changedTasks];

    const patches = changedTasks.map(t => {
        const original = tasks.find(x => x.id === t.id);
        // @ts-ignore
        const patch = jsonPatch.compare(original, t);
        return {
            task: t,
            patch: patch,
        };
    });

    return {
        tasks: newTasks,
        patches: patches,
    };
};
