import { PersistedCourseAndGroupSelection } from "../../common/LocalStorageAdapter";
import { Group } from "@/api-client";
import { UrlFactory } from "../../routing/UrlFactory";

export const getLatestTeamOrProgramSelectorRoute = (
    storedValues: PersistedCourseAndGroupSelection | undefined,
    allGroups: Group[],
    currentUserId: number
): string => {
    if (storedValues === undefined) {
        return UrlFactory.programSelector.create({});
    }

    const previousSelectedGroup = allGroups
        .filter(g => g.members.map(gm => gm.userId).includes(currentUserId))
        .find(g => g.id === storedValues.groupId);

    if (previousSelectedGroup === undefined) {
        return UrlFactory.programSelector.create({});
    }

    const course = previousSelectedGroup.courseIds.find(c => c === storedValues.courseId);

    if (course === undefined) {
        return UrlFactory.programSelector.create({});
    }

    return UrlFactory.team.home.create({ groupId: previousSelectedGroup.id! });
};
