import React, { useState } from "react";
import { useStyle } from "./taskCardStyles";

export interface CreateTaskProp {
    onMouseOut?: (taskTitle: string) => void;
    onPressEnter?: (taskTitle: string) => void;
}

export const CreateCard = React.memo((props: CreateTaskProp) => {
    const { classes } = useStyle({});
    const [cardTitle, setCardTitle] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setCardTitle(value);
    };

    const handleKeydown = (e: { key: string }) => {
        if (e.key === "Enter") {
            // @ts-ignore
            props.onMouseOut(cardTitle.trim());
        }
    };

    return (
        <div className={classes.taskCard}>
            <div className={classes.content}>
                <textarea
                    className={classes.textArea}
                    autoFocus
                    maxLength={55}
                    value={cardTitle}
                    onChange={e => handleChange(e)}
                    onBlur={() =>
                        // @ts-ignore
                        props.onMouseOut(cardTitle)
                    }
                    onKeyDown={e => handleKeydown(e)}
                />
            </div>
        </div>
    );
});
