import { Video } from "@/api-client";
import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createApi } from "../../../../common/api";

/*  eslint-disable react-hooks/exhaustive-deps */

interface AddVideoResponse {
    videos: Video[];
    isPending: boolean;
}

const ModuleBlockSharedProperties = (props: {
    activeVideoId: number;
    forAdd: boolean;
    selectVideo: (val: number | null) => void;
}) => {
    const api = createApi();

    const [videoList, setVideoList] = useState<AddVideoResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        videos: null,
    });

    useEffect(() => {
        getVideos();
    }, []);
    const getVideos = () => {
        setVideoList({ ...videoList, isPending: true });
        //API start loading here
        //Store the reuslt in setCourse local state
        api.videos.query(null).then(
            result => {
                // Course loading api done and success
                setVideoList({ ...videoList, videos: result, isPending: false });
            },
            error => {
                // corse loading api is done and error
                console.log(error);
                setVideoList({ ...videoList, isPending: false });
            }
        );
    };

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <label>Video</label>
                    <select
                        name="videoId"
                        className="form-select"
                        value={props.activeVideoId ?? undefined}
                        onChange={e => props.selectVideo(e.target.value ? parseInt(e.target.value) : null)}
                    >
                        <option key="001" value="">
                            Please Select Video
                        </option>
                        {videoList.videos && videoList.videos.length > 0
                            ? videoList.videos
                                  .filter(v => v.status === "Active")
                                  .map(video => (
                                      <option key={video.id} value={video?.id}>
                                          {video.name} - {video.id}
                                      </option>
                                  ))
                            : null}
                        ;
                    </select>
                </Col>
            </Row>
        </>
    );
};

export default ModuleBlockSharedProperties;
