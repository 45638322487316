import { Badge, SelectItem } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_Row } from "mantine-react-table";
import { useMemo } from "react";
import { GetVideosDto, VideoStatuses } from "../../../api";
import { NavButton, RowActionConfig, RowActions, Table } from "@/components";
import { createTagsSelectItems } from "../../../common/createTagsSelectItems";

const ToolbarActions = () => {
    return <NavButton link="/sysadmin/video-library/add" textKey="SYSADMIN_ADD_VIDEO" />;
};

const StatusBadge = (props: { status: VideoStatuses }) => {
    if (props.status === "Active") {
        return <Badge color="green">{props.status}</Badge>;
    }

    return <Badge>{props.status}</Badge>;
};

const StatusCell = (props: { row: MRT_Row<GetVideosDto> }) => {
    return <StatusBadge status={props.row.original.status} />;
};

const videoStatusOptions: readonly VideoStatuses[] = [
    "Active",
    "Inactive",
    "InProgress",
    "Deleted",
    "Invited",
    "Disabled",
    "UploadedToBlob",
];

const videoStatusSelectItems: SelectItem[] = videoStatusOptions.map(s => ({ label: s, value: s }));

type VideosListProps = {
    videos: GetVideosDto[] | null;
};

export const VideosList = (props: VideosListProps) => {
    const { t } = useTranslate();

    const tagsSelectItems: SelectItem[] = useMemo(
        () => createTagsSelectItems(props.videos?.flatMap(v => v.tags)),
        [props.videos]
    );

    const initialFilterState: MRT_ColumnFiltersState = [{ id: "status", value: ["Active"] }];

    const columns = useMemo<MRT_ColumnDef<GetVideosDto>[]>(
        () => [
            {
                header: t("SYSADMIN_ID"),
                accessorKey: "id",
            },
            {
                header: t("SYSADMIN_NAME"),
                accessorKey: "name",
                size: 300,
            },
            {
                header: t("SYSADMIN_STATUS"),
                accessorKey: "status",
                filterVariant: "multi-select",
                Cell: StatusCell,
                mantineFilterMultiSelectProps: {
                    data: videoStatusSelectItems,
                },
            },
            {
                header: t("SYSADMIN_EXPERT"),
                accessorFn: row => row.expert ?? "",
            },
            {
                header: t("SYSADMIN_TAGS"),
                accessorFn: row => row.tags.map(t => t.trim()).join(", "),
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: tagsSelectItems,
                },
            },
            {
                header: t("SYSADMIN_URL"),
                accessorKey: "url",
            },
        ],
        [tagsSelectItems]
    );

    const rowActions: RowActionConfig<GetVideosDto> = {
        renderRowActions: ({ row }) => <RowActions.EditLink to={`/sysadmin/video-library/edit/${row.original.id}`} />,
        size: 10,
    };

    return (
        <Table
            columns={columns}
            data={props.videos}
            initialColumnFilterState={initialFilterState}
            initialSort={[{ id: "id", desc: false }]}
            rowActions={rowActions}
            toolbarActions={ToolbarActions}
        />
    );
};
