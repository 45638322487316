import { Alert } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useSignalRStatusMessage } from "../../../../common/signalr";

export const ReactSignalRStatusMessage = () => {
    const statusMessage = useSignalRStatusMessage();
    const { t } = useTranslate();

    return statusMessage ? <Alert variant="light">{t(statusMessage)}</Alert> : null;
};
