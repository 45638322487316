import update from "immutability-helper";

export interface DragItem {
    // When adding items, before saving items don't have an id. Use sortId not to confuse with "id" on entitiy
    sortId: string | number;
    sortIndex: number;
}

/**
 * Reorder and update index after a drag-drop
 * @param items
 * @param dragIndex
 * @param hoverIndex
 * @returns
 */
export function updateSortOrderOnDrag<T extends DragItem>(items: T[], dragIndex: number, hoverIndex: number): T[] {
    return update(items, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, items[dragIndex] as T],
        ],
    }).map((item, ix) => ({ ...item, sortIndex: ix }));
}
