import { uiImprovementCreated } from "./uiImprovementCreated";
import { uiTeamMessageCreated } from "./uiTeamMessageCreated";
import { uiTeamMessageChanged } from "./uiTeamMessageChanged";
import { loginWithEmailSuccess } from "./loginWithEmailSuccess";
import { loginWithEmailFailed } from "./loginWithEmailFailed";
import { defaultError } from "./raiseDefaultError";
import { changeLanguageFailed } from "./changeLanguageFailed";
import { acceptInviteTokenError } from "./acceptInviteTokenError";
import { resetPasswordError } from "./resetPasswordError";
import { uiProgramOpened } from "./uiProgramOpened";
import { videoError } from "./videoError";

export const customEvents = {
    raiseUiImprovementCreatedFrom: uiImprovementCreated,
    raiseUiTeamMessageCreated: uiTeamMessageCreated,
    raiseUiTeamMessageChanged: uiTeamMessageChanged,
    raiseLoginWithEmailSuccess: loginWithEmailSuccess,
    raiseLoginWithEmailFailed: loginWithEmailFailed,
    raiseDefaultError: defaultError,
    raiseChangeLanguageFailed: changeLanguageFailed,
    raiseAcceptInviteTokenError: acceptInviteTokenError,
    raiseResetPasswordError: resetPasswordError,
    raiseUiProgramOpened: uiProgramOpened,
    raiseVideoError: videoError,
};
