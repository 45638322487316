import React, { useMemo, useState } from "react";
import { createApi } from "../../common/api";
import { useSysAdminCourses, useSysAdminUsers } from "../SysadminContext";
import { CourseAssignmentReport } from "./reports/definitions/CourseAssignmentReport";
import { TaskReport } from "./reports/definitions/TaskReport";
import { TaskPerUserReport } from "./reports/definitions/TasksPerUserReport";
import { IReport } from "./reports/IReport";
import { UserReport } from "./reports/definitions/UserReport";
import { createStyles, Group, Text } from "@mantine/core";
import { CardBlock } from "../../components/CardBlock";
import { PrimaryButton } from "../../components/Button/PrimaryButton";

const useStyles = createStyles(theme => ({
    card: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },

    item: {
        "& + &": {
            paddingTop: theme.spacing.sm,
            marginTop: theme.spacing.sm,
            borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
        },
    },

    title: {
        lineHeight: 1,
    },
}));

type ReportDefinition = {
    isLoading: boolean;
    report: IReport;
};

const ReportRow = ({ rd }: { rd: ReportDefinition }) => {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const download = async () => {
        setIsLoading(true);
        await rd.report.downloadReport().finally(() => setIsLoading(false));
    };

    return (
        <Group position="apart" className={classes.item} noWrap spacing="md">
            <div>
                <Text>{rd.report.name}</Text>
                <Text size="xs" color="dimmed">
                    {rd.report.description}
                </Text>
            </div>
            <PrimaryButton onClick={download} loading={rd.isLoading || isLoading}>
                Download
            </PrimaryButton>
        </Group>
    );
};

type ReportCardProps = {
    reports: ReportDefinition[];
};

const ReportsCard = ({ reports }: ReportCardProps) => {
    const { classes } = useStyles();

    const items = reports.map((item, index) => <ReportRow key={index} rd={item} />);

    return (
        <CardBlock classNames={{ card: classes.card }} mb="xl">
            <Text size="lg" mb="xl" className={classes.title} weight={500}>
                Statistics &amp; Reports
            </Text>
            {items}
        </CardBlock>
    );
};

export const Statistics = () => {
    const api = createApi();
    const allCourses = useSysAdminCourses();
    const allUsers = useSysAdminUsers();

    const reportDefinitions: ReportDefinition[] = useMemo(() => {
        return [
            { isLoading: false, report: new TaskReport(api.reports) },
            // @ts-ignore

            { isLoading: false, report: new TaskPerUserReport(api.reports, allUsers) },
            {
                isLoading: allCourses === undefined,
                report:
                    allCourses === undefined
                        ? new CourseAssignmentReport(api.reports, [])
                        : new CourseAssignmentReport(api.reports, allCourses),
            },
            { isLoading: false, report: new UserReport(api.newReports) },
        ];
    }, [allCourses]);

    return (
        <>
            <ReportsCard reports={reportDefinitions} />
        </>
    );
};
