import { Client } from "@/api-client";
import * as jsonpatch from "fast-json-patch";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { createApi } from "../../../common/api";
import { useOrganisationContext, useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import { showToast } from "../../../components/Notify";
import FileUploader from "../../Components/FileUploader";
import "./style.scss";
import { SecondaryButton } from "../../../components/Button/SecondaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface OrganisationalValidationsError {
    name: string | null;
    logo: string | null;
}

interface OrganisationDetailsFormProps {
    error?: (error: OrganisationalValidationsError) => void;
    isPending?: (isPending: boolean) => void;
    /* @ts-ignore */
    saveOrganisationDetails?: (saveOrganisationDetails) => void;
    redirect: boolean;
}

export const OrganisationDetailsForm = React.forwardRef((props: OrganisationDetailsFormProps, ref) => {
    const { t } = useTranslate();
    const client = useOrganisationContext().client;
    const organisationDispatch = useOrganisationContextDispatch();
    const api = createApi();
    const history = useHistory();

    // Create local state with a clone by using spread
    const [clientState, setClientState] = useState<Client>();

    // Hold the image
    /* @ts-ignore */
    const [image, setImage] = useState<string>(client?.logo);

    // Holds the uploaded image file reference
    /* @ts-ignore */
    const [uploadedImage, setUploadedImage] = useState<File>(null);

    // Populate default when unsaved orignal client is changed
    useEffect(() => {
        if (client != null) {
            setClientState({ ...client });
            /* @ts-ignore */
            setImage(client.logo);
        }
    }, [client]);

    // Holds error messages
    const [error, setError] = useState<OrganisationalValidationsError>({ name: null, logo: null });

    const LogoErrorMsg = t("ORGANISATION_DETAILS_LOGO_ERROR");
    const NameErrorMsg = t("ORGANISATION_DETAILS_NAME_ERROR");

    const fileUploader = (uploadFile: File) => {
        //console.log(uploadFile);
        setImage(URL.createObjectURL(uploadFile));

        // Save file reference until user submit form
        setUploadedImage(uploadFile);
    };

    const removeImage = () => {
        /* @ts-ignore */
        setImage(null);
        setError(prevState => ({ ...prevState, logo: LogoErrorMsg }));
        //setIsFormChanged(true);
    };

    useEffect(() => {
        if (error) {
            /* @ts-ignore */
            props.error(error);
        }
    }, [error]);

    const onNameChange = (value: string) => {
        /* @ts-ignore */
        setClientState(prev => ({ ...prev, name: value }));

        // QA: Duplicate code, refactor

        if (value) {
            setError(prevState => ({ ...prevState, name: null }));
        } else {
            setError(prevState => ({ ...prevState, name: NameErrorMsg }));
        }
    };

    /**
     * Validate form
     * @returns True if valid
     */
    const validate = (): boolean => {
        if (!clientState) {
            return false;
        }

        if (clientState.name === null || clientState.name === "") {
            setError(prevState => ({ ...prevState, name: NameErrorMsg }));
        } else {
            setError(prevState => ({ ...prevState, name: null }));
        }

        if (image === null) {
            setError(prevState => ({ ...prevState, logo: LogoErrorMsg }));
        } else {
            setError(prevState => ({ ...prevState, logo: null }));
        }

        return error.name == null && error.logo == null;
    };

    useEffect(() => {
        // Validate when data is changed
        validate();

        // Disable next arrow if something has changed
    }, [image, clientState?.name]);

    useImperativeHandle(ref, () => ({
        saveOrganisation() {
            saveOrganisationDetails();
        },
    }));

    const saveOrganisationDetails = () => {
        if (!validate()) {
            return;
        }

        // Create patch by comparing original client with then client in our state
        /* @ts-ignore */
        const patch = jsonpatch.compare(client, clientState);

        /*
         1. Upload image and wait for result first if set
         2. Update client item
         */

        const handleImage = (): Promise<string> => {
            if (uploadedImage) {
                /* @ts-ignore */
                props.isPending(true);
                // Save image and wait for result
                return api.images.post({ fileName: uploadedImage.name, data: uploadedImage });
            } else {
                /* @ts-ignore */
                props.isPending(false);
                /* @ts-ignore */
                return Promise.resolve<string>(null);
            }
        };

        handleImage()
            .then(imageUrl => {
                /* @ts-ignore */
                props.isPending(true);
                if (imageUrl) {
                    // Add new image url to patch call
                    patch.push({ path: "/logo", op: "replace", value: imageUrl });
                }

                if (patch.length > 0) {
                    // Patch the client with new data
                    /* @ts-ignore */
                    return api.clients.patch(client.id, patch);
                } else {
                    /* @ts-ignore */
                    return Promise.resolve<Client>(null);
                }
            })
            .then(
                updatedClient => {
                    organisationDispatch(s => ({
                        ...s,
                        client: updatedClient,
                    }));
                    /* @ts-ignore */
                    props.isPending(false);
                    if (props.redirect) {
                        // Move forward
                        history.push("/account-owner/organisation-planning/groups");
                    }
                },
                _ => {
                    showToast("Something going wrong. Please try again later.", "error");
                    /* @ts-ignore */
                    props.isPending(false);
                }
            );
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <label htmlFor={`formControl-name`} className="form-label">
                        {t("ORGANISATION_DETAILS_NAME")}
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={clientState?.name || ""}
                        className="form-control"
                        onChange={e => onNameChange(e.target.value)}
                    />
                    {error && error["name"] && <DisplayInlineError error={error["name"]} />}
                </Col>
            </Row>
            <Row className="mt-4 mb-5">
                <Col md={4}>
                    <label htmlFor={`formControl-logo`} className="form-label">
                        {t("ORGANISATION_DETAILS_LOGO")}
                    </label>
                    {image !== null && (
                        <div>
                            <img src={image} alt="" className="client-logo img-responsive" />
                        </div>
                    )}
                    <div>{error && error["logo"] && <DisplayInlineError error={error["logo"]} />}</div>
                </Col>
                <Col md={2} className="text-left mt-4">
                    <FileUploader
                        acceptedFiles="image/jpg, image/png, image/jpeg"
                        handleFile={(uploadFile: File) => fileUploader(uploadFile)}
                    />
                    {image !== "" && image !== null && (
                        <SecondaryButton onClick={() => removeImage()}>{t("COMMON_DELETE")}</SecondaryButton>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
});

OrganisationDetailsForm.displayName = "OrganisationDetailsForm";
