﻿import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "./Button/PrimaryButton";
import { EventName } from "./Button/Button";

export const NavButton = (props: { link: string; textKey: string; eventName?: EventName }) => {
    const { t } = useTranslate();

    return (
        <Link to={props.link}>
            <PrimaryButton eventName={props.eventName}>{t(props.textKey)}</PrimaryButton>
        </Link>
    );
};
