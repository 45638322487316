import { useTranslate } from "@tolgee/react";
import { DisplayModes } from "../ActionBar/ActionBar";
import { BoardLaneDefinition } from "../../types";
import { useBoardContext } from "../../BoardContext";
import UserAvatar from "../../../../components/UserAvatar";

export const BoardLaneDefinitions: { [Property in DisplayModes]: BoardLaneDefinition } = {
    User: {
        SwimLane: (props: { swimLane: any }) => {
            const { t } = useTranslate();
            const context = useBoardContext();

            if (props.swimLane === "null") {
                return <p>{t("BOARDS_GENERAL")}</p>;
            }

            const member = context.state.activeUsers.find(u => u.id === props.swimLane);
            return <UserAvatar className="flex-column" user={member} displayName />;
        },
    },
    Priority: {
        SwimLane: (props: { swimLane: any }) => (
            <>
                <p>{props.swimLane}</p>
            </>
        ),
    },
    Category: {
        SwimLane: (props: { swimLane: any }) => {
            const { t } = useTranslate();
            const context = useBoardContext();

            if (props.swimLane === "null") {
                return <p>{t("BOARD_COMMON_UNCATEGORISED")}</p>;
            }

            const categoryFilter = context.state.categoryFilters.items.find(c => c.item.id === props.swimLane);
            return (
                <div>
                    <h6>
                        {
                            // @ts-ignore

                            categoryFilter.item.name
                        }
                    </h6>
                    <div className="description">
                        {
                            // @ts-ignore

                            categoryFilter.item.description
                        }
                    </div>
                </div>
            );
        },
    },
};
