import React from "react";
import { createStyles, Flex, Text } from "@mantine/core";

export type SelectItemWithIndicatorProps = {
    label: string;
    color: string;
};

export const SelectItemWithIndicator = React.forwardRef<HTMLDivElement, SelectItemWithIndicatorProps>(
    ({ label, color, ...others }, ref: React.Ref<HTMLDivElement>) => {
        const useSelectItemWithIndicatorStyles = createStyles(() => ({
            colorIndicator: {
                alignSelf: "stretch",
                flexShrink: 0,
                width: "8px",
                backgroundColor: color,
                borderRadius: "2px",
                marginLeft: "2px",
            },
        }));

        const { classes } = useSelectItemWithIndicatorStyles();

        return (
            <Flex direction={"row"} align={"center"} justify={"flex-start"} gap={"xs"} ref={ref} {...others}>
                <div className={classes.colorIndicator} />
                <Text fz={"md"}>{label}</Text>
            </Flex>
        );
    }
);
