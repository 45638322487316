﻿import { createApi } from "@/common";
import { useOrganisationContextDispatch } from "../../common/OrganisationContext";
import { useCurrentGroupCourseContextDispatch } from "../../common/courses/context";
import { Api } from "../../common/api";
import { Group, User } from "@/api-client";
import { UserHasParticipantRole } from "../../common/user/utils";

export const useHandleTeamMembership = () => {
    const api = createApi();
    const organisationDispatch = useOrganisationContextDispatch();
    const courseGroupDispatch = useCurrentGroupCourseContextDispatch();

    const updateMembershipStatus = (api: Api, group: Group, user: User) => {
        const patch = [
            {
                op: "replace",
                path: "/inviteStatus",
                value: "Accepted",
            },
        ];
        return api.groupMembership.patch(user.id, group.id!, patch);
    };

    const updateGroupMembershipStatus = async (currentUser: User, selectedGroup: Group) => {
        const getGroupMembershipStatus = (selectedGroup: Group, userId: number) => {
            return selectedGroup?.members.find(m => m.userId === userId)?.inviteStatus;
        };
        const membershipStatus = getGroupMembershipStatus(selectedGroup, currentUser.id);

        if (
            membershipStatus !== "NotSent" &&
            membershipStatus !== "Accepted" &&
            UserHasParticipantRole(currentUser, selectedGroup)
        ) {
            await updateMembershipStatus(api, selectedGroup, currentUser).then(
                response => {
                    const { members, ...rest } = selectedGroup;
                    const updatedGroup: Group = {
                        ...rest,
                        members: members.map(member => {
                            return member.userId === currentUser.id
                                ? {
                                      ...member,
                                      inviteStatus: response.inviteStatus,
                                  }
                                : member;
                        }),
                    };
                    /** Update member in Organisation Context in Groups */
                    organisationDispatch(s => ({
                        ...s,
                        groups: s.groups?.map(g =>
                            g.id === response.groupId
                                ? {
                                      ...g,
                                      members: g.members.map(m =>
                                          m.userId === response.userId
                                              ? {
                                                    ...m,
                                                    inviteStatus: response.inviteStatus,
                                                }
                                              : m
                                      ),
                                  }
                                : g
                        ),
                    }));
                    /** Update member in Common Context in Group */
                    courseGroupDispatch(s => ({
                        ...s,
                        group: updatedGroup,
                    }));
                },
                error => {
                    console.log(error);
                }
            );
        }
    };

    return { updateGroupMembershipStatus };
};
