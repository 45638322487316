import React, { useEffect } from "react";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import StyledCheckbox from "../../../components/StyledCheckbox";
import Input from "react-phone-number-input/input";
import { EmployeesToBeAddedModel, ErrorType, ValidateUserModel } from ".";
import { useTranslate } from "@tolgee/react";
import { validateEmail, validateMobile } from "../../../helpers/helpers";
import { createApi } from "../../../common/api";
import { getUserByEmailOrPhone } from "./getUserByEmailOrPhone";
import { User } from "@/api-client";

/*  eslint-disable react-hooks/exhaustive-deps */

type PreviewGroupEmployeeRowProps = {
    employee: EmployeesToBeAddedModel;
    removeEmployee: () => void;
    setEmployee: (employee: EmployeesToBeAddedModel) => void;
    checkUserGroupOrAlreadyExist: (email: string, mobilePhone: string) => ValidateUserModel;
    hasDuplicateEmail: (email: string) => boolean;
    hasDuplicatePhone: (email: string) => boolean;
    users: User[];
};

export const PreviewGroupEmployeeRow = (props: PreviewGroupEmployeeRowProps) => {
    const { t } = useTranslate();
    const emp = props.employee;
    const api = createApi();

    const validateUser = (userToValidate: EmployeesToBeAddedModel) => {
        userToValidate.isValid = true;
        userToValidate.state = "New";
        userToValidate.errorMessage = "";
        /* @ts-ignore */
        userToValidate.errorType = null;
        userToValidate.isValid = true;

        if (emp.user.email === "" && emp.user.mobilePhone === "") {
            userToValidate.errorMessage = t("PREVIEW_ENTER_EMAIL_PASSWORD");
            userToValidate.state = "CannotCreate";
            userToValidate.errorType = "Both";
            userToValidate.isValid = false;
        } else {
            /* @ts-ignore */
            const user = getUserByEmailOrPhone(emp.user.email, emp.user.mobilePhone, props.users);
            userToValidate.user = { ...emp.user, ...user };

            if (emp.user.email) {
                const hasDuplicateEmail = props.hasDuplicateEmail(emp.user.email);
                const invalidString = "áéíóäëöúàèììù";
                /* @ts-ignore */
                const checkEmailContainsInvalidString = invalidString.split("").some(g => emp.user.email.includes(g));

                if (checkEmailContainsInvalidString) {
                    userToValidate.state = "CannotCreate";
                    userToValidate.errorMessage = t("PREVIEW_USER_EMAIL_INVALID_CHARACTERS");
                    userToValidate.errorType = "Email";
                    userToValidate.isValid = false;
                } else if (!validateEmail(emp.user.email)) {
                    userToValidate.state = "CannotCreate";
                    userToValidate.errorMessage = t("LOGIN_EMAIL_VALID");
                    userToValidate.errorType = "Email";
                    userToValidate.isValid = false;
                } else if (hasDuplicateEmail) {
                    userToValidate.state = "CannotCreate";
                    userToValidate.errorMessage = t("PREVIEW_EMAIL_ALREADY_EXIST");
                    userToValidate.errorType = "Email";
                    userToValidate.isValid = false;
                } else {
                    const userResult: ValidateUserModel = props.checkUserGroupOrAlreadyExist(
                        emp.user.email,
                        /* @ts-ignore */
                        emp.user.mobilePhone
                    );
                    userToValidate = { ...userToValidate, ...userResult };
                }
            }

            if (emp.user.mobilePhone) {
                const hasDuplicatePhone = props.hasDuplicatePhone(emp.user.mobilePhone);
                if (!validateMobile(emp.user.mobilePhone)) {
                    userToValidate.state = "CannotCreate";
                    userToValidate.errorMessage = t("PREIVEW_CORRECT_PHONE_NUMBER");
                    userToValidate.errorType = "Mobile Phone";
                    userToValidate.isValid = false;
                } else if (hasDuplicatePhone) {
                    userToValidate.state = "CannotCreate";
                    userToValidate.errorMessage = t("PREVIEW_MOBILE_ALREADY_EXIST");
                    userToValidate.errorType = "Mobile Phone";
                    userToValidate.isValid = false;
                } else {
                    const userResult: ValidateUserModel = props.checkUserGroupOrAlreadyExist(
                        /* @ts-ignore */
                        emp.user.email,
                        emp.user.mobilePhone
                    );
                    userToValidate = { ...userToValidate, ...userResult };
                }
            }
        }
        props.setEmployee(userToValidate);
        return userToValidate;
    };

    useEffect(() => {
        validateUser(props.employee);
    }, [props.employee.user.email, props.employee.user.mobilePhone]);

    useEffect(() => {
        const validationResults = validateUser(props.employee);
        const { user, tempId, ...rest } = validationResults;
        /* @ts-ignore */
        const result = props.checkUserGroupOrAlreadyExist(user.email, user.mobilePhone);
        if (result.state === "New") {
            api.users.validate(validationResults.user).then(response => {
                const result =
                    validationResults.state === "New"
                        ? {
                              errorMessage: response.errorMessage !== null ? t(response.errorMessage) : "",
                              isValid: response.isValid,
                              errorType: !response.isValid
                                  ? user.email
                                      ? "Email"
                                      : ("Mobile Phone" as ErrorType)
                                  : null,
                          }
                        : rest;
                /* @ts-ignore */
                props.setEmployee({ ...emp, ...result });
            });
        }
    }, []);

    return (
        <>
            <tr className="user-row">
                <td>
                    <input
                        onChange={e => props.setEmployee({ ...emp, user: { ...emp.user, firstName: e.target.value } })}
                        disabled={emp.disabled}
                        type="text"
                        value={emp.user.firstName === null ? undefined : emp.user.firstName}
                        className={`form-control`}
                        name="firstName"
                    />
                </td>
                <td>
                    <input
                        onChange={e => props.setEmployee({ ...emp, user: { ...emp.user, lastName: e.target.value } })}
                        disabled={emp.disabled}
                        value={emp.user.lastName === null ? undefined : emp.user.lastName}
                        type="text"
                        className="form-control"
                        name="lastName"
                    />
                </td>
                <td>
                    <input
                        onChange={e => {
                            props.setEmployee({ ...emp, user: { ...emp.user, email: e.target.value } });
                            //validateUser( { ...emp, user: { ...emp.user, email: e.target.value }} );
                        }}
                        value={emp.user.email}
                        disabled={emp.disabled}
                        type="text"
                        className={`form-control ${
                            emp.errorType === "Email" || emp.errorType === "Both" ? "is-invalid" : ""
                        }`}
                        name="email"
                    />
                </td>
                <td>
                    <Input
                        autoComplete={"off"}
                        value={emp.user.mobilePhone}
                        name="mobilePhone"
                        disabled={emp.disabled}
                        onChange={phone => {
                            props.setEmployee({ ...emp, user: { ...emp.user, mobilePhone: phone } });
                            //validateUser( { ...emp, user: { ...emp.user, mobilePhone: phone } } );
                        }}
                        className={`form-control ${
                            emp.errorType === "Mobile Phone" || emp.errorType === "Both" ? "is-invalid" : ""
                        }`}
                    />
                </td>
                <td>
                    <StyledCheckbox
                        onChange={isParticipant => props.setEmployee({ ...emp, user: { ...emp.user, isParticipant } })}
                        checked={emp.user.isParticipant}
                        className="mt-2"
                        disabled={emp.info ? false : emp.disabled}
                    />
                </td>
                <td>
                    <StyledCheckbox
                        onChange={isGroupLeader => props.setEmployee({ ...emp, user: { ...emp.user, isGroupLeader } })}
                        disabled={emp.info ? false : emp.disabled}
                        checked={emp.user.isGroupLeader}
                        className="mt-2"
                    />
                </td>
                <td>
                    <button onClick={e => props.removeEmployee()} className="btn btn-link">
                        <i className="fas fa-times" />
                    </button>
                </td>
            </tr>
            {emp.errorMessage && (
                <tr>
                    <td colSpan={7} className="text-left pt-0">
                        <DisplayInlineError error={emp.errorMessage} />
                    </td>
                </tr>
            )}
            {emp.info && (
                <tr>
                    <td colSpan={7} className="text-left text-muted pt-0">
                        {emp.info}
                    </td>
                </tr>
            )}
        </>
    );
};
