import { useEffect, useMemo, useState } from "react";
import { NativeSelect, Stack } from "@mantine/core";
import { useHistory, useCurrentUser, createApi } from "@/common";
import { useAnswers, useCurrentCourse, useCurrentGroup } from "../common/courses/context";
import { ChapterData, getChapterData, getQuestionData, OptionsData } from "./helper";
import { Group, Module } from "@/api-client";
import RenderIf from "../components/render-if/render-if";
import { TransformationDetails } from "./TransformationDetails";
import { TransformationOverview } from "./TransformationOverview";
import { ProgramRecommendations } from "./ProgramRecommendations";
import { trackPosthogEvent } from "../analytics/customEvents/trackPosthogEvent";

interface TransformationProps {
    moduleId: number | undefined;
}

export const Transformation = (props: TransformationProps): JSX.Element => {
    const initialGroup = useCurrentGroup();
    const currentCourse = useCurrentCourse();
    const answers = useAnswers();
    const history = useHistory();
    const currentUser = useCurrentUser();
    const api = createApi();

    const [groupsTakingCurrentCourse, setGroupsTakingCurrentCourse] = useState<Group[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(undefined);

    // Redirect to group home if group isn't allowed to access implementation/transformation
    useEffect(() => {
        if (!initialGroup) {
            return;
        }

        // @ts-ignore
        if (initialGroup.courseIds.length === 0) {
            console.info("No courses in group, redirecting to start");
            history.push(`/groups/${initialGroup?.id}`);
        }

        if (!currentCourse) {
            return;
        }

        if (currentCourse.courseType !== "AssessmentCourse") {
            console.info("Course is not assessment, redirecting to start");
            history.push(`/groups/${initialGroup?.id}`);
        }

        api.groups.query(null, null, currentCourse.id, null).then(groups => setGroupsTakingCurrentCourse(groups));

        setSelectedGroup(initialGroup);
    }, [currentCourse, initialGroup]);

    const chapterData = useMemo((): ChapterData[] => {
        if (
            answers === undefined ||
            currentCourse === undefined ||
            currentUser === undefined ||
            selectedGroup === undefined
        ) {
            return [];
        }

        const chapters = currentCourse.chapters ?? [];
        return getChapterData(chapters, currentUser, selectedGroup, answers);
    }, [currentUser, selectedGroup, answers, currentCourse]);

    const questionData = useMemo((): OptionsData[] => {
        if (
            answers === undefined ||
            currentCourse === undefined ||
            currentUser === undefined ||
            selectedGroup === undefined
        ) {
            return [];
        }

        const chapters = currentCourse.chapters ?? [];
        // @ts-ignore
        const module: Module = chapters
            .flatMap(x => x.modules)
            // @ts-ignore

            .find(m => m.id === props.moduleId);

        if (module === undefined) {
            return [];
        }

        return getQuestionData(module, currentUser, selectedGroup, answers);
    }, [currentUser, selectedGroup, answers, props.moduleId]);

    return (
        <Stack>
            <RenderIf show={chapterData.length > 0}>
                <NativeSelect
                    // @ts-ignore

                    data={groupsTakingCurrentCourse.map(x => ({ value: x.id.toString(), label: x.name }))}
                    // @ts-ignore

                    value={selectedGroup?.id.toString()}
                    onChange={x => {
                        trackPosthogEvent("ui", "assessment", "changeteam");
                        // @ts-ignore
                        return setSelectedGroup(groupsTakingCurrentCourse.find(g => g.id === +x.currentTarget.value));
                    }}
                    icon={<i className="icon fa fa-users" />}
                    mb="sm"
                    w={300}
                />

                <TransformationOverview
                    // @ts-ignore

                    moduleId={props.moduleId}
                    // @ts-ignore

                    groupId={initialGroup?.id}
                    chapterData={chapterData}
                />

                <RenderIf show={questionData.length > 0}>
                    <TransformationDetails
                        // @ts-ignore

                        moduleId={props.moduleId}
                        questionData={questionData}
                    />
                </RenderIf>

                <ProgramRecommendations chapterData={chapterData} />
            </RenderIf>
        </Stack>
    );
};
