import { Outlet, RouteObject } from "react-router-dom";
import { EXPERT_ROLE, SYSADMIN_ROLE } from "../constants";
import AddCourse from "../sysadmin/courses/CourseAdmin/AddCourse";
import Blocks from "../sysadmin/courses/EditBlock/Blocks";
import AdditionalResources from "../sysadmin/courses/EditBlock/Blocks/AdditionalResources/AdditionalResources";
import EditAdditionalResources from "../sysadmin/courses/EditBlock/Blocks/AdditionalResources/EditAdditionalResources";
import EditChapter from "../sysadmin/courses/EditChapters";
import AddModule from "../sysadmin/courses/EditChapters/AddModule";
import EditCourse from "../sysadmin/courses/EditCourse";
import AddChapter from "../sysadmin/courses/EditCourse/AddChapter";
import EditModule from "../sysadmin/courses/EditModules";
import QuestionEditorBlock from "../sysadmin/courses/ModuleBlocks/QuestionsEditorBlock";
import ContentIntroductionVideos from "../sysadmin/courses/IntroductionVideos/ContentIntroductionVideos";
import { CoursesLayout } from "../sysadmin/courses/CoursesLayout";
import { protectedRoute } from "./routebuilder";
import { UrlFactory } from "./UrlFactory";
import { ProgramsAndCapabilitiesPage } from "../pages/SysAdmin/ProgramsAndCapabilities/ProgramsAndCapabilitiesPage";

const SysAdminRole = SYSADMIN_ROLE;

export const SysAdminCourseRoutes: RouteObject = {
    path: UrlFactory.sysadmin.programsAndCapabilities.pattern,
    element: <Outlet />,
    children: [
        protectedRoute("", <ProgramsAndCapabilitiesPage />, [SysAdminRole, EXPERT_ROLE]),
        {
            element: (
                <CoursesLayout>
                    <Outlet />
                </CoursesLayout>
            ),
            children: [
                protectedRoute("/sysadmin/courses/:courseId/edit", <EditCourse />, [SysAdminRole, EXPERT_ROLE]),
                protectedRoute("/sysadmin/courses/:courseId/chapter/:chapterId/edit", <EditChapter />, [
                    SysAdminRole,
                    EXPERT_ROLE,
                ]),
                protectedRoute("/sysadmin/courses/:courseId/chapter/add", <AddChapter />, [SysAdminRole, EXPERT_ROLE]),
                protectedRoute("/sysadmin/courses/:courseId/chapter/:chapterId/module/add", <AddModule />, [
                    SysAdminRole,
                    EXPERT_ROLE,
                ]),
                protectedRoute("/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/edit", <EditModule />, [
                    SysAdminRole,
                    EXPERT_ROLE,
                ]),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/add",
                    <Blocks />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId/edit",
                    <Blocks />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId/additional-resources/add",
                    <AdditionalResources />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId/additional-resources/:resourceId/edit",
                    <EditAdditionalResources />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId/question/add",
                    <QuestionEditorBlock />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
                protectedRoute(
                    "/sysadmin/courses/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId/question/:questionId/edit",
                    <QuestionEditorBlock />,
                    [SysAdminRole, EXPERT_ROLE]
                ),
            ],
        },
        protectedRoute("/sysadmin/courses/add", <AddCourse />, [SysAdminRole, EXPERT_ROLE]),
        protectedRoute("/sysadmin/courses/:courseId/introduction-videos/add", <ContentIntroductionVideos />, [
            SysAdminRole,
        ]),
        protectedRoute(
            "/sysadmin/courses/:courseId/introduction-videos/edit/:introductionVideoId",
            <ContentIntroductionVideos />,
            [SysAdminRole, EXPERT_ROLE]
        ),
    ],
};
