import { useModals } from "@mantine/modals";
import { OpenConfirmModal } from "@mantine/modals/lib/context";
import { useTranslate } from "@tolgee/react";

export const useDeleteConfirmationModal = (): {
    open: (props: Pick<OpenConfirmModal, "onConfirm" | "title">) => string;
} => {
    const modal = useModals();
    const { t } = useTranslate();

    return {
        open: ({ onConfirm, title }) =>
            modal.openConfirmModal({
                title: typeof title === "string" ? t(title) : title,
                centered: true,
                labels: { confirm: t("COMMON_YES"), cancel: t("COMMON_NO") },
                confirmProps: { miw: "100px", color: "red" },
                cancelProps: { miw: "100px" },
                onConfirm: onConfirm,
            }),
    };
};
