import { Link } from "react-router-dom";
import { ActionIcon, createStyles } from "@mantine/core";
import React from "react";
import { WithChildren } from "../common/StateWrapper";

const useStyles = createStyles(() => ({
    noDecorators: { textDecoration: "none", width: "100%", display: "flex", justifyContent: "center" },
}));

export const LinkAction = (props: { link: string } & WithChildren) => {
    const { classes } = useStyles();
    return (
        <Link to={props.link} className={classes.noDecorators}>
            <ActionIcon>{props.children}</ActionIcon>
        </Link>
    );
};
