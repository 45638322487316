import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { createPatch } from "common/patchHelper";
import { Grid, Stack } from "@mantine/core";
import { EditUserStatus } from "components/EditUserStatus";
import { createApi, yupExtensions } from "@/common";
import { AppLoader, CardBlock, FormInput, showToast, Form, box, InitialFormValue, Section } from "@/components";
import { TeamMembership } from "./TeamMembership";
import { ImageInput } from "../../ImageInput";
import { GetUserDto } from "./types";
import { ACCOUNT_OWNER_ROLE } from "../../../../constants";

const userSchema = Yup.object({
    title: yupExtensions.nullableNonEmptyString(),
    email: yupExtensions
        .emailValidator("PROFILE_INVALID_EMAIL")
        .test("email", "PROFILE_EMAIL_OR_MOBILE_PHONE_REQUIRED", function (value) {
            const { mobilePhone } = this.parent;

            if (mobilePhone === undefined) {
                return value !== undefined;
            }

            return true;
        }),
    firstName: yupExtensions.nullableNonEmptyString(),
    lastName: yupExtensions.nullableNonEmptyString(),
    image: yupExtensions.nullableNonEmptyString(),
    mobilePhone: yupExtensions
        .phoneNumberValidator("PROFILE_INVALID_PHONE_NUMBER")
        .test("mobilePhone", "PROFILE_EMAIL_OR_MOBILE_PHONE_REQUIRED", function (value) {
            const { email } = this.parent;

            if (email === undefined) {
                return value !== undefined;
            }

            return true;
        }),
    isAccountOwner: Yup.bool(),
});

type FormUser = Yup.InferType<typeof userSchema>;

type EditUserProps = {
    userId: number;
};

const getInitialValues = (user: GetUserDto | null): InitialFormValue<FormUser> => {
    if (user == null) {
        return {
            firstName: "",
            lastName: "",
            email: "",
            title: "",
            mobilePhone: "",
            image: "",
            isAccountOwner: false,
        };
    }

    return {
        firstName: box(user.firstName),
        lastName: box(user.lastName),
        email: box(user.email),
        title: box(user.title),
        mobilePhone: box(user.mobilePhone),
        image: box(user.image),
        isAccountOwner: user.roles.any(r => r === ACCOUNT_OWNER_ROLE),
    };
};

export const EditUserComponent = (props: EditUserProps) => {
    const api = createApi();
    const { t } = useTranslate();
    const [user, setUser] = useState<GetUserDto | null>(null);

    const getUserDetails = (userId: number) => {
        api.users
            .get(userId)
            .then(response => {
                setUser({
                    title: response.title,
                    email: response.email,
                    image: response.image,
                    status: response.status,
                    mobilePhone: response.mobilePhone,
                    lastName: response.lastName,
                    firstName: response.firstName,
                    clientId: response.clientId,
                    id: response.id,
                    hasAcceptedInvite: response.hasAcceptedInvite,
                    roles: response.roles,
                } as GetUserDto);
            })
            .catchWithToast();
    };

    useEffect(() => {
        getUserDetails(props.userId);
    }, []);

    if (user === null) {
        return <AppLoader loading />;
    }

    const initialValues = getInitialValues(user);

    const unbox = (value: string | undefined | null): string | null => {
        if (value === "" || value === undefined) {
            return null;
        }

        return value;
    };

    const updateUser = (formValues: FormUser) => {
        const patch = createPatch(user, x => {
            x.firstName = formValues.firstName;
            x.lastName = formValues.lastName;
            x.mobilePhone = unbox(formValues.mobilePhone);
            x.image = formValues.image;
            x.email = unbox(formValues.email);
            x.title = formValues.title;
            x.roles = formValues.isAccountOwner === true ? [ACCOUNT_OWNER_ROLE] : [];
        });

        return api.users
            .patch(props.userId, patch)
            .then(() => showToast(t("ACCOUNT_OWNER_USERS_UPDATE_SUCCESS_MESSAGE"), "success"))
            .catchWithToastAsync();
    };

    return (
        <CardBlock>
            <Form<FormUser>
                submitLabel="COMMON_SAVE"
                initialValues={initialValues}
                validationSchema={userSchema}
                eventName={{ object: "ao_edituser", action: "save" }}
                onSubmit={async values => {
                    await updateUser(values);
                }}
            >
                <Grid>
                    <Grid.Col span={6}>
                        <Stack>
                            <FormInput.Text fieldName="title" label="ACCOUNTOWNER_UPDATE_FORM_TITLE" />
                            <FormInput.Text fieldName="firstName" label="ACCOUNTOWNER_UPDATE_FORM_FIRST_NAME" />
                            <FormInput.Text fieldName="lastName" label="ACCOUNTOWNER_UPDATE_FORM_LAST_NAME" />
                            <FormInput.Text fieldName="email" label="ACCOUNTOWNER_UPDATE_FORM_EMAIL" />
                            <FormInput.Text
                                fieldName="mobilePhone"
                                label="ACCOUNTOWNER_UPDATE_FORM_MOBILEPHONE"
                                description="PROFILE_PHONE_NUMBER_HINT"
                            />
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={2} />
                    <Grid.Col span={4}>
                        <ImageInput
                            label="ACCOUNTOWNER_UPDATE_FORM_IMAGE"
                            fieldName="image"
                            imageProps={{ height: 100, width: 100 }}
                        />
                        <Section mt="xl" title="ACCOUNTOWNER_ROLES">
                            <FormInput.Checkbox fieldName="isAccountOwner" label="ROLES_ACCOUNT_OWNER" />
                        </Section>
                    </Grid.Col>
                </Grid>
            </Form>
            <Section mt="xl" title="ACCOUNTOWNER_UPDATE_FORM_ACCOUNT_STATUS">
                <EditUserStatus
                    inactivateUserHelperText="ACCOUNTOWNER_USER_STATUS_HELPTEXT"
                    user={user}
                    onUserStatusChanged={() => getUserDetails(props.userId)}
                />
            </Section>

            <Section mt="xl" title="EDITEMPLOYEE_TEAM_MEMBERSHIP">
                <TeamMembership userId={props.userId} />
            </Section>
        </CardBlock>
    );
};
