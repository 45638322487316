import { useTranslate } from "@tolgee/react";
import { useEffect, useState } from "react";
import { Question } from "@/api-client";
import { createApi } from "../../../../common/api";
import { Breadcrumbs } from "../../../../components/BreadCrumbs";
import { ContentLabel } from "../../../../components/ContentLabel";
import RenderIf from "../../../../components/render-if/render-if";
import { AppLoader } from "../../../../components/Spinner";
import Sidebar from "../../../components/Sidebar";
import { CourseMenu } from "../../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../../helper";
import FreeTextAnswers from "./FreeTextAnswers";
import MatrixGrid from "./MatrixGrid";
import MatrixScale from "./MatrixScale";
import MultipleChoice from "./MultipleChoice";
import QuestionSharedProperties from "./QuestionSharedProperties";
import Rating from "./Rating";
import SingleChoice from "./SingleChoice";
import { CardBlock } from "../../../../components/CardBlock";
import { useParams } from "react-router-dom";

type RouteParams = {
    courseId: string;
    chapterId: string;
    moduleId: string;
    moduleBlockId: string;
    questionId: string;
};

const RenderQuestionsByType = (
    question: Question,
    courseId: number,
    chapterId: number,
    moduleId: number
): JSX.Element => {
    switch (question?.questionTypeId) {
        case 1:
            return (
                <FreeTextAnswers question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />
            );
        case 2:
            return <SingleChoice question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />;
        case 3:
            return <MultipleChoice question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />;
        case 4:
            return <Rating question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />;
        case 5:
            return <MatrixScale question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />;
        case 6:
            return <MatrixGrid question={question} courseId={courseId} chapterId={chapterId} moduleId={moduleId} />;

        default:
            // @ts-ignore
            return null;
    }
};

const Index = (): JSX.Element => {
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId: number = parseInt(params.courseId);

    //@ts-ignore
    const questionId: number = parseInt(params.questionId);
    //@ts-ignore

    const chapterId: number = parseInt(params.chapterId);

    //@ts-ignore
    const moduleId: number = parseInt(params.moduleId);
    //@ts-ignore

    const moduleBlockId: number = parseInt(params.moduleBlockId);

    const [question, setQuestion] = useState(null);
    const api = createApi();

    const [course] = useCurrentCourseFromParams();
    const nav = CourseMenu(course);

    useEffect(() => {
        if (questionId) {
            getQuestionById(questionId);
        }
    }, [questionId]);

    const getQuestionById = (questionId: number) => {
        //API start loading here
        //Store the reuslt in setCourse local state
        api.questions.get(questionId).then(
            result => {
                // Course loading api done and success
                // @ts-ignore
                setQuestion(result);
            },
            error => {
                // corse loading api is done and error
                console.log(error);
            }
        );
    };

    const isLoading = false;
    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const moduleDetails = chapterDetails?.modules?.find(m => m.id === moduleId);
    // @ts-ignore

    const moduleBlockDetails = moduleDetails?.blocks.find(b => b.id === moduleBlockId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/edit` },
        {
            name: moduleDetails?.name,
            path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/module/${moduleDetails?.id}/edit`,
        },
        { name: moduleBlockDetails?.title, path: null },
    ];
    return (
        <>
            {moduleBlockDetails && (
                <Breadcrumbs
                    // @ts-ignore

                    breadCrumb={links}
                />
            )}
            <AppLoader loading={isLoading} className="position-absolute" />
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar
                            children={nav}
                            name={course?.name}
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlockId={moduleBlockId}
                        />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <QuestionSharedProperties
                            // @ts-ignore

                            question={question}
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlockId={moduleBlockId}
                        />
                    </CardBlock>
                    <RenderIf show={questionId && questionId !== 0 ? true : false}>
                        <CardBlock mb="xl">
                            <ContentLabel text="Options" />
                            {
                                // @ts-ignore
                                RenderQuestionsByType(question, courseId, chapterId, moduleId)
                            }
                        </CardBlock>
                    </RenderIf>
                </div>
            </div>
        </>
    );
};

export default Index;
