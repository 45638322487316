import { Flex, SimpleGrid, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { CardBlock } from "@/components";
import RenderIf from "../../../components/render-if/render-if";
import { TeamMember } from "./TeamMember";
import { UserCards } from "./UserCards";
import { useTeamCardStyles } from "./useTeamCardStyles";

export type TeamCardProps = {
    teamMembers: TeamMember[];
};

export const TeamCard = (props: TeamCardProps) => {
    const { classes } = useTeamCardStyles();
    const { t } = useTranslate();

    const activeUsers = props.teamMembers.filter(m => m.user.status === "Active");

    const onboardedUsers = activeUsers.filter(m => m.user.hasAcceptedInvite).alphabeticSort(m => m.user.fullName!);

    const pendingUsers = activeUsers.filter(m => !m.user.hasAcceptedInvite);

    return (
        <CardBlock classNames={classes}>
            <Flex direction="column" rowGap="md">
                <Text size="xl">Team</Text>
                <RenderIf show={activeUsers.length > 0}>
                    <SimpleGrid cols={8} verticalSpacing="xl">
                        <UserCards activeMembers={[...onboardedUsers, ...pendingUsers]} />
                    </SimpleGrid>
                </RenderIf>
                <RenderIf show={activeUsers.length === 0}>
                    <Flex justify="center">
                        <Text color="gray.5">{t("HOME_NO_TEAM_MEMBERS")}</Text>
                    </Flex>
                </RenderIf>
            </Flex>
        </CardBlock>
    );
};
