import { UsersClient } from "./legacy";
import {
    ApiConfiguration,
    ChangePassword,
    FileParameter,
    Operation,
    PostUserDto,
    User,
    ValidateUser,
} from "./contracts";

export class UserClient {
    private usersClient: UsersClient;
    private config: ApiConfiguration;

    constructor(config: ApiConfiguration) {
        this.config = config;
        this.usersClient = new UsersClient(config);
    }

    changePassword = (changePassword: ChangePassword): Promise<User> =>
        this.usersClient.changePassword(changePassword) as Promise<User>;

    validate = (user: PostUserDto): Promise<ValidateUser> => this.usersClient.validate(user);

    uploadProfileImage = (id: number, image: FileParameter | null | undefined): Promise<string> =>
        this.usersClient.uploadProfileImage(id, image);

    query = (
        skip: number | null | undefined,
        take: number | null | undefined,
        departmentId: number | null | undefined,
        allUsers: boolean | null | undefined
    ): Promise<User[]> => this.usersClient.query(skip, take, departmentId, allUsers) as Promise<User[]>;

    post = (model: PostUserDto): Promise<User> => this.usersClient.post(model) as Promise<User>;

    get = (id: number): Promise<User> => this.usersClient.get(id) as Promise<User>;

    put = (id: number, model: User): Promise<User> => this.usersClient.put(id, model) as Promise<User>;

    patch = (id: number, patch: Operation[]): Promise<User> => this.usersClient.patch(id, patch) as Promise<User>;

    activate = async (id: number): Promise<Response> => {
        const token = this.config.getAuthorizationHeader();
        const response = await fetch(`${this.config.baseUrl}/api/users/activate`, {
            mode: "cors",
            method: "post",
            headers: {
                ...(token === null ? {} : { Authorization: token }),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(id),
        });
        return response;
    };

    deactivate = async (id: number): Promise<Response> => {
        const token = this.config.getAuthorizationHeader();
        const response = await fetch(`${this.config.baseUrl}/api/users/deactivate`, {
            mode: "cors",
            method: "post",
            headers: {
                ...(token === null ? {} : { Authorization: token }),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(id),
        });
        return response;
    };

    delete = async (id: number): Promise<Response> => {
        const token = this.config.getAuthorizationHeader();
        const response = await fetch(`${this.config.baseUrl}/api/users/delete`, {
            mode: "cors",
            method: "post",
            headers: {
                ...(token === null ? {} : { Authorization: token }),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(id),
        });
        return response;
    };
}
