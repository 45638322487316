import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useAllGroups, useAllUsers } from "../../../common/OrganisationContext";
import { EmailFormat } from "../../../helpers/helpers";
import { PreviewGroupEmployees } from "./PreviewGroupEmployees";
import { User } from "@/api-client";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { createStyles } from "@mantine/core";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

type AddEmployeeProps = {
    departmentId?: number;
    placeholder?: string;
    groupId?: number;
    departmentDropDown?: boolean;
    courseId?: number;
    className?: string;
};

interface Employee extends User {
    isGroupLeader: boolean;
    isParticipant: boolean;
}

export type ErrorType = "Mobile Phone" | "Email" | "Both";

export type MembershipStatus = "New" | "CannotCreate" | "NotMember" | "AlreadyMember";

export interface ValidateUserModel {
    errorMessage: string;
    state: MembershipStatus;
    errorType: ErrorType;
    isValid: boolean;
    disabled: boolean;
    info?: string;
}

export interface EmployeesToBeAddedModel extends ValidateUserModel {
    tempId: number;
    user: Employee;
}

export const getMobileNumberWithCountryCode = (mobileNumber: string): string => {
    const mobileToString = String(mobileNumber);
    const isLeadingZero = mobileToString[0];
    const isLeadingAnyNumber = parseInt(mobileToString[0]);
    if (isLeadingZero === "0") {
        const sepratedAfterZero = mobileToString.slice(1);
        return "+46" + sepratedAfterZero;
    } else if (isLeadingAnyNumber) {
        return "+46" + mobileToString;
    } else {
        return mobileNumber;
    }
};

export const AddGroupEmployees = (props: AddEmployeeProps): JSX.Element => {
    const { t } = useTranslate();
    const users = useAllUsers();
    const groups = useAllGroups();
    const [employees, setEmployees] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [previewGroupEmployees, setPreviewGroupEmployees] = useState<boolean>(false);
    const [employeesToBeAdded, setEmployeesToBeAdded] = useState<EmployeesToBeAddedModel[] | null>(null);
    /**
     * Set employees in state and set errors to null
     * @param e
     */
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setEmployees(value);
        setError(null);
    };

    const next = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (employees) {
            const splitEmployees = employees.split(/[,;\n\r]/).filter(element => element !== "");
            const trimmedEmployees = splitEmployees.map(s => s.trim());
            const employeesInArr = trimmedEmployees.map((emailOrMobile, ix) => {
                let empDetails: Employee = {
                    /* @ts-ignore */
                    email: null,
                    /* @ts-ignore */
                    firstName: null,
                    /* @ts-ignore */
                    lastName: null,
                    isGroupLeader: false,
                    isParticipant: true,
                    /* @ts-ignore */
                    mobilePhone: null,
                    status: "Active",
                    hasAcceptedInvite: false,
                };
                if (emailOrMobile.match(EmailFormat)) {
                    empDetails = { ...empDetails, email: emailOrMobile };
                } else {
                    // EmailOrMobile will be Mobile
                    const mobileWithCountryCode = getMobileNumberWithCountryCode(emailOrMobile);
                    const mobileNumber = mobileWithCountryCode.replace(/-/g, "").replace(/ /g, "").trim();
                    empDetails = { ...empDetails, mobilePhone: mobileNumber };
                }
                return {
                    tempId: ix, // Use index as a temporary id for this entry, could be anything unique
                    user: empDetails,
                    state: null,
                    errorMessage: null,
                    errorType: null,
                    isValid: true,
                    disabled: false,
                };
            });
            /* @ts-ignore */
            setEmployeesToBeAdded(employeesInArr);
            setPreviewGroupEmployees(true);
        } else {
            setError(t("RESOURCES_ADD_EMPLOYEE_ERROR"));
        }
    };

    return (
        <React.Fragment>
            {previewGroupEmployees && (
                <PreviewGroupEmployees
                    show={previewGroupEmployees}
                    hide={() => setPreviewGroupEmployees(false)}
                    resetForm={() => setEmployees("")}
                    /* @ts-ignore */
                    employees={employeesToBeAdded}
                    /* @ts-ignore */
                    users={users}
                    /* @ts-ignore */
                    groups={groups}
                    groupId={props.groupId}
                />
            )}
            <p className={`content-label mt-3 ${props.className}`}>
                {props.placeholder ? props.placeholder : t("RESOURCES_ADD_EMPLOYEES")}
            </p>
            <div className="plan-course-group-bg mt-3 ms-0">
                <Form className="col-md-12 mt-3">
                    <Row>
                        <Col className="col-md-6">
                            <label>{t("ACCOUNT_OWNER_ADD_EMPLOYEE_EMAILS_TEXT")}</label>
                            <textarea
                                value={employees}
                                onChange={e => {
                                    handleChange(e);
                                }}
                                className="form-control"
                                name="departments"
                                placeholder={props.placeholder ? props.placeholder : t("RESOURCES_ADD_EMPLOYEES")}
                            >
                                {employees}
                            </textarea>
                        </Col>
                        <Col className="col-md-6 mt-4">
                            <p>
                                <small>{t("ACCOUNTOWNER_ADD_EMPLOYEES_TIP_TEXT")}</small>
                            </p>
                        </Col>
                    </Row>
                    <ContentBlockButtonContainer>
                        <PrimaryButton disabled={error !== null ?? true} type="submit" onClick={e => next(e)}>
                            {t("COMMON_NEXT")}
                        </PrimaryButton>
                    </ContentBlockButtonContainer>
                </Form>
                <Col className="add-employees ms-0 col-md-12">
                    {error !== null && <span className="error">{error}</span>}
                </Col>
            </div>
        </React.Fragment>
    );
};
