import { Group, GroupMember, Operation } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { createApi } from "../../../common/api";
import {
    useAllUsers,
    useOrganisationContext,
    useOrganisationContextDispatch,
} from "../../../common/OrganisationContext";
import { createPatch } from "../../../common/patchHelper";
import { GroupMembershipEditor } from "../../../components/GroupMembershipEditor";
import StyledCheckbox from "../../../components/StyledCheckbox";
import UserAvatar from "../../../components/UserAvatar";
import "./style.scss";
import { ActionIcon, Menu } from "@mantine/core";
import { Icons } from "common/Icons";

/*  eslint-disable react-hooks/exhaustive-deps */

interface GroupMembersListProps {
    groupId: number;
}

interface GroupMemberListItem {
    groupMember: GroupMember;
}

const GroupMembersList = (props: GroupMembersListProps) => {
    const { t } = useTranslate();
    const history = useHistory();
    const api = createApi();
    const groupsInCourse = useOrganisationContext()?.groups;
    const users = useAllUsers();
    /* @ts-ignore */
    const group: Group = groupsInCourse?.find(x => x.id === props.groupId);

    const [usersToDisplay, setUsersToDisplay] = useState<GroupMemberListItem[]>();
    const groupsDispatch = useOrganisationContextDispatch();

    const [openGroupMembershipEditor, setOpenGroupMembershipEditor] = useState<{ groupId: number; userId: number }>(
        /* @ts-ignore */
        null
    );

    /**
     * QA: Make all logic in this component read right of state instead of "caching" data in state for component. All optmistic updates should affect the organization state directly instead.
     */

    useEffect(() => {
        if (group) {
            refreshUsersToDisplay();
        }
    }, [group, group?.members]);

    const refreshUsersToDisplay = () => {
        setUsersToDisplay(
            group.members.map(m => {
                return {
                    groupMember: m,
                    user: users?.find(u => u.id === m.userId),
                };
            })
        );
    };

    const setParticipant = (member: GroupMember, isParticipant: boolean): void => {
        // To optimistic update to prevent UI lag until server response
        setUsersToDisplay(
            /* @ts-ignore */
            usersToDisplay.map(x => ({
                ...x,
                groupMember: {
                    ...x.groupMember,
                    isParticipant: x.groupMember.userId === member.userId ? isParticipant : x.groupMember.isParticipant,
                },
            }))
        );
        changeGroupMembershipStatusParticipant(member, isParticipant);
    };

    const groupMemberShipPatchRequest = (member: GroupMember, groupId: number, patch: Operation[]) => {
        return api.groupMembership.patch(member.userId, groupId, patch);
    };

    const changeGroupMembershipStatusParticipant = (member: GroupMember, isParticipant: boolean) => {
        const patch = createPatch(member, g => {
            g.isParticipant = isParticipant;
        });
        groupMemberShipPatchRequest(member, props.groupId, patch);
    };

    const changeGroupMembershipStatusGroupLeader = (member: GroupMember, isGroupLeader: boolean) => {
        const patch = createPatch(member, g => {
            g.isGroupLeader = isGroupLeader;
        });
        groupMemberShipPatchRequest(member, props.groupId, patch);
    };

    const setGroupLeader = (member: GroupMember, isGroupLeader: boolean): void => {
        // To optimistic update to prevent UI lag until server response
        setUsersToDisplay(
            /* @ts-ignore */
            usersToDisplay.map(x => ({
                ...x,
                groupMember: {
                    ...x.groupMember,
                    isGroupLeader: x.groupMember.userId === member.userId ? isGroupLeader : x.groupMember.isGroupLeader,
                },
            }))
        );
        changeGroupMembershipStatusGroupLeader(member, isGroupLeader);
    };

    const removeGroupMember = (member: GroupMember): void => {
        /* @ts-ignore */
        const title = t("GROUP_MEMBER_DELETE_TITLE", { email: member.user.email || member.user.mobilePhone });
        confirmAlert({
            title: title,
            message: t("COMMON_DELETE_MESSAGE"),
            buttons: [
                {
                    label: t("COMMON_YES"),
                    onClick: () => {
                        api.groupMembership.delete(member.userId, props.groupId).then(
                            result => {
                                groupsDispatch(s => {
                                    return {
                                        ...s,
                                        groups: [
                                            /* @ts-ignore */
                                            ...s.groups.map(g => {
                                                if (g.id === props.groupId) {
                                                    const { members, ...rest } = g;
                                                    return {
                                                        ...rest,
                                                        members: [...members.filter(m => m.userId !== member.userId)],
                                                    };
                                                } else {
                                                    return g;
                                                }
                                            }),
                                        ],
                                    };
                                });
                            },
                            error => {
                                console.log(error, "Error during delete membership.");
                            }
                        );
                    },
                },
                {
                    label: t("COMMON_NO"),
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    return (
        <div className="group-members-list">
            <p className="content-label mt-3">{t("RESOURCES_EMPLOYEES_GROUP")}</p>
            {usersToDisplay && usersToDisplay.length > 0 ? (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>{t("GROUP_PARTICIPANTS_TABLE_NAME")}</th>
                            <th data-field="email">
                                {t("RESOURCES_EMAIL")}/{t("MEMBERSHIP_MOBILE_PHONE")}
                            </th>
                            <th>{t("RESOURCES_PARTICIPANT")}</th>
                            <th>{t("RESOURCES_GROUPLEADER")}</th>
                            <th>{t("RESOURCES_STATUS")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersToDisplay &&
                            usersToDisplay
                                /* @ts-ignore */
                                .sort((a, b) => (a.groupMember?.user?.email < b.groupMember?.user?.email ? -1 : 1))
                                .map((emp, i) => (
                                    <tr key={i} className="employeesData">
                                        <td className="align-middle full_name_tb">
                                            <UserAvatar user={emp.groupMember.user} />
                                        </td>
                                        <td>
                                            {emp.groupMember?.user?.email !== null
                                                ? emp.groupMember?.user?.email
                                                : emp.groupMember?.user?.mobilePhone}
                                        </td>
                                        <td className="checkbox-employees">
                                            <StyledCheckbox
                                                name={`participant${emp.groupMember.userId}`}
                                                onChange={checked => setParticipant(emp.groupMember, checked)}
                                                checked={emp.groupMember.isParticipant}
                                            />
                                        </td>
                                        <td className="checkbox-employees">
                                            <StyledCheckbox
                                                name={`groupLeader${emp.groupMember.userId}`}
                                                onChange={checked => setGroupLeader(emp.groupMember, checked)}
                                                checked={emp.groupMember.isGroupLeader}
                                            />
                                        </td>
                                        <td>{emp.groupMember.inviteStatus}</td>
                                        <td>
                                            <Menu>
                                                <Menu.Target>
                                                    <ActionIcon>{Icons.ContextMenu()}</ActionIcon>
                                                </Menu.Target>
                                                <Menu.Dropdown>
                                                    <Menu.Item
                                                        onClick={() =>
                                                            history.push(
                                                                `/account-owner/users/edit/${emp.groupMember.userId}`
                                                            )
                                                        }
                                                    >
                                                        {"Edit"}
                                                    </Menu.Item>
                                                    <Menu.Item onClick={() => removeGroupMember(emp.groupMember)}>
                                                        Remove
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            setOpenGroupMembershipEditor({
                                                                /* @ts-ignore */
                                                                groupId: group.id,
                                                                userId: emp.groupMember.userId,
                                                            });
                                                        }}
                                                    >
                                                        Move group membership
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </Table>
            ) : (
                <p className="alert alert-secondary">{t("ORGANISATION_ADD_USERS_IN_TEXT")}</p>
            )}
            {openGroupMembershipEditor && (
                <GroupMembershipEditor
                    userId={openGroupMembershipEditor.userId}
                    currentGroupId={openGroupMembershipEditor.groupId}
                    /* @ts-ignore */
                    show={openGroupMembershipEditor !== null ? true : false}
                    /* @ts-ignore */
                    hide={() => setOpenGroupMembershipEditor(null)}
                />
            )}
        </div>
    );
};

export default GroupMembersList;
