import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { UserHasOneOfRoles, UserHasSysAdminRole } from "../../common/user/utils";
import { Filtration } from "../../sysadmin/components/Filtration";
import { InvitationStatuses, RoleOptions, translateInviteStatus, UserStatuses } from "../../sysadmin/helper";
import { useSysAdminClients } from "../../sysadmin/SysadminContext";
import { GroupMembershipEditor } from "../GroupMembershipEditor";
import RenderIf from "../render-if/render-if";
import useDebounce from "../UseDebounce/UseDebounce";
import "./style.scss";
import { User } from "@/api-client";
import { useAuth } from "../../auth/useAuth";
import { ActionIcon, createStyles, Menu } from "@mantine/core";
import { Icons } from "common/Icons";
import { UserLoginDropdownItem } from "./UserLoginDropdownItem";
import { Link } from "react-router-dom";
import { UrlFactory } from "../../routing/UrlFactory";

/*  eslint-disable react-hooks/exhaustive-deps */

interface SortingModel {
    field: string;
    isDesc: boolean;
}

type UsersStateType = {
    users: User[];
    tempUsers: User[];
};

interface UserListProps {
    componentFor?: string;
    departmentId?: number;
    showDepartmentDropDown?: boolean;
    users: User[];
}

interface EmployeesSearch {
    name: string | null;
    email: string | null;
    status: string;
    department: number;
    clientId: number;
    role: string;
}

const useContextMenuStyles = createStyles(() => ({
    button: { backgroundColor: "transparent", border: "none", width: "100%" },
    link: { textDecoration: "none" },
}));

export function UsersList(props: UserListProps): JSX.Element {
    const { isImpersonating, user } = useAuth();
    const { t } = useTranslate();
    const { classes } = useContextMenuStyles();

    const userStatuses = useMemo(() => UserStatuses(t), []);
    const invitationStatuses = useMemo(() => InvitationStatuses(t), []);
    const [statusFilter, setStatusFilter] = useState(userStatuses);

    const userRolesOptions = useMemo(() => RoleOptions(), []);
    const [userRolesFilter, setUserRolesFilter] = useState(userRolesOptions);

    const [clientFilter, setClientFilter] = useState(null);

    const clientsFromContext = useSysAdminClients();

    const [filterCourseValV1, setFilterCourseValV2] = useState<EmployeesSearch>({
        name: null,
        status: "Active",
        email: null,
        // @ts-ignore

        department: null,
        clientId: 0,
        // @ts-ignore

        role: null,
    });

    // @ts-ignore

    const [userIdForGroupMembershipEditor, setUserIdForGroupMembershipEditor] = useState<number>(null);

    const [invitationFilter, setInvitationFilter] = useState(invitationStatuses);

    const isSysAdmin = useMemo(() => {
        return UserHasSysAdminRole(user!) && !isImpersonating();
    }, [user]);

    const [usersState, setUsersState] = useState<UsersStateType>({
        // @ts-ignore

        users: null,
        // @ts-ignore

        tempUsers: null,
    });

    const [sort, setSort] = useState<SortingModel>({ field: "name", isDesc: true });
    const sortHeaderCallback = (field: string) => {
        setSort(sort => ({ field: field, isDesc: sort.field === field ? !sort.isDesc : false }));
    };

    useEffect(() => {
        if (props.users) {
            setFilterCourseValV2({
                ...filterCourseValV1,
                status: "Active",
                clientId: 0,
            });
            setUsersState({
                ...usersState,
                users: props.users,
                tempUsers: props.users.filter(t => t.status === "Active"),
            });
        }
    }, [props.users]);

    useMemo(() => {
        setClientFilter(
            // @ts-ignore
            [{ id: null, label: "Unassigned", checked: true, value: null }]?.concat(
                // @ts-ignore
                _.sortBy(
                    clientsFromContext?.map((item, index) => ({
                        id: item.id,
                        label: item.name,
                        value: item.id,
                        checked: true,
                    })),
                    ["label"]
                )
            )
        );
    }, [clientsFromContext]);

    const filterCoursesStateChange = useDebounce(filterCourseValV1, 200);

    const users = useMemo(() => {
        const { name, email } = filterCourseValV1;
        let query = usersState.users;
        if (query && query.length > 0) {
            if (statusFilter) {
                const checkedStatuses = statusFilter.filter(s => s?.checked).map(s => s.value);
                query = query?.filter(s => checkedStatuses.includes(s?.status));
            }
            if (name) {
                query = query.filter(c => c?.fullName?.toLowerCase()?.includes(name?.toLowerCase()));
            }
            if (email) {
                query = query
                    .filter(x => x.email !== null)
                    // @ts-ignore
                    .filter(c => c?.email.toLowerCase().includes(email.toLowerCase()));
            }
            if ((clientFilter || clientFilter === null) && isSysAdmin) {
                // @ts-ignore
                const selectedClients = clientFilter.filter(x => x?.checked).map(x => x.id);
                query = query?.filter(x => selectedClients.includes(x.clientId));
            }
            if (userRolesFilter) {
                const checkedStatuses: string[] = userRolesFilter.filter(s => s?.checked).map(s => s.value);
                // @ts-ignore

                const isUncategorizedChecked = userRolesFilter.find(s => s.label === "Unassigned").checked;
                // get only the uncategorized if onyl this is checked
                if (checkedStatuses.length === 1 && isUncategorizedChecked) {
                    // @ts-ignore

                    query = query.filter(t => t.roles.length === 0);
                } else {
                    query = query.filter(t =>
                        isUncategorizedChecked
                            ? _.intersection(t.roles, checkedStatuses).length >= 0
                            : _.intersection(t.roles, checkedStatuses).length > 0
                    );
                }
            }
            if (invitationFilter) {
                const checkedValues: boolean[] = invitationFilter.filter(x => x.checked).map(x => x.value);
                let includeAcceptedInvite: (u: User) => boolean = _ => false;
                let includeNotAcceptedInvite: (u: User) => boolean = _ => false;

                if (checkedValues.includes(true)) {
                    includeAcceptedInvite = u => u.hasAcceptedInvite;
                }

                if (checkedValues.includes(false)) {
                    includeNotAcceptedInvite = u => !u.hasAcceptedInvite;
                }
                //debugger;
                query = query.filter(x => includeAcceptedInvite(x) || includeNotAcceptedInvite(x));
            }
            query = _.orderBy([...query], [sort.field], [sort.isDesc ? "desc" : "asc"]) as any;
        }
        return query;
    }, [filterCoursesStateChange, statusFilter, userRolesFilter, clientFilter, invitationFilter, sort]);

    const allSelectedStatuses = statusFilter && statusFilter?.filter(t => t?.checked);
    const selectedAndOtherStatusesCount: boolean = allSelectedStatuses?.length === statusFilter?.length;
    const getSelectedItems = allSelectedStatuses && allSelectedStatuses.length > 0 ? allSelectedStatuses[0] : null;

    const allSelectedInvitationStatuses = invitationFilter && invitationFilter?.filter(t => t?.checked);
    const selectedAndOtherInvitationCount: boolean = allSelectedInvitationStatuses?.length === invitationFilter?.length;
    const getSelectedInvitationStatuses =
        allSelectedInvitationStatuses && allSelectedInvitationStatuses.length > 0
            ? allSelectedInvitationStatuses[0]
            : null;

    const allSelectedRoles = userRolesFilter && userRolesFilter?.filter(t => t?.checked);
    const isAllRolesSelected: boolean = allSelectedRoles?.length === userRolesFilter?.length;
    const getSelectedRoles = allSelectedRoles && allSelectedRoles.length > 0 ? allSelectedRoles[0] : null;
    // @ts-ignore

    const allSelectedClients = clientFilter && clientFilter?.filter(t => t?.checked);
    // @ts-ignore

    const isAllClientsSelected: boolean = allSelectedClients?.length === clientFilter?.length;
    // @ts-ignore

    const getSelectedClient = allSelectedClients && allSelectedClients.length > 0 ? allSelectedClients[0] : null;

    const editAction = (user: User) => {
        if (isSysAdmin && UserHasOneOfRoles(user, ["Admin", "Expert", "Agent"])) {
            return (
                <Link className={classes.link} to={UrlFactory.sysadmin.admin.create({ userId: user.id! })}>
                    <Menu.Item> {t("COMMON_EDIT")} </Menu.Item>
                </Link>
            );
        }

        return (
            <Link className={classes.link} to={`/${props.componentFor}/users/edit/${user.id}`}>
                <Menu.Item> {t("COMMON_EDIT")} </Menu.Item>
            </Link>
        );
    };

    const sysadminAction = (user: User) => {
        if (isSysAdmin && UserHasSysAdminRole(user)) {
            return null;
        }

        return (
            <>
                <Menu.Divider />
                <Menu.Item>
                    <UserLoginDropdownItem userId={user.id!} />
                </Menu.Item>
            </>
        );
    };

    return (
        <>
            {/* <AppLoader loading={getUsers.pending} className="position-absolute" /> */}
            <table className="table table-borderless table-striped mt-4 list-users-table">
                <thead>
                    <Filtration
                        fields={[
                            {
                                id: 1,
                                type: "text",
                                name: "filter-courses",
                                placeholder: t("ACCOUNTOWNER_USERS_NAME"),
                                // @ts-ignore

                                onChange: (value: string) =>
                                    setFilterCourseValV2({ ...filterCourseValV1, name: value }),
                                value: filterCourseValV1.name,
                                className: "whitespace-nowrap",
                                displayField: true,
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "filter-by-email",
                                // @ts-ignore

                                onChange: (value: string) =>
                                    setFilterCourseValV2({ ...filterCourseValV1, email: value }),
                                value: filterCourseValV1.email,
                                className: "whitespace-nowrap",
                                placeholder: t("ACCOUNTOWNER_USERS_EMAIL"),
                                displayField: true,
                            },
                            {
                                id: 3,
                                type: "multi-select",
                                name: "filter-statuses",
                                // @ts-ignore

                                onChange: (value: number, checked: boolean) => {
                                    setStatusFilter(t => t.map(s => (s.id === value ? { ...s, checked } : s)));
                                },
                                onClear: () => {
                                    setStatusFilter(t =>
                                        t.map(s => ({
                                            ...s,
                                            checked: false,
                                        }))
                                    );
                                },
                                value: selectedAndOtherStatusesCount
                                    ? "All"
                                    : getSelectedItems
                                    ? `${getSelectedItems?.label} ${
                                          allSelectedStatuses.length > 1 ? `(+${allSelectedStatuses.length - 1})` : ""
                                      }`
                                    : "Select Status",
                                options: statusFilter,
                                displayField: true,
                                className: "whitespace-nowrap",
                            },
                            {
                                id: 4,
                                type: "multi-select",
                                name: "filter-statuses",
                                // @ts-ignore

                                onChange: (value: number, checked: boolean) => {
                                    setInvitationFilter(t => t.map(s => (s.id === value ? { ...s, checked } : s)));
                                },
                                onClear: () => {
                                    setInvitationFilter(t =>
                                        t.map(s => ({
                                            ...s,
                                            checked: false,
                                        }))
                                    );
                                },
                                value: selectedAndOtherInvitationCount
                                    ? "All"
                                    : getSelectedInvitationStatuses
                                    ? `${getSelectedInvitationStatuses?.label} ${
                                          allSelectedInvitationStatuses.length > 1
                                              ? `(+${allSelectedInvitationStatuses.length - 1})`
                                              : ""
                                      }`
                                    : "Select State",
                                options: invitationFilter,
                                displayField: true,
                                className: "whitespace-nowrap",
                            },
                            {
                                id: 5,
                                type: "multi-select",
                                name: "clients-filter",
                                // @ts-ignore

                                onChange: (value: number, checked: boolean) => {
                                    // @ts-ignore

                                    setClientFilter(t => t.map(s => (s.id === value ? { ...s, checked } : s)));
                                },
                                onClear: () => {
                                    setClientFilter(t =>
                                        // @ts-ignore
                                        t.map(s => ({
                                            ...s,
                                            checked: false,
                                        }))
                                    );
                                },

                                value: isAllClientsSelected
                                    ? "All Clients"
                                    : getSelectedClient
                                    ? // @ts-ignore
                                      `${getSelectedClient?.label} ${
                                          // @ts-ignore
                                          allSelectedClients.length > 1
                                              ? // @ts-ignore

                                                `(+${allSelectedClients.length - 1})`
                                              : ""
                                      }`
                                    : "Select Client",
                                // @ts-ignore

                                options: clientFilter,
                                //className: "whitespace-nowrap",
                                displayField: isSysAdmin,
                            },
                            {
                                id: 6,
                                type: "multi-select",
                                name: "roles-filter",
                                // @ts-ignore

                                onChange: (value: number, checked: boolean) => {
                                    setUserRolesFilter(t => t.map(s => (s.id === value ? { ...s, checked } : s)));
                                },
                                onClear: () => {
                                    setUserRolesFilter(t =>
                                        t.map(s => ({
                                            ...s,
                                            checked: false,
                                        }))
                                    );
                                },
                                value: isAllRolesSelected
                                    ? "All Roles"
                                    : getSelectedRoles
                                    ? `${getSelectedRoles?.label} ${
                                          allSelectedRoles.length > 1 ? `(+${allSelectedRoles.length - 1})` : ""
                                      }`
                                    : "Select Role",
                                options: userRolesFilter,
                                className: "whitespace-nowrap",
                                displayField: isSysAdmin,
                            },
                            {
                                type: "anchor",
                                name: "Hide",
                                className: "whitespace-nowrap",
                            },
                        ]}
                        tableHeadings={[
                            {
                                id: 1,
                                name: t("ACCOUNTOWNER_USERS_NAME"),
                                isSearchable: true,
                                isActive: true,
                                className: "whitespace-nowrap",
                                displayHeading: true,
                                sorting: true,
                                handleSorting: () => {
                                    sortHeaderCallback("firstName");
                                },
                            },
                            {
                                id: 2,
                                name: t("ACCOUNTOWNER_USERS_EMAIL"),
                                isSearchable: true,
                                isActive: false,
                                displayHeading: true,
                                sorting: true,
                                handleSorting: () => sortHeaderCallback("email"),
                            },
                            {
                                id: 3,
                                name: t("ACCOUNTOWNER_USERS_STATUS"),
                                isSearchable: false,
                                displayHeading: true,
                                isActive: false,
                                className: "whitespace-nowrap",
                            },
                            {
                                id: 4,
                                name: "Accepted Invite",
                                isSearchable: false,
                                displayHeading: true,
                                isActive: false,
                                className: "whitespace-nowrap",
                            },
                            {
                                id: 5,
                                name: "Client",
                                isSearchable: false,
                                isActive: false,
                                displayHeading: true,
                            },
                            {
                                id: 6,
                                name: "Roles",
                                isSearchable: false,
                                isActive: false,
                                displayHeading: true,
                            },
                            {
                                // @ts-ignore

                                name: null,
                            },
                        ]}
                    />
                </thead>
                <tbody>
                    <RenderIf show={users !== null}>
                        {users &&
                            users.length > 0 &&
                            users.map(user => (
                                <tr key={`users-list-${user.id}`}>
                                    <td>
                                        {user.firstName} {user.lastName}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>
                                        {
                                            // @ts-ignore
                                            translateInviteStatus(t)[
                                                // @ts-ignore
                                                user?.status
                                            ]
                                        }
                                    </td>
                                    <td>{user.hasAcceptedInvite ? t("COMMON_YES") : t("COMMON_NO")}</td>
                                    {isSysAdmin && (
                                        <React.Fragment>
                                            <td>{clientsFromContext?.find(x => x.id === user?.clientId)?.name}</td>
                                            <td>{user?.roles?.join(", ")}</td>
                                        </React.Fragment>
                                    )}
                                    <td className="justify-content-center">
                                        <Menu id="sysadmin-dropdown">
                                            <Menu.Target>
                                                <ActionIcon m="auto">{Icons.ContextMenu()}</ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                {editAction(user)}
                                                {sysadminAction(user)}
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                    </RenderIf>
                    <RenderIf show={users === null || (users && users.length === 0)}>
                        <tr>
                            <th colSpan={5} className="text-center">
                                {t("ACCOUNTOWNER_USERS_NO_USERS_FOUND")}
                            </th>
                        </tr>
                    </RenderIf>
                </tbody>
            </table>
            <GroupMembershipEditor
                userId={userIdForGroupMembershipEditor}
                show={userIdForGroupMembershipEditor ? true : false}
                hide={() =>
                    // @ts-ignore

                    setUserIdForGroupMembershipEditor(null)
                }
            />
        </>
    );
}
