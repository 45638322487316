import { LoginClient as OldLoginClient } from "./legacy";

import {
    ApiConfiguration,
    ErrorLoginResult,
    FileResponse,
    InviteData,
    Login,
    LoginResponse,
    OkLoginResult,
    PasswordRequest,
    LoginWithPhoneNumberCommandDto,
    ResetPassword,
    Signup,
    ThirdPartyServices,
    TokenStatus,
    User,
} from "./contracts";
import { PostJson } from "./utility";
import { BaseClient } from "./BaseClient";

export class LoginClient extends BaseClient {
    private readonly oldLoginClient: OldLoginClient;

    constructor(config: ApiConfiguration) {
        super(config);
        this.oldLoginClient = new OldLoginClient(config);
    }

    login = (model: Login): Promise<LoginResponse> => {
        const url = `${this.config.baseUrl}/api/login`;
        return LoginClient.post(url, model);
    };

    checkResetPasswordToken = async (model: Login): Promise<TokenStatus> => {
        const url = `${this.config.baseUrl}/api/login/check-reset-password-token`;
        const response = await PostJson(url, model);

        if (response.status === 200) {
            return Promise.resolve((await response.json()) as TokenStatus);
        }

        return Promise.reject();
    };

    refresh = async (): Promise<RefreshResultDto> => {
        return this.get<RefreshResultDto>("/api/login/refresh", new AbortController());
    };

    acceptInvite = (signup: Signup): Promise<LoginResponse> => {
        const url = `${this.config.baseUrl}/api/login/accept-invite`;
        return LoginClient.post(url, signup);
    };

    resetPassword = (resetPassword: ResetPassword): Promise<LoginResponse> => {
        const url = `${this.config.baseUrl}/api/login/reset-password`;
        return LoginClient.post(url, resetPassword);
    };

    requestPassword = async (passwordRequest: PasswordRequest): Promise<void> => {
        const url = `${this.config.baseUrl}/api/login/request-password`;
        const response = await PostJson(url, passwordRequest);

        if (response.status === 200 || response.status === 204) {
            return Promise.resolve();
        }
        return Promise.reject(response);
    };

    checkInviteToken = async (model: Login): Promise<TokenStatus> => {
        const url = `${this.config.baseUrl}/api/login/check-invite-token`;
        const response = await PostJson(url, model);

        if (response.status === 200) {
            return Promise.resolve((await response.json()) as TokenStatus);
        }

        return Promise.reject();
    };

    inviteData = (): Promise<InviteData[]> => this.oldLoginClient.inviteData();

    impersonate = async (userId: number): Promise<ImpersonateUserResultDto> => {
        const dto: ImpersonateUserDto = {
            userId: userId,
        };

        return this.post<ImpersonateUserDto, ImpersonateUserResultDto>("/api/login/impersonate", dto);
    };

    elevateToAccountOwner = (clientId: number) => {
        const dto: ElevateToAccountOwnerDto = {
            clientId: clientId,
        };
        return this.post<ElevateToAccountOwnerDto, ElevateToAccountOwnerResultDto>(
            "/api/login/elevateToAccountOwner",
            dto
        );
    };

    phoneLogin = (model: LoginWithPhoneNumberCommandDto): Promise<LoginResponse> => {
        const url = `${this.config.baseUrl}/api/login/phone`;
        return LoginClient.post(url, model);
    };

    requestVerificationCode = (mobilePhone: string | null | undefined): Promise<FileResponse | null> =>
        this.oldLoginClient.requestVerificationCode(mobilePhone);

    credentials = (name: ThirdPartyServices | undefined): Promise<any> => this.oldLoginClient.credentials(name);

    private static post = async <T>(url: string, model: T): Promise<LoginResponse> => {
        const response = await PostJson(url, model);

        switch (response.status) {
            case 200:
                return (await response.json()) as OkLoginResult;
            case 401:
            case 403:
                return (await response.json()) as ErrorLoginResult;

            default:
                return Promise.reject(response);
        }
    };
}

export type ElevateToAccountOwnerDto = {
    clientId: number;
};

export type ElevateToAccountOwnerResultDto = {
    user: User;
    token: string;
};

export type ImpersonateUserDto = {
    userId: number;
};

export type ImpersonateUserResultDto = {
    token: string;
};

export type RefreshResultDto = {
    user: User;
};
