import { Answer, ImprovementBoardTask } from "@/api-client";
import React, { useState } from "react";
import Masonry from "react-masonry-css";
import { useBoardContext } from "../../../../pages/ImprovementBoard/BoardContext";
import { TaskCardEditor } from "../../../../pages/ImprovementBoard/components/TaskCardEditor/TaskCardEditor";
import { useCurrentCourse, useCurrentGroup } from "../../../../common/courses/context";
import { useCurrentUser } from "../../../../common/user/context";
import UserAvatar from "../../../../components/UserAvatar";
import { Tooltip } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { showToast } from "../../../../components/Notify";
import { customEvents } from "../../../../analytics/customEvents/customEvents";

interface AnswerCardProps {
    answer: Answer;
    showRelatedTask?: boolean;
}

export const AnswerCard = (props: AnswerCardProps) => {
    const { t } = useTranslate();
    const currentGroup = useCurrentGroup();
    const currentCourse = useCurrentCourse();
    const currentUser = useCurrentUser();
    const hideUserInformation = currentGroup?.courseAssignments?.find(
        // @ts-ignore
        c => c.groupId === currentGroup?.id && c.courseId === currentCourse.id
    )?.hideUserInfoOnAnswers;
    const boardContext = useBoardContext();
    const [showAddImprovement, setShowAddImprovement] = useState<boolean>(false);

    const showAddImprovementButton = props.showRelatedTask === undefined || props.showRelatedTask;

    const relatedTask: ImprovementBoardTask = {
        // @ts-ignore
        clientId: currentUser.clientId,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdByUserId: currentUser?.id,
        createdInGroupId: boardContext.state.group.id,
        // @ts-ignore
        groupId: boardContext.state.group.id,
        sortIndex: boardContext.state.tasks.length,
        status: "New",
        title: "",
        description: props.answer?.text,
        priority: "Normal",
        answerId: props.answer?.id,
    };

    const Avatar = () => {
        if (hideUserInformation) {
            return null;
        }
        return <UserAvatar className="presentation-avatar" displayName={false} user={props.answer.user} />;
    };

    return (
        <>
            <div className="answer-card">
                <div className="card shadow">
                    <Avatar />
                    <div className="card-body">
                        <p className="text-center" style={{ fontSize: "1rem" }}>
                            {props.answer.text}
                        </p>
                    </div>
                    {showAddImprovementButton && (
                        <div className="ms-3 mb-2">
                            <Tooltip label={t("WORKSHOP_ADD_IMPROVEMENT")} position="bottom" withArrow>
                                <span
                                    className="link-primary"
                                    role="button"
                                    onClick={() => setShowAddImprovement(true)}
                                >
                                    <i className="fa-solid fa-plus add-improvement-button" />
                                </span>
                            </Tooltip>
                        </div>
                    )}
                </div>
                {showAddImprovement && (
                    <TaskCardEditor
                        create={true}
                        task={relatedTask}
                        show={showAddImprovement}
                        handleClose={() => setShowAddImprovement(false)}
                        onSave={() => {
                            customEvents.raiseUiImprovementCreatedFrom("FreeTextAnswer");
                            showToast(t("WORKSHOP_IMPROVEMENT_SENT_TO_TEAM_BOARD"), "information");
                        }}
                    />
                )}
            </div>
        </>
    );
};

interface AnswersLayoutProps {
    answers: Answer[];
}

export const AnswersLayout = ({ answers }: AnswersLayoutProps) => {
    /** Undefined argument is Language code. As we can have multiple languages so passed undefined */
    const sortedAnswers = answers ? answers.map(a => a).sort((a, b) => a.order - b.order) : undefined;

    return (
        <Masonry breakpointCols={3} className="answers-grid p-3" columnClassName="answers-grid-column">
            {sortedAnswers &&
                sortedAnswers.map((a, ind) => {
                    return <AnswerCard key={ind} answer={a} />;
                })}
        </Masonry>
    );
};
