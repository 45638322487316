import { createStyles } from "@mantine/core";

export const useStyles = createStyles((_, { background, textColor }: { background: string; textColor: string }) => ({
    fallbackImage: {
        width: "100%",
        height: "100%",
        background,
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    letters: { color: textColor, fontWeight: "bold" },
}));
