import React, { useEffect, useState } from "react";
import { createApi } from "../../../common/api";
import { MultiSelect } from "@mantine/core";

/*  eslint-disable react-hooks/exhaustive-deps */

interface AutocompleteProps {
    defaultValue: string[];
    module: string | null;
    selectedValue: (value: string[]) => void;
}

export function AutocompleteTags(props: AutocompleteProps) {
    const api = createApi();
    const [tags, setTags] = useState<string[]>([]);
    const [value, setValue] = useState<string[]>(props?.defaultValue);
    useEffect(() => {
        if (props.module) {
            // @ts-ignore
            api[props.module].getAllTags().then(response => {
                setTags(response);
            });
        }
    }, []);

    const onChange = (tags: string[]) => {
        console.log(tags);
        setValue(tags);
        props.selectedValue(tags);
    };

    return (
        <MultiSelect
            searchable
            value={value && value.length > 0 ? value : []}
            onChange={value => onChange(value)}
            data={tags}
            placeholder="Tags"
            creatable
            getCreateLabel={query => `+ Create ${query.trim()}`}
            onCreate={query => {
                const item = query.trim();
                setTags(current => [...current, item]);
                return item;
            }}
        />
    );
}
