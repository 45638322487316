import { isEmail, isPhoneNumber } from "@/common";

export type UsernameType = "email" | "mobilePhone";

export class UsernameDto {
    public value: string;
    public usernameType: UsernameType;

    constructor(value: string) {
        this.value = value;
        this.usernameType = this.getUserNameType();
    }

    private getUserNameType(): UsernameType {
        if (isEmail(this.value)) {
            return "email";
        }

        if (isPhoneNumber(this.value)) {
            return "mobilePhone";
        }

        throw new Error("Invalid username");
    }
}
