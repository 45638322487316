import { Group, GroupMembership, ValidateUser } from "@/api-client";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { EmployeesToBeAddedModel, ErrorType, MembershipStatus, ValidateUserModel } from ".";
import { createApi } from "../../../common/api";
import { useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { AppLoader } from "../../../components/Spinner";
import { PreviewGroupEmployeeRow } from "./PreviewGroupEmployeeRow";
import "./styles.scss";
import { getUserByEmailOrPhone } from "./getUserByEmailOrPhone";
import { User } from "@/api-client";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

type PreviewGroupEmployeesProps = {
    show: boolean;
    hide: () => void;
    employees: EmployeesToBeAddedModel[];
    groups: Group[];
    users: User[];
    groupId?: number;
    resetForm: () => void;
};

export const PreviewGroupEmployees = (props: PreviewGroupEmployeesProps) => {
    const { t } = useTranslate();

    const api = createApi();

    const accountOwnerDispatch = useOrganisationContextDispatch();

    /* @ts-ignore */
    const [previewEmployees, setPreviewEmployees] = useState<EmployeesToBeAddedModel[]>(null);

    const [isPending, setIsPending] = useState(false);

    /**
     * This method is sharing the information about teh user existence
     * @param email
     * @param mobilePhone
     * @returns
     */

    const isUserAlreadyExist = (email: string | null, mobilePhone: string | null) => {
        return getUserByEmailOrPhone(email, mobilePhone, props.users) !== undefined;
    };

    /**
     * Method used to check is user is already the group member using mobile phone or email
     * @param groupId
     * @param email
     * @param mobilePhone
     * @returns
     */
    const isGroupMemberExist = (groupId: number, email: string | null, mobilePhone: string | null) => {
        /**Group member ids get from porps.groups */
        const groupMemberIds = props.groups.find(g => g.id === groupId)?.members?.map(m => m.userId);
        /* @ts-ignore */
        const getUsers = props.users.filter(u => groupMemberIds?.includes(u.id));
        const userAlreadyExist = getUserByEmailOrPhone(email, mobilePhone, getUsers);

        return userAlreadyExist !== undefined;
    };

    /**
     * This method is responsible for validating user already exist or is already group member with different state and error message
     * @param groupId
     * @param email
     * @param mobilePhone
     * @returns
     */
    const checkUserGroupOrAlreadyExist = (email: string, mobilePhone: string): ValidateUserModel => {
        const groupId = props.groupId;

        let state: MembershipStatus = "New";
        let errorMessage = "";
        let errorType = null;
        let isValid = true;
        let disabled = false;
        let info = null;
        if (isUserAlreadyExist(email, mobilePhone)) {
            state = "NotMember";
            errorType = null;
            isValid = true;
            info = t("PREVIEW_USER_ACCOUNT_EXIST_NOTE");
            disabled = true;
            /* @ts-ignore */
            if (isGroupMemberExist(groupId, email, mobilePhone)) {
                state = "AlreadyMember";
                errorMessage = t("PREVIEW_USER_EMAIL_ALREADY_EXIST_GROUP");
                errorType = email ? "Email" : "Mobile Phone";
                isValid = false;
                disabled = true;
                info = null;
            }
        }
        /* @ts-ignore */
        return { state, errorMessage, errorType, isValid, disabled, info };
    };

    /**
     * Check if users exists in other organisations
     * @param previewEmployees
     * @returns
     */

    const validateOtherOrganisationUsers = async (employees: EmployeesToBeAddedModel[]): Promise<boolean> => {
        setIsPending(true);
        return await Promise.allSettled(
            employees.map(emp => {
                return api.users.validate(emp.user);
            })
        ).then(response => {
            const userStatus: ValidateUser[] = response
                .filter(result => result.status === "fulfilled")
                .map(r => (r as PromiseFulfilledResult<ValidateUser>).value);

            const data = userStatus.map((m, index) => {
                const userState = employees[index].state;
                /* @ts-ignore */
                const errorType: ErrorType = m.isValid ? null : employees[index].user.email ? "Email" : "Mobile Phone";
                setPreviewEmployees([
                    ...employees.map((e: EmployeesToBeAddedModel, i) =>
                        i === index && userState === "New"
                            ? {
                                  ...e,
                                  errorType: errorType,
                                  state: "New" as MembershipStatus,
                                  errorMessage: m.errorMessage !== null ? t(m.errorMessage) : "",
                                  isValid: m.isValid,
                              }
                            : { ...e, errorMessage: "", state: userState, isValid: true }
                    ),
                ]);
                return userState === "New" ? m.isValid : true;
            });
            setIsPending(false);
            return data.every(x => x === true);
        });
    };

    const isAllEntriesValid = useMemo(() => {
        return _.every(previewEmployees, e => e.isValid);
    }, [previewEmployees]);

    useEffect(() => {
        if (props.employees && props.employees.length > 0) {
            setPreviewEmployees(props.employees);
        }
    }, [props.employees]);

    const findGroupUsersForMembership = (groupEmployees: EmployeesToBeAddedModel[]) => {
        return groupEmployees.some(g => g.state === "NotMember");
    };

    /**
     * Function used to save the new users which are not exist yet
     * Firs save users, merge news users with old users and pass it down to handle membership
     * @param newUsers
     */
    const addUsers = (newUsers: EmployeesToBeAddedModel[]) => {
        setIsPending(true);
        /** Filter Users which doesn't exist yet */
        const findNewUsers = newUsers.filter(e => e.state === "New");
        const hasUsersForMembership = findGroupUsersForMembership(findNewUsers);
        return Promise.allSettled(
            findNewUsers.map(u => {
                const { user } = u;
                return api.users.post({ ...user });
            })
        )
            .then(results => {
                const createdUsers: User[] = results
                    .filter(result => result.status === "fulfilled")
                    .map(r => (r as PromiseFulfilledResult<User>).value);
                accountOwnerDispatch(s => ({
                    ...s,
                    users: [
                        /* @ts-ignore */
                        ...s.users,
                        ...createdUsers,
                    ],
                }));
                /** This is the check made if user id exist only then save the groupmembership */
                if (props.groupId) {
                    /** Mere new users with old users */
                    const allUsers = [...props.users, ...createdUsers];
                    /** Add users id's with EmployeesTobeAddedModel to save the membership data */
                    /* @ts-ignore */
                    const membersWithUserId: EmployeesToBeAddedModel[] = newUsers.map(gm => ({
                        ...gm,
                        user: {
                            ...gm.user,
                            /* @ts-ignore */
                            id: getUserByEmailOrPhone(gm.user.email, gm.user.mobilePhone, allUsers).id,
                        },
                        state: null,
                        errorMessage: null,
                    }));

                    addGroupMembership(membersWithUserId as EmployeesToBeAddedModel[], allUsers);
                }
                if (!hasUsersForMembership) {
                    setIsPending(false);
                    props.hide();
                    props.resetForm();
                }
            })
            .catch(err => {
                //props.hide();
                props.resetForm();
                setIsPending(false);
                console.log(err, "Error");
            });
    };

    /**
     * Function used to add the group membership for new or existing users.
     * @param groupMemberUsers
     * @param users
     */
    const addGroupMembership = (groupMemberUsers: EmployeesToBeAddedModel[], users: User[]) => {
        setIsPending(true);
        return Promise.allSettled(
            groupMemberUsers.map(groupMember => {
                const { isParticipant, isGroupLeader, id } = groupMember.user;
                /* @ts-ignore */
                return api.groupMembership.post(id, props.groupId, {
                    userId: id,
                    groupId: props.groupId,
                    inviteStatus: "NotSent",
                    isGroupLeader,
                    isParticipant,
                });
            })
        )
            .then(response => {
                const membershipResult: GroupMembership[] = response
                    .filter(result => result.status === "fulfilled")
                    .map(r => (r as PromiseFulfilledResult<GroupMembership>).value);
                /** Updated optimistic state for membership */
                accountOwnerDispatch(s => {
                    return {
                        ...s,
                        groups: [
                            /* @ts-ignore */
                            ...s.groups.map(g => {
                                if (g.id === props.groupId) {
                                    const { members, ...rest } = g;
                                    const data = {
                                        ...rest,
                                        members: [
                                            ...members,
                                            ...membershipResult.map(mr => ({
                                                ...mr,
                                                user: users.find(u => u.id === mr.userId),
                                            })),
                                        ],
                                    };
                                    return data;
                                } else {
                                    return g;
                                }
                            }),
                        ],
                    };
                });
                props.hide();
                setIsPending(false);
                props.resetForm();
            })
            .catch(err => {
                setIsPending(false);
                console.log(err, "Error");
            });
    };

    /**
     * On click handler to save the employees and add membership
     */
    const handleSaveEmployees = async () => {
        if (previewEmployees && previewEmployees.length > 0) {
            const valid = await validateOtherOrganisationUsers([...previewEmployees]);
            if (valid) {
                if (props.groupId) {
                    addUsers(previewEmployees);
                    /** Filter group members which are not a member of any group yet */
                    const groupMemberUsers = previewEmployees.filter(e => e.state === "NotMember" || e.state === null);
                    /** Create data to add the users in membership */
                    /* @ts-ignore */
                    const usersForMembership: EmployeesToBeAddedModel[] = groupMemberUsers.map(m => {
                        return {
                            ...m,
                            user: {
                                ...m.user,
                                isParticipant: m.user.isParticipant,
                                isGroupLeader: m.user.isGroupLeader,
                                /* @ts-ignore */
                                id: getUserByEmailOrPhone(m.user.email, m.user.mobilePhone, props.users).id,
                            },
                            state: null,
                            errorMessage: null,
                            isValid: true,
                            errorType: null,
                        };
                    });
                    addGroupMembership(usersForMembership as EmployeesToBeAddedModel[], props.users);
                } else {
                    addUsers(previewEmployees);
                }
            }
        }
    };

    // New

    const removeEmployee = (employee: EmployeesToBeAddedModel) => {
        setPreviewEmployees(s => [...s.filter(e => e.tempId !== employee.tempId)]);
    };

    const updateEmployee = (employee: EmployeesToBeAddedModel) => {
        setPreviewEmployees(s => [...s.map(e => (e.tempId === employee.tempId ? employee : e))]);
    };

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={() => {
                    props.hide();
                }}
                size={"xl"}
                animation={false}
            >
                <AppLoader loading={isPending} />
                <Modal.Header closeButton aria-label="Hide">
                    <Modal.Title>{t("COMMON_PREVIEW")}</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Table responsive className="preview-group-employees" borderless={true}>
                            <thead>
                                <tr>
                                    <th>{t("PROFILE_FIRST_NAME")}</th>
                                    <th>{t("PROFILE_LAST_NAME")}</th>
                                    <th>{t("PROFILE_EMAIL")}</th>
                                    <th>{t("ACCOUNTOWNER_UPDATE_FORM_MOBILEPHONE")}</th>
                                    <th>PL</th>
                                    <th>GL</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {previewEmployees &&
                                    previewEmployees.length > 0 &&
                                    previewEmployees?.map(groupEmp => {
                                        //const emp = groupEmp.user;
                                        //const isInvalid = !groupEmp.isValid;
                                        return (
                                            <PreviewGroupEmployeeRow
                                                key={groupEmp.tempId}
                                                users={props.users}
                                                employee={groupEmp}
                                                removeEmployee={() => removeEmployee(groupEmp)}
                                                setEmployee={groupEmp =>
                                                    updateEmployee(groupEmp as EmployeesToBeAddedModel)
                                                }
                                                checkUserGroupOrAlreadyExist={checkUserGroupOrAlreadyExist}
                                                hasDuplicateEmail={email =>
                                                    previewEmployees.filter(g => g.user.email === email).length > 1
                                                }
                                                hasDuplicatePhone={mobilePhone =>
                                                    previewEmployees.filter(g => g.user.mobilePhone === mobilePhone)
                                                        .length > 1
                                                }
                                            />
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <PrimaryButton
                            onClick={e => {
                                e.preventDefault();
                                handleSaveEmployees();
                            }}
                            disabled={!isAllEntriesValid || !previewEmployees || previewEmployees.length === 0}
                            //disabled={( previewEmployees && previewEmployees.length === 0 ) || ( errors && errors > 0 ) ? true : false}
                            type="submit"
                        >
                            {t("RESOURCES_ADD_EMPLOYEES")}
                        </PrimaryButton>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
