import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import "./scss/matrix.scss";
import { QuestionLabel } from "./Components/QuestionLabel";
import useDebounce from "../UseDebounce/UseDebounce";
import { ExtendAnswer, QuestionTemplateProps } from "./models/QuestionTemplateProps";
import { Answer, Question, User } from "@/api-client";
import { useUserContext } from "../../common/user/context";
import { MaterialUiStyledSlider } from "../MaterialUiStyledSlider";
import { darkSmoke } from "../../common/colors";

/*  eslint-disable react-hooks/exhaustive-deps */

/**
 * Wrapped model to add properties needed while handling matrix answers in this component
 */
interface MatrixGridAnswer {
    answer: ExtendAnswer;
    isSkipped: boolean;
    hasValue: boolean;
    x: number;
    y: number;
    label?: string;
    isValid?: boolean;
}

/**
 * Parse X,Y into numbers
 * @param answerText
 */
const parseXY = (answerText: string) => {
    const answers = answerText ? answerText.split(",") : [];
    return {
        x: answers.length === 2 ? parseInt(answers[0], 10) : NaN,
        y: answers.length === 2 ? parseInt(answers[1], 10) : NaN,
    };
};

//change this
export const getInitValues = (
    question: Question,
    existingAnswers: Answer[],
    user: User,
    currentProgress: boolean
): MatrixGridAnswer[] => {
    /* @ts-ignore */
    return question.options.map(a => {
        //const existingAnswer = existingAnswers.find(x => x.optionAnswerId == a.answer_id);
        const existingAnswer = existingAnswers.find(x => x.optionAnswerId === a.id);
        const textValue = question.minAnswer === null ? 0 : question.minAnswer;
        const answer = {
            id: undefined,
            optionAnswerId: a.id,
            text: `${textValue},${textValue}`,
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            userId: user.id,
            ...existingAnswer,
        };
        const xy = parseXY(answer.text);
        return {
            answer: answer,
            isSkipped: !!(currentProgress && !existingAnswer?.text),
            //hasValue: isSkipped ? true : false,
            hasValue: true,
            x: xy.x,
            y: xy.y,
            label: a.text,
        };
    });
};

interface GridMatrixAnswersProps extends QuestionTemplateProps {
    currentProgress: boolean | null;
    index: number;
    placeholder: string;
    error: string[] | undefined;
}

export const MatrixGridAnswers = (props: GridMatrixAnswersProps): JSX.Element => {
    const { t } = useTranslate();

    const userContext = useUserContext();
    const [answers, setAnswers] = useState<MatrixGridAnswer[]>();
    const [errors, setErrors] = useState<string[]>();

    useEffect(() => {
        if (props.question && userContext.user && props.question.options) {
            const initValues = getInitValues(
                props.question,
                props.answers,
                userContext.user,
                /* @ts-ignore */
                props.currentProgress
            );
            setAnswers(initValues);
        }
    }, [props.question, userContext, props.currentProgress]);

    const answersStateChange = useDebounce(answers, 500);

    useEffect(() => {
        if (answers != null && props.question) {
            if (props.question && answers.filter(x => x.isValid === undefined).length === answers.length) {
                return;
            }
            /* @ts-ignore */
            const error = [];
            const isValid = true;
            /* @ts-ignore */
            setErrors(error);
            props.setValidation(isValid, true);
            props.setAnswers(answers.filter(a => a.hasValue === true).map(x => x.answer));
        }
    }, [answersStateChange]);

    const handleSkipChange = (answer: MatrixGridAnswer, e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        answer.isSkipped = e.target.checked;
        /* @ts-ignore */
        const previousState = [...answers];
        previousState[index].isSkipped = e.target.checked;
        previousState[index].hasValue = true;
        previousState[index].isValid = false;
        if (e.target.checked) {
            previousState[index].isValid = true;
            previousState[index].answer.isSkipped = true;
        }
        setAnswers(previousState);
    };

    const setAnswerValue = (answer: MatrixGridAnswer, index: number, x?: number, y?: number) => {
        answer.x = x ?? answer.x;
        answer.y = y ?? answer.y;
        /* @ts-ignore */
        const previousState = [...answers];
        previousState[index].isValid = false;
        if (x && y) {
            previousState[index].isValid = true;
        }
        previousState[index].answer.text = `${answer.x},${answer.y}`;
        const getMinAndMax = previousState[index].answer.text.split(",");
        if (getMinAndMax[0] && getMinAndMax[1]) {
            previousState[index].hasValue = true;
        } else {
            previousState[index].hasValue = true;
        }
        setAnswers(previousState);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{props.placeholder}</h4>
                        <p>{props.question.text}</p>
                    </div>
                </div>
            </div>
            <div className="matrix-options block-content-option">
                {answers != null &&
                    answers.map((answer, ix) => {
                        if (!answer) {
                            throw new Error("No matching answer found");
                        }
                        return (
                            <React.Fragment key={ix}>
                                <div className={`matrix-grid row ${ix > 0 ? "mt-1" : ""}`}>
                                    <div className="col-sm-12 top-container">
                                        <div className="matrix-title">{answer.label}</div>
                                        {props.question.allowSkip === true && (
                                            <div className="matrix-skip-button" style={{ fontSize: "14px" }}>
                                                <label>
                                                    <input
                                                        disabled={!!props.currentProgress}
                                                        checked={answer.isSkipped}
                                                        type="checkbox"
                                                        onChange={e => handleSkipChange(answer, e, ix)}
                                                    />{" "}
                                                    {t("PROGRAM_MY_TRAINING_SKIP_THIS_QUESTION")}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`matrix-grid row matrix-layout-border d-block ${
                                        answer.isSkipped ? "change-matrix-style" : ""
                                    }`}
                                >
                                    <p
                                        className={`block-content-text-inner ${
                                            answer.isSkipped ? "change-descriptionY-style" : ""
                                        }`}
                                    >
                                        {props.question.description}
                                    </p>
                                    {/* Call X-Axis */}
                                    <div className="col-md-12">
                                        <div className="matrix-slider mb-4">
                                            <MaterialUiStyledSlider
                                                labelColor={darkSmoke.hex}
                                                value={answer.x}
                                                min={
                                                    props.question.minAnswer === undefined
                                                        ? 0
                                                        : props.question.minAnswer
                                                }
                                                max={
                                                    props.question.maxAnswer === undefined
                                                        ? 0
                                                        : props.question.maxAnswer
                                                }
                                                onChange={value => setAnswerValue(answer, ix, value, undefined)}
                                                disabled={props.currentProgress || answer.isSkipped}
                                            />
                                        </div>
                                    </div>

                                    <QuestionLabel
                                        min_answer_label={props.question.minAnswerLabel}
                                        max_answer_label={props.question.maxAnswerLabel}
                                    />
                                    <p
                                        className={`block-content-text-inner mb-5${
                                            answer.isSkipped ? "change-descriptionY-style" : ""
                                        }`}
                                    >
                                        {props.question.descriptionY}
                                    </p>
                                    {/* Call Y-Axis */}

                                    <div className="col-md-12">
                                        <div className="matrix-slider mb-4">
                                            <MaterialUiStyledSlider
                                                labelColor={darkSmoke.hex}
                                                value={answer.y}
                                                min={
                                                    props.question.minAnswer === undefined
                                                        ? 0
                                                        : props.question.minAnswer
                                                }
                                                max={
                                                    props.question.maxAnswer === undefined
                                                        ? 0
                                                        : props.question.maxAnswer
                                                }
                                                onChange={value => setAnswerValue(answer, ix, undefined, value)}
                                                disabled={!!props.currentProgress}
                                            />
                                        </div>
                                    </div>
                                    <QuestionLabel
                                        min_answer_label={props.question.minAnswerYLabel}
                                        max_answer_label={props.question.maxAnswerYLabel}
                                    />
                                    {errors != null && errors.length > 0 && (
                                        <div key={`error_${ix}`} className="ms-md-3">
                                            <div className="clearfix w-100 text-danger">{errors[ix]}</div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                {((errors != null && errors.length === 0) || errors === undefined) && props.error != null && (
                    <p className="error learfix w-100 text-danger">{t("MODULE_VALIDATE_MATRIX_REQUIRED")}</p>
                )}
            </div>
        </>
    );
};
