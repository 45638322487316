﻿import React from "react";
import { getTrainingUrl } from "../../common/navHelper";
import { Course, Group, ParticipantProgress } from "@/api-client";
import { User } from "@/api-client";
import { NavButton } from "@/components";

export const MyTrainingShortcut = (props: {
    group: Group;
    user: User;
    course: Course;
    userProgress: ParticipantProgress[];
}) => {
    const trainingUrl = getTrainingUrl(props.user, props.userProgress, props.course, props.group);
    return (
        <NavButton
            eventName={{ object: "program", action: "mytrainingclick" }}
            link={trainingUrl}
            textKey={"PROGRAM_MY_TRAINING"}
        />
    );
};
