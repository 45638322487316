import React, { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { ActionIcon, Flex, ScrollArea, Text } from "@mantine/core";
import { CardBlock } from "../../components/CardBlock";
import { TeamMessageEditor } from "./TeamMessageEditor";
import { Icons } from "common/Icons";
import RenderIf from "../../components/render-if/render-if";
import { customEvents } from "../../analytics/customEvents/customEvents";
import { useTeamMessageStyles } from "./TeamMessageStyles";

type TeamMessageProps = {
    clientId: number;
    teamId: number;
    message: string | null;
    onMessageChange: (message: string | null) => void;
    hasEditPermission: boolean;
};

const EditIcon = (props: { inEditMode: boolean; hasMessage: boolean }) => {
    if (props.inEditMode) {
        return <Icons.Cross />;
    }

    if (props.hasMessage) {
        return <Icons.Edit />;
    }

    return <Icons.Add />;
};

export const TeamMessage = (props: TeamMessageProps) => {
    const { t } = useTranslate();
    const [editMode, setEditMode] = useState(false);
    const { classes } = useTeamMessageStyles();

    const hasMessage = props.message !== null && props.message.length > 0;

    const Message = useMemo(
        () => () => {
            if (hasMessage) {
                return (
                    <ScrollArea className={classes.message} type="auto" offsetScrollbars>
                        <ReactMarkdown>{props.message!}</ReactMarkdown>
                    </ScrollArea>
                );
            }

            return (
                <Flex justify={"center"} align={"center"} className={classes.noMessageContainer}>
                    <Text color={"gray.5"}> {t("HOME_NO_MESSAGE")} </Text>
                </Flex>
            );
        },
        [props.message]
    );

    const onMessageChanged = (newMessage: string | null) => {
        if (hasMessage) {
            customEvents.raiseUiTeamMessageChanged();
        } else {
            customEvents.raiseUiTeamMessageCreated();
        }

        props.onMessageChange(newMessage);
        setEditMode(false);
    };

    return (
        <CardBlock classNames={{ card: classes.card }}>
            <Flex rowGap={"md"} direction={"column"} h={"100%"}>
                <Flex justify={"space-between"}>
                    <Text size={"xl"}>{t("HOME_MESSAGE")}</Text>
                    <RenderIf show={props.hasEditPermission}>
                        <ActionIcon onClick={() => setEditMode(!editMode)}>
                            <EditIcon inEditMode={editMode} hasMessage={hasMessage} />
                        </ActionIcon>
                    </RenderIf>
                </Flex>

                <Flex className={classes.contentContainer} direction={"column"}>
                    <RenderIf show={!editMode}>
                        <Message />
                    </RenderIf>
                    <RenderIf show={editMode}>
                        <TeamMessageEditor
                            currentMessage={props.message}
                            teamId={props.teamId}
                            clientId={props.clientId}
                            onSave={onMessageChanged}
                        />
                    </RenderIf>
                </Flex>
            </Flex>
        </CardBlock>
    );
};
