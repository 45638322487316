import { Group, GroupMember } from "@/api-client";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useOrganisationContext } from "../../../common/OrganisationContext";
import Slideshow from "../../../components/Slideshow";
import { AddGroupEmployees } from "../../Components/AddGroupEmployees";
import { GroupsWithGroupTypes } from "../../Planning/helper";
import { EmployeesListDisplay } from "../Components/EmployeesListDisplay";
import GroupMembersList from "../Components/GroupMembersList";
import { NavItemDisplay, ResourcesNavItem } from "../Components/NavItemDisplay";
import { Sidebar } from "../Components/Sidebar";
import { countUsersInGroupsByInviteStatus } from "../helper";
import "./style.scss";
import { createStyles } from "@mantine/core";
import { NavButton } from "@/components";

const ListDepartment: FunctionComponent = (): JSX.Element => {
    const { t } = useTranslate();
    return (
        <>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 sub-heading mt-2">
                <p>{t("RESOURCES_PLAN_TEXT_P1")}</p>
                <p>{t("RESOURCES_PLAN_TEXT_P2")}</p>
            </div>
        </>
    );
};

interface PlanCourseGroupMembersProps {
    courseId: number;
}

const useStyles = createStyles(() => ({ buttonContainer: { position: "absolute", top: "50%" } }));

const PlanCourseGroupMembers = (props: PlanCourseGroupMembersProps): JSX.Element => {
    const { classes } = useStyles();
    const { t } = useTranslate();
    const courseId = props.courseId;
    const [allGroups, setAllGroups] = useState<Group[]>([]);
    const accountOwnerContext = useOrganisationContext();
    const groups = accountOwnerContext?.groups;
    const groupTypes = accountOwnerContext?.groupTypes;
    const [isAllPeople, setAllPeople] = useState<boolean>(true);

    const [selectedGroupId, setSelectedGroupId] = useState<number>(0);

    useEffect(() => {
        if (groups && groups.length > 0) {
            setAllGroups(groups.filter(g => g.courseIds.includes(courseId)));
        }
    }, [courseId, groups]);

    const showEmployees = (name: string, id: number) => {
        setSelectedGroupId(id);
        if (name === "all") {
            setAllPeople(true);
        } else {
            setAllPeople(false);
        }
    };

    /* @ts-ignore */
    const groupMembersByGroupId: GroupMember[] | null =
        allGroups && allGroups?.find(g => g.id === selectedGroupId)?.members;
    //const getAllMembers: GroupMember[] | null = getAllMembersOfGroups(allGroups);
    //const usersInGroups = countUsersInGroups(groupsByCourseId);

    // Check if user can move next
    const inviteWithNotSentCount = countUsersInGroupsByInviteStatus(allGroups, "NotSent");

    const canMoveNext = inviteWithNotSentCount > 0;
    const button = canMoveNext ? (
        <div className={classes.buttonContainer}>
            <NavButton link={`/account-owner/resources/send-invites/${courseId}`} textKey="COMMON_NEXT" />
        </div>
    ) : null;
    /* @ts-ignore */
    const groupsWithGroupTypes = GroupsWithGroupTypes(allGroups, groupTypes);

    return (
        <>
            <Slideshow
                previousLink={`/account-owner/resources/plan-course-groups/${courseId}`}
                /* @ts-ignore */
                nextLink={canMoveNext ? `/account-owner/resources/send-invites/${courseId}` : null}
                /* @ts-ignore */
                button={button}
            >
                <React.Fragment>
                    <div className="employees-slide row">
                        <div className="col-md-12 heading">
                            <p className="content-label">{t("RESOURCES_PLAN_A_NEW_COURSE")}</p>
                            <h2>{t("RESOURCES_DEFINE_PARTICIPANTS_GROUPS_MEMBERS")}</h2>
                        </div>
                        <ListDepartment />
                    </div>
                    <div className="employees-slide sidebar-section row no-gutters">
                        <Sidebar class="col-lg-4 col-md-4 col-sm-12 resources-leftnav">
                            <ul>
                                <li className={isAllPeople ? "active" : ""} onClick={() => showEmployees("all", 0)}>
                                    <div className={isAllPeople ? "active" : ""}>{t("RESOURCES_ALL_EMPLOYEES")}</div>
                                    {/* <span>{users?.length} {t("RESOURCES_TOTAL")}</span> */}
                                </li>
                                {groupsWithGroupTypes &&
                                    groupsWithGroupTypes?.map((gt, ix) => {
                                        return (
                                            <React.Fragment key={ix}>
                                                {gt.groupTypeName !== null && gt.groupTypeName !== undefined && (
                                                    <li className="group-type">{gt.groupTypeName}</li>
                                                )}
                                                {gt.groups.map((group, ix) => {
                                                    const navItem: ResourcesNavItem = {
                                                        /* @ts-ignore */
                                                        id: group.id,
                                                        title: group.name,
                                                        count: group.members.length,
                                                    };
                                                    return (
                                                        <NavItemDisplay
                                                            courseId={courseId}
                                                            clickHandler={() =>
                                                                showEmployees("other", group.id as number)
                                                            }
                                                            key={ix}
                                                            item={navItem}
                                                            level={0}
                                                            isVisited={selectedGroupId}
                                                        />
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                {/* <li><Link to={`/`}>+ Add Group</Link></li> */}
                            </ul>
                            <p className="users-in-group-summary">
                                {allGroups && allGroups.flatMap(x => x.members).length}
                                {/* {t("RESOURCES_TOTAL")} */}
                            </p>
                        </Sidebar>
                        <div className="col-lg-8 col-md-8 col-sm-12 pl-4 pr-4">
                            {isAllPeople && (
                                <>
                                    <EmployeesListDisplay
                                        //groupId={selectedGroupId}
                                        employees={groupMembersByGroupId}
                                        //isAllPeople={isAllPeople}
                                        courseId={courseId}
                                    />
                                    <AddGroupEmployees />
                                </>
                            )}

                            {!isAllPeople && <GroupMembersList groupId={selectedGroupId} courseId={courseId} />}
                        </div>
                    </div>
                </React.Fragment>
            </Slideshow>
        </>
    );
};
export default PlanCourseGroupMembers;
