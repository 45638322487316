import React from "react";
import { ListGroup } from "react-bootstrap";
import { Question } from "@/api-client";

type MultipleChoiceQuestionProps = {
    question: Question;
    placeholder: string;
};

export const MultipleChoiceQuestions = React.memo(function MultipleChoiceQuestion(props: MultipleChoiceQuestionProps) {
    const { question, placeholder } = props;
    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        {question && (
                            <React.Fragment>
                                <p>{question.text}</p>
                                <p>{question.description}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 text-secondary">
                    <div className="block-content-option">
                        {question && (
                            <div className="block-content-option">
                                <p className="px-5 question_type"></p>
                                <ListGroup>
                                    {question &&
                                        question.options &&
                                        question.options.map((o, index) => {
                                            return (
                                                <ListGroup.Item
                                                    id={`question-${index}-${question?.questionTypeId}-questions`}
                                                    key={`question_${index}_${question?.id}`}
                                                    className={"inactive p-1 border mb-2"}
                                                >
                                                    <div className="custom-control custom-checkbox row">
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input me-1"
                                                                id={`questions.question-${o?.id}.value`}
                                                                name={`questions.question-${o?.id}.value`}
                                                                disabled={true}
                                                            />
                                                            <label
                                                                htmlFor={`questions.question-${o?.id}.value`}
                                                                className="custom-control-label"
                                                            >
                                                                <span
                                                                    className="mb-1 position-absolute"
                                                                    style={{ top: "2px" }}
                                                                >
                                                                    {o.text}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
