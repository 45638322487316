import { MRT_ColumnDef, MRT_RowSelectionState } from "mantine-react-table";
import { useTranslate } from "@tolgee/react";
import { ActionIcon, Flex, HoverCard, Text, Title } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Icons, createApi } from "@/common";
import { Team } from "@/api-client";
import { PrimaryButton, Table } from "@/components";

type TableRow = {
    team: string;
    email: string;
    phoneNumber: string;
    userId: number;
    teamId: number;
};

export const SendInvitesPage = () => {
    const { t } = useTranslate();
    const [memberships, setState] = useState<TableRow[] | null>(null);
    const [selectedRows, setSelectedRows] = useState<MRT_RowSelectionState>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const reload = () => {
        const inner = async () => {
            const api = createApi();
            const teams = await api.groups.query(null, null, null, null);
            const teamCourses = await api.groupCourses.query(null, null, null);

            const hasProgramAssigned = (team: Team) => {
                return teamCourses.any(gc => gc.groupId === team.id);
            };

            const mapping: TableRow[] = teams
                .filter(team => team.status === "Active" && hasProgramAssigned(team))
                .flatMap(g =>
                    g.members
                        .filter(x => x.inviteStatus === "NotSent" && x.user!.status === "Active")
                        .map(u => ({
                            team: g.name,
                            email: u.user!.email!,
                            phoneNumber: u.user!.mobilePhone!,
                            userId: u.userId,
                            teamId: g.id!,
                        }))
                );

            setState(mapping);
        };

        return inner().catchWithToast();
    };

    useEffect(() => {
        reload();
    }, []);

    const sendInvites = async (memberships: TableRow[]) => {
        setIsSubmitting(true);
        const selectedMemberships = memberships.filter(x => x.userId in selectedRows);

        const api = createApi();

        const operations = selectedMemberships.map(row => {
            return api.accountOwners.sendInvite(row.userId, row.teamId);
        });

        await Promise.all(operations);
        setIsSubmitting(false);
        reload();
    };

    const columns = useMemo<MRT_ColumnDef<TableRow>[]>(
        () => [
            {
                header: t("COMMON_TEAM"),
                accessorKey: "team",
            },
            {
                header: t("ACCOUNTOWNER_USERS_EMAIL"),
                accessorKey: "email",
            },

            {
                header: t("ACCOUNTOWNER_PHONE_NUMBER"),
                accessorKey: "phoneNumber",
            },
        ],
        []
    );

    const toolbarActions = useCallback(() => {
        return (
            <Flex align="center">
                <PrimaryButton
                    onClick={() => sendInvites(memberships!).catchWithToast()}
                    loading={isSubmitting}
                    disabled={Object.keys(selectedRows).length === 0}
                    eventName={{ object: "ao_sendinvites", action: "send" }}
                >
                    {t("RESOURCES_SEND_INVITES")}
                </PrimaryButton>
                <HoverCard shadow="md">
                    <HoverCard.Target>
                        <ActionIcon ml="sm">
                            <Icons.Info />
                        </ActionIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        <>
                            <Title size="h4">{t("ACCOUNTOWNER_SEND_INVITES_HELPTEXT_HEADING")}</Title>
                            <Text fz="sm">{t("ACCOUNTOWNER_SEND_INVITES_HELPTEXT")}</Text>
                        </>
                    </HoverCard.Dropdown>
                </HoverCard>
            </Flex>
        );
    }, [memberships, isSubmitting, selectedRows]);

    return (
        <Table
            columns={columns}
            data={memberships}
            initialColumnFilterState={[]}
            initialSort={[
                { id: "team", desc: false },
                { id: "email", desc: false },
            ]}
            toolbarActions={toolbarActions}
            rowSelection={{
                onRowSelectionChange: setSelectedRows,
                selectedRows: selectedRows,
                getRowId: row => row.userId?.toString(),
            }}
        />
    );
};
