import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { createStyles } from "@mantine/core";
import { useHistory, TFunction, yupExtensions } from "@/common";
import { Login, Signup, TokenStatus } from "@/api-client";
import { FullLogo } from "../../assets/index";
import { createApi } from "../../common/api";
import { useQuery } from "../../common/utils";
import { Notify, showToast } from "../../components/Notify";
import { AppLoader, PrimaryButton } from "@/components";
import { LoginContainer } from "../../login/LoginContainer";
import { Languages } from "../../translation";
import { hashPassword } from "./helper";
import "./style.scss";
import { UrlFactory } from "../../routing/UrlFactory";
import RenderIf from "../../components/render-if/render-if";
import { SUPPORT_EMAIL } from "../../constants";
import { showLoginErrorNotification } from "../../components/showLoginErrorNotification";
import { useAuth } from "../../auth/useAuth";
import { customEvents } from "../../analytics/customEvents/customEvents";

const useStyles = createStyles(() => ({
    buttonWrapper: {
        position: "absolute",
        margin: "auto",
        left: "0",
        right: "0",
        width: "80%",
    },
}));

/*  eslint-disable react-hooks/exhaustive-deps */
const AcceptInviteSchema = (t: TFunction) => {
    return Yup.object().shape({
        firstName: Yup.string().required(t("REGISTRATION_FIRST_NAME_REQUIRED")),
        lastName: Yup.string().required(t("REGISTRATION_LAST_NAME_REQUIRED")),
        resetPassword: yupExtensions.passwordValidator(
            t("REGISTRATION_PASSWORD_REQUIRED"),
            t("REGISTRATION_PASSWORD_VALID")
        ),
        selectLanguage: Yup.string().required(t("LOGIN_LANGUAGE_REQUIRED")),
        acceptTerms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    });
};

interface SubmitFormValues {
    title: string;
    firstName: string;
    lastName: string;
    resetPassword: string;
    selectLanguage: string;
    acceptTerms: boolean;
}

const styleUserCard = {
    height: "auto",
    width: "350px",
    "margin-top": "30px",
    "margin-bottom": "20px",
    position: "relative",
    display: "flex",
    "justify-content": "center",
    "flex-direction": "column",
    padding: "20px",
    "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    "-webkit-box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    "-moz-box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    "border-radius": "5px",
    "background-color": "#fff",
} as React.CSSProperties;

const DisplayInvalidInvite = (): JSX.Element => {
    const { t } = useTranslate();
    return (
        <div className="d-flex justify-content-center h-100">
            <div style={styleUserCard}>
                <div className="pl-3 pr-3 pt-3">
                    <h5 className="text-center">{t("REGISTRATION_INVITE_INVALID")}</h5>
                    <p className="text-start mt-3">{t("REGISTRATION_INVITE_INVALID_DESCRIPTION")}</p>
                </div>
                <div className="pl-3 pr-3 pt-3 text-center">
                    <a href={`mailto:${SUPPORT_EMAIL}`}>
                        <PrimaryButton type="button" eventName={{ object: "acceptinvite", action: "contactsupport" }}>
                            {t("REGISTRATION_CONTACT_SUPPORT")}
                        </PrimaryButton>
                    </a>
                </div>
                <div className="d-flex justify-content-around pt-4 pb-4">
                    <div className="text-center">
                        <Link to="/login">{t("COMMON_LOGIN")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AcceptInvite = (): JSX.Element => {
    const { t } = useTranslate();
    const api = createApi();
    const LoginLogo: string = FullLogo;
    const { classes } = useStyles();
    const history = useHistory();
    const query = useQuery();
    const { signIn } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tokenStatus, setTokenStatus] = useState<TokenStatus>();
    const [registrationError, setRegistrationError] = useState<string>();

    useEffect(() => {
        const email: string | null = query.get("email");
        const token: string | null = query.get("token");

        if (query && email && token) {
            const loginModel: Login = {
                email: email,
                password: token, // token is password on invites
            };

            api.login.checkInviteToken(loginModel).then(
                status => {
                    setTokenStatus(status);
                },
                reason => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    customEvents.raiseAcceptInviteTokenError(reason, email);
                }
            );
        }
    }, []);

    const submitLoginForm = async (values: SubmitFormValues) => {
        setRegistrationError(undefined);
        setIsLoading(true);

        const email: string | null = query.get("email");
        const token: string | null = query.get("token");

        if (email !== null && token !== null) {
            const signup: Signup = {
                title: values.title,
                firstName: values.firstName,
                lastName: values.lastName,
                password: hashPassword(values.resetPassword),
                language: values.selectLanguage,
                email: email,
                token: token,
                clearTextPassword: values.resetPassword,
            };
            await api.login
                .acceptInvite(signup)
                .then(
                    async acceptInviteResult => {
                        if (acceptInviteResult.resultCode === "Ok") {
                            await signIn(acceptInviteResult.token);
                            history.push(UrlFactory.home.create({}));
                            showToast("You are registered Successfully", "success");
                        } else {
                            history.push(UrlFactory.login.create({}));
                            showLoginErrorNotification(acceptInviteResult, t);
                        }
                    },
                    reason => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        customEvents.raiseDefaultError(reason);
                        showToast(t("COMMON_DEFAULT_ERROR"), "warning");
                    }
                )
                .then(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            {registrationError && <Notify message={t("COMMON_DEFAULT_ERROR")} id="registersuccess" type="warning" />}
            <AppLoader loading={isLoading} />
            <LoginContainer>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <img src={LoginLogo} className="main-login-logo" alt="Hups" />
                    </div>

                    <RenderIf show={tokenStatus === "Invalid"}>
                        <DisplayInvalidInvite />
                    </RenderIf>
                    <RenderIf show={tokenStatus === "Valid"}>
                        <div className="d-flex justify-content-center h-100">
                            <div style={styleUserCard}>
                                <div className="pl-3 pr-3 pt-3">
                                    <h5 className="text-start">{t("REGISTRATION_HEADING")}</h5>
                                    <p className="text-start">{t("REGISTRATION_SUBHEADING")}</p>
                                </div>
                                <Formik
                                    initialValues={{
                                        title: "",
                                        firstName: "",
                                        lastName: "",
                                        resetPassword: "",
                                        selectLanguage: "",
                                        acceptTerms: false,
                                    }}
                                    validationSchema={AcceptInviteSchema(t)}
                                    onSubmit={submitLoginForm}
                                    autoComplete="off"
                                >
                                    {props => {
                                        const { errors, touched } = props;
                                        return (
                                            <Form className="text-center pl-3 pr-3 pt-1">
                                                <div className="acceptInvite mb-3">
                                                    <label htmlFor="title" className="form-label">
                                                        {t("ACCOUNTOWNER_UPDATE_FORM_TITLE")}
                                                    </label>
                                                    <Field
                                                        autoComplete="off"
                                                        type="text"
                                                        name="title"
                                                        placeholder={t("ACCOUNTOWNER_UPDATE_FORM_TITLE")}
                                                        className={`form-control${
                                                            errors.title && touched.title ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="title"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="acceptInvite mb-3">
                                                    <label htmlFor="firstName" className="form-label">
                                                        {t("REGISTRATION_FIRST_NAME")}
                                                    </label>
                                                    <Field
                                                        autoComplete="off"
                                                        type="text"
                                                        name="firstName"
                                                        placeholder={t("REGISTRATION_FIRST_NAME")}
                                                        className={`form-control${
                                                            errors.firstName && touched.firstName ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="firstName"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="acceptInvite mb-3">
                                                    <label htmlFor="lastName" className="form-label">
                                                        {t("REGISTRATION_LAST_NAME")}
                                                    </label>
                                                    <Field
                                                        autoComplete="off"
                                                        type="text"
                                                        name="lastName"
                                                        placeholder={t("REGISTRATION_LAST_NAME")}
                                                        className={`form-control${
                                                            errors.lastName && touched.lastName ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="lastName"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="acceptInvite mb-3">
                                                    <label htmlFor="resetPassword" className="form-label">
                                                        {t("REGISTRATION_PASSWORD")}
                                                    </label>
                                                    <Field
                                                        autoComplete="new-password"
                                                        type="password"
                                                        name="resetPassword"
                                                        placeholder="Password"
                                                        className={`form-control${
                                                            errors.resetPassword && touched.resetPassword
                                                                ? " is-invalid"
                                                                : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        name="resetPassword"
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="acceptInvite mb-3">
                                                    <label htmlFor="selectLanguage" className="form-label">
                                                        {t("LOGIN_SELECT_LANGUAGE")}
                                                    </label>
                                                    <Field
                                                        className={`form-select${
                                                            errors.selectLanguage && touched.selectLanguage
                                                                ? " is-invalid"
                                                                : ""
                                                        }`}
                                                        as="select"
                                                        name="selectLanguage"
                                                    >
                                                        <option value="">{t("LOGIN_SELECT_LANGUAGE")}</option>
                                                        {Languages.map(l => (
                                                            <option key={l.value} value={l.value}>
                                                                {t(l.name)}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="selectLanguage"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="acceptInvite-terms mb-5">
                                                    <div className="form-check">
                                                        <Field
                                                            type="checkbox"
                                                            name="acceptTerms"
                                                            className={`form-check-input ${
                                                                errors.acceptTerms && touched.acceptTerms
                                                                    ? " is-invalid"
                                                                    : ""
                                                            }`}
                                                        />
                                                        <label htmlFor="acceptTerms" className="form-check-label">
                                                            I agree to the
                                                            <Link target="_blank" to="/terms">
                                                                Terms of Service
                                                            </Link>{" "}
                                                            and
                                                            <Link target="_blank" to="/policies">
                                                                Privacy Policy
                                                            </Link>
                                                        </label>
                                                    </div>
                                                    <ErrorMessage
                                                        name="acceptTerms"
                                                        component="span"
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                    />
                                                </div>
                                                <div className={classes.buttonWrapper}>
                                                    <PrimaryButton
                                                        fullWidth
                                                        type="submit"
                                                        eventName={{ object: "acceptinvite", action: "accept" }}
                                                        disabled={
                                                            !!(
                                                                errors.firstName ||
                                                                errors.lastName ||
                                                                errors.resetPassword
                                                            )
                                                        }
                                                    >
                                                        Accept Invite
                                                    </PrimaryButton>
                                                    {/* {accountStatus.pending ? (<BeatLoader size={15} color={'#fff'} loading={accountStatus.pending} />) : t('REGISTRATION_SUBMIT_BUTTON')} */}
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </RenderIf>
                </div>
            </LoginContainer>
        </>
    );
};

export default AcceptInvite;
