﻿import { GroupsClient } from "./legacy";
import { ApiConfiguration, Group, Operation } from "./contracts";

export class GroupClient {
    private readonly client: GroupsClient;

    constructor(config: ApiConfiguration) {
        this.client = new GroupsClient(config);
    }

    query = (
        userId: number | null | undefined,
        allGroups: boolean | null | undefined,
        courseId: number | null | undefined,
        groupTypeId: number | null | undefined
    ): Promise<Group[]> => {
        return this.client.query(userId, allGroups, courseId, groupTypeId) as Promise<Group[]>;
    };

    post = (model: Group): Promise<Group> => {
        return this.client.post(model) as Promise<Group>;
    };

    get = (id: number): Promise<Group> => {
        return this.client.get(id) as Promise<Group>;
    };

    patch = (id: number, patch: Operation[]): Promise<Group> => {
        return this.client.patch(id, patch) as Promise<Group>;
    };

    delete = (id: number): Promise<Group> => {
        return this.client.delete(id) as Promise<Group>;
    };
}
