import { useState, useEffect } from "react";
import { ActionIcon, Container, CopyButton, Flex, SimpleGrid, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { Breadcrumbs } from "pages/AccountOwner/Breadcrumbs";
import { Icons } from "common/Icons";
import { useTranslate } from "@tolgee/react";
import { EditUserStatus } from "components/EditUserStatus";
import { AppLoader, CardBlock, Form, FormInput, RenderIf, Section } from "@/components";
import { createApi, createPatch, UrlFactory } from "@/common";
import { UserRoles, UserStatuses } from "@/api-client";
import { roleCheckBoxItems } from "./roleCheckBoxItems";
import { adminFormValidator } from "./adminFormValidator";

type Admin = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRoles[];
    status: UserStatuses;
    hasAcceptedInvite: boolean;
    inviteLink: string;
};

type EditAdminForm = yup.InferType<typeof adminFormValidator>;

export const EditAdminPage = (props: { userId: number }) => {
    const [admin, setAdmin] = useState<Admin | null>(null);
    const { t } = useTranslate();

    const items = [
        { title: t("SYSADMIN_USERS"), href: UrlFactory.sysadmin.users.create({}) },
        { title: t("SYSADMIN_BREADCRUMBS_EDIT_ADMIN") },
    ];

    const createInviteLink = (email: string, password: string) =>
        `${window.location.protocol}//${window.location.hostname}/accept-invite?email=${encodeURIComponent(
            email
        )}&token=${encodeURIComponent(password)}`;

    const getAdmin = () => {
        return createApi()
            .users.get(props.userId)
            .then(x =>
                setAdmin({
                    email: x.email!,
                    roles: x.roles,
                    firstName: x.firstName!,
                    lastName: x.lastName!,
                    status: x.status,
                    id: x.id!,
                    hasAcceptedInvite: x.hasAcceptedInvite,
                    inviteLink: createInviteLink(x.email!, x.password!),
                })
            )
            .catchWithToast();
    };

    useEffect(() => {
        getAdmin();
    }, []);

    if (admin === null) {
        return <AppLoader loading />;
    }

    const updateAdmin = (admin: Admin, values: EditAdminForm) => {
        return createApi().users.patch(
            props.userId,
            createPatch(admin, x => {
                x.firstName = values.firstName;
                x.lastName = values.lastName;
                x.email = values.email;
                x.roles = values.roles;
            })
        );
    };

    const initialValues: EditAdminForm = {
        ...admin,
    };

    return (
        <Container>
            <Flex direction="column" rowGap="xl">
                <Breadcrumbs items={items} />
                <CardBlock>
                    <SimpleGrid cols={2}>
                        <Stack>
                            <Form<EditAdminForm>
                                initialValues={initialValues}
                                validationSchema={adminFormValidator}
                                onSubmit={values =>
                                    updateAdmin(admin, values)
                                        .then(() => getAdmin())
                                        .catchWithToastAsync()
                                }
                                submitLabel="SYSADMIN_SAVE"
                            >
                                <Stack>
                                    <FormInput.Text fieldName="email" label="SYSADMIN_EMAIL" />
                                    <FormInput.Text fieldName="firstName" label="SYSADMIN_FIRST_NAME" />
                                    <FormInput.Text fieldName="lastName" label="SYSADMIN_LAST_NAME" />
                                    <FormInput.CheckboxGroup
                                        fieldName="roles"
                                        label="SYSADMIN_ROLE"
                                        options={roleCheckBoxItems(t)}
                                    />
                                </Stack>
                            </Form>
                            <RenderIf show={!admin.hasAcceptedInvite}>
                                <Section title="Invite">
                                    <Flex mb="xl" align="center">
                                        <CopyButton value={admin.inviteLink}>
                                            {({ copied, copy }) => (
                                                <ActionIcon w="100%" onClick={copy}>
                                                    <Flex align="center" columnGap="md">
                                                        <Text size="sm" color="dimmed" lineClamp={2}>
                                                            {admin.inviteLink}
                                                        </Text>
                                                        <Flex>
                                                            {copied ? (
                                                                <Icons.ClipBoardCheck />
                                                            ) : (
                                                                <Icons.ClipBoardCopy />
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </ActionIcon>
                                            )}
                                        </CopyButton>
                                    </Flex>
                                </Section>
                            </RenderIf>
                            <Section title="SYSADMIN_STATUS">
                                <Flex direction="column">
                                    <EditUserStatus
                                        inactivateUserHelperText="SYSADMIN_EDIT_ADMIN_USER_INACTIVE_USERS_CANNOT_LOG_IN"
                                        user={{
                                            status: admin.status,
                                            id: admin.id,
                                            hasAcceptedInvite: admin.hasAcceptedInvite,
                                        }}
                                        onUserStatusChanged={() => getAdmin()}
                                    />
                                </Flex>
                            </Section>
                        </Stack>
                    </SimpleGrid>
                </CardBlock>
            </Flex>
        </Container>
    );
};
