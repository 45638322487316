import React from "react";
import "../../App.scss";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { createStyles, MantineProvider } from "@mantine/core";
import Footer from "../Footer/footer";
import { getLocationClassName } from "./LayoutUtils";
import { navbarWidth } from "./Navbar";

export interface LayoutProps {
    children: React.ReactNode;
    navbar: React.ReactNode;
    banner?: React.ReactNode;
    header: React.ReactNode;
}

const useStyles = createStyles(() => {
    return {
        layoutContainer: {
            display: "flex",
        },
        contentContainer: {
            flex: 1,
            height: "100vh",
            overflowY: "auto",
        },
        contentArea: {
            minHeight: "100vh",
            marginTop: "2rem",
        },
    };
});

export const Layout = (props: LayoutProps): JSX.Element => {
    const location = useLocation();
    const { classes } = useStyles();

    const locationClassName = getLocationClassName(location);

    return (
        <div className={classes.layoutContainer}>
            <MantineProvider
                theme={{
                    colorScheme: "dark",
                    globalStyles: () => ({
                        "nav.navbar": {
                            left: navbarWidth,
                        },
                    }),
                }}
            >
                {props.navbar}
            </MantineProvider>
            <div className={classes.contentContainer}>
                {props.header}
                {props.banner}
                <div className={`${locationClassName} ${classes.contentArea}`}>{props.children}</div>
                <Footer />
            </div>
        </div>
    );
};
