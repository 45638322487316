import { ReportsClient, TaskCountPerClientAndGroup } from "@/api-client";
import { Columns, exportToCsv } from "../csvUtil";
import { IReport } from "../IReport";

export class TaskReport implements IReport {
    public name;
    public description;

    constructor(private reportsClient: ReportsClient) {
        this.name = "Improvement tasks";
        this.description = "Statistics on improvement tasks for all clients and groups by status";
    }

    public downloadReport() {
        return this.reportsClient.getTaskCountPerClientAndGroup().then(result => {
            const columns: Columns<TaskCountPerClientAndGroup> = {
                ClientName: "clientName",
                GroupName: "groupName",
                ClientId: "clientId",
                GroupId: "groupId",
                Inbox: "inbox",
                "To-Do": "toDo",
                "In-Progress": "inProgress",
                Done: "done",
                Completed: "completed",
            };

            exportToCsv(result, columns, "TaskCountPerClientAndGroup.csv");
        });
    }
}
