﻿import { Button, createStyles, INPUT_SIZES, getStylesRef } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { CategoryMenu } from "./CategoryMenu";
import { CategoryItem, DefaultCategoryItem } from "./CategoryMenuItem";
import RenderIf from "../../../../components/render-if/render-if";
import { Icons } from "../../../../common/Icons";
import { FullscreenProps } from "../../useFullscreen";
import { SegmentedControl } from "./SegmentedControl";

const useStyles = createStyles(theme => {
    const borderThickness = 1;
    const colors = theme.fn.variant({ variant: "default" });

    return {
        controlContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "end",
        },
        segmentRoot: {
            display: "flex",
            alignItems: "center",
            padding: 0,
            border: `${borderThickness}px solid ${colors.border}`,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
        },
        segmentControl: {
            ref: getStylesRef("segmentControl"),
            height: `calc(${INPUT_SIZES.sm} - ${borderThickness * 2}px)`,
            display: "flex",
            alignItems: "center",
            [`&:not(${getStylesRef("segmentControlIndicator")}):hover`]: {
                backgroundColor: colors.hover,
            },
        },
        segmentLabel: {
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 600,
        },
        buttonGroupRoot: {
            display: "flex",
            alignItems: "center",
        },
        buttonRoot: {
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
        },
    };
});

export type DisplayModes = "User" | "Category" | "Priority";

export const DisplayModeTranslations: { [key in DisplayModes]: string } = {
    User: "BOARDS_PEOPLE",
    Category: "BOARDS_CATEGORY",
    Priority: "BOARDS_PRIORITY",
};

type ActionBarProps = {
    displayMode: {
        currentMode: DisplayModes;
        onModeChange: (newMode: DisplayModes) => void;
    };
    zoom: {
        onZoomIn: () => void;
        onZoomOut: () => void;
        onZoomReset: () => void;
    };
    categories: {
        defaultItem: DefaultCategoryItem;
        items: CategoryItem[];
        onAddCategory: () => void;
    };
    fullscreen: FullscreenProps;
};

export const ActionBar = (props: ActionBarProps) => {
    const { t } = useTranslate();
    const { classes } = useStyles();

    return (
        <div className={classes.controlContainer}>
            <SegmentedControl
                classNames={{
                    root: classes.segmentRoot,
                    control: classes.segmentControl,
                    label: classes.segmentLabel,
                }}
                value={props.displayMode.currentMode}
                onChange={x => props.displayMode.onModeChange(x as DisplayModes)}
                data={[
                    { label: t(DisplayModeTranslations["User"]), value: "User" },
                    { label: t(DisplayModeTranslations["Category"]), value: "Category" },
                    { label: t(DisplayModeTranslations["Priority"]), value: "Priority" },
                ]}
            />
            <CategoryMenu
                onAddCategory={() => props.categories.onAddCategory()}
                defaultItem={props.categories.defaultItem}
                categories={props.categories.items}
            />

            <Button.Group className={classes.buttonGroupRoot} ml={"md"}>
                <Button
                    classNames={{ root: classes.buttonRoot }}
                    variant="default"
                    leftIcon={<Icons.ZoomOut />}
                    onClick={() => props.zoom.onZoomOut()}
                >
                    {t("BOARD_ZOOM_OUT")}
                </Button>
                <Button
                    classNames={{ root: classes.buttonRoot }}
                    variant="default"
                    leftIcon={<Icons.ZoomIn />}
                    onClick={() => props.zoom.onZoomIn()}
                >
                    {t("BOARD_ZOOM_IN")}
                </Button>
                <RenderIf show={!props.fullscreen.isEnabled}>
                    <Button
                        classNames={{ root: classes.buttonRoot }}
                        variant="default"
                        leftIcon={<Icons.FullScreen />}
                        onClick={() => props.fullscreen.enter()}
                    >
                        {t("WORSKHOP_PRESENTATION_ENTER_FULLSCREEN")}
                    </Button>
                </RenderIf>
                <RenderIf show={props.fullscreen.isEnabled}>
                    <Button
                        classNames={{ root: classes.buttonRoot }}
                        variant="default"
                        leftIcon={<Icons.ExitFullScreen />}
                        onClick={async () => {
                            await props.fullscreen.exit();
                            props.zoom.onZoomReset();
                        }}
                    >
                        {t("WORSKHOP_PRESENTATION_EXIT_FULLSCREEN")}
                    </Button>
                </RenderIf>
            </Button.Group>
        </div>
    );
};
