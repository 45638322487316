import { MouseEventHandler } from "react";
import { WithChildren } from "../../common/StateWrapper";
import { Button, EventName } from "./Button";

type PrimaryButtonProps = {
    loading?: boolean;
    type?: "button" | "submit";
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    fullWidth?: boolean;
    tabIndex?: number;
    eventName?: EventName;
} & WithChildren;

export const PrimaryButton = (props: PrimaryButtonProps) => {
    return (
        <Button
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.onClick}
            type={props.type}
            fullWidth={props.fullWidth}
            tabIndex={props.tabIndex}
            eventName={props.eventName}
        >
            {props.children}
        </Button>
    );
};
