export type Color = {
    name: string;
    hex: string;
    rgb: [number, number, number];
    textColorHex: string;
};

export type Palette = "dark" | "light";

const rgbToHex = (r: number, g: number, b: number) =>
    `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

const color = (r: number, g: number, b: number): { hex: string; rgb: [number, number, number] } => ({
    rgb: [r, g, b],
    hex: rgbToHex(r, g, b),
});

export const darkSmoke: Color = {
    name: "Dark Smoke",
    ...color(96, 112, 139),
    textColorHex: "#FFFFFF",
};

export const darkPurple: Color = {
    name: "Dark Purple",
    ...color(162, 82, 189),
    textColorHex: "#FFFFFF",
};

export const dark: Color[] = [
    {
        name: "Black",
        ...color(0, 0, 0),
        textColorHex: "#FFFFFF",
    },
    darkSmoke,
    {
        name: "Dark Indigo",
        ...color(73, 63, 128),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Blue",
        ...color(2, 92, 144),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Cyan",
        ...color(64, 143, 187),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Teal",
        ...color(1, 111, 81),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Green",
        ...color(63, 132, 85),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Sand",
        ...color(183, 173, 121),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Lime",
        ...color(181, 170, 31),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Beige",
        ...color(156, 142, 109),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Orange",
        ...color(187, 129, 1),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Brick",
        ...color(160, 71, 1),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Dark Maroon",
        ...color(100, 56, 80),
        textColorHex: "#FFFFFF",
    },
    {
        name: "Maroon",
        ...color(142, 90, 116),
        textColorHex: "#FFFFFF",
    },
];

export const light: Color[] = [
    {
        name: "Light Indigo",
        ...color(171, 215, 236),
        textColorHex: "#212729",
    },
    {
        name: "Light Blue",
        ...color(96, 112, 139),
        textColorHex: "#212729",
    },
    {
        name: "Moss",
        ...color(160, 183, 137),
        textColorHex: "#212729",
    },
    {
        name: "Mint",
        ...color(128, 158, 153),
        textColorHex: "#212729",
    },
    {
        name: "Light Teal",
        ...color(112, 202, 176),
        textColorHex: "#212729",
    },
    {
        name: "Lime",
        ...color(194, 208, 82),
        textColorHex: "#212729",
    },
    {
        name: "Pear",
        ...color(170, 170, 0),
        textColorHex: "#212729",
    },
    {
        name: "Light Sand",
        ...color(238, 221, 136),
        textColorHex: "#212729",
    },
    {
        name: "Beige",
        ...color(245, 241, 214),
        textColorHex: "#212729",
    },
    {
        name: "Light Pink",
        ...color(255, 170, 187),
        textColorHex: "#212729",
    },
    {
        name: "Light Purple",
        ...color(187, 137, 179),
        textColorHex: "#212729",
    },
    {
        name: "Light Maroon",
        ...color(187, 117, 129),
        textColorHex: "#212729",
    },
    {
        name: "Light Orange",
        ...color(238, 136, 102),
        textColorHex: "#212729",
    },
];

export const getColorByIndexRepeatingSeq = (index: number, palette: Palette): Color => {
    switch (palette) {
        case "dark":
            return dark[index % dark.length];
        case "light":
            return light[index % light.length];
    }
};

export const getColorsUnusedFirst = (palette: string[], usedColors: string[], numberOfColors: number) => {
    const filteredPalette = palette.filter(c => !usedColors.find(tc => tc === c));

    const colors = [];
    for (let i = 0; i < numberOfColors; i++) {
        if (i < filteredPalette.length) {
            colors.push(filteredPalette[i]);
        } else {
            colors.push(palette[(i - filteredPalette.length) % palette.length]);
        }
    }

    return colors;
};
