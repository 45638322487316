﻿import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Container, Flex, Title } from "@mantine/core";
import { useCurrentCourse, useCurrentGroup, useCurrentUserProgress } from "../../common/courses/context";
import { useCurrentUser } from "@/common";
import { UserHasGroupLeaderRole } from "../../common/user/utils";
import GroupCourseSettings from "../../components/GroupCourseSettings";
import TeamProgressDisplay from "../../components/GroupProgressDisplay";
import { AppLoader } from "@/components";
import RenderIf from "../../components/render-if/render-if";
import { NavigationLinks } from "./NavigationLinks";
import { CourseSelectorDropdown } from "./CourseSelectorDropdown";
import { Icons } from "../../common/Icons";
import { useOnboardingVideos } from "../../onboarding/useOnboardingVideos";
import { NoProgramsAvailable } from "./NoProgramsAvailable";

export const Program = (): JSX.Element => {
    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();
    const currentUserProgress = useCurrentUserProgress();
    const [showGroupCourseSettings, setShowGroupCourseSettings] = useState(false);
    const currentUser = useCurrentUser();
    const onBoardingUrl = useOnboardingVideos();

    if (onBoardingUrl !== undefined) {
        return <Navigate to={onBoardingUrl} />;
    }

    if (currentGroup === undefined || currentUserProgress === undefined) {
        return <AppLoader loading />;
    }

    if (currentCourse === undefined) {
        return <NoProgramsAvailable />;
    }

    const isTeamLeader = UserHasGroupLeaderRole(currentUser, currentGroup);

    return (
        <Container size="xl">
            <Flex>
                <Title order={3}>{currentGroup.name}</Title>
            </Flex>
            <Flex justify="space-between">
                <CourseSelectorDropdown />
                <RenderIf show={isTeamLeader}>
                    <button className="btn btn-link p-0" onClick={() => setShowGroupCourseSettings(true)}>
                        <Icons.Settings /> Settings
                    </button>

                    <GroupCourseSettings
                        show={showGroupCourseSettings}
                        handleClose={() => setShowGroupCourseSettings(false)}
                        group={currentGroup}
                        course={currentCourse}
                    />
                </RenderIf>
            </Flex>

            <NavigationLinks
                group={currentGroup}
                course={currentCourse}
                user={currentUser}
                userProgress={currentUserProgress}
            />

            <TeamProgressDisplay team={currentGroup} course={currentCourse} />
        </Container>
    );
};
