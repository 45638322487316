import React, { useEffect } from "react";
import { useHistory } from "@/common";
import { useCurrentCourse, useCurrentGroup } from "../common/courses/context";
import { useUserContext } from "../common/user/context";
import TransformationTodoOverview from "./Components/TransformationTodoOverview";
import "./style.scss";

export function TransformationTodo(): JSX.Element {
    const currentGroup = useCurrentGroup();
    const currentCourse = useCurrentCourse();
    const userContext = useUserContext();
    const history = useHistory();

    // Redirect to group home if group isn't allowed to access implementation/transformation
    useEffect(() => {
        if (!currentGroup) {
            return;
        }

        // @ts-ignore
        if (currentGroup.courseIds.length === 0) {
            console.info("No courses in group, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }

        if (!currentCourse) {
            return;
        }

        if (currentCourse.courseType !== "ImplementationCourse") {
            console.info("Course is not implementation, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }
    }, [currentCourse, currentGroup, history]);

    return (
        <TransformationTodoOverview
            // @ts-ignore
            group={currentGroup}
            // @ts-ignore
            course={currentCourse}
            // @ts-ignore
            clientId={userContext?.user?.clientId}
            // @ts-ignore
            user={userContext?.user}
        />
    );
}
