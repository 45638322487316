import { ReactNode } from "react";
import { useTranslate } from "@tolgee/react";
import { Navbar as MNavbar, NavLink } from "@mantine/core";
import { Link } from "react-router-dom";
import { useNavbarStyles } from "./useNavbarStyles";

type BottomSectionProps = {
    navigateTo: string;
    label: string;
    icon: ReactNode;
    active: boolean;
};

export const BottomSection = (props: BottomSectionProps) => {
    const { classes } = useNavbarStyles();
    const { t } = useTranslate();

    return (
        <MNavbar.Section className={classes.settingsSection}>
            <NavLink
                active={props.active}
                component={Link}
                to={props.navigateTo}
                h={40}
                classNames={{
                    root: classes.navLinkRoot,
                    label: classes.buttonNavLinkLabel,
                    body: classes.navLinkBody,
                }}
                label={t(props.label)}
                icon={props.icon}
            />
        </MNavbar.Section>
    );
};
