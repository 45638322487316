import React from "react";
import { Col, Row } from "react-bootstrap";
import { useCurrentCourse } from "../../../common/courses/context";
import { useCurrentClient } from "../../../common/user/context";
import AltBlocks from "../../../components/AltBlocks";
import { usePresentationContext } from "../PresentationContext";
import SlideshowBlock from "../SlideshowBlock";

const IntroSlide = () => {
    const { chapter, module } = usePresentationContext();

    const currentCourse = useCurrentCourse();

    const currentClient = useCurrentClient();

    return (
        <AltBlocks>
            <SlideshowBlock elementId={`element-0`} className="alt-blocks-first text-center">
                <Row className="justify-content-md-center mt-5">
                    <Col md={6}>
                        <h1>{chapter?.name}</h1>
                        <p className="lead">{module?.name}</p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center mt-5">
                    <Col md={6}>
                        {currentClient !== null && <img src={currentClient?.logo} alt="Logo" className="main-logo" />}
                    </Col>
                </Row>
                <Row className="justify-content-md-center mt-5">
                    <Col md={6}>
                        <p className="lead">{currentCourse?.name}</p>
                    </Col>
                </Row>
            </SlideshowBlock>
        </AltBlocks>
    );
};

export default IntroSlide;
