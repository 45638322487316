import { IntroductionVideo, Video } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import { AppLoader } from "../../../components/Spinner";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

type ManageIntroductionVideosProps = {
    courseId: number;
    introductionVideoId?: number;
    introductionVideo: IntroductionVideo;
    videoList: Video[];
    isPending: boolean;
};

const ManageIntroductionVideos = (props: ManageIntroductionVideosProps): JSX.Element => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const initialValues = {
        video: props.introductionVideo?.videoId,
    };
    const api = createApi();

    return (
        <>
            {/* <AppLoader loading={(isLoading) ? true : false} className="position-absolute" /> */}

            <ContentLabel text="IntroductionVideos" />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, helpers) => {
                    const patch = createPatch(props.introductionVideo, g => {
                        g.videoId = values.video;
                    });
                    if (patch.length > 0) {
                        //handle save and update
                        // @ts-ignore
                        api.introductionVideos.patch(props.introductionVideoId, patch).then(
                            result => {
                                setIsPending(false);
                            },
                            error => {
                                console.log(error, "Error in the patch request");
                            }
                        );
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="editModuleForm" className="mt-2 editModuleForm">
                            <AppLoader className="position-absolute" loading={isPending || props.isPending} />
                            <Row className="mb-4">
                                <Col>
                                    <label className="form-label">Select Video</label>
                                    <Field
                                        name="video"
                                        as="select"
                                        className={"form-select" + (errors.video && touched.video ? " is-invalid" : "")}
                                    >
                                        {props.videoList &&
                                            props.videoList.length > 0 &&
                                            props.videoList.map(video => (
                                                <option key={video.id} value={video?.id}>
                                                    {video.name}
                                                </option>
                                            ))}
                                        ;
                                    </Field>
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="video"
                                        component="span"
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={props.isPending}>
                                    Save
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ManageIntroductionVideos;
