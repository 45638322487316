import { Answer, ModuleBlock, Question } from "@/api-client";
import React, { ReactNode, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../common/api";
import { useCurrentCourseUserAnswers, useCurrentGroupCourseContextDispatch } from "../../common/courses/context";
import { cloneUpdateObjectArray } from "../../helpers/javascript";
import { Notify } from "../Notify";
import { QuestionsSubmitButton } from "./Components/QuestionsSubmitButton";
import { FreeTextAnswers } from "./FreeTextAnswers";
import { MatrixGridAnswers } from "./MatrixGrid";
import { MatrixScaleAnswers } from "./MatrixScale";
import { ExtendAnswer, QuestionTemplateProps } from "./models/QuestionTemplateProps";
import { MultiChoiceAnswers } from "./MultipleChoice";
import { RatingAnswers } from "./Rating";
import { SingleChoiceAnswers } from "./SingleChoice";
import { CardBlockWithButtonContainer } from "../../training/Components/CardBlockWithButtonContainer";
import { CardBlock } from "../CardBlock";
import { createStyles } from "@mantine/core";

/*  eslint-disable react-hooks/exhaustive-deps */
const useStyles = createStyles(theme => ({ card: { marginBottom: theme.spacing.xl } }));

interface AllQuestionsProps {
    questions: Question[];
    moduleId: number;
    chapterId: number;
    courseId: number;
    currentProgress: boolean | null;
    nextModuleBlock: ModuleBlock | undefined;
    moduleBlockId: number;
    moduleBlock: ModuleBlock;
    isLast: boolean;
    id: string;
    moduleProgressPending?: boolean;
    groupId: number;
    roundId: number | null;

    summaryButtonHandler(): void;
}

interface QuestionWithAnswers {
    answers: ExtendAnswer[];
    question: Question; // TODO: Move to new API signature
    isValid?: boolean;
    errors?: string[];
    hasBeenValidated?: boolean;
    needValidation?: boolean;
}

type ContainerProps = {
    index: number;
    children: ReactNode;
    questionsWithAnswers: QuestionWithAnswers[];
    isPending: boolean;
    nextModuleBlock: ModuleBlock;
    currentProgress: boolean | null;
    disabled: boolean;
    isLast: boolean;
    moduleProgressPending: boolean;
    moduleBlock: ModuleBlock;
    moduleBlockId: number;
    id: string;
};

const Container = ({
    index,
    children,
    questionsWithAnswers,
    isPending,
    disabled,
    isLast,
    moduleProgressPending,
    nextModuleBlock,
    currentProgress,
    moduleBlock,
    moduleBlockId,
    id,
}: ContainerProps) => {
    const { classes } = useStyles();
    const lastElement = questionsWithAnswers.length - 1;
    if (index !== lastElement) {
        return <CardBlock classNames={classes}>{children}</CardBlock>;
    }

    const button = questionsWithAnswers && (
        <QuestionsSubmitButton
            isPending={isPending}
            nextModuleBlock={nextModuleBlock}
            currentProgress={currentProgress}
            moduleBlock={moduleBlock}
            disabled={disabled}
            moduleBlockId={moduleBlockId}
            id={id}
            isLast={isLast}
            moduleProgressPending={moduleProgressPending}
        />
    );

    return <CardBlockWithButtonContainer buttons={<>{button}</>}>{children}</CardBlockWithButtonContainer>;
};

export const AllQuestions = ({
    questions,
    currentProgress,
    summaryButtonHandler,
    moduleId,
    chapterId,
    nextModuleBlock,
    moduleBlockId,
    moduleBlock,
    isLast,
    id,
    moduleProgressPending,
    groupId,
    roundId,
}: AllQuestionsProps): JSX.Element => {
    const { t } = useTranslate();

    const api = createApi();

    const answers = useCurrentCourseUserAnswers();

    const [state, setState] = useState({
        isPending: false,
        isValid: true,
        isFormSubmitted: false,
        error: null,
    });

    const [questionsWithAnswers, setQuestionsWithAnswers] = useState<QuestionWithAnswers[]>();

    useEffect(() => {
        if (questions && answers) {
            const formData: QuestionWithAnswers[] = questions.map(question => ({
                question: question,
                answers: answers?.filter(a => {
                    return question.id === a.questionId;
                }),
                isValid: false, // Let components explicitly set to true to avoid posting unvalidated answers
                needValidation: false,
            }));
            setQuestionsWithAnswers(formData);
        }
    }, [questions, answers]);

    const [formErrors, setFormErrors] = useState([]);

    //This is making the form slower
    useEffect(() => {
        if (questionsWithAnswers && state.isFormSubmitted && !currentProgress) {
            // @ts-ignore
            let error = [];
            questionsWithAnswers.forEach(qa => {
                if (qa.question.questionTypeId === 1) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
                if (qa.question.questionTypeId === 2) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
                if (qa.question.questionTypeId === 3) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
                if (qa.question.questionTypeId === 4) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
                if (qa.question.questionTypeId === 5) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else if (qa.isValid === false) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
                if (qa.question.questionTypeId === 6) {
                    if (qa.answers.length === 0) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else if (qa.isValid === false) {
                        error[qa.question.questionTypeId] = true;
                        // @ts-ignore
                        setFormErrors(error);
                    } else {
                        error[qa.question.questionTypeId] = false;
                        // @ts-ignore
                        setFormErrors(error);
                    }
                }
            });
        }
    }, [questionsWithAnswers, state]);

    const currentCourseQuestionsDispatch = useCurrentGroupCourseContextDispatch();

    const handleFormSubmit = () => {
        // If exercise is submitted user cannot be able to give answers again.
        if (!currentProgress) {
            if (!questionsWithAnswers) {
                throw new Error("Unexpected failure: questionAnswers is not initialized on form submit");
            }

            // Cancel submit if any questions that aren't valid or aren't validated are found
            if (questionsWithAnswers.find(x => !x.isValid || x.isValid === undefined) !== undefined) {
                setState({ ...state, isPending: false, isValid: false, isFormSubmitted: true });
                return;
            }

            const userModuleBlockAnswers: Answer[] =
                questionsWithAnswers &&
                questionsWithAnswers
                    .flatMap(x => x.answers)
                    .filter(a => a.isSkipped === false || a.isSkipped === undefined);
            setState({ ...state, isPending: true });
            Promise.allSettled(
                userModuleBlockAnswers.map(answer => {
                    // @ts-ignore
                    const answerWithRoundAndGroupId: Answer = { ...answer, roundId, groupId, status: "Active" };
                    if (answer.id && answer.id !== undefined) {
                        return api.answers.put(answer.id, answerWithRoundAndGroupId);
                    } else {
                        // @ts-ignore
                        delete answerWithRoundAndGroupId.id;
                        return api.answers.post(answerWithRoundAndGroupId);
                    }
                })
            ).then(results => {
                setState({ ...state, isPending: false });
                const successPostedAnswers: Answer[] = results
                    .filter(result => result.status === "fulfilled")
                    .map(r => (r as PromiseFulfilledResult<Answer>).value);
                const failedAnswers = results.filter(result => result.status === "rejected");
                if (failedAnswers.length > 0) {
                    setState({
                        ...state,
                        // @ts-ignore

                        error: "Something going wrong in posting some answers. Please try again Later.",
                    });
                    return;
                } else {
                    currentCourseQuestionsDispatch(s => ({
                        ...s,
                        // @ts-ignore
                        answers: [...s.answers, ...successPostedAnswers],
                    }));
                    // Update optimistic update for answers
                    summaryButtonHandler();
                }
            });
        } else {
            summaryButtonHandler();
        }
    };

    // Sort by order or by id
    const sorter = (a: Question, b: Question): number => {
        // @ts-ignore
        const diff = a.order - b.order;
        // @ts-ignore
        return diff !== 0 ? diff : a.id - b.id;
    };

    const inValidAnswers = questionsWithAnswers && questionsWithAnswers.filter(q => q.isValid === false).length;
    const isValidForm = formErrors && formErrors.filter(e => e === true).length;

    return (
        <React.Fragment>
            {state.error !== null && <Notify message={state.error} id="question-error" type="warning" />}
            {answers && (
                <Form
                    className="form_container"
                    onSubmit={e => {
                        e.preventDefault();
                        handleFormSubmit();
                    }}
                >
                    {questionsWithAnswers &&
                        questionsWithAnswers
                            .sort((a, b) => sorter(a.question, b.question))
                            .map((qa, index) => {
                                const qLength = questions.length;
                                const placeholder = t("MODULE_QUESTION_NUMBERS", { min: index + 1, max: qLength });

                                const templateProps: QuestionTemplateProps = {
                                    answers: qa.answers,
                                    question: qa.question,
                                    // @ts-ignore
                                    needValidation: qa.needValidation,
                                    setAnswers: newAnswers =>
                                        setQuestionsWithAnswers(state =>
                                            cloneUpdateObjectArray(state ?? [], qa, x => {
                                                x.answers = newAnswers;
                                                return x;
                                            })
                                        ),
                                    setValidation: (isValid, hasBeenValidated) =>
                                        setQuestionsWithAnswers(state =>
                                            cloneUpdateObjectArray(state ?? [], qa, x => {
                                                x.isValid = isValid;
                                                x.hasBeenValidated = hasBeenValidated;
                                                return x;
                                            })
                                        ),
                                };

                                const commonProps = { index, placeholder, currentProgress, ...templateProps };
                                const questionTypeId = qa.question.questionTypeId;

                                const containerProps = {
                                    index: index,
                                    questionsWithAnswers: questionsWithAnswers,
                                    isPending: state.isPending,
                                    nextModuleBlock: nextModuleBlock,
                                    currentProgress: currentProgress,
                                    disabled:
                                        // @ts-ignore
                                        (!state.isValid && inValidAnswers > 0) ||
                                        (state.isFormSubmitted && isValidForm > 0),
                                    isLast: isLast,
                                    moduleProgressPending: moduleProgressPending,
                                    moduleBlock: moduleBlock,
                                    moduleBlockId: moduleBlockId,
                                    id: id,
                                };

                                switch (qa.question.questionTypeId) {
                                    case 1:
                                        return (
                                            // @ts-ignore
                                            <Container key={`freetext_${index}`} {...containerProps}>
                                                <FreeTextAnswers {...commonProps} error={formErrors[questionTypeId]} />
                                            </Container>
                                        );

                                    case 2:
                                        return (
                                            // @ts-ignore
                                            <Container {...containerProps}>
                                                <SingleChoiceAnswers
                                                    {...commonProps}
                                                    error={formErrors[questionTypeId]}
                                                />
                                            </Container>
                                        );

                                    case 3:
                                        return (
                                            // @ts-ignore
                                            <Container key={`single_choice_${index}`} {...containerProps}>
                                                <MultiChoiceAnswers
                                                    key={`multi_choice_${index}`}
                                                    {...commonProps}
                                                    error={formErrors[questionTypeId]}
                                                />
                                            </Container>
                                        );

                                    case 4:
                                        return (
                                            // @ts-ignore
                                            <Container key={`rating_answers_${index}`} {...containerProps}>
                                                <RatingAnswers {...commonProps} error={formErrors[questionTypeId]} />
                                            </Container>
                                        );

                                    case 5:
                                        return (
                                            // @ts-ignore
                                            <Container key={`matrix_scale_answers_${index}`} {...containerProps}>
                                                <MatrixScaleAnswers
                                                    {...commonProps}
                                                    error={formErrors[questionTypeId]}
                                                />
                                            </Container>
                                        );

                                    case 6:
                                        return (
                                            // @ts-ignore
                                            <Container key={`matrix_grid_answers_${index}`} {...containerProps}>
                                                <MatrixGridAnswers
                                                    {...commonProps}
                                                    error={formErrors[questionTypeId]}
                                                />
                                            </Container>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                </Form>
            )}
        </React.Fragment>
    );
};
