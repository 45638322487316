import * as yup from "yup";
import { UserRoles } from "@/api-client";
import { yupExtensions } from "@/common";

const adminRoleValidator = (value: (UserRoles | undefined)[] | undefined) => {
    if (!value) {
        return false;
    }

    if (value.includes("Admin") && (value.includes("Agent") || value.includes("Expert"))) {
        return false;
    }

    return true;
};

export const adminFormValidator = yup.object({
    roles: yup
        .array()
        .of(yup.mixed<UserRoles>().defined())
        .min(1)
        .required()
        .test(
            "role combination",
            "SYSADMIN_INVALID_ROLE_USER_CANNOT_HAVE_ADMIN_AND_AGENT_OR_EXPERT_ROLE",
            adminRoleValidator
        ),
    email: yupExtensions
        .requiredNonEmptyString("SYSADMIN_FIELD_IS_REQUIRED")
        .email()
        .required("SYSADMIN_FIELD_IS_REQUIRED"),
    firstName: yupExtensions.requiredNonEmptyString("SYSADMIN_FIELD_IS_REQUIRED"),
    lastName: yupExtensions.requiredNonEmptyString("SYSADMIN_FIELD_IS_REQUIRED"),
});
