import * as yup from "yup";

const isPhoneNumberRegExp = /^\+\d+$/;

export const createEmailValidator = (message?: string) => yup.string().email(message);

export const createPhoneNumberValidator = (message?: string) => yup.string().matches(isPhoneNumberRegExp, message);

export const createEmailOrPhoneNumberValidator = (message: string) => {
    return yup.string().test("email or phone", message, async value => {
        const isEmail = await createEmailValidator(message).isValid(value);
        const isPhoneNumber = await createPhoneNumberValidator(message).isValid(value);

        if (!isEmail && !isPhoneNumber) {
            return false;
        }

        return true;
    });
};

export const createPasswordValidator = (requiredMessage: string, invalidMessage: string) => {
    return yup
        .string()
        .required(requiredMessage)
        .matches(/(?=.*[0-9])(?=.*[a-z])/i, invalidMessage)
        .min(8, invalidMessage)
        .max(50, invalidMessage);
};

export const yupExtensions = {
    requiredNonEmptyString: (message?: string) =>
        yup
            .string()
            .transform((x: string | null) => (x === null ? "" : x.trim()))
            .required(message),
    nullableNonEmptyString: () =>
        yup
            .string()
            .ensure()
            .nullable(true)
            .transform((x: string | null) => {
                if (x === null) {
                    return null;
                }

                if (x.trim().length > 0) {
                    return x.trim();
                }

                return null;
            }),
    emailValidator: createEmailValidator,
    phoneNumberValidator: createPhoneNumberValidator,
    emailOrPhoneNumberValidator: createEmailOrPhoneNumberValidator,
    passwordValidator: createPasswordValidator,
} as const;
