import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { Input } from "@mantine/core";
import { light } from "../../common/colors";
import { ColorSelect } from "../ColorSelect";

export type FormColorInputProps = {
    fieldName: string;
    label: string;
};

export const FormColorInput = (props: FormColorInputProps) => {
    const { t } = useTranslate();
    const [field, , helpers] = useField(props.fieldName);

    return (
        <Input.Wrapper label={t(props.label)}>
            <ColorSelect {...field} onChange={helpers.setValue} palette={light.map(x => x.hex)} />
        </Input.Wrapper>
    );
};
