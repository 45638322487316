import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslate } from "@tolgee/react";
import { Grid, Stack } from "@mantine/core";
import { Answer, Question, User } from "@/api-client";
import { QuestionTemplateProps } from "./models/QuestionTemplateProps";
import useDebounce from "../UseDebounce/UseDebounce";
import { useUserContext } from "../../common/user/context";

/*  eslint-disable react-hooks/exhaustive-deps */

interface RatingAnswer {
    answer: Answer;
    isSelected?: boolean;
    isValid?: boolean;
}

//change this
export const getInitValues = (question: Question, existingAnswers: Answer[], user: User): RatingAnswer[] => {
    const ratingAnswer = _.takeRight(existingAnswers, 1);
    // Add 1 to get the max limit answers
    // @ts-ignore
    const maxValue = question.maxAnswer + 1;
    // Get Answers on the basis of max and min value
    // @ts-ignore

    const ratingAnswers = [...Array(maxValue - question.minAnswer).keys()].map(index => {
        // @ts-ignore
        const textValue = index + question.minAnswer;
        const existingAnswer = ratingAnswer.find(x => x.text === textValue.toString());
        const answer = {
            id: ratingAnswer && ratingAnswer[0] && ratingAnswer[0].id ? ratingAnswer[0].id : undefined,
            text: textValue.toString(),
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            userId: user.id,
            ...existingAnswer,
        };

        return {
            answer: answer,
            isSelected: existingAnswer !== undefined,
        };
    });
    // @ts-ignore

    return ratingAnswers;
};

interface RatingAnswersProps extends QuestionTemplateProps {
    currentProgress: boolean | null;
    index: number;
    //change this
    question: any;
    placeholder: string;
    error: string[] | undefined;
}

export const RatingAnswers = (props: RatingAnswersProps): JSX.Element => {
    const { t } = useTranslate();
    const userContext = useUserContext();
    const [answers, setAnswers] = useState<RatingAnswer[]>();
    const [errors, setErrors] = useState<string[]>();

    // Initialize answers
    useEffect(() => {
        if (props.question && userContext.user && props.question.options) {
            const initValues = getInitValues(props.question, props.answers, userContext.user);
            setAnswers(initValues);
        }
    }, []);

    const answersStateChange = useDebounce(answers, 500);
    useEffect(() => {
        if (answers && props.question) {
            const findValidAnswer = answers.filter(a => a.isSelected === true);
            let isValid = true;
            if (findValidAnswer.length === 0) {
                if (!findValidAnswer) {
                    setErrors([t("MODULE_VALIDATE_RATING_ANSWER_MIN_REQUIRED")]);
                    isValid = false;
                } else {
                    setErrors([]);
                }
                return;
            }
            // Update parent form
            props.setValidation(isValid, true);
            const selectedAnswer = answers.filter(x => x.isSelected).map(a => a.answer);
            props.setAnswers(selectedAnswer);
        }
    }, [answersStateChange]);

    const setAnswerValue = (answer: RatingAnswer, value: string) => {
        setAnswers(prevState => {
            if (!prevState) {
                return;
            }
            return prevState?.map(a => {
                if (a.answer.text === value) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            });
        });
    };

    const currentProgress = props.currentProgress;
    const { maxAnswer, minAnswer } = props.question;
    const me = [];

    for (let i = minAnswer; i <= maxAnswer; i++) {
        me.push(i);
    }

    const refs = Array(me.length);
    return (
        <Stack>
            <div className="block-content-text">
                <h4>{props.placeholder}</h4>
                <p>{props.question.text}</p>
                <p>{props.question.description}</p>
            </div>
            <Grid className="" justify="center" p={10} bg="gray.0">
                {answers &&
                    answers.map((answer, index) => {
                        return (
                            <Grid.Col
                                span="auto"
                                p={0}
                                my={10}
                                key={`question_${answer.answer.text}_${props.question.questionId}`}
                            >
                                <Stack
                                    align="center"
                                    p={0}
                                    m={0}
                                    onClick={() => {
                                        refs[index].click();
                                    }}
                                    className="rating-value"
                                >
                                    <input
                                        type="radio"
                                        id={`questions.question-${props.index}-${props.question.questionTypeId}-${index}`}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setAnswerValue(answer, e.target.value)
                                        }
                                        checked={answer.isSelected}
                                        name={`questions.question-${props.index}-${props.question.questionTypeId}.text`}
                                        value={answer.answer.text}
                                        ref={e => (refs[index] = e)}
                                        disabled={currentProgress ? true : false}
                                    />
                                    <label
                                        htmlFor={`questions.question-${props.index}-${props.question.questionTypeId}.value`}
                                    >
                                        {answer.answer.text}
                                    </label>
                                </Stack>
                            </Grid.Col>
                        );
                    })}
                {errors && errors.length === 0 && props.error && (
                    <p className="error learfix w-100 text-danger">{t("MODULE_VALIDATE_RATING_ANSWER_MIN_REQUIRED")}</p>
                )}
                {errors &&
                    errors.map((error, index) => (
                        <div key={index} className="clearfix w-100 text-danger">
                            {error}
                        </div>
                    ))}
            </Grid>
        </Stack>
    );
};
