import { Question } from "@/api-client";
import { scaleOrdinal } from "d3-scale";
// @ts-ignore
import { schemeCategory10 } from "d3-scale-chromatic";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import {
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts";
import { useOrganisationContext } from "../../../../common/OrganisationContext";
import RenderIf from "../../../../components/render-if/render-if";
import { AppLoader } from "../../../../components/Spinner";
import {
    groupAnswersByDemographicData,
    groupAnswersByGroup,
    groupAnswersByQuestionOption,
} from "../../data/aggregation";
import { AnswerGroup } from "../../data/AnswerGroup";
import { usePresentationContext } from "../../PresentationContext";
import { QuestionTitle } from "../../QuestionTitle";
import SlideshowBlock from "../../SlideshowBlock";
import "./styles.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

interface GridValue {
    label: string;
    x: number;
    y: number;
}

const getGridData = (data: AnswerGroup[]): GridValue[] => {
    return data.map(d => {
        const xy = d.answers.map(x => {
            // @ts-ignore
            const split = x.text.split(",");
            return { x: parseInt(split[0], 10), y: parseInt(split[1], 10) };
        });

        const countX = xy.filter(d => d.x > 0).length;
        const averageX =
            countX > 0
                ? xy
                      .filter(d => d.x > 0)
                      .map(d => d.x)
                      .reduce((a, b) => a + b, 0) / countX
                : 0;

        const countY = xy.filter(d => d.y > 0).length;
        const averageY =
            countY > 0
                ? xy
                      .filter(d => d.y > 0)
                      .map(d => d.y)
                      .reduce((a, b) => a + b, 0) / countY
                : 0;

        return {
            x: averageX,
            y: averageY,
            z: 200,
            label: d.name,
        };
    });
};

interface MatrixGridBlockProps {
    optionGroup: AnswerGroup;
    question: Question;
}

// @ts-ignore
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.label} : ${payload[0].payload.x.toFixed(
                    2
                )} / ${payload[0].payload.y.toFixed(2)}`}</p>
            </div>
        );
    }

    return null;
};

const MatrixGridBlock = ({ optionGroup, question }: MatrixGridBlockProps) => {
    const [gridData, setGridData] = useState<GridValue[]>();
    const { filters } = usePresentationContext();
    const { users, demographicQuestions, demographicData, groups } = useOrganisationContext();
    const colors = scaleOrdinal(schemeCategory10).range();

    useEffect(() => {
        if (!(optionGroup && optionGroup?.answers && groups && demographicQuestions && demographicData)) {
            return;
        }

        if (filters.groupBy === "DemographicData") {
            const demographicQuestion = demographicQuestions.find(x => x.id === filters.groupById);

            // @ts-ignore
            groupAnswersByDemographicData(optionGroup.answers, users, demographicQuestion, demographicData).then(
                data => {
                    setGridData(getGridData(data));
                }
            );
        } else {
            // Default to group

            groupAnswersByGroup(optionGroup.answers, groups).then(data => {
                setGridData(getGridData(data));
            });
        }
    }, [optionGroup, optionGroup?.answers, groups, demographicQuestions, demographicData]);

    // @ts-ignore
    const handleMouseEnter = _ => {};

    // @ts-ignore
    const handleMouseLeave = _ => {};

    console.log(question.minAnswer, question.maxAnswer, "question.minAnswer, question.maxAnswer");

    return (
        <SlideshowBlock
            elementId={`optiongroup-${optionGroup.id}`}
            key={optionGroup.id}
            showScrollToTop={true}
            className="p-5"
        >
            <h2>{optionGroup.name}</h2>

            <div className="chart-wrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                        width={400}
                        height={400}
                        margin={{
                            top: 20,
                            right: 150,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            tickCount={question.maxAnswer}
                            type="number"
                            dataKey="x"
                            label={{ value: question.description, position: "center", dy: 10 }}
                            name={question.description}
                            unit=""
                            tickSize={1}
                            // @ts-ignore
                            domain={[question.minAnswer, question.maxAnswer]}
                        />

                        <YAxis
                            tickCount={question.maxAnswer}
                            type="number"
                            width={100}
                            dataKey="y"
                            label={{ value: question.descriptionY, position: "center", dy: 10, angle: 270, width: 400 }}
                            name={question.descriptionY}
                            unit=""
                            tickSize={1}
                            // @ts-ignore
                            domain={[question.minAnswer, question.maxAnswer]}
                            allowDataOverflow={false}
                        />

                        <ZAxis type="number" dataKey="z" range={[60, 400]} />
                        <Tooltip
                            cursor={{ strokeDasharray: "3 3" }}
                            content={<CustomTooltip active={undefined} payload={undefined} />}
                        />

                        {gridData && (
                            <>
                                {gridData.map((entry, index) => (
                                    <Scatter
                                        key={`scatter_${entry.label}`}
                                        name={entry.label}
                                        data={[entry]}
                                        fill={colors[index % colors.length] as string}
                                    >
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length] as string} />
                                        {/* <LabelList dataKey="x" position={`right`} />
                                     <LabelList dataKey="y" position={`left`} /> */}
                                    </Scatter>
                                ))}
                            </>
                        )}
                        <Legend
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            layout="vertical"
                            verticalAlign="middle"
                            align="right"
                            width={150}
                            wrapperStyle={{ right: 0 }}
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        </SlideshowBlock>
    );
};

const MatrixGridQuestionSlide = () => {
    const { question, module, answers, filters } = usePresentationContext();
    const { t } = useTranslate();
    const [optionGroups, setOptionGroups] = useState<AnswerGroup[]>();

    useEffect(() => {
        if (!(answers && question)) {
            return;
        }

        groupAnswersByQuestionOption(question, answers).then(data => {
            setOptionGroups(data);
        });
    }, [filters?.groupBy, filters.groupById, answers, question]);

    // @ts-ignore

    const containsAnswers: boolean = answers && answers.length > 0;

    return (
        <div className="matrix-grid">
            <QuestionTitle
                // @ts-ignore
                text={question.text}
                // @ts-ignore
                moduleName={module?.name}
            />

            {!containsAnswers && optionGroups !== undefined && (
                <div className="alert alert-info">{t("WORKSHOP_PRESENTATION_NOANSWER")}</div>
            )}
            <AppLoader loading={optionGroups === undefined} />

            <div className="bg-even-odd">
                <RenderIf show={containsAnswers}>
                    {optionGroups &&
                        optionGroups.map(optionGroup => (
                            <MatrixGridBlock
                                optionGroup={optionGroup}
                                // @ts-ignore

                                question={question}
                                key={`gridblock_${optionGroup.id}`}
                            />
                        ))}

                    <div>
                        {answers && (
                            <p className="mt-2 text-center">
                                {t("WORKSHOP_MATRIX_NUMBEROFANSWER", {
                                    numberOfAnswers: _.uniq(answers.flatMap(x => x.userId)).length,
                                })}
                            </p>
                        )}
                    </div>
                </RenderIf>
            </div>
        </div>
    );
};

export default MatrixGridQuestionSlide;
