import { User } from "@/api-client";
import { Username } from "./Users/AddUsers/AddUserMultiSelectInput";

export const getUsernames = (users: User[]): Username[] => {
    const emailUsers: Username[] = users.filter(u => u.email).map(u => ({ type: "email", value: u.email! }));

    const mobilePhoneUsers: Username[] = users
        .filter(u => u.mobilePhone)
        .map(u => ({ type: "mobilePhone", value: u.mobilePhone! }));

    return emailUsers.concat(mobilePhoneUsers);
};
