import { Question, QuestionOption } from "@/api-client";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import { Api, createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { Notify } from "../../../../components/Notify";
import { AppLoader } from "../../../../components/Spinner";
import { AddQuestionOptions } from "./AddQuestionOptions";
import { ContentLabel } from "../../../../components/ContentLabel";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () => {
    // return Yup.object().shape({
    //     minAnswer: Yup.number().typeError("Min Answer's value must be number type"),
    //     maxAnswer: Yup.number().typeError("Max Answer's value must be number type")
    // });
};

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

interface EditQuestionResponse {
    question: Question;
    isPending: boolean;
    isSuccess: boolean;
}

interface FormModel {
    questionId: number;
    description: string;
    descriptionY: string;
    minLabel: string;
    maxLabel: string;
    minLabelY: string;
    maxLabelY: string;
    allowSkip: boolean;
    gridSize: string;
    minAnswer: number;
    maxAnswer: number;
    questionOptions: QuestionOption[];
}

// QA: Please move to a separate file like utils.tsx or helpers when used by multiple components
// QA: Used typed args instead of any

// @ts-ignore
export const handleUpdateOptions = async (api: Api, updatedOptions, initialOptions) => {
    // @ts-ignore

    const getOptions = updatedOptions.filter(ua => ua.id !== undefined);
    // @ts-ignore

    const patchedQuestions: QuestionOption[] = getOptions?.map(m => {
        // @ts-ignore

        const value = initialOptions.find(o => o.id === m.id);
        const patch = createPatch(value, x => {
            x.text = m.text;
            x.order = m.order;
        });

        if (patch && patch.length > 0) {
            // Update Question Options
            return api.questionOptions.patch(m.id, patch).catch(error => {
                console.log(error);
            });
        } else {
            return Promise.reject();
        }
    });

    return await Promise.allSettled(patchedQuestions);
};

const MatrixGrid = (props: EditQuestionProps): JSX.Element => {
    const api = createApi();
    const history = useHistory();
    const initialValues: FormModel = {
        // @ts-ignore

        questionId: props.question?.id,
        // @ts-ignore

        description: props.question?.description,
        // @ts-ignore

        descriptionY: props.question?.descriptionY,

        // @ts-ignore

        minLabel: props.question?.minAnswerLabel,
        // @ts-ignore

        maxLabel: props.question?.maxAnswerLabel,
        // @ts-ignore

        minLabelY: props.question?.minAnswerYLabel,
        // @ts-ignore

        maxLabelY: props.question?.maxAnswerYLabel,
        // @ts-ignore

        allowSkip: props.question?.allowSkip,
        // @ts-ignore

        gridSize: props.question?.gridSize,
        // @ts-ignore

        minAnswer: props.question?.minAnswer,
        // @ts-ignore

        maxAnswer: props.question?.maxAnswer,
        // @ts-ignore

        questionOptions: props.question?.options,
    };
    const [editQuestion, setEditQuestion] = useState<EditQuestionResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        question: null,
        isSuccess: false,
    });

    const [updatedOptions, setUpdatedOptions] = useState<QuestionOption[]>([]);

    const getOptions = (options: QuestionOption[]) => {
        setUpdatedOptions(options);
    };

    const [submitted, setSubmitted] = useState(false);

    return (
        <>
            <AppLoader loading={editQuestion.isPending} className="position-absolute" />
            {editQuestion.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Question options updated successfully." id="registersuccess" type="success" />
                    {/* <Redirect to="/sysadmin/clients" /> */}
                </Fragment>
            )}{" "}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={values => {
                    setSubmitted(true);
                    setEditQuestion({ ...editQuestion, isPending: true });

                    var patch = createPatch(props.question, x => {
                        x.id = values.questionId;
                        x.minAnswerLabel = values.minLabel;
                        x.maxAnswerLabel = values.maxLabel;
                        x.minAnswerYLabel = values.minLabelY;
                        x.maxAnswerYLabel = values.maxLabelY;
                        x.description = values.description;
                        x.descriptionY = values.descriptionY;
                        x.allowSkip = values.allowSkip;
                        x.gridSize = values.gridSize;
                        x.minAnswer = values.minAnswer;
                        x.maxAnswer = values.maxAnswer;
                    });

                    if (patch && patch.length > 0) {
                        // Update profile
                        // @ts-ignore

                        api.questions.patch(props.question?.id, patch).then(
                            result => {
                                setEditQuestion({ ...editQuestion, isPending: false, isSuccess: true });
                            },
                            error => {
                                setEditQuestion({ ...editQuestion, isPending: false });
                                history.push("/error");
                                console.log(error);
                            }
                        );
                    } else {
                        setEditQuestion({ ...editQuestion, isPending: false });
                    }
                    // Update Question Options pathc request API cal
                    handleUpdateOptions(api, updatedOptions, props.question.options);
                    setEditQuestion({ ...editQuestion, isPending: false });
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="matrixGridForm" className="mt-2 editModuleForm">
                            <Row>
                                <Col>
                                    <h6>
                                        <b>X-Axis</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Label</label>
                                    <Field
                                        name="description"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.description && touched.description ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.description && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.description}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Low Label</label>
                                    <Field
                                        name="minLabel"
                                        type="text"
                                        className={
                                            "form-control" + (errors.minLabel && touched.minLabel ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.minLabel && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.minLabel}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>High Label</label>
                                    <Field
                                        name="maxLabel"
                                        type="text"
                                        className={
                                            "form-control" + (errors.maxLabel && touched.maxLabel ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.maxLabel && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.maxLabel}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h6>
                                        <b>Y-Axis</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Label</label>
                                    <Field
                                        name="descriptionY"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.descriptionY && touched.descriptionY ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.descriptionY && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.descriptionY}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Low Label</label>
                                    <Field
                                        name="minLabelY"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.minLabelY && touched.minLabelY ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.minLabelY && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.minLabelY}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>High Label</label>
                                    <Field
                                        name="maxLabelY"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.maxLabelY && touched.maxLabelY ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.maxLabelY && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.maxLabelY}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h6>
                                        <b>Axis Limits</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Low</label>
                                    <Field
                                        name="minAnswer"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.minAnswer && touched.minAnswer ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.minAnswer && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.minAnswer}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>High</label>
                                    <Field
                                        name="maxAnswer"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.maxAnswer && touched.maxAnswer ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.maxAnswer && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.maxAnswer}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h6>
                                        <b>Display Grid</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label></label>
                                    <label>
                                        <Field type="checkbox" name="allowSkip" />
                                        Allow user to skip statemenrs
                                    </label>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Grid Size</label>
                                    <Field name="gridSize" as="select" className="form-select">
                                        <option value="2*2">2*2</option>
                                        <option value="3*3">3*3</option>
                                    </Field>
                                </Col>
                            </Row>

                            <ContentLabel text="Answer options" />
                            <AddQuestionOptions
                                question={props.question}
                                updatedOptions={options => getOptions(options)}
                            />
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={editQuestion.isPending}>
                                    Update
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default MatrixGrid;
