import { Course, Video, VideoUsageReport } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { useCurrentUser } from "../../../common/user/context";
import { UserHasOneOfRoles, UserHasSpecificRole } from "../../../common/user/utils";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify, showToast } from "../../../components/Notify";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "../../../components/Spinner";
import { EXPERT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { CardBlock } from "../../../components/CardBlock";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { VideoPlayer } from "../../../components/VideoPlayer";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Name is required"),
        descriptions: Yup.string().required("Description is required"),
    });
};

type VideoResponse = {
    isPending: boolean;
    video: Video;
    isUpdateCompleted: boolean;
};

type VideoUsageReportResponse = {
    isPending: boolean;
    videoUsageReport: VideoUsageReport;
};

type ExpertCoursesResponse = {
    isPending: boolean;
    courses: Course[];
};

type RouteParams = {
    videoId: string;
};

const EditVideo = (): JSX.Element => {
    const api = createApi();
    const params = useParams<RouteParams>();
    //@ts-ignore
    const videoId = parseInt(params.videoId);
    const userContext = useCurrentUser();
    const [videoResponse, setVideoResponse] = useState<VideoResponse>({
        isPending: false,
        // @ts-ignore

        video: null,
        // @ts-ignore

        isUpdateCompleted: null,
    });

    const [expertCourses, setExpertCourses] = useState<ExpertCoursesResponse>({
        isPending: false,
        // @ts-ignore

        courses: null,
    });

    const [videoUsageResponse, setVideoUsageResponse] = useState<VideoUsageReportResponse>({
        isPending: false,
        // @ts-ignore

        videoUsageReport: null,
    });

    const sysadminVideosDispatch = useSysadminContextDispatch();

    useEffect(() => {
        if (videoId) {
            setVideoUsageResponse({ ...videoUsageResponse, isPending: true });
            api.videos.videoUsageReport(videoId).then(
                result => {
                    setVideoUsageResponse({ ...videoUsageResponse, isPending: false, videoUsageReport: result });
                },
                error => {
                    setVideoUsageResponse({ ...videoUsageResponse, isPending: false });
                    console.log(error);
                }
            );
        }
    }, [videoId]);

    useEffect(() => {
        setVideoResponse({ ...videoResponse, isPending: true });
        api.videosClient.get(videoId).then(
            res => {
                setVideoResponse({ ...videoResponse, isPending: false, video: res });
            },
            error => {
                setVideoResponse({ ...videoResponse, isPending: false });
                console.log(error);
            }
        );
    }, []);

    const getExpertCourses = () => {
        // @ts-ignore

        if (UserHasSpecificRole(userContext, EXPERT_ROLE)) {
            setExpertCourses({ ...expertCourses, isPending: true });
            //API start loading here
            api.courses.query(false).then(
                result => {
                    setExpertCourses({ ...expertCourses, isPending: false, courses: result });
                },
                error => {
                    console.log(error);
                    setExpertCourses({ ...expertCourses, isPending: false });
                }
            );
        } else {
            setExpertCourses({ ...expertCourses, isPending: false });
        }
    };

    useEffect(() => {
        getExpertCourses();
    }, []);

    const updateVideo = (data: Video) => {
        const patch = createPatch(videoResponse.video, x => {
            x.name = data.name;
            x.description = data.description;
            x.status = data.status;
            x.ownerId = data.ownerId;
            x.tags = data.tags;
        });
        if (patch.length > 0) {
            setVideoResponse({ ...videoResponse, isPending: true });
            // @ts-ignore

            api.videosClient.patch(videoResponse.video.id, patch).then(
                response => {
                    showToast("Video updated successfully.", "success");
                    setVideoResponse({ ...videoResponse, isPending: false, video: response, isUpdateCompleted: true });
                    sysadminVideosDispatch(s => ({
                        ...s,
                        // @ts-ignore

                        videos: s.videos.map(v => (v.id === data.id ? response : v)),
                    }));
                },
                error => {
                    showToast("Something going wrong. Please try again later.", "warning");
                    setVideoResponse({ ...videoResponse, isPending: false, isUpdateCompleted: false });
                    console.log(error);
                }
            );
        }
    };

    const { isPending, video, isUpdateCompleted } = videoResponse;

    const initialValues = {
        name: video?.name || "",
        descriptions: video?.description || "",
        status: video?.status || "",
        ownerId: video?.ownerId || "",
        tags: video?.tags || [],
    };

    const breadCrumb = [
        { name: "Home", path: "/sysadmin" },
        { name: "Video Library", path: "/sysadmin/video-library" },
        { name: video?.name, path: null },
    ];
    // @ts-ignore

    const isSysadmin: boolean = UserHasOneOfRoles(userContext, [SYSADMIN_ROLE]);

    const checkModuleBlockVideos =
        videoUsageResponse.videoUsageReport &&
        videoUsageResponse.videoUsageReport.moduleBlockVideos &&
        videoUsageResponse.videoUsageReport.moduleBlockVideos.length > 0
            ? true
            : false;

    const checkIntroductionVideos =
        videoUsageResponse.videoUsageReport &&
        videoUsageResponse.videoUsageReport.courseIntroductionVideos &&
        videoUsageResponse.videoUsageReport.courseIntroductionVideos.length > 0
            ? true
            : false;

    const IsCourseAvailableForExpert = (courseId: number) => {
        if (expertCourses.courses && expertCourses.courses.length > 0) {
            return expertCourses.courses.some(x => x.id === courseId);
        }
        return true;
    };
    return (
        <>
            {video && (
                <Breadcrumbs
                    // @ts-ignore

                    breadCrumb={breadCrumb}
                />
            )}
            <div className="row">
                <AppLoader loading={isPending} />
                {video !== null ? (
                    <div className="col-md-12">
                        <CardBlock mb="xl">
                            {/* <AppLoader loading={ ( saveVideoResponse.pending ) ? true : false } className="position-absolute" /> */}
                            {isUpdateCompleted && isUpdateCompleted !== null && (
                                <Fragment>
                                    <Notify message="Video updated Successfully." id="video_update" type="success" />
                                    <Navigate to="/sysadmin/video-library" />
                                </Fragment>
                            )}
                            <ContentLabel text="Edit Video" />
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validate()}
                                onSubmit={(values, helpers) => {
                                    const data = {
                                        id: videoResponse.video.id,
                                        name: values.name,
                                        description: values.descriptions,
                                        status: values.status,
                                        ownerId: values.ownerId ? values.ownerId : video?.ownerId,
                                        tags: values.tags,
                                    };
                                    updateVideo(data as unknown as Video);
                                }}
                            >
                                {formikProps => {
                                    const { errors, touched, setFieldValue } = formikProps;
                                    return (
                                        <Form action="#" name="addCourseForm" className="mt-2 editCourseForm">
                                            <Row>
                                                <Col md={6}>
                                                    <label>Video Id</label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col md={6}>
                                                    <label>{videoId}</label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col md={6}>
                                                    <label>Name</label>
                                                    <Field
                                                        name="name"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (errors.name && touched.name ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="name"
                                                        component="span"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Col md={6}>
                                                    <label>Description</label>
                                                    <Field
                                                        name="descriptions"
                                                        type="text"
                                                        className={
                                                            "description form-control" +
                                                            (errors.descriptions && touched.descriptions
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="description"
                                                        component="span"
                                                    />
                                                </Col>
                                            </Row>
                                            {isSysadmin && (
                                                <Row className="mb-4">
                                                    <Col md={6}>
                                                        <label>Video Expert</label>
                                                        <RoleBasedUser roleName={EXPERT_ROLE} fieldName="ownerId" />
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row className="mb-4">
                                                <Col md={6}>
                                                    <label>Status</label>
                                                    <Field
                                                        name="status"
                                                        as="select"
                                                        className="form-select"
                                                        disabled={checkModuleBlockVideos || checkIntroductionVideos}
                                                    >
                                                        <option key="1" value="Active">
                                                            Active
                                                        </option>
                                                        <option key="4" value="Deleted">
                                                            Deleted
                                                        </option>
                                                        <option key="6" value="Disabled">
                                                            Disabled
                                                        </option>
                                                        <option key="7" value="UploadedToBlob">
                                                            UploadedToBlob
                                                        </option>
                                                    </Field>

                                                    <p
                                                        className="fs-6"
                                                        style={{ color: "grey" }}
                                                        hidden={!checkModuleBlockVideos || !checkIntroductionVideos}
                                                    >
                                                        You cannot change the status while this video is used by a
                                                        course.
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col md={6}>
                                                    <label className="form-label">Tags</label>
                                                    <AutocompleteTags
                                                        module={`videos`}
                                                        // @ts-ignore
                                                        defaultValue={videoResponse?.video?.tags}
                                                        selectedValue={(value: string[]) =>
                                                            setFieldValue("tags", value)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <label>Video</label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                {video && video.status !== "UploadedToBlob" && video.id && (
                                                    <VideoPlayer
                                                        // @ts-ignore
                                                        url={video.url}
                                                        id={video.id}
                                                    />
                                                )}
                                                <RenderIf show={video.status === "UploadedToBlob" ? true : false}>
                                                    <Col md={6} className="mt-2">
                                                        <p className="alert alert-info">
                                                            The video is uploaded and is being processing by our
                                                            systems. The status of the video will automatically change
                                                            when processing is completed.
                                                        </p>
                                                    </Col>
                                                </RenderIf>
                                            </Row>
                                            <ContentBlockButtonContainer>
                                                <PrimaryButton type="submit">Update Video</PrimaryButton>
                                            </ContentBlockButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardBlock>
                    </div>
                ) : null}

                <div className="col-md-12">
                    <AppLoader loading={expertCourses.isPending} />
                    <CardBlock mb="xl">
                        <ContentLabel text="Video Usage" />
                        <table className="table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th>Course Name</th>
                                    <th>Usage</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoUsageResponse.videoUsageReport &&
                                    videoUsageResponse.videoUsageReport.courseIntroductionVideos &&
                                    videoUsageResponse.videoUsageReport.courseIntroductionVideos.map(
                                        (introductionVideo, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {IsCourseAvailableForExpert(introductionVideo.courseId) ? (
                                                        <Link
                                                            to={`/sysadmin/courses/${introductionVideo.courseId}/edit`}
                                                        >
                                                            {introductionVideo.courseName}
                                                        </Link>
                                                    ) : (
                                                        introductionVideo.courseName
                                                    )}
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span>
                                                        <i className="fas fa-lock" />
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                {videoUsageResponse.videoUsageReport &&
                                    videoUsageResponse.videoUsageReport.moduleBlockVideos &&
                                    videoUsageResponse.videoUsageReport.moduleBlockVideos
                                        // @ts-ignore
                                        .sort((a, b) => a.courseName.localeCompare(b.courseName))
                                        .map((moduleBlockVideo, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {IsCourseAvailableForExpert(moduleBlockVideo.courseId) ? (
                                                        <Link
                                                            to={`/sysadmin/courses/${moduleBlockVideo.courseId}/chapter/${moduleBlockVideo.chapterId}/edit`}
                                                        >
                                                            {moduleBlockVideo?.courseName}
                                                        </Link>
                                                    ) : (
                                                        moduleBlockVideo?.courseName
                                                    )}
                                                </td>

                                                <td>
                                                    {IsCourseAvailableForExpert(moduleBlockVideo.courseId) ? (
                                                        <Link
                                                            to={`/sysadmin/courses/${moduleBlockVideo.courseId}/chapter/${moduleBlockVideo.chapterId}/module/${moduleBlockVideo.moduleId}/block/${moduleBlockVideo.moduleBlockId}/edit`}
                                                        >
                                                            {moduleBlockVideo.moduleBlockName}
                                                        </Link>
                                                    ) : (
                                                        moduleBlockVideo.moduleBlockName
                                                    )}
                                                </td>
                                                <td>
                                                    {IsCourseAvailableForExpert(moduleBlockVideo.courseId) ? (
                                                        <span></span>
                                                    ) : (
                                                        <span>
                                                            <i className="fas fa-lock" />
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </CardBlock>
                </div>
            </div>
        </>
    );
};

export default EditVideo;
