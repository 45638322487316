import { Course, CourseStatuses, CourseTypes, User } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { useUserContext } from "../../../common/user/context";
import { UserHasOneOfRoles } from "../../../common/user/utils";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify } from "../../../components/Notify";
import ReactMdeEditor from "../../../components/ReactMdeEditor";
import { AppLoader } from "../../../components/Spinner";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import { useUpdateCurrentCourseFromParams } from "../helper";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Course Name is required"),
    });
};

interface EditCourseResponse {
    course: Course;
    isPending: boolean;
    isSuccess: boolean;
}

type EditCourseProps = {
    courseId: number;
    course: Course;
};

interface FormModel {
    courseId: number;
    name: string;
    description: string;
    //order: number;
    status: CourseStatuses;
    enforceSequence: boolean;
    adminName: string;
    courseType: CourseTypes;
    enableDiploma: boolean;
    ownerId: number;
    tags: string[];
}

interface ExpertUsersResponse {
    expertUsersList: User[];
    isPending: boolean;
}

const EditCourse = (props: EditCourseProps) => {
    const history = useHistory();
    const api = createApi();
    const updateCourse = useUpdateCurrentCourseFromParams();

    const [editCourse, setEditCourse] = useState<EditCourseResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        course: null,
        isSuccess: false,
    });

    const [expertUsers, setExpertUsers] = useState<ExpertUsersResponse>({
        // @ts-ignore

        expertUsersList: null,
        isPending: false,
    });

    useEffect(() => {
        // Fetch admin courses if null
        GetAllExperts();
    }, []);

    const GetAllExperts = () => {
        setExpertUsers({ ...expertUsers, isPending: true });
        api.users.query(null, null, null, null).then(
            result => {
                setExpertUsers({
                    // @ts-ignore

                    expertUsersList: result.filter(x => x.roles.includes("Experts")),
                    isPending: false,
                });
            },
            error => {
                console.log(error);
                setExpertUsers({ ...expertUsers, isPending: false });
            }
        );
    };

    const initialValues: FormModel = {
        // @ts-ignore

        courseId: props.course.id,
        name: props.course.name,
        // @ts-ignore

        description: props.course.description,
        status: props.course.status,
        //order: props.course.order,
        enforceSequence: props.course.enforceSequence,
        // @ts-ignore

        adminName: props.course.adminName,
        courseType: props.course.courseType,
        enableDiploma: props.course.enableDiploma,
        // @ts-ignore

        ownerId: props.course.ownerId,
        // @ts-ignore

        tags: props.course.tags,
    };

    const [description, setDescription] = useState<string>(
        // @ts-ignore

        props.course.description
    );
    const [submitted, setSubmitted] = useState(false);
    const isLoading = editCourse.isPending ? true : false;
    const userContext = useUserContext();
    // @ts-ignore

    const isSysadmin: boolean = UserHasOneOfRoles(userContext.user, ["Admin"]);
    return (
        <>
            <AppLoader
                loading={isLoading || expertUsers.isPending || editCourse.isPending ? true : false}
                className="position-absolute"
            />
            {editCourse.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Course updated successfully." id="registersuccess" type="success" />
                    {/* <Redirect to="/sysadmin/courses" /> */}
                </Fragment>
            )}
            <ContentLabel text="Edit Program or Capability" />
            <Row>
                <Col className="col-md-11">
                    <h2>{props.course?.name}</h2>
                </Col>
                <Col className="col-md-1 text-right text-danger"></Col>
            </Row>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={(values, helpers) => {
                    setSubmitted(true);
                    setEditCourse({ ...editCourse, isPending: true });
                    const patch = createPatch(props.course, x => {
                        x.name = values.name;
                        x.description = values.description;
                        x.status = values.status;
                        x.enforceSequence = values.enforceSequence;
                        x.adminName = values.adminName;
                        x.courseType = values.courseType;
                        x.enableDiploma = values.enableDiploma;
                        x.ownerId = values.ownerId;
                        x.tags = values.tags;
                    });

                    if (patch && patch.length > 0) {
                        // Update Course
                        api.courses.patch(props.courseId, patch).then(
                            result => {
                                setEditCourse({
                                    ...editCourse,
                                    course: result,
                                    isPending: false,
                                    isSuccess: true,
                                });
                                updateCourse(s => ({
                                    ...s,
                                    result,
                                }));
                            },
                            error => {
                                setEditCourse({ ...editCourse, isPending: false });
                                history.push("/error");
                                console.log(error);
                            }
                        );
                    } else {
                        setEditCourse({ ...editCourse, isPending: false });
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched, setFieldValue } = formikProps;
                    return (
                        <Form name="editCourseForm" className="mt-2 editCourseForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="name"
                                        component="span"
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Admin Name</label>
                                    <Field
                                        name="adminName"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.adminName && touched.adminName ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.adminName && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.adminName}
                                        </span>
                                    )}
                                </Col>
                                <Col></Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Description</label>
                                    <ReactMdeEditor
                                        onChange={value => {
                                            setDescription(value);
                                            setFieldValue("description", value);
                                        }}
                                        initialValue={description}
                                    />
                                    {/* <ErrorMessage
                                     className="clearfix w-100 text-danger text-left displayblock"
                                     name="description"
                                     component="span"
                                     /> */}
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Enforce Sequence</label>
                                    <Field name="enforceSequence" as="select" className="form-select">
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Field>
                                </Col>

                                <Col>
                                    <label>Status</label>
                                    {/** Please move the option in const I think we can reuse this eiter we have to
                                     * write it eveywhere
                                     */}
                                    <Field name="status" as="select" className="form-select">
                                        <option value="Active">Active</option>
                                        <option value="Inprogress">Inprogress</option>
                                        <option value="Deleted">Deleted</option>
                                        <option value="Disabled">Disabled</option>
                                        <option value="Template">Template</option>
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Enable Diploma</label>
                                    <Field name="enableDiploma" as="select" className="form-select">
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Field>
                                </Col>
                                <Col>
                                    <label>Type</label>
                                    <Field name="courseType" as="select" className="form-select">
                                        <option value="Training">Training</option>
                                        <option value="ImplementationCourse">Implementation Course</option>
                                        <option value="AssessmentCourse">AssessmentCourse</option>
                                    </Field>
                                </Col>
                            </Row>
                            {isSysadmin && (
                                <Row className="mb-4">
                                    <Col>
                                        <label>Course Expert</label>
                                        <RoleBasedUser roleName={"Expert"} fieldName="ownerId" />
                                    </Col>
                                </Row>
                            )}
                            <Row className="mb-4">
                                <Col>
                                    <label className="form-label">Tags</label>
                                    <AutocompleteTags
                                        module={`courses`}
                                        // @ts-ignore

                                        defaultValue={props?.course?.tags}
                                        selectedValue={(value: string[]) => setFieldValue("tags", value)}
                                    />
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" loading={isLoading}>
                                    Save
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default EditCourse;
