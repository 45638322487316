import { createStyles } from "@mantine/core";

export const useTeamCardStyles = createStyles(() => ({
    card: {
        width: "100%",
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
    },
}));
