import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { DemoGraphicQuestionsTemplateProps } from ".";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import StyledCheckbox from "../../../components/StyledCheckbox";

/*  eslint-disable react-hooks/exhaustive-deps */

export const CheckboxTypeQuestion = (props: DemoGraphicQuestionsTemplateProps) => {
    const { question, answer, error } = props.questionAndAnswer;
    const { id, label, options, required } = question;
    // @ts-ignore
    const questionOptions = options.split("\n");

    const [answerValues, setAnswerValues] = useState<string[]>(answer && answer.value ? answer.value.split(",") : []);
    const [isQuestionTouched, setIsQuestionTouched] = useState<boolean>(false);

    const handleChange = (checked: boolean, answerValue: string): void => {
        if (checked) {
            setAnswerValues([...answerValues, answerValue]);
        } else {
            setAnswerValues([...answerValues.filter(s => s !== answerValue)]);
        }
        setIsQuestionTouched(true);
    };

    useEffect(() => {
        if (isQuestionTouched) {
            props.setAnswers(answerValues.join(","), answer?.id);
        }
    }, [answerValues, isQuestionTouched]);

    const answers = answerValues && answerValues.length > 0 ? answerValues.join(",") : "";
    return (
        <Col className="mb-3" key={`questions-answers-${id}`}>
            <label htmlFor={`formControl${label}`} className="demographic-questions-label form-label">
                {label}
            </label>
            {questionOptions &&
                questionOptions.map((o, i) => (
                    <StyledCheckbox
                        key={i}
                        checked={answers.includes(o)}
                        onChange={checked => handleChange(checked, o)}
                        label={o}
                    ></StyledCheckbox>
                ))}

            {required === true && error && <DisplayInlineError error={error} />}
        </Col>
    );
};
