import { ActionIcon, Flex, SelectItem, Tooltip } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "mantine-react-table";
import { useMemo } from "react";
import { UserRoles, UserStatus } from "@/api-client";
import { PrimaryButton, RowActions, showToast, Table, UserStatusBadge } from "@/components";
import { UserHasOneOfRoles } from "../../common/user/utils";
import { UrlFactory } from "../../routing/UrlFactory";
import { useAuth } from "../../auth/useAuth";
import { RowActionConfig } from "../../components/Table/Table";
import { Icons } from "@/common";

export type UserRow = {
    id: number;
    email: string;
    status: UserStatus;
    hasAcceptedInvite: boolean;
    displayName: string | null;
    clientName?: string;
    roles: UserRoles[];
};

type UserListProps = {
    users: UserRow[] | null;
    onAddAdminClicked: () => void;
};

const acceptedInviteOptions: boolean[] = [true, false];

const statusOptions: UserStatus[] = ["Active", "Inactive", "Deleted"];

const userRolesOptions: readonly UserRoles[] = [
    "Admin",
    "User",
    "Agent",
    "GroupLeader",
    "AccountOwner",
    "System",
    "Expert",
] as const;

const EditAction = ({ user }: { user: UserRow }) => {
    if (UserHasOneOfRoles({ ...user, firstName: null, lastName: null }, ["Admin", "Agent", "Expert"])) {
        return <RowActions.EditLink to={UrlFactory.sysadmin.admin.create({ userId: user.id })} />;
    }
    return <RowActions.EditLink to={UrlFactory.sysadmin.user.create({ userId: user.id })} />;
};

const ImpersonateAction = (props: { userId: number }) => {
    const { impersonate } = useAuth();
    const { t } = useTranslate();

    const loginByUserId = (userId: number) => {
        impersonate({ type: "user", userId: userId }).catch(() => showToast(`Cannot impersonate user`, "error"));
    };

    return (
        <Tooltip label={t("SYSADMIN_LOGIN_AS_USER")}>
            <ActionIcon onClick={() => loginByUserId(props.userId)}>
                <Icons.SignIn />
            </ActionIcon>
        </Tooltip>
    );
};

const ImpersonateRowAction = ({ user }: { user: UserRow }) => {
    if (!UserHasOneOfRoles({ ...user, firstName: null, lastName: null }, ["Admin"])) {
        return <ImpersonateAction userId={user.id} />;
    }
    return null;
};

export const UserList = (props: UserListProps) => {
    const { t } = useTranslate();

    const convertToYesNo = (value: boolean) => (value ? t("SYSADMIN_YES") : t("SYSADMIN_NO"));

    const userStatusSelectItems = statusOptions.map(s => ({
        label: s,
        value: s,
    })) as SelectItem[] & string;

    const invitationStatusesSelectItems = acceptedInviteOptions.map(v => ({
        label: convertToYesNo(v),
        value: convertToYesNo(v),
    })) as SelectItem[] & string;

    const roleSelectItems = userRolesOptions.map(v => ({
        label: v,
        value: v,
    })) as SelectItem[] & string;

    const columnFilters: MRT_ColumnFiltersState = [
        {
            id: "status",
            value: ["Active"],
        },
        {
            id: "hasAcceptedInvite",
            value: [convertToYesNo(true)],
        },
    ];

    const columns = useMemo<MRT_ColumnDef<UserRow>[]>(
        () => [
            {
                header: t("SYSADMIN_NAME"),
                accessorKey: "displayName",
            },
            {
                header: t("SYSADMIN_EMAIL"),
                accessorKey: "email",
            },
            {
                id: "status",
                header: t("SYSADMIN_STATUS"),
                accessorKey: "status",
                filterVariant: "multi-select",
                Cell: ({ row }) => <UserStatusBadge status={row.original.status} />,
                mantineFilterMultiSelectProps: {
                    data: userStatusSelectItems,
                },
                size: 120,
            },
            {
                id: "hasAcceptedInvite",
                header: t("SYSADMIN_ACCEPTED_INVITE"),
                accessorFn: user => convertToYesNo(user.hasAcceptedInvite),
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: invitationStatusesSelectItems,
                },
            },
            {
                header: t("SYSADMIN_CLIENT"),
                accessorKey: "clientName",
            },
            {
                id: "roles",
                header: t("SYSADMIN_ROLES"),
                accessorFn: user => user.roles.join(", "),
                filterVariant: "multi-select",
                size: 120,
                mantineFilterMultiSelectProps: {
                    data: roleSelectItems,
                },
            },
        ],
        []
    );

    const rowActions: RowActionConfig<UserRow> = useMemo(
        () => ({
            renderRowActions: ({ row }) => (
                <>
                    <ImpersonateRowAction user={row.original} />
                    <EditAction user={row.original} />
                </>
            ),
            size: 75,
        }),
        []
    );

    return (
        <Table
            columns={columns}
            data={props.users}
            toolbarActions={() => (
                <PrimaryButton onClick={props.onAddAdminClicked}>{t("SYSADMIN_BREADCRUMBS_ADD_ADMIN")}</PrimaryButton>
            )}
            initialColumnFilterState={columnFilters}
            rowActions={rowActions}
        />
    );
};
