import React, { createContext, useContext, useEffect, useState } from "react";
import { createApi } from "./api";
import { useCurrentUser } from "./user/context";

/* eslint-disable react-hooks/exhaustive-deps */

interface UnstartedCoursesProps {
    children: React.ReactNode;
}

type UnstartedCourses = {
    numberOfUnstartedCourses: number;
    refresh: () => void;
    hasUnstartedCourses: boolean;
};

const defaults = {
    numberOfUnstartedCourses: 0,
    refresh: () => undefined,
    hasUnstartedCourses: false,
};

const UnstartedCoursesContext = createContext<UnstartedCourses>(defaults);

export const UnstartedCoursesProvider = (props: UnstartedCoursesProps) => {
    const [numberOfUnstartedCourses, setNumberOfUnstartedCourses] = useState(0);
    const currentUser = useCurrentUser();

    const getNumberOfUnstaredCourses = () => {
        //eslint-disable-next-line react-hooks/rules-of-hooks
        const api = createApi();
        api.participantProgress.getNumberOfUnstartedCourses(currentUser!.id!).then(result => {
            setNumberOfUnstartedCourses(result);
        });
    };

    useEffect(() => getNumberOfUnstaredCourses(), [currentUser]);

    const value = {
        numberOfUnstartedCourses: numberOfUnstartedCourses,
        refresh: getNumberOfUnstaredCourses,
        hasUnstartedCourses: numberOfUnstartedCourses > 0,
    };

    return <UnstartedCoursesContext.Provider value={value}>{props.children}</UnstartedCoursesContext.Provider>;
};

export const useUnstartedCourses = () => useContext(UnstartedCoursesContext);
