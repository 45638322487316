import React, { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import SideNav from "../SideNav/SideNav";
import TrainingSideNavNotEnforcedSequence from "../SideNav/SideNavNotEnforce";
import "./sidebar.scss";
import { getTrainingNavItems, NavItem } from "../../helpers/TrainingNavHelper";
import { useLocation, useParams } from "react-router-dom";
import { useCurrentCourse, useCurrentUserProgress } from "../../../common/courses/context";
import { TrainingRouteParams } from "../..";
import { ParticipantProgress } from "@/api-client";
import { createStyles } from "@mantine/core";
import { radius, shadow } from "../../../components/CardBlock";

const useStyles = createStyles(theme => ({
    sidebar: {
        borderRadius: theme.fn.radius(radius),
        boxShadow: theme.shadows[shadow],
    },
}));

export const TrainingSidebar = () => {
    const { classes } = useStyles();
    const params = useParams<TrainingRouteParams>();
    const location = useLocation();
    //@ts-ignore
    const groupId = parseInt(params.groupId);
    const currentCourse = useCurrentCourse();
    const userCourseProgress: ParticipantProgress[] = useCurrentUserProgress();
    const { t } = useTranslate();

    const isEnforceSequence = !!(
        currentCourse &&
        currentCourse.enforceSequence &&
        currentCourse.enforceSequence === true
    );

    const navItems: NavItem[] | undefined = useMemo(() => {
        if (!currentCourse || !userCourseProgress) {
            return undefined;
        }

        return getTrainingNavItems(
            currentCourse,
            userCourseProgress,
            `groups/${groupId}/training`,
            location,
            params as any,
            t
        );
    }, [currentCourse, groupId, location, params, userCourseProgress]);

    return (
        <aside className={`sidebar training-sidebar ${classes.sidebar}`}>
            {isEnforceSequence === true ? (
                <SideNav items={navItems} />
            ) : (
                <TrainingSideNavNotEnforcedSequence items={navItems} />
            )}
        </aside>
    );
};
