﻿import { Text, Badge, Title, Flex, Stack, Image, createStyles } from "@mantine/core";
import { ModalConfig, RenderIf, VimeoPlayer } from "@/components";

export type UrlType = {
    value: string;
    type: "video" | "image";
};

type ProgramDetailsProps = {
    shortDescription: string;
    url: UrlType;
    experts: string[];
    languages: string[];
    numberOfModules: number;
    breadText: { title: string; text: string }[];
};

const useStyles = createStyles(() => ({
    breadText: {
        "& p": {
            whiteSpace: "pre-wrap",
        },
    },
}));

export const ProgramDetails = (props: ProgramDetailsProps) => {
    const { classes } = useStyles();

    return (
        <Flex direction="column" gap="md">
            <Text fs="italic">{props.shortDescription}</Text>

            <Stack>
                <RenderIf show={props.url.type === "image"}>
                    <Image height={400} src={props.url.value} alt="Image" />
                </RenderIf>
                <RenderIf show={props.url.type === "video"}>
                    <VimeoPlayer url={props.url.value} />
                </RenderIf>
            </Stack>

            <Flex gap="md">
                <Badge> Expert: {props.experts.join(", ")} </Badge>
                <Badge> Language: {props.languages.join(", ")} </Badge>
                <RenderIf show={props.numberOfModules > 0}>
                    <Badge> Modules: {props.numberOfModules} </Badge>
                </RenderIf>
            </Flex>

            <Text>
                {props.breadText.map(p => (
                    <div key={p.title} className={classes.breadText}>
                        <h5>{p.title}</h5>
                        <p>{p.text}</p>
                    </div>
                ))}
            </Text>
        </Flex>
    );
};

export const ProgramDetailsModal = (title: string): ModalConfig<ProgramDetailsProps> => ({
    content: ProgramDetails,
    title: (
        <Flex>
            <Title order={2}>{title}</Title>
        </Flex>
    ),
});
