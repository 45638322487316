import React, { Fragment } from "react";
import { Table as BTable } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useExpanded, useTable } from "react-table";
import { createStyles } from "@mantine/core";
import { Group, OrganisationUserReport } from "@/api-client";
import { createHierachicalGroupsForToggleByParentId, GroupsWithParentId } from "../../OrganisationPlanning/helper";
import { NavButton } from "@/components";

/*  eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */
const TableOptions = ({ columns: userColumns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded // Use the useExpanded plugin hook
    );

    return (
        <>
            <BTable className="organisation-table-details table-borderless" striped hover {...getTableProps()}>
                <thead>
                    {
                        /* @ts-ignore */
                        headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    /* @ts-ignore */
                                    headerGroup.headers.map((column, j) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        /* @ts-ignore */
                        rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        /* @ts-ignore */
                                        row.cells.map((cell, j) => {
                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </BTable>
        </>
    );
};

export interface OrganisationDataState {
    organisations: OrganisationUserReport[];
    isPending: boolean;
    error: string;
}

type OrganisationReportProps = {
    link: string;
    buttonTitle: string;
    groupId?: number;
    groups: Group[];
};

const useStyles = createStyles(() => ({ right: { float: "right" } }));

const OrganisationReport = (props: OrganisationReportProps): JSX.Element => {
    const { t } = useTranslate();
    let organisationsTreeStructure: GroupsWithParentId[] = [];
    const { classes } = useStyles();

    if (props.groupId !== undefined) {
        const currentGroup = props.groups?.find(f => f.id === props.groupId);
        const currentGroupChildren = props.groups?.filter(f => f.parentId === props.groupId);
        if (currentGroupChildren && currentGroupChildren.length > 0) {
            organisationsTreeStructure = createHierachicalGroupsForToggleByParentId([
                /* @ts-ignore */
                currentGroup,
                ...currentGroupChildren,
            ]);
        } else {
            organisationsTreeStructure = [];
        }
    } else {
        organisationsTreeStructure = createHierachicalGroupsForToggleByParentId(props.groups);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Info",
                columns: [
                    {
                        Header: "Groups",
                        accessor: "name",
                        /* @ts-ignore */
                        Cell: ({ row, cell }) => {
                            return (
                                <Fragment>
                                    {row.canExpand ? (
                                        <span
                                            {...row.getToggleRowExpandedProps()}
                                            {...row.getToggleRowExpandedProps({
                                                style: {
                                                    paddingLeft: `${row.depth * 10}px`,
                                                },
                                            })}
                                        >
                                            {cell.row.values.name}
                                            {row.isExpanded ? (
                                                <i className="organisation-report-expand fas fa-caret-down" />
                                            ) : (
                                                <i className="organisation-report-expand fas fa-caret-right" />
                                            )}
                                        </span>
                                    ) : (
                                        <span style={{ paddingLeft: `${row.depth * 10}px` }}>
                                            {cell.row.values.name}
                                        </span>
                                    )}
                                </Fragment>
                            );
                        },
                    },
                    {
                        Header: "Employees",
                        accessor: "employees",
                        /* @ts-ignore */
                        Cell: ({ cell }) => {
                            return <>{cell.row.values.employees}</>;
                        },
                    },
                    {
                        Header: "Onboarding Rate",
                        accessor: "acceptedInvitations",
                        /* @ts-ignore */
                        Cell: ({ cell }) => {
                            return <>{cell.row.values.acceptedInvitations}</>;
                        },
                    },
                    {
                        Header: "",
                        accessor: "action",
                        /* @ts-ignore */
                        Cell: ({ cell }) => {
                            return (
                                <div className={classes.right}>
                                    {props.buttonTitle ? (
                                        <NavButton
                                            link={`${props.link}/${cell.row.values.action}`}
                                            textKey={props.buttonTitle}
                                        />
                                    ) : null}
                                </div>
                            );
                        },
                    },
                ],
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableOptions columns={columns} data={organisationsTreeStructure} />
            {organisationsTreeStructure && organisationsTreeStructure.length === 0 && (
                <p className="organisation-not-found text-center mb-5">{t("ACCOUNTOWNER_MYORGANISATION_NOT_FOUND")}</p>
            )}
        </React.Fragment>
    );
};

export default OrganisationReport;
