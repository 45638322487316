import { Flex, Group, SelectItem } from "@mantine/core";
import type { ModalSettings } from "@mantine/modals/lib/context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslate } from "@tolgee/react";
import { TFunction } from "@/common";
import { FormInput, PrimaryButton } from "@/components";
import { dark, getColorsUnusedFirst, light } from "../../../../common/colors";
import { createApi } from "../../../../common/api";
import { showToast } from "../../../../components/Notify";

type AddTeamProps = {
    clientId: number;
    usedColors: string[];
    teamTypes: { name: string; id: number }[];
    onSave: (modalId: string) => void;
    modalId: string;
};

export type AddTeamModalProps = {
    modalId: string;
    clientId: number;
    usedColors: string[];
    teamTypes: { name: string; id: number }[];
    onSave: (modalId: string) => void;
};

const addTeamValidationSchema = Yup.object({
    name: Yup.string().required("COMMON_FIELD_REQUIRED"),
    color: Yup.string().required(),
    teamTypeId: Yup.string().required("COMMON_FIELD_REQUIRED"),
});

type AddTeamForm = Yup.InferType<typeof addTeamValidationSchema>;

export const AddTeam = (props: AddTeamProps) => {
    const { t } = useTranslate();

    const initialValues: AddTeamForm = {
        name: "",
        color: getColorsUnusedFirst(
            light.concat(dark).map(x => x.hex),
            props.usedColors,
            props.usedColors.length
        )[0],
        teamTypeId: "",
    };

    const teamTypeOptions: SelectItem[] = props.teamTypes.map(x => ({ value: x.id.toString(), label: x.name }));

    const onSubmit = (values: AddTeamForm) => {
        createApi()
            .accountOwners.addTeam(values.name, values.color, parseInt(values.teamTypeId), props.clientId)
            .then(() => {
                showToast(t("ACCOUNTOWNER_ADD_TEAMS_TEAM_ADDED"), "success");
                props.onSave(props.modalId);
            })
            .catchWithToast();
    };

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={addTeamValidationSchema}>
            {formikProps => (
                <Form>
                    <Flex direction="column" rowGap="md">
                        <FormInput.ColorInput fieldName="color" label="ACCOUNTOWNER_ADD_TEAMS_COLOR" />
                        <FormInput.Text fieldName="name" label="ACCOUNTOWNER_ADD_TEAMS_NAME" />
                        <FormInput.NativeSelect
                            fieldName="teamTypeId"
                            label="ACCOUNTOWNER_ADD_TEAMS_TEAM_TYPE"
                            placeholder="ACCOUNTOWNER_SELECT_TEAM_TYPE_PLACEHOLDER"
                            options={teamTypeOptions}
                        />
                        <Group position="right">
                            <PrimaryButton
                                type="submit"
                                disabled={!formikProps.isValid}
                                loading={formikProps.isSubmitting}
                                eventName={{ object: "ao_addteam", action: "save" }}
                            >
                                {t("COMMON_SAVE")}
                            </PrimaryButton>
                        </Group>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
};

export const addTeamModal = (t: TFunction, props: AddTeamModalProps): ModalSettings => {
    return {
        modalId: props.modalId,
        title: t("ACCOUNTOWNER_ADD_TEAMS_ADD_TEAM"),
        closeOnClickOutside: false,
        children: (
            <AddTeam
                modalId={props.modalId}
                usedColors={props.usedColors}
                teamTypes={props.teamTypes}
                clientId={props.clientId}
                onSave={modalId => props.onSave(modalId)}
            />
        ),
    };
};
