import { useParams } from "react-router-dom";
import { ProgramChild } from "./Program/ProgramChild";
import { TrainingModule } from "../training";

export const TrainingModulePage = () => {
    const params = useParams();

    return (
        <ProgramChild
            //@ts-ignore

            teamId={params.groupId}
            useFullWidth={true}
            breadCrumbTitleKey={"PROGRAM_MY_TRAINING"}
        >
            <TrainingModule
                //@ts-ignore

                courseId={params.courseId}
                //@ts-ignore

                groupId={params.groupId}
            />
        </ProgramChild>
    );
};
