import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { HubConnectionState } from "@microsoft/signalr";
import {
    Answer,
    Client,
    ClientCourseUserOnboardingProgress,
    Course,
    Group,
    GroupCourse,
    ParticipantProgress,
    Question,
} from "@/api-client";
import { StateWrapper2, WithChildren } from "../StateWrapper";
import { useCurrentUser } from "../user/context";
import { useAllCourses, useAllGroups } from "../OrganisationContext";
import { getCourseGroupInLocalStorage } from "../LocalStorageAdapter";
import { useSignalR } from "../signalr";
import { createApi } from "../api";
import { AppLoader } from "../../components/Spinner";

/* eslint-disable react-hooks/exhaustive-deps */

interface CurrentGroupAndCourseContextState {
    group?: Group;
    course?: Course;
    client?: Client;
    participantsProgress?: ParticipantProgress[];
    courseGroupAssignment?: GroupCourse;
    answers?: Answer[];
    currentCourseUserAnswers?: Answer[];
    clientCourseUserOnboardingProgress?: ClientCourseUserOnboardingProgress;
    questions?: Question[];
}

const CurrentGroupCourseContext =
    //@ts-ignore
    React.createContext<StateWrapper2<CurrentGroupAndCourseContextState, WithChildren>>(undefined);

// Helpers
export const useCurrentGroup = () => React.useContext(CurrentGroupCourseContext)?.state?.group;
export const useCurrentCourse = () => React.useContext(CurrentGroupCourseContext)?.state?.course;
export const useCurrentClient = () => React.useContext(CurrentGroupCourseContext)?.state?.client;
export const useParticipantsProgress = () => React.useContext(CurrentGroupCourseContext)?.state?.participantsProgress;
export const useCurrentCourseGroupAssignment = () =>
    React.useContext(CurrentGroupCourseContext)?.state?.courseGroupAssignment;

/**
 * Custom Hook to get the current course user answers
 * @returns Current User Answers
 */
export const useCurrentCourseUserAnswers = (): Answer[] => {
    const params = useParams<{ groupId: string }>();
    const answers = useAnswers();
    const courseGroupAssignment = useCurrentCourseGroupAssignment();
    const currentUser = useCurrentUser();

    //@ts-ignore
    return useMemo(
        () =>
            answers?.filter(
                a =>
                    a.roundId === courseGroupAssignment?.roundId &&
                    //@ts-ignore
                    a.groupId === parseInt(params.groupId) &&
                    a.userId === currentUser?.id
            ),
        [answers, courseGroupAssignment, currentUser, params.groupId]
    );
};

/**
 * Hook to get the current user progress
 * @returns Current User Progress
 */

export const useCurrentUserProgress = (): ParticipantProgress[] => {
    const participantsProgress = useParticipantsProgress();
    const courseGroupAssignment = useCurrentCourseGroupAssignment();
    const currentUser = useCurrentUser();
    return useMemo(
        () =>
            participantsProgress
                ?.filter(p => p.userId === currentUser?.id)
                .filter(p => p.roundId === courseGroupAssignment?.roundId) ?? [],
        [courseGroupAssignment, currentUser, participantsProgress]
    );
};

export const useCourseGroupUserOnboardingProgress = () =>
    React.useContext(CurrentGroupCourseContext)?.state?.clientCourseUserOnboardingProgress;
export const useQuestions = () => React.useContext(CurrentGroupCourseContext)?.state?.questions;

export const useAnswers = (groupId?: number) => {
    const answers = React.useContext(CurrentGroupCourseContext)?.state?.answers;
    return useMemo(() => {
        return groupId ? answers?.filter(a => a.groupId === groupId) : answers;
    }, [answers, groupId]);
};

export const useCurrentGroupCourseContextDispatch = () => React.useContext(CurrentGroupCourseContext)?.setState;

type RouteParams = {
    groupId?: string;
};

// NOTE: React functional components doesn't have callback in setState so we wrap the actual state a old-style class component
class CurrentGroupCourseContextProvider extends React.Component<WithChildren, CurrentGroupAndCourseContextState> {
    //@ts-ignore
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const stateWrapper: StateWrapper2<CurrentGroupAndCourseContextState, WithChildren> = {
            state: this.state,
            setState: this.setState.bind(this),
        };

        return (
            <CurrentGroupCourseContext.Provider value={stateWrapper}>
                <CurrentGroupCourseContextProviderInner>{this.props.children}</CurrentGroupCourseContextProviderInner>
            </CurrentGroupCourseContext.Provider>
        );
    }
}

export default CurrentGroupCourseContextProvider;

const CurrentGroupCourseContextProviderInner = (props: WithChildren) => {
    const api = createApi();
    const params = useParams<RouteParams>();
    //@ts-ignore
    const groupId = parseInt(params.groupId);
    const dispatch = useCurrentGroupCourseContextDispatch();
    const currentCourseFromContext = useCurrentCourse();
    const allGroups = useAllGroups();
    const allCourses = useAllCourses();
    const currentUser = useCurrentUser();
    const stickySelection = getCourseGroupInLocalStorage();
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Connect SignalR
     */
    const signalr = useSignalR();

    useEffect(() => {
        const sendActiveGroup = async () => {
            if (signalr !== undefined && signalr.state === HubConnectionState.Connected) {
                await signalr.send("SetActiveGroup", groupId);
            }
        };

        sendActiveGroup().finally();
    }, [groupId, signalr, signalr?.state]);

    useEffect(() => {
        setIsLoading(true);

        if (isNaN(groupId)) {
            //Happens when routed to course-selector
            setIsLoading(false);
            return;
        }

        const currentGroup = allGroups?.find(g => g.id === groupId);
        dispatch(s => ({ ...s, group: currentGroup }));

        api.participantProgress.query(null, groupId, null, null, null).then(progress => {
            dispatch(s => ({
                ...s,
                participantsProgress: progress,
            }));
        });

        let currentCourse = currentCourseFromContext;

        if (currentGroup !== undefined && allCourses !== undefined) {
            const currentGroupCourses = allCourses.filter(c => currentGroup.courseIds?.includes(c.id));
            if (stickySelection !== undefined) {
                currentCourse = currentGroupCourses.find(c => c.id === stickySelection.courseId);
            }

            if (currentCourse === undefined) {
                const firstAssignedCourse = _.first(currentGroup.courseIds);
                currentCourse = currentGroupCourses.find(c => c.id === firstAssignedCourse);
            }

            dispatch(s => ({ ...s, course: currentCourse }));
        }

        if (currentCourse !== undefined) {
            const currentCourseId = currentCourse.id;

            //@ts-ignore
            dispatch(s => ({
                ...s,
                //@ts-ignore
                questions: currentCourse.chapters
                    .flatMap(m => m.modules.flatMap(m => m.blocks.flatMap(b => b.questions)))
                    .filter(x => x !== null),
            }));

            api.answers.query(null, null, currentCourseId).then(response => {
                dispatch(s => ({
                    ...s,
                    answers: response,
                }));
            });

            //@ts-ignore
            api.clientCourseUserOnboarding.getByUserIdAndCourseId(currentUser.id, currentCourseId).then(response => {
                dispatch(s => ({
                    ...s,
                    clientCourseUserOnboardingProgress: response,
                }));
            });

            const courseGroupAssignment = currentGroup?.courseAssignments?.find(ca => ca.courseId === currentCourseId);
            dispatch(s => ({
                ...s,
                courseGroupAssignment,
            }));
        }

        setIsLoading(false);
    }, [
        currentUser,
        allGroups,
        groupId,
        allCourses,
        stickySelection?.courseId,
        stickySelection?.groupId,
        currentCourseFromContext,
    ]);

    if (isLoading) {
        return <AppLoader loading />;
    }

    return <>{props.children}</>;
};
