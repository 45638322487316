import {User, UserRoles} from "@/api-client";
import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { createApi } from "../../../common/api";

/*  eslint-disable react-hooks/exhaustive-deps */

interface RoleBasedUserProps {
    roleName: UserRoles;
    fieldName: string;
    disabled?: boolean;
}

export function RoleBasedUser({ roleName, fieldName, disabled }: RoleBasedUserProps) {
    //@ts-ignore
    const [users, setUsers] = useState<User[]>(null);
    const api = createApi();

    useEffect(() => {
        api.users.query(null, null, null, null).then(result => {
            roleName !== null ? setUsers(result?.filter(r => r.roles?.includes(roleName))) : setUsers(result);
        });
    }, []);

    return (
        <Field as="select" name={fieldName} className="form-select" disabled={disabled}>
            <option key="0" value="">
                Please select {roleName === "Agent" ? "agent" : "expert"}
            </option>
            {users &&
                users.length > 0 &&
                users.map(u => (
                    <option key={u.id} value={u.id}>
                        {u.fullName}
                    </option>
                ))}
        </Field>
    );
}
