﻿import { createStyles, Slider as MantineSlider } from "@mantine/core";
import { Marks } from "./Questions/Components/Marks";
import React from "react";

const useStyles = createStyles((theme, { color, disabled }: { color: string; disabled: boolean }) => ({
    label: {
        backgroundColor: color,
        top: "-50px",
        minWidth: "40px",
        textAlign: "center",
        padding: "10px 0",
        fontSize: "14px",
    },
    mantineSliderBar: {
        backgroundColor: "#6c757d",
        left: "0 !important",
        "&:after": {
            content: "''",
            position: "absolute",
            backgroundColor: "#edeef3",
            right: "0",
            width: "8px",
            height: "2px",
        },
    },
    mantineSliderTrack: {
        "&:before": {
            backgroundColor: "#edeef3",
            left: "0",
            right: "0",
        },
        "&:after": {
            content: "''",
            position: "absolute",
            backgroundColor: "#fafafa",
            height: "2px",
            right: "-10px",
            width: "10px",
            zIndex: 1,
        },
        height: "2px",
        marginLeft: 0,
        marginRight: 0,
    },
    mark: {
        borderColor: "#bdbdbd",
        backgroundColor: "#bdbdbd",
        height: "2px",
        border: "none",
        width: "2px",
        transform: "translateX(-1px)",
    },
    markFilled: {
        borderColor: "#fff",
        backgroundColor: "#fff",
    },
    markLabel: {
        pointerEvents: disabled ? "none" : "auto",
    },
    thumb: {
        backgroundColor: color,
        borderColor: color,
        width: "10px",
        height: "10px",
        display: "flex",
    },
}));

type MaterialUiStyledSliderProps = {
    labelColor: string;
    value: number;
    min: number;
    max: number;
    onChange: (value: number) => void;
    disabled: boolean;
};

export const MaterialUiStyledSlider = (props: MaterialUiStyledSliderProps) => {
    const { classes } = useStyles({ color: props.labelColor, disabled: props.disabled });
    const marks = Marks(props.min, props.max);

    return (
        <MantineSlider
            value={props.value}
            disabled={props.disabled}
            labelAlwaysOn
            step={1}
            marks={marks}
            min={props.min}
            max={props.max}
            onChange={value => {
                props.onChange(value);
            }}
            aria-labelledby="discrete-slider-always"
            classNames={{
                label: classes.label,
                track: classes.mantineSliderTrack,
                bar: classes.mantineSliderBar,
                mark: classes.mark,
                markFilled: classes.markFilled,
                thumb: classes.thumb,
                markLabel: classes.markLabel,
            }}
        />
    );
};
