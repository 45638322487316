import { TrainingOverviewReport } from "@/api-client";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { createApi } from "../../../common/api";
import { useOrganisationContext } from "../../../common/OrganisationContext";
import { ContentLabel } from "../../../components/ContentLabel";
import { TrainingProgressCard } from "../../../components/Progress";
import { AppLoader } from "../../../components/Spinner";
import { trainingOverViewReportMapWithCourses } from "../../Planning/helper";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { createStyles } from "@mantine/core";
import { CardBlock } from "../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface TrainingOverViewState {
    result: TrainingOverviewReport[];
    isPending: boolean;
    error: string;
}

const useStyles = createStyles(() => ({ buttonContainer: { float: "right" } }));

const TrainingOverview = (props: { showButtons: boolean }) => {
    const { classes } = useStyles();
    const api = createApi();
    const history = useHistory();
    const courses = useOrganisationContext().courses;
    const [trainingOverview, setTrainingOverView] = useState<TrainingOverViewState>({
        /* @ts-ignore */
        result: null,
        isPending: false,
        /* @ts-ignore */
        error: null,
    });
    useEffect(() => {
        getTrainingOverviewReport();
    }, []);

    const getTrainingOverviewReport = () => {
        setTrainingOverView({ ...trainingOverview, isPending: true });
        api.trainingOverViewReport.trainingOverview().then(
            response => {
                setTrainingOverView({ ...trainingOverview, result: response, isPending: false });
            },
            _ => {
                setTrainingOverView({ ...trainingOverview, isPending: false });
            }
        );
    };

    const showGroupAndCourseTraining = (courseId: number) => {
        history.push(`/account-owner/planning/${courseId}`);
    };

    /* @ts-ignore */
    const getTrainingWithCourses = trainingOverViewReportMapWithCourses(courses, trainingOverview.result);
    const { t } = useTranslate();
    const getActualTrainingWithCourses =
        getTrainingWithCourses && getTrainingWithCourses.length > 0
            ? getTrainingWithCourses.filter(
                  training => training.name !== "" && training.name !== null && training.name !== undefined
              )
            : [];
    return (
        <CardBlock>
            <AppLoader loading={trainingOverview.isPending} className="position-absolute" />
            <ContentLabel text={t("ACCOUNTOWNER_TRAINING_OVERVIEW_HEADING")} />
            <table className="table table-borderless table-striped">
                <thead>
                    <tr className="border-bottom">
                        <th>{t("ACCOUNTOWNER_TRAINING_OVERVIEW_COURSE")}</th>
                        <th className="whitespace-nowrap">{t("ACCOUNTOWNER_TRAINING_OVERVIEW_ACTIVE_IN_TRAINING")}</th>
                        <th className="whitespace-nowrap">{t("ACCOUNTOWNER_TRAINING_OVERVIEW_COMPLETION_RATE")}</th>
                        <th className="whitespace-nowrap">{t("ACCOUNTOWNER_TRAINING_OVERVIEW_ASSIGNMENT_RATE")}</th>
                        <th className="whitespace-nowrap">{t("ACCOUNTOWNER_TRAINING_OVERVIEW_PROGRESS")}</th>
                        {props.showButtons && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {getActualTrainingWithCourses &&
                        getActualTrainingWithCourses.length > 0 &&
                        getActualTrainingWithCourses.map((report, i) => {
                            const completedPercentage =
                                report.courseCompletedByUsers !== 0
                                    ? (report.courseCompletedByUsers / report.totalUsersAssignedToCourse) * 100
                                    : 0;

                            const activeInTrainingPercentage =
                                report?.activeInTraining !== 0
                                    ? /* @ts-ignore */
                                      (report?.activeInTraining / report.totalUsersAssignedToCourse) * 100
                                    : 0;
                            const assignementRatePercentage =
                                report.totalUsersAssignedToCourse !== 0
                                    ? (report.totalUsersAssignedToCourse / report.totalUsersInOrganisation) * 100
                                    : 0;
                            return (
                                <tr key={`training-verview-report-${i}`}>
                                    <td className="whitespace-nowrap">{report.name}</td>
                                    <td>
                                        {activeInTrainingPercentage > 0 ? activeInTrainingPercentage.toFixed(0) : 0}% (
                                        {report?.activeInTraining}/{report.totalUsersAssignedToCourse})
                                    </td>
                                    <td>
                                        {completedPercentage > 0 ? completedPercentage.toFixed(0) : 0}% (
                                        {report.courseCompletedByUsers}/{report.totalUsersAssignedToCourse})
                                    </td>
                                    <td>
                                        {assignementRatePercentage > 0 ? assignementRatePercentage.toFixed(0) : 0}% (
                                        {report.totalUsersAssignedToCourse}/{report.totalUsersInOrganisation})
                                    </td>
                                    <td>
                                        <div className="progress-container">
                                            {report &&
                                                report.progress &&
                                                report.progress.length > 0 &&
                                                report.progress.map((p, i) => {
                                                    return (
                                                        <React.Fragment key={`progress-display-${i}`}>
                                                            <TrainingProgressCard progress={p} />
                                                            <span style={{ marginRight: "10px" }}></span>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    </td>
                                    {props.showButtons && (
                                        <td className="whitespace-nowrap">
                                            <div className={classes.buttonContainer}>
                                                <PrimaryButton
                                                    onClick={() => showGroupAndCourseTraining(report.courseId)}
                                                >
                                                    {t("ACCOUNTOWNER_MYORGANISATION_VIEW_DETAILS")}
                                                </PrimaryButton>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {getActualTrainingWithCourses &&
                getActualTrainingWithCourses.length === 0 &&
                trainingOverview.isPending === false && (
                    <p className="organisation-not-found text-center mb-5">
                        {t("ACCOUNTOWNER_TRAINING_OVERVIEW_NOT_FOUND")}
                    </p>
                )}
        </CardBlock>
    );
};
export default TrainingOverview;
