import { DemographicQuestion, DemographicUserData } from "@/api-client";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "@/common";
import { createApi } from "../common/api";
import {
    useDemographicData,
    useDemographicQuestions,
    useOrganisationContextDispatch,
} from "../common/OrganisationContext";
import { useCurrentUser } from "../common/user/context";
import { AppLoader } from "../components/Spinner";
import { DemographicQuestionsForm } from "./Components/demograhicQuestions";
import OnboardingLayout from "./Components/Layout";
import {
    combineDemographicQuestionsWithAnswers,
    hasValidationError,
    saveDemographicDataForUser,
    validateDemographicAnswers,
} from "./helper";
import "./style.scss";
import { PrimaryButton } from "../components/Button/PrimaryButton";
import { CardBlockWithButtonContainer } from "../training/Components/CardBlockWithButtonContainer";

export interface QuestionsWithAnswers {
    question: DemographicQuestion;
    answer: DemographicUserData;
    isValid?: boolean;
    error?: string;
}

export const DemographicQuestionsPage = () => {
    const organisationDispatch = useOrganisationContextDispatch();
    const demographicQuestions = useDemographicQuestions();
    const demographicData = useDemographicData();
    const { t } = useTranslate();
    const api = createApi();
    const user = useCurrentUser();
    const history = useHistory();

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [questionsWithAnswers, setQuestionsWithAnswers] = useState<QuestionsWithAnswers[]>();

    useEffect(() => {
        if (demographicQuestions && demographicData && user) {
            setQuestionsWithAnswers(
                /* @ts-ignore */
                combineDemographicQuestionsWithAnswers(user.id, demographicQuestions, demographicData)
            );
        }
    }, [demographicData, demographicQuestions, user]);

    const moveNext = () => {
        // Validate before post
        /* @ts-ignore */
        const validatedAnswers = validateDemographicAnswers(questionsWithAnswers);

        // Update state so errors can be shown
        setQuestionsWithAnswers(validatedAnswers);

        // Return if there are errors
        if (hasValidationError(validatedAnswers)) {
            return;
        }

        setIsSaving(true);

        // Save answers
        /* @ts-ignore */
        saveDemographicDataForUser(user.id, questionsWithAnswers, api.demographicAnswers).then(
            savedAnswers => {
                // Dispatch update (this can be automated by api client in the future)
                organisationDispatch(s => ({
                    ...s,
                    /* @ts-ignore */
                    demographicData: [...s.demographicData.filter(dd => dd.userId !== user.id), ...savedAnswers],
                }));

                setIsSaving(false);
                history.push("/");
            },
            _ => {
                setIsSaving(false);
                // TODO: Show error message
            }
        );
    };

    /* @ts-ignore */
    const isValid = useMemo(() => !hasValidationError(questionsWithAnswers), [questionsWithAnswers]);

    return (
        <OnboardingLayout>
            <CardBlockWithButtonContainer
                buttons={
                    <PrimaryButton
                        disabled={!isValid || isSaving}
                        onClick={() => moveNext()}
                        eventName={{ object: "demographicquestion", action: "save" }}
                    >
                        {t("COMMON_SAVE")}
                    </PrimaryButton>
                }
            >
                <>
                    <AppLoader loading={isSaving} />
                    <div className="demographic-questions-slide">
                        <Row>
                            <Col md={12} className="heading">
                                <p className="content-label">{t("ONBOARDING_DEMOGRAPHIC_HEADING")}</p>
                                <h2>{t("ONBOARDING_DEMOGRAPHIC_SUB_HEADING")}</h2>
                            </Col>
                        </Row>
                        <Row className="pt-4 pb-4">
                            <Col md={6} className={`demographic-questions demographic-questions-bg`}>
                                <DemographicQuestionsForm
                                    /* @ts-ignore */
                                    userId={user?.id}
                                    /* @ts-ignore */
                                    questionsWithAnswers={questionsWithAnswers}
                                    setQuestionsWithAnswers={setQuestionsWithAnswers}
                                />
                            </Col>
                            <Col md={6} sm={12} className="sub-heading mt-2">
                                <ReactMarkdown className="markdown-editor">
                                    {t("ONBOARDING_DEMOGRAPHIC_INFOTEXT")}
                                </ReactMarkdown>
                            </Col>
                        </Row>
                    </div>
                </>
            </CardBlockWithButtonContainer>
        </OnboardingLayout>
    );
};
