import React from "react";
import { useTranslate } from "@tolgee/react";

type DropDownData = {
    id?: number;
    name?: string;
};

interface Data extends DropDownData {
    children?: DropDownData[];
}

type DropDownProps = {
    data: Data[];
    placeholder: string;
    className?: string;
    defaultValue?: number | null;

    // QA: Should be named "onChange"
    getSelectedValue: (value: number) => void;
    noDepartmentOption?: boolean;
};

export const DropDown = ({
    data,
    placeholder,
    className,
    defaultValue,
    getSelectedValue,
    noDepartmentOption,
}: DropDownProps) => {
    const { t } = useTranslate();
    return (
        <select
            name="departments-dropdown"
            className={`${className} form-select`}
            onChange={e => getSelectedValue(parseInt(e.target.value))}
        >
            <option value="">{placeholder}</option>
            {noDepartmentOption && <option value="-1">{t("ACCOUNT_OWNER_WITHOUT_DEPARTMENT_FILTER")}</option>}
            <CreateOptions options={data} level={0} defaultValue={defaultValue} />
        </select>
    );
};

/* @ts-ignore */
const CreateOptions = ({ options, level, defaultValue }) => {
    /* @ts-ignore */
    const space = [];
    return (
        <React.Fragment>
            {
                /* @ts-ignore */
                options &&
                    /* @ts-ignore */
                    options.length > 0 &&
                    /* @ts-ignore */
                    options.map(d => {
                        [...Array(level)].forEach(m => {
                            space.push("\xA0");
                        });
                        return (
                            <React.Fragment key={d.id}>
                                <option value={d.id} selected={d.id === defaultValue}>
                                    {/* @ts-ignore */}
                                    {space}
                                    {d.name}
                                </option>
                                {d.children && d.children.length > 0 && (
                                    <CreateOptions options={d.children} level={level + 1} defaultValue={defaultValue} />
                                )}
                            </React.Fragment>
                        );
                    })
            }
        </React.Fragment>
    );
};
