import React from "react";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { AdditionalResource } from "@/api-client";
import { AdditionalResources } from "./AdditionalResources";

interface DescriptionProps {
    videoId: number | null;
    description: string;
    completionTime: string | null;
    additionalResources?: AdditionalResource[];
    currentModuleBlockId?: number;
}

export const ModuleBlockDescription = (props: DescriptionProps): JSX.Element => {
    const { videoId, description, completionTime } = props;
    const additionalResources = props.additionalResources ?? [];
    const { t } = useTranslate();
    const convertHtoM = (timeInHour: string) => {
        const timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    };
    const minutes = completionTime != null ? convertHtoM(completionTime) : "";
    return (
        <div className={videoId ? "col-12 col-md-4" : "col-12 col-md-12"}>
            <div className="block-content">
                {minutes && (
                    <div className="completion-time mb-3">
                        <i className="clock-color far fa-clock" /> {t("TRAINING_TIME_TO_COMPLETE", { minutes })}
                    </div>
                )}
                <div className="mediumFont">
                    <ReactMarkdown className="markdown-editor">{description}</ReactMarkdown>
                </div>

                <AdditionalResources
                    resources={additionalResources?.filter(
                        a =>
                            a.moduleBlockId === props.currentModuleBlockId &&
                            !["Inactive", "Deleted", "Disabled"].includes(a.status)
                    )}
                />
            </div>
        </div>
    );
};
