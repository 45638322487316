import { TFunction } from "@/common";
import { UserStatuses as UserStatusesT } from "@/api-client";

export const translateInviteStatus = (t: TFunction) => {
    return {
        NotSent: t("INVITE_STATUS.NotSent"),
        Queued: t("INVITE_STATUS.Queued"),
        Sent: t("INVITE_STATUS.Sent"),
        NotDelivered: t("INVITE_STATUS.NotDelivered"),
        Opened: t("INVITE_STATUS.Opened"),
        Accepted: t("INVITE_STATUS.Accepted"),
        Active: t("INVITE_STATUS.Active"),
        Inactive: t("INVITE_STATUS.Inactive"),
        InProgress: t("INVITE_STATUS.InProgress"),
        Deleted: t("INVITE_STATUS.Deleted"),
        Invited: t("INVITE_STATUS.Invited"),
        Disabled: t("INVITE_STATUS.Disabled"),
        Template: t("INVITE_STATUS.Template"),
        All: t("INVITE_STATUS.All"),
    };
};

export const translateTeamStatus = (t: TFunction) => {
    return {
        Active: t("TEAM_STATUS.Active"),
        Inactive: t("TEAM_STATUS.Inactive"),
        Deleted: t("TEAM_STATUS.Deleted"),
    };
};

export const AllClientStatuses = (t: TFunction) => [
    // @ts-ignore
    // { id: 1, label: t('INVITE_STATUS', { returnObjects: true })['All'], value: 'All', checked: true },
    { id: 1, label: translateInviteStatus(t)["Active"], value: "Active", checked: true },
    // @ts-ignore

    { id: 2, label: translateInviteStatus(t)["Inactive"], value: "Inactive", checked: false },
    // @ts-ignore

    { id: 3, label: translateInviteStatus(t)["Deleted"], value: "Deleted", checked: false },
    // @ts-ignore

    { id: 4, label: translateInviteStatus(t)["Invited"], value: "Invited", checked: false },
];

export const VideosStatuses = (t: TFunction) => [
    // @ts-ignore
    { id: 1, label: translateInviteStatus(t)["Active"], value: "Active", checked: true },
    // @ts-ignore
    { id: 2, label: translateInviteStatus(t)["Inactive"], value: "Inactive", checked: false },
    // @ts-ignore
    { id: 4, label: translateInviteStatus(t)["Deleted"], value: "Deleted", checked: false },
];

export const CoursesStatuses = (t: TFunction) => [
    // @ts-ignore

    { id: 1, label: translateInviteStatus(t)["Active"], value: "Active", checked: true },
    // @ts-ignore

    { id: 3, label: translateInviteStatus(t)["InProgress"], value: "InProgress", checked: false },
    // @ts-ignore

    { id: 4, label: translateInviteStatus(t)["Deleted"], value: "Deleted", checked: false },
    // @ts-ignore

    { id: 6, label: translateInviteStatus(t)["Disabled"], value: "Disabled", checked: false },
    // @ts-ignore

    { id: 7, label: translateInviteStatus(t)["Template"], value: "Template", checked: false },
    // @ts-ignore
];

export const UserStatuses = (t: TFunction): { id: number; label: string; value: UserStatusesT; checked: boolean }[] => {
    // @ts-ignore
    const { Active, Deleted, Inactive } = translateInviteStatus(t);

    return [
        { id: 1, label: Active as string, value: "Active", checked: true },
        { id: 2, label: Inactive as string, value: "Inactive", checked: false },
        { id: 4, label: Deleted as string, value: "Deleted", checked: false },
    ];
};

export const InvitationStatuses = (t: TFunction): { id: number; label: string; value: boolean; checked: boolean }[] => [
    { id: 1, label: t("COMMON_YES"), value: true, checked: true },
    { id: 2, label: t("COMMON_NO"), value: false, checked: false },
];
// @ts-ignore

export const RoleOptions = (): { id; label; value; checked }[] => [
    { id: 1, label: "Unassigned", value: "Unassigned", checked: true },
    { id: 2, label: "User", value: "User", checked: true },
    { id: 3, label: "AccountOwner", value: "AccountOwner", checked: true },
    { id: 4, label: "Admin", value: "Admin", checked: true },
    { id: 5, label: "GroupLeader", value: "GroupLeader", checked: true },
    { id: 6, label: "Agent", value: "Agent", checked: true },
    { id: 7, label: "Expert", value: "Expert", checked: true },
];
