﻿import { useTranslate } from "@tolgee/react";
import { Button, createStyles, Flex, Menu, ScrollArea } from "@mantine/core";
import { CategoryItem, CategoryMenuItem, DefaultCategoryItem } from "./CategoryMenuItem";
import { Icons } from "../../../../common/Icons";

const useStyles = createStyles(theme => ({
    filterCategoriesButton: {
        borderLeft: 0,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
        borderColor: "#ced4da", //TODO: Use same borders as button group,
        color: "black",
        "&:hover": {
            backgroundColor: "#f8f9fa",
        },
    },

    itemLabel: {
        cursor: "pointer",
    },
}));

type MenuProps = {
    categories: CategoryItem[];
    defaultItem: DefaultCategoryItem;
    onAddCategory: () => void;
};

export const CategoryMenu = (props: MenuProps): JSX.Element => {
    const { t } = useTranslate();
    const { classes } = useStyles();

    return (
        <Menu width={400} shadow="md" classNames={{ itemLabel: classes.itemLabel }}>
            <Menu.Target>
                <Button leftIcon={<Icons.FilterList />} className={classes.filterCategoriesButton}>
                    {t("BOARDS_CATEGORY_MENU_LABEL")}
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <ScrollArea.Autosize mah={300} type={"auto"}>
                    <Flex p="sm" direction="column" rowGap="xs">
                        <CategoryMenuItem item={props.defaultItem} />
                        {props.categories.map(item => (
                            <CategoryMenuItem key={item.id} item={item} />
                        ))}
                    </Flex>
                </ScrollArea.Autosize>
                <Menu.Divider />
                <Menu.Item icon={<Icons.Add />} onClick={props.onAddCategory}>
                    {t("BOARDS_CATEGORY_ADD_NEW")}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
