import { useMemo } from "react";
import { Client, User } from "@/api-client";
import { useAuth } from "../../auth/useAuth";

type UserContextState = {
    user: User;
    client: Client | null;
};

export const useUserContext = (): UserContextState => {
    const authContext = useAuth();

    if (authContext.user === null) {
        throw new Error("useUserContext can only be used inside of an authenticated context/ProtectedRoute");
    }

    return useMemo(
        () => ({
            user: authContext.user!,
            client: authContext.client,
        }),
        [authContext.user, authContext.client]
    );
};

export const useCurrentUser = (): User => {
    const context = useUserContext();
    return context.user;
};

export function useCurrentClient(require?: true): Client;
export function useCurrentClient(require: false): Client | null;

export function useCurrentClient(): Client | null {
    const context = useUserContext();
    return context.client as Client;
}

export type FeatureToggle = "";

export const useFeatureToggle = (feature: FeatureToggle) => {
    const currentClient = useCurrentClient();
    return currentClient.tags.find(x => x.trim() === feature.toLocaleLowerCase()) !== undefined;
};
