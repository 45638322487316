﻿import { useTranslate } from "@tolgee/react";
import { Button, createStyles, Flex } from "@mantine/core";
import { Icons } from "../../common/Icons";
import { Link } from "react-router-dom";
import React from "react";

const useStyles = createStyles(theme => ({
    rightIcon: {
        color: theme.colors.dark,
    },
    root: {
        color: theme.colors.dark,
    },

    link: {
        textDecoration: "none",
        width: "100%",
    },
}));

export const HomeLinkButton = (props: { textKey: string; link: string }) => {
    const { t } = useTranslate();
    const { classes } = useStyles();

    return (
        <Flex>
            <Link to={props.link} className={classes.link}>
                <Button
                    classNames={{ rightIcon: classes.rightIcon, root: classes.root }}
                    color={"gray"}
                    variant={"subtle"}
                    rightIcon={<Icons.RightArrow />}
                    radius={"xl"}
                    size={"xs"}
                >
                    {t(props.textKey)}
                </Button>
            </Link>
        </Flex>
    );
};
