export const evenOddClassName = (ix: number) => (ix % 2 === 0 ? "even" : "odd");

export function ColorScaleFromValue(value: number) {
    // Adjust number from min/max to percentage
    const max = 10;
    const min = 1;

    return getColorScaleFromPercentage(((value - min) / max) * 100);
}

/**
 * Calculate a red to green scale from 0-100%
 * @param perc
 * @returns
 */
// @ts-ignore

export function getColorScaleFromPercentage(perc) {
    // eslint-disable-next-line prefer-const
    let r,
        g,
        b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
}

//eslint-disable-next-line no-useless-escape
export const EmailFormat =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string) => {
    const result = String(email).toLowerCase().match(EmailFormat);
    if (result === null) {
        return false;
    }
    return true;
};

export const validateMobile = (phone: string) => {
    const phoneNumberToString: string = phone.toString();
    if (phoneNumberToString.length > 10) {
        return true;
    }
    return false;
};
