﻿import { ReactNode } from "react";
import { Card, createStyles } from "@mantine/core";
import { MantineStyleSystemProps } from "@mantine/styles/lib/theme/types/MantineStyleSystem";

export const radius = "md";
export const shadow = "xs";

const cardBlockStyles = createStyles(() => ({ card: { overflow: "visible" } }));
export const CardBlock = ({
    children,
    classNames,
    ...rest
}: {
    children: ReactNode;
    classNames?: { card: string };
} & MantineStyleSystemProps) => {
    const { classes } = cardBlockStyles();
    return (
        <Card
            className={`${classes.card} ${classNames?.card ?? ""}`}
            radius="md"
            shadow="xs"
            p="lg"
            withBorder
            {...rest}
        >
            {children}
        </Card>
    );
};
