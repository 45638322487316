import React from "react";
import { Course, Group, GroupType, User } from "@/api-client";
//import { CourseGroup } from './helper';

// QA: This is specfic to course planning. "Resources" should be CoursePlanning

export interface ResourcesContextProps {
    template?: string;
    courses?: Course[];
    coursePending?: boolean;
    groupTypes?: GroupType[];
    /**
     * All groups in the currently selected course
     */
    allGroups?: Group[];
    users?: User[] | null;

    courseId?: number;
}

const ResourcesContext = React.createContext<ResourcesContextProps>({});

export default ResourcesContext;
