import React, { Fragment, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import useDebounce from "../UseDebounce/UseDebounce";
import { QuestionTemplateProps } from "./models/QuestionTemplateProps";
import { cloneUpdateObjectArray } from "../../helpers/javascript";
import { Answer, Question, User } from "@/api-client";
import { useUserContext } from "../../common/user/context";
import "./scss/Questions.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

/**
 *
 * General idea here:
 *
 * Create a model for this question type just as MatrixGridAnswer
 * Generate an answer for each option so you can connect it to the form
 * Before sending form, filter out the answers that have empty values. (That should be the responsibility of this component)
 *
 */

export interface FreeTextAnswer {
    answer: Answer;
    isValid?: boolean;
    errors?: string;
    needValidation?: boolean;
    hasBeenValidated?: boolean;
}

export const getInitValues = (question: Question, existingAnswers: Answer[], user: User): FreeTextAnswer[] => {
    /* @ts-ignore */
    return [...Array(question.maxAnswer).keys()].map(index => {
        // Take the first answers by corresponding index to populate with since they don't have correlation id per row
        const existingAnswer = index < existingAnswers.length ? existingAnswers[index] : undefined;

        const answer = {
            id: existingAnswer && existingAnswer.id ? existingAnswer.id : undefined,
            text: "",
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            userId: user.id,
            ...existingAnswer,
        };

        return {
            answer: answer,
            hasBeenValidated: false,
        };
    });
};

interface FreeTextAnswerProps extends QuestionTemplateProps {
    index?: number;
    currentProgress?: boolean | null;
    placeholder?: string;
    error: string[] | undefined;
}

export const FreeTextAnswers = React.memo(function FreeTextAnswers(props: FreeTextAnswerProps) {
    const { t } = useTranslate();
    const { currentProgress, placeholder, index } = props;
    const question = props.question;
    const userContext = useUserContext();
    const [answers, setAnswers] = useState<FreeTextAnswer[]>();

    const [errors, setErrors] = useState<string[]>();
    useEffect(() => {
        if (question && userContext.user && props.answers) {
            const initValues = getInitValues(question, props.answers, userContext.user);
            setAnswers(initValues);
        }
    }, []);
    //Added more delay
    const answersStateChange = useDebounce(answers, 500);

    useEffect(() => {
        if (answers && question) {
            // If all fields have undefined validation no field is changed, don't trigger validations
            /* @ts-ignore */
            if (question.minAnswer > 0 && answers.filter(x => x.isValid === undefined).length === answers.length) {
                props.setValidation(false, false);
                return;
            }

            const nonEmptyAnswers = answers.filter(x => x.answer.text && x.answer.text.length > 0);
            let isValid = true;
            // Question-level validations like how many answers are provided etc
            /* @ts-ignore */
            if (question.minAnswer > nonEmptyAnswers.length) {
                // To few questions answered
                setErrors([t("MODULE_TEXT_FIELD_DESCRIPTION", { min: question.minAnswer, max: question.maxAnswer })]);
                isValid = false;
                props.setValidation(false, false);
            } else {
                props.setValidation(true, true);
                // Clear errors
                setErrors([]);
            }

            // See if any answers is marked as not valid
            if (nonEmptyAnswers.filter(x => !x.isValid).length > 0) {
                isValid = false;
            }

            // Update parent form
            props.setValidation(isValid, true);
            props.setAnswers(nonEmptyAnswers.map(x => x.answer));
        }
    }, [answersStateChange]);

    const handleChange = (answer: FreeTextAnswer, text: string): void => {
        setAnswers(prevState => {
            if (!prevState) {
                return;
            }

            return cloneUpdateObjectArray<FreeTextAnswer>(prevState, answer, a => {
                // Use a scoped variable to detect when isValid changes to true
                let isValid = true;

                a.answer.text = text;

                // Validate answer
                /* @ts-ignore */
                if (text.length > question.charLimitAnswer) {
                    isValid = false;
                    a.errors = t("MODULE_ANSWER_MAX_LENGTH", { min_length: 5, max_length: question.charLimitAnswer });
                }

                if (text.length < 5 && text.length > 0) {
                    isValid = false;
                }

                if (answer.isValid) {
                    a.errors = undefined;
                }

                a.isValid = isValid;

                return a;
            });
        });
    };
    /* @ts-ignore */
    const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
        if (relatedTarget === null) {
            return false;
        }

        var node = relatedTarget.parentNode;

        while (node !== null) {
            if (node === currentTarget) {
                return true;
            }
            node = node.parentNode;
        }

        return false;
    };
    /* @ts-ignore */
    const handleOnMouseLeave = (answer, e: React.FocusEvent<HTMLTextAreaElement>) => {
        if (!focusInCurrentTarget(e)) {
            const value = e.target.value;
            setAnswers(prevState => {
                if (!prevState) {
                    return;
                }

                return cloneUpdateObjectArray<FreeTextAnswer>(prevState, answer, a => {
                    // Use a scoped variable to detect when isValid changes to true
                    let isValid = true;

                    a.answer.text = value;

                    if (value && value.length < 5 && value.length > 0) {
                        isValid = false;
                        a.errors = t("MODULE_ANSWER_MIN_LENGTH");
                    }

                    if (answer.isValid) {
                        a.errors = undefined;
                    }

                    a.isValid = isValid;

                    return a;
                });
            });
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-md-4">
                <div className="block-content-text">
                    <div className="moduleHeader">{placeholder}</div>
                    {question && (
                        <React.Fragment>
                            <p>{question.text}</p>
                            <p>{question.description}</p>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="col-12 col-md-8 text-secondary">
                {question && (
                    <div className="block-content-option">
                        <p className="question_type">
                            {question && question.minAnswer === question.maxAnswer
                                ? t("MODULE_TEXT_FIELD_DESCRIPTION_SAME", {
                                      max: question.maxAnswer,
                                  })
                                : t("MODULE_TEXT_FIELD_DESCRIPTION", {
                                      min: question.minAnswer === 0 ? 1 : question.minAnswer,
                                      max: question.maxAnswer,
                                  })}
                        </p>
                        {answers &&
                            answers.map((answer, ix) => {
                                return (
                                    <Fragment key={`question_${ix}_${question.id}`}>
                                        <InputGroup
                                            id={`question-${index}-${question.questionTypeId}-questions`}
                                            className="mb-3"
                                        >
                                            <FormControl
                                                className="border border-info "
                                                as="textarea"
                                                name={`questions.question-${props.index}-${question.questionTypeId}[${ix}].text`}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                                    handleChange(answer, e.target.value)
                                                }
                                                onBlur={(e: any) => handleOnMouseLeave(answer, e)}
                                                value={answer.answer.text}
                                                minLength={5}
                                                autoComplete="off"
                                                //maxLength={question.char_limit_answer}
                                                disabled={currentProgress ? true : false}
                                                readOnly={currentProgress ? true : false}
                                                id={`questions.question-${index}-${question.questionTypeId}[${ix}].text`}
                                            />
                                        </InputGroup>
                                        {!answer.isValid && (
                                            <p className="error learfix w-100 text-danger">{answer.errors}</p>
                                        )}
                                    </Fragment>
                                );
                            })}
                        {((errors && errors.length === 0) || errors === undefined) && props.error && (
                            <p className="error learfix w-100 text-danger">
                                {t("MODULE_TEXT_FIELD_DESCRIPTION", {
                                    min: question.minAnswer,
                                    max: question.maxAnswer,
                                })}
                            </p>
                        )}
                        {errors &&
                            errors.map(error => (
                                <div key={index} className="clearfix w-100 text-danger">
                                    {error}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
});
