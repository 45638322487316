import { useParams } from "react-router-dom";
import { ProgramChild } from "./Program/ProgramChild";
import { Transformation } from "../transformation/Transformation";

export const TransformationPage = () => {
    const params = useParams();

    return (
        <ProgramChild
            //@ts-ignore
            teamId={params.groupId}
            breadCrumbTitleKey={"PROGRAM_ASSESSMENT"}
        >
            <Transformation
                //@ts-ignore

                moduleId={params.moduleId === undefined ? undefined : parseInt(params.moduleId)}
            />
        </ProgramChild>
    );
};
