import React from "react";
import { Col, ListGroup } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { ProfileImageEditor } from "./ProfileImageEditor";
import { User } from "@/api-client";
import { CardBlock } from "../../../components/CardBlock";

interface Props {
    userProfile: User;
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

export const ProfileSideMenu = (props: Props) => {
    const { t } = useTranslate();

    const imageUpdatedCallback = (newImageUrl: string) => {
        // Update user profile in state
        props.userProfile.image = newImageUrl;
        //dispatch(setUser(props.userProfile));
    };

    return (
        <Col className="mt-4 mt-lg-0" lg={4}>
            <CardBlock>
                <ProfileImageEditor userProfile={props.userProfile} imageUpdatedCallback={imageUpdatedCallback} />

                <h4 className="text-dark">{props.userProfile?.firstName + " " + props.userProfile?.lastName}</h4>
                <p className="text-secondary mb-5">{props.userProfile?.email}</p>

                <ListGroup className="border-0 my-2">
                    <ListGroup.Item className="border-0 p-0">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={e => props.setActiveTab("profile")}
                            className={(props.activeTab === "profile" ? "active_form" : "") + " ps-2 py-3"}
                        >
                            {t("PROFILE_MENU_PROFILE")}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={e => props.setActiveTab("password")}
                            className={(props.activeTab === "password" ? "active_form" : "") + " ps-2 py-3"}
                        >
                            {t("PROFILE_MENU_PASSWORD")}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </CardBlock>
        </Col>
    );
};
