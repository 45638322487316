import React, { ChangeEvent } from "react";
import "./styles.scss";

interface StyledCheckboxProps {
    label?: string;
    className?: string;
    checked?: boolean;
    onChange?: (checked: boolean, e?: ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    disabled?: boolean;
}

const StyledCheckbox = (props: StyledCheckboxProps): JSX.Element => {
    return (
        <label className={`control control-checkbox ${props.disabled ? "disabled" : null}`}>
            <input
                id={props.label}
                name={props.name}
                type="checkbox"
                checked={props.checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    // @ts-ignore

                    props.onChange(e.target.checked, e)
                }
                disabled={props.disabled}
            />
            <div className={`control_indicator ${props.className}`} />
            <label className="styled-label" htmlFor={props.label}>
                {props.label}
            </label>
        </label>
    );
};

StyledCheckbox.defaultProps = {
    onChange: () => {},
};

export default StyledCheckbox;
