import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { TFunction } from "@/common";
import { FullLogo } from "../../assets/index";
import { createApi } from "../../common/api";
import { showToast } from "../../components/Notify";
import RenderIf from "../../components/render-if/render-if";
import { AppLoader, PrimaryButton } from "@/components";
import { LoginContainer } from "../../login/LoginContainer";
import "./style.scss";
import { SUPPORT_EMAIL } from "../../constants";
import { customEvents } from "../../analytics/customEvents/customEvents";

const ResetPasswordSchema = (t: TFunction) => {
    return Yup.object().shape({
        email: Yup.string().email(t("LOGIN_EMAIL_VALID")).required(t("LOGIN_EMAIL_REQUIRED")),
    });
};

interface SubmitFormValues {
    email: string;
}

type ResetPasswordEmailSentProps = {
    email: string;
};

const ResetPasswordEmailSent: (props: ResetPasswordEmailSentProps) => JSX.Element = (
    props: ResetPasswordEmailSentProps
): JSX.Element => {
    const { t } = useTranslate();

    return (
        <div className="reset-password-container">
            <div className="pl-3 pr-3 pt-2 text-center emailIcon">
                <i className="fas fa-envelope-open" />
            </div>
            <div className="pl-3 pr-3 pt-2">
                <h5 className="text-left mt-2 text-center">{t("FORGOT_PASSWORD_EMAIL_SENT_TITLE")}</h5>
                <div className="text-left mt-4 mb-4 text-center">
                    {t("FORGOT_PASSWORD_EMAIL_SENT_BODY", { email: props.email })}
                </div>
                <div className="text-left mt-4 mb-4 text-center">{t("FORGOT_PASSWORD_EMAIL_CHECK_JUNK")}</div>
                <div className="pl-3 pr-3 pt-3 text-center">
                    <a href={`mailto:${SUPPORT_EMAIL}`}>
                        <PrimaryButton eventName={{ object: "resetpassword", action: "contactsupport" }} type="button">
                            {t("REGISTRATION_CONTACT_SUPPORT")}
                        </PrimaryButton>
                    </a>
                </div>
            </div>
        </div>
    );
};

const ResetPassword = (): JSX.Element => {
    const { t } = useTranslate();
    const api = createApi();
    const LoginLogo: string = FullLogo;
    const [isPending, setIsPending] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const submitLoginForm = async (values: SubmitFormValues) => {
        setIsPending(true);
        setEmail(values.email);

        api.login.requestPassword({ email: values.email }).then(
            () => {
                setEmailSent(true);
            },
            reason => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                customEvents.raiseResetPasswordError(reason, values.email);
                showToast(t("COMMON_DEFAULT_ERROR"), "error");
            }
        );
        setIsPending(false);
    };

    return (
        <>
            <AppLoader loading={isPending} />
            <LoginContainer>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <img src={LoginLogo} className="main-login-logo" alt="Hups" />
                    </div>
                    <div className="d-flex justify-content-center h-100">
                        <RenderIf show={emailSent}>
                            <ResetPasswordEmailSent email={email} />
                        </RenderIf>
                        <RenderIf show={!emailSent}>
                            <div className="reset-password-container">
                                <div className="pl-3 pr-3 pt-3">
                                    <h5 className="text-center">{t("FORGOT_PASSWORD_MAIN_TITLE")}</h5>
                                    <p className="mt-2 text-left">{t("FORGOT_PASSWORD_TITLE")}</p>
                                </div>

                                <Formik
                                    initialValues={{ email: "" }}
                                    validationSchema={ResetPasswordSchema(t)}
                                    onSubmit={submitLoginForm}
                                >
                                    {(
                                        props: FormikProps<{
                                            email: string;
                                        }>
                                    ) => {
                                        const { errors, touched } = props;
                                        return (
                                            <Form className="text-center pl-3 pr-3 pt-1" autoComplete="off">
                                                <div className="reset-password mb-3">
                                                    <label htmlFor="email" className="form-label">
                                                        {t("REGISTRATION_EMAIL")}
                                                    </label>
                                                    <Field
                                                        autoComplete="off"
                                                        type="text"
                                                        name="email"
                                                        placeholder={t("REGISTRATION_EMAIL")}
                                                        className={`form-control${
                                                            errors.email && touched.email ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="email"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="reset-password-button mt-5">
                                                    <PrimaryButton
                                                        type="submit"
                                                        disabled={errors.email !== undefined}
                                                        loading={isPending}
                                                        eventName={{ object: "resetpassword", action: "save" }}
                                                    >
                                                        {t("FORGOT_PASSWORD_MAIN_TITLE")}
                                                    </PrimaryButton>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </RenderIf>
                    </div>
                </div>
            </LoginContainer>
        </>
    );
};

export default ResetPassword;
