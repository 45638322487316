import { Course } from "@/api-client";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSysadminCourse, useSysAdminCourses, useUpdateSysadminCourse } from "../SysadminContext";

/* eslint-disable react-hooks/exhaustive-deps */

type CourseParams = {
    courseId: string;
};

export const useCurrentCourseFromParams = (): [Course, (callback: (course: Course) => Course) => void] => {
    const params = useParams<CourseParams>();
    const courses = useSysAdminCourses();
    //@ts-ignore
    const sysadminCourse = useSysadminCourse(parseInt(params.courseId));
    return useMemo(() => sysadminCourse, [params.courseId, courses]);
};

export const useUpdateCurrentCourseFromParams = (): ((callback: (course: Course) => Course) => void) => {
    const params = useParams<CourseParams>();
    const courses = useSysAdminCourses();
    //@ts-ignore
    const sysadminCourse = useUpdateSysadminCourse(parseInt(params.courseId));
    return useMemo(() => sysadminCourse, [params.courseId, courses]);
};
