import { getAppInsights } from "../AppInsights";
import { trackNamedPosthogEvent } from "./trackPosthogEvent";

export const videoError = (error: Error, id: number, url: string, warning = false) => {
    const appInsights = getAppInsights();
    const userAgent = navigator.userAgent;
    const data = {
        errorMessage: error.message,
        errorStack: error.stack,
        errorName: error.name,
        userAgent: userAgent,
        id: id,
        url: url,
        warning: warning,
    };
    appInsights.trackEvent({ name: "app:videoError" }, { data });
    appInsights.trackException({ exception: error });
    trackNamedPosthogEvent("app:videoError", { data });
};
