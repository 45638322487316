import { FocusEventHandler, useState, forwardRef } from "react";
import { MultiSelect, Badge, SelectItem, MultiSelectValueProps, ActionIcon, Group } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { modalZIndex } from "../../../../CommonModalProps";
import { Icons, isEmail, isPhoneNumber } from "@/common";
import { exhaustiveCheck } from "../../../../common/exhaustiveCheck";
import { UsernameType } from "../../../../api/clients/UsernameDto";

const splitAll = (separators: string[], value: string) => {
    const group = [",", ";", "\n", "\r", "\\s"].join("");
    const pattern = new RegExp(`[${group}]+`, "g");
    return value.split(pattern);
};

type ParseResult = {
    isValid: boolean;
    value: string;
    type: InputType;
};

export type InputType = UsernameType | "invalid";

const parseUserName = (value: string): ParseResult => {
    const getType = () => {
        if (isEmail(value)) {
            return "email";
        }

        if (isPhoneNumber(value)) {
            return "mobilePhone";
        }

        return "invalid";
    };

    const type = getType();

    return {
        isValid: type !== "invalid",
        value: value,
        type: type,
    };
};

const parse = (input: string): ParseResult[] => {
    const separators = [",", ";", "\n", "\r", "\\s"];

    return splitAll(separators, input)
        .map(value => value.trim())
        .filter(value => value.length > 0)
        .map(value => parseUserName(value))
        .orderBy(x => x.value);
};

const RemoveButton = (props: { onClick: () => void }) => (
    <ActionIcon onClick={props.onClick} size="xs" variant="subtle">
        <IconX />
    </ActionIcon>
);

const UserNameBadge = (props: {
    value: string;
    type: InputType;
    isValid?: boolean;
    isExisting?: boolean;
    onRemove?: () => void;
}) => {
    const getColor = () => {
        if (props.isExisting) {
            return "green";
        }

        if (!(props.isValid === undefined || props.isValid)) {
            return "red";
        }

        return undefined;
    };

    const getIcon = () => {
        switch (props.type) {
            case "email":
                return <Icons.At size={16} />;
            case "mobilePhone":
                return <Icons.Phone size={16} />;
            case "invalid":
                return undefined;
            default:
                return exhaustiveCheck(props.type);
        }
    };

    return (
        <Badge
            color={getColor()}
            leftSection={getIcon()}
            rightSection={props.onRemove ? <RemoveButton onClick={props.onRemove} /> : undefined}
        >
            {props.value}
        </Badge>
    );
};

const CreateLabel = (value: string) => {
    const parsed = parse(value);
    return (
        <Group>
            <Icons.Add />
            {parsed.map(v => {
                return <UserNameBadge key={v.value} value={v.value} type={v.type} />;
            })}
        </Group>
    );
};

const Value = ({
    value,
    isValid,
    isExisting,
    type,
    label,
    onRemove,
    classNames,
    ...others
}: MultiSelectValueProps & {
    value: string;
    isValid: boolean;
    isExisting: boolean;
    type: InputType;
}) => {
    return (
        <div {...others}>
            <UserNameBadge value={value} isValid={isValid} type={type} onRemove={onRemove} isExisting={isExisting} />
        </div>
    );
};

const Data = forwardRef<
    HTMLDivElement,
    MultiSelectValueProps & {
        value: string;
        isValid: boolean;
        isExisting: boolean;
        type: InputType;
    }
>(({ value, isValid, isExisting, type, label, onRemove, classNames, ...others }, ref) => {
    return (
        <div ref={ref} {...others}>
            <UserNameBadge value={value} isValid={isValid} type={type} onRemove={onRemove} isExisting={isExisting} />
        </div>
    );
});

type Item = SelectItem & { isValid: boolean; isExisting: boolean; type: InputType };

type AddUserMultiSelectState = {
    items: Item[];
    value: string[];
};

type AddUserMultiSelectProps = {
    value: string[];
    onChange: (value: string[]) => void;
    error?: string;
    placeholder: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    name?: string;
    label: string;
    data: Item[];
    showSuggestions?: boolean;
};

export const AddUserMultiSelect = (props: AddUserMultiSelectProps) => {
    const [state, setState] = useState<AddUserMultiSelectState>({
        items: props.showSuggestions === true ? props.data : [],
        value: props.value,
    });

    if (state.value !== props.value) {
        props.onChange(state.value);
    }

    return (
        <MultiSelect
            withinPortal
            creatable
            searchable
            limit={5}
            zIndex={modalZIndex + 1}
            label={props.label}
            placeholder={props.placeholder}
            data={state.items}
            value={state.value}
            onBlur={props.onBlur}
            name={props.name}
            onChange={value =>
                setState(state => ({
                    ...state,
                    value: value,
                }))
            }
            error={props.error}
            valueComponent={Value}
            itemComponent={Data}
            getCreateLabel={CreateLabel}
            onCreate={x => {
                const values = parse(x);

                setState(state => {
                    const items = [
                        ...state.items,
                        ...values
                            .filter(v => !state.items.any(d => d.value === v.value))
                            .map(x => ({
                                value: x.value,
                                isValid: x.isValid,
                                label: x.value,
                                isExisting: props.data.any(a => a.value === x.value),
                                type: x.type,
                            })),
                    ];

                    const value = [
                        ...state.value,
                        ...values.filter(v => !state.items.any(d => d.value === v.value)).map(x => x.value),
                    ];

                    return {
                        items: items,
                        value: value,
                    };
                });
                return undefined;
            }}
        />
    );
};
