import { TasksStatus } from "@/api-client";
import { trackAppInsightsEvent } from "./trackAppInsightsEvent";
import { trackPosthogEvent } from "./trackPosthogEvent";

type CreatedFrom =
    | "NavBar"
    | "FreeTextAnswer"
    | "Board.Inbox"
    | "Board.Todo"
    | "Board.InProgress"
    | "Board.Done"
    | "Board.Completed"
    | "Board.Archived";

export const convertFromColumn = (val: TasksStatus): CreatedFrom => {
    switch (val) {
        case "New":
            return "Board.Inbox";
        case "Todo":
            return "Board.Todo";
        case "Active":
            return "Board.InProgress";
        case "Completed":
            return "Board.Completed";
        case "Archived":
            return "Board.Archived";
    }
};

export const uiImprovementCreated = (createdFrom: CreatedFrom) => {
    trackAppInsightsEvent("ui", "improvement", "createdfrom", { from: createdFrom });
    trackPosthogEvent("ui", "improvement", "createdfrom", { from: createdFrom });
};
