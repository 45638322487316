﻿import { Card, createStyles } from "@mantine/core";
import { WithChildren } from "../../common/StateWrapper";

const useStyles = createStyles(theme => ({
    card: {
        transition: "box-shadow 150ms ease, transform 100ms ease",
        cursor: "pointer",
        "&:hover": {
            boxShadow: theme.shadows.md,
            transform: "scale(1.05)",
        },
        display: "flex",
        flexDirection: "column",
    },
}));

type ProgramCardContainerProps = { onClick?: () => void } & WithChildren;

export const ProgramCardContainer = (props: ProgramCardContainerProps) => {
    const { classes } = useStyles();

    return (
        <Card shadow="sm" padding="lg" radius="md" className={classes.card} withBorder onClick={props.onClick}>
            {props.children}
        </Card>
    );
};
