import { Course } from "@/api-client";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { useHistory } from "@/common";
import { TrainingRouteParams } from "..";
import { useCurrentCourse } from "../../common/courses/context";
import MainContent from "../../components/MainContent";
import { Notify } from "../../components/Notify";
import { TrainingSidebar } from "../Components/Sidebar";
import { ModuleBlockDescription } from "../ContentModuleBlocks/ModuleBlockDescription";
import { ModuleHeaderInformation } from "../ContentModuleBlocks/ModuleHeaderInformation";
import { checkChapterModuleAndBlockStatus } from "../helper";
import { getChapterDetailByChapterId } from "./helper";
import { CardBlockWithButtonContainer } from "../Components/CardBlockWithButtonContainer";
import { PrimaryButton } from "../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

const ChapterDetails: FunctionComponent = () => {
    const { t } = useTranslate();
    const params = useParams<TrainingRouteParams>();
    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore
    const chapterId = parseInt(params.chapterId);
    //@ts-ignore
    const groupId = parseInt(params.groupId);
    const history = useHistory();
    const currentCourse = useCurrentCourse();
    // @ts-ignore
    const chapter = getChapterDetailByChapterId(currentCourse, chapterId, courseId, groupId);
    const [isModulesActive, setModulesActive] = useState<boolean>(true);
    const openModuleSection = (moduleUrl: string) => {
        history.push(moduleUrl);
    };

    const isChapterActive =
        currentCourse &&
        currentCourse.chapters &&
        currentCourse.chapters.find(c => c.id === chapterId)?.status === "Active";

    useEffect(() => {
        const isStatusActive = checkChapterModuleAndBlockStatus(
            currentCourse as Course,
            chapterId as number,
            // @ts-ignore
            null,
            null
        );
        if (!isStatusActive) {
            setModulesActive(false);
            history.push("/");
        }
    }, [currentCourse, chapterId]);
    return (
        <div style={{ marginBottom: "10px", display: "flex", flex: 1 }}>
            <TrainingSidebar />
            {!isModulesActive && (
                <Notify
                    message="The module you are trying to access is not enabled. Please try again Later"
                    type={`warning`}
                    id="4"
                />
            )}
            <MainContent>
                <Row>
                    <div className="col-12">
                        {chapter && (
                            <CardBlockWithButtonContainer
                                buttons={
                                    <>
                                        <PrimaryButton
                                            onClick={() => openModuleSection(chapter?.moduleUrl)}
                                            eventName={{ object: "presentation", action: "chapterdetailsnext" }}
                                        >
                                            {t("COMMON_NEXT")}
                                        </PrimaryButton>
                                    </>
                                }
                            >
                                <React.Fragment>
                                    <div className="row block">
                                        <ModuleHeaderInformation
                                            // @ts-ignore

                                            title={chapter?.name}
                                            id={`chapter-details-${chapter.id}`}
                                            sequence={0}
                                        />
                                        <ModuleBlockDescription
                                            videoId={null}
                                            // @ts-ignore
                                            description={chapter?.description}
                                            completionTime={null}
                                        />
                                    </div>
                                    {isChapterActive && (
                                        <React.Fragment>
                                            <div style={{ marginBottom: "0px", position: "absolute" }}></div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </CardBlockWithButtonContainer>
                        )}
                    </div>
                </Row>
            </MainContent>
        </div>
    );
};

export default ChapterDetails;
