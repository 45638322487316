﻿import { createStyles, Navbar as MNavbar, ScrollArea } from "@mantine/core";
import { HupsLogoWhiteSmall } from "assets";
import { ReactNode } from "react";
import { useNavbarStyles } from "./useNavbarStyles";

const useLogoStyles = createStyles((theme, props: { headerHeight: number }) => ({
    logoContainer: {
        display: "flex",
        height: props.headerHeight,
        padding: "10px",
    },
    logo: {
        flexGrow: 1,
        flexShrink: 1,
        backgroundImage: `url(${HupsLogoWhiteSmall})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
    },
}));

export const navbarWidth = 250;

type NavbarProps = {
    headerHeight: number;
    children: ReactNode;
    bottomSection?: ReactNode;
};

const NavBarLogo = (props: { headerHeight: number }) => {
    const { classes } = useLogoStyles({ headerHeight: props.headerHeight });

    return (
        <div className={classes.logoContainer}>
            <div className={classes.logo} />
        </div>
    );
};

export const Navbar = (props: NavbarProps) => {
    const { classes } = useNavbarStyles();

    return (
        <MNavbar width={{ base: navbarWidth }} height="100vh">
            <NavBarLogo headerHeight={props.headerHeight} />
            <MNavbar.Section classNames={{ root: classes.navbarContentContainer }} grow component={ScrollArea}>
                {props.children}
            </MNavbar.Section>
            {props.bottomSection}
        </MNavbar>
    );
};
