import { ActionIcon } from "@mantine/core";
import { Icons } from "@/common";

type DeleteButtonProps = {
    onClick: () => void;
};

export const DeleteButton = (props: DeleteButtonProps) => {
    return (
        <ActionIcon onClick={props.onClick}>
            <Icons.Delete />
        </ActionIcon>
    );
};
