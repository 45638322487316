import { Course, Group, TransformationTodo, TransformationTodoItem } from "@/api-client";
import _ from "lodash";
import { Matrix, MatrixData } from "./types";

const getChaptersInMatrix = (course: Course, firstChapterWithTodosIndex: number) => {
    // @ts-ignore
    return course.chapters.filter(x => x.status === "Active").slice(firstChapterWithTodosIndex);
};

const getCompletedTodosForAllGroups = (x: TransformationTodo, allGroupsForCourse: Group[]) => {
    // @ts-ignore
    return s =>
        s.transformationTodoId === x.id && s.completed === true && allGroupsForCourse.some(g => g.id === s.groupId);
};

const getFirstChapterWithTodos = (course: Course) => {
    // @ts-ignore
    return (
        _
            // @ts-ignore
            .orderBy(
                course.chapters.filter(x => x.status === "Active"),
                "order"
            )
            // @ts-ignore
            .findIndex(c => c.modules?.flatMap(m => m.transformationTodos).length > 0)
    );
};

export const getTransformationMatrix = (
    course: Course,
    statuses: TransformationTodoItem[],
    currentGroup: Group,
    allGroups: Group[]
): Matrix => {
    // @ts-ignore
    var allGroupsForCourse = allGroups.filter(g => g.status === "Active" && g.courseIds.includes(course.id));
    const chaptersInMatrix = getChaptersInMatrix(course, getFirstChapterWithTodos(course));

    const chaptersAndModules = _.orderBy(chaptersInMatrix, "order").flatMap((chapter, chapterIndex) =>
        // @ts-ignore
        _.orderBy(
            chapter.modules.filter(x => x.status === "Active"),
            "order"
        ).map((module, moduleIndex): MatrixData => {
            // @ts-ignore
            const activeTransformationTodos: TransformationTodo[] = module.transformationTodos.filter(
                x => x.status === "Active"
            );
            const completedItemsInModule = activeTransformationTodos.flatMap(x =>
                statuses.filter(getCompletedTodosForAllGroups(x, allGroupsForCourse))
            );

            return {
                columnIndex: chapterIndex,
                chapter: chapter,
                rowIndex: moduleIndex,
                module: module,
                groupProgress:
                    completedItemsInModule.filter(x => x.groupId === currentGroup.id).length /
                    activeTransformationTodos.length,
                allProgress:
                    completedItemsInModule.length / (activeTransformationTodos.length * allGroupsForCourse.length),
                // @ts-ignore
                todosWithStatus: activeTransformationTodos.map(x => ({
                    todo: x,
                    status: statuses.find(s => s.transformationTodoId === x.id && s.groupId === currentGroup.id),
                })),
            };
        })
    );

    const moduleCount = _.max(chaptersInMatrix.map(c => c.modules?.length ?? 0));

    return {
        // @ts-ignore
        columns: chaptersInMatrix.map(c => c.name),
        rows: [...Array(moduleCount).keys()].map(x => `${x + 1}`),
        data: chaptersAndModules,
    };
};

export const formatProgressValue = (value: number) =>
    isNaN(value) || value === 0 ? "-" : `${(value * 100).toFixed(0)} %`;
