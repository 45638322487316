import { Navbar as MNavbar, NavLink, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useMatchRoute } from "../useMatchRoute";
import { accountOwnerNavbarNavigationItems } from "./accountOwnerNavigationItems";
import { Icons, useCurrentUser } from "@/common";
import { useAllGroups } from "../../../common/OrganisationContext";
import { getCourseGroupInLocalStorage } from "../../../common/LocalStorageAdapter";
import { getLatestTeamOrProgramSelectorRoute } from "../../../pages/HomeRoot/getLatestTeamOrProgramSelectorRoute";
import { headerHeight } from "../headerHeight";
import { useNavbarStyles } from "../useNavbarStyles";
import { Navbar } from "../Navbar";
import { BottomSection } from "../BottomSection";

export const OrgSettingsNavbar = () => {
    const { t } = useTranslate();
    const { classes } = useNavbarStyles();
    const { matchRoute } = useMatchRoute();
    const currentUser = useCurrentUser();
    const allGroups = useAllGroups();

    const to = getLatestTeamOrProgramSelectorRoute(getCourseGroupInLocalStorage(), allGroups ?? [], currentUser.id);

    return (
        <Navbar
            headerHeight={headerHeight}
            bottomSection={<BottomSection icon={<Icons.X />} label="NAVIGATION_EXIT_SETTINGS" navigateTo={to} active />}
        >
            <MNavbar.Section className={classes.section}>
                <Text className={classes.sectionHeader} size="md" color="white" weight={700}>
                    {t("NAVIGATION_SETTINGS")}
                </Text>
                {accountOwnerNavbarNavigationItems.map(item => (
                    <NavLink
                        classNames={{
                            root: classes.navLinkRoot,
                            label: classes.navLinkChildren,
                        }}
                        to={item.to}
                        active={matchRoute(item.to, true)}
                        component={Link}
                        label={t(item.label)}
                        key={item.label}
                    />
                ))}
            </MNavbar.Section>
        </Navbar>
    );
};
