import { GroupMembership } from "@/api-client";
import React, { useMemo, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useHistory } from "@/common";

import { createApi } from "../../../common/api";
import { useOrganisationContext, useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { createPatch } from "../../../common/patchHelper";
import Slideshow from "../../../components/Slideshow";
import { AppLoader } from "../../../components/Spinner";
import { findGroupsByCourseId } from "../helper";
import "./style.scss";
import { Group } from "@/api-client";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { createStyles } from "@mantine/core";

interface SendInvitesConfirmationProps {
    courseId: number;
}

const useStyles = createStyles(() => ({ buttonContainer: { display: "flex", justifyContent: "flex-end" } }));

const SendInvitesConfirmation = (props: SendInvitesConfirmationProps): JSX.Element => {
    const { classes } = useStyles();
    const { t } = useTranslate();
    const api = createApi();
    const history = useHistory();
    const accountOwnerContext = useOrganisationContext();
    const accountOwnerDispatch = useOrganisationContextDispatch();
    const allGroups = accountOwnerContext.groups;
    const courseId = props.courseId;
    /* @ts-ignore */
    const groups = useMemo(() => findGroupsByCourseId(allGroups, courseId as number), [allGroups, courseId]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const invitesToSend = groups?.flatMap(x => x.members).filter(x => x.inviteStatus.toString() === "NotSent");

    const sendInvites = () => {
        if (invitesToSend && invitesToSend.length > 0) {
            setIsLoading(true);

            const invitesToBeSent = groups?.flatMap(g =>
                g.members
                    .filter(member => member.inviteStatus === "NotSent")
                    .map(member => ({ groupId: g.id, ...member }))
            );

            if (invitesToBeSent && invitesToBeSent.length > 0) {
                Promise.allSettled(
                    invitesToBeSent?.map(member => {
                        //addGroupMembership( member.userId, member.groupId, member.isParticipant, member.isGroupLeader, "Queued" );
                        const patch = createPatch(member, g => {
                            g.inviteStatus = "Queued";
                            g.isGroupLeader = member.isGroupLeader;
                            g.isParticipant = member.isParticipant;
                        });
                        /* @ts-ignore */
                        return api.groupMembership.patch(member.userId, member.groupId, patch);
                    })
                )
                    .then(results => {
                        const finalResult: GroupMembership[] = results
                            .filter(result => result.status === "fulfilled")
                            .map(r => (r as PromiseFulfilledResult<GroupMembership>).value);
                        history.push(`/account-owner/resources/send-invites-success/${courseId}`);
                        accountOwnerDispatch(s => {
                            return {
                                ...s,
                                groups: [
                                    /* @ts-ignore */
                                    ...s.groups.map(g => {
                                        /* @ts-ignore */
                                        if (finalResult?.map(m => m.groupId).includes(g.id)) {
                                            const { members, ...rest } = g;

                                            const data: Group = {
                                                ...rest,
                                                members: [
                                                    ...members.map(m => ({
                                                        ...m,
                                                        inviteStatus: finalResult.map(f => f.userId)?.includes(m.userId)
                                                            ? "Queued"
                                                            : m.inviteStatus,
                                                    })),
                                                ],
                                            };
                                            return data;
                                        } else {
                                            return g;
                                        }
                                    }),
                                ],
                            };
                        });
                        setIsLoading(false);
                    })
                    .catch(err => {
                        setIsLoading(false);
                        console.log(err, "Error");
                    });
            }

            // allGroups.forEach(group => group.members.filter(x => x.inviteStatus.toString() == 'NotSent').forEach(user => {

            //     // Pass a callback to when this call succeeds and redirect when done
            //     const callback = (success: boolean) => {

            //         // TODO: Show error message if fails
            //         invitesSent--;
            //         if (invitesSent == 0) {
            //             setIsLoading(false);
            //             dispatch(push(`/account-owner/resources/send-invites-success/${courseId}`));
            //         }
            //     }

            //     // Count sent invites
            //     invitesSent++;
            //     dispatch(setGroupMembershipAction(user.userId, group.id, user.isParticipant, user.isGroupLeader, "Queued", callback));
            // }))

            // addGroupMembership
        }
    };
    const button = <Link to={`/account-owner/resources/send-invites/${courseId}`} />;
    return (
        <Slideshow previousLink={`/account-owner/resources/plan-course-group-members/${courseId}`} button={button}>
            <>
                <AppLoader loading={isLoading} />
                <Row className="confirmation-slide">
                    <Col md={12} className="heading">
                        <p className="content-label">{t("RESOURCES_PLAN_A_NEW_COURSE")}</p>
                    </Col>
                    <Col md={6} className="mt-2">
                        <h2>{t("RESOUCES_SEND_INVITE_CONFIRMATION_HEADING")}</h2>
                        <p>{t("RESOUCES_SEND_INVITE_CONFIRMATION_TEXT")}</p>
                    </Col>
                </Row>
                <Row className="confirmation-slide">
                    <Table striped borderless>
                        <thead>
                            <tr>
                                <th>{t("RESOURCES_INVITES_GROUP_NAME")}</th>
                                {/*
                             <th>
                             {t("RESOURCES_GROUPLEADER")}
                             </th>
                             */}
                                <th>{t("RESOURCES_INVITES_TO_BE_SENT")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups &&
                                groups.map(group => {
                                    return (
                                        <tr key={`${group.name}-${group.id}`}>
                                            <td>{group.name}</td>
                                            {/* 
                                     <td>
                                     {groupLeaders}
                                     </td>
                                     */}
                                            <td>
                                                <ul className="list-unstyled">
                                                    {group.members
                                                        .filter(x => x.inviteStatus.toString() === "NotSent")
                                                        .map(m => (
                                                            <li key={m.userId}>
                                                                {
                                                                    /* @ts-ignore */
                                                                    m.user.email
                                                                }
                                                            </li>
                                                        ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    );
                                })}
                            <tr>
                                <td></td>
                                <td className="total-users">{invitesToSend && invitesToSend.length}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <div className={classes.buttonContainer}>
                    <PrimaryButton disabled={isLoading && invitesToSend.length === 0} onClick={() => sendInvites()}>
                        {t("RESOURCES_SEND_INVITES")}
                    </PrimaryButton>
                </div>
            </>
        </Slideshow>
    );
};
export default SendInvitesConfirmation;
