import { ReactNode } from "react";
import { Layout } from "../Layout";
import { TeamHeader } from "./TeamHeader";
import { TeamNavbar } from "./TeamNavbar";

export const TeamLayout = (props: { children: ReactNode }) => {
    return (
        <Layout navbar={<TeamNavbar />} header={<TeamHeader />}>
            {props.children}
        </Layout>
    );
};
