import React from "react";
import { Table } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createStyles } from "@mantine/core";
import { DemographicQuestion, User } from "@/api-client";
import { AddQuestions } from "../addQuestion";
import { NavButton } from "@/components";

type EditPlanningDemographicQuestions = {
    items: DemographicQuestion[];
    demographicQuestionId: number;
    user: User;
    /* @ts-ignore */
    handleQuestion(question): void;
    /* @ts-ignore */
    showQuestionsScreen: (value) => void;
};

const useStyles = createStyles(() => ({ floatRight: { float: "right" } }));

export const EditPlanningDemograpicQuestions = (props: EditPlanningDemographicQuestions) => {
    const { t } = useTranslate();
    return (
        <React.Fragment>
            {props.demographicQuestionId || props.items.length === 0 ? (
                <EditDemographicQuestions
                    user={props.user}
                    handleQuestion={question => props.handleQuestion(question)}
                    questions={props.items}
                    demographicQuestionId={props.demographicQuestionId}
                />
            ) : (
                <React.Fragment>
                    <button onClick={() => props.showQuestionsScreen(true)} className="btn btn-link mt-4 float-right">
                        + {t("ONBOARDING_ADD_DEMOGRAPHIC_QUESTIONS")}
                    </button>
                    <ListDemographic items={props.items} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const ListDemographic = (props: { items: DemographicQuestion[] }) => {
    const { classes } = useStyles();
    return (
        <React.Fragment>
            <Table className="table table-borderless table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.items &&
                        props.items.length > 0 &&
                        props.items.map(i => {
                            return (
                                <tr key={i.id}>
                                    <td>{i.label}</td>
                                    <td>
                                        <div className={classes.floatRight}>
                                            <NavButton
                                                link={`/account-owner/edit/organisation-planning/demographic-questions/${i.id}/edit`}
                                                textKey="COMMON_EDIT"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

const EditDemographicQuestions = (props: {
    questions: DemographicQuestion[];
    demographicQuestionId: number;
    user: User;
    /* @ts-ignore */
    handleQuestion(question): void;
}) => {
    return (
        <React.Fragment>
            <AddQuestions
                user={props.user}
                handleQuestion={data => props.handleQuestion(data)}
                question={props.questions?.find(q => q.id === props.demographicQuestionId)}
                demographicQuestionId={props.demographicQuestionId}
            />
        </React.Fragment>
    );
};
