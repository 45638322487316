import { Link } from "react-router-dom";
import { Course } from "@/api-client";
import { OnboardingVideo } from "../helper";

/**
 * NOTE: This entire onboarding is deprecated. Use a introduction chapter in course instead.
 */

const stepNumberStyle = {
    backgroundColor: "#c061e0",
    color: "#fff",
    borderColor: "$brand-color",
};

interface OnBoardNavProps {
    onBoardVideoIndex: number;
    onBoardMenu: OnboardingVideo[];
    course: Course | null;
    groupId: number;
}

const OnBoardingNav = (props: OnBoardNavProps): JSX.Element => {
    const { onBoardMenu, course, onBoardVideoIndex } = props;
    return (
        <div className="row justify-content-center mt-5 mb-5 d-flex flex-nowrap">
            <div className="col-md-12 onboarding-nav-display">
                {course &&
                    onBoardMenu.map((video: OnboardingVideo, index: number) => {
                        const videoName = video.title;
                        const videoIndex = index + 1;
                        const isActive = videoIndex <= onBoardVideoIndex;
                        return (
                            <>
                                <div
                                    className={
                                        isActive
                                            ? "onboarding-nav-btns slide_button_active text-center p-3"
                                            : "slide_button_disabled text-center p-3"
                                    }
                                    key={`video-${videoIndex}`}
                                >
                                    <Link
                                        to={
                                            isActive
                                                ? `/groups/${props.groupId}/onboarding/courses/${course.id}/videos/${videoIndex}`
                                                : "#"
                                        }
                                        className={`${
                                            isActive ? "onboarding-nav-btns enabled" : "onboarding-nav-btns disabled"
                                        } d-flex flex-column `}
                                    >
                                        <span
                                            className="step-number rounded-circle border me-1 align-self-center mb-3"
                                            style={stepNumberStyle}
                                        >
                                            {videoIndex}
                                        </span>
                                        <span className="step-name">{videoName}</span>
                                    </Link>

                                    {/*<p className={`${isActive ? 'opex-text-color' : 'text-muted'}  video_name mt-2`}>{video_name}</p>*/}
                                </div>
                            </>
                        );
                    })}
            </div>
        </div>
    );
};
export default OnBoardingNav;
