import React from "react";
import "./style.scss";

interface InlineErrorProps {
    error: string;
    className?: string;
}

export const DisplayInlineError = (props: InlineErrorProps) => {
    return <span className={`inline-error ${props.className}`}>{props.error}</span>;
};
