﻿import { createStyles, Flex, Group, Stack, Text } from "@mantine/core";
import { Avatar, RenderIf } from "@/components";
import { Icons } from "@/common";

const useStyles = createStyles(theme => ({
    icon: {
        color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
    },
}));

type NameCellProps = {
    id: number;
    email: string | null;
    phoneNumber: string | null;
    avatarUrl: string | null;
    displayName: string | null;
};

export const NameCell = (props: NameCellProps) => {
    const { classes } = useStyles();

    const isNullOrEmpty = (value: string | null) => value === null || value === "";
    const hasBothEmailAndPhoneNumber = !isNullOrEmpty(props.email) && !isNullOrEmpty(props.phoneNumber);

    return (
        <Flex align="center" gap="xs">
            <Avatar
                userId={props.id}
                image={props.avatarUrl === null ? undefined : props.avatarUrl}
                fullName={props.displayName === null ? undefined : props.displayName}
            />
            <Stack spacing="none">
                <RenderIf show={props.displayName !== null}>
                    <Text>{props.displayName}</Text>
                </RenderIf>
                <Group spacing="none">
                    <Text fz="xs" c="dimmed">
                        {props.email}
                    </Text>
                    <RenderIf show={hasBothEmailAndPhoneNumber}>
                        <Icons.Divider stroke={1.5} size="1rem" className={classes.icon} />
                    </RenderIf>
                    <Text fz="xs" c="dimmed">
                        {props.phoneNumber}
                    </Text>
                </Group>
            </Stack>
        </Flex>
    );
};
