import { Course } from "@/api-client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { CourseDisplay } from "../../../components/CourseSelector/CourseDisplay";
import RenderIf from "../../../components/render-if/render-if";
import Slideshow from "../../../components/Slideshow";
import { AppLoader } from "../../../components/Spinner";
import ResourcesContext from "../CoursesContext";
import "./style.scss";

const PlanNewCourse = () => {
    const { t } = useTranslate();
    const resources = React.useContext(ResourcesContext);
    const history = useHistory();
    /* @ts-ignore */
    const courses: Course[] = resources.courses;
    /* @ts-ignore */
    const courseLoading: boolean = resources.coursePending;

    const selectCourse = (course: Course) => {
        history.push(`/account-owner/resources/plan-course-group-members/${course.id}`);
    };

    return (
        <Slideshow>
            <div className="plan-new-course-slide">
                <Row>
                    <Col md={12} className="heading">
                        <p className="content-label">{t("RESOURCES_PLAN_A_NEW_COURSE")}</p>
                        <h2>{t("RESOURCES_SELECT_FIRST_COURSE")}</h2>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12} className="sub-heading mt-2">
                        <p>{t("RESOURCES_PLAN_COURSE_P1")}</p>
                        <p>{t("RESOURCES_PLAN_COURSE_P2")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="plan-courses course-selector">
                        <AppLoader loading={courseLoading} />
                        <RenderIf show={courses && courses.length > 0}>
                            {courses.map((c, i) => {
                                return (
                                    <CourseDisplay
                                        isCurrentlySelected={false}
                                        key={i}
                                        name={c.name}
                                        description={c.description!}
                                        hasProgress={false}
                                        selectCourseCallback={() => selectCourse(c)}
                                    />
                                );
                            })}
                        </RenderIf>
                    </Col>
                </Row>
            </div>
        </Slideshow>
    );
};
export default PlanNewCourse;
