import { Question, QuestionOption } from "@/api-client";
import { Field } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { AppLoader } from "../../../../components/Spinner";
import useDebounce from "../../../../components/UseDebounce/UseDebounce";
import { QuestionOptionsCard } from "./QuestionOptionsCard";
import update from "immutability-helper";
import { useHistory } from "@/common";
import "./styles.scss";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

type QuestionOptionProps = {
    question: Question;
    updatedOptions?: (options: QuestionOption[]) => void;
};

export const AddQuestionOptions = (props: QuestionOptionProps) => {
    const { t } = useTranslate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const api = createApi();

    // @ts-ignore
    const defaultOptions: QuestionOption = useMemo(
        () => ({
            id: undefined,
            questionId: props.question?.id,
            text: "",
            order: 0,
            status: "Active",
            hidden: false,
            isPredefined: true,
        }),
        [props.question?.id]
    );

    // @ts-ignore

    const [options, setOptionsState] = useState<QuestionOption[]>(props.question.options);

    const setOptions = (options: QuestionOption[]) => {
        setOptionsState(options);
        // @ts-ignore

        props.updatedOptions(options);
    };

    const updateField = (text: string, option: QuestionOption) => {
        setIsLoading(true);

        const patch = createPatch(option, o => {
            o.text = text;
        });

        // @ts-ignore

        api.questionOptions.patch(option.id, patch).then(
            _ => {
                setIsLoading(false);
            },
            error => {
                console.log(error);
                setIsLoading(false);
                history.push("/error");
            }
        );
    };

    const addField = (text: string) => {
        const newItem: QuestionOption = {
            ...defaultOptions,
            text: text,
            order: options.length,
        };

        setIsLoading(true);
        api.questionOptions.post(newItem).then(
            savedItem => {
                setOptions([...options, savedItem]);
                setIsLoading(false);
            },
            error => {
                history.push("/error");
                console.log(error);
                setIsLoading(false);
            }
        );
    };

    const removeField = (id: number) => {
        confirmAlert({
            title: t("SYSADMIN_ANSWEROPTIONS_DELETE_CONFIRM_HEADING"),
            message: t("ONBOARDING_QUESTTION_DELETE_MESSAGE"),
            buttons: [
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_YES"),
                    onClick: () => {
                        setIsLoading(true);

                        //API start loading here
                        //Store the reuslt in setCourse local state
                        api.questionOptions.delete(id).then(
                            result => {
                                console.log(result);
                                setOptions(options.filter(x => x.id !== id));
                                setIsLoading(false);
                            },
                            error => {
                                // corse loading api is done and error
                                console.log(error);
                                setIsLoading(false);
                                history.push("/error");
                            }
                        );
                    },
                },
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_NO"),
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    const moveCard = useCallback(
        (dragIndex: any, hoverIndex: any) => {
            const updatedOptions = update(options, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, options[dragIndex]],
                ],
            }).map((x, ix) => ({ ...x, order: ix }));

            updatedOptions.forEach(m => {
                const option = props.question.options?.find(o => o.id === m.id)!;
                const patch = createPatch(option, x => {
                    x.order = m.order;
                });

                if (patch && patch.length > 0) {
                    // @ts-ignore

                    return api.questionOptions.patch(m.id, patch);
                }
            });

            setOptions(updatedOptions);
        },
        [options]
    );

    return (
        <div className="items-drag-drop-container">
            <AppLoader loading={isLoading} className="position-absolute" />
            <AddOptionForm isLoading={isLoading} addField={addField} />
            <ul className="list-unstyled sysadmin-drag">
                <DndProvider backend={HTML5Backend}>
                    {options &&
                        options.map((m, i) => (
                            <QuestionOptionsCard
                                questionOption={m}
                                moveCard={moveCard}
                                key={m.id}
                                removeField={
                                    // @ts-ignore

                                    () => removeField(m.id)
                                }
                                updateField={newText => updateField(newText, m)}
                                index={i}
                                // @ts-ignore

                                id={m.id}
                            />
                        ))}
                </DndProvider>
            </ul>
        </div>
    );
};

interface EditOptionFormProps {
    option: QuestionOption;
    update: (text: string) => void;
}

export const EditOptionForm = (props: EditOptionFormProps) => {
    // @ts-ignore

    const [text, setText] = useState<string>(props.option.text);
    const textChange = useDebounce(text, 1000);

    useEffect(() => {
        props.update(text);
    }, [textChange]);

    return (
        <Field
            name="text"
            as="textarea"
            type="textarea"
            className="form-control questions-options-all"
            value={text}
            // @ts-ignore

            onChange={e => setText(e.target.value)}
            On
        />
    );
};

interface AddOptionFormProps {
    isLoading: boolean;
    addField: (text: string) => void;
}

export const AddOptionForm = (props: AddOptionFormProps) => {
    const [text, setText] = useState<string>();

    return (
        <div className="add-question-option">
            <Field
                name="text"
                as="textarea"
                type="textarea"
                className="form-control questions-options-all"
                value={text}
                // @ts-ignore

                onChange={e => setText(e.target.value)}
                placeholder={"Enter Option"}
            />

            <PrimaryButton
                disabled={props.isLoading || !text}
                onClick={() => {
                    // @ts-ignore

                    props.addField(text);
                    setText("");
                }}
            >
                Add Option
            </PrimaryButton>
        </div>
    );
};
