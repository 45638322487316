import { Chapter, ChapterStatuses } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify } from "../../../components/Notify";
import ReactMdeEditor from "../../../components/ReactMdeEditor";
import { AppLoader } from "../../../components/Spinner";
import { PreviewHandler } from "../../components/PreviewHandler";
import { useUpdateSysadminCourse } from "../../SysadminContext";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Chapter Name is required"),
    });
};

interface EditChapterResponse {
    chapter: Chapter;
    isPending: boolean;
    isSuccess: boolean;
}

interface FormModel {
    chapterId: number;
    name: string;
    description: string;
    order: number;
    status: ChapterStatuses;
    navigationLabel: string;
}

type EditChapterProps = {
    courseId: number;
    chapterId: number;
};

const EditChapter = (props: EditChapterProps): JSX.Element => {
    const history = useHistory();
    const updateCourse = useUpdateSysadminCourse(props.courseId);

    const [submitted, setSubmitted] = useState(false);
    const [editChapter, setEditChapter] = useState<EditChapterResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        chapter: null,
        isSuccess: false,
    });

    //@ts-ignore
    const [chapter, setChapter] = useState<Chapter>(null);
    const [isPending, setIsPending] = useState<boolean>(false);

    const api = createApi();

    useEffect(() => {
        if (props.chapterId) {
            getChapterById(props.chapterId);
        }
    }, [props.chapterId]);

    const getChapterById = (chapterId: number) => {
        setIsPending(true);
        //API start loading here
        //Store the reuslt in setCourse local state
        api.chapters.get(chapterId).then(
            result => {
                // Course loading api done and success
                setChapter(result);
                setIsPending(false);
                //@ts-ignore
                setDescription(result.description);
            },
            error => {
                // corse loading api is done and error
                console.log(error);
                setIsPending(false);
            }
        );
    };

    const initialValues: FormModel = {
        //@ts-ignore
        chapterId: chapter?.id,
        //@ts-ignore
        name: chapter?.name,
        //@ts-ignore
        description: chapter?.description,
        status: chapter?.status,
        //@ts-ignore
        order: chapter?.order,
        //@ts-ignore
        navigationLabel: chapter?.navigationLabel,
    };

    //@ts-ignore
    const [description, setDescription] = useState<string>(chapter?.description);

    const isLoading = editChapter.isPending || isPending;

    //@ts-ignore
    const updateChapter = (chapterId: number, patch) => {
        api.chapters.patch(chapterId, patch).then(
            result => {
                setEditChapter({ ...editChapter, chapter: result, isPending: false, isSuccess: true });
                updateCourse(s => ({
                    ...s,
                    chapters: s.chapters.map(m => {
                        if (m.id === chapterId) {
                            //@ts-ignore
                            delete result.modules;
                            return {
                                ...m,
                                ...result,
                            };
                        }
                        return m;
                    }),
                }));

                history.push(`/sysadmin/courses/${props.courseId}/chapter/${chapterId}/edit`);
            },
            error => {
                setEditChapter({ ...editChapter, isPending: false });
                history.push("/error");
                console.log(error);
            }
        );
    };

    return (
        <>
            <AppLoader loading={isLoading} className="position-absolute" />
            {editChapter.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Chapter updated successfully." id="registersuccess" type="success" />
                </Fragment>
            )}

            <ContentLabel text="Edit Chapter" />

            <Row>
                <Col className="col-md-9">
                    <h2>{chapter?.name}</h2>
                </Col>
                <Col className="col-md-3 text-right">
                    {chapter && (chapter.status === "Active" || chapter.status === "Disabled") && (
                        <PreviewHandler
                            link={`/sysadmin/course-preview/${props.courseId}/chapter/${chapter?.id}/chapter-detail`}
                        />
                    )}
                </Col>
            </Row>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={(values, helpers) => {
                    const patch = createPatch(chapter, x => {
                        x.name = values.name;
                        x.description = values.description;
                        x.order = values.order;
                        x.status = values.status;
                        x.navigationLabel = values.navigationLabel;
                    });

                    if (patch && patch.length > 0) {
                        if (values.status === "Deleted") {
                            confirmAlert({
                                title: `Are you sure to want to delete Chapter?`,
                                message: "Action can be undone",
                                buttons: [
                                    {
                                        label: "Yes",
                                        onClick: () => {
                                            setSubmitted(true);
                                            setEditChapter({ ...editChapter, isPending: true });
                                            updateChapter(props.chapterId, patch);
                                        },
                                    },
                                    {
                                        label: "No",
                                        onClick: () => console.log("No pressed."),
                                    },
                                ],
                            });
                        } else {
                            setSubmitted(true);
                            setEditChapter({ ...editChapter, isPending: true });
                            updateChapter(props.chapterId, patch);
                        }
                    } else {
                        setEditChapter({ ...editChapter, isPending: false });
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched, setFieldValue } = formikProps;
                    return (
                        <Form name="editChapterForm" className="mt-2 editChapterForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="name"
                                        component="span"
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Navigation Label</label>
                                    <Field
                                        name="navigationLabel"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.navigationLabel && touched.navigationLabel ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="name"
                                        component="span"
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Description</label>
                                    <ReactMdeEditor
                                        onChange={value => {
                                            setDescription(value);
                                            setFieldValue("description", value);
                                        }}
                                        initialValue={description}
                                    />
                                    {/* <ErrorMessage className="clearfix w-100 text-danger text-left displayblock" name="description" component="span" /> */}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Status</label>
                                    {/** Please move the option in const I think we can reuse this eiter we have to
                                     * write it eveywhere
                                     */}
                                    <Field name="status" as="select" className="form-select">
                                        <option key="1" value="Active">
                                            Active
                                        </option>
                                        <option key="2" value="Inactive">
                                            Inactive
                                        </option>
                                        <option key="3" value="InProgress">
                                            Inprogress
                                        </option>
                                        <option key="4" value="Deleted">
                                            Deleted
                                        </option>
                                        <option key="5" value="Invited">
                                            Invited
                                        </option>
                                        <option key="6" value="Disabled">
                                            Disabled (Locked)
                                        </option>
                                    </Field>
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" loading={isLoading}>
                                    Save
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default EditChapter;
