import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { createApi } from "@/common";
import {
    BasicTable,
    BasicTableColumn,
    PrimaryButton,
    RowActions,
    useDeleteConfirmationModal,
    useModal,
} from "@/components";
import { AssignProgramModal } from "./AssignProgramModal";

type AssignedProgram = {
    name: string;
    id: number;
};

type AssignablePrograms = {
    name: string;
    id: number;
};

type AssignProgramsProps = {
    teamId: number;
};

type State = {
    assignablePrograms: AssignablePrograms[];
    assignedPrograms: AssignedProgram[];
};

export const AssignPrograms = (props: AssignProgramsProps) => {
    const { t } = useTranslate();
    const [state, setState] = useState<State | null>(null);
    const modal = useModal(AssignProgramModal);
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const refresh = () => {
        const api = createApi();

        const programs = api.courses.query(false);
        const teamPrograms = api.groupCourses.query(props.teamId, null, null);

        return Promise.all([programs, teamPrograms]).then(([programs, teamPrograms]) => {
            setState({
                assignedPrograms: teamPrograms
                    .filter(p => programs.any(program => program.id === p.courseId))
                    .map(p => ({
                        name: programs.find(x => x.id === p.courseId)!.name,
                        id: p.id!,
                    })),
                assignablePrograms: programs
                    .filter(p => teamPrograms.every(tp => tp.courseId !== p.id))
                    .map(p => ({ name: p.name, id: p.id })),
            });
        });
    };

    useEffect(() => {
        refresh().catchWithToast();
    }, []);

    const columns = useMemo<BasicTableColumn<AssignablePrograms>[]>(
        () => [
            {
                header: t("ACCOUNTOWNER_EDIT_TEAMS_PROGRAM_NAME"),
                accessor: row => row.name,
            },
        ],
        []
    );

    return (
        <BasicTable
            data={state?.assignedPrograms}
            columns={columns}
            renderRowActions={({ row }) => (
                <RowActions.DeleteButton
                    onClick={() =>
                        deleteConfirmationModal.open({
                            title: "ACCOUNTOWNER_EDIT_TEAM_REMOVE_TEAM_PROGRAM_CONFIRMATION_MESSAGE",
                            onConfirm: () =>
                                createApi()
                                    .groupCourses.delete(row.original.id)
                                    .then(() => refresh())
                                    .catchWithToast(),
                        })
                    }
                />
            )}
            toolbarActions={() => (
                <PrimaryButton
                    eventName={{ object: "ao_assignprogram", action: "open" }}
                    onClick={() =>
                        modal.open({
                            programs: state !== null ? state.assignablePrograms : null,
                            teamId: props.teamId,
                            onClose: () => refresh().catchWithToast(),
                        })
                    }
                >
                    {t("ACCOUNTOWNER_EDIT_TEAMS_ASSIGN_PROGRAM")}
                </PrimaryButton>
            )}
        />
    );
};
