const getAuthorizationHeader = (token: string | null) => (token === null ? null : { Authorization: token });

export const PostJson = <T>(url: string, body: T, token: string | null = null): Promise<Response> =>
    fetch(url, {
        mode: "cors",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(body),
    });

export const Get = (
    url: string,
    token: string | null = null,
    abortController: AbortController | null = null
): Promise<Response> =>
    fetch(url, {
        mode: "cors",
        method: "get",
        headers: {
            Accept: "application/json",
            ...getAuthorizationHeader(token),
        },
        signal: abortController?.signal,
    });
