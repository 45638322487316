import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { yupExtensions } from "common/YupExtensions";
import { createApi } from "../../../common/api";
import { useCurrentUser } from "../../../common/user/context";
import { showToast } from "../../../components/Notify";
import { hashPassword } from "../../Login/helper";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

interface SubmitFormValues {
    current_password: string;
    new_password: string;
    confirm_password: string;
}

const ProfileChangePasswordForm = () => {
    const { t } = useTranslate();
    const api = createApi();
    const email = useCurrentUser().email;

    const validate = () =>
        Yup.object().shape({
            current_password: Yup.string().required(t("PROFILE_PASSWORD_REQUIRED")),
            new_password: yupExtensions.passwordValidator(
                t("PROFILE_NEW_PASSWORD_REQUIRED"),
                t("PROFILE_NEW_PASSWORD_VALID")
            ),
            confirm_password: Yup.string()
                .when("new_password", {
                    is: (val: string) => val && val.length > 0,
                    then: Yup.string().oneOf([Yup.ref("new_password")], t("PROFILE_CONFIRM_PASSWORD_MATCH")),
                })
                .when("new_password", {
                    is: (val: string) => val && val.length > 0,
                    then: Yup.string().required(t("PROFILE_CONFIRM_PASSWORD_REQUIRED")),
                }),
        });

    const submitLoginForm = async (values: SubmitFormValues) => {
        api.users
            .changePassword({
                email: email,
                currentPassword: hashPassword(values.current_password),
                newPassword: hashPassword(values.new_password),
            })
            .then(
                x => {
                    console.log(x);
                    showToast(t("PROFILE_CHANGE_PASSWORD_SUCCESS"), "success");
                },
                error => {
                    console.log(error);
                    showToast(t("COMMON_DEFAULT_ERROR"), "warning");
                }
            );
    };

    return (
        <div className="profile_change_password">
            <Formik
                initialValues={{
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                }}
                validationSchema={validate()}
                onSubmit={submitLoginForm}
            >
                {props => {
                    const { errors, touched } = props;
                    return (
                        <Form>
                            <div className="my-3s p-4">
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">{t("PROFILE_PASSWORD")}</label>
                                    <Field
                                        type="password"
                                        name="current_password"
                                        placeholder={t("PROFILE_PASSWORD")}
                                        className={`form-control${
                                            errors.current_password && touched.current_password ? " is-invalid" : ""
                                        }`}
                                    />
                                    {errors.current_password ? (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.current_password}{" "}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">{t("PROFILE_NEW_PASSWORD")}</label>
                                    <Field
                                        type="password"
                                        name="new_password"
                                        placeholder={t("PROFILE_NEW_PASSWORD")}
                                        className={`form-control${
                                            errors.new_password && touched.new_password ? " is-invalid" : ""
                                        }`}
                                    />
                                    {errors.new_password ? (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.new_password}{" "}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">
                                        {t("PROFILE_CONFIRM_PASSWORD")}
                                    </label>
                                    <Field
                                        type="password"
                                        name="confirm_password"
                                        placeholder={t("PROFILE_CONFIRM_PASSWORD")}
                                        className={`form-control${
                                            errors.confirm_password && touched.confirm_password ? " is-invalid" : ""
                                        }`}
                                    />
                                    {errors.confirm_password ? (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {errors.confirm_password}{" "}
                                        </span>
                                    ) : null}
                                </div>

                                <ContentBlockButtonContainer>
                                    <PrimaryButton
                                        eventName={{ object: "editprofile", action: "changepassword" }}
                                        type="submit"
                                        disabled={
                                            !!(
                                                errors.current_password ||
                                                errors.new_password ||
                                                errors.confirm_password
                                            )
                                        }
                                    >
                                        {t("PROFILE_CHANGE_PASSWORD_BUTTON")}
                                    </PrimaryButton>
                                </ContentBlockButtonContainer>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ProfileChangePasswordForm;
