import { createStyles } from "@mantine/core";
import { Icons } from "@/common";

const useStyles = createStyles((_, props: { paddingLeft: number; withBullet: boolean }) => {
    const bullet = {
        content: "''",
        display: "inline-block",
        width: "0.4rem",
        height: "0.4rem",
        borderRadius: "100%",
        backgroundColor: "lightgray",
        marginBottom: "0.1rem",
        marginRight: "0.6rem",
        marginTop: "10px",
        minWidth: "0.4rem",
    };

    return {
        row: {
            display: "flex",
            width: "100%",
            marginLeft: "auto",
            position: "relative",
            fontSize: "16px",
            padding: "10px 0",
            color: "grey",
        },

        rowText: {
            display: "block",
            width: "100%",
            marginLeft: "auto",
            paddingLeft: `${props.paddingLeft}rem`,
            position: "relative",
            fontSize: "16px",
            color: "grey",

            "&:before": props.withBullet ? bullet : {},

            "div:last-child": {
                marginLeft: "auto",
            },
        },
    };
});

export const LockedNav = (props: { text: string; level: number; withBullet: boolean }) => {
    const paddingLeft = props.level === 0 ? 0 : props.level - 0.5;
    const { classes } = useStyles({ paddingLeft: paddingLeft, withBullet: props.withBullet });

    return (
        <div className={classes.row}>
            <div className={classes.rowText}>
                <span>{props.text}</span>
            </div>
            <div>
                <Icons.Lock height="20px" width="20px" />
            </div>
        </div>
    );
};
