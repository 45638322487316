import { useAuth } from "../auth/useAuth";
import { useHistory, createApi } from "@/common";
import { UserHasSpecificRole } from "../common/user/utils";
import { AGENT_ROLE } from "../constants";
import { UrlFactory } from "../routing/UrlFactory";

export const useAccessToSysadmin = () => {
    const { clearImpersonation } = useAuth();
    const history = useHistory();

    return async () => {
        await clearImpersonation();
        const api = createApi();
        const refreshResponse = await api.login.refresh();
        const refreshUser = refreshResponse.user;
        if (UserHasSpecificRole(refreshUser, AGENT_ROLE)) {
            history.push(UrlFactory.sysadmin.clients.create({}));
        } else {
            history.push(UrlFactory.sysadmin.home.create({}));
        }
    };
};
