import { AdditionalResource } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createApi } from "../../../../../common/api";
import { ContentLabel } from "../../../../../components/ContentLabel";
import RenderIf from "../../../../../components/render-if/render-if";
import { CoursesLayout } from "../../../CoursesLayout";
import { NavButton } from "@/components";
import { EditAction } from "../../../EditAction";

/*  eslint-disable react-hooks/exhaustive-deps */

interface ModuleProps {
    chapterId: number;
    courseId: number;
    moduleId: number;
    moduleBlockId: number;
}

type ResourceStateType = {
    isPending: boolean;
    result: AdditionalResource[];
};

const AdditionalResources = (props: ModuleProps) => {
    const [resourcesState, setResourcesState] = useState<ResourceStateType>({
        isPending: false,
        // @ts-ignore

        result: null,
    });

    const api = createApi();

    useEffect(() => {
        setResourcesState({ ...resourcesState, isPending: true });
        api.additionalResources.query(props.moduleId, props.moduleBlockId).then(
            result => {
                setResourcesState({ ...resourcesState, result, isPending: false });
            },
            error => {
                setResourcesState({ ...resourcesState, isPending: false });
                console.log(error, "Error in Additional resources API");
            }
        );
    }, [props.moduleBlockId]);

    const getIconsForAdditionalResources = (value: string): JSX.Element => {
        const extension = value.split(".").pop();
        switch (extension) {
            case "pdf":
                return <i className="fas fa-file-pdf" />;
            case "png":
            case "jpg":
                return <i className="fas fa-image" />;
            case "docx":
            case "doc":
                return <i className="fas fa-file-word" />;
            case "xlsx":
            case "xls":
                return <i className="fas fa-file-excel" />;
            default:
                return <i className="fas fa-file-word" />;
        }
    };

    const { isPending, result } = resourcesState;

    return (
        <CoursesLayout>
            <ContentLabel text="Additional Resources" />
            <NavButton
                link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}/additional-resources/add`}
                textKey="Add Additional Resources"
            />
            {/* <AppLoader loading={(isLoading) ? true : false} className="position-absolute" /> */}
            <table className="table table-borderless table-striped additional-resources">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {result &&
                        result.length > 0 &&
                        result.map((r, i) => {
                            // @ts-ignore
                            const getIconByExtension = getIconsForAdditionalResources(r.url);
                            return (
                                <tr key={i}>
                                    <td style={{ width: "50%" }}>
                                        {getIconByExtension}{" "}
                                        <Link to={{ pathname: r.url }} target="_blank">
                                            {r.title}
                                        </Link>
                                    </td>
                                    <td>{r?.status}</td>
                                    <td className="col-1">
                                        <EditAction
                                            link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}/additional-resources/${r.id}/edit`}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    <RenderIf show={isPending === false && result && result.length === 0}>
                        <tr>
                            <th className="text-center" colSpan={3}>
                                No Additional Resources Found.
                            </th>
                        </tr>
                    </RenderIf>
                </tbody>
            </table>
        </CoursesLayout>
    );
};

export default AdditionalResources;
