import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useOrganisationContext } from "../../common/OrganisationContext";
import PageHeading from "../../components/PageHeading";
import { AppLoader } from "../../components/Spinner";
import { GroupsListing } from "../Components/MyOrganisation/GroupsListing";
import { GroupEditor } from "./Groups/GroupEditor";
import { Group } from "@/api-client";
import { createApi } from "../../common/api";
import { showToast } from "../../components/Notify";
import { CardBlock } from "../../components/CardBlock";

type EditGroupProps = {
    groupId?: number;
    groupTypeId?: number;
    template?: string;
};

export const EditGroups = ({ groupTypeId }: EditGroupProps): JSX.Element => {
    const api = createApi();
    const { t } = useTranslate();
    const groupTypes = useOrganisationContext().groupTypes;

    const [groups, setGroups] = useState<Group[]>(null!);

    useEffect(() => {
        api.groups.query(null, null, null, null).then(
            result => {
                const filteredGroups = result
                    .filter(g => g.status === "Active" || g.status === "Inactive")
                    .filter(g => g.groupTypeId === groupTypeId);

                setGroups(filteredGroups);
            },
            () => showToast(t("COMMON_DEFAULT_ERROR"), "error")
        );
    }, [groupTypeId, t]);

    const groupTypeName = groupTypeId ? groupTypes?.find(gt => gt.id === groupTypeId)?.name : "";

    return (
        <>
            <CardBlock mb="xl">
                <AppLoader loading={false} className="position-absolute" />
                <PageHeading
                    //@ts-ignore
                    contentLabel={groupTypeName}
                    detailText={t("ORGANISATION_GROUP_TYPE_HEADING")}
                    className="account-settings"
                />
                <Row>
                    <Col md={12}>
                        <GroupsListing
                            groups={groups}
                            link="/account-owner/edit/organisation-planning"
                            buttonTitle={t("COMMON_EDIT")}
                            showTableHeaders
                            groupTypeId={groupTypeId}
                        />
                    </Col>
                </Row>
            </CardBlock>
            <CardBlock mb="xl">
                <Row className="department-slide row">
                    <Col md={12}>
                        <PageHeading contentLabel={t("ORGANISATION_ADD_YOUR_GROUPS")} className="account-settings" />
                        <GroupEditor groups={groups} groupTypeId={groupTypeId} refreshOrganisationReport />
                    </Col>
                </Row>
            </CardBlock>
        </>
    );
};
