import posthog from "posthog-js";

/**
 * Removes the given group type from currently identified groups. This prevents future events
 * from being associated with the group type.
 */
export const resetGroupType = (type: string) => {
    const groups = posthog.getGroups();
    posthog.resetGroups();
    Object.entries(groups)
        .filter(([groupType]) => groupType !== type)
        .forEach(([groupType, groupId]) => posthog.group(groupType, String(groupId)));
};
