import { Group, GroupMember, Operation } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../../common/api";
import { useOrganisationContext, useOrganisationContextDispatch } from "../../../../common/OrganisationContext";
import { createPatch } from "../../../../common/patchHelper";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import { AddGroupEmployees } from "../../../Components/AddGroupEmployees";
import { setDragData } from "../../helper";
import "./style.scss";
import { translateInviteStatus } from "../../../../sysadmin/helper";

/*  eslint-disable react-hooks/exhaustive-deps */

interface GroupMembersListProps {
    groupId: number;
    courseId: number;
}

interface GroupMemberListItem {
    groupMember: GroupMember;
}

const GroupMembersList = (props: GroupMembersListProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const groupsDispatch = useOrganisationContextDispatch();
    const accountOwnerContext = useOrganisationContext();
    const groupsInCourse = accountOwnerContext.groups;
    /* @ts-ignore */
    const group: Group = groupsInCourse?.find(x => x.id === props.groupId);
    const [usersToDisplay, setUsersToDisplay] = useState<GroupMemberListItem[]>();

    useEffect(() => {
        refreshUsersToDisplay();
    }, [group, group.members]);

    const refreshUsersToDisplay = () => {
        setUsersToDisplay(
            group.members.map(m => {
                return {
                    groupMember: m,
                };
            })
        );
    };

    const setParticipant = (member: GroupMember, isParticipant: boolean): void => {
        // To optimistic update to prevent UI lag until server response
        setUsersToDisplay(
            /* @ts-ignore */

            usersToDisplay.map(x => ({
                ...x,
                groupMember: {
                    ...x.groupMember,
                    isParticipant: x.groupMember.userId === member.userId ? isParticipant : x.groupMember.isParticipant,
                },
            }))
        );

        changeGroupMembershipStatusParticipant(member, isParticipant);
    };

    const groupMemberShipPatchRequest = (member: GroupMember, groupId: number, patch: Operation[]) => {
        return api.groupMembership.patch(member.userId, groupId, patch);
    };

    const changeGroupMembershipStatusParticipant = (member: GroupMember, isParticipant: boolean) => {
        const patch = createPatch(member, g => {
            g.isParticipant = isParticipant;
        });
        groupMemberShipPatchRequest(member, props.groupId, patch);
    };

    const changeGroupMembershipStatusGroupLeader = (member: GroupMember, isGroupLeader: boolean) => {
        const patch = createPatch(member, g => {
            g.isGroupLeader = isGroupLeader;
        });
        groupMemberShipPatchRequest(member, props.groupId, patch);
    };

    const setGroupLeader = (member: GroupMember, isGroupLeader: boolean): void => {
        // To optimistic update to prevent UI lag until server response
        setUsersToDisplay(
            /* @ts-ignore */
            usersToDisplay.map(x => ({
                ...x,
                groupMember: {
                    ...x.groupMember,
                    isGroupLeader: x.groupMember.userId === member.userId ? isGroupLeader : x.groupMember.isGroupLeader,
                },
            }))
        );
        changeGroupMembershipStatusGroupLeader(member, isGroupLeader);
    };

    const deleteGroupMember = (member: GroupMember): void => {
        api.groupMembership.delete(member.userId, props.groupId).then(
            result => {
                groupsDispatch(s => {
                    return {
                        ...s,
                        groups: [
                            /* @ts-ignore */
                            ...s.groups.map(g => {
                                if (g.id === props.groupId) {
                                    const { members, ...rest } = g;
                                    return {
                                        ...rest,
                                        members: [...members.filter(m => m.userId !== member.userId)],
                                    };
                                } else {
                                    return g;
                                }
                            }),
                        ],
                    };
                });
            },
            error => {
                console.log(error, "Error during delete membership.");
            }
        );
    };

    const removeGroupMember = (member: GroupMember, email: string): void => {
        const title = t("GROUP_MEMBER_DELETE_TITLE", { email });
        confirmAlert({
            title: title,
            message: t("COMMON_DELETE_MESSAGE"),
            buttons: [
                {
                    label: t("COMMON_YES"),
                    onClick: () => {
                        deleteGroupMember(member);
                    },
                },
                {
                    label: t("COMMON_NO"),
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    return (
        <div className="group-members-list">
            <p className="content-label mt-3">{t("RESOURCES_EMPLOYEES_GROUP")}</p>
            {usersToDisplay && usersToDisplay.length > 0 ? (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th data-field="email">
                                {t("RESOURCES_EMAIL")}/{t("MEMBERSHIP_MOBILE_PHONE")}
                            </th>
                            <th>{t("RESOURCES_PARTICIPANT")}</th>
                            <th>{t("RESOURCES_GROUPLEADER")}</th>
                            <th>{t("RESOURCES_STATUS")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersToDisplay &&
                            usersToDisplay
                                /* @ts-ignore */
                                .sort((a, b) => (a.groupMember?.user?.email < b.groupMember?.user?.email ? -1 : 1))
                                .map((emp, i) => (
                                    <tr
                                        key={i}
                                        draggable={true}
                                        onDragStart={e =>
                                            setDragData(e.dataTransfer, emp.groupMember.userId, props.groupId)
                                        }
                                        onDragEnd={e => refreshUsersToDisplay()}
                                        className="employeesData draggable"
                                    >
                                        <td style={{ width: "220px" }}>
                                            {emp.groupMember?.user?.email || emp.groupMember?.user?.mobilePhone}
                                        </td>
                                        <td className="checkbox-employees">
                                            <StyledCheckbox
                                                name={`participant${emp.groupMember.userId}`}
                                                onChange={checked => setParticipant(emp.groupMember, checked)}
                                                checked={emp.groupMember.isParticipant}
                                                //disabled={!emp.allowParticipant}
                                            />
                                        </td>
                                        <td className="checkbox-employees">
                                            <StyledCheckbox
                                                name={`groupLeader${emp.groupMember.userId}`}
                                                onChange={checked => setGroupLeader(emp.groupMember, checked)}
                                                checked={emp.groupMember.isGroupLeader}
                                            />
                                        </td>
                                        <td>
                                            {
                                                /* @ts-ignore */
                                                translateInviteStatus(t)[
                                                    /* @ts-ignore */
                                                    emp.groupMember.inviteStatus
                                                ]
                                            }
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-link p-0"
                                                onClick={() =>
                                                    /* @ts-ignore */
                                                    removeGroupMember(emp.groupMember, emp.groupMember?.user?.email)
                                                }
                                            >
                                                <i className="fa fa-times" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </Table>
            ) : (
                <p className="alert alert-secondary">{t("RESOURCES_NO_EMPLOYEES_IN_GROUP")}</p>
            )}

            {usersToDisplay && usersToDisplay.length > 0 && usersToDisplay.length < 8 && (
                <p className="alert alert-secondary">{t("RESOURCES_EMPLOYEES_IDEAL_TEXT")}</p>
            )}
            <AddGroupEmployees
                groupId={props.groupId}
                placeholder={t("RESOURCES_GROUP_MEMBERS_ADD_EMPLOYEES_PLACEHOLDER")}
                courseId={props.courseId}
            />
        </div>
    );
};

export default GroupMembersList;
