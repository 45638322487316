import { ModuleBlock } from "@/api-client";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import "../../style.scss";
import { ModuleBlockCard } from "./ModuleBlockCard";
import { NavButton } from "@/components";

interface blockProps {
    moduleId: number;
    courseId: number;
    chapterId: number;
    moduleBlocks: ModuleBlock[];
}

/*  eslint-disable react-hooks/exhaustive-deps */

const Blocks = (props: blockProps) => {
    const api = createApi();

    // @ts-ignore
    const [moduleBlocks, setModuleBlocks] = useState<ModuleBlock[]>(null);

    useEffect(() => {
        setModuleBlocks(props.moduleBlocks);
    }, [props.moduleBlocks]);

    const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
        setModuleBlocks(c =>
            update(c, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, c[dragIndex]],
                ],
            }).map((x, ix) => ({ ...x, sortIndex: ix }))
        );
    }, []);

    useEffect(() => {
        if (!moduleBlocks) {
            return;
        }

        const patchedModules = moduleBlocks.map(m => {
            const patch = createPatch(props.moduleBlocks.find(mb => mb.id === m.id)!, x => {
                x.sortIndex = m.sortIndex;
            });

            if (patch && patch.length > 0) {
                // @ts-ignore

                return api.moduleBlocks.patch(m.id, patch);
            } else {
                return Promise.reject();
            }
        });

        const allSettled = async () => {
            await Promise.allSettled(patchedModules);
        };

        allSettled().finally();
    }, [moduleBlocks]);

    const renderModuleBlock = useCallback((moduleBlock: any, index: any) => {
        return (
            <ModuleBlockCard
                key={moduleBlock.id}
                index={index}
                moveCard={moveCard}
                moduleBlock={moduleBlock}
                courseId={props.courseId}
                id={moduleBlock.id}
                chapterId={props.chapterId}
            />
        );
    }, []);

    return (
        <>
            <ContentLabel text="Blocks" />
            <NavButton
                link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/add`}
                textKey="Add Block"
            />
            <table className="items-drag-drop table table-borderless table-striped sysAdminTable table-responsive table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <DndProvider backend={HTML5Backend}>
                    <tbody className="sysadmin-drag">
                        {moduleBlocks && moduleBlocks.length > 0
                            ? moduleBlocks &&
                              moduleBlocks
                                  .filter(a => a?.status === "Active" || a?.status === "Disabled")
                                  ?.map((moduleBlock, index) => renderModuleBlock(moduleBlock, index))
                            : null}
                    </tbody>
                </DndProvider>
            </table>
        </>
    );
};

export default Blocks;
