import { ParticipantsProgressClient } from "./legacy";
import { ApiConfiguration, ParticipantProgress } from "./contracts";

export class ParticipantProgressClient {
    private participantsProgressClient: ParticipantsProgressClient;
    private config: ApiConfiguration;

    constructor(config: ApiConfiguration) {
        this.config = config;
        this.participantsProgressClient = new ParticipantsProgressClient(config);
    }

    getNumberOfUnstartedCourses = async (userId: number): Promise<number> => {
        const token = this.config.getAuthorizationHeader();
        const response = await fetch(
            `${this.config.baseUrl}/api/participants-progress/user/${userId}/unstarted-courses/count`,
            {
                mode: "cors",
                method: "get",
                headers: {
                    ...(token === null ? {} : { Authorization: token }),
                    Accept: "application/json",
                },
            }
        );

        return response.json();
    };

    query = (
        userId: number | null | undefined,
        groupId: number | null | undefined,
        moduleId: number | null | undefined,
        moduleBlockId: number | null | undefined,
        roundId: number | null | undefined
    ): Promise<ParticipantProgress[]> =>
        this.participantsProgressClient.query(userId, groupId, moduleId, moduleBlockId, roundId);
    getByUserIdAndModuleId = (
        userId: number,
        moduleId: number,
        moduleBlockId: number,
        groupId: number,
        roundId: number | null
    ): Promise<ParticipantProgress> =>
        this.participantsProgressClient.getByUserIdAndModuleId(userId, moduleId, moduleBlockId, groupId, roundId);
    putByUserIdAndModuleId = (
        userId: number,
        moduleId: number,
        moduleBlockId: number,
        groupId: number,
        roundId: number | null,
        dto: ParticipantProgress
    ): Promise<ParticipantProgress> =>
        this.participantsProgressClient.putByUserIdAndModuleId(userId, moduleId, moduleBlockId, groupId, roundId, dto);
}
