import { Group } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { PrimaryButton } from "./Button/PrimaryButton";
import { EventName } from "./Button/Button";
import { useFormContext } from "./FormContext";

type SubmitProps = {
    label: string;
    eventName?: EventName;
};

export const Submit = (props: SubmitProps) => {
    const context = useFormContext();
    const { t } = useTranslate();

    return (
        <Group position="right" mt="md">
            <PrimaryButton
                loading={context.isSubmitting}
                disabled={!context.canSubmit}
                type="submit"
                eventName={props.eventName}
            >
                {t(props.label)}
            </PrimaryButton>
        </Group>
    );
};
