import React, { FunctionComponent } from "react";
import { Col } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import { EditUserComponent } from "../../../components/Users/EditUserComponent";
import { AccountOwnerLayout } from "../../Layout";
import { EditOrganisationSidebar } from "../EditOrganisationSidebar";
import { UrlFactory } from "../../../routing/UrlFactory";
import { Flex } from "@mantine/core";
import { useParams } from "react-router-dom";

export const AoUsers = (): JSX.Element => {
    const params = useParams();
    //@ts-ignore
    const userId = parseInt(params.userId!);
    const { t } = useTranslate();
    const breadCrumb = [
        { name: t("HEADER_NAV_ORGANISATION"), path: "/account-owner/edit/organisation-planning/organisation" },
        { name: t("ACCOUNTOWNER_BREADCRUMB_EMPLOYEES"), path: "/account-owner/edit/organisation-planning/employees" },
        { name: userId ? t("ACCOUNTOWNER_BREADCRUMB_EDIT_EMPLOYEE") : "Add Employee", path: null as unknown as string },
    ];
    return (
        <AccountOwnerLayout show={false} showMainNav={true}>
            <Flex gap="xl">
                <Flex h="100%">
                    <EditOrganisationSidebar />
                </Flex>
                <Col md={9}>
                    <Breadcrumbs breadCrumb={breadCrumb} />
                    <EditUserComponent
                        userId={userId}
                        componentFor={UrlFactory.accountOwner.organisationPlanning.employees.create({})}
                    />
                </Col>
            </Flex>
        </AccountOwnerLayout>
    );
};
