import { Chapter } from "@/api-client";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import "../../style.scss";
import { RowCard } from "./RowCard";
import { NavButton } from "@/components";

/*  eslint-disable react-hooks/exhaustive-deps */

type ChapterProps = {
    courseId: number;
    chapters: Chapter[];
    isUpdated?: (val: boolean) => boolean;
};

const Chapters = (props: ChapterProps) => {
    const [chapters, setChapter] = useState(props.chapters);
    const api = createApi();

    useEffect(() => {
        setChapter(props.chapters);
    }, [props.chapters]);

    const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
        setChapter(c =>
            update(c, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, c[dragIndex]],
                ],
            }).map((x, ix) => ({ ...x, order: ix }))
        );
    }, []);

    useMemo(() => {
        const patchedChapters = chapters.map(c => {
            const chapter = props.chapters.find(chapter => chapter.id === c.id)!;
            const patch = createPatch(chapter, x => {
                x.order = c.order;
            });

            if (patch && patch.length > 0) {
                // @ts-ignore
                return api.chapters.patch(c.id, patch);
            } else {
                return Promise.reject();
            }
        });

        const allSettled = async () => {
            await Promise.allSettled(patchedChapters);
        };

        allSettled().finally();
    }, [chapters]);

    // QA: Empty deps always re-render, why wrap in callback?
    const renderChapter = useCallback((chapter: any, index: any) => {
        return (
            <RowCard
                key={chapter.id}
                index={index}
                moveCard={moveCard}
                chapter={chapter}
                courseId={props.courseId}
                id={chapter.id}
            />
        );
    }, []);

    return (
        <>
            <ContentLabel text="Chapters" />
            <NavButton link={`/sysadmin/courses/${props.courseId}/chapter/add`} textKey="Add Chapter"></NavButton>
            <table className="items-drag-drop table table-borderless table-striped sysAdminTable table-responsive table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Modules</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <DndProvider backend={HTML5Backend}>
                    <tbody>{chapters && chapters.map((chapter, index) => renderChapter(chapter, index))}</tbody>
                </DndProvider>
            </table>
        </>
    );
};

export default Chapters;
