import { Client } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useHistory } from "@/common";
import * as Yup from "yup";
import FileUploader from "../../../account-owner/Components/FileUploader";
import { createApi } from "../../../common/api";
import { useUserContext } from "../../../common/user/context";
import { UserHasOneOfRoles } from "../../../common/user/utils";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify, showToast } from "../../../components/Notify";
import { AppLoader } from "../../../components/Spinner";
import { AGENT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { CardBlock } from "../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Client Name is required"),
        maxLicenses: Yup.string().required("Licences is required"),
    });
};

interface ImageData {
    isPending: boolean;
    path: string;
}

interface AddClientResponse {
    isPending: boolean;
    client: Client;
}

const AddClient = () => {
    const api = createApi();
    const history = useHistory();
    // @ts-ignore
    const [image, setImage] = useState<string>(null);
    const sysadminVideosDispatch = useSysadminContextDispatch();
    const [saveImage, setSaveImage] = useState<ImageData>({
        // @ts-ignore
        isPending: null,
        // @ts-ignore
        path: null,
    });
    const [addClient, setAddClient] = useState<AddClientResponse>({
        // @ts-ignore
        isPending: null,
        // @ts-ignore
        client: null,
    });

    const userContext = useUserContext();
    // @ts-ignore
    const isSysadmin: boolean = UserHasOneOfRoles(userContext.user, [SYSADMIN_ROLE]);

    const initialValues = {
        name: "",
        maxLicenses: "",
        logo: "",
        accountManagerId: isSysadmin ? null : userContext?.user?.id,
    };

    // @ts-ignore
    const fileUploader = (uploadFile: File, setFieldValue) => {
        setImage(URL.createObjectURL(uploadFile));
        setSaveImage({ ...saveImage, isPending: true });

        return api.images.post({ fileName: uploadFile.name, data: uploadFile }).then(
            result => {
                setFieldValue("logo", result, false);
                setSaveImage({ ...saveImage, path: result, isPending: false });
            },
            error => {
                setSaveImage({ ...saveImage, isPending: false });
                console.log(error);
            }
        );
    };

    //@ts-ignore
    const saveClient = values => {
        setAddClient({ ...addClient, isPending: true });
        const client: Client = {
            ...values,
            status: "Active",
            createdAt: new Date(),
            updatedAt: new Date(),
            accountManagerId: values.accountManagerId, //values.ownerId ? findUser(values.ownerId) : isAgent ? userContext.user : null
        };
        api.clients.post(client).then(
            result => {
                setAddClient({ ...addClient, client: result, isPending: false });
                sysadminVideosDispatch(s => ({
                    ...s,
                    //@ts-ignore
                    clients: [...s.clients, result],
                }));
                history.push(`/sysadmin/clients/edit/${result.id}`);
                showToast("Client created successfully.", "success");
            },
            error => {
                setAddClient({ ...addClient, isPending: false });
                showToast("Something going wrong. Please try again later.", "warning");
                console.log(error);
            }
        );
    };

    return (
        <>
            {/* <Breadcrumbs breadCrumb={breadCrumb} /> */}
            <div className="row">
                {/* <div className='col-md-4'>
                 <ContentBlock>
                 <Sidebar children={sidebarChildren} name={"Clients"} activeId={2} />
                 </ContentBlock>
                 </div> */}
                {userContext?.user && (
                    <div className="col-md-12">
                        <CardBlock mb="xl">
                            <AppLoader
                                loading={saveImage.isPending || addClient.isPending ? true : false}
                                className="position-absolute"
                            />
                            {addClient.client !== null && (
                                <Fragment>
                                    <Notify message="Client added successfully." id="registersuccess" type="success" />
                                    <Navigate to="/sysadmin/clients" />
                                </Fragment>
                            )}
                            <ContentLabel text="Add Client" />
                            <Formik
                                enableReinitialize={true}
                                //@ts-ignore
                                initialValues={initialValues}
                                validationSchema={validate()}
                                onSubmit={(values: {
                                    name: string;
                                    maxLicenses: string;
                                    logo: string;
                                    accountManagerId: number;
                                }) => {
                                    saveClient(values);
                                }}
                            >
                                {formikProps => {
                                    const { errors, touched, setFieldValue } = formikProps;
                                    return (
                                        <Form action="#" name="addCourseForm" className="mt-2 editCourseForm">
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Name</label>
                                                    <Field
                                                        name="name"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (errors.name && touched.name ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="name"
                                                        component="span"
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Licence</label>
                                                    <Field
                                                        name="maxLicenses"
                                                        type="text"
                                                        className={
                                                            "maxLicenses form-control" +
                                                            (errors.maxLicenses && touched.maxLicenses
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="maxLicenses"
                                                        component="span"
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            {isSysadmin && (
                                                <Row className="mb-4">
                                                    <Col>
                                                        <label>Agent</label>
                                                        <RoleBasedUser
                                                            roleName={AGENT_ROLE}
                                                            fieldName="accountManagerId"
                                                        />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            )}
                                            <Row className="mb-4">
                                                <Col md={12}>
                                                    <label>Logo</label>
                                                    {image !== null && (
                                                        <div className="mb-2">
                                                            <img
                                                                style={{ maxWidth: "100px" }}
                                                                src={image}
                                                                alt=""
                                                                className="client-logo"
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col>
                                                    <FileUploader
                                                        acceptedFiles="image/jpg, image/png, image/jpeg"
                                                        handleFile={(uploadFile: any) =>
                                                            fileUploader(uploadFile, setFieldValue)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label className="form-label">Tags</label>
                                                    <AutocompleteTags
                                                        module={`clients`}
                                                        //@ts-ignore
                                                        defaultValue={null}
                                                        selectedValue={(value: string[]) =>
                                                            setFieldValue("tags", value)
                                                        }
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <ContentBlockButtonContainer>
                                                <PrimaryButton
                                                    type="submit"
                                                    loading={saveImage.isPending || addClient.isPending}
                                                >
                                                    Create Client
                                                </PrimaryButton>
                                            </ContentBlockButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardBlock>
                    </div>
                )}
            </div>
        </>
    );
};

export default AddClient;
