import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { TaskCategoryEditor } from "./TaskCategoryEditor";
import { TaskCategory } from "@/api-client";

interface TaskCategoryModalProps {
    category: TaskCategory;
    show: boolean;
    mode: "create" | "edit";
    handleClose: () => void;
}

export const TaskCategoryModal = (props: TaskCategoryModalProps) => {
    const { t } = useTranslate();
    const ref = useRef<HTMLDivElement>(null);

    const title =
        props.mode === "create"
            ? t("BOARDS_CATEGORY_ADD_NEW")
            : t("BOARDS_CATEGORY_EDIT_TITLE", { categoryName: props.category.name });

    return (
        <div ref={ref}>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                size={"lg"}
                centered={true}
                container={ref}
                animation={false}
            >
                <Modal.Header>
                    <h5 className="model-title">{title}</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.handleClose()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <TaskCategoryEditor
                        mode={props.mode}
                        category={props.category}
                        requestClose={() => props.handleClose()}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};
