import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "../../helpers/TrainingNavHelper";
import "./sidenav.scss";
import { LockedNav } from "./LockedNav";

export interface NavItemProps {
    item: NavItem;
    level: number;
}

export interface NavTypeProps {
    item: NavItem;
    level: number;
}

export interface DisplayChaptersProgressProps {
    progress: boolean[];
}

export interface TrainingSidebarProps {
    items: Array<NavItem> | undefined;
}

export const DisplayChaptersProgress: FunctionComponent<DisplayChaptersProgressProps> = ({
    progress,
}: DisplayChaptersProgressProps): JSX.Element => {
    return (
        <>
            {progress &&
                progress.map((p, i) => {
                    return <i key={i} className={`fas fa-circle chapter-progress progress-${p}`} />;
                })}
        </>
    );
};

const NavTypeDisplay: FunctionComponent<NavTypeProps> = ({ item, level }: NavTypeProps): JSX.Element => {
    const isActiveMenu = item.isFirstChapter || item.active;
    let nav: JSX.Element | string = "";
    if (item.status === "Disabled") {
        nav = <LockedNav text={item.title} level={level} withBullet={level > 0} />;
    } else if (level === 0) {
        // @ts-ignore
        const progressCount = item.chapterProgress.every(e => e === true);
        const linkOrText =
            (isActiveMenu || item.completed || progressCount) && item.status === "Active" ? (
                <Link to={item.link}>{item.title}</Link>
            ) : (
                <p>{item.title}</p>
            );
        nav = (
            <div className="firstLevel chapter-display-progress">
                {linkOrText}
                <div>
                    <DisplayChaptersProgress progress={item.chapterProgress as boolean[]} />
                </div>
            </div>
        );
    } else if (isActiveMenu || item.completed) {
        nav = <Link to={item.link}>{item.title}</Link>;
    } else {
        const classNameLevel = level === 1 ? "level2-create-nav" : "create-nav level3-create-nav";
        nav = <span className={classNameLevel}>{item.title}</span>;
    }
    return nav;
};

const NavItemDisplay: FunctionComponent<NavItemProps> = ({ item, level }: NavItemProps) => {
    if (item) {
        const itemClasses = [
            `navitem-level-${level}`,
            item.completed ? "state-completed" : "state-not-completed",
            item.active ? "active visited" : "",
            item.items && item.items.length > 0 ? "has-children" : "",
            item.items && item.items.find(x => x.active) ? "has-active-child" : "",
        ];
        return (
            <li className={itemClasses.join(" ")}>
                <div>
                    <span className="subtitle">{item.subtitle}</span>
                    <NavTypeDisplay item={item} level={level} />
                </div>
                {item.items && item.active && (
                    <ul>
                        {item.items.map((item, ix) => {
                            return <NavItemDisplay key={ix} item={item} level={level + 1} />;
                        })}
                    </ul>
                )}
            </li>
        );
    } else {
        return <></>;
    }
};

const TrainingSideNav: FunctionComponent<TrainingSidebarProps> = ({ items }: TrainingSidebarProps): JSX.Element => {
    return (
        <div>
            <ul className="training-sidenav">
                {items &&
                    items.map((item, ix) => {
                        return <NavItemDisplay key={ix} item={item} level={0} />;
                    })}
            </ul>
        </div>
    );
};

export default React.memo(TrainingSideNav);
