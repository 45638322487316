import React from "react";
import { Flex, Skeleton, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { ProgramProgress } from "./ProgramProgress";
import { CardBlock } from "../../../components/CardBlock";
import { GetLatestProgramsState } from "./getLatestProgramsState";
import { HomeLinkButton } from "../HomeLinkButton";
import { UrlFactory } from "../../../routing/UrlFactory";
import { exhaustiveCheck } from "../../../common/exhaustiveCheck";

const ProgramProgressSkeleton = () => (
    <Flex direction="column" rowGap="md">
        <Flex rowGap={25} direction="column">
            <Skeleton height={25} />
            <Skeleton height={25} />
        </Flex>
    </Flex>
);

const Content = (props: GetLatestProgramsState) => {
    const { t } = useTranslate();
    switch (props.type) {
        case "Loading":
            return <ProgramProgressSkeleton />;
        case "Success":
            return <ProgramProgress progress={props.programs} />;
        case "Error":
            return (
                <Flex justify="center">
                    <Text color="gray.5">{t("HOME_CANNOT_VIEW_PROGRESS")}</Text>
                </Flex>
            );
        default:
            return exhaustiveCheck(props);
    }
};

type LatestProgramsProps = {
    latestPrograms: GetLatestProgramsState;
    teamId: number;
};

export const LatestPrograms = (props: LatestProgramsProps) => {
    const { t } = useTranslate();
    return (
        <CardBlock>
            <Flex direction="column" rowGap="md">
                <Flex justify="space-between">
                    <Text size="xl">{t("HOME_LATEST_PROGRAMS")}</Text>
                </Flex>
                <Flex direction="column" mih={75} justify="center">
                    <Content {...props.latestPrograms} />
                </Flex>
                <Flex justify="flex-end">
                    <HomeLinkButton
                        textKey="HOME_PROGRAMS"
                        link={UrlFactory.team.program.create({ groupId: props.teamId })}
                    />
                </Flex>
            </Flex>
        </CardBlock>
    );
};
