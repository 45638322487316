import React from "react";
import { useTranslate } from "@tolgee/react";
import { ChartLabel, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from "react-vis";
import RenderIf from "../../../components/render-if/render-if";
import AltBlocks from "../../../components/AltBlocks";
import SlideshowBlock from "../SlideshowBlock";
import { QuestionTitle } from "../QuestionTitle";
import { usePresentationContext } from "../PresentationContext";

interface DataEntry {
    x: number;
    y: number;
}

const RatingQuestionSlide = () => {
    const { t } = useTranslate();

    const { question, module, answers } = usePresentationContext();

    const data: DataEntry[] = [];
    // @ts-ignore
    const max = question.maxAnswer ?? 10;

    for (let index = 1; index <= max; index++) {
        data.push({
            x: index,
            // @ts-ignore
            y: answers ? answers.filter(a => parseInt(a.text) === index).length : 0,
        });
    }

    // Show 10 or the number of answers if higher on Y-axis.
    const maxValue = Math.max(Math.max(...data.map(d => d.y)), 10);
    // @ts-ignore
    const containsAnswers: boolean = answers && answers.length > 0;

    const total = data
        .filter(d => d.y > 0)
        .map(d => d.y)
        .reduce((a, b) => a + b, 0);
    const average =
        total > 0
            ? data
                  .filter(d => d.y > 0)
                  .map(d => d.x * d.y)
                  .reduce((a, b) => a + b, 0) / total
            : 0;

    return (
        <>
            <AltBlocks>
                <SlideshowBlock>
                    // @ts-ignore
                    <QuestionTitle
                        // @ts-ignore
                        text={question.text}
                        // @ts-ignore
                        moduleName={module?.name}
                    />
                    <RenderIf show={!containsAnswers}>
                        <div className="alert alert-info">{t("WORKSHOP_PRESENTATION_NOANSWER")}</div>
                    </RenderIf>
                    <RenderIf show={containsAnswers}>
                        <XYPlot width={700} height={400} yDomain={[0, maxValue]} className="mb-5 mx-auto">
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis
                                tickFormat={v => parseInt(v).toString()}
                                tickValues={Array.from({ length: max }, (_, i) => i + 1)}
                            />
                            <YAxis
                                tickFormat={v => parseInt(v).toString()}
                                tickValues={Array.from({ length: maxValue }, (_, i) => i + 1)}
                            />
                            <VerticalBarSeries
                                className="area-series-example"
                                barWidth={0.7}
                                // Curve is missing from @types
                                // @ts-ignore
                                //curve="curveMonotoneX"
                                data={data}
                            />
                            <ChartLabel
                                text={t("WORKSHOP_RATING_XY_RATING")}
                                className="alt-x-label"
                                includeMargin={false}
                                xPercent={0.0}
                                yPercent={1.12}
                            />

                            <ChartLabel
                                text={t("WORKSHOP_RATING_XY_NUMBEROFANSWER")}
                                className="alt-y-label"
                                includeMargin={false}
                                xPercent={0.0}
                                yPercent={0.01}
                                style={{
                                    transform: "rotate(-90)",
                                    textAnchor: "end",
                                }}
                            />
                        </XYPlot>

                        <p className="text-center">
                            {t("WORKSHOP_PRESENTATION_AVERAGE", {
                                average: Math.round(average * 100) / 100,
                                total: total,
                            })}
                        </p>
                    </RenderIf>
                </SlideshowBlock>
            </AltBlocks>
        </>
    );
};

export default RatingQuestionSlide;
