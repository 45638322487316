import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { TFunction } from "@/common";
import React, { useState } from "react";
import { useTranslate, useTolgee } from "@tolgee/react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { createApi } from "../../common/api";
import { showToast } from "../../components/Notify";
import { AppLoader } from "@/components";
import { Languages } from "../../translation";
import { hashPassword } from "./helper";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { useAuth } from "../../auth/useAuth";
import { showLoginErrorNotification } from "../../components/showLoginErrorNotification";
import { customEvents } from "../../analytics/customEvents/customEvents";

const loginSchema = (t: TFunction) => {
    return Yup.object().shape({
        password: Yup.string()
            .min(4, t("LOGIN_PASSWORD_SHORT"))
            .max(50, t("LOGIN_PASSWORD_LONG"))
            .required(t("LOGIN_PASSWORD_REQUIRED")),
        email: Yup.string().email(t("LOGIN_EMAIL_VALID")).required(t("LOGIN_EMAIL_REQUIRED")),
        selectLanguage: Yup.string().required(t("LOGIN_LANGUAGE_REQUIRED")),
    });
};

interface SubmitFormValues {
    email: string;
    password: string;
    selectLanguage: string;
}

export const SignInWithEmail = (): JSX.Element | null => {
    const { t } = useTranslate();
    const tolgee = useTolgee(["language"]);
    const api = createApi();
    const [isPending, setIsPending] = useState<boolean>(false);
    const { signIn, refresh } = useAuth();

    const userLogin = (values: SubmitFormValues) => {
        setIsPending(true);

        api.login.login({ email: values.email, password: hashPassword(values.password) }).then(
            async loginResponse => {
                await tolgee.changeLanguage(values.selectLanguage);

                if (loginResponse.resultCode === "Ok") {
                    const user = loginResponse.data;
                    const { token } = loginResponse;

                    const redirect = await signIn(token);

                    const data = [
                        {
                            op: "replace",
                            path: "language",
                            value: values.selectLanguage,
                        },
                    ];

                    if (user.id != null) {
                        await api.users.patch(user.id, data).then(
                            updateLangResp => {
                                // Set user in cookie
                                refresh(updateLangResp);
                                redirect();
                            },
                            () => {
                                customEvents.raiseChangeLanguageFailed(values.selectLanguage, user);
                            }
                        );
                    }
                    customEvents.raiseLoginWithEmailSuccess(values.email);
                } else {
                    showLoginErrorNotification(loginResponse, t);
                    customEvents.raiseLoginWithEmailFailed(values.email, loginResponse);
                }

                setIsPending(false);
            },
            (reason: Response) => {
                showToast(t("COMMON_DEFAULT_ERROR"), "error");
                customEvents.raiseDefaultError({ reason: reason, email: values.email });
                setIsPending(false);
            }
        );
    };

    const getLanguageFromTolgee = tolgee.getLanguage();
    return (
        <>
            <AppLoader loading={isPending} />
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    selectLanguage: getLanguageFromTolgee || "",
                }}
                validationSchema={loginSchema(t)}
                enableReinitialize={false}
                onSubmit={userLogin}
            >
                {(
                    props: FormikProps<{
                        email: string;
                        password: string;
                        selectLanguage: string;
                    }>
                ) => {
                    const { errors, touched, values } = props;

                    if (values.selectLanguage !== getLanguageFromTolgee) {
                        tolgee.changeLanguage(values.selectLanguage);
                    }

                    return (
                        <Form className="border border-light p-4" autoComplete="off">
                            <div className="mb-4">
                                <label>{t("LOGIN_EMAIL")}</label>
                                <Field
                                    tabIndex={1}
                                    type="email"
                                    name="email"
                                    //defaultValue={email}
                                    autoComplete="off"
                                    placeholder="Email"
                                    className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                />
                                <ErrorMessage
                                    className="clearfix w-100 text-danger text-start displayblock"
                                    name="email"
                                    component="span"
                                />
                            </div>
                            <div className="mb-4">
                                <div className="email-label">
                                    <label>{t("LOGIN_PASSWORD")}</label>
                                    <Link tabIndex={5} to={`/reset-password`}>
                                        {t("LOGIN_FORGOT_PASSWORD_LINK")}
                                    </Link>
                                </div>
                                <Field
                                    tabIndex={2}
                                    type="password"
                                    name="password"
                                    autoComplete="new-password"
                                    placeholder="Password"
                                    className={
                                        "form-control" + (errors.password && touched.password ? " is-invalid" : "")
                                    }
                                />
                                <ErrorMessage
                                    className="clearfix w-100 text-danger text-start displayblock"
                                    name="password"
                                    component="span"
                                />
                            </div>
                            <div className="mb-4">
                                <label>{t("LOGIN_SELECT_LANGUAGE")}</label>
                                <Field
                                    tabIndex={3}
                                    className={
                                        "form-select" +
                                        (errors.selectLanguage && touched.selectLanguage ? " is-invalid" : "")
                                    }
                                    as="select"
                                    name="selectLanguage"
                                >
                                    <option value="">{t("LOGIN_SELECT_LANGUAGE")}</option>
                                    {Languages.map((l, i) => {
                                        return (
                                            <option key={i} value={l.value}>
                                                {t(l.name)}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage
                                    className="clearfix w-100 text-danger text-start displayblock"
                                    name="selectLanguage"
                                    component="span"
                                />
                            </div>
                            <PrimaryButton
                                fullWidth
                                type="submit"
                                disabled={errors.email || errors.password ? true : false}
                                loading={isPending}
                                tabIndex={4}
                            >
                                {t("LOGIN_SIGNIN_BUTTON")}
                            </PrimaryButton>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
