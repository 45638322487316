import { Course } from "@/api-client";
import React, { Fragment } from "react";
import { useTranslate } from "@tolgee/react";
import { ContentLabel } from "../../../../components/ContentLabel";
import { TrainingProgressCard } from "../../../../components/Progress";
import RenderIf from "../../../../components/render-if/render-if";
import { AppLoader, NavButton } from "@/components";
import { MapTrainingWithCourseModel } from "../../Model";
import { ActionIcon, createStyles, Menu, Space } from "@mantine/core";
import { Icons } from "../../../../common/Icons";

const useStyles = createStyles(() => ({
    buttonContainer: {
        display: "flex",
        position: "absolute",
        bottom: "-22px",
        left: 0,
        right: 0,
        margin: "auto",
        justifyContent: "center",
    },
}));

const TrainingInProgress = (props: {
    courseId: number;
    isPending: boolean;
    groupWithProgress: MapTrainingWithCourseModel[];
    course: Course;
    handleTraining: (groupId: number, courseId: number) => void;
}) => {
    const { classes } = useStyles();
    const { t } = useTranslate();
    return (
        <Fragment>
            <AppLoader loading={props.isPending} className="position-absolute" />
            <h2 className="mb-4">{props.course?.name}</h2>
            <NavButton
                link={`/account-owner/resources/plan-course-group-members/${props.courseId}`}
                textKey={"ACCOUNTOWNER_MANAGER_GROUP_PARTICIPANTS"}
            ></NavButton>
            <Space h="xl" />
            <ContentLabel text={t("ACCOUNTOWNER_TRAINING_IN_PROGRESS")} />
            <table className="table table-borderless table-striped">
                <thead>
                    <tr className="border-bottom">
                        <th>{t("ACCOUNTOWNER_TRAINING_PROGRESS_GROUP")}</th>
                        <th>{t("ACCOUNTOWNER_TRAINING_PROGRESS_GROUP_LEADERS")}</th>
                        <th>{t("ACCOUNTOWNER_TRAINING_PROGRESS")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.groupWithProgress &&
                        props.groupWithProgress.length > 0 &&
                        props.groupWithProgress.map((p, i) => {
                            return (
                                <tr key={`progress-per-group-${i}`}>
                                    <td>{p.groupName}</td>
                                    <td>{p.groupLeader}</td>
                                    <td className="whitespace-nowrap">
                                        <div className="progress-container">
                                            {p.progress.map((p, i) => {
                                                return (
                                                    <React.Fragment key={`progress-display-${i}`}>
                                                        <TrainingProgressCard progress={p} />
                                                        <span style={{ marginRight: "10px" }}></span>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </td>
                                    <td>
                                        <Menu id="sysadmin-dropdown">
                                            <Menu.Target>
                                                <ActionIcon>
                                                    <Icons.ContextMenu />
                                                </ActionIcon>
                                            </Menu.Target>

                                            <Menu.Dropdown>
                                                <Menu.Item onClick={() => props.handleTraining(p.groupId, p.courseId)}>
                                                    {t("ACCOUNTOWNER_MYORGANISATION_GROUP_LEADER_VIEW")}
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <RenderIf
                show={props.isPending === false && props.groupWithProgress && props.groupWithProgress.length === 0}
            >
                <p className="organisation-not-found text-center mb-5">{t("ACCOUNTOWNER_GROUP_REPORT_NOT_FOUND")}</p>
            </RenderIf>
        </Fragment>
    );
};
export default TrainingInProgress;
