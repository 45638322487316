import { SimpleGrid, Text, Stack, UnstyledButton } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { NavLink } from "react-router-dom";
import { accountOwnerNavigationItems } from "components/Layout/AccountOwner/accountOwnerNavigationItems";
import { CardBlock } from "@/components";
import { Button } from "../../components/Button/Button";
import { EditOrganisation } from "./Organization/EditOrganisation";

type NavigationCardProps = { title: string; description: string; to: string };

const NavigationCard = (props: NavigationCardProps) => {
    const { t } = useTranslate();

    return (
        <CardBlock>
            <Stack>
                <Text>{t(props.title)}</Text>
                <Text fz="xs" c="dimmed" mt={7}>
                    {t(props.description)}
                </Text>
                <UnstyledButton variant="outline" component={NavLink} to={props.to}>
                    <Button size="xs" variant="outline">
                        {t("ACCOUNTOWNER_HOME_MANAGE")}
                    </Button>
                </UnstyledButton>
            </Stack>
        </CardBlock>
    );
};

const NavigationCards = () => {
    return (
        <>
            {accountOwnerNavigationItems.map(item => (
                <NavigationCard to={item.to} title={item.label} description={item.description} key={item.label} />
            ))}
        </>
    );
};

export const AccountOwnerNavigationGrid = () => {
    return (
        <SimpleGrid
            spacing="1.5rem"
            breakpoints={[
                { maxWidth: "md", cols: 2 },
                { maxWidth: "xs", cols: 1 },
            ]}
            cols={3}
        >
            <NavigationCards />
        </SimpleGrid>
    );
};

export const AccountOwnerHomePage = () => {
    return (
        <Stack spacing="1.5rem">
            <EditOrganisation />
            <AccountOwnerNavigationGrid />
        </Stack>
    );
};
