import { Flex, Text } from "@mantine/core";
import { useStyles } from "./taskPreviewStyles";

type RowProps = {
    label: string;
    text: string;
};
export const Row = (props: RowProps) => {
    const { classes } = useStyles();

    return (
        <Flex className={classes.row}>
            <Text fw="bold">{props.label}</Text>
            <Text>{props.text}</Text>
        </Flex>
    );
};
