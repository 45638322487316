import React from "react";
import "./styles.scss";
import { Droppable } from "@hello-pangea/dnd";
import { DroppableId, serializeDroppableId } from "../../dragAndDrop";
import { ImprovementBoardTask } from "@/api-client";

interface TaskStackProps {
    tasks: ImprovementBoardTask[];
    onClick: () => void;
    droppableId: DroppableId;
}

export const TaskStack = (props: TaskStackProps) => {
    return (
        <Droppable droppableId={serializeDroppableId(props.droppableId)}>
            {provided => (
                <div ref={provided.innerRef} className="task-stack-container" {...provided.droppableProps}>
                    <div onClick={() => props.onClick()}>{props.tasks.length}</div>
                </div>
            )}
        </Droppable>
    );
};
