import { useTranslate } from "@tolgee/react";
import { AdditionalResource } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import RenderIf from "../../components/render-if/render-if";
import { AppLoader } from "../../components/Spinner";
import { CoursesLayout } from "../courses/CoursesLayout";
import { useCurrentCourseFromParams } from "../courses/helper";
import { BlockPreview } from "./components/BlockPreview";
import { ChaptersPreview } from "./components/ChaptersPreview";
import PreviewNav from "./components/PreviewNav";
import { PreviewNavHelper } from "./components/PreviewNav/helper";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    courseId?: string;
    chapterId?: string;
    moduleBlockId?: string;
    moduleId?: string;
    key?: string;
};

const CoursePreview = (): JSX.Element => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore
    const chapterId = parseInt(params.chapterId);
    //@ts-ignore
    const moduleBlockId = parseInt(params.moduleBlockId);
    //@ts-ignore
    const moduleId = parseInt(params.moduleId);
    const chapterDetail = params.key;
    const api = createApi();

    const [isCourseValidForPreview, setIsCourseValidForPreview] = useState<boolean>(false);
    const [course] = useCurrentCourseFromParams();
    const nav = PreviewNavHelper(course, "sysadmin/course-preview", location);
    useEffect(() => {
        //dispatch(fetchAdminCourseAction( courseId ));
    }, []);

    const breadCrumb = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name ? course.name : "---", path: `/sysadmin/courses/${courseId}/edit` },
        { name: "Preview", path: null },
    ];

    useEffect(() => {
        if (course !== null && course && course.chapters) {
            const sortCourse = course?.chapters
                ?.filter(c => c.status === "Active")
                //@ts-ignore
                .sort((a, b) => a?.order - b?.order)
                .map(chapter =>
                    chapter?.modules
                        ?.filter(c => c?.status === "Active")
                        .sort(
                            (
                                a,
                                b
                                //@ts-ignore
                            ) => a?.order - b?.order
                        )
                        .map(a => {
                            return {
                                chapterId: chapter?.id,
                                moduleId: a.id,
                            };
                        })
                )
                .flat();
            setIsCourseValidForPreview(true);
            if (chapterId && (moduleId === undefined || moduleBlockId === undefined)) {
                if (sortCourse && sortCourse.length > 0 && sortCourse[0].chapterId && sortCourse[0].moduleId) {
                    setIsCourseValidForPreview(true);
                    history.push(
                        `/sysadmin/course-preview/${course.id}/chapter/${sortCourse[0].chapterId}/chapter-detail`
                    );
                } else {
                    setIsCourseValidForPreview(false);
                }
            }
            const hasCourseId = courseId ? true : false;
            if (hasCourseId && isNaN(chapterId) && isNaN(moduleId) && isNaN(moduleBlockId)) {
                if (sortCourse && sortCourse.length > 0 && sortCourse[0].chapterId && sortCourse[0].moduleId) {
                    setIsCourseValidForPreview(true);
                    history.push(
                        `/sysadmin/course-preview/${course.id}/chapter/${sortCourse[0].chapterId}/chapter-detail`
                    );
                }
            }
        }
    }, [course, chapterId, moduleId, moduleBlockId]);

    //@ts-ignore
    const [additionalResources, setAdditionalResources] = useState<AdditionalResource[]>(null);

    //Temp solution for getting the Additional Resources
    useEffect(() => {
        api.additionalResources.query(null, null).then(result => {
            setAdditionalResources(result);
        });
    }, []);

    return (
        <>
            <CoursesLayout>
                <AppLoader loading={course === null ? true : false} />

                {
                    //@ts-ignore
                    course && <Breadcrumbs breadCrumb={breadCrumb} />
                }
                <RenderIf show={course !== null && !isCourseValidForPreview}>
                    <div className="alert alert-danger">
                        Course is not available for preview. A Course should have One Chapter, One Module & One Block.
                    </div>
                </RenderIf>
                <Row>
                    <Col md={4}>
                        <PreviewNav items={nav} />
                    </Col>
                    <Col md={8}>
                        {isCourseValidForPreview && (
                            <React.Fragment>
                                <RenderIf show={chapterDetail ? true : false}>
                                    <ChaptersPreview course={course} chapterId={chapterId} />
                                </RenderIf>
                                <RenderIf show={chapterId && moduleBlockId ? true : false}>
                                    <BlockPreview
                                        course={course}
                                        courseId={courseId}
                                        chapterId={chapterId}
                                        moduleBlockId={moduleBlockId}
                                        moduleId={moduleId}
                                        additionalResources={additionalResources}
                                    />
                                </RenderIf>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </CoursesLayout>
        </>
    );
};

export default CoursePreview;
