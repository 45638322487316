import { useTranslate } from "@tolgee/react";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import { useHistory } from "@/common";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { TFunction } from "@/common";
import { Department, User } from "@/api-client";
import { Col, Container, Row, Stack } from "react-bootstrap";
import FileUploader from "../../account-owner/Components/FileUploader";
import {
    useDemographicData,
    useDemographicQuestions,
    useOrganisationContextDispatch,
} from "../../common/OrganisationContext";
import { createPatch } from "../../common/patchHelper";
import { createApi } from "@/common";
import { QuestionsWithAnswers } from "../../onboarding/DemographicQuestionsPage";
import { DemographicQuestionsForm } from "../../onboarding/Components/demograhicQuestions";
import { combineDemographicQuestionsWithAnswers, saveDemographicDataForUser } from "../../onboarding/helper";
import { useSysadminContextDispatch } from "../../sysadmin/SysadminContext";
import { ContentLabel } from "../ContentLabel";
import { showToast } from "../Notify";
import RenderIf from "../render-if/render-if";
import { AppLoader } from "../Spinner";
import { Membership } from "./Membership";
import { EditUserRolesAndStatus } from "./EditUserRolesAndStatus";
import { PrimaryButton, SecondaryButton } from "@/components";
import { ContentBlockButtonContainer } from "../ContentBlock/ContentBlockButtonContainer";
import { CardBlock } from "../CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = (t: TFunction) =>
    Yup.object().shape({
        email: Yup.string().email(t("PROFILE_INVALID_EMAIL")).required(t("PROFILE_EMAIL_REQUIRED")),
        status: Yup.string().required("Status is required"),
    });

const validateForAdd = (t: TFunction) =>
    Yup.object().shape({
        firstName: Yup.string().required(t("ACCOUNTOWNER_UPDATE_FIRST_NAME_ERROR")).nullable(),
        lastName: Yup.string().required(t("ACCOUNTOWNER_UPDATE_LAST_NAME_ERROR")).nullable(),
        email: Yup.string().email(t("PROFILE_INVALID_EMAIL")).required(t("PROFILE_EMAIL_REQUIRED")),
        status: Yup.string().required("Status is required"),
    });

interface ImageData {
    isPending: boolean;
    path: string;
}

type UsersSharedComponentProps = {
    userId?: number;
    componentFor?: string;
};

type DepartmentStateType = {
    departments: Department[];
    isPending: boolean;
};

const ProfileImage = (props: {
    label: string;
    image: string;
    user: User;
    /* @ts-ignore */
    handleFile: (uploadFile) => Promise<void>;
    onClick: () => void;
    deleteLabel: string;
}) => (
    <Container>
        <Row className="justify-content-end">
            <Col className="text-left" md={"auto"}>
                <label>{props.label}</label>
                {props.image !== null && (
                    <div>
                        <img style={{ maxHeight: "100px" }} src={props.image} alt="Profile" className="client-logo" />
                    </div>
                )}
            </Col>
            <Col className="text-left mt-4" md={"auto"}>
                <Stack gap={2}>
                    <FileUploader acceptedFiles="image/jpg, image/png, image/jpeg" handleFile={props.handleFile} />

                    {props.image !== null && (
                        <SecondaryButton onClick={props.onClick}>{props.deleteLabel}</SecondaryButton>
                    )}
                </Stack>
            </Col>
        </Row>
    </Container>
);

export function EditUserComponent(props: UsersSharedComponentProps) {
    const history = useHistory();
    const api = createApi();
    const { t } = useTranslate();
    const accountOwnerDispatch = useOrganisationContextDispatch();
    const sysadminDispatch = useSysadminContextDispatch();
    /* @ts-ignore */
    const [user, setUser] = useState<User>(null);
    const demographicQuestions = useDemographicQuestions();
    /* @ts-ignore */
    const [image, setImage] = useState<string>(null);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [saveImage, setSaveImage] = useState<ImageData>({
        isPending: false,
        /* @ts-ignore */
        path: null,
    });
    const [departmentsState, setDepartmentsState] = useState<DepartmentStateType>({
        isPending: false,
        /* @ts-ignore */
        departments: null,
    });

    const pendingChanges = () => {
        return saveImage.isPending || departmentsState.isPending;
    };

    useEffect(() => {
        if (props.userId) {
            getUserDetails(props.userId);
        }
        fetchUserDepartments();
    }, []);

    const fetchUserDepartments = () => {
        setDepartmentsState({ ...departmentsState, isPending: true });
        api.departmentsClient.query(null).then(
            response => {
                setDepartmentsState({ ...departmentsState, departments: response });
            },
            error => {
                setDepartmentsState({ ...departmentsState, isPending: false });
                console.log(error, "Error in getting departments.");
            }
        );
    };
    /* @ts-ignore */
    const getUserDetails = userId => {
        api.users.get(userId).then(
            response => {
                setUser(response);
                /* @ts-ignore */
                setImage(response.image);
            },
            error => {
                console.log(error, "Error in getting user details.");
            }
        );
    };

    const initialValues: User = {
        firstName: props.userId ? user?.firstName : "",
        lastName: props.userId ? user?.lastName : "",
        /* @ts-ignore */
        email: props.userId ? user?.email : null,
        /* @ts-ignore */
        departmentId: props.userId ? user?.departmentId : null,
        status: props.userId ? user?.status : "Active",
        roles: props.userId ? user?.roles : [],
        title: props.userId ? user?.title : "",
        /* @ts-ignore */
        mobilePhone: props.userId ? user?.mobilePhone : null,
        hasAcceptedInvite: false,
    };

    /* @ts-ignore */
    const fileUploader = (uploadFile: File, setFieldValue) => {
        setImage(URL.createObjectURL(uploadFile));
        setSaveImage({ ...saveImage, isPending: true });

        return api.images.post({ fileName: uploadFile.name, data: uploadFile }).then(
            result => {
                setFieldValue("image", result, false);
                setSaveImage({ ...saveImage, path: result, isPending: false });
            },
            error => {
                setSaveImage({ ...saveImage, isPending: false });
                console.log(error);
            }
        );
    };

    const updateUser = (values: User) => {
        setLoadingData(true);
        let patch = createPatch(initialValues, x => {
            x.firstName = values.firstName;
            x.lastName = values.lastName;
            x.image = values.image;
            x.departmentId = values.departmentId;
            x.roles = values.roles;
            x.title = values.title;
            x.mobilePhone = values.mobilePhone;
            x.email = values.email;
        });

        /* @ts-ignore */
        api.users.patch(props.userId, patch).then(
            result => {
                setUser(result);
                if (props.componentFor === "sysadmin") {
                    sysadminDispatch(s => ({
                        ...s,
                        /* @ts-ignore */
                        users: s.users.map(u => (u.id === result.id ? { ...result } : u)),
                    }));
                } else {
                    accountOwnerDispatch(s => ({
                        ...s,
                        /* @ts-ignore */
                        users: s.users.map(u => (u.id === result.id ? { ...result } : u)),
                    }));
                }
                setLoadingData(false);
                showToast(t("ACCOUNT_OWNER_USERS_UPDATE_SUCCESS_MESSAGE"), "success");
                history.push(`/${props.componentFor}/users/edit/${props.userId}`);
            },
            error => {
                setLoadingData(false);
                console.log(error);
            }
        );
    };

    const addUser = (values: User) => {
        setLoadingData(true);
        api.users.post(values).then(
            result => {
                if (props.componentFor === "sysadmin") {
                    sysadminDispatch(s => ({
                        ...s,
                        /* @ts-ignore */
                        users: [...s.users, result],
                    }));
                } else {
                    accountOwnerDispatch(s => ({
                        ...s,
                        /* @ts-ignore */
                        users: [...s.users, result],
                    }));
                }
                setLoadingData(false);
                showToast(t("ACCOUNTOWNER_USER_SUCCESSFULLY_CREATED", "User successfully created!"), "success");
                history.push(`/${props.componentFor}/users/edit/${result.id}`);
            },
            error => {
                setLoadingData(false);
                console.log(error);
            }
        );
    };

    const removeImage = () => {
        /* @ts-ignore */
        setImage(null);
    };

    return (
        <>
            <CardBlock mb="xl">
                <Container>
                    <Row>
                        <Col md={9}>
                            <ContentLabel
                                text={props.userId ? t("ACCOUNTOWNER_BREADCRUMB_EDIT_EMPLOYEE") : "Add Employee"}
                            />
                        </Col>
                    </Row>
                </Container>
                <AppLoader className="position-absolute" loading={loadingData} />
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={props.userId ? validate(t) : validateForAdd(t)}
                    onSubmit={(values, helpers) => {
                        if (props.userId) {
                            updateUser(values);
                        } else {
                            addUser(values);
                        }
                    }}
                >
                    {formikProps => {
                        const { errors, touched, setFieldValue } = formikProps;
                        return (
                            <Form action="#" name="addCourseForm" className="mt-2 editCourseForm">
                                <Container fluid>
                                    <Row>
                                        <Col className="col-6">
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>{t("ACCOUNTOWNER_UPDATE_FORM_TITLE")}</label>
                                                    <Field
                                                        name="title"
                                                        type="text"
                                                        className={`form-control${
                                                            errors.title && touched.title ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    {errors.title && (
                                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                                            {" "}
                                                            {errors.title}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>{t("ACCOUNTOWNER_UPDATE_FORM_FIRST_NAME")}</label>
                                                    <Field
                                                        name="firstName"
                                                        type="text"
                                                        className={`form-control${
                                                            errors.firstName && touched.firstName ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    {errors.firstName && (
                                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                                            {" "}
                                                            {errors.firstName}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>{t("ACCOUNTOWNER_UPDATE_FORM_LAST_NAME")}</label>
                                                    <Field
                                                        name="lastName"
                                                        type="text"
                                                        className={`form-control${
                                                            errors.lastName && touched.lastName ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    {errors.lastName && (
                                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                                            {" "}
                                                            {errors.lastName}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>{t("ACCOUNTOWNER_UPDATE_FORM_EMAIL")}</label>
                                                    <Field
                                                        name="email"
                                                        type="text"
                                                        className={`form-control${
                                                            errors.email && touched.email ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    {errors.email && (
                                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                                            {" "}
                                                            {errors.email}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>{t("ACCOUNTOWNER_UPDATE_FORM_MOBILEPHONE")}</label>
                                                    <PhoneInput
                                                        placeholder={t("LOGIN_WITH_PHONE_PLACEHOLDER")}
                                                        autoComplete={"off"}
                                                        country={"SE"}
                                                        international={true}
                                                        defaultCountry={"SE"}
                                                        smartCaret={true}
                                                        value={initialValues.mobilePhone}
                                                        name="mobilePhone"
                                                        readOnly={false}
                                                        rules={{ required: false, isPossiblePhoneNumber: true }}
                                                        onChange={val => {
                                                            formikProps.setFieldValue("mobilePhone", val);
                                                            formikProps.setErrors({ mobilePhone: "" });
                                                        }}
                                                        countryCallingCodeEditable={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <ProfileImage
                                                    label={t("ACCOUNTOWNER_UPDATE_FORM_IMAGE")}
                                                    image={image}
                                                    user={user}
                                                    handleFile={uploadFile => fileUploader(uploadFile, setFieldValue)}
                                                    onClick={() => removeImage()}
                                                    deleteLabel={t("COMMON_DELETE")}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                                <ContentBlockButtonContainer>
                                    <PrimaryButton type="submit" disabled={pendingChanges()} loading={loadingData}>
                                        {props.userId ? t("COMMON_UPDATE") : "Add Employee"}
                                    </PrimaryButton>
                                </ContentBlockButtonContainer>
                            </Form>
                        );
                    }}
                </Formik>
            </CardBlock>
            <CardBlock mb="xl">
                <Row>
                    <Col md={9}>
                        <ContentLabel text={props.userId ? t("ACCOUNTOWNER_UPDATE_FORM_STATUS") : "STATUS"} />
                    </Col>
                </Row>
                <Row>
                    <EditUserRolesAndStatus
                        /* @ts-ignore */
                        userId={props.userId}
                        onUserDeleted={() => history.push(props.componentFor!)}
                    />
                </Row>
            </CardBlock>
            <RenderIf show={props.userId !== undefined}>
                <CardBlock mb="xl">
                    <Membership
                        // @ts-ignore
                        clientId={user?.clientId}
                        // @ts-ignore
                        userId={props?.userId}
                        componentFor="account-owner"
                    />
                </CardBlock>
            </RenderIf>
            {demographicQuestions !== undefined && <DemographicDataEditorDisplay user={user} />}
        </>
    );
}

interface DemographicDataEditorDisplayProps {
    user: User;
}

const DemographicDataEditorDisplay = (props: DemographicDataEditorDisplayProps) => {
    const { t } = useTranslate();
    const [questionsWithAnswers, setQuestionsWithAnswers] = useState<QuestionsWithAnswers[]>();
    const demographicQuestions = useDemographicQuestions();
    const demographicData = useDemographicData();
    const organisationDispatch = useOrganisationContextDispatch();

    const api = createApi();

    useEffect(() => {
        if (demographicQuestions && demographicData && props.user) {
            setQuestionsWithAnswers(
                /* @ts-ignore */
                combineDemographicQuestionsWithAnswers(props.user.id, demographicQuestions, demographicData)
            );
        }
    }, [demographicData, demographicQuestions, props.user]);

    const save = () => {
        /* @ts-ignore */
        saveDemographicDataForUser(props.user.id, questionsWithAnswers, api.demographicAnswers).then(
            savedAnswers => {
                // Dispatch update (this can be automated by api client in the future)
                organisationDispatch(s => ({
                    ...s,
                    /* @ts-ignore */
                    demographicData: [...s.demographicData.filter(dd => dd.userId !== props.user.id), ...savedAnswers],
                }));

                showToast("Demographic data saved successfully.", "success");
            },
            error => {
                // TODO: Show error message
                showToast("Demographic data failed to save, please try again.", "error");
            }
        );
    };

    // TODO: Fix translations so demographic questions are not prefixed with ONBOARDING

    return (
        <>
            <CardBlock mb="xl">
                <ContentLabel text={t("ONBOARDING_DEMOGRAPHIC_SUB_HEADING")} />
                <DemographicQuestionsForm
                    /* @ts-ignore */
                    userId={props.user?.id}
                    /* @ts-ignore */
                    questionsWithAnswers={questionsWithAnswers}
                    setQuestionsWithAnswers={setQuestionsWithAnswers}
                />
                <ContentBlockButtonContainer>
                    <PrimaryButton onClick={save}>{t("COMMON_SAVE")}</PrimaryButton>
                </ContentBlockButtonContainer>
            </CardBlock>
        </>
    );
};
