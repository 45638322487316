import { createStyles } from "@mantine/core";
import {
    IconClipboardCheck,
    IconClipboardCopy,
    IconInfoCircle,
    IconSettings,
    IconUser,
    IconHexagons,
    IconLock,
    IconX,
    IconMinusVertical,
    IconLogin,
    IconAt,
    IconPhone,
} from "@tabler/icons-react";

/* https://tabler-icons.io/ */

const useIconStyles = createStyles(() => ({
    completed: {
        color: "#5cb85c !important",
    },
    started: {
        color: "#f0ad4e !important",
    },
    notStarted: {
        color: "#edeef3 !important",
    },
}));

export const Icons = {
    ZoomIn: () => <i className="fa-light fa-magnifying-glass-plus"></i>,
    ZoomOut: () => <i className="fa-light fa-magnifying-glass-minus"></i>,
    FullScreen: () => <i className="fa-light fa-expand" />,
    ExitFullScreen: () => <i className="fa-light fa-compress" />,
    Edit: () => <i className="fa-regular fa-pen" />,
    Delete: () => <i className="fa-regular fa-trash-can" />,
    FilterList: () => <i className="fa-light fa-filter-list" />,
    Add: () => <i className="fa-solid fa-plus"></i>,
    Settings: () => <i className="fa-solid fa-gear"></i>,
    SettingsInNav: IconSettings,
    Envelope: ({ classes }: { classes: string }) => <i className={`fa fa-envelope ${classes}`}></i>,
    Exclamation: ({ classes }: { classes: string }) => <i className={`fa fa-exclamation ${classes}`}></i>,
    ContextMenu: () => <i className="fa-solid fa-ellipsis-vertical"></i>,
    RightArrow: () => <i className="fa-solid fa-arrow-right"></i>,
    Cross: () => <i className="fa fa-times" />,
    Check: () => <i className="fa fa-check" />,
    SignIn: () => <IconLogin />,
    ClipBoardCopy: IconClipboardCopy,
    ClipBoardCheck: IconClipboardCheck,
    ProgramProgress: {
        Completed: () => {
            const { classes } = useIconStyles();
            return <i className={`fas fa-circle ${classes.completed}`} />;
        },
        InProgress: () => {
            const { classes } = useIconStyles();
            return <i className={`fas fa-circle ${classes.started}`} />;
        },
        NotStarted: () => {
            const { classes } = useIconStyles();
            return <i className={`fas fa-circle ${classes.notStarted}`} />;
        },
    },
    Info: IconInfoCircle,
    User: IconUser,
    Modules: IconHexagons,
    Lock: IconLock,
    X: IconX,
    Divider: IconMinusVertical,
    At: IconAt,
    Phone: IconPhone,
};
