import _ from "lodash";
import { DisplayModes } from "./components/ActionBar/ActionBar";
import { TasksByStatus, TasksBySwimLane, InternalLoadedBoardContextState, SwimLaneTasks } from "./types";
import { ImprovementBoardTask, TaskCategory, TaskPriority, User } from "@/api-client";
import { exhaustiveCheck } from "../../common/exhaustiveCheck";

export const getFilteredAndGroupedTasks = (state: InternalLoadedBoardContextState) => {
    const enabledCategories = state.categoryFilters.items.filter(x => x.enabled).map(f => f.item);

    const set = new Set(enabledCategories.map(x => x.id));

    const filteredTasks = state.tasks
        .filter(x => x.groupId === state.group?.id)
        .filter(x => {
            if (!x.taskCategoryId) {
                return state.categoryFilters.nullItemsEnabled;
            }

            return set.has(x.taskCategoryId);
        });

    return groupTasksBySwimLane(filteredTasks, state.activeUsers, enabledCategories);
};

export const getTasksBySwimLane = (
    displayMode: DisplayModes,
    tasks: ImprovementBoardTask[],
    users: User[],
    categories: TaskCategory[]
): SwimLaneTasks => {
    const getProperties = () => {
        switch (displayMode) {
            case "User":
                return {
                    swimLanes: [
                        "null" as "null",
                        // @ts-ignore
                        ...users.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(u => u.id),
                    ],
                    selector: (t: ImprovementBoardTask) => t.assignedToUserId,
                };
            case "Category":
                return {
                    swimLanes: ["null" as "null", ...categories.map(c => c.id)],
                    selector: (t: ImprovementBoardTask) => t.taskCategoryId,
                };
            case "Priority":
                return {
                    swimLanes: priorities,
                    selector: (t: ImprovementBoardTask) => t.priority,
                };
        }

        exhaustiveCheck(displayMode);
    };

    const { swimLanes, selector } = getProperties();
    // @ts-ignore
    const zeroObject = _.assign({}, ...swimLanes.map(s => ({ [s]: [] })));

    const groupedTasks = _(tasks)
        .groupBy(x => selector(x))
        .defaults(zeroObject)
        .mapValues(v => getTasksByStatus(v))
        .value();

    return {
        swimLaneCount: swimLanes.length,
        tasks: groupedTasks,
        // @ts-ignore
        swimLanes: swimLanes,
    };
};

export const groupTasksBySwimLane = (
    tasks: ImprovementBoardTask[],
    users: User[],
    categories: TaskCategory[]
): TasksBySwimLane => {
    return {
        all: getTasksByStatus(tasks),
        Category: getTasksBySwimLane("Category", tasks, users, categories),
        User: getTasksBySwimLane("User", tasks, users, categories),
        Priority: getTasksBySwimLane("Priority", tasks, users, categories),
    };
};

export const getTasksByStatus = (tasks: ImprovementBoardTask[]): TasksByStatus => {
    const dictionary = _(tasks)
        .groupBy(x => x.status)
        .value();

    return {
        Todo: dictionary["Todo"] ?? [],
        New: dictionary["New"] ?? [],
        Active: dictionary["Active"] ?? [],
        Completed: dictionary["Completed"] ?? [],
        Archived: dictionary["Archived"] ?? [],
    };
};

export const priorities: TaskPriority[] = ["High", "Normal"];
