﻿import { Link } from "react-router-dom";
import { Breadcrumbs, createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles(() => ({
    link: {
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

type BreadCrumbItem = {
    title: string;
    href: string;
};

export const Breadcrumb = (props: { items: BreadCrumbItem[] }) => {
    const { classes } = useStyles();
    return (
        <Breadcrumbs>
            {props.items.map((item, index) => (
                <Link to={item.href} key={index} className={classes.link}>
                    {item.title}
                </Link>
            ))}
        </Breadcrumbs>
    );
};
