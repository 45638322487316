﻿import { useTranslate } from "@tolgee/react";
import { Flex, Text, Title } from "@mantine/core";
import React from "react";

export type TaskSummaryInfoData = {
    title: string;
    teamValue: number;
    organisationValue: number;
    teamValueColor: string;
};

type TaskSummaryInfoProps = {
    data: TaskSummaryInfoData;
};

export const TaskSummaryInfo = (props: TaskSummaryInfoProps) => {
    const { t } = useTranslate();
    const { title, teamValue, organisationValue, teamValueColor } = props.data;

    return (
        <Flex direction={"column"} align={"center"} columnGap={"xl"} px={0} miw={144}>
            <Text size={"sm"}>{title} </Text>
            <Title order={1} fw={700} color={teamValueColor}>
                {teamValue}{" "}
            </Title>
            <Text size={"xs"} color={"#5D5D5D"}>
                {t("HOME_ORGANISATION")}{" "}
                <Text span inherit fw={500}>
                    {organisationValue}{" "}
                </Text>{" "}
            </Text>
        </Flex>
    );
};
