import { Question } from "@/api-client";
import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { ContentLabel } from "../../../../components/ContentLabel";
import "../../../style.scss";
import { QuestionModuleBlockCard } from "./QuestionModuleBlockCard";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useCurrentCourseFromParams } from "../../helper";
import { NavButton } from "@/components";

/*  eslint-disable react-hooks/exhaustive-deps */

type QuestionsProps = {
    moduleBlockId: number;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

const QuestionModuleBlock = (props: QuestionsProps): JSX.Element => {
    const api = createApi();
    const [course] = useCurrentCourseFromParams();

    const questionList: Question[] =
        course &&
        //@ts-ignore
        course.chapters
            .find(x => x.id === props.chapterId)
            .modules.find(x => x.id === props.moduleId)
            .blocks.find(x => x.id === props.moduleBlockId).questions;
    const [tempQuestionList, setTempQuestionList] = useState<Question[]>(questionList);

    useEffect(() => {
        setTempQuestionList(questionList);
    }, [questionList]);

    const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
        setTempQuestionList(c =>
            update(c, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, c[dragIndex]],
                ],
            }).map((x, ix) => ({ ...x, order: ix }))
        );
    }, []);

    useEffect(() => {
        const patchedQuestions = tempQuestionList?.map(c => {
            const patch = createPatch(
                //@ts-ignore
                questionList.find(q => q.id === c.id),
                x => {
                    //@ts-ignore
                    x.order = c.order;
                }
            );
            if (patch && patch.length > 0) {
                //@ts-ignore
                return api.questions.patch(c.id, patch);
            } else {
                return Promise.reject();
            }
        });

        const allSettled = async () => {
            await Promise.allSettled(patchedQuestions);
        };

        allSettled();
    }, [tempQuestionList]);

    const renderQuestion = useCallback((question: any, index: any) => {
        return (
            <QuestionModuleBlockCard
                key={question.id}
                index={index}
                moveCard={moveCard}
                question={question}
                courseId={props.courseId}
                chapterId={props.chapterId}
                moduleId={props.moduleId}
                moduleBlockId={props.moduleBlockId}
                id={question.id}
            />
        );
    }, []);

    return (
        <>
            <ContentLabel text="Questions" />
            <NavButton
                link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}/question/add`}
                textKey="Add Question"
            />
            <table className="items-drag-drop table table-borderless table-striped table-responsive table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="sysadmin-drag">
                    <DndProvider backend={HTML5Backend}>
                        {tempQuestionList && tempQuestionList.length > 0
                            ? tempQuestionList.map((question, index) => renderQuestion(question, index))
                            : null}
                    </DndProvider>
                </tbody>
            </table>
        </>
    );
};

export default QuestionModuleBlock;
