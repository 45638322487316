import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import ContentBlock from "../ContentBlock";
import { CourseDisplay } from "./CourseDisplay";
import { getCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";

export type CourseWithProgress = { id: number; name: string; description: string; hasProgress: boolean };
export type GroupInfo = { id: number; name: string };

interface CourseSelectorListProps {
    groupsCourses: { group: GroupInfo; courses: CourseWithProgress[] }[];
    selectCourse: (groupId: number, coarseId: number) => Promise<void>;
}

export const CourseSelectorList = ({ groupsCourses, selectCourse }: CourseSelectorListProps) => {
    const { t } = useTranslate();

    const displayGroups = useMemo(
        () =>
            groupsCourses
                .map(gc => {
                    return {
                        groupId: gc.group.id,
                        groupName: gc.group.name,
                        courses: gc.courses.alphabeticSort(x => x.name),
                    };
                })
                .alphabeticSort(x => x.groupName),
        []
    );

    const isLastSelectedGroupCourse = (groupId: number, courseId: number) => {
        const lastCourse = getCourseGroupInLocalStorage();

        if (lastCourse === undefined) {
            return false;
        }

        return groupId === lastCourse.groupId && courseId === lastCourse.courseId;
    };

    return (
        <ContentBlock>
            {groupsCourses.every(gc => gc.courses.length === 0) ? (
                <>
                    <h1>{t("PROGRAM_SELECTOR_NO_PROGRAMS_AVAILABLE")}</h1>
                    <p>{t("PROGRAM_SELECTOR_NO_PROGRAMS_AVAILABLE_DESCRIPTION")}</p>
                </>
            ) : (
                <>
                    <h1>{t("PROGRAM_SELECTOR_SELECT_PROGRAM")}</h1>
                    <p>{t("PROGRAM_SELECTOR_ACCESS_PROGRAM_DETAIL")}</p>
                    <div className="onboard-course-selector">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="my-4 course-conditional-header">
                                    {t("PROGRAM_SELECTOR_MY_GROUPS_AND_PROGRAMS")}
                                </h6>
                            </div>
                        </div>
                        {displayGroups.map(group => (
                            <div key={`i-${group.groupName}`}>
                                <h4 className="ms-2">{group.groupName}</h4>
                                <div className="ms-4">
                                    <div className="course-selector mt-3 mb-3">
                                        {group.courses.map(course => (
                                            <CourseDisplay
                                                isCurrentlySelected={isLastSelectedGroupCourse(
                                                    group.groupId,
                                                    course.id
                                                )}
                                                key={`c-${course.id}`}
                                                name={course.name}
                                                description={course.description}
                                                hasProgress={course.hasProgress}
                                                selectCourseCallback={() =>
                                                    selectCourse(group.groupId, course.id).catchWithToast()
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </ContentBlock>
    );
};
