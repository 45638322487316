﻿import {
    AccountOwner,
    AccountOwnersClient,
    ApiConfiguration,
    GroupMembership,
    TaskVisibility,
    TeamStatus,
    UserStatus,
    UserStatuses,
} from "@/api-client";
import { Get } from "./utility";
import { BaseClient } from "./BaseClient";
import { createApi, createPatchFromDiff } from "@/common";
import { UsernameDto } from "./UsernameDto";

type AddAccountOwnerResponse = "ok" | "badrequest";

export class AccountOwnerClient extends BaseClient {
    private readonly client: AccountOwnersClient;

    constructor(readonly config: ApiConfiguration) {
        super(config);
        this.client = new AccountOwnersClient(config);
    }

    query = (clientId: number): Promise<AccountOwner[]> => {
        return this.client.query(clientId);
    };

    addAccountOwner = async (clientId: number, email: string): Promise<AddAccountOwnerResponse> => {
        const dto: AddAccountOwnerCommandDto = {
            clientId,
            email,
        };

        const authHeader = this.config.getAuthorizationHeader();

        const response = await fetch(`${this.config.baseUrl}/api/account-owners/`, {
            mode: "cors",
            method: "post",
            headers: {
                ...(authHeader === null ? {} : { Authorization: authHeader }),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(dto),
        });

        if (response.status >= 200 && response.status < 300) {
            return "ok";
        }

        if (response.status === 400) {
            return "badrequest";
        }

        return Promise.reject();
    };

    delete = async (id: number): Promise<void> => {
        await this.client.delete(id);
    };

    getUsers = async (clientId: number): Promise<UserDto[]> => {
        const authHeader = this.config.getAuthorizationHeader();

        const response = await fetch(`${this.config.baseUrl}/api/accountowner/getusers?clientid=${clientId}`, {
            mode: "cors",
            method: "get",
            headers: {
                ...(authHeader === null ? {} : { Authorization: authHeader }),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        if (response.status >= 200 && response.status < 300) {
            return (await response.json()) as UserDto[];
        }

        return Promise.reject();
    };

    addUsers = (clientId: number, teamId: number, users: UsernameDto[]) => {
        const dto: AddUsersCommandDto = {
            users: users,
            clientId: clientId,
            teamId: teamId,
        };

        return this.postVoid("/api/accountowner/AddUsers", dto);
    };

    getTeams = async (clientId: number): Promise<TeamsDto[]> => {
        const response = await Get(
            `${this.config.baseUrl}/api/accountowner/getteams?clientid=${clientId}`,
            this.config.getAuthorizationHeader()
        );

        if (response.status >= 200 && response.status < 300) {
            return (await response.json()) as TeamsDto[];
        }

        return Promise.reject();
    };

    getTeam = async (teamId: number, clientId: number, abortController: AbortController): Promise<TeamDto> => {
        return this.get<TeamDto>(`/api/accountowner/getteam?teamId=${teamId}&clientid=${clientId}`, abortController);
    };

    addTeam = async (name: string, color: string, teamTypeId: number, clientId: number) => {
        const dto: AddTeamCommandDto = { name, color, teamTypeId, clientId };
        return this.postVoid("/api/accountowner/addteam", dto);
    };

    sendInvite = async (userId: number, teamId: number) => {
        const patchSource: Pick<GroupMembership, "inviteStatus"> = {
            inviteStatus: "NotSent",
        };

        const patch = createPatchFromDiff(patchSource, { inviteStatus: "Queued" });

        return createApi().groupMembership.patch(userId, teamId, patch);
    };
}

export type AddTeamCommandDto = {
    name: string;
    color: string;
    teamTypeId: number;
    clientId: number;
};

export type UserDto = {
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    hasAcceptedInvite: boolean;
    status: UserStatuses;
    avatarUrl: string | null;
};

type AddAccountOwnerCommandDto = {
    clientId: number;
    email: string;
};

export type EditTeamCommandDto = {
    id: number;
    name: string;
    color: string;
    status: TeamStatus;
    taskVisibility: TaskVisibility;
    groupTypeId: number;
};

export type TeamsDto = {
    id: number;
    name: string;
    noOfMembers: number;
    status: TeamStatus;
    color: string;
    teamTypeName: string;
};

export type TeamDto = {
    id: number;
    name: string;
    status: TeamStatus;
    color: string;
    teamTypeId: number;
    taskVisibility: TaskVisibility;
    members: TeamMemberDto[];
};

export type TeamMemberDto = {
    isTeamLeader: boolean;
    isParticipant: boolean;
    id: number;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    avatarUrl: string | null;
    status: UserStatus;
};

export type AddUsersCommandDto = {
    users: UsernameDto[];
    teamId: number;
    clientId: number;
};
