import { FC } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAuth } from "../auth/useAuth";
import { UserHasOneOfRoles } from "../common/user/utils";
import { User, UserRoles } from "@/api-client";
import { UrlFactory } from "./UrlFactory";
import { WithChildren } from "../common/StateWrapper";

type IsAuthorizedResult =
    | {
          isUserAuthorized: true;
      }
    | {
          isUserAuthorized: false;
          redirectTo: string;
      };

export const isAuthorized = (
    userProfile: User | null,
    token: string | null,
    predicate: (user: User) => boolean
): IsAuthorizedResult => {
    const redirectToLogin = UrlFactory.login.create({});

    if (userProfile == null || token == null) {
        return {
            isUserAuthorized: false,
            redirectTo: redirectToLogin,
        };
    }

    if (!predicate(userProfile)) {
        return {
            isUserAuthorized: false,
            redirectTo: UrlFactory.home.create({}),
        };
    }

    return { isUserAuthorized: true };
};

export const isAuthorizedWithRoles = (
    userProfile: User | null,
    token: string | null,
    requiredRoles: UserRoles[]
): IsAuthorizedResult => {
    const predicate = (userProfile: User): boolean => {
        const userRoles = userProfile.roles;
        if (requiredRoles && requiredRoles.length > 0) {
            // No roles when route requires roles  => unauthorized
            if (userRoles === null) {
                return false;
            }

            // Authorized if user have one or more of the required roles
            return UserHasOneOfRoles(userProfile, requiredRoles);
        }

        return true;
    };

    return isAuthorized(userProfile, token, predicate);
};

interface ProtectedRouteProps {
    roles?: UserRoles[];
    policy?: (user: User) => boolean;
}

export const ProtectedRouteComponent = ({ children, ...props }: ProtectedRouteProps & WithChildren) => {
    const { user, token } = useAuth();

    let result: IsAuthorizedResult | undefined;
    if (props.policy !== undefined) {
        result = isAuthorized(user, token, props.policy);
    } else {
        result = isAuthorizedWithRoles(user, token, props.roles ?? []);
    }

    if (!result.isUserAuthorized) {
        return <Navigate to={{ pathname: result.redirectTo }} />;
    }

    return <> {children} </>;
};

export const ProtectedRouteComponentWithOutlet = (props: ProtectedRouteProps) => {
    return (
        <ProtectedRouteComponent {...props}>
            <Outlet />
        </ProtectedRouteComponent>
    );
};

export type PageProtectedProps<Path extends string, TProps> = {
    component: FC<TProps>;
    roles?: UserRoles[];
    url: {
        pattern: Path;
        parse: (params: any) => TProps;
    };
};

export const ProtectedPageRouteComponent = <Path extends string, TProps>(props: PageProtectedProps<Path, TProps>) => {
    const { user, token } = useAuth();
    const result = isAuthorizedWithRoles(user, token, props.roles === undefined ? [] : props.roles);
    const params = useParams();

    if (!result.isUserAuthorized) {
        return <Navigate to={{ pathname: result.redirectTo }} />;
    }

    return <props.component {...(props.url.parse(params) as any)} />;
};
