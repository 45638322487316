import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../Layout";
import { EditDemographicQuestions } from "./EditDemoGraphicQuestions";
import { EditEmployees } from "./EditEmployees";
import { EditGroups } from "./EditGroups";
import { EditGroup } from "./EditGroup";
import { EditOrganisation } from "./EditOrganisation";
import { EditOrganisationSidebar } from "./EditOrganisationSidebar";
import { Rounds } from "./Rounds";
import { Flex } from "@mantine/core";

type RouteParams = {
    template: string;
    groupId?: string;
    groupTypeId?: string;
    demographicQuestionId?: string;
    roundId?: string;
};

export const EditOrganisationLayout = (): JSX.Element => {
    const params = useParams();
    /* @ts-ignore */
    const template = params.template;
    /* @ts-ignore */
    const groupId = parseInt(params.groupId);
    /* @ts-ignore */
    const groupTypeId = parseInt(params.groupTypeId);
    /* @ts-ignore */
    const demographicQuestionId = parseInt(params.demographicQuestionId);
    /* @ts-ignore */
    const roundId = parseInt(params.roundId);

    const renderTemplate = (template: string, groupId: number) => {
        switch (template) {
            case "organisation":
                return <EditOrganisation />;
            case "group-type":
                return <EditGroups template={template} groupId={groupId} groupTypeId={groupTypeId} />;
            case "group":
                return <EditGroup template={template} groupId={groupId} groupTypeId={groupTypeId} />;
            case "employees":
                return <EditEmployees />;
            case "demographic-questions":
                return <EditDemographicQuestions demographicQuestionId={demographicQuestionId} />;
            case "rounds":
                return <Rounds template={template} roundId={roundId} />;
            default:
                return null;
        }
    };

    return (
        <Layout>
            <Flex gap="xl">
                <Flex h="100%">
                    <EditOrganisationSidebar />
                </Flex>

                <Col md={9}>
                    {
                        //@ts-ignore
                        renderTemplate(template, groupId)
                    }
                </Col>
            </Flex>
        </Layout>
    );
};
