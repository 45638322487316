import { Field } from "formik";
import { StatusType } from "../../../constants";

export const Statuses = (props: { statuses: StatusType[]; isNumericValue?: boolean; name?: string }) => {
    return (
        <Field name={props.name ? props.name : "status"} as="select" className="form-select">
            {props.statuses &&
                props.statuses.map(status => (
                    <option
                        key={props.isNumericValue ? status.id : status.label}
                        value={props.isNumericValue ? status.id : status.value}
                    >
                        {status.label}
                    </option>
                ))}
        </Field>
    );
};
