import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { Checkbox } from "@mantine/core";

export type FormTextInputProps = {
    fieldName: string;
    label: string;
};

export const CheckboxInput = (props: FormTextInputProps) => {
    const [{ value, ...field }, meta] = useField<boolean>(props.fieldName);
    const { t } = useTranslate();

    return (
        <Checkbox
            checked={value}
            {...field}
            label={t(props.label)}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
        />
    );
};
