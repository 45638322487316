import "moment/locale/nb";
import "moment/locale/sv";
import moment, { LocaleSpecifier } from "moment/moment";

/** Format current date as 2000-02-01 */
export const formatYYYYMMDD = () => moment().format("YYYY_MM_DD");

const toMomentLocale = (selectedLang: string): LocaleSpecifier => {
    switch (selectedLang) {
        case "no":
            return "nb";
        case "se":
            return "sv-SE";
        default:
            return "en";
    }
};

export const fromNow = (timeStamp: Date, locale: string) =>
    moment.utc(timeStamp).locale(toMomentLocale(locale)).fromNow();
