import React from "react";
import { Carousel, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import {
    useCourseGroupUserOnboardingProgress,
    useCurrentCourse,
    useCurrentGroup,
    useCurrentGroupCourseContextDispatch,
    useCurrentUserProgress,
} from "../../common/courses/context";
import { getTrainingUrl } from "../../common/navHelper";
import { useCurrentClient, useCurrentUser } from "../../common/user/context";
import { UserHasGroupLeaderRole, UserHasParticipantRole, UserHasSpecificRole } from "../../common/user/utils";
import { OnboardCard } from "./OnboardContent";
import OnBoardingNav from "./OnBoardNav";
import { ACCOUNT_OWNER_ROLE } from "../../constants";
import { onBoardVideos } from "./helper";
import "./style.css";
import { UserStorage } from "../../auth/UserStorage";
import { VideoPlayer } from "../../components/VideoPlayer";

type RouteParams = {
    clientId: string;
    courseId: string;
    videoId: string;
    groupId: string;
};

const OnBoard = () => {
    const { t } = useTranslate();
    const params = useParams<RouteParams>();
    const history = useHistory();
    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore
    const videoIndex = parseInt(params.videoId);
    //@ts-ignore
    const groupId = parseInt(params.groupId);

    const api = createApi();

    const currentCourse = useCurrentCourse();

    const isOnboardCompleted = useCourseGroupUserOnboardingProgress();
    const currentClient = useCurrentClient();

    const courseGroupContext = useCurrentGroupCourseContextDispatch();

    const currentGroup = useCurrentGroup();
    const currentUser = useCurrentUser();
    const progress = useCurrentUserProgress();
    const tempHTML = t("ONBOARD_TEXT");

    const isParticipant = UserHasParticipantRole(
        currentUser,
        // @ts-ignore
        currentGroup!
    );

    const isGroupLeader = UserHasGroupLeaderRole(
        currentUser,
        // @ts-ignore

        currentGroup!
    );

    const isManager = UserHasSpecificRole(currentUser, ACCOUNT_OWNER_ROLE);
    const myOnBoardVideos = onBoardVideos(
        // @ts-ignore
        currentCourse,
        currentClient,
        isParticipant,
        isGroupLeader,
        isManager
    );

    const isLast = myOnBoardVideos && myOnBoardVideos.length > 0 && myOnBoardVideos.length === videoIndex;

    const goNext = async () => {
        //return false;
        const nextVideo = isLast ? myOnBoardVideos.length : videoIndex + 1;
        if (isLast) {
            // Save to API if not already completed
            // @ts-ignore
            if (!isOnboardCompleted.completed) {
                // @ts-ignore
                api.clientCourseUserOnboarding
                    // @ts-ignore
                    .putByUserIdAndCourseId(currentUser.id, courseId, {
                        // @ts-ignore
                        clientId: currentGroup.clientId,
                        courseId,
                        userId: currentUser.id,
                        completed: true,
                    })
                    .then(_ => {
                        // @ts-ignore
                        courseGroupContext(s => ({
                            ...s,
                            clientCourseUserOnboardingProgress: {
                                // @ts-ignore
                                clientId: currentGroup.clientId,
                                courseId,
                                userId: currentUser.id,
                                completed: true,
                            },
                        }));
                    });
            }
            if (
                UserHasParticipantRole(
                    currentUser,
                    // @ts-ignore
                    currentGroup
                )
            ) {
                const url = getTrainingUrl(
                    currentUser,
                    progress,
                    // @ts-ignore
                    currentCourse,
                    currentGroup
                );
                history.push(url);
                return;
            } else {
                if (
                    UserStorage.getImpersonationToken() ||
                    UserHasGroupLeaderRole(
                        currentUser,
                        // @ts-ignore
                        currentGroup
                    )
                ) {
                    history.push(`/groups/${groupId}`);
                    return;
                }
                history.push(`/account-owner`);
            }
        } else {
            history.push(`/groups/${groupId}/onboarding/courses/${courseId}/videos/${nextVideo}`);
        }
    };

    return (
        <div className="main-content onboarding-page">
            <div className="container">
                {myOnBoardVideos && myOnBoardVideos.length > 0 && (
                    <OnBoardingNav
                        // @ts-ignore
                        course={currentCourse}
                        onBoardMenu={myOnBoardVideos}
                        onBoardVideoIndex={videoIndex}
                        groupId={groupId}
                    />
                )}

                <Carousel
                    activeIndex={videoIndex - 1}
                    indicators={false}
                    interval={null}
                    key={`${videoIndex}_corousel`}
                >
                    {myOnBoardVideos.map((video, index) => {
                        const video_index = index + 1;

                        return (
                            <Carousel.Item key={video_index}>
                                <div className="block-section page_content slide_content_course_text">
                                    <div className="row block">
                                        <OnboardCard video={video} description={tempHTML} animated={false} />
                                        <VideoPlayer
                                            url={video.url}
                                            // @ts-ignore
                                            id={video.id}
                                        />
                                    </div>
                                </div>
                                <Row>
                                    <div className="nextButton">
                                        <button onClick={goNext} className="nextButtonRounded">
                                            {video_index === myOnBoardVideos.length
                                                ? t("ONBOARD_START_COURSE")
                                                : t("ONBOARD_NEXT")}
                                        </button>
                                    </div>
                                </Row>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
};

export default OnBoard;
