import { Question } from "@/api-client";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { Notify } from "../../../../components/Notify";
import { AppLoader } from "../../../../components/Spinner";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () => {
    return Yup.object().shape({
        minAnswer: Yup.number().typeError("Min Answer's value must be number type"),
        maxAnswer: Yup.number().typeError("Max Answer's value must be number type"),
        charLimit: Yup.number().typeError("Char limit's value must be number type"),
        enableCategorisation: Yup.boolean().typeError("Categorisation is required"),
    });
};

interface FormModel {
    questionId: number;
    minAnswer: number;
    maxAnswer: number;
    charLimit: number;
    enableCategorisation: boolean;
}

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

interface EditFreeTextAnswerResponse {
    question: Question;
    isPending: boolean;
    isSuccess: boolean;
}

const FreeTextAnswers = (props: EditQuestionProps): JSX.Element => {
    const api = createApi();
    const history = useHistory();
    const initialValues: FormModel = {
        // @ts-ignore

        questionId: props.question?.id,
        // @ts-ignore

        minAnswer: props.question?.minAnswer,
        // @ts-ignore

        maxAnswer: props.question?.maxAnswer,
        // @ts-ignore

        charLimit: props.question?.charLimitAnswer,
        // @ts-ignore

        enableCategorisation: props.question?.enableCategorisation,
    };
    const [editFreeTextAnswer, setEditFreeTextAnswer] = useState<EditFreeTextAnswerResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        question: null,
        isSuccess: false,
    });
    const [submitted, setSubmitted] = useState(false);

    const isLoading = editFreeTextAnswer.isPending ? true : false;
    return (
        <>
            <AppLoader loading={isLoading ? true : false} className="position-absolute" />
            {editFreeTextAnswer.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Question updated successfully." id="registersuccess" type="success" />
                    {/* <Redirect to="/sysadmin/clients" /> */}
                </Fragment>
            )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={values => {
                    setSubmitted(true);
                    setEditFreeTextAnswer({ ...editFreeTextAnswer, isPending: true });
                    const patch = createPatch(props.question, x => {
                        x.id = values.questionId;
                        x.minAnswer = values.minAnswer;
                        x.maxAnswer = values.maxAnswer;
                        x.charLimitAnswer = values.charLimit;
                        x.enableCategorisation = values.enableCategorisation;
                    });

                    if (patch && patch.length > 0) {
                        // Update profile
                        // @ts-ignore

                        api.questions.patch(props.question?.id, patch).then(
                            result => {
                                setEditFreeTextAnswer({
                                    ...editFreeTextAnswer,
                                    question: result,
                                    isPending: false,
                                    isSuccess: true,
                                });
                                //dispatch(push(`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/exercise/${props.exerciseId}/edit`));
                            },
                            error => {
                                setEditFreeTextAnswer({ ...editFreeTextAnswer, isPending: false });
                                console.log(error);
                                history.push("/error");
                            }
                        );
                    } else {
                        setEditFreeTextAnswer({ ...editFreeTextAnswer, isPending: false });
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="freeTextAnswerForm" className="mt-2 editModuleForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Minimum Answers</label>
                                    <Field
                                        name="minAnswer"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.minAnswer && touched.minAnswer ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.minAnswer && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.minAnswer}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Maximum Answers</label>
                                    <Field
                                        name="maxAnswer"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.maxAnswer && touched.maxAnswer ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.maxAnswer && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.maxAnswer}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Char Limit</label>
                                    <Field
                                        name="charLimit"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.charLimit && touched.charLimit ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.charLimit && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.charLimit}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Enable Categorisation</label>
                                    <Field
                                        name="enableCategorisation"
                                        as="select"
                                        //value={props.question?.enableCategorisation}
                                        type="text"
                                        className={
                                            "form-select" +
                                            (errors.enableCategorisation && touched.enableCategorisation
                                                ? " is-invalid"
                                                : "")
                                        }
                                    >
                                        <option value={""}>Please select Categorisation</option>
                                        <option key={1} value={"true"}>
                                            Yes
                                        </option>
                                        <option key={2} value={"false"}>
                                            No
                                        </option>
                                    </Field>
                                    {errors.enableCategorisation && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.enableCategorisation}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={isLoading}>
                                    Update
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default FreeTextAnswers;
