import React, { useEffect, useState } from "react";
import { Row, Tab } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { DemographicQuestion } from "@/api-client";
import { createApi } from "@/common";
import { useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { useCurrentUser } from "@/common";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "@/components";
import { TabItems } from "../Components/TabsItems";
import { AddQuestions } from "./Components/addQuestion";
import { NoDemographicQuestions } from "./Components/NoDemographicQuestions/noDemographicQuestions";
import { EditPlanningDemograpicQuestions } from "./Components/EditPlanningDemograpicQuestions";
import "./style.scss";

export const DisplayDemoGraphicQuestions = (props: {
    isEditComponent?: boolean;
    demographicQuestionId?: number;
    questions: DemographicQuestion[] | undefined;
    questionsPending: boolean;
}) => {
    const { t } = useTranslate();
    const api = createApi();
    const accountOwnerDispatch = useOrganisationContextDispatch();
    const [showAddQuestion, setAddQuestion] = useState<boolean>(false);
    const showAddQuestions = (value: boolean): void => {
        setAddQuestion(value);
    };

    const currentUser = useCurrentUser();

    const saveQuestion = (data: DemographicQuestion) => {
        if (data.id === undefined) {
            api.demographicQuestions.post(data).then(
                response => {
                    accountOwnerDispatch(s => ({
                        ...s,
                        demographicQuestions: [
                            //@ts-ignore
                            ...s.demographicQuestions,
                            response,
                        ],
                    }));
                },
                error => {
                    console.log(error);
                }
            );
            //dispatch(saveDemographicQuestionAction(data));
        } else {
            api.demographicQuestions.put(data.id, data).then(
                response => {
                    accountOwnerDispatch(s => ({
                        ...s,
                        demographicQuestions: [
                            //@ts-ignore
                            ...s.demographicQuestions.map(d => (d.id === response.id ? response : d)),
                        ],
                    }));
                },
                reason => {
                    console.log(reason);
                }
            );
        }
        setAddQuestion(false);
    };

    useEffect(() => {
        if (props.questions && props.questions.length > 0) {
            setAddQuestion(false);
        }
    }, [props.questions]);

    return (
        <React.Fragment>
            <AppLoader loading={props.questionsPending} className="position-absolute" />

            <RenderIf
                /* @ts-ignore */
                show={
                    showAddQuestion === false &&
                    props.questionsPending === false &&
                    props.questions &&
                    props.questions.length === 0
                }
            >
                <NoDemographicQuestions showQuestionsScreen={value => showAddQuestions(value)} />
            </RenderIf>
            <RenderIf show={(props.questions && props.questions.length > 0) || showAddQuestion === true}>
                <RenderIf
                    //@ts-ignore
                    show={props.isEditComponent}
                >
                    <React.Fragment>
                        <RenderIf show={!showAddQuestion}>
                            <EditPlanningDemograpicQuestions
                                //@ts-ignore
                                demographicQuestionId={props.demographicQuestionId}
                                //@ts-ignore
                                items={props.questions}
                                //@ts-ignore
                                user={currentUser}
                                showQuestionsScreen={(value: any) => showAddQuestions(value)}
                                handleQuestion={(data: any) => saveQuestion(data)}
                            />
                        </RenderIf>
                        <RenderIf show={showAddQuestion}>
                            <AddQuestions handleQuestion={data => saveQuestion(data)} user={currentUser} />
                        </RenderIf>
                    </React.Fragment>
                </RenderIf>
                <RenderIf show={!props.isEditComponent}>
                    <Tab.Container id="left-tabs-example">
                        <Row className="tabs-content">
                            <div className="col-md-3 p-0">
                                {props.questions ? (
                                    <TabItems
                                        items={props.questions}
                                        onClick={id => {
                                            showAddQuestions(false);
                                        }}
                                    />
                                ) : (
                                    <>{t("ORGANISATIONS_DEMOGRAPHIC_QUESTION_NOT_FOUND")}</>
                                )}
                                <button
                                    className="btn btn-link mt-2 text-center"
                                    onClick={() => {
                                        showAddQuestions(true);
                                    }}
                                >
                                    + {t("ORGANISATIONS_DEMOGRAPHICE_ADD_QUESTION")}
                                </button>
                            </div>
                            <div className="col-md-9">
                                {showAddQuestion ? (
                                    /* @ts-ignore */
                                    <AddQuestions handleQuestion={data => saveQuestion(data)} user={currentUser} />
                                ) : (
                                    <Tab.Content>
                                        {props.questions &&
                                            props.questions.map((q, ix) => {
                                                return (
                                                    <Tab.Pane key={ix} eventKey={q.id}>
                                                        <AddQuestions
                                                            user={currentUser}
                                                            handleQuestion={data => saveQuestion(data)}
                                                            question={q}
                                                        />
                                                    </Tab.Pane>
                                                );
                                            })}
                                    </Tab.Content>
                                )}
                            </div>
                        </Row>
                    </Tab.Container>
                </RenderIf>
            </RenderIf>
        </React.Fragment>
    );
};
