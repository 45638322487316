import { GroupMember, InviteStatus } from "@/api-client";
import React, { FunctionComponent, useState } from "react";
import { createApi } from "../../../../common/api";
import { useOrganisationContext, useOrganisationContextDispatch } from "../../../../common/OrganisationContext";
import { getDragData, getGroupRolesBeforeAssigningUserToGroup, isGroupMember } from "../../helper";
import "./navItemDisplay.scss";

interface NavItemProps {
    item: ResourcesNavItem;
    level?: number;
    clickHandler: (name: string, id: number) => void;
    isVisited?: number;
    courseId?: number;
}

export interface ResourcesNavItem {
    title: string;
    id: number;
    level?: number;
    count?: number;
    subCat?: Array<ResourcesNavItem>;
}

export const NavItemDisplay: FunctionComponent<NavItemProps> = ({
    item,
    level,
    clickHandler,
    isVisited,
    courseId,
}: NavItemProps) => {
    const api = createApi();
    const accountOwnerContext = useOrganisationContext();
    const allGroups = accountOwnerContext.groups;
    const users = accountOwnerContext.users;
    const accountOwnerDispatch = useOrganisationContextDispatch();
    const [dragItem, setDragItem] = useState<number | null>(null);
    /**
     * Function used to handle the drag over
     * @param e
     * @param item
     */
    const onDragOver = (e: React.DragEvent<HTMLLIElement>, item: ResourcesNavItem) => {
        setDragItem(item.id);
        e.preventDefault();
    };

    const removeMembership = (userId: number, groupId: number) => {
        api.groupMembership.delete(userId, groupId).then(
            response => {
                accountOwnerDispatch(s => {
                    return {
                        ...s,
                        groups: [
                            /* @ts-ignore */
                            ...s.groups.map(g => {
                                if (g.id === groupId) {
                                    const { members, ...rest } = g;
                                    return {
                                        ...rest,
                                        members: [...members.filter(m => m.userId !== userId)],
                                    };
                                } else {
                                    return g;
                                }
                            }),
                        ],
                    };
                });
            },
            error => {
                console.log(error, "Group membership error....");
            }
        );
    };

    const addGroupMembership = (
        userId: number,
        groupId: number,
        isParticipant: boolean,
        isGroupLeader: boolean,
        inviteStatus: InviteStatus
    ) => {
        api.groupMembership
            .post(userId, groupId, {
                userId,
                groupId,
                inviteStatus,
                isGroupLeader,
                isParticipant,
            })
            .then(
                response => {
                    accountOwnerDispatch(s => {
                        return {
                            ...s,
                            groups: [
                                /* @ts-ignore */
                                ...s.groups.map(g => {
                                    if (g.id === groupId) {
                                        const { members, ...rest } = g;
                                        const data = {
                                            ...rest,
                                            members: [
                                                ...members,
                                                {
                                                    ...response,
                                                    userId,
                                                    /* @ts-ignore */
                                                    user: users.find(u => u.id === userId),
                                                } as GroupMember,
                                            ],
                                        };
                                        return data;
                                    } else {
                                        return g;
                                    }
                                }),
                            ],
                        };
                    });
                },
                error => {
                    console.log(error, "Error...");
                }
            );
    };

    /**
     * Function used for onDrop item
     * @param e
     * @param item
     */
    const onDrop = (e: React.DragEvent<HTMLLIElement>, item: ResourcesNavItem) => {
        const dragData = getDragData(e.dataTransfer);

        /* @ts-ignore */
        if (isGroupMember(allGroups, item.id, dragData.userId)) {
            // Already member
            setDragItem(null);
            return;
        }

        const userInfo = getGroupRolesBeforeAssigningUserToGroup(
            allGroups,
            /* @ts-ignore */
            dragData.groupId,
            courseId,
            dragData.userId
        );

        if (userInfo) {
            if (dragData.groupId) {
                // User is dragged from another group => MOVE the user to that group by removing and adding to the other group

                // Before assign the user to other group lets delete the membership first
                removeMembership(dragData.userId, dragData.groupId);

                // Add the new membership
                /* @ts-ignore */
                addGroupMembership(dragData.userId, item.id, userInfo.isParticipant, userInfo.isGroupLeader, "NotSent");
            } else {
                /* @ts-ignore */
                addGroupMembership(dragData.userId, item.id, userInfo.isParticipant, userInfo.isGroupLeader, "NotSent");
            }
        }

        setDragItem(null);
    };

    if (item) {
        return (
            <>
                {/* {error && <Notify message={`User already exist in this group`} id="user_errro" type="warning" />} */}
                <li
                    className={`dropable ${dragItem === item.id ? "droppable-item" : ""} ${
                        isVisited === item.id ? "active" : ""
                    }`}
                    onDragOver={e => (isVisited !== item.id ? onDragOver(e, item) : "")}
                    onDrop={e => (isVisited !== item.id ? onDrop(e, item) : "")}
                    onDragLeave={() => (isVisited !== item.id ? setDragItem(null) : "")}
                    onClick={() => clickHandler("subitem", item.id)}
                >
                    <div className={isVisited === item.id ? "active" : ""}>{item.title}</div>
                    <span>{item.count}</span>
                    {item.subCat && (
                        <ul>
                            {item.subCat.map((item, ix) => {
                                /* @ts-ignore */
                                return <NavItemDisplay key={ix} item={item} level={level + 1} />;
                            })}
                        </ul>
                    )}
                </li>
            </>
        );
    } else {
        return <></>;
    }
};
