import { useMemo } from "react";
import { MRT_ColumnDef } from "mantine-react-table";
import { useTranslate } from "@tolgee/react";
import { Box, ColorSwatch, Flex, SelectItem } from "@mantine/core";
import { PrimaryButton, Table, RowActions, RowActionConfig } from "@/components";
import { TeamsDto } from "@/api-client";
import { UrlFactory } from "../../../routing/UrlFactory";
import { TeamStatusBadge } from "./TeamStatusBadge";
import { translateTeamStatus as _translateTeamStatus } from "../../../sysadmin/helper";

type TeamsListProps = {
    teams: TeamsDto[] | null;
    onAddTeamClicked: () => void;
};

export const TeamsList = (props: TeamsListProps) => {
    const { t } = useTranslate();

    const translateTeamStatus: { [key: string]: string } = _translateTeamStatus(t);

    const teamTypeSelectItems: SelectItem[] = useMemo(() => {
        if (props.teams === null) {
            return [];
        }

        const uniqueTeamTypes = props.teams.map(x => x.teamTypeName).unique();

        return uniqueTeamTypes.map(t => ({ value: t, label: t }));
    }, [props.teams]);

    const columns = useMemo<MRT_ColumnDef<TeamsDto>[]>(
        () => [
            {
                header: t("ACCOUNTOWNER_TEAMS_NAME"),
                Cell: ({ row: { original }, renderedCellValue }) => {
                    return (
                        <Flex columnGap="md" align="center">
                            <Box>
                                <ColorSwatch color={original.color} radius="sm" />
                            </Box>
                            {renderedCellValue}
                        </Flex>
                    );
                },
                accessorKey: "name",
            },
            {
                header: t("ACCOUNTOWNER_EDIT_TEAMS_TEAM_TYPE"),
                accessorKey: "teamTypeName",
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: teamTypeSelectItems,
                },
            },
            {
                header: t("ACCOUNTOWNER_TEAMS_MEMBERS"),
                accessorKey: "noOfMembers",
                size: 100,
            },
            {
                header: t("ACCOUNTOWNER_TEAMS_STATUS"),
                accessorFn: team => translateTeamStatus[team.status],
                Cell: ({ row }) => <TeamStatusBadge status={row.original.status} />,
                size: 100,
            },
        ],
        [teamTypeSelectItems]
    );

    const rowActions: RowActionConfig<TeamsDto> = useMemo(
        () => ({
            renderRowActions: ({ row }) => (
                <RowActions.EditLink to={UrlFactory.accountOwner.team.create({ teamId: row.original.id })} />
            ),
            size: 10,
        }),
        []
    );

    return (
        <Table
            columns={columns}
            data={props.teams}
            initialColumnFilterState={[]}
            toolbarActions={() => (
                <PrimaryButton eventName={{ object: "ao_addteam", action: "open" }} onClick={props.onAddTeamClicked}>
                    {t("ACCOUNTOWNER_ADD_TEAM")}
                </PrimaryButton>
            )}
            rowActions={rowActions}
        />
    );
};
