import { ActionIcon } from "@mantine/core";
import { NavLink } from "react-router-dom";
import { Icons } from "@/common";

type ArrowLinkProps = {
    to: string;
};

export const ArrowLink = (props: ArrowLinkProps) => {
    return (
        <ActionIcon component={NavLink} to={props.to}>
            <Icons.RightArrow />
        </ActionIcon>
    );
};
