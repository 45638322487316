import { DemographicQuestion } from "@/api-client";
import React from "react";
import { Nav } from "react-bootstrap";
import "./tabitems.scss";

interface TabItemProps {
    items: DemographicQuestion[];
    onClick: (id: number) => void;
}

export const TabItems = ({ items, onClick }: TabItemProps) => {
    return (
        <Nav className="flex-column">
            {items && items.length > 0 ? (
                items.map((i, ix) => {
                    return (
                        <Nav.Item key={ix} onClick={() => onClick(i.id!)}>
                            <Nav.Link eventKey={i.id}>{i.label}</Nav.Link>
                        </Nav.Item>
                    );
                })
            ) : (
                <></>
            )}
        </Nav>
    );
};
