import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { useCurrentUser } from "../../common/user/context";
import PageHeading from "../../components/PageHeading";
import { AppLoader } from "../../components/Spinner";
import { UsersList } from "../../components/Users/UsersList";
import { AddGroupEmployees } from "../Components/AddGroupEmployees";
import { User } from "@/api-client";
import { CardBlock } from "../../components/CardBlock";

export const EditEmployees = () => {
    const { t } = useTranslate();
    const allUsers = useOrganisationContext().users;
    const currentUser = useCurrentUser();
    /* @ts-ignore */
    const [users, setUsers] = useState<User[]>(null);

    useEffect(() => {
        if (currentUser && allUsers && allUsers.length > 0) {
            setUsers(allUsers.filter(u => u.id !== currentUser.id));
        }
    }, [allUsers, currentUser]);

    return (
        <React.Fragment>
            <CardBlock mb="xl">
                <AppLoader loading={false} className="position-absolute" />
                <PageHeading
                    contentLabel={t("ACCOUNTOWNER_MYORGANISATION_EMPLOYEES")}
                    detailText={t("ACCOUNTOWNER_EDIT_EMPLOYEE_DETAIL_TEXT")}
                    className="account-settings"
                />
                <Row>
                    <Col md={12}>
                        <UsersList users={users} componentFor="account-owner" />
                    </Col>
                </Row>
                <Row className="department-slide row mt-5">
                    <Col md={12}>
                        <AddGroupEmployees />
                    </Col>
                </Row>
            </CardBlock>
        </React.Fragment>
    );
};
