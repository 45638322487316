import { ListGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Answer, User } from "@/api-client";
import { useUserContext } from "../../common/user/context";
import { QuestionTemplateProps } from "./models/QuestionTemplateProps";
import useDebounce from "../UseDebounce/UseDebounce";
import { useTranslate } from "@tolgee/react";

/*  eslint-disable react-hooks/exhaustive-deps */

export const getInitValues = (question: any, existingAnswers: Answer[], user: User): MultipleChoiceAnswer[] => {
    // @ts-ignore
    return question.options.map(a => {
        const existingAnswer = existingAnswers.find(x => x.optionAnswerId === a.id);
        const answer = {
            id: existingAnswer && existingAnswer.id ? existingAnswer.id : undefined,
            optionAnswerId: a.id,
            text: a.text ?? "",
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            order: a.order,
            userId: user.id,
            ...existingAnswer,
        };

        return {
            answer: answer,
            isSelected: existingAnswer !== undefined,
        };
    });
};

export interface MultipleChoiceAnswer {
    answer: Answer;
    isSelected?: boolean;
    isValid?: boolean;
    error?: string[] | undefined;
}

interface MultiChoiceAnswersProps extends QuestionTemplateProps {
    //change this
    question: any;
    currentProgress: boolean | null;
    placeholder: string;
    index: number;
    error?: string[] | undefined;
}

export const MultiChoiceAnswers = (props: MultiChoiceAnswersProps): JSX.Element => {
    const { t } = useTranslate();
    const userContext = useUserContext();
    const [answers, setAnswers] = useState<MultipleChoiceAnswer[]>();
    const [errors, setErrors] = useState<string[]>();

    // Initialize answers
    useEffect(() => {
        if (props.question && userContext.user && props.question.options) {
            const initValues = getInitValues(props.question, props.answers, userContext.user);
            setAnswers(initValues);
        }
    }, []);

    const answersStateChange = useDebounce(answers, 500);

    useEffect(() => {
        if (answers && props.question) {
            const findValidAnswer = answers.filter(a => a.isSelected === true);
            let isValid = true;
            if (findValidAnswer.length === 0) {
                if (!findValidAnswer) {
                    setErrors([t("MODULE_VALIDATE_MC_MIN_REQUIRED")]);
                    isValid = false;
                } else {
                    setErrors([]);
                }
                return;
            }
            // Update parent form
            props.setValidation(isValid, true);
            const selectedAnswers = answers.filter(x => x.isSelected).map(a => a.answer);
            props.setAnswers(selectedAnswers);
        }
    }, [answersStateChange]);

    const setAnswerValue = (answer: MultipleChoiceAnswer, selected: boolean) => {
        setAnswers(answers =>
            answers?.map(a => {
                if (a.answer.text === answer.answer.text) {
                    a.isSelected = selected;
                }
                return a;
            })
        );
    };
    return (
        <div className="row">
            <div className="col-12 col-md-4">
                <div className="block-content-text">
                    <p>{props.question.text}</p>
                    <p>{props.question.description}</p>
                </div>
            </div>
            <div className="col-12 col-md-8 text-secondary">
                <div className="block-content-option">
                    <p className="px-5 question_type"></p>
                    <ListGroup id={`question-${props.index}-${props.question.questionTypeId}-questions`}>
                        {answers &&
                            answers.map((answer, index) => {
                                if (!answer) {
                                    throw new Error("No matching answer found");
                                }

                                return (
                                    <ListGroup.Item
                                        className={
                                            (answer.isSelected ? "active_selection" : "inactive") + " border mb-2"
                                        }
                                        //onClick={() => { refs[index].click(); toggleClass(refs[index].checked, index) }}
                                        key={`question_${index}_${props.question.id}`}
                                    >
                                        <div className="custom-control custom-checkbox row">
                                            <div className="col-sm-12">
                                                {answer.isSelected}
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input me-1"
                                                    id={`answer_${answer.answer.text}_${props.question.id}`}
                                                    checked={answer.isSelected}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setAnswerValue(answer, e.target.checked)
                                                    }
                                                    name={`answer_${answer.answer.text}`}
                                                    value={1}
                                                    disabled={props.currentProgress ? true : false}
                                                />
                                                <label
                                                    htmlFor={`answer_${answer.answer.text}_${props.question.id}`}
                                                    className="custom-control-label"
                                                >
                                                    <span>{answer.answer.text}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                    {((errors && errors.length === 0) || errors === undefined) && props.error && (
                        <p className="error learfix w-100 text-danger">{t("MODULE_VALIDATE_MC_MIN_REQUIRED")}</p>
                    )}
                    {errors &&
                        errors.map((error, index) => (
                            <div key={index} className="clearfix w-100 text-danger">
                                {error}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
