﻿import { ApiConfiguration } from "@/api-client";
import { BaseClient } from "./BaseClient";

export type GetConfigurationResultDto = {
    blobStorageBaseUrl: string;
    googleAnalyticsMeasurementId: string;
};
export class ConfigurationClient extends BaseClient {
    constructor(readonly config: ApiConfiguration) {
        super(config);
    }

    getConfiguration = (abortController: AbortController): Promise<GetConfigurationResultDto> => {
        return this.get(`/api/GetConfiguration`, abortController);
    };
}
