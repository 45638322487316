﻿import { BoardLane } from "../BoardLane";
import { BoardProps } from "./BoardProps";
import { DragAndDropStrategy } from "../../dragAndDrop";
import { BoardLaneDefinitions } from "./BoardLaneDefinitions";

export const BoardLanes = ({ swimLane, defaultNewTask, displayMode }: BoardProps) => {
    const boardLaneDefinition = BoardLaneDefinitions[displayMode];

    const swimLanes = swimLane.swimLanes.map(s => (
        <BoardLane
            swimLane={s}
            key={s}
            tasks={swimLane.tasks[s]}
            defaultNewTask={DragAndDropStrategy[displayMode](s.toString()).modifyTask(defaultNewTask)}
        >
            <boardLaneDefinition.SwimLane swimLane={s} />
        </BoardLane>
    ));

    return <>{swimLanes}</>;
};
