import { Answer, AnswerCategory, DemographicQuestion, DemographicUserData, Group, Question, User } from "@/api-client";
import _ from "lodash";
import { AnswerGroup } from "./AnswerGroup";

/*
 Note: We use promises here to be able to send request to the API in the future when amount of data might be to big to calculate in browser
 */

export const groupAnswersByGroup = (answers: Answer[], groups: Group[]): Promise<AnswerGroup[]> => {
    return new Promise<AnswerGroup[]>((resolve, reject) => {
        resolve(
            // @ts-ignore
            _.chain(answers)
                .groupBy(x => x.groupId)
                .map<AnswerGroup>((value, key) => ({
                    id: key,
                    // @ts-ignore
                    name: groups?.find(x => x.id === parseInt(key))?.name,
                    answers: value,
                }))
                .value()
        );
    });
};

export const groupAnswersByCategory = (
    answers: Answer[],
    answerCategories: AnswerCategory[]
): Promise<AnswerGroup[]> => {
    return new Promise<AnswerGroup[]>((resolve, reject) => {
        resolve(
            // @ts-ignore
            _.chain(answers)
                .groupBy(x => x.answerCategoryId)
                .map<AnswerGroup>((value, key) => ({
                    id: key,
                    // @ts-ignore
                    name: answerCategories?.find(x => x.id === (key as string))?.name,
                    answers: value,
                }))
                // @ts-ignore
                .orderBy(x => x.answers.length, "desc")
                .value()
        );
    });
};

export const groupAnswersByDemographicData = (
    answers: Answer[],
    users: User[],
    demographicQuestion: DemographicQuestion,
    demographicData: DemographicUserData[]
): Promise<AnswerGroup[]> => {
    var answersAndData = answers
        .map(answer => ({
            answer: answer,
            data: demographicData.find(
                dd => dd.demographicQuestionId === demographicQuestion.id && dd.userId === answer.userId
            ),
        }))
        .filter(x => x.data !== undefined);

    return new Promise<AnswerGroup[]>((resolve, reject) => {
        var answers = _.chain(answersAndData)
            // @ts-ignore
            .groupBy(x => x.data.value)
            .map<AnswerGroup>((value, key) => ({
                id: key,
                name: key,
                answers: value.map(v => v.answer),
            }))
            .value();

        resolve(answers);
    });
};

/**
 * Group answers by answer optionId, useful to split answers from multi-option questions like matrix and similar
 * @param question
 * @param answers
 * @returns
 */
export const groupAnswersByQuestionOption = (question: Question, answers: Answer[]): Promise<AnswerGroup[]> => {
    return new Promise<AnswerGroup[]>((resolve, reject) => {
        const results = _.chain(answers)
            .groupBy(x => x.optionAnswerId)
            .map((value, key) => {
                // @ts-ignore
                const option = question.options.find(x => x.id === parseInt(key));

                return {
                    id: key,
                    // @ts-ignore

                    name: option.text,
                    answers: value,

                    // @ts-ignore
                    order: option.order,
                };
            })
            .sortBy(x => x.order)
            .value();

        // @ts-ignore
        resolve(results);
    });
};

export const groupAnswersByUser = (answers: Answer[], users: User[]): Promise<AnswerGroup[]> => {
    return new Promise<AnswerGroup[]>((resolve, reject) => {
        resolve(
            // @ts-ignore
            _.chain(answers)
                .groupBy(x => x.userId)
                .map<AnswerGroup>((value, key) => ({
                    id: key,
                    // @ts-ignore
                    name: users?.find(x => x.id === parseInt(key))?.fullName,
                    answers: value,
                }))
                // @ts-ignore
                .orderBy(x => x.name, "asc")
                .value()
        );
    });
};
