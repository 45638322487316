import React from "react";
import "../../../../components/Questions/scss/matrix.scss";
import stringToColor from "../../../../lib/StringToColor";
import { Question } from "@/api-client";
import { QuestionLabel } from "../../../../components/Questions/Components/QuestionLabel";
import { MaterialUiStyledSlider } from "../../../../components/MaterialUiStyledSlider";
import { useTranslate } from "@tolgee/react";

interface MatrxGridQuestionsProps {
    question: Question;
    placeholder: string;
}

export const MatrixGridQuestions = (props: MatrxGridQuestionsProps): JSX.Element => {
    const { placeholder, question } = props;
    const { t } = useTranslate();

    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        <p>{question.text}</p>
                    </div>
                </div>
            </div>
            <div className="matrix-options block-content-option">
                {question &&
                    question.options &&
                    question.options.map((option, ix) => {
                        return (
                            <React.Fragment key={ix}>
                                <div className={"matrix-grid row " + (ix > 0 ? "mt-1" : "")}>
                                    <div className="col-sm-12 top-container">
                                        <div className="matrix-title">{option.text}</div>
                                        {props.question.allowSkip === true && (
                                            <div className="matrix-skip-button" style={{ fontSize: "14px" }}>
                                                <label>
                                                    <input
                                                        disabled={true}
                                                        checked={false}
                                                        type="checkbox"
                                                        onChange={e => false}
                                                    />{" "}
                                                    {t("PROGRAM_MY_TRAINING_SKIP_THIS_QUESTION")}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"matrix-grid row matrix-layout-border d-block change-matrix-style"}>
                                    <p className={"block-content-text-inner change-descriptionY-style"}>
                                        {question.description}
                                    </p>
                                    {/* Call X-Axis */}
                                    <div className="col-md-12">
                                        <div className="matrix-slider mb-4">
                                            <MaterialUiStyledSlider
                                                //@ts-ignore
                                                labelColor={stringToColor(option.text)}
                                                //@ts-ignore
                                                value={question.minAnswer === null ? 0 : question.minAnswer}
                                                //@ts-ignore
                                                min={question.minAnswer === null ? 0 : question.minAnswer}
                                                //@ts-ignore
                                                max={question.maxAnswer}
                                                onChange={() => {}}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>

                                    <QuestionLabel
                                        min_answer_label={props.question.minAnswerLabel}
                                        max_answer_label={props.question.maxAnswerLabel}
                                    />
                                    <p className={"block-content-text-inner mb-5 change-descriptionY-style"}>
                                        {question.descriptionY}
                                    </p>
                                    {/* Call Y-Axis */}

                                    <div className="col-md-12">
                                        <div className="matrix-slider mb-4">
                                            <MaterialUiStyledSlider
                                                //@ts-ignore
                                                labelColor={stringToColor(option.text)}
                                                //@ts-ignore
                                                value={question.minAnswer === null ? 0 : question.minAnswer}
                                                //@ts-ignore
                                                min={props.question.minAnswer === null ? 0 : props.question.minAnswer}
                                                //@ts-ignore
                                                max={props.question.maxAnswer}
                                                onChange={() => {}}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <QuestionLabel
                                        min_answer_label={question.minAnswerYLabel}
                                        max_answer_label={question.maxAnswerYLabel}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>
        </div>
    );
};
