import { Answer, Group } from "@/api-client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

type SplitAnswerProps = {
    show: boolean;
    hide: () => void;
    answer: Answer;
    answersUpdate: (answers: Answer[]) => void;
    currentGroup: Group;
};

export const SplitAnswer = (props: SplitAnswerProps) => {
    const { show, hide, answer, answersUpdate } = props;
    const { t } = useTranslate();
    const [originalAnswer, setOriginalAnswer] = useState<Answer>(answer);
    const [answers, setAnswers] = useState<Answer[]>([]);

    const handleSplitAnswer = (answer: Answer) => {
        // @ts-ignore
        setAnswers([...answers, { ...answer, id: undefined, groupId: props.currentGroup.id }]);
    };

    const deleteAnswer = (index: number) => {
        setAnswers(answers.filter((a, i) => i !== index));
    };

    const changeAnswersText = (text: string, answer: Answer, index: number | undefined) => {
        if (index === undefined) {
            setOriginalAnswer({ ...answer, text, groupId: props.currentGroup.id });
        } else {
            setAnswers(answers.map((el: Answer, i: number) => (i === index ? { ...el, text } : el)));
        }
    };

    const saveSplitAnswers = () => {
        const allAnswers = [...answers, originalAnswer];
        answersUpdate(allAnswers);
    };

    return (
        <Modal show={show} size={"lg"} onHide={hide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Split Answer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="split-answers-container form-inline">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control mb-2 me-sm-2 col"
                        id="inlineFormInputName2"
                        value={originalAnswer.text}
                        onChange={e => changeAnswersText(e.target.value, answer, undefined)}
                    />
                    <PrimaryButton
                        eventName={{ object: "categorize", action: "splitanswer" }}
                        onClick={() => handleSplitAnswer(answer)}
                    >
                        Split
                    </PrimaryButton>
                </div>
                {answers &&
                    answers.map((m, i) => (
                        <div key={`splitted-answer-${i}`} className="split-answers-container form-inline">
                            <label className="sr-only" htmlFor="inlineFormInputName2">
                                Name
                            </label>
                            <input
                                type="text"
                                className="form-control mb-2 me-sm-2 col"
                                id="inlineFormInputName2"
                                value={m.text}
                                onChange={e => changeAnswersText(e.target.value, m, i)}
                            />
                            <PrimaryButton
                                eventName={{ object: "categorize", action: "deleteanswer" }}
                                onClick={() => deleteAnswer(i)}
                            >
                                Delete
                            </PrimaryButton>
                        </div>
                    ))}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton
                    eventName={{ object: "categorize", action: "updateanswer" }}
                    onClick={() => saveSplitAnswers()}
                >
                    Update Answers
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
