import { Question, QuestionOption } from "@/api-client";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { ContentLabel } from "../../../../components/ContentLabel";
import { Notify } from "../../../../components/Notify";
import { AppLoader } from "../../../../components/Spinner";
import { AddQuestionOptions } from "./AddQuestionOptions";
import { handleUpdateOptions } from "./MatrixGrid";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () => {
    return Yup.object().shape({
        low: Yup.number().typeError("Low's value must be number type"),
        high: Yup.number().typeError("High's value must be number type"),
    });
};

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

interface EditQuestionResponse {
    question: Question;
    isPending: boolean;
    isSuccess: boolean;
}

interface FormModel {
    questionId: number;
    low: number;
    lowLabel: string;
    high: number;
    highLabel: string;
    ratingDescription: string;
    allowSkip: boolean;
}

const MatrixScale = (props: EditQuestionProps): JSX.Element => {
    const api = createApi();
    const history = useHistory();
    const initialValues: FormModel = {
        // @ts-ignore

        questionId: props.question?.id,
        // @ts-ignore

        low: props.question?.minAnswer,
        // @ts-ignore

        lowLabel: props.question?.minAnswerLabel,
        // @ts-ignore

        high: props.question?.maxAnswer,
        // @ts-ignore

        highLabel: props.question?.maxAnswerLabel,
        // @ts-ignore

        ratingDescription: props.question?.description,

        allowSkip: props.question?.allowSkip,
    };
    const [editQuestion, setEditQuestion] = useState<EditQuestionResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        question: null,
        isSuccess: false,
    });

    const [updatedOptions, setUpdatedOptions] = useState<QuestionOption[]>([]);

    const getOptions = (options: QuestionOption[]) => {
        setUpdatedOptions(options);
    };

    const [submitted, setSubmitted] = useState(false);

    return (
        <>
            <AppLoader loading={editQuestion.isPending} className="position-absolute" />
            {editQuestion.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Question updated successfully." id="registersuccess" type="success" />
                    {/* <Redirect to="/sysadmin/clients" /> */}
                </Fragment>
            )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={(values, helpers) => {
                    setSubmitted(true);
                    setEditQuestion({ ...editQuestion, isPending: true });

                    var patch = createPatch(props.question, x => {
                        x.id = values.questionId;
                        x.minAnswer = values.low;
                        x.maxAnswer = values.high;
                        x.minAnswerLabel = values.lowLabel;
                        x.maxAnswerLabel = values.highLabel;
                        x.description = values.ratingDescription;
                        x.allowSkip = values.allowSkip;
                    });

                    if (patch && patch.length > 0) {
                        // Update profile
                        // @ts-ignore

                        api.questions.patch(props.question?.id, patch).then(
                            result => {
                                setEditQuestion({
                                    ...editQuestion,
                                    question: result,
                                    isPending: false,
                                    isSuccess: true,
                                });
                                // dispatch(push(`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/exercise/${props.exerciseId}/edit`));
                            },
                            error => {
                                setEditQuestion({ ...editQuestion, isPending: false });
                                history.push("/error");
                                console.log(error);
                            }
                        );
                    } else {
                        setEditQuestion({ ...editQuestion, isPending: false });
                    }
                    // Update Question Options pathc request API cal
                    handleUpdateOptions(api, updatedOptions, props.question.options);
                    setEditQuestion({ ...editQuestion, isPending: false });
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="matrixScaleForm" className="mt-2 editModuleForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Low</label>
                                    <Field
                                        name="low"
                                        type="text"
                                        className={"form-control" + (errors.low && touched.low ? " is-invalid" : "")}
                                    />
                                    {errors.low && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.low}
                                        </span>
                                    )}
                                </Col>
                                <Col>
                                    <label>Low Label</label>
                                    <Field
                                        name="lowLabel"
                                        type="text"
                                        className={
                                            "form-control" + (errors.lowLabel && touched.lowLabel ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.lowLabel && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.lowLabel}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>High</label>
                                    <Field
                                        name="high"
                                        type="text"
                                        className={"form-control" + (errors.high && touched.high ? " is-invalid" : "")}
                                    />
                                    {errors.high && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.high}
                                        </span>
                                    )}
                                </Col>
                                <Col>
                                    <label>High Label</label>
                                    <Field
                                        name="highLabel"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.highLabel && touched.highLabel ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.highLabel && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.highLabel}
                                        </span>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Rating Description</label>
                                    <Field
                                        name="ratingDescription"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.ratingDescription && touched.ratingDescription ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.ratingDescription && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.ratingDescription}
                                        </span>
                                    )}
                                </Col>
                            </Row>

                            <div className="form-check mb-5">
                                <Field type="checkbox" className="form-check-input" name="allowSkip" />
                                <label className="form-check-label">Allow user to skip statements</label>
                            </div>

                            <ContentLabel text="Answer options" />
                            <AddQuestionOptions
                                question={props.question}
                                updatedOptions={options => getOptions(options)}
                            />
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={editQuestion.isPending}>
                                    Update
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default MatrixScale;
