import { RouteObject } from "react-router-dom";
import AccountOwner from "../account-owner";
import AccountOwnerTraining from "../account-owner/AccountOwnerTraining";
import Planning from "../account-owner/Planning";
import { SlideShow } from "../account-owner/Resources";
import { AoUsers } from "../account-owner/OrganisationPlanning/Users/Users";
import { ACCOUNT_OWNER_ROLE, AGENT_ROLE, SYSADMIN_ROLE } from "../constants";
import { EditOrganisationLayout } from "../account-owner/OrganisationPlanning/EditOrganisationLayout";
import { UserRoles } from "@/api-client";
import { ProtectedRouteComponentWithOutlet } from "./ProtectedRouteComponent";
import { route } from "./routebuilder";

const ManagerAndAdminAccess: UserRoles[] = [ACCOUNT_OWNER_ROLE, SYSADMIN_ROLE, AGENT_ROLE];

export const OldAccountOwnerRoutes: RouteObject = {
    element: <ProtectedRouteComponentWithOutlet roles={ManagerAndAdminAccess} />,
    children: [
        route("/account-owner/resources/:template?/:courseId?", <SlideShow />),
        route("/account-owner/training", <AccountOwnerTraining />),
        route("/account-owner/edit/organisation-planning/:template", <EditOrganisationLayout />),
        route("/account-owner/edit/organisation-planning/:template/:groupTypeId", <EditOrganisationLayout />),
        route(
            "/account-owner/edit/organisation-planning/group-type/:groupTypeId/:template/:groupId",
            <EditOrganisationLayout />
        ),
        route(
            "/account-owner/edit/organisation-planning/:template/:demographicQuestionId/edit",
            <EditOrganisationLayout />
        ),
        route("/account-owner/edit/organisation-planning/round/:edit?/:roundId?", <EditOrganisationLayout />),
        route("/account-owner", <AccountOwner />),
        route("/account-owner/planning/:courseId?", <Planning />),
        route("/account-owner/users/edit/:userId", <AoUsers />),
    ],
};
