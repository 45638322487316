import { useEffect, useState } from "react";
import { useHistory, createApi } from "@/common";
import { UrlFactory } from "../../../routing/UrlFactory";
import { CardBlock, RenderIf } from "@/components";
import { DemographicQuestion } from "@/api-client";
import { EditQuestion } from "./EditQuestion";

type DemographicQuestionsPageProps = {
    id?: number;
};

export const EditDemographicQuestionsPage = (props: DemographicQuestionsPageProps) => {
    const api = createApi();
    const history = useHistory();

    const [state, setState] = useState<{
        isLoading: boolean;
        question?: DemographicQuestion;
    }>({ isLoading: true });

    const getDemographicQuestions = async (id: number) => {
        const question = await api.demographicQuestions.get(id);
        setState({ isLoading: false, question: question });
    };

    useEffect(() => {
        if (props.id) {
            getDemographicQuestions(props.id).catchWithToast();
        } else {
            setState({ isLoading: false });
        }
    }, []);

    return (
        <CardBlock>
            <RenderIf show={!state.isLoading}>
                <EditQuestion
                    question={state.question}
                    onSaved={() => history.push(UrlFactory.accountOwner.demographicQuestions.create({}))}
                />
            </RenderIf>
        </CardBlock>
    );
};
