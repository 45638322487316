import { AnswerCategory, Chapter, Course, Group, Module, Question } from "@/api-client";
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "@/common";
import { createApi } from "../../../common/api";
import { getColorByIndexRepeatingSeq } from "../../../common/colors";
import { useAnswers, useCurrentCourse, useCurrentGroup, useQuestions } from "../../../common/courses/context";
import { createPatch } from "../../../common/patchHelper";
import { useCurrentUser } from "../../../common/user/context";
import { UserHasGroupLeaderRole } from "../../../common/user/utils";
import { BreadCrumbLinks, Breadcrumbs } from "../../../components/BreadCrumbs";
import Slideshow from "../../../components/Slideshow";
import { findModuleBlocksIds } from "../../helpers";
import { getPresentationLink } from "../../Presentation/helpers";
import { QuestionTypes } from "../../QuestionTypes";
import { QuestionCategoriesEditor } from "../QuestionCategoriesEditor";
import { ColoredCategory } from "../QuestionCategoriesEditor/ColoredCategory";
import QuestionRatingBlock from "../QuestionRatingBlock";
import "./style.scss";
import { uuid } from "@/common";

/*  eslint-disable react-hooks/exhaustive-deps */

type QuestionLink = string | null;
type QuestionLinks = {
    prev: QuestionLink;
    next: QuestionLink;
};

type RouteParams = {
    chapterId?: string;
    moduleId: string;
    questionId: string;
    groupId: string;
};

// @ts-ignore
export const WorkshopCategorization = () => {
    const params = useParams<RouteParams>();
    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();
    const questionsState = useQuestions();
    const history = useHistory();
    const currentUser = useCurrentUser();

    //@ts-ignore
    const allAnswers = useAnswers(parseInt(params.groupId));

    // const [nav, setNav] = useState<NavItem[]>();
    const { t } = useTranslate();
    const api = createApi();

    //@ts-ignore
    const moduleId = parseInt(params.moduleId);
    // @ts-ignore
    const chapterId = parseInt(params.chapterId);
    //@ts-ignore
    const questionId = parseInt(params.questionId);

    // Redirect to group home if group isn't allowed to access implementation/transformation
    useEffect(() => {
        if (!currentGroup) {
            return;
        }
        // @ts-ignore
        if (currentGroup.courseIds.length === 0) {
            console.info("No courses in group, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }

        if (!currentCourse || !currentUser) {
            return;
        }

        const isGroupLeader = UserHasGroupLeaderRole(currentUser, currentGroup);

        if (currentCourse.courseType !== "Training" || !isGroupLeader) {
            console.info("Course is not training or user not group leader, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }
    }, [currentCourse, currentGroup, currentUser, history]);

    const questions = useMemo(() => {
        if (currentCourse != null && questionsState != null) {
            const moduleBlockIds = findModuleBlocksIds(currentCourse, chapterId, moduleId);
            // Since the same template is used for Single cholice and multichoice questions I think check should be used here  || q.questionTypeId === QuestionTypes.SINGLE_CHOICE || q.questionTypeId === QuestionTypes.MULTI_CHOICE
            const getQuestions =
                moduleBlockIds &&
                questionsState.filter(q => q.questionTypeId === QuestionTypes.TEXT && q.enableCategorisation === true);
            return getQuestions;
        }
    }, [currentCourse, questionsState, chapterId, moduleId]);

    const currentQuestion = useMemo(() => {
        if (questions && questionId && allAnswers) {
            return {
                ...questions.find(x => x.id === questionId),
                answers: allAnswers.filter(a => a.questionId === questionId),
            };
        }

        return undefined;
    }, [questions, questionId, allAnswers]);

    const [currentChapter, setCurrentChapter] = useState<Chapter>();
    const [currentModule, setCurrentModule] = useState<Module>();

    useEffect(() => {
        if (!currentCourse) {
            return;
        }

        // Put in variable to avoid waiting for async set
        // @ts-ignore
        const course = currentCourse.chapters.find(x => x.id === chapterId);

        // @ts-ignore
        const questionsWithModuleId =
            // @ts-ignore
            currentCourse.chapters
                .flatMap(c =>
                    c.modules.flatMap(m =>
                        m.blocks.flatMap(
                            b =>
                                b.questions &&
                                b.questions.flatMap(q => ({
                                    questionId: q.id,
                                    moduleBlockId: q.moduleBlockId,
                                    moduleId: m.id,
                                }))
                        )
                    )
                )
                .filter(q => q !== null && q !== undefined)
                .flat();

        // @ts-ignore
        const getModuleIdByQuestionId = questionsWithModuleId.find(q => q.questionId === questionId)?.moduleId;

        setCurrentChapter(course);
        // @ts-ignore
        setCurrentModule(course.modules.find(x => x.id === getModuleIdByQuestionId));
    }, [currentCourse, questionId, chapterId, moduleId]);

    /*
     CATEGORIES
     */
    const [coloredCategories, setColoredCategories] = useState<ColoredCategory[]>([]);

    const refreshCategories = () => {
        // @ts-ignore
        api.answerCategories.query(currentQuestion.id, currentGroup.id).then(categories => {
            // @ts-ignore
            let categoriesForGroup = categories.filter(x => x.groupId === currentGroup.id);
            setColoredCategories(
                categoriesForGroup.map((c, i) => ({ ...c, color: getColorByIndexRepeatingSeq(i, "dark") }))
            );
        });
    };

    useEffect(() => {
        if (!(currentQuestion && currentGroup)) {
            return;
        }

        refreshCategories();
    }, [currentQuestion, currentGroup]);

    const addCategory = (name: string) => {
        const newCategory: AnswerCategory = {
            id: uuid(),
            name,
            questionId,
            // @ts-ignore
            groupId: currentGroup.id,
            createdAt: new Date(),
            updatedAt: new Date(),
        };

        // Optimistic update.
        setColoredCategories([
            ...coloredCategories,
            { ...newCategory, color: getColorByIndexRepeatingSeq(coloredCategories.length, "dark") },
        ]);

        api.answerCategories.post(newCategory);
    };

    const removeCategory = (removedCategory: AnswerCategory) => {
        // Move all questions in category to uncategoriezed by setting it to null

        // @ts-ignore
        const patchedAnswers = currentQuestion.answers
            .filter(a => a.answerCategoryId === removedCategory.id)
            .map(answer => {
                const patch = createPatch(answer, g => {
                    g.answerCategoryId = null;
                });
                // Remove category
                setColoredCategories(coloredCategories.filter(c => c.name !== removedCategory.name));
                return api.answers.patch(answer.id, patch);
            });

        Promise.all(patchedAnswers).then(() => {
            // Optimistic delete
            setColoredCategories(coloredCategories.filter(x => x.id !== removedCategory.id));

            // Call API
            return api.answerCategories.delete(removedCategory.id);
        });
    };

    const renameCategory = (renamedCategory: AnswerCategory, newName: string) => {
        setColoredCategories(coloredCategories.map(c => (c === renamedCategory ? { ...c, name: newName } : c)));
        renamedCategory.name = newName;
        api.answerCategories.put(renamedCategory.id, renamedCategory);
    };

    const [breadcrumb, setBreadcrumb] = useState<BreadCrumbLinks[]>();

    useEffect(() => {
        if (!(currentChapter && currentModule && questionsState && currentGroup)) {
            return;
        }

        const links: BreadCrumbLinks[] = [
            { name: "Overview", path: `/groups/${currentGroup?.id}/workshop` },
            {
                name: `${currentChapter.name} – ${currentModule.name}`,
                // @ts-ignore
                path: undefined,
                // @ts-ignore
                dropdownItems: currentCourse.chapters.flatMap(chapter => {
                    // Only show modules that have questions with categorization
                    // @ts-ignore
                    const getChildQuestions = (m: Module) =>
                        m.blocks
                            .filter(b => b.questions)
                            .flatMap(b => b.questions)
                            .filter(x => x.enableCategorisation);

                    // @ts-ignore
                    return chapter.modules.map((module: Module) => ({
                        name: `${chapter.name} – ${module.name}`,
                        path:
                            getChildQuestions(module).length > 0
                                ? // @ts-ignore
                                  `/groups/${currentGroup.id}/workshop/chapter/${chapter.id}/module/${
                                      module.id
                                  }/question/${getChildQuestions(module)[0].id}`
                                : undefined,
                    }));
                }),
            },
        ];

        setBreadcrumb(links);
    }, [currentChapter, moduleId, currentModule, questionsState, currentGroup, currentCourse?.chapters]);

    const getQuestionPath = (question: Question, group: Group, course: Course): QuestionLink => {
        if (question === undefined) {
            return null;
        }

        const chapters = course.chapters;
        // @ts-ignore
        const modules = chapters.flatMap(c => c.modules);

        // @ts-ignore
        const module = modules.find(m => m.blocks.find(b => b.id === question.moduleBlockId));
        // @ts-ignore
        const chapter = chapters.find(c => c.modules.find(m => m.id === module.id));

        // @ts-ignore
        return `/groups/${group.id}/workshop/chapter/${chapter.id}/module/${module.id}/question/${question.id}`;
    };

    const [questionLinks, setQuestionLinks] = useState<QuestionLinks>({ prev: null, next: null });

    useEffect(() => {
        const currentQuestionIndex = questions && questions.findIndex(q => q.id === currentQuestion?.id);
        // @ts-ignore
        const prevQuestion = currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : undefined;
        const nextQuestion =
            // @ts-ignore
            currentQuestionIndex > -1 && currentQuestionIndex < questions?.length - 1
                ? // @ts-ignore
                  questions[currentQuestionIndex + 1]
                : undefined;

        // @ts-ignore
        const prevLink = getQuestionPath(prevQuestion, currentGroup, currentCourse);
        // @ts-ignore
        const nextLink = getQuestionPath(nextQuestion, currentGroup, currentCourse);
        setQuestionLinks({
            prev: prevLink,
            next: nextLink,
        });
    }, [currentQuestion]);

    return (
        <div className="workshop-categorisation">
            <Breadcrumbs breadCrumb={breadcrumb} />

            <Slideshow
                // @ts-ignore
                previousLink={questionLinks.prev}
                // @ts-ignore
                nextLink={questionLinks.next}
            >
                <>
                    <Row>
                        <Col md={8}>
                            <h2 className="mb-3">{currentQuestion && currentQuestion.text}</h2>
                        </Col>
                        <Col md={4} className="text-end">
                            <Link to={getPresentationLink(chapterId, moduleId, questionId, currentGroup?.id)}>
                                {t("WORKSHOP_CATEGORIES_CATEGORISED_PREVIEW_PRESENTATION")}
                            </Link>
                        </Col>
                    </Row>

                    {currentQuestion && currentGroup && coloredCategories && (
                        <>
                            <QuestionCategoriesEditor
                                answers={currentQuestion.answers}
                                categories={coloredCategories}
                                addCategory={addCategory}
                                removeCategory={removeCategory}
                                renameCategory={renameCategory}
                            />
                            <QuestionRatingBlock
                                // @ts-ignore
                                question={currentQuestion}
                                categories={coloredCategories}
                                currentGroup={currentGroup}
                                questionAnswers={currentQuestion.answers}
                            />
                        </>
                    )}
                </>
            </Slideshow>
        </div>
    );
};

export default WorkshopCategorization;
