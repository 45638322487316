﻿import { SelectItem } from "@mantine/core";
import { FormikValues, useFormikContext } from "formik";
import React, { useState } from "react";
import { FormInput } from "@/components";

type DependentNativeSelectProps = {
    fieldName: string;
    label: string;
    dependentOn: string;
    createOptions: (selectedDependentValue: string) => SelectItem[];
};

export const DependentNativeSelect = (props: DependentNativeSelectProps): JSX.Element => {
    const context = useFormikContext<FormikValues>();
    const [options, setOptions] = useState<SelectItem[]>([]);

    React.useEffect(() => {
        const newOptions = props.createOptions(context.values[props.dependentOn] as string);
        setOptions(newOptions);
        context.values[props.fieldName] = newOptions[0]?.value;
    }, [context.values[props.dependentOn]]);

    return <FormInput.NativeSelect label={props.label} fieldName={props.fieldName} options={options} />;
};
