import { useTranslate } from "@tolgee/react";
import { ModuleBlock } from "@/api-client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createApi } from "../../../../common/api";
import { Breadcrumbs } from "../../../../components/BreadCrumbs";
import RenderIf from "../../../../components/render-if/render-if";
import { AppLoader } from "../../../../components/Spinner";
import Sidebar from "../../../components/Sidebar";
import { CourseMenu } from "../../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../../helper";
import AdditionalResources from "./AdditionalResources";
import ContentModuleBlock from "./ContentModuleBlock";
import QuestionModuleBlock from "./QuestionModuleBlock";
import { CardBlock } from "../../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    courseId: string;
    chapterId: string;
    moduleId: string;
    moduleBlockId: string;
};

interface ModuleBlocksResponse {
    moduleBlock: ModuleBlock;
    isPending: boolean;
}

const Index = (): JSX.Element => {
    const params = useParams<RouteParams>();
    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore

    const chapterId = parseInt(params.chapterId);
    //@ts-ignore

    const moduleId = parseInt(params.moduleId);
    //@ts-ignore

    const moduleBlockId = parseInt(params.moduleBlockId);
    const api = createApi();
    const [course] = useCurrentCourseFromParams();
    const { t } = useTranslate();

    const nav = CourseMenu(course);

    const [fetchModuleBlock, setFetchModuleBlock] = useState<ModuleBlocksResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        moduleBlock: null,
    });

    useEffect(() => {
        if (moduleBlockId) {
            getModuleBlockById(moduleBlockId);
        }
    }, [moduleBlockId]);

    const getModuleBlockById = (moduleBlockId: number) => {
        setFetchModuleBlock({ ...fetchModuleBlock, isPending: true });
        //API start loading here
        //Store the reuslt in setCourse local state
        api.moduleBlocks.get(moduleBlockId).then(
            result => {
                setFetchModuleBlock({ ...fetchModuleBlock, moduleBlock: result, isPending: false });
            },
            error => {
                // corse loading api is done and error
                console.log(error);
                setFetchModuleBlock({ ...fetchModuleBlock, isPending: false });
            }
        );
    };

    const isLoading = fetchModuleBlock.isPending;
    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const moduleDetails = chapterDetails?.modules?.find(m => m.id === moduleId);
    const blockDetails = moduleDetails?.blocks?.find(b => b.id === moduleBlockId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/edit` },
        {
            name: moduleDetails?.name,
            path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/module/${moduleDetails?.id}/edit`,
        },
        { name: blockDetails?.title ? blockDetails?.title : "Add Module Block", path: null },
    ];

    return (
        <>
            {
                //@ts-ignore
                moduleDetails && <Breadcrumbs breadCrumb={links} />
            }
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar
                            children={nav}
                            name={course?.name}
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlockId={moduleBlockId}
                        />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <AppLoader loading={isLoading} className="position-absolute" />
                    <CardBlock mb="xl">
                        <ContentModuleBlock
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlockId={moduleBlockId}
                            moduleBlock={fetchModuleBlock.moduleBlock}
                        />
                    </CardBlock>
                    <RenderIf show={fetchModuleBlock && fetchModuleBlock.moduleBlock != null}>
                        <CardBlock mb="xl">
                            <AdditionalResources
                                courseId={courseId}
                                chapterId={chapterId}
                                moduleId={moduleId}
                                moduleBlockId={moduleBlockId}
                            />
                        </CardBlock>
                    </RenderIf>
                    <RenderIf
                        show={
                            fetchModuleBlock &&
                            fetchModuleBlock.moduleBlock !== null &&
                            fetchModuleBlock.moduleBlock?.type === "ExerciseBlock"
                        }
                    >
                        <CardBlock mb="xl">
                            <QuestionModuleBlock
                                moduleBlockId={moduleBlockId}
                                courseId={courseId}
                                chapterId={chapterId}
                                moduleId={moduleId}
                            />
                        </CardBlock>
                    </RenderIf>
                </div>
            </div>
        </>
    );
};

export default Index;
