import React, { createRef, useEffect } from "react";
import { useTranslate } from "@tolgee/react";
import scrollToElement from "scroll-to-element";
import { AdditionalResource, ModuleBlock } from "@/api-client";
import { VideoPlayer } from "../../components/VideoPlayer";
import { ModuleBlockDescription } from "./ModuleBlockDescription";
import { ModuleHeaderInformation } from "./ModuleHeaderInformation";
import { CardBlockWithButtonContainer } from "../Components/CardBlockWithButtonContainer";
import { PrimaryButton } from "../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

interface CourseProps {
    moduleBlock: ModuleBlock;
    isLast: boolean;
    id: string;
    sequence: number;
    moduleBlockId?: number;
    moduleProgressPending?: boolean;
    additionalResources: AdditionalResource[];
    currentModuleBlockId: number;

    onClick(): void;
}

export const ContentBlock = (props: CourseProps): JSX.Element => {
    const { t } = useTranslate();
    const { moduleBlock, isLast, onClick, id, sequence, moduleBlockId, additionalResources, currentModuleBlockId } =
        props;
    const tempSpace: any = createRef();
    const buttonRef: any = createRef();
    useEffect(() => {
        if (isLast) {
            if (tempSpace.current && buttonRef.current) {
                tempSpace.current.style.display = "block";
            } else if (tempSpace.current && buttonRef.current) {
                tempSpace.current.style.display = "none";
            }
        }
        scrollToElement(`#${id}`, {
            offset: -65,
        });
    }, [isLast, id]);
    return (
        <>
            <CardBlockWithButtonContainer
                buttons={
                    <>
                        {moduleBlock &&
                            (moduleBlock.questions === null ||
                                (moduleBlock.questions && moduleBlock.questions.length === 0)) && (
                                <div ref={buttonRef}>
                                    {moduleBlockId === moduleBlock.id && (
                                        <PrimaryButton
                                            eventName={{ object: "mytraining", action: "contentnext" }}
                                            onClick={onClick}
                                            loading={props.moduleProgressPending}
                                        >
                                            {isLast ? t("PROGRAM_MY_TRAINING_FINISH_MODULE") : t("COMMON_NEXT")}
                                        </PrimaryButton>
                                    )}
                                </div>
                            )}
                    </>
                }
            >
                <ModuleHeaderInformation
                    // @ts-ignore

                    title={moduleBlock?.title}
                    id={id}
                    sequence={sequence}
                    navigationLabel={moduleBlock?.navigationLabel}
                />
                <div className="row block">
                    <ModuleBlockDescription
                        // @ts-ignore

                        videoId={moduleBlock?.videoId}
                        // @ts-ignore

                        description={moduleBlock?.description}
                        // @ts-ignore

                        completionTime={moduleBlock?.completionTime}
                        additionalResources={additionalResources}
                        currentModuleBlockId={currentModuleBlockId}
                    />

                    {moduleBlock && moduleBlock.video && (
                        <VideoPlayer
                            // @ts-ignore
                            url={moduleBlock.video?.url}
                            // @ts-ignore
                            id={moduleBlock.video?.id}
                            breakPoints={moduleBlock.videoBreakPoints}
                        />
                    )}
                </div>
            </CardBlockWithButtonContainer>

            <div ref={tempSpace} style={{ display: "none" }} id="tempSpace" />
        </>
    );
};
