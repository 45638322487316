import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { MRT_ColumnDef } from "mantine-react-table";
import { Table, RowActionConfig, RowActions } from "@/components";
import { createApi, UrlFactory } from "@/common";

type Program = {
    id: number;
    name: string;
};

const ProgramsList = () => {
    const { t } = useTranslate();
    const [programs, setPrograms] = useState<Program[] | null>(null);

    useEffect(() => {
        createApi()
            .courses.query(undefined)
            .then(courses =>
                setPrograms(
                    courses
                        .filter(c => c.status === "Active")
                        .map(course => ({
                            id: course.id,
                            name: course.name,
                        }))
                )
            )
            .catchWithToast();
    }, []);

    const columns = useMemo<MRT_ColumnDef<Program>[]>(
        () => [
            {
                header: t("COMMON_NAME"),
                accessorKey: "name",
            },
        ],
        []
    );

    const rowActions: RowActionConfig<Program> = {
        renderRowActions: ({ row }) => (
            <RowActions.ArrowLink to={UrlFactory.accountOwner.program.create({ programId: row.original.id })} />
        ),
        size: 10,
    };

    return <Table initialColumnFilterState={[]} columns={columns} data={programs} rowActions={rowActions} />;
};

export const ProgramsPage = () => {
    return <ProgramsList />;
};
