import { Client, Course } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createApi } from "../../../common/api";
import { ContentLabel } from "../../../components/ContentLabel";
import { AppLoader } from "../../../components/Spinner";
import StyledCheckbox from "../../../components/StyledCheckbox";
import { CardBlock } from "../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

type AssignCourseProps = {
    clientId: number;
    client: Client;
};

type CourseState = {
    courses: Course[];
    isPending: boolean;
};

type AssignedClientCourse = {
    clientCourseId?: number;
    courseId: number;
};

const AssignCourse = (props: AssignCourseProps) => {
    const { clientId, client } = props;
    const api = createApi();

    const [courseState, setCourseState] = useState<CourseState>({
        // @ts-ignore
        courses: null,
        isPending: false,
    });

    // @ts-ignore
    const [tempCourses, setTempCourses] = useState<Course[]>(null);

    const [assignedCourses, setAssignedCourses] = useState<AssignedClientCourse[]>();

    useEffect(() => {
        if (client && client.id) {
            fetchAllCourses();
            fetchClientCourses();
        }
    }, [client]);

    const fetchClientCourses = () => {
        api.clientCourses.query(client.id).then(
            response => {
                setAssignedCourses(
                    response &&
                        response.map(c => {
                            return {
                                courseId: c.courseId,
                                clientCourseId: c.id,
                            };
                        })
                );
            },
            error => {
                console.log(error);
            }
        );
    };

    const fetchAllCourses = () => {
        setCourseState({
            ...courseState,
            isPending: true,
        });
        api.courses.query(false).then(
            response => {
                const activeCourses = response.filter(c => c.status === "Active");
                setCourseState({
                    ...courseState,
                    courses: activeCourses,
                    isPending: false,
                });
                setTempCourses(activeCourses);
            },
            error => {
                setCourseState({
                    ...courseState,
                    isPending: false,
                });
                console.log(error);
            }
        );
    };

    const assignCourseToClient = (value: boolean, courseId: number, clientCourseId?: number) => {
        let selectedCourses = [];
        if (value === true) {
            // @ts-ignore
            selectedCourses = [...assignedCourses, { courseId }];
            setAssignedCourses(selectedCourses);
            api.clientCourses
                .post({
                    clientId,
                    courseId,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                })
                .then(
                    response => {
                        fetchClientCourses();
                        console.log(response);
                    },
                    error => {
                        console.log(error);
                    }
                );
        } else {
            // @ts-ignore
            selectedCourses = assignedCourses.filter(a => a.courseId !== courseId);
            setAssignedCourses(selectedCourses);
            // @ts-ignore
            api.clientCourses.delete(clientCourseId).then(
                response => {
                    fetchClientCourses();
                    console.log(response);
                },
                error => console.log(error)
            );
        }
    };

    const filterCourses = (searchVal: string) => {
        setTempCourses([...courseState.courses]);
        if (searchVal) {
            setTempCourses([
                ...courseState?.courses?.filter(
                    c =>
                        c?.name?.toLowerCase().includes(searchVal?.toLowerCase()) ||
                        c?.adminName?.toLowerCase()?.includes(searchVal.toLowerCase())
                ),
            ]);
        }
    };

    return (
        <Row>
            {/* <div className='col-md-4'>
             </div> */}
            <div className="col-md-12">
                <CardBlock mb="xl">
                    <AppLoader className="position-absolute" loading={courseState.isPending} />
                    <ContentLabel text="Assign Courses" />
                    <Row>
                        <Col md={5}>
                            <input
                                className="form-control mt-2 mb-4"
                                onChange={e => filterCourses(e.target.value)}
                                type="text"
                                placeholder="Filter Courses"
                            />
                        </Col>
                        <Col md={12}>
                            <p>
                                Select the courses that this client has purchased. The account owner will then be able
                                to assign it to any of their groups and students.
                            </p>
                        </Col>
                    </Row>
                    <table className="table table-borderless table-striped">
                        <thead>
                            <tr>
                                <th>Course Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tempCourses &&
                                tempCourses
                                    ?.sort((a, b) =>
                                        a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
                                    )
                                    .map(c => {
                                        const isCourseAssigned =
                                            assignedCourses && assignedCourses.find(x => x.courseId === c.id);
                                        const clientCourseId =
                                            isCourseAssigned !== undefined
                                                ? isCourseAssigned.clientCourseId
                                                : undefined;
                                        return (
                                            <tr key={c.id}>
                                                <td>
                                                    <StyledCheckbox
                                                        className="mt-2"
                                                        //@ts-ignore
                                                        checked={isCourseAssigned ? true : false}
                                                        onChange={value =>
                                                            //@ts-ignore
                                                            assignCourseToClient(value, c.id, clientCourseId)
                                                        }
                                                    />
                                                    <span className="mx-5">{c.adminName ? c.adminName : c.name}</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                        </tbody>
                    </table>
                </CardBlock>
            </div>
        </Row>
    );
};

export default AssignCourse;
