﻿import { Stack, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { AppLoader, Form, ModalConfig, ModalProps } from "@/components";
import { createApi, createEmailOrPhoneNumberValidator, useCurrentClient } from "@/common";
import { AddUserMultiSelectInput, Username } from "../../Users/AddUsers/AddUserMultiSelectInput";
import { UsernameDto } from "@/api-client";
import { getUsernames } from "../../getUsernames";

const validator = yup.object({
    usernames: yup
        .array()
        .of(createEmailOrPhoneNumberValidator("ACCOUNT_OWNER_SOME_EMAILS_ARE_NOT_CORRECTLY_FORMATTED").required())
        .min(1, "ACCOUNT_OWNER_AT_LEAST_ONE_EMAIL_IS_REQUIRED")
        .required(),
});

type FormSchema = yup.InferType<typeof validator>;

const initialValues: FormSchema = {
    usernames: [],
};

type AddTeamMemberProps = ModalProps<{ teamId: number; teamMemberIds: number[] }>;

const AddTeamMember = (props: AddTeamMemberProps) => {
    const { t } = useTranslate();
    const [usernames, setUsernames] = useState<Username[] | undefined>();

    const api = createApi();
    const clientId = useCurrentClient().id;

    useEffect(() => {
        api.users
            .query(undefined, undefined, undefined, undefined)
            .then(users => {
                const nonTeamMembers = users
                    .filter(u => !props.teamMemberIds.includes(u.id))
                    .filter(u => u.clientId === clientId);

                return setUsernames(getUsernames(nonTeamMembers));
            })
            .catchWithToast();
    }, []);

    if (usernames === undefined) {
        return <AppLoader loading />;
    }

    return (
        <Form<FormSchema>
            initialValues={initialValues}
            validationSchema={validator}
            submitLabel="ACCOUNTOWNER_EDIT_TEAMS_ADD_MEMBER"
            eventName={{ object: "ao_addteammember", action: "save" }}
            onSubmit={async values => {
                api.accountOwners
                    .addUsers(
                        clientId,
                        props.teamId,
                        values.usernames.map(x => new UsernameDto(x))
                    )
                    .then(() => props.onClose())
                    .catchWithToast();
            }}
        >
            <Stack>
                <div>
                    <Text color="dimmed" size="xs" mb="sm">
                        {t("ACCOUNTOWNER_ADD_EMPLOYEES_TIP_TEXT")}
                    </Text>
                    <AddUserMultiSelectInput
                        label="ACCOUNT_OWNER_ADD_EMAILS_OR_PHONE_NUMBERS"
                        fieldName="usernames"
                        existingUsers={usernames}
                        showExistingUsers
                    />
                </div>
            </Stack>
        </Form>
    );
};

export const AddTeamMemberModal: ModalConfig<AddTeamMemberProps> = {
    content: AddTeamMember,
    title: "ACCOUNTOWNER_EDIT_TEAMS_ADD_MEMBER",
};
