﻿import { Badge, Card, createStyles, Divider, Flex, Group, Image, Text } from "@mantine/core";
import { RenderIf, useModal } from "@/components";
import { Icons } from "@/common";
import { ProgramDetailsModal, UrlType } from "./ProgramDetails";
import { ProgramCardContainer } from "./ProgramCardContainer";

const useStyles = createStyles(() => ({
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
    },
}));

type ProgramCardProps = {
    imageUrl: string;
    title: string;
    shortDescription: string | null;
    experts: string[];
    description: string;
    videoUrl: string | null;
    languages: string[];
    numberOfModules: number;
    breadText: { title: string; text: string }[];
    onOpen?: () => void;
};

const CardBadge = (props: { icon: React.ReactNode; text: string | number }) => {
    return (
        <Badge>
            <Flex gap={3} align="center">
                {props.icon}
                <Text> {props.text} </Text>
            </Flex>
        </Badge>
    );
};

export const ProgramCard = (props: ProgramCardProps) => {
    const { classes } = useStyles();
    const { open } = useModal(ProgramDetailsModal(props.title));

    return (
        <ProgramCardContainer
            onClick={() => {
                if (props.onOpen) {
                    props.onOpen();
                }

                const getUrl = (): UrlType => {
                    if (props.videoUrl !== null) {
                        return { value: props.videoUrl, type: "video" };
                    }

                    return { value: props.imageUrl, type: "image" };
                };

                open({
                    shortDescription: props.shortDescription === null ? props.description : props.shortDescription,
                    url: getUrl(),
                    experts: props.experts,
                    languages: props.languages,
                    numberOfModules: props.numberOfModules,
                    breadText: props.breadText,
                });
            }}
        >
            <Card.Section>
                <Image height={160} src={props.imageUrl} alt="Image" />
            </Card.Section>
            <div className={classes.content}>
                <div>
                    <Group mt="md" mb="xs">
                        <Text weight={500}>{props.title}</Text>
                    </Group>

                    <Text size="sm" color="dimmed">
                        {props.description}
                    </Text>
                </div>
                <div>
                    <Divider my="md" />

                    <Flex justify="space-between">
                        <Group spacing="xs">
                            {props.experts.map(expert => (
                                <CardBadge key={expert} icon={<Icons.User size={16} />} text={expert} />
                            ))}
                        </Group>
                        <RenderIf show={props.numberOfModules > 0}>
                            <CardBadge icon={<Icons.Modules size={16} />} text={props.numberOfModules} />
                        </RenderIf>
                    </Flex>
                </div>
            </div>
        </ProgramCardContainer>
    );
};
