import React from "react";
import { Client, User } from "@/api-client";

export type AuthContextProps = {
    user: User | null;
    token: string | null;
    client: Client | null;
    signIn: (token: string) => Promise<() => void>;
    refresh: (user: User) => void;
    clearImpersonation: () => Promise<void>;
    impersonate: (data: { type: "ao"; clientId: number } | { type: "user"; userId: number }) => Promise<void>;
    signOut: () => void;
    isImpersonating: () => boolean;
};

export const AuthContext = React.createContext<AuthContextProps | null>(null);
