import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useHistory, yupExtensions, TFunction } from "@/common";
import { FullLogo } from "../../assets/index";
import { createApi } from "../../common/api";
import { useQuery } from "../../common/utils";
import { showToast } from "../../components/Notify";
import RenderIf from "../../components/render-if/render-if";
import { AppLoader, PrimaryButton } from "@/components";
import { SUPPORT_EMAIL } from "../../constants";
import { hashPassword } from "./helper";
import { LoginContainer } from "../../login/LoginContainer";
import "./style.scss";
import { useAuth } from "../../auth/useAuth";
import { showLoginErrorNotification } from "../../components/showLoginErrorNotification";
import { UrlFactory } from "../../routing/UrlFactory";
import { TokenStatus } from "@/api-client";
import { customEvents } from "../../analytics/customEvents/customEvents";
/*  eslint-disable react-hooks/exhaustive-deps */

// @ts-ignore
const ChangePasswordSchema = (t: TFunction) =>
    Yup.object().shape({
        password: yupExtensions.passwordValidator(
            t("RESET_PASSWORD_PASSWORD_REQUIRED"),
            t("RESET_PASSWORD_PASSWORD_VALID")
        ),
        //confirmPassword: Yup.string().required(t("RESET_PASSWORD_CONFIRM_PASSWORD_REQUIRED")),
        confirmPassword: Yup.string()
            .required(t("RESET_PASSWORD_CONFIRM_PASSWORD_REQUIRED"))
            .when("password", {
                // eslint-disable-next-line no-unneeded-ternary
                is: (val: string | undefined) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], t("RESET_PASSWORD_CONFIRM_PASSWORD_MATCH")),
            }),
    });

interface SubmitFormValues {
    password: string;
    confirmPassword: string;
}

const DisplayInvalidPasswordLink = (): JSX.Element => {
    const { t } = useTranslate();
    return (
        <div className="d-flex justify-content-center h-100">
            <div className="reset-password-container">
                <div className="pl-3 pr-3 pt-3">
                    <h5 className="text-center">{t("RESET_PASSWORD_LINK_INVALID_TITLE")}</h5>
                    <p className="text-left mt-3">{t("RESET_PASSWORD_LINK_EXPIRED")}</p>
                </div>
                <div className="pl-3 pr-3 pt-3 text-center">
                    <a href={`mailto:${SUPPORT_EMAIL}`}>
                        <PrimaryButton eventName={{ object: "changepassword", action: "contactsupport" }} type="button">
                            {t("REGISTRATION_CONTACT_SUPPORT")}
                        </PrimaryButton>
                    </a>
                </div>
                <div className="d-flex justify-content-around pt-4 pb-4">
                    <div>
                        {t("RESET_PASSWORD_CLICK_TO_LOGIN")} <Link to="/login">{t("COMMON_LOGIN")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ChangePassword = (): JSX.Element => {
    const { t } = useTranslate();

    const api = createApi();

    const history = useHistory();

    const LoginLogo: string = FullLogo;

    // eslint-disable-next-line prefer-const
    let query = useQuery();

    const { signIn } = useAuth();

    //let params = queryString.parse(location.search);

    const [accountStatus, setAccountStatus] = useState<TokenStatus>("Valid");
    const [isPending, setIsPending] = useState<boolean>(false);

    useEffect(() => {
        const email: string | null = query.get("email");
        const token: string | null = query.get("token");

        if (query && email && token) {
            api.login.checkResetPasswordToken({ email: email, password: token }).then(
                tokenStatus => {
                    setAccountStatus(tokenStatus);
                },
                response => {
                    customEvents.raiseResetPasswordError(response, email);
                }
            );
        }
    }, []);

    const changePassword = async (values: SubmitFormValues) => {
        setIsPending(true);
        // eslint-disable-next-line prefer-destructuring
        const password = values.password;
        const email: string | null = query.get("email");
        const token: string | null = query.get("token");
        if (query && email && token) {
            api.login
                .resetPassword({
                    email: email,
                    password: hashPassword(password),
                    token: token,
                })
                .then(
                    async response => {
                        if (response.resultCode === "Ok") {
                            const jwt = response.token;
                            const redirect = await signIn(jwt);

                            showToast(t("RESET_PASSWORD_SUCCESS_MESSAGE"), "success");
                            redirect();
                        } else {
                            history.push(UrlFactory.login.create({}));
                            showLoginErrorNotification(response, t);
                        }
                    },
                    () => {
                        showToast(t("COMMON_DEFAULT_ERROR"), "error");
                    }
                );

            setIsPending(false);
        }
    };

    const email: string | null = query.get("email");
    const token: string | null = query.get("token");

    const displayInvalidPasswordLink = accountStatus === "Invalid" || email === null || token === null;
    const displayPasswordChangeForm = accountStatus !== "Invalid" && email !== null && token !== null;
    return (
        <>
            <AppLoader loading={isPending} />
            <LoginContainer>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <img src={LoginLogo} className="main-login-logo" alt="Hups" />
                    </div>
                    <div className="d-flex justify-content-center h-100">
                        <RenderIf show={displayInvalidPasswordLink}>
                            <DisplayInvalidPasswordLink />
                        </RenderIf>
                        <RenderIf show={displayPasswordChangeForm}>
                            <div className="reset-password-container">
                                <div className="pl-3 pr-3 pt-3 mb-2">
                                    <h5 className="text-left">{t("FORGOT_PASSWORD_MAIN_TITLE")}</h5>
                                </div>

                                <Formik
                                    initialValues={{ password: "", confirmPassword: "" }}
                                    validationSchema={ChangePasswordSchema(t)}
                                    onSubmit={changePassword}
                                >
                                    {props => {
                                        const { errors, touched } = props;
                                        return (
                                            <Form className="text-center pl-3 pr-3 pt-1" autoComplete="off">
                                                <div className="reset-password mb-4">
                                                    <label htmlFor="password">{t("RESET_PASSWORD_PASSWORD")}</label>
                                                    <Field
                                                        autoComplete="new-password"
                                                        type="password"
                                                        name="password"
                                                        placeholder={t("RESET_PASSWORD_PASSWORD")}
                                                        className={`form-control${
                                                            errors.password && touched.password ? " is-invalid" : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="password"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="reset-password mb-4">
                                                    <label htmlFor="confirmPassword">
                                                        {t("RESET_PASSWORD_CONFIRM_PASSWORD")}
                                                    </label>
                                                    <Field
                                                        autoComplete="new-password"
                                                        type="password"
                                                        name="confirmPassword"
                                                        placeholder={t("RESET_PASSWORD_CONFIRM_PASSWORD")}
                                                        className={`form-control${
                                                            errors.confirmPassword && touched.confirmPassword
                                                                ? " is-invalid"
                                                                : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-start displayblock"
                                                        name="confirmPassword"
                                                        component="span"
                                                    />
                                                </div>
                                                <div className="reset-password-button mt-5">
                                                    <PrimaryButton
                                                        eventName={{ object: "changepassword", action: "save" }}
                                                        type="submit"
                                                        disabled={!!(errors.password || errors.confirmPassword)}
                                                        loading={isPending}
                                                    >
                                                        {t("FORGOT_PASSWORD_MAIN_TITLE")}
                                                    </PrimaryButton>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </RenderIf>
                    </div>
                </div>
            </LoginContainer>
        </>
    );
};

export default ChangePassword;
