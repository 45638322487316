import React from "react";

function MainContent(props: { children: React.ReactNode }): JSX.Element {
    return (
        <aside className="main-content">
            <div className="container">{props.children}</div>
        </aside>
    );
}

export default MainContent;
