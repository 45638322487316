import WorkshopCategorization from "workshop/categorisation/WorkshopCategorization";
import { useParams } from "react-router-dom";
import { ProgramChild } from "./Program/ProgramChild";

export const WorkshopCategorisationPage2 = () => {
    const params = useParams();

    return (
        <ProgramChild
            //@ts-ignore

            teamId={params.groupId}
            useFullWidth={true}
            breadCrumbTitleKey={"PROGRAM_PREPAREWORKSHOP"}
        >
            <WorkshopCategorization />
        </ProgramChild>
    );
};
