import { Course, Group, ParticipantProgress, User } from "@/api-client";
import _ from "lodash";
import { UserHasParticipantRole } from "./user/utils";

export const getTrainingUrl = (
    userProfile: User,
    progress: ParticipantProgress[],
    currentCourse: Course,
    currentGroup: Group
) => {
    const participantProgress = _.cloneDeep(progress);

    // Check if training link should be shown
    // @Edit: UserHasParticipantRole() is removed from condition in order to work this for other roles too
    const showTrainingLink = userProfile && currentCourse && UserHasParticipantRole(userProfile, currentGroup);

    // Set progress to overview or the users current state
    if (showTrainingLink && currentCourse) {
        if (currentCourse && currentCourse.chapters) {
            if (participantProgress && participantProgress.length > 0) {
                // Hack: User us not participant in this group. Return to home to avoid error.
                // if(!userProgress){
                //     return '/';
                // }

                // Map all progress to a combined object
                const chapterModules = combineChapterModules(currentCourse, participantProgress);
                // Find better way to do this
                /* @ts-ignore */
                const chapterModulesFlat = chapterModules.flat(2);
                /* @ts-ignore */
                const nextStep = chapterModulesFlat.find(x => !x.isCompleted);
                /* @ts-ignore */
                const prevStep = [...chapterModulesFlat].reverse().find(x => x.isCompleted === true);

                // First step or no next step, all done, show first chapter

                if (!chapterModulesFlat || chapterModulesFlat.length === 0) {
                    return `/groups/${currentGroup.id}`;
                }

                if (!nextStep || !prevStep) {
                    /* @ts-ignore */
                    return `/groups/${currentGroup.id}/training/${currentCourse.id}/chapter/${chapterModulesFlat[0].chapterId}/chapter-detail`;
                }

                // Show chapter introduction if next module is in another chapter
                if (prevStep && nextStep && prevStep.chapterId !== nextStep.chapterId) {
                    return `/groups/${currentGroup.id}/training/${currentCourse.id}/chapter/${nextStep.chapterId}/chapter-detail`;
                }
                if (nextStep) {
                    return `/groups/${currentGroup.id}/training/${currentCourse.id}/chapter/${nextStep.chapterId}/module/${nextStep.moduleId}/${nextStep.moduleBlockId}`;
                } else {
                    // No progress, link to first item in course
                    /* @ts-ignore */
                    return `/groups/${currentGroup.id}/training/${currentCourse.id}/chapter/${chapterModulesFlat[0].chapterId}/chapter-detail`;
                }
            } else {
                return `/groups/${currentGroup.id}/training/${currentCourse.id}/chapter/${currentCourse.chapters[0].id}/chapter-detail`;
            }
        }
    }

    return `/`;
};

export const combineChapterModules = (currentCourse: Course, userProgress: ParticipantProgress[]) => {
    if (currentCourse === null) {
        return;
    }
    const chapterModules = currentCourse.chapters
        .filter(f => f.status === "Active")
        /* @ts-ignore */
        .sort((a, b) => a.order - b.order)
        .map(chapter =>
            chapter.modules
                /* @ts-ignore */
                .sort((a, b) => a.order - b.order)
                .filter(modules => modules.status === "Active")
                .map(module =>
                    module.blocks
                        .sort((a, b) => a.sortIndex - b.sortIndex)
                        .filter(mb => mb.status === "Active")
                        .map(block => {
                            // QA: getProgress is a bad name, use something like userHasProgressForModuleBlock that is understandable

                            //let getProgress = userProgress.find((u) => u.moduleBlockId === block.id)?.isCompleted;
                            /* @ts-ignore */
                            const userHasProgressForMoudleBlock: ParticipantProgress = userProgress.find(
                                u => u.moduleBlockId === block.id
                            );
                            return {
                                chapterId: chapter.id,
                                moduleId: module.id,
                                moduleBlockId: block.id,
                                progressStatusId: userHasProgressForMoudleBlock?.progressStatusId,
                                isCompleted: userHasProgressForMoudleBlock?.progressStatusId ? true : null,
                            };
                        })
                )
        );
    return chapterModules;
};

export const checkContinueTraining = (
    userProfile: User,
    progress: ParticipantProgress[],
    currentCourse: Course,
    currentGroup: Group
) => {
    const participantProgress = _.cloneDeep(progress);
    // Check if training link should be shown
    const showTrainingLink = userProfile && userProfile && UserHasParticipantRole(userProfile, currentGroup);
    const userProgress =
        participantProgress && participantProgress.length > 0
            ? participantProgress.filter(x => x.userId === userProfile.id)
            : [];

    // Set progress to overview or the users current state
    if (showTrainingLink && currentCourse) {
        if (currentCourse && currentCourse.chapters) {
            const combinedChapterModulesProgress = combineChapterModules(currentCourse, userProgress);

            // Map all progress to a combined object
            /* @ts-ignore */
            const chapterModules = combinedChapterModulesProgress.flat(2);
            const result = chapterModules.every(progress => {
                /* @ts-ignore */
                return progress.isCompleted === true;
            });
            return !result;
        } else {
            return false;
        }
    }

    return false;
};
