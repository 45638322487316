import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CoursesNav } from "./NavBarHelper";
import { Badge } from "@mantine/core";
import RenderIf from "../../../components/render-if/render-if";

/*  eslint-disable react-hooks/exhaustive-deps */

type MultiMenuProps = {
    courseId?: number;
    moduleId?: number;
    moduleBlockId?: number;
    chapterId: number;
    menus?: CoursesNav[];
    activeId: number;
};

type ListMenuProps = {
    dept: number;
    data: CoursesNav;
    hasSubMenu: boolean;
    menuName: string;
    menuIndex: number;
    allData: CoursesNav[];
};

type SubMenuProps = {
    dept: number;
    data: CoursesNav[];
    toggle: boolean;
    menuIndex: number;
    allData: CoursesNav[];
};

const MultiMenus = ({ menus, courseId, chapterId, moduleId, moduleBlockId, activeId }: MultiMenuProps) => {
    const [toggledMenus, settoggledMenus] = useState<number[]>([]);
    const [activeMenus, setActiveMenus] = useState<number[]>([]);

    useEffect(() => {
        let toggledMenusIds: number[] = [];
        if (chapterId !== undefined) {
            toggledMenusIds.push(chapterId);
        }
        if (moduleId !== undefined) {
            toggledMenusIds.push(moduleId);
        }
        if (moduleBlockId !== undefined) {
            toggledMenusIds.push(moduleBlockId);
        }
        if (activeId !== undefined) {
            toggledMenusIds.push(activeId);
        }
        settoggledMenus(toggledMenusIds);
        setActiveMenus(toggledMenusIds);
    }, [courseId, chapterId, moduleBlockId, activeId]);

    const handleArrowClick = (data: CoursesNav) => {
        const { id, parentId } = data;
        let newtoggledMenus = [...toggledMenus];
        if (parentId && newtoggledMenus.includes(parentId)) {
            if (newtoggledMenus.includes(id)) {
                var index = newtoggledMenus.indexOf(id);
                if (index > -1) {
                    newtoggledMenus.splice(index, 1);
                }
            } else {
                newtoggledMenus.push(id);
            }
            settoggledMenus(newtoggledMenus);
        } else {
            settoggledMenus([id]);
        }
    };

    const ListMenu = ({ dept, data, hasSubMenu, menuIndex, allData }: ListMenuProps) => {
        return (
            <li className={hasSubMenu ? "menu-tree" : ""}>
                <div className="menu-container">
                    <Link className={activeMenus.includes(data.id) ? "active" : ""} to={data.link}>
                        <span className="label">{data.name}</span>
                    </Link>
                    <RenderIf show={data.status === "Disabled"}>
                        <Badge radius="sm" variant="light" size="xs" color="gray" ml="xs">
                            {data.status}
                        </Badge>
                    </RenderIf>
                    <RenderIf show={data.status === "Deleted"}>
                        <Badge radius="sm" variant="light" size="xs" color="red" ml="xs">
                            {data.status}
                        </Badge>
                    </RenderIf>
                    {hasSubMenu && (
                        <span
                            className="arrows"
                            onClick={() => handleArrowClick(data)}
                            style={{ transform: toggledMenus.includes(data.id) ? "rotate(180deg)" : "rotate(0deg)" }}
                        ></span>
                    )}
                </div>
                {hasSubMenu && (
                    <SubMenu
                        dept={dept}
                        //@ts-ignore
                        data={data.children}
                        allData={allData}
                        toggle={toggledMenus.includes(data.id)}
                        menuIndex={menuIndex}
                    />
                )}
            </li>
        );
    };

    const SubMenu = ({ dept, data, toggle, menuIndex, allData }: SubMenuProps) => {
        if (!toggle) {
            return null;
        }

        dept = dept + 1;

        return (
            <ul>
                {data.map((menu, index) => {
                    const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

                    return (
                        <ListMenu
                            dept={dept}
                            allData={allData}
                            data={menu}
                            //@ts-ignore
                            hasSubMenu={menu.children && menu.children.length > 0}
                            menuName={menuName}
                            key={menuName}
                            menuIndex={index}
                        />
                    );
                })}
            </ul>
        );
    };

    return (
        <ul>
            {
                //@ts-ignore
                menus.map((menu, index) => {
                    const dept = 1;
                    const menuName = `sidebar-menu-${dept}-${index}`;

                    return (
                        <ListMenu
                            dept={dept}
                            data={menu}
                            //@ts-ignore
                            allData={menus}
                            //@ts-ignore
                            hasSubMenu={menu.children && menu.children.length > 0}
                            menuName={menuName}
                            key={menuName}
                            menuIndex={index}
                        />
                    );
                })
            }
        </ul>
    );
};

export default MultiMenus;
