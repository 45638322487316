import { Group, TrainingProgressPerDepartmentReport } from "@/api-client";

export interface GroupAsChildParent extends Group {
    children?: Group[];
}

/**
 * Method used to create the tree sturcture of groups using parent Id
 * @param groups
 * @returns
 */
export interface GroupsWithParentId extends Group {
    parentGroupId?: number;
    action?: string;
}

export interface GroupsForToggleByParentId extends GroupsWithParentId {
    children?: GroupsWithParentId[];
    subRows?: GroupsWithParentId[];
    action: string;
    employees: number;
    acceptedInvitations: string;
}

export const createHierachicalGroupsForToggleByParentId = (groups: Group[]): GroupsForToggleByParentId[] => {
    if (groups && groups.length > 0) {
        /* @ts-ignore */
        const nest = (items: Group[], id = null, link = "parentId") =>
            items
                /* @ts-ignore */
                .filter(item => item[link] === id)
                .map(item => {
                    const allMembers = item.members.length;
                    const acceptedInvitations = item.members.filter(m => m.inviteStatus === "Accepted").length;
                    let acceptedPer;
                    if (allMembers === 0 && acceptedInvitations === 0) {
                        acceptedPer = 0;
                    } else {
                        acceptedPer = (100 * acceptedInvitations) / allMembers;
                    }
                    return {
                        ...item,
                        /* @ts-ignore */
                        subRows: nest(items, item.id),
                        /* @ts-ignore */
                        children: nest(items, item.id),
                        action: `group-type/${item.groupTypeId}/group/${item.id}`,
                        employees: allMembers,
                        acceptedInvitations: `${acceptedPer.toFixed(0)}% (${acceptedInvitations}/${allMembers})`,
                    };
                });
        const data = nest(groups as GroupsForToggleByParentId[]);
        return data;
    } else {
        return [];
    }
};

export const createHierachicalGroupsReportsByParentId = (groups: Group[]) => {
    if (groups && groups.length > 0) {
        /* @ts-ignore */
        const nest = (items: Group[], id = null, link = "parentId") =>
            items
                /* @ts-ignore */
                .filter(item => item[link] === id)
                .map(item => ({
                    ...item,
                    /* @ts-ignore */
                    subRows: nest(items, item.id),
                    /* @ts-ignore */
                    children: nest(items, item.id),
                    group: item.name,
                    onboardingRate: item.members.filter(m => m.inviteStatus === "Accepted").length,
                    action: `${item.id}`,
                }));
        return nest(groups as Group[]);
    }
    return [];
};

export interface DepartmentTrainingWithParentId extends TrainingProgressPerDepartmentReport {
    children?: TrainingProgressPerDepartmentReport[];
}

export const createTrainingHierachicalPerDepartmentByParentId = (
    trainingProgressPerDepartment: TrainingProgressPerDepartmentReport[]
): DepartmentTrainingWithParentId[] => {
    if (trainingProgressPerDepartment && trainingProgressPerDepartment.length > 0) {
        /* @ts-ignore */
        const nest = (items, id = 0, link = "parentId") =>
            items
                /* @ts-ignore */
                .filter(item => item[link] === id)
                /* @ts-ignore */
                .map(item => ({
                    ...item,
                    children: nest(items, item.departmentId),
                }));
        return nest(trainingProgressPerDepartment);
    }
    return [];
};

/**
 * Method used to create the tree sturcture of groups using parent Id
 * @param groups
 * @returns
 */
export const createHierachicalGroupsByParentId = (groups: Group[]): GroupAsChildParent[] => {
    if (groups && groups.length > 0) {
        /* @ts-ignore */
        const nest = (items, id = null, link = "parentId") =>
            items
                /* @ts-ignore */
                .filter(item => item[link] === id)
                /* @ts-ignore */
                .map(item => ({ ...item, children: nest(items, item.id) }));
        return nest(groups);
    }
    return [];
};
