import * as jsonPatch from "fast-json-patch";

export function createPatch<T extends object>(item: T, callback: (x: T) => void): jsonPatch.Operation[] {
    const observer = jsonPatch.observe<T>(item);
    callback(item);
    return jsonPatch.generate(observer);
}

export function createPatchFromDiff<T extends object>(item: T, changes: Partial<T>): jsonPatch.Operation[] {
    const target = {
        ...item,
        ...changes,
    };

    return jsonPatch.compare(item, target);
}
