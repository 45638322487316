import { Course, TransformationTodo } from "@/api-client";
import { uniqueId } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { createApi } from "../../../common/api";
import { ContentLabel } from "../../../components/ContentLabel";
import { showToast } from "../../../components/Notify";
import { AppLoader } from "../../../components/Spinner";
import { DragItem, updateSortOrderOnDrag } from "../../components/SortableTable/common";
import { SortableTableBody } from "../../components/SortableTable/SortableTableBody";
import { SortableTableRow } from "../../components/SortableTable/SortableTableRow";
import { useSysadminCourse } from "../../SysadminContext";
import { CardBlock } from "../../../components/CardBlock";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { ActionIcon } from "@mantine/core";

/*  eslint-disable react-hooks/exhaustive-deps */

interface ImplementationTodosEditorProps {
    courseId: number;
    chapterId: number;
    moduleId: number;
}

const getModule = (course: Course, chapterId: number, moduleId: number) => {
    return course.chapters?.find(x => x.id === chapterId)?.modules?.find(m => m.id === moduleId);
};

interface DraggableImplementationTodo extends DragItem, TransformationTodo {}

const getSortableItems = (items: TransformationTodo[]): DraggableImplementationTodo[] =>
    items?.map<DraggableImplementationTodo>(x => ({ ...x, sortId: uniqueId() }));

export const ImplementationTodosEditor = (props: ImplementationTodosEditorProps) => {
    const [course, updateCourse] = useSysadminCourse(props.courseId);
    const api = createApi();
    const [todoItems, setTodoItems] = useState<DraggableImplementationTodo[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isValidated, setIsValidated] = useState<boolean>();

    useEffect(() => {
        if (!course) {
            return;
        }

        const module = getModule(course, props.chapterId, props.moduleId);

        if (!module) {
            return;
        }

        // @ts-ignore
        setTodoItems(getSortableItems(module.transformationTodos));

        setIsLoading(false);
    }, [course, props.chapterId, props.moduleId]);

    const save = useCallback(() => {
        setIsValidated(true);

        if (!isValid) {
            return;
        }

        setIsLoading(true);
        // @ts-ignore

        const ops = todoItems.map(ti => api.transformationTodos.put(ti.id, ti));

        Promise.all(ops).then(
            savedItems => {
                updateCourse(c => {
                    const module = getModule(c, props.chapterId, props.moduleId);

                    if (!module) {
                        throw new Error("Module not found in course");
                    }

                    module.transformationTodos = savedItems;

                    return c;
                });

                // Update local state
                setTodoItems(getSortableItems(savedItems));

                setIsLoading(false);
            },
            err => {
                showToast("Unable to save transformation todos. Please try again.", "warning");
                setIsLoading(false);
            }
        );
    }, [course, props.chapterId, props.moduleId, todoItems, updateCourse]);

    const removeItem = useCallback((item: DraggableImplementationTodo) => {
        if (item.id === 0) {
            // @ts-ignore

            setTodoItems(s => [...s.filter(x => x.sortId !== item.sortId)]);
        } else {
            // @ts-ignore

            setTodoItems(s => [...s.filter(x => x.id !== item.id), { ...item, status: "Inactive" }]);
        }
    }, []);

    const addItem = useCallback(() => {
        setTodoItems(s => [
            // @ts-ignore
            ...s,
            {
                id: 0,
                text: "",
                sortId: uniqueId(),
                moduleId: props.moduleId,
                sortIndex: todoItems?.length ?? 0,
                status: "Active",
            },
        ]);
    }, [todoItems?.length]);

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        // @ts-ignore

        setTodoItems(prev => updateSortOrderOnDrag(prev, dragIndex, hoverIndex));
    }, []);

    const updateTodoText = useCallback((todoSortId: string | number, newText: string) => {
        // @ts-ignore

        setTodoItems(s => s.map(x => (x.sortId !== todoSortId ? x : { ...x, text: newText })));
    }, []);

    const isValid = useMemo(() => todoItems && todoItems.every(x => x.text && x.text.length > 0), [todoItems]);

    return (
        <CardBlock mb="xl">
            <AppLoader loading={isLoading} />
            <ContentLabel text="Implementation todos" />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    save();
                }}
                className={`${isValidated && "was-validated"}`}
            >
                <Table striped={true} borderless={true} className="items-drag-drop">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Text</th>
                        </tr>
                    </thead>
                    <SortableTableBody>
                        {todoItems &&
                            todoItems
                                .filter(x => x.status === "Active")
                                .sort((a, b) => a.sortIndex - b.sortIndex)
                                .map((todo, ix) => (
                                    <SortableTableRow key={todo.sortId} dragItem={todo} moveItem={moveItem}>
                                        <td style={{ width: "1%" }}>
                                            <i className="fa fa-grip-vertical me-2"></i>
                                        </td>
                                        <td className="col-auto">
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                required={isValidated}
                                                value={todo.text}
                                                onChange={e => updateTodoText(todo.sortId, e.target.value)}
                                            />
                                        </td>
                                        <td style={{ width: "1%" }}>
                                            <ActionIcon onClick={() => removeItem(todo)}>
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </ActionIcon>
                                        </td>
                                    </SortableTableRow>
                                ))}
                    </SortableTableBody>
                    <tfoot>
                        <tr>
                            <th colSpan={3}>
                                <PrimaryButton onClick={() => addItem()}>Add item</PrimaryButton>
                            </th>
                        </tr>
                    </tfoot>
                </Table>
                <ContentBlockButtonContainer>
                    <PrimaryButton type="submit" disabled={!isValid && isValidated}>
                        Save
                    </PrimaryButton>
                </ContentBlockButtonContainer>
            </form>
        </CardBlock>
    );
};
