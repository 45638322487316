import { NativeSelect, SelectItem } from "@mantine/core";
import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";

type NativeSelectInputProps = {
    fieldName: string;
    label: string;
    placeholder?: string;
    options: SelectItem[];
    disabled?: boolean;
};

export const NativeSelectInput = (props: NativeSelectInputProps) => {
    const [field, meta] = useField(props.fieldName);
    const { t } = useTranslate();
    const placeholder = props.placeholder !== undefined ? props.placeholder : props.label;

    const options = useMemo(() => {
        return [
            ...(placeholder !== undefined
                ? [
                      {
                          value: "",
                          disabled: true,
                          label: t(placeholder),
                      },
                  ]
                : []),
            ...props.options,
        ];
    }, props.options);

    return (
        <NativeSelect
            {...field}
            data={options}
            label={t(props.label)}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
            disabled={props.disabled}
        />
    );
};
