import { useTranslate } from "@tolgee/react";
import { Module } from "@/api-client";
import { useCurrentCourse } from "../common/courses/context";
import { ColorScaleFromValue } from "../helpers/helpers";
import { average, formatNumber, OptionsData } from "./helper";
import "./Transformation.scss";
import { CardBlock } from "@/components";

interface Props {
    moduleId: number;
    questionData: OptionsData[];
}

export const TransformationDetails = (props: Props) => {
    const { t } = useTranslate();
    const currentCourse = useCurrentCourse();

    // @ts-ignore
    const module: Module =
        currentCourse?.chapters
            ?.flatMap(x => x.modules)
            // @ts-ignore

            .find(m => m.id === props.moduleId) ?? null;

    const moduleQuestions =
        module && module.blocks && module?.blocks?.flatMap(b => b.questions).filter(q => q !== null && q !== undefined);

    return (
        <CardBlock>
            <div className="transformation-overview">
                <h3>{module && module.name}</h3>
                <div className="table-responsive-lg">
                    <table className="table table-borderless table-striped">
                        <thead>
                            <tr>
                                <th rowSpan={2}>Understanding</th>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>{t("TRANSFORMATION_ME")}</th>
                                <th>{t("TRANSFORMATION_GROUP")}</th>
                                <th>{t("TRANSFORMATION_ALL")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {moduleQuestions &&
                                moduleQuestions
                                    // @ts-ignore
                                    .flatMap(q => q.options)
                                    .map(opt => (
                                        // @ts-ignore
                                        <tr key={opt.id}>
                                            {/*// @ts-ignore*/}
                                            <td>{opt.text}</td>
                                            <td
                                                style={{
                                                    backgroundColor: ColorScaleFromValue(
                                                        // @ts-ignore
                                                        props.questionData.find(q => q.optionId === opt.id)?.me
                                                    ),
                                                }}
                                            >
                                                {formatNumber(
                                                    // @ts-ignore
                                                    props.questionData.find(q => q.optionId === opt.id)?.me
                                                )}
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: ColorScaleFromValue(
                                                        // @ts-ignore
                                                        props.questionData.find(q => q.optionId === opt.id)?.group
                                                    ),
                                                }}
                                            >
                                                {formatNumber(
                                                    // @ts-ignore
                                                    props.questionData.find(q => q.optionId === opt.id)?.group
                                                )}
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: ColorScaleFromValue(
                                                        // @ts-ignore
                                                        props.questionData.find(q => q.optionId === opt.id)?.company
                                                    ),
                                                }}
                                            >
                                                {formatNumber(
                                                    // @ts-ignore
                                                    props.questionData.find(q => q.optionId === opt.id)?.company
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                        <tfoot>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th />
                                <th
                                    style={{
                                        backgroundColor: ColorScaleFromValue(
                                            average(props.questionData.map(x => x.me))
                                        ),
                                    }}
                                >
                                    {formatNumber(props.questionData && average(props.questionData.map(x => x.me)))}
                                </th>
                                <th
                                    style={{
                                        backgroundColor: ColorScaleFromValue(
                                            average(props.questionData.map(x => x.group))
                                        ),
                                    }}
                                >
                                    {formatNumber(props.questionData && average(props.questionData.map(x => x.group)))}
                                </th>
                                <th
                                    style={{
                                        backgroundColor: ColorScaleFromValue(
                                            average(props.questionData.map(x => x.company))
                                        ),
                                    }}
                                >
                                    {formatNumber(
                                        props.questionData && average(props.questionData.map(x => x.company))
                                    )}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </CardBlock>
    );
};
