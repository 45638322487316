import React from "react";
import { ActionIcon, createStyles, Text, Paper } from "@mantine/core";
import { Icons } from "../../../common/Icons";

const useCategoryCardStyles = createStyles((theme, params: { color: string }) => ({
    categoryCard: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "50px",
        borderLeft: "8px solid " + params.color,
    },

    categoryText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        flexGrow: 2,
    },

    categoryContentContainer: {
        alignSelf: "center",
    },

    categoryEditIcon: {
        margin: theme.spacing.sm,
    },
}));

type CategoryCardProps = {
    color: string;
    label: string;
    counter?: number;
    onClick: () => void;
    editIcon?: boolean;
    editCallback?: () => void;
};

export const CategoryCard = (props: CategoryCardProps): JSX.Element => {
    const { classes } = useCategoryCardStyles({ color: props.color });
    return (
        <Paper radius={"md"} className={classes.categoryCard} withBorder={true}>
            <div className={classes.categoryText} onClick={() => props.onClick()}>
                <Text fz={"sm"} mx={"md"}>
                    {props.label}
                </Text>
            </div>
            <Text fz={"sm"}>{props.counter}</Text>
            {props.editIcon && (
                // @ts-ignore
                <ActionIcon onClick={() => props.editCallback()} color="dark" mx={"xs"}>
                    <Icons.Edit />
                </ActionIcon>
            )}
        </Paper>
    );
};
