import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../components/Footer/footer";
import MainNavbar from "../MainNavbar";

interface SysadminLayoutProps {
    children: ReactNode;
    isFluid?: boolean;
}

const SysadminLayout = ({ isFluid, children }: SysadminLayoutProps): JSX.Element => {
    return (
        <>
            <MainNavbar />
            <Container id="layout-container" fluid={isFluid}>
                <div id="wrapper" className="content_area w-100">
                    {children}
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default SysadminLayout;
