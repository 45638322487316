import { Client, Course } from "@/api-client";

export type OnboardingVideo = {
    description: string;
    role?: number | null;
    title: string;
    id: string;
    url: string;
};
export const onBoardVideos = (
    currentCourse: Course,
    client: Client,
    isParticipant: boolean,
    isGroupLeader: boolean,
    isManager: boolean
): OnboardingVideo[] => {
    // @ts-ignore
    let userRole = [];
    if (isParticipant) {
        userRole = ["User"];
    }
    if (isGroupLeader) {
        // @ts-ignore
        userRole = [...userRole, "Group Leader"];
    }
    if (isManager) {
        // @ts-ignore
        userRole = [...userRole, "Account Owner"];
    }
    // @ts-ignore
    let courseVideos = [];
    // @ts-ignore
    let clientVideos = [];
    // @ts-ignore
    let roleVideos = [];
    let result = [];
    if (
        currentCourse &&
        currentCourse.onBoardingVideo &&
        currentCourse.onBoardingVideo.length > 0 &&
        // @ts-ignore
        userRole &&
        userRole.length > 0
    ) {
        // Get course Videos
        courseVideos = currentCourse.onBoardingVideo
            .map(video => {
                const { videoUrl, ...rest } = video;
                return {
                    ...rest,
                    url: "https://vimeo.com" + videoUrl,
                    // @ts-ignore
                    id: videoUrl.replace(/\//g, ""),
                };
            })
            .filter(v => {
                return v.role === null || v.role === undefined;
            });
        // Get role based videos
        roleVideos = currentCourse.onBoardingVideo
            .map(video => {
                const { videoUrl, ...rest } = video;
                return {
                    ...rest,
                    url: "https://vimeo.com" + videoUrl,
                    // @ts-ignore
                    id: videoUrl.replace(/\//g, ""),
                };
            })
            .filter(v => {
                // @ts-ignore
                return userRole.includes(v.role);
            });
    }
    //Client Videos is the company introduction video
    if (client && client.introductionMessage && client.introductionMessage.length > 0) {
        clientVideos = client.introductionMessage.map(v => {
            return {
                description: v.description,
                role: null,
                title: v?.title,
                id: v.videoUrl ? v.videoUrl.replace(/\//g, "") : "",
                url: "https://vimeo.com" + v?.videoUrl,
            };
        });
    }
    // Combine company/client intro, course videos and last role videos
    // @ts-ignore
    result = [...clientVideos, ...courseVideos, ...roleVideos];
    return result;
};
