import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from "@microsoft/signalr";
import Constants from "../../constants";
import { WithChildren } from "../StateWrapper";
import { useAuth } from "../../auth/useAuth";

interface SignalRContextProps {
    connection?: HubConnection;
    statusMessage?: string;
}

const SignalRContext = React.createContext<SignalRContextProps>({});

export const useSignalRStatusMessage = () => {
    const statusMessage = React.useContext(SignalRContext)?.statusMessage;
    return useMemo(() => statusMessage, [statusMessage]);
};

export const SignalRProvider = (props: WithChildren) => {
    const connection = useRef<HubConnection>();
    const [state, setState] = useState<SignalRContextProps>({});
    const { token, client: currentClient } = useAuth();

    const setStatusMessage = useCallback(
        (message: string | null) => {
            setState(s => ({
                ...s,
                statusMessage: message ?? undefined,
            }));
        },
        [setState]
    );

    const options: IHttpConnectionOptions = useMemo(
        () => ({
            accessTokenFactory: () => token!,
        }),
        [token]
    );

    const connect = useCallback(() => {
        const conn = new HubConnectionBuilder()
            .withUrl(`${Constants.NEW_API_URL}/api/signalr`, options)
            .configureLogging(LogLevel.Warning)
            .withAutomaticReconnect()
            .build();

        setStatusMessage("SIGNALR_CONNECTION_CONNECTING");

        conn.start()
            .then(result => {
                //console.log('SignalR connected');
                /* @ts-ignore */
                setStatusMessage(null);
                console.info("SignalR connected.");
            })
            .catch(e => {
                setStatusMessage("SIGNALR_CONNECTION_ERROR");
                //console.log('SignalR connection failed: ', e)
            });

        return conn;
    }, [options, setStatusMessage]);

    const disconnect = () => {
        if (connection.current) {
            connection.current.stop();
            connection.current = undefined;
        }
    };

    useEffect(() => {
        let cancel = false;

        if (cancel) {
            return;
        }

        if (!token) {
            return;
        } else {
            if (!currentClient?.id) {
                disconnect();
                return;
            }

            if (connection.current) {
                disconnect();
            }

            connection.current = connect();

            setState(s => ({
                ...s,
                connection: connection.current,
            }));

            connection.current.onclose(error => setStatusMessage("SIGNALR_CONNECTION_CLOSED"));
            connection.current.onreconnecting(error => setStatusMessage("SIGNALR_CONNECTION_RECONNECTING"));
            connection.current.onreconnected(() => setStatusMessage(null));
        }

        return () => {
            disconnect();
            cancel = true;
        };
    }, [connect, currentClient?.id, setStatusMessage]);

    /* @ts-ignore */
    return <SignalRContext.Provider value={state}>{props.children}</SignalRContext.Provider>;
};

export const useSignalR = () => {
    // TODO: Connect to AppInsights
    return useContext(SignalRContext)?.connection;
};
