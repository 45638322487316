import React from "react";
import { ConsolidatedUsersProgress } from "../GroupProgressDisplay/helper";
import "./styles.scss";

export const progressClasses = (isCompleted: boolean): string | undefined => {
    if (isCompleted === true) {
        return "alertsuccess";
    }
    if (isCompleted === false) {
        return "alertdanger";
    }
    if (isCompleted === null || isCompleted === undefined) {
        return "test";
    }
};

export const ShowProgress = (props: {
    index: number;
    prog: { moduleId?: number; isCompleted: boolean; break?: boolean };
}): JSX.Element => {
    const progressStatusId = props.prog.isCompleted;
    const progressClass = progressClasses(progressStatusId);
    let style;
    if (props.prog.break) {
        style = { marginRight: "10px" };
    }

    return <i className={`fas fa-circle ${progressClass}`} style={style} />;
};
/* @ts-ignore */
export const TrainingProgressCard = ({ progress }) => {
    return (
        progress &&
        /* @ts-ignore */
        progress.map((p, i) => {
            const progressClass = progressClasses(p.isCompleted);
            return (
                <React.Fragment key={i}>
                    <i className={`fas fa-circle ${progressClass}`} />
                </React.Fragment>
            );
        })
    );
};
/* @ts-ignore */
export const AttendeesProgress = (props): JSX.Element => {
    const { progress } = props;
    return (
        <div className="progress-container">
            {progress &&
                progress.length > 0 &&
                /* @ts-ignore */
                progress.map((p, i) => {
                    return (
                        <React.Fragment key={i}>
                            {p &&
                                p.length > 0 &&
                                /* @ts-ignore */
                                p.map((c, j) => {
                                    return (
                                        <React.Fragment key={`${c.isCompleted}-${j - i}-${c.isCompleted}-${i}`}>
                                            <ShowProgress
                                                index={i}
                                                prog={c}
                                                key={`${i}--${c.isCompleted || c.isCompleted}-${j}`}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            <span style={{ marginRight: "10px" }}></span>
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

export const Progress = (props: { progress: ConsolidatedUsersProgress[] }): JSX.Element => {
    const { progress } = props;
    return (
        <div className="progress-container">
            {progress &&
                progress.length > 0 &&
                progress.map(
                    /* @ts-ignore */
                    (userProgress: { moduleId?: number; isCompleted: boolean; break?: boolean }, i: number) => {
                        return (
                            <ShowProgress
                                index={i}
                                prog={userProgress}
                                key={`${userProgress.moduleId}--${
                                    userProgress.isCompleted || userProgress.isCompleted
                                }-${i}`}
                            />
                        );
                    }
                )}
        </div>
    );
};
