import { useEffect, useState } from "react";
import { Space } from "@mantine/core";
import { createApi } from "@/common";
import { ClientList, ClientRow } from "./ListClients/ClientList";

export const ClientsPage = () => {
    const [clientRows, setClientRows] = useState<ClientRow[] | null>(null);

    useEffect(() => {
        const abortController = new AbortController();
        const api = createApi();

        api.sysadmin
            .getClients(abortController)
            .then(c =>
                setClientRows(
                    c.map(x => ({
                        id: x.id,
                        name: x.name,
                        tags: x.tags,
                        status: x.status,
                        agent: x.agent,
                        activeUsers: x.numberOfActiveUsers,
                    }))
                )
            )
            .catchWithToast();

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <ClientList clients={clientRows} />
            <Space h="lg" />
        </>
    );
};
