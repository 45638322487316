import { Round } from "@/api-client";
import React from "react";
import { Table } from "react-bootstrap";
import RenderIf from "../../../components/render-if/render-if";
import { ActionIcon, Flex, Menu } from "@mantine/core";
import { Icons } from "common/Icons";

type RoundListingProps = {
    rounds: Round[];
    editRound: (roundId: number) => void;
    deleteRound: (roundId: number) => void;
};

export const RoundsListing = (props: RoundListingProps) => {
    return (
        <React.Fragment>
            <Table borderless striped responsive>
                <thead>
                    <tr>
                        <th>Round Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.rounds &&
                        props.rounds.map(r => (
                            <tr key={r.id}>
                                <td>{r.name}</td>
                                <td>{new Date(r.startDate)?.toLocaleDateString()}</td>
                                <td>{new Date(r.endDate)?.toLocaleDateString()}</td>
                                <td>
                                    <Flex justify="center">
                                        <Menu id="training-assignment-dropdown">
                                            <Menu.Target>
                                                <ActionIcon>
                                                    <Icons.ContextMenu />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown className="drop-down-menu">
                                                <Menu.Item onClick={() => props.editRound(r.id!)}>{"Edit"}</Menu.Item>
                                                <Menu.Item onClick={() => props.deleteRound(r.id!)}>
                                                    {"Remove"}
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Flex>
                                </td>
                            </tr>
                        ))}
                    <RenderIf show={props.rounds && props.rounds.length === 0 ? true : false}>
                        <tr>
                            <th className="text-center" colSpan={4}>
                                No round found.
                            </th>
                        </tr>
                    </RenderIf>
                </tbody>
            </Table>
        </React.Fragment>
    );
};
