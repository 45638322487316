import React from "react";
import { Dropdown } from "react-bootstrap";
import StyledCheckbox from "../StyledCheckbox";
import "./style.scss";

interface Options {
    id: number;
    label: string;
    checked: boolean;
}

interface MultiSelectToggleProps {
    buttonText: any;
    options: Options[];
    onChange?: (value: string | number, checked?: boolean) => void;
    onClear?: () => void;
    toggleClassName?: string;
}

export const MultiSelectToggle = (props: MultiSelectToggleProps) => {
    return (
        <Dropdown className={`multiselect-dropdown`}>
            <Dropdown.Toggle className={`${props.toggleClassName} d-flex align-items-center`} variant="outline">
                <span>{props?.buttonText}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="drop-down-menu scrollbar">
                {props.options
                    ?.filter(options => options.label !== " ")
                    .map((o, i) => (
                        <div className="dropdown-item" key={i}>
                            <StyledCheckbox
                                onChange={checked =>
                                    // @ts-ignore

                                    props.onChange(o.id, checked)
                                }
                                checked={o?.checked}
                                key={`${o?.id}-${o?.label}`}
                                label={o?.label}
                            />
                        </div>
                    ))}
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={e => {
                        e.stopPropagation();
                        // @ts-ignore

                        props.onClear();
                    }}
                    className="text-right-clear"
                >
                    Clear <i className="fas fa-times clear-tags" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
