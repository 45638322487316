import { QuestionOption } from "@/api-client";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { EditOptionForm } from "./AddQuestionOptions";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";

export const ItemType = {
    CARD: "option",
};

export const QuestionOptionsCard = (props: {
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    updateField: (text: string, options: QuestionOption) => void;
    removeField: (id: number) => void;
    questionOption: QuestionOption;
    id: number;
}) => {
    const ref = useRef(null);

    // @ts-ignore
    const [{ handlerId }, drop] = useDrop(
        {
            accept: ItemType.CARD,
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            // @ts-ignore

            hover(item: { id: number; index: number }, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = props.index;
                console.log(props.index, "In hover method.");
                if (dragIndex === hoverIndex) {
                    return;
                }
                // @ts-ignore

                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                // @ts-ignore

                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
                props.moveCard(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        },
        [props]
    );

    const [{ isDragging }, drag] = useDrag(
        {
            type: ItemType.CARD,
            item: () => {
                return {
                    id: props.id,
                    index: props.index,
                };
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [props.index, props.id]
    );

    const containerClass = isDragging ? "dragging-container-li" : "listing-container";
    drag(drop(ref));

    return (
        <li
            key={`blocks-listing-${props.questionOption?.id}`}
            ref={ref}
            className={`${containerClass} mt-2 droppable draggable drag-allowed content-answer-card question-option-row`}
            data-handler-id={handlerId}
        >
            <div>
                <span className="drag-handle">
                    {" "}
                    <i className="fa fa-grip-vertical"></i>
                </span>
                {props.questionOption.order}
            </div>
            <EditOptionForm
                option={props.questionOption}
                update={newText => props.updateField(newText, props.questionOption)}
            />
            <div>
                <PrimaryButton
                    onClick={e =>
                        // @ts-ignore
                        props.removeField(props.questionOption.id)
                    }
                >
                    <div>
                        <i className="fa fa-trash-alt" /> Delete
                    </div>
                </PrimaryButton>
            </div>
        </li>
    );
};
