import { useCallback, useRef, useState } from "react";
import Vimeo, { EndEvent, PlayEvent, TimeUpdateEvent } from "@u-wave/react-vimeo";
import { CuePointEvent, Player } from "@vimeo/player";
import moment from "moment";
import { useTranslate } from "@tolgee/react";
import { Alert } from "@mantine/core";
import { customEvents } from "analytics/customEvents/customEvents";
import RenderIf from "./render-if/render-if";
import { trackNamedPosthogEvent } from "../analytics/customEvents/trackPosthogEvent";

//https://help.vimeo.com/hc/en-us/articles/12426206785553-Integrate-the-Vimeo-player-with-Google-Analytics
//Script not working due to privacy settings. So instead of change it on all videos, custom tracking was created instead

interface VideoPlayerProps {
    url: string;
    id: number;
    className?: string;
    breakPoints?: string | null;
}

const toDisplayTime = (seconds: number) => moment("2015-01-01").startOf("day").seconds(seconds).format("mm:ss");
const toSeconds = (breakPoint: string) => moment(breakPoint, "HH:mm:ss").diff(moment().startOf("day"), "seconds");
const addVimeoDotComIfNecessary = (url: string) =>
    url.startsWith("https://vimeo.com") ? url : `https://vimeo.com${url}`;

export const VimeoPlayer = (props: { url: string }) => {
    const { t } = useTranslate();
    const [hasError, setHasError] = useState(false);
    const playerRef = useRef<Player>();

    const raiseEvent = useCallback((eventName: string, percent: number) => {
        if (playerRef.current == null) {
            return;
        }

        const videoTitle = playerRef.current.getVideoTitle();
        const videoDuration = playerRef.current.getDuration();
        const videoId = playerRef.current.getVideoId();

        Promise.all([videoId, videoTitle, videoDuration])
            .then(([videoId, videoTitle, videoDuration]) => {
                trackNamedPosthogEvent(eventName, {
                    video_provider: "vimeo",
                    video_url: `https://vimeo.com${props.url}`,
                    video_title: videoTitle,
                    video_id: videoId,
                    video_duration: videoDuration,
                    video_current_time: playerRef.current!.getCurrentTime(),
                    video_percent: percent,
                });
            })
            .catch(e => console.log(e));
    }, []);

    const handleVimeoError = useCallback(
        (error: Error) => {
            console.error(error);
            // Don't treat InvalidCuePoint errors as errors, they will only break tracking but not the video
            if (error.name === "InvalidCuePoint") {
                customEvents.raiseVideoError(error, -1, props.url, true);
            } else {
                setHasError(true);
                customEvents.raiseVideoError(error, -1, props.url);
            }
        },
        [props.url]
    );

    const onReady = useCallback(
        (newPlayer: Player) => {
            playerRef.current = newPlayer;

            raiseEvent("video_loaded", 0);
        },
        [raiseEvent]
    );

    const onLoaded = useCallback(() => {
        if (playerRef.current == null) {
            return;
        }

        playerRef.current
            .getDuration()
            .then(duration => {
                playerRef.current?.addCuePoint(Math.round(duration * 0.25), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(Math.round(duration * 0.5), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(Math.round(duration * 0.75), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(duration, { raiseEvent: true }).catchWithToast();
            })
            .catchWithToast();
    }, []);

    const onEnd = useCallback(
        (endEvent: EndEvent) => {
            raiseEvent("video_ended", endEvent.percent * 100);
        },
        [raiseEvent]
    );

    const onPlay = useCallback(
        (playEvent: PlayEvent) => {
            raiseEvent("video_play", playEvent.percent * 100);
        },
        [raiseEvent]
    );
    const onCuePoint = useCallback(
        (event: CuePointEvent) => {
            if (event.data["raiseEvent"]) {
                playerRef
                    .current!.getDuration()
                    .then(duration => {
                        raiseEvent(`video_timeupdate`, Math.round((event.time / duration) * 100));
                    })
                    .catchWithToast();
            }
        },
        [raiseEvent]
    );

    return (
        <>
            {hasError && <Alert color="orange">{t("COMMON_VIDEO_NOT_LOADED")}</Alert>}
            {!hasError && (
                <Vimeo
                    onReady={onReady}
                    onEnd={onEnd}
                    onPlay={onPlay}
                    onLoaded={onLoaded}
                    onCuePoint={onCuePoint}
                    video={addVimeoDotComIfNecessary(props.url)}
                    speed
                    showTitle={false}
                    showPortrait={false}
                    showByline={false}
                    id={props.url}
                    responsive
                    onError={handleVimeoError}
                />
            )}
        </>
    );
};

/* Errors in strict mode where events aren't fired propery relate to reacts strict mode
 * https://github.com/u-wave/react-vimeo/issues/200
 */
export const VideoPlayer = (props: VideoPlayerProps): JSX.Element => {
    const { url, id } = props;
    const { t } = useTranslate();
    const [hasError, setHasError] = useState(false);
    const [videoTime, setVideoTime] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const playerRef = useRef<Player>();

    const raiseEvent = useCallback((eventName: string, percent: number) => {
        if (playerRef.current == null) {
            return;
        }
        const videoTitle = playerRef.current.getVideoTitle();
        const videoDuration = playerRef.current.getDuration();
        const videoId = playerRef.current.getVideoId();

        Promise.all([videoId, videoTitle, videoDuration])
            .then(([videoId, videoTitle, videoDuration]) => {
                trackNamedPosthogEvent(eventName, {
                    video_provider: "vimeo",
                    video_url: `https://vimeo.com${url}`,
                    video_title: videoTitle,
                    video_id: videoId,
                    video_duration: videoDuration,
                    video_current_time: playerRef.current?.getCurrentTime(),
                    video_percent: percent,
                });
            })
            .catch(e => console.log(e));
    }, []);

    const handleVideoLoaded = useCallback(() => {
        if (playerRef.current == null) {
            return;
        }

        if (props.breakPoints) {
            const cuePoints = props.breakPoints.split(",").map(breakPoint => toSeconds(breakPoint.trim()));
            cuePoints.forEach(point => playerRef.current?.addCuePoint(point, {}).catchWithToast());
        }

        playerRef.current
            .getDuration()
            .then(duration => {
                playerRef.current?.addCuePoint(Math.round(duration * 0.25), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(Math.round(duration * 0.5), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(Math.round(duration * 0.75), { raiseEvent: true }).catchWithToast();
                playerRef.current?.addCuePoint(duration, { raiseEvent: true }).catchWithToast();

                setVideoTime(duration);
            })
            .catchWithToast();
    }, []);

    const handleCuePoint = useCallback(
        (event: CuePointEvent) => {
            if (playerRef.current == null) {
                return;
            }

            if (event.data["raiseEvent"]) {
                playerRef.current
                    .getDuration()
                    .then(duration => {
                        raiseEvent(`video_timeupdate`, Math.round((event.time / duration) * 100));
                    })
                    .catchWithToast();
            } else {
                playerRef.current.pause().catchWithToast();
            }
        },
        [raiseEvent]
    );

    const handleTimeUpdate = useCallback((event: TimeUpdateEvent) => {
        if (playerRef.current == null) {
            return;
        }

        setElapsedTime(Math.round(event.seconds));
    }, []);

    const handleVimeoError = useCallback((error: Error) => {
        console.error(error);
        // Don't treat InvalidCuePoint errors as errors, they will only break tracking but not the video
        if (error.name === "InvalidCuePoint") {
            customEvents.raiseVideoError(error, props.id, props.url, true);
        } else {
            customEvents.raiseVideoError(error, props.id, props.url);
        }
    }, []);

    const onReady = useCallback(
        (newPlayer: Player) => {
            playerRef.current = newPlayer;

            raiseEvent("video_loaded", 0);
        },
        [raiseEvent]
    );

    const onEnd = useCallback(
        (endEvent: EndEvent) => {
            raiseEvent("video_ended", endEvent.percent * 100);
        },
        [raiseEvent]
    );

    const onPlay = useCallback(
        (playEvent: PlayEvent) => {
            raiseEvent("video_play", playEvent.percent * 100);
        },
        [raiseEvent]
    );

    const courseVideoExtClass = props.className ? props.className : "col-12 col-md-8";
    return (
        <div className={`${courseVideoExtClass}`}>
            {hasError && <Alert color="orange">{t("COMMON_VIDEO_NOT_LOADED")}</Alert>}
            {!hasError && (
                <div className="block-content-option">
                    <Vimeo
                        video={addVimeoDotComIfNecessary(url)}
                        onReady={onReady}
                        onEnd={onEnd}
                        onPlay={onPlay}
                        speed
                        showTitle={false}
                        showPortrait={false}
                        showByline={false}
                        onLoaded={handleVideoLoaded}
                        id={`vimeo-player${id}`}
                        responsive
                        onTimeUpdate={handleTimeUpdate}
                        onError={handleVimeoError}
                        onCuePoint={handleCuePoint}
                    />
                    <RenderIf show={playerRef.current != null}>
                        <div className="showTime">
                            <i className="far fa-clock" />{" "}
                            {`${toDisplayTime(elapsedTime)} / ${toDisplayTime(videoTime)}`}
                        </div>
                    </RenderIf>
                </div>
            )}
        </div>
    );
};
