import { Course, GroupMember, ParticipantProgress } from "@/api-client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Progress } from "../Progress";
import UserAvatar from "../UserAvatar";
import { AllGroupProgress, ConsolidatedUsersProgress } from "./helper";

interface CompletionProps {
    progress: ParticipantProgress[];
    countChapters: number[];
    currentCourse: Course;
    members: GroupMember[];
}

const gtInx = (countChapters: number[], i: number) => {
    let sum = 0;
    for (let j = 0; j < i; j++) {
        sum = sum + countChapters[j];
    }
    return sum;
};

export const displayOverAllProgress = (
    progress: ConsolidatedUsersProgress[],
    countChapters: number[]
    /* @ts-ignore */
): JSX.Element => {
    if (countChapters && countChapters.length > 0) {
        let count = 0;
        let i = 0;
        let j = 0;
        countChapters.forEach(() => {
            const ind = gtInx(countChapters, j);
            for (i; i < ind; i++) {
                if (i === ind - 1) {
                    if (progress[count]) {
                        Object.assign(progress[count], { break: true });
                    }
                }
                count = count + 1;
            }
            j++;
        });
        return <Progress progress={progress} />;
    }
};

export const Completion = (props: CompletionProps): JSX.Element => {
    const { t } = useTranslate();

    const getProgress = AllGroupProgress(
        props.currentCourse,
        props.progress.filter(m => m.moduleBlockId !== null),
        props.members.filter(m => m.isParticipant)
    );

    const teamLeaders = props.members.filter(g => g.isGroupLeader);

    return (
        <Row>
            <Col xs={12} xl={3} md={6} className="text-left showGroupProgress">
                <div>
                    <small className="text-muted text-uppercase">{t("GROUP_PARTICIPANTS_PROGRESS")}</small>
                </div>
                <div className="attendiesProgress">{displayOverAllProgress(getProgress, props.countChapters)}</div>
            </Col>
            <Col className="text-left showGroupProgress">
                <div>
                    <small className="text-muted text-uppercase">{t("PROGRAM_TEAM_LEADERS")}</small>
                </div>
                <div className="attendiesProgress groupLeadersDisplay">
                    {teamLeaders.map(g => (
                        <div key={g.userId} className="align-middle full_name_tb">
                            <UserAvatar user={g.user} />
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};
