import React, { ReactNode, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useAllUsers } from "../../common/OrganisationContext";
import Footer from "../../components/Footer/footer";
import MainNavbar from "../MainNavbar";
import { setCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";

type AccountOwnerLayoutProps = {
    children: ReactNode;
    show?: boolean;
    showMainNav?: boolean;
};

export const AccountOwnerLayout = (props: AccountOwnerLayoutProps): JSX.Element => {
    useEffect(() => {
        /* @ts-ignore */
        setCourseGroupInLocalStorage(null, null);
    }, []);

    const users = useAllUsers();

    return (
        <>
            <MainNavbar users={users} />
            <Container id="layout-container">
                <div id="wrapper" className="content_area w-100">
                    {props.children}
                </div>
            </Container>
            <Footer />
        </>
    );
};
export default AccountOwnerLayout;
