import { useTranslate } from "@tolgee/react";
import { Module, ModuleStatuses } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify } from "../../../components/Notify";
import { AppLoader } from "../../../components/Spinner";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../helper";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { CardBlock } from "../../../components/CardBlock";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { useParams } from "react-router-dom";

interface AddModuleResponse {
    module: Module;
    isPending: boolean;
    isSuccess: boolean;
}

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Module Name is required"),
        //description: Yup.string()
        //.required("Description is required")
    });
};

interface FormModel {
    moduleId: number;
    name: string;
    description: string;
    order: number;
    status: ModuleStatuses;
}

type RouteParams = {
    chapterId: string;
    courseId: string;
};

const AddModule = (): JSX.Element => {
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore
    const chapterId = parseInt(params.chapterId);
    const [course, updateCourse] = useCurrentCourseFromParams();

    const api = createApi();
    const history = useHistory();

    const nav = CourseMenu(course);
    const [addModule, setAddModule] = useState<AddModuleResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        module: null,
        isSuccess: false,
    });
    const [submitted, setSubmitted] = useState(false);

    const initialValues: FormModel = {
        moduleId: 0,
        name: "",
        description: "",
        status: "Active",
        order: 0,
    };

    const isLoading = addModule.isPending ? true : false;
    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/edit` },
        { name: "Add Module", path: null },
    ];
    return (
        <>
            {
                //@ts-ignore
                chapterDetails && <Breadcrumbs breadCrumb={links} />
            }
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar children={nav} courseId={courseId} chapterId={chapterId} name={course?.name} />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <AppLoader loading={isLoading ? true : false} className="position-absolute" />
                        {addModule.isSuccess && submitted && (
                            <Fragment>
                                <Notify message="Module added successfully." id="registersuccess" type="success" />
                                {/* <Redirect to="/sysadmin/clients" /> */}
                            </Fragment>
                        )}
                        <ContentLabel text="Add Module" />
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validate()}
                            onSubmit={values => {
                                setSubmitted(true);
                                setAddModule({ ...addModule, isPending: true });
                                api.modules
                                    //@ts-ignore
                                    .post({
                                        //@ts-ignore
                                        id: 0,
                                        //@ts-ignore
                                        name: values.name,
                                        //@ts-ignore
                                        description: values.description,
                                        //@ts-ignore
                                        status: values.status,
                                        //@ts-ignore
                                        order: values.order,
                                        //@ts-ignore
                                        chapterId: chapterId,
                                        //@ts-ignore
                                        createdAt: new Date(),
                                        //@ts-ignore
                                        updatedAt: new Date(),
                                    })
                                    .then(
                                        x => {
                                            //@ts-ignore
                                            const moduleId: number = x.id;
                                            setAddModule({
                                                ...addModule,
                                                module: x,
                                                isPending: false,
                                                isSuccess: true,
                                            });
                                            updateCourse(s => ({
                                                ...s,
                                                chapters: s.chapters.map(c => {
                                                    if (c.id === chapterId) {
                                                        return {
                                                            ...c,
                                                            modules: [...c.modules, x],
                                                        };
                                                    }
                                                    return c;
                                                }),
                                            }));
                                            history.push(
                                                `/sysadmin/courses/${courseId}/chapter/${chapterId}/module/${moduleId}/edit`
                                            );
                                        },
                                        error => {
                                            history.push("/error");
                                            setAddModule({ ...addModule, isPending: false });
                                            console.log(error);
                                        }
                                    );
                            }}
                        >
                            {formikProps => {
                                const { errors, touched } = formikProps;
                                return (
                                    <Form name="addCHapterForm" className="mt-2 addCHapterForm">
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Name</label>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    className={
                                                        "form-control" +
                                                        (errors.name && touched.name ? " is-invalid" : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="clearfix w-100 text-danger text-left displayblock"
                                                    name="name"
                                                    component="span"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        {/* <Row className="mb-4">
                                             <Col>
                                             <label>Description</label>
                                             <ReactMdeEditor
                                             onChange={ ( value ) => {
                                             setDescription( value );
                                             setFieldValue( 'description', value )
                                             }}
                                             initialValue={ description }
                                             />
                                             <ErrorMessage className="clearfix w-100 text-danger text-left displayblock" name="description" component="span" />
                                             </Col>
                                             </Row> */}
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Status</label>
                                                {/** Please move the option in const I think we can reuse this eiter we have to
                                                 * write it eveywhere
                                                 */}
                                                <Field name="status" as="select" className="form-select">
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                    <option value="Inprogress">Inprogress</option>
                                                    <option value="Deleted">Deleted</option>
                                                    <option value="Invited">Invited</option>
                                                    <option value="Disabled">Disabled (Locked)</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <ContentBlockButtonContainer>
                                            <PrimaryButton type="submit" disabled={isLoading}>
                                                Save
                                            </PrimaryButton>
                                        </ContentBlockButtonContainer>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBlock>
                </div>
            </div>
        </>
    );
};

export default AddModule;
