import { Answer } from "@/api-client";
import { ColoredCategory } from "../QuestionCategoriesEditor/ColoredCategory";
import { createApi } from "../../../common/api";
import { useTranslate } from "@tolgee/react";
import { useCurrentGroup } from "../../../common/courses/context";
import React, { Fragment, useState } from "react";
import { createPatch } from "../../../common/patchHelper";
import { ActionIcon, Menu, Select, SelectItem } from "@mantine/core";
import UserCard from "../../../components/UserCard";
import { SelectItemWithIndicator } from "./SelectItemWithIndicator";
import { EditAnswer } from "./EditAnswer";
import { SplitAnswer } from "./SplitAnswer";
import { Icons } from "../../../common/Icons";
import { trackPosthogEvent } from "../../../analytics/customEvents/trackPosthogEvent";

interface AnswerCardProps {
    answer: Answer;
    onCategoryChange: (newCategoryId: string | null) => void;
    setAnswerVisibility: (answer: Answer, hidden: boolean) => void;
    categories: ColoredCategory[];
    allowDelete: boolean;
    setAnswerText: (answer: Answer) => void;
    setNewlyAddedAnswer: (answer: Answer[]) => void;
    className: string;
    answerIndex: number;
}

export const AnswerCard = ({
    answer,
    onCategoryChange,
    categories,
    setAnswerVisibility,
    allowDelete,
    setAnswerText,
    setNewlyAddedAnswer,
    className,
    answerIndex,
}: AnswerCardProps) => {
    const api = createApi();
    const { t } = useTranslate();
    const currentGroup = useCurrentGroup();
    // @ts-ignore
    const [answerToEdit, setAnswerToEdit] = useState<Answer>(null);
    // @ts-ignore
    const [answerToSplit, setAnswerToSplit] = useState<Answer>(null);

    const editAnswer = (answer: Answer) => {
        setAnswerToEdit(answer);
    };

    const splitAnswer = (answer: Answer) => {
        setAnswerToSplit(answer);
    };

    const onRenameAnswer = (text: string) => {
        if (answer !== undefined) {
            const patch = createPatch(answer, x => {
                x.text = text;
            });
            setAnswerText({ ...answer, text });
            api.answers.patch(answer.id, patch).then(
                response => {
                    // @ts-ignore
                    setAnswerToEdit(undefined);
                },
                error => {
                    // @ts-ignore
                    setAnswerToEdit(undefined);
                    console.log(error, " Error in updating answers text.");
                }
            );
        }
    };

    const splitAnswersUpdate = (answers: Answer[]) => {
        // @ts-ignore
        setAnswerToSplit(undefined);
        if (answers && answers.length > 0) {
            const originalAnswer = answers.find(a => a.id !== undefined);
            if (originalAnswer) {
                if (originalAnswer.id !== undefined) {
                    const patch = createPatch(answer, x => {
                        x.text = originalAnswer.text;
                        x.status = "Active";
                    });
                    api.answers.patch(answer.id, patch).then(
                        response => {
                            setAnswerText({ ...answer, text: response.text });
                        },
                        error => {
                            console.log(error, " Error in updating answers text.");
                        }
                    );
                }
            }
            Promise.allSettled(
                answers
                    .filter(a => a.id === undefined)
                    .map((a: Answer) => {
                        const answer: Answer = { ...a, status: "Active" };
                        // @ts-ignore
                        delete answer.id;
                        // delete answer.name;
                        return api.answers.post(answer as Answer);
                    })
            ).then(results => {
                const finalResult = results
                    .map(r => (r.status === "fulfilled" ? r.value : null))
                    .filter(e => e !== null);
                // @ts-ignore
                setNewlyAddedAnswer(finalResult);
            });
        }
    };

    const getSelectData = (): SelectItem[] => {
        let uncategorizedData = {
            label: t("WORKSHOP_UNCATEGORIZED_HEADER"),
            value: "_uncategorized",
            group: " ",
            color: "#FFFFFF",
        };

        return [
            uncategorizedData,
            ...categories
                .alphabeticSort(x => x.name)
                .map((c, i) => ({
                    label: c.name,
                    value: c.id.toString(), //Mantine requires value to always be string
                    group: t("BOARD_CATEGORIES"),
                    color: c.color.hex,
                })),
        ];
    };

    return (
        <Fragment>
            {answer && (
                <div
                    className={`${className} border p-3 rounded mb-3 answer-card ${
                        answer.hidden ? "answer-hidden" : "answer-not-hidden"
                    }`}
                    data-position={answerIndex}
                >
                    <div className={`row content-answer-card`}>
                        <div className="col col-md-8">
                            <p>{answer.text}</p>
                            <UserCard
                                // @ts-ignore
                                userId={answer.userId}
                                name={answer.name}
                                className={"user-card"}
                            />
                        </div>
                        <div className="col col-md-3 text-center">
                            <Select
                                value={answer.answerCategoryId ? answer.answerCategoryId.toString() : "_uncategorized"}
                                onChange={categoryId => {
                                    trackPosthogEvent("ui", "categorize", "changecategory");
                                    onCategoryChange(categoryId !== "_uncategorized" ? categoryId : null);
                                }}
                                itemComponent={SelectItemWithIndicator}
                                data={getSelectData()}
                                placeholder={t("WORKSHOP_UNCATEGORIZED_HEADER")}
                                zIndex={1000}
                                maxDropdownHeight={400}
                            />
                        </div>
                        <div className="col col-md-1">
                            <Menu shadow="md" width={200} zIndex={1000} withArrow={true}>
                                <Menu.Target>
                                    <ActionIcon className={"answer-settings-icon"} size={"lg"} variant={"default"}>
                                        <Icons.Settings />
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    {allowDelete && (
                                        <Menu.Item onClick={() => setAnswerVisibility(answer, !answer.hidden)}>
                                            {answer.hidden && t("WORKSHOP_RATING_SHOWANSWER")}
                                            {!answer.hidden && <>{t("WORKSHOP_RATING_HIDEANSWER")}</>}
                                        </Menu.Item>
                                    )}
                                    <Menu.Item onClick={() => editAnswer(answer)}>{t("COMMON_EDIT")}</Menu.Item>
                                    <Menu.Item onClick={() => splitAnswer(answer)}>
                                        {t("WORKSHOP_CATEGORIZATION_SPLIT_ANSWER")}
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </div>
                        {answerToEdit && (
                            <EditAnswer
                                show={answerToEdit != null}
                                answer={answerToEdit}
                                renameAnswer={(newAnswer: string) => onRenameAnswer(newAnswer)}
                                // @ts-ignore

                                hide={() => setAnswerToEdit(undefined)}
                            />
                        )}
                        {answerToSplit && (
                            <SplitAnswer
                                // @ts-ignore
                                currentGroup={currentGroup}
                                answersUpdate={(answers: Answer[]) => splitAnswersUpdate(answers)}
                                show={answerToSplit != null}
                                answer={answerToSplit}
                                // @ts-ignore

                                hide={() => setAnswerToSplit(undefined)}
                            />
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};
