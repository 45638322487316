export class ReportClient {
    constructor(private readonly baseUrl: string, private readonly getToken: () => Promise<string | null>) {}

    getUsersReport = async (): Promise<Blob> => {
        const token = await this.getToken();

        const response = await fetch(`${this.baseUrl}/api/reports/user-report`, {
            mode: "cors",
            method: "get",
            headers: {
                ...(token === null ? {} : { Authorization: token }),
            },
        });

        return await response.blob();
    };
}
