import { Location } from "react-router-dom";
import { TFunction } from "@/common";
import { Course, CourseStatuses, ModuleBlockStatuses, ModuleStatuses, ParticipantProgress } from "@/api-client";
import { TrainingRouteParams } from "..";

interface MapProgressWithChapterAndModule {
    moduleId: number;
    moduleBlockId: number;
    progressStatusId: number;
    isCompleted: boolean;
    status: ModuleStatuses;
}

export interface NavItem {
    title: string;
    subtitle?: string;
    link: string;
    active?: boolean;
    isFirstChapter?: boolean;
    chapterProgress?: boolean[] | undefined;
    progress?: boolean | undefined;
    status?: CourseStatuses | ModuleBlockStatuses | ModuleStatuses;
    isAllModulesLocked?: boolean;
    completed?: boolean | undefined;
    items?: Array<NavItem>;
}

export const mapProgressChapterAndModules = (
    currentCourse: Course,
    progress: ParticipantProgress[]
): MapProgressWithChapterAndModule[] => {
    // @ts-ignore
    const chapterModules = currentCourse.chapters
        .filter(c => c.status === "Active" || c.status === "Disabled")
        // @ts-ignore

        .map(chapter =>
            chapter.modules
                .filter(c => c.status === "Active" || c.status === "Disabled")
                // @ts-ignore

                .map(module =>
                    module.blocks
                        .filter(c => c.status === "Active" || c.status === "Disabled")
                        .map(block => {
                            // @ts-ignore
                            let userHasProgressForModuleBlock: ParticipantProgress = progress.find(
                                u => u.moduleBlockId === block.id
                            );
                            return {
                                moduleId: block.moduleId,
                                moduleBlockId: block.id,
                                progressStatusId: userHasProgressForModuleBlock?.progressStatusId,
                                isCompleted: userHasProgressForModuleBlock?.progressStatusId ? true : null,
                                status: block.status,
                            };
                        })
                )
        );
    // @ts-ignore

    return chapterModules.flat(2);
};

export const getTrainingNavItems = (
    currentCourse: Course,
    courseProgress: ParticipantProgress[],
    moduleName: string,
    location: Location,
    // @ts-ignore
    trainingRouteParams: TrainingRouteParams,
    t: TFunction
    // @ts-ignore
): NavItem[] => {
    // @ts-ignore
    const moduleBlockId = parseInt(trainingRouteParams.moduleBlockId);

    if (currentCourse && currentCourse.chapters && courseProgress) {
        /**
         * Map all modules with progress and get flat return of progress
         */
        const progress: MapProgressWithChapterAndModule[] = mapProgressChapterAndModules(
            currentCourse as Course,
            courseProgress as ParticipantProgress[]
        ).filter(b => b.status === "Active");
        // @ts-ignore

        return (
            currentCourse.chapters
                // @ts-ignore

                .sort((a, b) => a.order - b.order)
                .map((c, i) => {
                    /*
                     * Find the chapters progress here
                     */
                    // @ts-ignore

                    let isChapterCompleted = progress
                        .filter(o1 => c.modules.filter(c => c.status === "Active").some(o2 => o1.moduleId === o2.id))
                        .every(e => e.isCompleted === true);

                    // @ts-ignore

                    let chapterProgress = c.modules
                        .filter(c => c.status === "Active")
                        .filter(m => m.status === "Active")
                        // @ts-ignore

                        .map(m =>
                            m.blocks
                                .filter(b => b.status === "Active")
                                .map(b => progress.find(p => p.moduleBlockId === b.id)?.isCompleted)
                        )
                        .map(p => {
                            if (p.every(a => a === true)) {
                                return true;
                            } else if (p.every(a => a === null)) {
                                return null;
                            } else {
                                return false;
                            }
                        });
                    return {
                        title: c.name,
                        subtitle: c.navigationLabel
                            ? c.navigationLabel
                            : `${t("PROGRAM_MY_TRAINING_CHAPTER")} ${i + 1}`,
                        link: `/${moduleName}/${currentCourse.id}/chapter/${c.id}/chapter-detail`,
                        active: location.pathname.startsWith(`/${moduleName}/${currentCourse.id}/chapter/${c.id}`),
                        status: c.status,
                        chapterProgress: chapterProgress,
                        completed: isChapterCompleted,
                        isFirstChapter: i === 0,
                        //checkif all moudles are locked
                        // @ts-ignore

                        isAllModulesLocked: c.modules.every(m => m.status === "Active"),
                        /**
                         * Get Modules and create a nav
                         */

                        items: c.modules
                            // @ts-ignore
                            .sort((a, b) => a.order - b.order)
                            .map(m => {
                                /**
                                 * Find the progress and map it with modules
                                 * To show the latest progress in training section
                                 */
                                const moduleProgress = progress
                                    .filter(p => p.moduleId === m.id)
                                    .every(e => e.isCompleted === true);

                                return {
                                    title: m.name,
                                    status: m.status,
                                    progress: progress
                                        .filter(p => p.moduleId === m.id)
                                        .every(e => e.isCompleted === true),
                                    link:
                                        m.blocks && m.blocks[0]
                                            ? `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}/${m.blocks[0].id}`
                                            : "",
                                    active: location.pathname.startsWith(
                                        `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}`
                                    ),
                                    completed: moduleProgress,
                                    /**
                                     * This is something third level menu
                                     * In training section it will be story, exercise & summary
                                     */
                                    items:
                                        m.blocks &&
                                        m.blocks
                                            .filter(b => b.status === "Active" || b.status === "Disabled")
                                            .sort((a, b) => a.sortIndex - b.sortIndex)
                                            .map(n => {
                                                return {
                                                    title: n.navigationLabel ? n.navigationLabel : n.title,
                                                    link: `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}/${n.id}`,
                                                    active: n.id === moduleBlockId,
                                                    progress: progress.find(p => p.moduleBlockId === n.id)?.isCompleted,
                                                    completed: progress.find(p => p.moduleBlockId === n.id)
                                                        ?.isCompleted,
                                                    status: n.status,
                                                };
                                            })
                                            .filter(x => x !== undefined),
                                };
                            }),
                    };
                })
        );
    }
};
