import React from "react";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { createStyles } from "@mantine/core";

interface PreviewHandlerProps {
    link: string;
}

const useStyles = createStyles(() => ({ mg: { marginLeft: ".5rem" } }));

export const PreviewHandler = ({ link }: PreviewHandlerProps) => {
    const { classes } = useStyles();

    const navigateToPreview = (link: string) => {
        const tab = window.open(link, "reuse_win");
        // @ts-ignore
        tab.location.reload();
    };

    return (
        <PrimaryButton onClick={() => navigateToPreview(link)}>
            <i className="fa fa-info-circle" />
            <span className={classes.mg}>Preview</span>
        </PrimaryButton>
    );
};
