import { CheckboxGroupInput } from "./CheckboxGroupInput";
import { FormTextInput } from "./FormTextInput";
import { CheckboxInput } from "./CheckboxInput";
import { PhoneNumberInput } from "./PhoneNumberInput";
import { NativeSelectInput } from "./NativeSelectInput";
import { FormColorInput } from "./FormColorInput";
import { TextareaInput } from "./TextareaInput";
import { DependentNativeSelect } from "./DependentNativeSelect";
import { RadioGroupInput } from "./RadioGroupInput";

export const FormInput = {
    Checkbox: CheckboxInput,
    CheckboxGroup: CheckboxGroupInput,
    Text: FormTextInput,
    PhoneNumber: PhoneNumberInput,
    NativeSelect: NativeSelectInput,
    DependentNativeSelect: DependentNativeSelect,
    Textarea: TextareaInput,
    ColorInput: FormColorInput,
    RadioGroup: RadioGroupInput,
};
