import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdditionalResource, Course, ParticipantProgress, User } from "@/api-client";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import {
    useCurrentCourse,
    useCurrentCourseGroupAssignment,
    useCurrentGroup,
    useCurrentGroupCourseContextDispatch,
    useCurrentUserProgress,
} from "../../common/courses/context";
import { useCurrentClient, useCurrentUser } from "../../common/user/context";
import MainContent from "../../components/MainContent";
import { showToast } from "../../components/Notify";
import { AllQuestions } from "../../components/Questions/Allquestions";
import { Congrats } from "../Components/Congrats";
import { TrainingSidebar } from "../Components/Sidebar";
import { ContentBlock } from "../ContentModuleBlocks/ContentBlock";
import {
    checkChapterModuleAndBlockStatus,
    createSlidesForModuleBlocks,
    getNextChapterAndModuleAndModuleBlock,
    isLastModuleBlock,
    useIsValidCourseRoute,
} from "../helper";
import { mapProgressChapterAndModules } from "../helpers/TrainingNavHelper";
import { useUnstartedCourses } from "../../common/UnstartedCoursesContext";
import { UrlFactory } from "../../routing/UrlFactory";
import { useAuth } from "../../auth/useAuth";

/*  eslint-disable react-hooks/exhaustive-deps */

export type TrainingRouteParams = {
    courseId: string;
    moduleId?: string;
    chapterId: string;
    moduleBlockId?: string;
    groupId: string;
    type?: string;
};

/*
 * QA: This entire function needs to be divided into smaller components with clear separation of concerns and connected
 * to a clear routing hierarchy
 */

export const ModulesInner = () => {
    const api = createApi();
    const params = useParams<TrainingRouteParams>();
    const history = useHistory();
    const { isImpersonating } = useAuth();
    // @ts-ignore
    const moduleId = parseInt(params.moduleId);
    // @ts-ignore
    const courseId = parseInt(params.courseId);
    // @ts-ignore
    const chapterId = parseInt(params.chapterId);
    // @ts-ignore
    const groupId = parseInt(params.groupId);
    const pageType = params.type;
    // @ts-ignore
    const moduleBlockId = parseInt(params.moduleBlockId);
    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();
    const currentClient = useCurrentClient();
    const currentCourseGroupAssignment = useCurrentCourseGroupAssignment();
    const currentGroupDispatch = useCurrentGroupCourseContextDispatch();
    const unstartedCourses = useUnstartedCourses();

    const user = useCurrentUser();
    const userId = user?.id;

    const userCourseProgress: ParticipantProgress[] = useCurrentUserProgress();
    //const userCurrentProgress: boolean = userCourseProgress?.find((u) => u.moduleBlockId === moduleBlockId)?.isCompleted ?? null;

    // QA a boolean should be phrased as yes/no. A better name would be userHasCurrentProgress or similar
    const userCurrentProgress: boolean = userCourseProgress?.find(u => u.moduleBlockId === moduleBlockId) !== undefined;

    const [updateModuleProgress, setUpdateModuleProgress] = useState<boolean>(false);

    const createDiploma = (course: Course, user: User) => {
        if (isImpersonating()) {
            showToast("Training not allowed during impersonation.", "warning");
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        api.diplomas.query(user.id).then(res => {
            const isCertificateAlreadyExist = res.find(
                r =>
                    r.courseId === currentCourse?.id &&
                    r.groupId === groupId &&
                    r.roundId === currentCourseGroupAssignment?.roundId
            );
            if (isCertificateAlreadyExist == null) {
                if (course != null && user != null) {
                    const data = {
                        fullName: user.fullName,
                        courseName: course.name,
                        dateOfCompletion: new Date(),
                        userId: user.id,
                        courseId: course.id,
                        groupId: groupId,
                        roundId: currentCourseGroupAssignment?.roundId,
                        createdDate: new Date(),
                        updatedDate: new Date(),
                    };

                    api.diplomas.post(data).then(
                        _ => {
                            history.push(`/groups/${groupId}/training/${course.id}/congratulations`);
                        },
                        error => {
                            console.log(error, "Error in diploma");
                            history.push(`/groups/${groupId}`);
                        }
                    );
                }
            }
        });
    };

    const redirectToHomeOrNextBlock = (
        nextChapterId: number | undefined,
        nextModuleId: number | undefined,
        nextBlockId: number | undefined
    ): void => {
        if (nextChapterId != null && nextBlockId != null && nextModuleId != null) {
            history.push(
                `/groups/${groupId}/training/${courseId}/chapter/${nextChapterId}/module/${nextModuleId}/${nextBlockId}`
            );
        } else {
            history.push(UrlFactory.team.program.create({ groupId }));
        }
    };

    const handleClick = (
        nextChapterId: number | undefined,
        nextModuleId: number | undefined,
        nextBlockId: number | undefined
    ) => {
        // if( isUserImpersonating() ){
        //     showToast("Training not allowed during impersonation.","warning-training","warning");
        //     return;
        // }
        // Check the progress if null or false save it. Either move directly to home page
        if (userCurrentProgress === false || userCurrentProgress === null) {
            const progressStatusId = 1;
            //const isCompleted = true;
            setUpdateModuleProgress(true);

            const participantsProgress: ParticipantProgress = {
                // @ts-ignore

                userId: userId,
                moduleId: moduleId,
                progressStatusId: progressStatusId,
                moduleBlockId: moduleBlockId,
                // @ts-ignore

                groupId: currentGroup.id,

                // @ts-ignore
                roundId: currentCourseGroupAssignment.roundId ?? 0,
                //isCompleted,
            };

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            api.participantProgress
                .putByUserIdAndModuleId(
                    // @ts-ignore

                    userId,
                    moduleId,
                    moduleBlockId,
                    // @ts-ignore

                    currentGroup.id,
                    // @ts-ignore

                    currentCourseGroupAssignment.roundId ?? 0, // API have special logic for converting 0 to null to avoid issue with swagger and sending null
                    participantsProgress
                )
                .then(response => {
                    setUpdateModuleProgress(false);
                    currentGroupDispatch(s => ({
                        ...s,
                        // @ts-ignore

                        participantsProgress: [...s.participantsProgress, response],
                    }));

                    unstartedCourses.refresh();

                    const mergeProgress = [...userCourseProgress, response];
                    const mapUserProgressWithCourse = mapProgressChapterAndModules(
                        // @ts-ignore

                        currentCourse,
                        mergeProgress
                    );
                    const isProgressCompleted = mapUserProgressWithCourse
                        .flatMap(a => a.progressStatusId === 1)
                        .every(c => c === true);
                    // @ts-ignore

                    if (isProgressCompleted && currentCourse.enableDiploma) {
                        // @ts-ignore

                        createDiploma(currentCourse, user);
                    } else {
                        redirectToHomeOrNextBlock(nextChapterId, nextModuleId, nextBlockId);
                    }
                });
        } else {
            redirectToHomeOrNextBlock(nextChapterId, nextModuleId, nextBlockId);
        }
    };

    // @ts-ignore

    const [additionalResources, setAdditionalResources] = useState<AdditionalResource[]>(null);

    //Temp solution for getting the Additional Resources
    useEffect(() => {
        api.additionalResources.query(null, null).then(result => {
            setAdditionalResources(result);
        });
    }, []);

    const moduleBlockSlides = chapterId
        ? createSlidesForModuleBlocks(
              // @ts-ignore

              currentCourse,
              chapterId,
              moduleId,
              moduleBlockId
          )
        : null;

    const moduleBlocks =
        moduleBlockSlides != null && moduleBlockSlides.current && moduleBlockSlides.current.length > 0
            ? moduleBlockSlides.current
            : null;
    const { nextChapter, nextModule, nextModuleBlock } = getNextChapterAndModuleAndModuleBlock(
        currentCourse,
        chapterId,
        moduleId,
        moduleBlockId
    );

    useEffect(() => {
        const isStatusActive = checkChapterModuleAndBlockStatus(
            currentCourse as Course,
            chapterId,
            moduleId,
            moduleBlockId
        );
        if (!isStatusActive) {
            history.push("/");
        }
    }, [currentCourse, chapterId, moduleId, moduleBlockId]);

    return (
        <div style={{ marginBottom: "10px", display: "flex", flex: 1 }}>
            <TrainingSidebar />
            <MainContent>
                {pageType && pageType === "congratulations" && (
                    // @ts-ignore

                    <Congrats course={currentCourse} client={currentClient} user={user} />
                )}
                <div className="row">
                    <div className="col-12">
                        {moduleBlocks != null &&
                            moduleBlocks.map((m, i) => (
                                <React.Fragment key={`module-wrapper-${m.moduleId}-${m.id}`}>
                                    <ContentBlock
                                        onClick={() =>
                                            handleClick(nextChapter?.id, nextModule?.id, nextModuleBlock?.id)
                                        }
                                        key={`module-block-${m.moduleId}-${m.id}`}
                                        moduleBlock={m}
                                        // @ts-ignore

                                        isLast={isLastModuleBlock(
                                            // @ts-ignore

                                            currentCourse,
                                            chapterId,
                                            moduleId,
                                            m.id
                                        )}
                                        id={`module-block-${m.id}`}
                                        sequence={i}
                                        moduleBlockId={moduleBlockId}
                                        // @ts-ignore

                                        currentModuleBlockId={m.id}
                                        moduleProgressPending={updateModuleProgress}
                                        additionalResources={additionalResources}
                                    />
                                    {/* NOTE:- Let's add the common props to pass in course and questions */}
                                    {m.questions && m.questions.length > 0 && (
                                        <AllQuestions
                                            questions={m.questions}
                                            moduleId={moduleId}
                                            chapterId={chapterId}
                                            courseId={courseId}
                                            groupId={groupId}
                                            // @ts-ignore

                                            roundId={currentCourseGroupAssignment?.roundId}
                                            // QA: currentProgress is a bad name for a boolean
                                            currentProgress={
                                                userCourseProgress?.find(u => u.moduleBlockId === m.id) !== undefined
                                                    ? true
                                                    : null
                                            }
                                            id={`module-block-${m.id}`}
                                            // @ts-ignore

                                            isLast={isLastModuleBlock(
                                                // @ts-ignore

                                                currentCourse,
                                                chapterId,
                                                moduleId,
                                                m.id
                                            )}
                                            // oldProgressId={userCurrentProgress}
                                            nextModuleBlock={nextModuleBlock ?? undefined}
                                            moduleBlock={m}
                                            moduleBlockId={moduleBlockId}
                                            key={`${m.id}"_"${moduleId}"_"quest`}
                                            summaryButtonHandler={() => {
                                                handleClick(nextChapter?.id, nextModule?.id, nextModuleBlock?.id);
                                            }}
                                            moduleProgressPending={updateModuleProgress}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            </MainContent>
        </div>
        // </TrainingLayout>
    );
};

export const Modules = () => {
    const params = useParams<TrainingRouteParams>();
    const history = useHistory();
    const currentCourse = useCurrentCourse();

    // Redirect if not valid route
    // @ts-ignore
    const isValidRoute = useIsValidCourseRoute(currentCourse, params);

    if (isValidRoute !== undefined && isValidRoute === false) {
        console.info("Invalid course route, redirecting");
        history.replace(`/groups/${params.groupId}`);
    }

    return <> {isValidRoute && <ModulesInner />}</>;
};

export default Modules;
