import { useTranslate } from "@tolgee/react";
import { IntroductionVideo, Video } from "@/api-client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createApi } from "../../../common/api";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import ContentBlock from "../../../components/ContentBlock";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { CoursesLayout } from "../CoursesLayout";
import { useCurrentCourseFromParams } from "../helper";
import ManageIntroductionVideos from "./ManageIntroductionVideos";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    courseId: string;
    introductionVideoId?: string;
};

interface AddVideoResponse {
    videos: Video[];
    isPending: boolean;
}

const ContentIntroductionVideos = (): JSX.Element => {
    const api = createApi();
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    // @ts-ignore
    const introductionVideoId = parseInt(params.introductionVideoId);

    const [videoList, setVideoList] = useState<AddVideoResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        videos: null,
    });

    // @ts-ignore

    const [introductionVideo, setIntroductionVideo] = useState<IntroductionVideo>(null);

    const getIntroductionVideo = (id: number) => {
        api.introductionVideos.get(id).then(
            result => {
                setIntroductionVideo(result);
            },
            error => {
                console.log(error, "Error in getting introudction videos.");
            }
        );
    };

    useEffect(() => {
        getIntroductionVideo(introductionVideoId);
        getVideos();
    }, []);
    const getVideos = () => {
        setVideoList({ ...videoList, isPending: true });
        api.videos.query(null).then(
            result => {
                setVideoList({ ...videoList, videos: result, isPending: false });
            },
            error => {
                console.log(error);
                setVideoList({ ...videoList, isPending: false });
            }
        );
    };

    const [course] = useCurrentCourseFromParams();

    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: introductionVideoId ? "Edit Introduction Video" : "Add Introduction Video", path: null },
    ];

    const nav = CourseMenu(course);

    return (
        <CoursesLayout>
            {course && (
                <Breadcrumbs
                    // @ts-ignore

                    breadCrumb={links}
                />
            )}
            <div className="row">
                <div className="col-md-4">
                    <ContentBlock>
                        <Sidebar children={nav} courseId={courseId} name={course?.name} />
                    </ContentBlock>
                </div>
                <div className="col-md-8">
                    <ContentBlock>
                        <ManageIntroductionVideos
                            courseId={courseId}
                            introductionVideoId={introductionVideoId}
                            introductionVideo={introductionVideo}
                            videoList={videoList.videos}
                            isPending={videoList.isPending}
                        />
                    </ContentBlock>
                </div>
            </div>
        </CoursesLayout>
    );
};

export default ContentIntroductionVideos;
