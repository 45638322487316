import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { User } from "@/api-client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import "react-phone-number-input/style.css";
import { useHistory } from "@/common";
import * as yup from "yup";
import { createApi } from "../../common/api";
import { createPatch } from "../../common/patchHelper";
import { useUserContext } from "../../common/user/context";
import { DisplayInlineError } from "../../components/DisplayInlineError";
import { AppLoader } from "../../components/Spinner";
import { LoginContainer } from "../../login/LoginContainer";
import "./style.scss";
import { useAuth } from "../../auth/useAuth";
import { PrimaryButton } from "../../components/Button/PrimaryButton";

const CompleteProfile = () => {
    const { t } = useTranslate();

    const { user } = useUserContext();
    const { refresh } = useAuth();

    const [isPending, setIsPending] = useState(false);

    // Hold the image
    // @ts-ignore
    const [image, setImage] = useState<string>(null);

    const history = useHistory();

    const api = createApi();

    const insights = useAppInsightsContext();

    // @ts-ignore
    const initialValues: User = {
        ...user,
    };

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: false,
        initialValues,
        validationSchema: yup.object({
            firstName: yup.string().required(t("REGISTRATION_FIRST_NAME_REQUIRED")).nullable(),
            lastName: yup.string().required(t("REGISTRATION_LAST_NAME_REQUIRED")).nullable(),
        }),
        onSubmit: values => {
            handleSubmit(values);
        },
        enableReinitialize: false,
    });

    /**
     * Submit form for handling user profile
     * @param values
     */
    // @ts-ignore
    const handleSubmit = values => {
        const patch = createPatch(user!, u => {
            // @ts-ignore
            u.title = values.title;
            // @ts-ignore
            u.firstName = values.firstName;
            // @ts-ignore
            u.lastName = values.lastName;
            // @ts-ignore
            u.image = values.image;
        });
        if (patch && patch.length > 0) {
            setIsPending(true);
            // @ts-ignore
            api.users.patch(user.id, patch).then(
                response => {
                    // @ts-ignore
                    insights.trackEvent({ name: "UPDATE_USER_PROFILE_SUCESS" }, { email: user.email });

                    refresh(response);

                    setIsPending(false);
                    history.push("/");
                },
                error => {
                    setIsPending(false);
                    // @ts-ignore
                    insights.trackEvent({ name: "UPDATE_USER_PROFILE_ERROR" }, { email: user.email });
                    console.log(error, "Error in patch of users.");
                }
            );
        } else {
            history.push("/");
        }
    };

    /**
     * Method used to upload the image.
     * @param image
     * @param setFieldValue
     * @returns
     */
    // @ts-ignore
    const handleImageChange = (image: File, setFieldValue) => {
        // setImage(URL.createObjectURL(image));
        // Save file reference until user submit form
        setIsPending(true);
        // @ts-ignore
        return api.users.uploadProfileImage(user?.id, { fileName: image.name, data: image }).then(
            userImageUrl => {
                setIsPending(false);
                userImageUrl = userImageUrl + "?t=" + new Date().getTime();
                setFieldValue("image", userImageUrl);
                setImage(userImageUrl);
                // @ts-ignore
                insights.trackEvent({ name: "UPDATE_USER_PROFILE_PICTURE" }, { email: user.email });
            },
            error => {
                setIsPending(false);
                // @ts-ignore
                insights.trackEvent({ name: "UPDATE_USER_PROFILE_PICTURE_ERROR" }, { email: user.email });
                console.log(error, "Error in image uploading.");
            }
        );
    };

    const userAvatar = image ? image : formik.values.image;

    return (
        <React.Fragment>
            <AppLoader loading={isPending} />
            <div>
                <LoginContainer>
                    <div className="container">
                        <div className="d-flex justify-content-center h-100">
                            <div className="login-card">
                                <h5 className="text-center mt-3">{t("COMPLETE_PROFILE_LABEL")}</h5>
                                <Form className="px-4 complete-profile" onSubmit={formik.handleSubmit}>
                                    <div className="file has-name">
                                        <label className="file-label">
                                            <input
                                                type="file"
                                                accept="image/jpg, image/png, image/jpeg"
                                                name="image"
                                                multiple={false}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    // @ts-ignore
                                                    handleImageChange(e.target.files[0], formik.setFieldValue);
                                                }}
                                                className={"file-input"}
                                            />
                                            {userAvatar ? (
                                                <img className="profile-image" src={userAvatar} alt="profile-img" />
                                            ) : (
                                                <span className="file-cta">
                                                    <span className="file-label">Image</span>
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    <Form.Group>
                                        <Form.Label>{t("COMMON_TITLE")}</Form.Label>
                                        <Form.Control
                                            value={formik.values.title}
                                            autoFocus
                                            onChange={e => {
                                                e.persist();
                                                formik.setFieldValue("title", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("PROFILE_FIRST_NAME")}</Form.Label>
                                        <Form.Control
                                            value={
                                                formik.values.firstName === null ? undefined : formik.values.firstName
                                            }
                                            autoFocus
                                            onChange={e => {
                                                e.persist();
                                                formik.setFieldValue("firstName", e.target.value);
                                            }}
                                        />
                                        <DisplayInlineError
                                            className="clearfix w-100 text-danger text-start displayblock"
                                            error={formik.errors.firstName as string}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mt-3 mb-5">
                                        <Form.Label>{t("PROFILE_LAST_NAME")}</Form.Label>
                                        <Form.Control
                                            value={formik.values.lastName !== null ? formik.values.lastName : undefined}
                                            onChange={e => {
                                                e.persist();
                                                formik.setFieldValue("lastName", e.target.value);
                                            }}
                                        />
                                        <DisplayInlineError
                                            className="clearfix w-100 text-danger text-start displayblock"
                                            error={formik.errors.lastName as string}
                                        />
                                    </Form.Group>
                                    <Form.Group className="reset-password-button mt-3">
                                        <PrimaryButton
                                            fullWidth
                                            type="submit"
                                            eventName={{ object: "completeprofile", action: "save" }}
                                        >
                                            {t("COMMON_CONTINUE")}
                                        </PrimaryButton>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </LoginContainer>
            </div>
        </React.Fragment>
    );
};

export default CompleteProfile;
