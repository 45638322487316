import React from "react";
import { useTranslate } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import ResourcesLayout from "./Layout";
import PlanNewCourse from "./PlanACourse";
import PlanCourseGroupMembers from "./PlanCourseGroupMembers";
import SendInvites from "./SendInvites";
import SendInvitesConfirmation from "./SendInvitesConfirmation";
import "./slideshow.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface RenderTemplateProps {
    resourceType: string;
    groupId: number;
}
/* @ts-ignore */
export const SlideShow = (props): JSX.Element => {
    const params = useParams();
    const { t } = useTranslate();
    const SlideShowNavItems = [
        {
            footerButtonLabel: t("COMMON_NEXT"),
            name: "plan-course-group-members",
            label: t("RESOURCES_NAV_SELECT_PARTICIPANTS"),
            isValid: false,
            message: null,
        },
        {
            footerButtonLabel: t("COMMON_NEXT"),
            name: "send-invites",
            label: t("RESOURCES_NAV_SEND_INVITES"),
            isValid: false,
            message: null,
        },
    ];
    //@ts-ignore
    const resourceType = params.template;
    /* @ts-ignore */
    const courseId = parseInt(params.courseId);
    const SlideShowWithSuccessItem = [
        ...SlideShowNavItems,
        {
            footerButtonLabel: t("COMMON_NEXT"),
            name: "send-invites-success",
            label: t("COMMON_SUCCESS"),
        },
    ];

    const RenderTemplate = (props: RenderTemplateProps) => {
        switch (props.resourceType) {
            case "plan-a-new-course":
                return <PlanNewCourse />;
            case "plan-course-group-members":
                return <PlanCourseGroupMembers courseId={courseId} />;
            case "send-invites":
                return <SendInvitesConfirmation courseId={courseId} />;
            case "send-invites-success":
                return <SendInvites courseId={courseId} />;
            default:
                return null;
        }
    };

    const findActiveSlideIndex =
        SlideShowWithSuccessItem && SlideShowWithSuccessItem?.findIndex(s => s.name === resourceType);

    const breadCrumbs =
        SlideShowWithSuccessItem &&
        SlideShowWithSuccessItem.length > 0 &&
        SlideShowWithSuccessItem.map((m, i) => ({
            name: `${i + 1}. ${m.label}`,
            path: i <= findActiveSlideIndex ? `/account-owner/resources/${m.name}/${courseId}` : null,
        }));

    return (
        <ResourcesLayout courseId={courseId}>
            {/* @ts-ignore */}
            <Breadcrumbs breadCrumb={breadCrumbs} className="organisation-breadcrumbs resources-breadcrumbs" />
            {resourceType && <RenderTemplate resourceType={resourceType} {...props} />}
        </ResourcesLayout>
    );
};
export default SlideShow;
