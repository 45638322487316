import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "@/common";
import "./style.scss";

export type BreadCrumbLinks = {
    name: string;
    path: string;
    dropdownItems?: BreadCrumbLinks[];
};

type BreadcrumbsProps = {
    breadCrumb?: BreadCrumbLinks[];
    className?: string;
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
    const history = useHistory();
    // @ts-ignore
    const handleRedirect = path => {
        history.push(path);
        return path;
    };

    const [activeDropdown, setActiveDropdown] = useState<number>();
    const node = useRef(null);

    const handleClick = (e: MouseEvent) => {
        // @ts-ignore

        if (node && node.current.contains(e.target)) {
            // inside click
            return;
        }

        // outside click
        setActiveDropdown(undefined);
    };

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Breadcrumb className={`breadcrump-component ${props.className}`} ref={node}>
            {props.breadCrumb &&
                props.breadCrumb.length > 0 &&
                props.breadCrumb.map((b, i) => {
                    return (
                        <React.Fragment key={`breadcrumb-${b.name}-${i}`}>
                            {b.dropdownItems && (
                                <>
                                    <Breadcrumb.Item>
                                        <li className="active relative drop-container">
                                            <span
                                                onClick={evt => {
                                                    setActiveDropdown(i);
                                                }}
                                            >
                                                {b.name}{" "}
                                                {b.dropdownItems && b.dropdownItems.length > 0 && (
                                                    <i className="fas fa-caret-down" aria-hidden="true"></i>
                                                )}
                                            </span>
                                            <div
                                                className="bg-white drop"
                                                style={{ display: activeDropdown === i ? "inline-block" : "none" }}
                                            >
                                                <ul className="list">
                                                    {b.dropdownItems.map((d, ix) => (
                                                        <li key={ix}>
                                                            {d.path && (
                                                                <Link
                                                                    to={d.path}
                                                                    onClick={() => setActiveDropdown(undefined)}
                                                                >
                                                                    {d.name}
                                                                </Link>
                                                            )}
                                                            {!d.path && (
                                                                <Link to={""} className="disabled">
                                                                    {d.name}
                                                                </Link>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    </Breadcrumb.Item>
                                </>
                            )}
                            {!b.dropdownItems && (
                                <>
                                    {b.path === null || !b.path ? (
                                        <span className="title-no-link">{b.name}</span>
                                    ) : (
                                        <Breadcrumb.Item onClick={() => handleRedirect(b.path)} key={i}>
                                            {b.name}
                                        </Breadcrumb.Item>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
        </Breadcrumb>
    );
};
