import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Container, Flex, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { TaskSummary } from "./TaskSummary";
import { useOnboardingVideos } from "../../onboarding/useOnboardingVideos";
import { AppLoader } from "@/components";
import { TeamMessage } from "./TeamMessage";
import { useCurrentUser, createApi } from "@/common";
import { Group } from "@/api-client";
import { hasSetTeamMessagePermission } from "../../auth/permissions";
import { TeamCard } from "./TeamCard/TeamCard";
import { useGetLatestPrograms } from "./LatestPrograms/useGetLatestProgram";
import { LatestPrograms } from "./LatestPrograms/LatestPrograms";

type HomeProps = {
    teamId: number;
};

const Home = (props: HomeProps): JSX.Element => {
    const api = createApi();
    const [currentTeam, setCurrentTeam] = useState<Group | null>(null);
    const currentUser = useCurrentUser();
    const onBoardingUrl = useOnboardingVideos();
    const latestPrograms = useGetLatestPrograms(props.teamId, currentUser.clientId!, e =>
        showNotification({
            message: e.message,
            color: "red",
        })
    );

    useEffect(() => {
        const getCurrentTeam = async () => {
            const team = await api.groups.get(props.teamId);
            setCurrentTeam(team);
        };

        getCurrentTeam().catchWithToast();
    }, [props.teamId]);

    if (onBoardingUrl !== undefined) {
        return <Navigate to={onBoardingUrl} />;
    }

    if (currentTeam === null) {
        return <AppLoader loading />;
    }

    const onMessageChanged = async (message: string | null) => {
        await api.home.setTeamMessage(currentUser.clientId!, props.teamId, message);
        setCurrentTeam(s => ({ ...s!, participantsMessage: message }));
    };

    const getTeamMembers = () =>
        currentTeam.members.map(m => ({
            user: m.user!,
            isTeamLeader: m.isGroupLeader,
        }));

    return (
        <Container size="xl">
            <Flex direction="column" rowGap="xl" mb="xl">
                <Flex>
                    <Title order={3}>{currentTeam.name}</Title>
                </Flex>

                <Flex gap="xl" direction="row">
                    <Flex direction="column" rowGap="xl">
                        <TaskSummary team={currentTeam} />
                        <LatestPrograms latestPrograms={latestPrograms} teamId={props.teamId} />
                    </Flex>

                    <Flex w="100%">
                        <TeamMessage
                            message={currentTeam.participantsMessage}
                            onMessageChange={onMessageChanged}
                            teamId={currentTeam.id!}
                            clientId={currentUser.clientId!}
                            hasEditPermission={hasSetTeamMessagePermission(currentUser, currentTeam)}
                        />
                    </Flex>
                </Flex>

                <Flex>
                    <TeamCard teamMembers={getTeamMembers()} />
                </Flex>
            </Flex>
        </Container>
    );
};

export default Home;
