import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProfileSideMenu } from "./components/ProfileSideMenu";
import { useTranslate } from "@tolgee/react";
import RenderIf from "../../components/render-if/render-if";
import ProfileForm from "./components/ProfileForm";
import ProfileChangePasswordForm from "./components/ProfileChangePasswordForm";
import { useCurrentUser } from "../../common/user/context";
import { CardBlock } from "../../components/CardBlock";

const Profile = () => {
    const { t } = useTranslate();

    const userProfile = useCurrentUser();
    const [activeTab, setActiveTab] = useState<string>("profile");

    if (!(userProfile && userProfile.id)) {
        return <></>;
    }

    return (
        <Container>
            <Row>
                <ProfileSideMenu userProfile={userProfile} setActiveTab={setActiveTab} activeTab={activeTab} />

                <Col className="mt-4  mt-lg-0" lg={8}>
                    <CardBlock>
                        <RenderIf show={activeTab === "profile"}>
                            <div>
                                <h5 className="pt-2 pb-4 text-dark border-bottom pl-2">{t("PROFILE_TITLE")}</h5>
                                <ProfileForm userProfile={userProfile} />
                            </div>
                        </RenderIf>
                        <RenderIf show={activeTab === "password"}>
                            <div>
                                <h5 className="pt-2 pb-4 text-dark border-bottom pl-2">{t("PROFILE_MENU_PASSWORD")}</h5>
                                <ProfileChangePasswordForm />
                            </div>
                        </RenderIf>
                    </CardBlock>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
