import { Button as MantineButton, MantineSize, DefaultProps } from "@mantine/core";
import { MouseEventHandler } from "react";
import { trackPosthogEvent } from "analytics/customEvents/trackPosthogEvent";
import { WithChildren } from "../../common/StateWrapper";

export type EventName = {
    object: string;
    action: string;
};

type ButtonProps = {
    variant?: "outline";
    type?: "button" | "submit";
    loading?: boolean;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    fullWidth?: boolean;
    danger?: boolean;
    tabIndex?: number;
    size?: MantineSize;
    eventName?: EventName;
} & WithChildren &
    DefaultProps;

export const Button = (props: ButtonProps) => {
    const { danger, eventName, ...other } = props;

    const onClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick !== undefined) {
            props.onClick(ev);
        }

        if (eventName != null) {
            trackPosthogEvent("ui", eventName.object, eventName.action);
        }
    };

    return (
        <MantineButton
            radius="sm"
            size={props.size ? props.size : "md"}
            loading={props.loading}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            variant={props.variant}
            type={props.type}
            color={danger === true ? "red" : undefined}
            tabIndex={props.tabIndex}
            {...other}
            onClick={onClick}
        >
            {props.children}
        </MantineButton>
    );
};
