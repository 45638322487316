import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_TableOptions,
    MRT_TableState,
} from "mantine-react-table";
import { createStyles, Flex } from "@mantine/core";
import { useTableLocalization } from "./useTableLocalization";

const useTableStyles = createStyles(() => ({
    paper: {
        overflow: "hidden",
    },
}));

export type RowActionConfig<T extends Record<string, unknown>> = {
    renderRowActions: MRT_TableOptions<T>["renderRowActions"];
    size: number;
};

type TableProps<T extends Record<string, unknown>> = {
    columns: MRT_ColumnDef<T>[];
    data: T[] | null | undefined;
    initialColumnFilterState: MRT_ColumnFiltersState;
    initialSort?: { id: string; desc: boolean }[];
    enableColumnFilters?: boolean;
    toolbarActions?: MRT_TableOptions<T>["renderTopToolbarCustomActions"];
    hiddenColumns?: (keyof T)[];
    rowActions?: RowActionConfig<T>;
    rowSelection?: {
        onRowSelectionChange: MRT_TableOptions<T>["onRowSelectionChange"];
        selectedRows: MRT_TableState<T>["rowSelection"];
        getRowId: MRT_TableOptions<T>["getRowId"];
    };
};

export const Table = <T extends Record<string, unknown>>(props: TableProps<T>) => {
    const { classes } = useTableStyles();
    const localization = useTableLocalization();

    const hiddenColumnsData = props.hiddenColumns ? props.hiddenColumns : [];
    const hiddenColumns: { [key: string]: boolean } = Object.assign(
        {},
        ...hiddenColumnsData.map(c => ({ [c]: false }))
    );

    const rowActionProps: Partial<MRT_TableOptions<T>> = props.rowActions
        ? {
              enableRowActions: true,
              positionActionsColumn: "last",
              renderRowActions: cellProps => (
                  <Flex justify="flex-end">{props.rowActions!.renderRowActions!(cellProps)}</Flex>
              ),
              displayColumnDefOptions: {
                  "mrt-row-actions": {
                      mantineTableHeadCellProps: {
                          align: "center",
                      },
                      header: "",
                      size: props.rowActions.size,
                  },
              },
          }
        : {};

    const rowSelection: { props: Partial<MRT_TableOptions<T>>; state: Partial<MRT_TableState<T>> } =
        props.rowSelection !== undefined
            ? {
                  props: {
                      enableRowSelection: true,
                      selectAllMode: "all",
                      positionToolbarAlertBanner: "bottom",
                      onRowSelectionChange: props.rowSelection.onRowSelectionChange,
                      getRowId: props.rowSelection.getRowId,
                      mantineTableBodyRowProps: ({ row }) => ({
                          //add onClick to row to select upon clicking anywhere in the row
                          onClick: row.getToggleSelectedHandler(),
                      }),
                  },
                  state: {
                      rowSelection: props.rowSelection.selectedRows,
                  },
              }
            : { props: {}, state: {} };

    return (
        <MantineReactTable
            columns={props.columns}
            data={props.data === undefined || props.data === null ? [] : props.data}
            enableColumnFilters={props.enableColumnFilters}
            enableColumnDragging={false}
            enableSorting
            enableTopToolbar
            //'Hard' to implement on 'custom' columns. So disable it globally for a consistent feel
            enableFilterMatchHighlighting={false}
            enableFullScreenToggle={false}
            enableHiding={false}
            enableDensityToggle={false}
            initialState={{
                columnFilters: props.initialColumnFilterState,
                density: "xs",
                ...(props.initialSort
                    ? {
                          sorting: props.initialSort,
                      }
                    : {}),
                columnVisibility: hiddenColumns,
                pagination: { pageIndex: 0, pageSize: 50 },
            }}
            state={{
                isLoading: props.data === null || props.data === undefined,
                ...rowSelection.state,
            }}
            renderTopToolbarCustomActions={props.toolbarActions}
            mantinePaperProps={{
                radius: "md",
                className: classes.paper,
                shadow: "xs",
                withBorder: true,
            }}
            mantineTopToolbarProps={{
                //This is pretty brittle, but the padding cannot be set currently.
                //https://github.com/KevinVandy/mantine-react-table/blob/e8710b1abd0ed7a92d63ce44bca79535cff5aeaf/packages/mantine-react-table/src/toolbar/MRT_TopToolbar.tsx#L92
                sx: theme => {
                    return {
                        "& > div:nth-child(2)": {
                            marginBottom: theme.spacing.xs,
                            alignItems: "center",
                        },
                    };
                },
            }}
            mantineProgressProps={{
                //Space progressbar from button
                mx: 8,
            }}
            mantineTableProps={{
                sx: {
                    tableLayout: "fixed",
                },
                highlightOnHover: false,
                withColumnBorders: false,
                striped: true,
            }}
            {...rowActionProps}
            {...rowSelection.props}
            localization={localization}
        />
    );
};
