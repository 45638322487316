import { Client } from "@/api-client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createApi } from "../../../common/api";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import AssignAccountOwner from "../AssignAccountOwner";
import AssignCourses from "../AssignCourses";
import GroupTypes from "../GroupTypes";
import { EditForm } from "./EditForm";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    clientId: string;
};

type ClientState = {
    client: Client;
    isPending: boolean;
};

const EditClient = (): JSX.Element => {
    const params = useParams<RouteParams>();
    //@ts-ignore
    const clientId = parseInt(params.clientId);
    const api = createApi();

    const [clientState, setClientState] = useState<ClientState>({
        // @ts-ignore
        client: null,
        // @ts-ignore
        isPending: null,
    });

    useEffect(() => {
        if (clientId) {
            setClientState({ ...clientState, isPending: true });
            api.clients.get(clientId).then(
                result => {
                    setClientState({ ...clientState, client: result, isPending: false });
                },
                _ => {
                    setClientState({ ...clientState, isPending: false });
                }
            );
        }
    }, [clientId]);

    const breadCrumb = [
        { name: "Home", path: "/sysadmin" },
        { name: "Clients", path: "/sysadmin/clients" },
        { name: clientState.client?.name, path: null },
    ];

    return (
        <>
            {clientState.client && (
                <Breadcrumbs
                    //@ts-ignore
                    breadCrumb={breadCrumb}
                />
            )}
            <div className="row">
                {/* <div className='col-md-4'>
                 <ContentBlock>
                 <Sidebar children={sidebarChildren} name="Edit Clients" />
                 </ContentBlock>
                 </div> */}
                <div className="col-md-12">
                    <EditForm clientData={clientState} />
                </div>
            </div>
            <AssignCourses clientId={clientId} client={clientState.client} />
            <AssignAccountOwner clientId={clientId} client={clientState.client} />
            <GroupTypes clientId={clientId} />
        </>
    );
};

export default EditClient;
