import { Question } from "@/api-client";
import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { createApi } from "../../../../common/api";
import { showToast } from "../../../../components/Notify";
import { QuestionTypes } from "../../../../workshop/QuestionTypes";
import { useSysadminContextDispatch } from "../../../SysadminContext";
import { CopyToDialog, CopyToDialogProps, LevelEnum } from "../../CopyToDialog";
import { EditAction } from "../../EditAction";
import { CopyAction } from "../../CopyAction";

export const ItemType = {
    CARD: "chapter",
};

export const QuestionModuleBlockCard = (props: {
    question: Question;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    courseId: number;
    id: number;
    chapterId: number;
    moduleId: number;
    moduleBlockId: number;
}) => {
    const [showCopyDialog, setShowCopyDialog] = useState<boolean>();

    const api = createApi();
    const sysadminContextDispatch = useSysadminContextDispatch();

    const ref = useRef(null);
    //@ts-ignore
    const [{ handlerId }, drop] = useDrop({
        accept: ItemType.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        //@ts-ignore
        hover(item: { id: number; index: number }, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            //@ts-ignore
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            //@ts-ignore
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemType.CARD,
        item: () => {
            return {
                id: props.id,
                index: props.index,
            };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const containerClass = isDragging ? "dragging-container" : "listing-container";
    drag(drop(ref));

    const copyToProps: CopyToDialogProps = {
        initialSelection: {
            courseId: props.courseId,
            chapterId: props.chapterId,
            moduleId: props.moduleId,
            contentBlockId: props.moduleBlockId,
        },
        level: LevelEnum.ContentBlock,
        onSelected: data => {
            // Clone chapter and set Id to zero so we create new instead of updating existing
            //@ts-ignore
            const questionClone: Question = { ...props.question, id: 0, moduleBlockId: data.contentBlock.id };

            // Update local state
            api.questions.post(questionClone).then(
                x => {
                    sysadminContextDispatch(s => ({
                        ...s,
                        //@ts-ignore
                        courses: s.courses.map(c => {
                            if (c.id === data.course.id) {
                                return {
                                    ...c,
                                    chapters: c.chapters.map(ch => {
                                        //@ts-ignore
                                        if (ch.id === data.chapter.id) {
                                            return {
                                                ...ch,
                                                modules: ch.modules.map(m => {
                                                    //@ts-ignore
                                                    if (m.id === data.module.id) {
                                                        return {
                                                            ...m,
                                                            blocks: m.blocks.map(mb => {
                                                                if (mb.id === x.moduleBlockId) {
                                                                    return {
                                                                        ...mb,
                                                                        questions: [...mb.questions, x],
                                                                    };
                                                                } else {
                                                                    return mb;
                                                                }
                                                            }),
                                                        };
                                                    } else {
                                                        return m;
                                                    }
                                                }),
                                            };
                                        } else {
                                            return ch;
                                        }
                                    }),
                                };
                            } else {
                                return c;
                            }
                        }),
                    }));

                    showToast(
                        //@ts-ignore
                        `Question successfully copied to ${data.contentBlock.title}`,
                        "success"
                    );
                },
                error => {
                    console.log(error);
                }
            );

            // Hide modal
            setShowCopyDialog(false);
        },
        //@ts-ignore
        show: showCopyDialog,
        onClose: () => setShowCopyDialog(false),
    };

    return (
        <>
            <tr ref={ref} className={containerClass} data-handler-id={handlerId}>
                <td>{props.index + 1}.</td>
                <td>{props.question?.text}</td>
                <td> {QuestionTypes[props.question?.questionTypeId]} </td>
                <td className="whitespace-nowrap col-1">
                    <EditAction
                        link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}/question/${props.question?.id}/edit`}
                    />
                </td>
                <td className="whitespace-nowrap col-1">
                    <CopyAction onClick={() => setShowCopyDialog(true)}></CopyAction>
                </td>
            </tr>
            {showCopyDialog && <CopyToDialog {...copyToProps} />}
        </>
    );
};
