import React from "react";
import "./style.scss";

function ContentBlock(props: {
    children: React.ReactNode;
    className?: string | null;
    style?: React.CSSProperties;
    bottomMargin?: boolean;
}): JSX.Element {
    const divStyle = { backgroundColor: "white", ...props.style };

    return (
        <>
            <section
                className={`content-block block ${props.bottomMargin === false ? "" : "mb-5"} ${props.className}`}
                style={divStyle}
            >
                <div className="row">
                    <div className="col-md-12">{props.children}</div>
                </div>
            </section>
        </>
    );
}

export default ContentBlock;
