import stc from "string-to-color";

/**
 * Generate color from string in a consistent way
 * @param str
 * @param s
 * @param l
 */
const stringToColor = (str: string): string | undefined => {
    if (str != null) {
        return stc(str);
    }
};

export default stringToColor;
