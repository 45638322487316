import { Container, Stack } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import * as yup from "yup";
import { UrlFactory, yupExtensions } from "@/common";
import { Form, FormInput, ModalConfig, ModalProps } from "@/components";

const validator = yup.object({
    client: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
    contact: yupExtensions.nullableNonEmptyString(),
});

type Schema = yup.InferType<typeof validator>;

const initialValues = {
    client: "",
    contact: "",
};

const secretToken = "f8529643-6c95-4bca-9de3-6f59dafc8826";

type InviteToOverviewInviteData = {
    secretToken: string;
    client: string;
    contact?: string;
};

const createInviteLink = (clientName: string, contact?: string): string => {
    const data: InviteToOverviewInviteData = {
        secretToken: secretToken,
        client: clientName,
        contact: contact,
    };

    const encoded = btoa(JSON.stringify(data));
    const url = UrlFactory.allProgramsInvite.create({ data: encoded });
    return window.location.origin + url;
};

export const deserializeInviteLink = (data: string) => {
    const decoded = atob(data);
    try {
        return JSON.parse(decoded) as InviteToOverviewInviteData;
    } catch {
        return undefined;
    }
};

export const isInviteLinkValid = (data?: InviteToOverviewInviteData): data is InviteToOverviewInviteData => {
    if (data === undefined) {
        return false;
    }

    return data.secretToken === secretToken;
};

const InviteClientToProgramOverview = (props: ModalProps<object>) => {
    const clipboard = useClipboard();
    const submit = (values: Schema) => {
        clipboard.copy(createInviteLink(values.client, values.contact ?? undefined));
        props.onClose();
    };

    return (
        <Container>
            <Form<Schema>
                initialValues={initialValues}
                validationSchema={validator}
                onSubmit={submit}
                submitLabel="SYSADMIN_COPY_INVITE_LINK"
            >
                <Stack>
                    <FormInput.Text fieldName="client" label="SYSADMIN_CLIENT" />
                    <FormInput.Text
                        fieldName="contact"
                        label="SYSADMIN_CONTACT"
                        description="SYSADMIN_CONTACT_FIELD_DESCRIPTION"
                    />
                </Stack>
            </Form>
        </Container>
    );
};

export const InviteToProgramOverviewModal: ModalConfig<{}> = {
    content: InviteClientToProgramOverview,
    title: "SYSADMIN_INVITE_CLIENT_TO_PROGRAM_OVERVIEW",
};
