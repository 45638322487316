import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useBoardContext } from "../../BoardContext";
import { ColorSelect } from "../../../../components/ColorSelect";
import { getColorsUnusedFirst, light } from "../../../../common/colors";
import { TaskCategory } from "@/api-client";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { SecondaryButton } from "../../../../components/Button/SecondaryButton";

interface TaskCategoryEditorProps {
    mode: "create" | "edit";
    category: TaskCategory;
    requestClose: () => void;
}

const colors = light.map(x => x.hex);

export const createTaskCategory = (
    name: string | undefined,
    groupId: number | undefined,
    clientId: number | undefined,
    existingCategories: TaskCategory[]
): TaskCategory => {
    const sortIndex = existingCategories.length + 1;
    const takenColors = existingCategories.map(x => x.color).filter(x => x !== undefined);
    /* @ts-ignore */
    const color = getColorsUnusedFirst(colors, takenColors, 1)[0];

    return {
        name: name,
        /* @ts-ignore */
        groupId: groupId,
        /* @ts-ignore */
        clientId: clientId,
        createdAt: new Date(),
        updatedAt: new Date(),
        sortIndex: sortIndex,
        color: color,
    };
};

export const TaskCategoryEditor = (props: TaskCategoryEditorProps) => {
    const { t } = useTranslate();
    const boardContext = useBoardContext();
    const [category, setCategory] = useState<TaskCategory>(props.category);

    const saveCategory = async () => {
        await boardContext.addOrUpdateCategory(category);
    };

    const isValid = useMemo(() => {
        return category.name !== undefined && category.name.trim().length > 0;
    }, [category.name]);

    const handleColorChange = (color: string) => {
        setCategory(c => ({ ...c, color: color }));
    };

    // Reset category to the create new mode (usually from edit mode)
    return (
        <>
            <form
                className="form-inline"
                onSubmit={async e => {
                    e.preventDefault();
                    await saveCategory();
                    props.requestClose();
                }}
            >
                <Row>
                    <Col>
                        <Form.Group className="form-group">
                            <Form.Label>{t("BOARDS_CATEGORY_NAME")}</Form.Label>
                            <Form.Control
                                placeholder="Name"
                                isValid={isValid}
                                autoFocus
                                onChange={e => {
                                    e.persist();
                                    setCategory(c => ({ ...c, name: e.target.value }));
                                }}
                                value={category.name}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Form.Group className="form-group">
                            <Form.Label>{t("BOARDS_CATEGORY_DESCRIPTION")}</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder={t("BOARDS_CATEGORY_DESCRIPTION")}
                                onChange={e => {
                                    e.persist();
                                    setCategory(c => ({ ...c, description: e.target.value }));
                                }}
                                value={category.description}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>
                        <Form.Group className="form-group">
                            <Form.Label>{t("BOARDS_CATEGORY_COLOR")}</Form.Label>
                            {/* @ts-ignore */}
                            <ColorSelect value={category.color} onChange={handleColorChange} palette={colors} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="col text-start">
                        <SecondaryButton
                            onClick={() => props.requestClose()}
                            eventName={{ object: "improvementboard", action: "cancelcategory" }}
                        >
                            {t("COMMON_CANCEL")}
                        </SecondaryButton>
                    </Col>
                    <Col className="col text-end">
                        <PrimaryButton
                            type="submit"
                            disabled={!isValid}
                            eventName={{ object: "improvementboard", action: "savecategory" }}
                        >
                            {props.mode === "edit" ? t("COMMON_SAVE") : t("COMMON_ADD")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </form>
        </>
    );
};
