import { Client, Course, Diploma, User } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createApi } from "../../../common/api";
import ContentBlock from "../../../components/ContentBlock";
import { ContentLabel } from "../../../components/ContentLabel";
import Constants from "../../../constants";
import { CongratsImg, LinkedInImg, SuccessImg } from "../../../assets/index";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

type CongratsProps = {
    course: Course;
    client: Client;
    user: User;
};

export const Congrats = (props: CongratsProps): JSX.Element => {
    const api = createApi();
    // @ts-ignore
    const [diplomaDetails, setDiplomaDetails] = useState<Diploma>(null);
    // @ts-ignore

    const [summary, setSummary] = useState<string>(null!);
    useEffect(() => {
        if (props.user && props.course && props.client) {
            handleDiplomas();
            setSummary(
                `Congratulations! you have successfully completed the course "${props.course?.name}" under client "${props.client?.name}".`
            );
        }
    }, [props.user, props.course, props.client]);
    // @ts-ignore

    const getDiploma = userId => {
        return api.diplomas.query(userId);
    };

    const handleDiplomas = () => {
        if (props.course && props.user) {
            getDiploma(props.user.id).then(res => {
                const isCertificateAlreadyExist = res.find(r => r.courseId === props.course.id);
                // @ts-ignore

                setDiplomaDetails(isCertificateAlreadyExist);
                if (!isCertificateAlreadyExist) {
                    const data = {
                        fullName: props.user.fullName,
                        courseName: props.course.name,
                        dateOfCompletion: new Date(),
                        userId: props.user.id,
                        courseId: props.course.id,
                        createdDate: new Date(),
                        updatedDate: new Date(),
                    };
                    // @ts-ignore

                    api.diplomas.post(data).then(res => {
                        setDiplomaDetails(res);
                    });
                }
            });
        }
    };

    const shareCertificate = () => {
        const completionDate = new Date(diplomaDetails?.dateOfCompletion);
        const diplomaUrl = `${Constants.APP_URL}/diploma/${diplomaDetails?.token}`;

        const shareUrl = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(
            // @ts-ignore
            diplomaDetails?.courseName
            // @ts-ignore
        )}&name=${encodeURIComponent(diplomaDetails?.courseName)}&organizationName=World Of Excellence&issueYear
        =${completionDate?.getFullYear()}&issueMonth=${completionDate?.getMonth()}&certUrl=${encodeURI(
            diplomaUrl
        )}&certId=53250489`;
        window.open(shareUrl, "_blank");
    };

    const congrats: any = CongratsImg;
    const success: any = SuccessImg;
    const linkedIn: any = LinkedInImg;

    return (
        <React.Fragment>
            <ContentBlock className="congrats-name">
                <ContentLabel text={"Congratulation"} />
                <Row>
                    <Col className="text-center" md={12}>
                        <img src={congrats} className="img-responsive" alt="Hups" />
                    </Col>
                    <Col md={12} className="mt-3 text-center">
                        {summary}
                    </Col>
                    <hr></hr>
                    <Col md={12} className="mt-5 certificate-information">
                        <div>
                            <img src={success} className="img-responsive text-start" alt="Hups" />
                        </div>
                        <div className="mt-4">
                            <p>View your certificate</p>
                            <Link className="btn btn-primary" to={`/diploma/${diplomaDetails?.token}`}>
                                View Cerficate
                            </Link>
                        </div>
                    </Col>
                    <Col md={12} className="mt-3 certificate-information">
                        <div>
                            <img src={linkedIn} className="img-responsive text-start" alt="Hups" />
                        </div>
                        <div className="mt-4">
                            <p>Add certificate to your LinkedIn page</p>
                            <PrimaryButton
                                eventName={{ object: "presentation", action: "sharecertificate" }}
                                onClick={() => shareCertificate()}
                            >
                                Add cetificate to LinkedIn
                            </PrimaryButton>
                        </div>
                    </Col>
                </Row>
            </ContentBlock>
        </React.Fragment>
    );
};
