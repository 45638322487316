import { ReactNode } from "react";
import { LinkToOldAccountOwnerBanner } from "../../../pages/AccountOwner/LinkToOldAccountOwnerBanner";
import { AccountOwnerLayoutPage } from "../../../pages/AccountOwner/AccountOwnerLayoutPage";
import { Layout } from "../Layout";
import { OrgSettingsNavbar } from "./OrgSettingsNavbar";
import { OrgSettingsHeader } from "./OrgSettingsHeader";

export const AccountOwnerLayout = (props: { children: ReactNode }) => {
    return (
        <Layout navbar={<OrgSettingsNavbar />} banner={<LinkToOldAccountOwnerBanner />} header={<OrgSettingsHeader />}>
            <AccountOwnerLayoutPage>{props.children}</AccountOwnerLayoutPage>
        </Layout>
    );
};
