﻿import { useEffect, useState } from "react";
import { Container, Divider, Grid, Stack, Title } from "@mantine/core";
import { Form, Formik } from "formik";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { useHistory } from "@/common";
import { CardBlock, FormInput, PrimaryButton } from "@/components";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { createApi } from "../../../common/api";
import { GetAreasDto, LanguageCode } from "@/api-client";
import { showToast } from "../../../components/Notify";
import { UrlFactory } from "../../../routing/UrlFactory";

type FormObject = {
    name: string;
    area: string;
    languageCode: string;
    subarea: string;
    title: string;
    description: string;
};

type Language = { displayName: string; languageCode: LanguageCode };

const languages: Language[] = [
    { displayName: "LOGIN_SELECT_ENGLISH", languageCode: "en" },
    { displayName: "LOGIN_SELECT_SWEDISH", languageCode: "sv" },
    { displayName: "LOGIN_SELECT_NORWEGIAN", languageCode: "no" },
    { displayName: "COMMON_LANGUAGE_GERMAN", languageCode: "de" },
];

const capabilitySchema = Yup.object({
    name: Yup.string().required(),
    area: Yup.string().required(),
    languageCode: Yup.string().required(),
    subarea: Yup.string().required(),
    title: Yup.string().required(),
    description: Yup.string(),
});

export const CapabilityEditorPage = () => {
    const { t } = useTranslate();
    const [areas, setAreas] = useState<GetAreasDto[]>([]);
    const history = useHistory();

    useEffect(() => {
        const abortController = new AbortController();
        createApi()
            .sysadmin.getAreas(abortController)
            .then(areas => setAreas(areas))
            .catchWithToast();

        return () => abortController.abort();
    }, []);

    const languageOptions = languages.map(x => ({ label: t(x.displayName), value: x.languageCode }));

    const initialValues: FormObject = {
        title: "",
        description: "",
        area: areas[0]?.id,
        subarea: areas[0]?.subareas[0]?.id,
        languageCode: languageOptions[0].value,
        name: "",
    };

    const handleSubmit = async (values: FormObject): Promise<void> => {
        try {
            const api = createApi();
            const capabilityId = await api.sysadmin.createCapability(values.name, values.subarea, values.area);

            await api.sysadmin.addCapabilityLanguage(
                capabilityId,
                languages.find(x => x.languageCode === values.languageCode)!.languageCode,
                values.title,
                values.description
            );

            showToast(t("SYSADMIN_CAPABILITY_CAPABILITY_SUCCESSFULLY_CREATED"), "success");

            history.push(UrlFactory.sysadmin.programs.pattern);
        } catch (_) {
            showToast(t("COMMON_DEFAULT_ERROR"), "error");
        }
    };

    return (
        <Container>
            <CardBlock>
                <Title size={20}>{t("SYSADMIN_CAPABILITY_CREATE_CAPABILITY")} </Title>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={capabilitySchema}
                    onSubmit={async values => {
                        return handleSubmit(values);
                    }}
                >
                    {formikProps => {
                        const { isSubmitting } = formikProps;

                        return (
                            <Form>
                                <Grid>
                                    <Grid.Col span={6}>
                                        <Stack mb="md">
                                            <Divider mt="sm" label={t("SYSADMIN_CAPABILITY_METADATA")} />
                                            <FormInput.Text fieldName="name" label="SYSADMIN_CAPABILITY_NAME" />

                                            <FormInput.NativeSelect
                                                fieldName="area"
                                                label="SYSADMIN_CAPABILITY_AREA"
                                                options={areas.map(x => ({ label: x.name, value: x.id }))}
                                            />
                                            <FormInput.DependentNativeSelect
                                                fieldName="subarea"
                                                label="SYSADMIN_CAPABILITY_SUBAREA"
                                                dependentOn="area"
                                                createOptions={selectedArea =>
                                                    areas
                                                        .find(x => x.id === selectedArea)
                                                        ?.subareas.map(x => ({ label: x.name, value: x.id })) ?? []
                                                }
                                            />
                                            <Divider mt="sm" label={t("SYSADMIN_CAPABILITY_CAPABILITY")} />
                                            <FormInput.NativeSelect
                                                fieldName="languageCode"
                                                label="SYSADMIN_CAPABILITY_LANGUAGE"
                                                options={languageOptions}
                                            />
                                            <FormInput.Text fieldName="title" label="SYSADMIN_CAPABILITY_TITLE" />
                                            <FormInput.Textarea
                                                fieldName="description"
                                                label="SYSADMIN_CAPABILITY_DESCRIPTION"
                                            />
                                        </Stack>
                                        <ContentBlockButtonContainer>
                                            <PrimaryButton type="submit" loading={isSubmitting}>
                                                {t("COMMON_SAVE")}
                                            </PrimaryButton>
                                        </ContentBlockButtonContainer>
                                    </Grid.Col>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </CardBlock>
        </Container>
    );
};
