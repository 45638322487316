import { Course } from "@/api-client";

export type CoursesNav = {
    id: number;
    name: string;
    link: string;
    status?: string;
    parentId?: number;
    children?: CoursesNav[];
};

//@ts-ignore
export const CoursesMenu = (courses: Course[]): CoursesNav[] => {
    if (courses && courses.length > 0) {
        const nav = courses.map(c => {
            return {
                id: c.id,
                name: c.name,
                link: `/sysadmin/courses/${c.id}/edit`,
            };
        });

        //@ts-ignore
        return nav;
    }
};

//@ts-ignore
export const CourseMenu = (course: Course): CoursesNav[] => {
    if (course) {
        //@ts-ignore
        return (
            course.chapters
                //@ts-ignore
                .sort((a, b) => a.order - b.order)
                .map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        link: `/sysadmin/courses/${course.id}/chapter/${c.id}/edit`,
                        status: c.status,
                        parentId: c.id,
                        //@ts-ignore
                        children: c.modules
                            //@ts-ignore
                            .sort((a, b) => a.order - b.order)
                            .map(m => {
                                return {
                                    id: m.id,
                                    name: m.name,
                                    link: `/sysadmin/courses/${course.id}/chapter/${c.id}/module/${m.id}/edit`,
                                    parentId: c.id,
                                    status: m.status,
                                    children: m.blocks
                                        .sort((a, b) => a.sortIndex - b.sortIndex)
                                        .map(b => {
                                            return {
                                                id: b.id,
                                                name: b.navigationLabel ? b.navigationLabel : b.title,
                                                link: `/sysadmin/courses/${course.id}/chapter/${c.id}/module/${m.id}/block/${b.id}/edit`,
                                                status: b.status,
                                                parentId: m.id,
                                            };
                                        }),
                                };
                            }),
                    };
                })
        );
    }
};
