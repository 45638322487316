import { UserRoles } from "@/api-client";

export const ACCOUNT_OWNER_ROLE = "AccountOwner";
export const SYSADMIN_ROLE = "Admin";
export const AGENT_ROLE = "Agent";
export const EXPERT_ROLE = "Expert";

export const ALL_ROLES: UserRoles[] = [ACCOUNT_OWNER_ROLE, SYSADMIN_ROLE, AGENT_ROLE, EXPERT_ROLE];

export const APP_DOMAIN = "hups.com";

export const SUPPORT_EMAIL = "support@hups.com";

declare global {
    interface Window {
        APP_INSIGHTS_KEY: string | undefined;
        NEW_API_URL: string | undefined;
    }
}

/**
 * NOTE: Using a salt for all passwords adds no security at all, this is an inherited issue from Ministry of Programming. Salt should be managed server-side and unique to each salted value.
 */

const AppInsightInstrumentationKeys = {
    dev: "1c20305e-5262-4f4b-b9af-721dfe938c99",
    stage: "cb7ef3f3-0429-4015-bbbf-79ee1f48733b",
    prod: "ed033c0c-b1f9-4966-b531-4a340037dfd0",
} as const;

const passwordSalt = "$2a$02$0.o/O/p/E/x/S/A/L/T/";

const local = {
    ENVIRONMENT: "local",
    APP_URL: "https://localhost:4444",
    NEW_API_URL: window.NEW_API_URL ?? "https://localhost:5001",
    MAGIC_SALT: passwordSalt,
    APP_INSIGHTS_KEY: window.APP_INSIGHTS_KEY ?? AppInsightInstrumentationKeys.dev,
};

const development = {
    ENVIRONMENT: "development",
    APP_URL: `https://app-dev.${APP_DOMAIN}`,
    NEW_API_URL: window.NEW_API_URL ?? `https://api-dev.${APP_DOMAIN}`,
    MAGIC_SALT: passwordSalt,
    APP_INSIGHTS_KEY: window.APP_INSIGHTS_KEY ?? AppInsightInstrumentationKeys.dev,
};

const stage = {
    ENVIRONMENT: "stage",
    APP_URL: `https://app-stage.${APP_DOMAIN}`,
    NEW_API_URL: window.NEW_API_URL ?? `https://api-stage.${APP_DOMAIN}`,
    MAGIC_SALT: passwordSalt,
    APP_INSIGHTS_KEY: window.APP_INSIGHTS_KEY ?? AppInsightInstrumentationKeys.stage,
};

const production = {
    ENVIRONMENT: "production",
    APP_URL: `https://app.${APP_DOMAIN}`,
    NEW_API_URL: window.NEW_API_URL ?? `https://api.${APP_DOMAIN}`,
    MAGIC_SALT: passwordSalt,
    APP_INSIGHTS_KEY: window.APP_INSIGHTS_KEY ?? AppInsightInstrumentationKeys.prod,
};

//The value of MODE will be set at build time, and will always return 'production' for all deployments.
const getConstants = () => {
    switch (import.meta.env.MODE) {
        case "localdev":
            return local;
        case "production":
            return production;
        case "development":
            return development;
        case "stage":
            return stage;
        default:
            return production;
    }
};

export default getConstants();
