import { IntroductionVideo } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createApi } from "../../../common/api";
import { ContentLabel } from "../../../components/ContentLabel";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "../../../components/Spinner";

/*  eslint-disable react-hooks/exhaustive-deps */

type IntroductionVideoProps = {
    courseId: number;
};
const IntroductionVideos = (props: IntroductionVideoProps) => {
    const [introductionVideos, setIntroductionVideo] = useState<IntroductionVideo[]>([]);
    const [isPending, setIsPending] = useState<boolean>(true);
    const api = createApi();

    useEffect(() => {
        api.introductionVideos.query(props.courseId).then(
            result => {
                // @ts-ignore
                setIntroductionVideo(result && result.length > 0 && result.filter(r => r.courseId === props.courseId));
                setIsPending(false);
            },
            error => {
                console.log(error);
                setIsPending(false);
            }
        );
    }, []);

    return (
        <>
            <AppLoader className="position-absolute" loading={isPending} />
            <ContentLabel text="Introduction Videos" />
            <table className="table table-borderless table-striped sysAdminTable">
                <thead>
                    <tr>
                        <th>Video Id</th>
                        <th>Order</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <RenderIf show={introductionVideos && introductionVideos.length > 0 ? true : false}>
                        {introductionVideos &&
                            introductionVideos.map((introductionVideo, i) => (
                                <tr key={i}>
                                    <td>{introductionVideo.videoId}</td>
                                    <td>{introductionVideo.order}</td>
                                    <td className="text-end">
                                        <Link
                                            to={`/sysadmin/courses/${props.courseId}/introduction-videos/edit/${introductionVideo.courseVideoId}`}
                                            className="btn btn-primary btn-block"
                                        >
                                            Edit
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </RenderIf>
                    <RenderIf show={isPending === false && introductionVideos && introductionVideos.length === 0}>
                        <tr className="text-center">
                            <td colSpan={3} style={{ fontWeight: "bold" }}>
                                No Introduction Video Found
                            </td>
                        </tr>
                    </RenderIf>
                </tbody>
            </table>
            {/* <ContentBlockButton to={`/sysadmin/courses/${props.courseId}/introduction-videos/add`} >Add Introduction Video</ContentBlockButton> */}
        </>
    );
};

export default IntroductionVideos;
