﻿import { Container, Text } from "@mantine/core";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { CardBlock } from "@/components";

export const NoProgramsAvailable = () => {
    const { t } = useTranslate();

    return (
        <Container px="xl">
            <CardBlock>
                <Text fz="lg"> {t("PROGRAM_NO_PROGRAMS_AVAILABLE")} </Text>
            </CardBlock>
        </Container>
    );
};
