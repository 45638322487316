import { Course, Group, TransformationTodoItem, User } from "@/api-client";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { createApi } from "../../common/api";
import { useAllGroups } from "../../common/OrganisationContext";
import ContentBlock from "../../components/ContentBlock";
import { evenOddClassName, getColorScaleFromPercentage } from "../../helpers/helpers";
import { formatProgressValue, getTransformationMatrix } from "../helper";
import "../style.scss";
import TransformationTodoDetail from "./TransformationTodoDetail";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

/*  eslint-disable react-hooks/exhaustive-deps */

interface TransformationTodoOverviewProps {
    group: Group;
    course: Course;
    clientId: number;
    user: User;
}

const getItemKey = (columnIndex: number, rowIndex: number) => `${rowIndex}-${columnIndex}`;

function TransformationTodoOverview(props: TransformationTodoOverviewProps): JSX.Element {
    const { t } = useTranslate();
    const api = createApi();
    const [selected, setSelected] = useState<{ columnIndex: number; rowIndex: number }>();
    const allGroups = useAllGroups();
    const [todoItems, setTodoItems] = useState<TransformationTodoItem[]>();

    // Users need to be able to refresh, should probably be connected to live update system in the future
    useEffect(() => {
        if (!props.group?.id) {
            return;
        }

        api.transformationTodoItems.query(null).then(items => {
            setTodoItems(items);
        });
    }, [props?.group?.id]);

    const matrix = useMemo(() => {
        if (!(props.course && todoItems && props.group && allGroups)) {
            return;
        }

        return getTransformationMatrix(props.course, todoItems, props.group, allGroups);
    }, [props.course, todoItems, props.group, allGroups]);

    useEffect(() => {
        if (matrix === undefined || selected !== undefined) {
            return;
        }

        const notCompletedImplementations = matrix.data.filter(x => x.groupProgress !== 1);

        if (notCompletedImplementations.length > 0) {
            const first = notCompletedImplementations.at(0);
            // @ts-ignore
            setSelected({ columnIndex: first.columnIndex, rowIndex: first.rowIndex });
        } else {
            const last = matrix.data.at(matrix.data.length - 1);
            // @ts-ignore
            setSelected({ columnIndex: last.columnIndex, rowIndex: last.rowIndex });
        }
    }, [matrix, selected]);

    const updateLocalTodoItem = useCallback(
        (newItem: TransformationTodoItem) => {
            setTodoItems(s => [
                // @ts-ignore
                ...s.filter(
                    x => !(x.groupId === newItem.groupId && x.transformationTodoId === newItem.transformationTodoId)
                ),
                newItem,
            ]);
        },
        [todoItems]
    );

    const selectedData = useMemo(() => {
        if (!selected) {
            return null;
        }
        // @ts-ignore
        return matrix.data.find(x => x.columnIndex === selected.columnIndex && x.rowIndex === selected.rowIndex);
    }, [matrix?.data, selected]);

    const changeTodoStatus = useCallback(
        (transformationTodoId: number, completed: boolean) => {
            const newItem: TransformationTodoItem = {
                transformationTodoId,
                // @ts-ignore
                groupId: props.group.id,
                // @ts-ignore
                clientId: props.group.clientId,
                // @ts-ignore
                userId: props.user.id,
                completed: completed,
            };

            // Optimistic update
            updateLocalTodoItem(newItem);

            // Call api
            api.transformationTodoItems.put(newItem.transformationTodoId, newItem.groupId, newItem).then(res => {
                updateLocalTodoItem(res);
            });
            trackPosthogEvent("ui", "implementation", "changetodostatus", { completed: completed });
        },
        [props.group, props.user]
    );

    return (
        <>
            <ContentBlock className="transformation-todo-overview">
                <h1>{t("TRANSFORMATIONTODO_HEADING")}</h1>
                <ReactMarkdown>{t("TRANSFORMATIONTODO_SUBHEADING")}</ReactMarkdown>
                <div className="table-responsive-lg">
                    <table className="table table-borderless text-center">
                        <thead>
                            <tr>
                                <th className="text-left" />
                                {matrix?.columns &&
                                    matrix?.columns.map((column, ix) => (
                                        <th className={evenOddClassName(ix)} key={`${ix}-${column}`} colSpan={2}>
                                            {column}
                                        </th>
                                    ))}
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th className="text-left" />
                                {matrix?.columns &&
                                    matrix?.columns.map((x, ix) => (
                                        <Fragment key={ix}>
                                            <th className={evenOddClassName(ix)} key={`${ix}-group`}>
                                                {t("TRANSFORMATION_GROUP")}
                                            </th>
                                            <th className={evenOddClassName(ix)} key={`${ix}-all`}>
                                                {t("TRANSFORMATION_ALL")}
                                            </th>
                                        </Fragment>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {matrix?.rows &&
                                matrix?.rows.map((row, rowIndex) => (
                                    <tr key={rowIndex} className="text-left">
                                        <th>{t("TRANSFORMATIONTODO_LEVEL", { level: row })}</th>
                                        {matrix.columns.map((column, columnIndex) => {
                                            const data = matrix.data.find(
                                                x => x.rowIndex === rowIndex && x.columnIndex === columnIndex
                                            );
                                            const itemKey = getItemKey(columnIndex, rowIndex);
                                            const isLocked = data === undefined || data.todosWithStatus.length === 0;

                                            const classes = [
                                                "group-data",
                                                evenOddClassName(columnIndex),
                                                selected !== undefined &&
                                                getItemKey(selected.columnIndex, selected.rowIndex) === itemKey
                                                    ? "active"
                                                    : "",
                                                // @ts-ignore
                                                data?.groupProgress > 0 ? "has-value" : "",
                                            ];

                                            return (
                                                <Fragment key={itemKey}>
                                                    {isLocked && (
                                                        <td className={`${classes.join(" ")}`} colSpan={2}>
                                                            <small>
                                                                <i className="fas fa-lock" />
                                                            </small>
                                                        </td>
                                                    )}
                                                    {!isLocked && (
                                                        <>
                                                            <td
                                                                className={`${classes.join(" ")}`}
                                                                style={{
                                                                    backgroundColor:
                                                                        data.groupProgress > 0
                                                                            ? getColorScaleFromPercentage(
                                                                                  data.groupProgress * 100
                                                                              )
                                                                            : "",
                                                                }}
                                                                onClick={() => {
                                                                    trackPosthogEvent(
                                                                        "ui",
                                                                        "implementation",
                                                                        "showdetails"
                                                                    );
                                                                    return setSelected({ rowIndex, columnIndex });
                                                                }}
                                                            >
                                                                {formatProgressValue(data.groupProgress)}
                                                            </td>
                                                            <td
                                                                className={evenOddClassName(columnIndex)}
                                                                style={{
                                                                    backgroundColor:
                                                                        data.allProgress > 0
                                                                            ? getColorScaleFromPercentage(
                                                                                  data.allProgress * 100
                                                                              )
                                                                            : "",
                                                                }}
                                                            >
                                                                {formatProgressValue(data.allProgress)}
                                                            </td>
                                                        </>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </ContentBlock>

            {selectedData && (
                <ContentBlock>
                    <TransformationTodoDetail
                        // @ts-ignore
                        title={`${matrix.columns[selected.columnIndex]} - ${matrix.rows[selected.rowIndex]}`}
                        data={selectedData}
                        // @ts-ignore
                        editable={props.group.members.find(m => m.userId === props.user.id) !== undefined}
                        onChangeTodo={changeTodoStatus}
                    />
                </ContentBlock>
            )}
        </>
    );
}

export default TransformationTodoOverview;
