import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { PrimaryButton, SecondaryButton } from "@/components";

interface CourseDisplayProps {
    isCurrentlySelected: boolean;
    name: string;
    description: string;
    hasProgress: boolean;
    selectCourseCallback: () => void;
}

/**
 * Display a course item in the list
 * @param props
 */
export const CourseDisplay = (props: CourseDisplayProps): JSX.Element => {
    const { t } = useTranslate();
    const [readMore, setReadMore] = useState<boolean>(false);

    const handleReadMore = (): void => {
        setReadMore(!readMore);
    };

    const charLimit = 95;
    let textToDisplay = props.description;
    const textLength = textToDisplay.length;
    const showReadMoreButton = textLength > charLimit;

    if (!readMore) {
        textToDisplay = `${textToDisplay.substring(0, charLimit)}...`;
    }

    return (
        <div key="First Course" className="course-display mb-3 course-area markdown-editor">
            <div style={{ display: "flex", flexDirection: "row" }}>
                <h4>{props.name} </h4>
                {!props.hasProgress && <div className="new-text">{t("MY_COURSES_NEW")}</div>}
            </div>
            <div className="row mb-2">
                <div className="col col-md-9 course-description">
                    <ReactMarkdown className="markdown-editor">{textToDisplay}</ReactMarkdown>

                    {showReadMoreButton && (
                        <button className="btn btn-link p-0" type="button" onClick={() => handleReadMore()}>
                            <i className={!readMore ? "fas fa-sort-down" : "fas fa-sort-up"}></i>
                            {!readMore ? t("COMMON_READ_MORE") : t("COMMON_READ_LESS")}
                        </button>
                    )}
                </div>

                <div className="col-md-3">
                    {props.isCurrentlySelected ? (
                        <PrimaryButton
                            fullWidth
                            onClick={() => props.selectCourseCallback()}
                            eventName={{ object: "programselector", action: "continueprogram" }}
                        >
                            {t("COMMON_CONTINUE")}
                        </PrimaryButton>
                    ) : (
                        <SecondaryButton
                            fullWidth
                            onClick={() => props.selectCourseCallback()}
                            eventName={{ object: "programselector", action: "selectprogram" }}
                        >
                            {t("SELECT_COURSE_SELECT")}
                        </SecondaryButton>
                    )}
                </div>
            </div>
        </div>
    );
};
