import React, { useCallback } from "react";
import { Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useAllUsers } from "../../common/OrganisationContext";
import StyledCheckbox from "../../components/StyledCheckbox";
import UserAvatar from "../../components/UserAvatar";
import "../style.scss";
import { MatrixData } from "../types";

interface TransformationTodoDetailProps {
    title: string;
    data: MatrixData;
    editable: boolean;
    onChangeTodo: (todoId: number, completed: boolean) => void;
}

function TransformationTodoDetail(props: TransformationTodoDetailProps) {
    const { t } = useTranslate();
    const allUsers = useAllUsers();
    // @ts-ignore
    const getUserById = useCallback((userId: number) => allUsers.find(x => x.id === userId), [allUsers]);

    return (
        <div>
            <h1 className="mt-2">{props.title}</h1>
            <Row className="tranformation-todo-detail">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t("TRANSFORMATIONTODO_STATEMENT")}</th>
                            <th className="col-sm-2">{t("TRANSFORMATIONTODO_STATUS")}</th>
                            <th className="whitespace-nowrap text-center col-sm-3">
                                {t("TRANSFORMATIONTODO_SIGNEDBY")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data?.todosWithStatus &&
                            props.data.todosWithStatus.map((todoItem, index) => (
                                <tr key={todoItem.todo.id}>
                                    <td className="todo-title">{todoItem.todo.text}</td>
                                    <td>
                                        <StyledCheckbox
                                            disabled={!props.editable}
                                            // @ts-ignore
                                            onChange={val => props.onChangeTodo(todoItem.todo.id, val)}
                                            checked={todoItem.status && todoItem.status.completed === true}
                                        />
                                    </td>
                                    <td>
                                        {todoItem.status && todoItem.status.completed === true && (
                                            <UserAvatar
                                                // @ts-ignore
                                                user={getUserById(todoItem?.status?.userId)}
                                                style={{ justifyContent: "center" }}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Row>
        </div>
    );
}

export default React.memo(TransformationTodoDetail);
