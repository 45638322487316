﻿import { useEffect, useState } from "react";
import { SimpleGrid, Flex, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useHistory, createApi, Icons, UrlFactory } from "@/common";
import { ChapterData } from "./helper";
import { Program } from "@/api-client";
import { CardBlock, ProgramCard } from "@/components";
import { customEvents } from "../analytics/customEvents/customEvents";
import { ProgramCardContainer } from "../components/ProgramCard/ProgramCardContainer";

type ProgramRecommendationsProps = { chapterData: ChapterData[] };
export const ProgramRecommendations = (props: ProgramRecommendationsProps) => {
    const [suggestedPrograms, setSuggestedPrograms] = useState<Program[]>([]);
    const { t } = useTranslate();
    const history = useHistory();

    useEffect(() => {
        const abortController = new AbortController();

        const op = async () => {
            const api = createApi();

            const config = await api.configuration.getConfiguration(abortController);

            const groupCellValues = props.chapterData
                .flatMap((x, column) => x.group.map((value, row) => ({ column: column, row: row, value: value })))
                .filter(x => !Number.isNaN(x.value));

            const allSuggestions = await api.allPrograms.getAssessmentSuggestions(
                config.blobStorageBaseUrl,
                abortController
            );
            const suggestedProgramIds = groupCellValues
                .map(cellValue => ({
                    ...allSuggestions.find(ps => ps.row === cellValue.row && ps.column === cellValue.column)!,
                    ...cellValue,
                }))
                .filter(x => x.id !== null)
                .orderBy(x => x.value)
                .uniqueBy(x => x.id!)
                .map(x => x.id!)
                .take(3);

            const suggestedPrograms = await api.allPrograms
                .getAllPrograms(config.blobStorageBaseUrl, abortController)
                .then(programs => programs.filter(x => suggestedProgramIds.includes(x.id)));

            setSuggestedPrograms(suggestedPrograms);
        };

        op().catchWithToast();

        return () => abortController.abort();
    }, [props.chapterData]);

    if (suggestedPrograms.length === 0) {
        return null;
    }

    return (
        <CardBlock>
            <h3>{t("ASSESSMENT_PROGRAM_RECOMMENDATIONS")}</h3>
            <Text>{t("ASSESSMENT_BASED_ON_YOUR_TEAMS_RESULT_WE_RECOMMEND_YOU_TO_EXPLORE_THE_FOLLOWING_PROGRAMS")}</Text>
            <SimpleGrid cols={4} mt="md">
                {suggestedPrograms.map(x => (
                    <ProgramCard
                        key={x.id}
                        imageUrl={x.imageUrl}
                        title={x.title}
                        shortDescription={x.shortDescription}
                        experts={x.experts}
                        description={x.description}
                        videoUrl={x.videoUrl}
                        languages={x.languages}
                        numberOfModules={x.numberOfModules}
                        breadText={x.breadText}
                        onOpen={() => customEvents.raiseUiProgramOpened(x.id, x.title)}
                    />
                ))}
                <ProgramCardContainer onClick={() => history.push(UrlFactory.team.allPrograms.create({}))}>
                    <Flex justify="center" align="center" h="100%" gap="sm">
                        <Text>{t("HEADER_NAV_BROWSE_HUPS_PROGRAMS")}</Text>
                        <Icons.RightArrow />
                    </Flex>
                </ProgramCardContainer>
            </SimpleGrid>
        </CardBlock>
    );
};
