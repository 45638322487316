﻿import { useEffect, useMemo, useState } from "react";
import { Card, createStyles, SegmentedControl, SimpleGrid, Text, Flex, Badge, Box } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { CardBlock, RenderIf, ProgramCard, PrimaryButton, useModal } from "@/components";
import { createApi } from "@/common";
import { AreaFromJson } from "@/api-client";
import { InviteToProgramOverviewModal } from "./InviteToProgramOverviewModal";

const useStyles = createStyles(theme => ({
    subareaCard: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    subareaTitle: {
        fontWeight: 700,
    },

    draftBadge: {
        position: "absolute",
        marginTop: "10px",
        marginLeft: "10px",
    },
}));

export const ProgramsPage = () => {
    const [areas, setAreas] = useState<AreaFromJson[]>([]);
    const [selectedArea, setSelectedArea] = useState<AreaFromJson | undefined>(undefined);
    const { classes } = useStyles();
    const { t } = useTranslate();

    const modal = useModal(InviteToProgramOverviewModal);

    useEffect(() => {
        const abortController = new AbortController();

        const op = async () => {
            const api = createApi();

            const config = await api.configuration.getConfiguration(abortController);
            const capabilities = await api.sysadmin.getCapabilities(config.blobStorageBaseUrl, abortController);

            setAreas(capabilities);
            setSelectedArea(capabilities[0]);
        };

        op().catchWithToast();

        return () => {
            abortController.abort();
        };
    }, []);

    const segmentedControlData = useMemo(() => areas.map(area => ({ label: area.name, value: area.name })), [areas]);

    return (
        <CardBlock mb="xl">
            <Box mb="md">
                <PrimaryButton onClick={() => modal.open({})}>
                    {t("SYSADMIN_INVITE_CLIENT_TO_PROGRAM_OVERVIEW")}
                </PrimaryButton>
            </Box>
            <SegmentedControl
                size="md"
                value={selectedArea?.name}
                fullWidth
                onChange={value => setSelectedArea(areas.find(x => x.name === value))}
                data={segmentedControlData}
            />
            <RenderIf show={selectedArea !== undefined}>
                <div>
                    <RenderIf show={selectedArea?.description !== null}>
                        <Card key={selectedArea?.name} withBorder radius="md" className={classes.subareaCard} my="xl">
                            <div dangerouslySetInnerHTML={{ __html: selectedArea?.description! }} />
                        </Card>
                    </RenderIf>

                    {selectedArea?.subareas.map(subarea => (
                        <Card key={subarea.name} withBorder radius="md" className={classes.subareaCard} my="xl">
                            <Text className={classes.subareaTitle}>{subarea.name}</Text>
                            <SimpleGrid cols={4} mt="md">
                                {subarea.capabilities.map(cap => (
                                    <Flex key={cap.title}>
                                        <ProgramCard
                                            imageUrl={cap.imageUrl}
                                            title={cap.title}
                                            shortDescription={cap.shortDescription}
                                            experts={cap.experts}
                                            key={cap.title}
                                            description={cap.description}
                                            videoUrl={cap.videoUrl}
                                            languages={cap.languages}
                                            numberOfModules={cap.numberOfModules}
                                            breadText={cap.breadText}
                                        />
                                        {!cap.published && (
                                            <Badge className={classes.draftBadge} color="yellow">
                                                {t("SYSADMIN_DRAFT")}
                                            </Badge>
                                        )}
                                    </Flex>
                                ))}
                            </SimpleGrid>
                        </Card>
                    ))}
                </div>
            </RenderIf>
        </CardBlock>
    );
};
