import React from "react";
import { useParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { Chapter } from "@/api-client";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import RenderIf from "../../../components/render-if/render-if";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../helper";
import IntroductionVideos from "../IntroductionVideos";
import Chapters from "./Chapters";
import EditCourse from "./EditCourse";
import { CardBlock } from "../../../components/CardBlock";

type RouteParams = {
    courseId: string;
};

const Index = (): JSX.Element => {
    const params = useParams<RouteParams>();
    const [currentCourse] = useCurrentCourseFromParams();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    // @ts-ignore

    const chapters: Chapter[] = currentCourse && currentCourse.chapters;
    const nav = CourseMenu(currentCourse);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        {
            name:
                currentCourse?.adminName === null || currentCourse?.adminName === ""
                    ? currentCourse?.name
                    : currentCourse?.adminName,
            path: null,
        },
    ];
    return (
        <>
            {currentCourse && (
                <Breadcrumbs
                    // @ts-ignore

                    breadCrumb={links}
                />
            )}
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar children={nav} courseId={courseId} name={currentCourse?.name} />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <RenderIf show={!!(courseId && currentCourse)}>
                            <EditCourse courseId={courseId} course={currentCourse} />
                        </RenderIf>
                    </CardBlock>
                    <CardBlock mb="xl">
                        {/* Send chapters in props for Chaters componet */}
                        <Chapters courseId={courseId} chapters={chapters} />
                    </CardBlock>
                    <CardBlock mb="xl">
                        <IntroductionVideos courseId={courseId} />
                    </CardBlock>
                </div>
            </div>
        </>
    );
};

export default Index;
