import { useEffect, useMemo, useState } from "react";
import { Group, ParticipantProgress } from "@/api-client";
import { useCurrentUser, useHistory, createApi } from "@/common";
import { setCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";
import { useAllCourses, useAllGroups } from "../../common/OrganisationContext";
import { CourseSelectorList, CourseWithProgress, GroupInfo } from "./CourseSelectorList";
import { UrlFactory } from "../../routing/UrlFactory";
import { AppLoader } from "@/components";
import { useHandleTeamMembership } from "./useHandleTeamMembership";
import "./style.scss";

const CourseSelectorPage = (): JSX.Element => {
    const api = createApi();
    const allCourses = useAllCourses();
    const allGroups = useAllGroups();
    const currentUser = useCurrentUser();
    const history = useHistory();
    const [userProgress, setUserProgress] = useState<ParticipantProgress[] | null>(null);
    const { updateGroupMembershipStatus } = useHandleTeamMembership();

    useEffect(() => {
        api.participantProgress
            .query(currentUser.id, null, null, null, null)
            .then(response => setUserProgress(response))
            .catchWithToast();
    }, []);

    const userGroupCourses = useMemo((): { group: GroupInfo; courses: CourseWithProgress[] }[] | null => {
        if (allCourses === undefined || allGroups === undefined || userProgress === null) {
            return null;
        }

        const userGroupsWithInvitationSent = allGroups.filter(g =>
            g.members.find(m => m.userId === currentUser.id && m.inviteStatus !== "NotSent")
        );

        const getGroupCoursesWithProgress = (group: Group) =>
            allCourses
                .filter(course => group.courseIds.includes(course.id))
                .map(course => {
                    const hasProgress =
                        course.chapters.flatMap(m => m.modules).filter(m => userProgress.find(p => m.id === p.moduleId))
                            .length > 0;

                    return {
                        id: course.id,
                        name: course.name,
                        description: course.description!,
                        hasProgress: hasProgress,
                    };
                });

        return userGroupsWithInvitationSent.flatMap(group => {
            const coursesInGroup = getGroupCoursesWithProgress(group);

            return {
                group: {
                    id: group.id!,
                    name: group.name,
                },
                courses: coursesInGroup,
            };
        });
    }, [userProgress, allGroups, allCourses]);

    const selectCourse = async (groupId: number, courseId: number) => {
        const selectedGroup: Group = allGroups!.find(g => g.id === groupId)!;

        //TODO: Where shall this happen?? A user with only one group and course is now directed there without calling this:
        await updateGroupMembershipStatus(currentUser, selectedGroup);

        setCourseGroupInLocalStorage(courseId, selectedGroup.id!);

        history.push(UrlFactory.team.program.create({ groupId: selectedGroup.id! }));
    };

    if (userGroupCourses === null) {
        return <AppLoader loading />;
    }

    return <CourseSelectorList groupsCourses={userGroupCourses} selectCourse={selectCourse} />;
};

export default CourseSelectorPage;
