import { EditLink } from "./EditLink";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";
import { ArrowLink } from "./ArrowLink";
import { ContextMenu, ContextMenuButton, ContextMenuLink } from "./ContextMenu";

export const RowActions = {
    EditLink: EditLink,
    DeleteButton: DeleteButton,
    EditButton: EditButton,
    ArrowLink: ArrowLink,
    ContextMenu: {
        Menu: ContextMenu,
        Button: ContextMenuButton,
        Link: ContextMenuLink,
    },
};
