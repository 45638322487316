import { Link, useParams } from "react-router-dom";
import { Text, NavLink, Navbar as MNavbar } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Icons, UrlFactory, useCurrentUser } from "@/common";
import { useAllGroups, useGroupTypes } from "../../../common/OrganisationContext";
import { RenderIf } from "@/components";
import { Group, GroupType, User } from "@/api-client";
import { headerHeight } from "../headerHeight";
import { useNavbarStyles } from "../useNavbarStyles";
import { BottomSection } from "../BottomSection";
import { GroupRouteParams } from "./TeamRouteParams";
import { Navbar } from "../Navbar";
import { hasOrganizationSettingsPermission } from "../../../auth/permissions";

type TeamLinksProps = {
    selectedTeam: number;
    user: User;
    allTeams: Group[];
    teamGroups: GroupType[];
};

const TeamNavLink = ({ team, ...props }: { team: Group; selectedTeam: number }) => {
    const { classes } = useNavbarStyles();
    const active = team.id === props.selectedTeam;
    const path = UrlFactory.team.home.create({ groupId: team.id! });

    return (
        <NavLink
            key={team.id}
            active={active}
            label={team.name}
            component={Link}
            to={path}
            classNames={{
                root: classes.navLinkRoot,
            }}
        />
    );
};

const TeamsNavbarItems = (props: TeamLinksProps) => {
    const { classes } = useNavbarStyles();
    const { t } = useTranslate();

    const teamStructure = useMemo(() => {
        const myTeams = props.allTeams
            .filter(t => t.members.find(u => u.userId === props.user.id))
            .alphabeticSort(a => a.name);

        const allTeams = props.teamGroups
            .map(gt => ({
                teamId: gt.id,
                teamName: gt.name,
                groups: props.allTeams.filter(x => x.groupTypeId === gt.id).alphabeticSort(x => x.name),
            }))
            .filter(x => x.groups.length > 0)
            .alphabeticSort(x => x.teamName!);

        return {
            myTeams: myTeams,
            allTeams: allTeams,
        };
    }, [props.teamGroups, props.allTeams, props.user]);

    const teamLinks = teamStructure.myTeams.map(team => (
        <TeamNavLink selectedTeam={props.selectedTeam} key={`teamLinks_${team.id!}`} team={team} />
    ));

    const allTeams = teamStructure.allTeams.map(teamGroup => {
        const teamLinks = teamGroup.groups.map(team => (
            <TeamNavLink selectedTeam={props.selectedTeam} key={`allTeamsTeamLinks_${team.id!}`} team={team} />
        ));

        return (
            <NavLink
                key={teamGroup.teamId}
                label={teamGroup.teamName}
                classNames={{
                    root: classes.navLinkRoot,
                    label: classes.parentNavLinkLabel,
                    children: classes.navLinkChildren,
                }}
            >
                {teamLinks}
            </NavLink>
        );
    });

    return (
        <>
            <MNavbar.Section className={classes.section}>
                <Text className={classes.sectionHeader} size="md" color="white" weight={700}>
                    {t("NAVIGATION_MY_TEAMS")}
                </Text>
                {teamLinks}
            </MNavbar.Section>
            <MNavbar.Section className={classes.section}>
                <Text className={classes.sectionHeader} size="md" color="white" weight={700}>
                    {t("NAVIGATION_ALL_TEAMS")}
                </Text>
                {allTeams}
            </MNavbar.Section>
        </>
    );
};

const SettingsSection = () => {
    const currentUser = useCurrentUser();
    const showSettings = hasOrganizationSettingsPermission(currentUser);

    return (
        <RenderIf show={showSettings}>
            <BottomSection
                active={false}
                icon={<Icons.SettingsInNav />}
                label="NAVIGATION_SETTINGS"
                navigateTo={UrlFactory.accountOwner.newHome.create({})}
            />
        </RenderIf>
    );
};

export const TeamNavbar = () => {
    const teamGroups = useGroupTypes();
    const allTeamsFlat = useAllGroups();
    const user = useCurrentUser();
    const groupParams = useParams<GroupRouteParams>();

    const selectedTeamId = parseInt(groupParams.groupId ?? "");

    if (!allTeamsFlat || !teamGroups) {
        return null;
    }

    return (
        <Navbar headerHeight={headerHeight} bottomSection={<SettingsSection />}>
            <TeamsNavbarItems
                selectedTeam={selectedTeamId}
                user={user}
                allTeams={allTeamsFlat}
                teamGroups={teamGroups}
            />
        </Navbar>
    );
};
