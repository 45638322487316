import React from "react";
import { Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { Course } from "@/api-client";
import { ModuleBlockDescription } from "../../../training/ContentModuleBlocks/ModuleBlockDescription";
import { ModuleHeaderInformation } from "../../../training/ContentModuleBlocks/ModuleHeaderInformation";
import { findChapterDetails } from "../helper";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

type ChapterPreviewProps = {
    course: Course;
    chapterId: number;
};

export const ChaptersPreview = (props: ChapterPreviewProps) => {
    const history = useHistory();
    const openModuleSection = (moduleUrl: string) => {
        history.push(moduleUrl);
    };
    const chapterDetails = findChapterDetails(props.course, props.chapterId);
    const { t } = useTranslate();
    return (
        <Row>
            <div className="col-12">
                {chapterDetails && chapterDetails.data && (
                    <>
                        <ModuleHeaderInformation
                            //@ts-ignore
                            title={chapterDetails.data?.name}
                            id={`chapter-details-${chapterDetails.data.id}`}
                            sequence={0}
                        />
                        <div id="chapter_detail" className="block-section page_content course_tile">
                            <div className="row block">
                                <ModuleBlockDescription
                                    videoId={0}
                                    //@ts-ignore
                                    description={chapterDetails.data?.description}
                                    completionTime=""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ marginBottom: "0px" }} className="nextButton">
                                <PrimaryButton onClick={() => openModuleSection(chapterDetails.next)}>
                                    {t("COMMON_NEXT")}
                                </PrimaryButton>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Row>
    );
};
