import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import { EditUserComponent } from "../../components/Users/EditUserComponent";
import { UrlFactory } from "../../routing/UrlFactory";

type RouteParams = {
    userId?: string;
};

export const SysadminUsers = (): JSX.Element => {
    const params = useParams<RouteParams>();
    // @ts-ignore
    const userId = parseInt(params.userId);
    let breadCrumb = [
        { name: "Home", path: "/sysadmin" },
        { name: "Employees", path: "/sysadmin/users" },
        { name: "Add Employee", path: null },
    ];

    if (userId) {
        breadCrumb = [
            { name: "Home", path: "/sysadmin" },
            { name: "Employees", path: "/sysadmin/users" },
            { name: "Edit Employee", path: null },
        ];
    }

    return (
        <>
            <Row className="p-0">
                <Col md={12}>
                    <Breadcrumbs
                        // @ts-ignore

                        breadCrumb={breadCrumb}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <EditUserComponent userId={userId} componentFor={UrlFactory.sysadmin.users.create({})} />
                </Col>
            </Row>
        </>
    );
};
export default SysadminUsers;
