import {
    AdditionalResourcesClient,
    AnswerCategoriesClient,
    AnswersClient,
    ApiConfiguration,
    ChaptersClient,
    ClientCoursesClient,
    ClientsClient,
    CoursesClient,
    DemographicQuestionClient,
    DemographicUserDataClient,
    DepartmentsClient,
    DiplomasClient,
    GroupClient,
    GroupCoursesClient,
    GroupMembershipClient,
    GroupTypesClient,
    ImagesClient,
    IntroductionVideosClient,
    LoginClient,
    ModuleBlocksClient,
    ModulesClient,
    OnBoardingProgressClient,
    ParticipantProgressClient,
    QuestionOptionsClient,
    QuestionsClient,
    ReportClient,
    ReportsClient,
    RoundsClient,
    TaskCategoriesClient,
    TasksClient,
    TransformationTodoItemsClient,
    TransformationTodosClient,
    UserClient,
    VideosClient,
    HomeClient,
    AccountOwnerClient,
    SysadminClient,
    AllProgramsClient,
    ConfigurationClient,
} from "@/api-client";
import Constants from "../constants";
import { UserStorage } from "../auth/UserStorage";

export interface Api {
    home: HomeClient;
    images: ImagesClient;
    clients: ClientsClient;
    participantProgress: ParticipantProgressClient;
    courses: CoursesClient;
    users: UserClient;
    groups: GroupClient;
    chapters: ChaptersClient;
    modules: ModulesClient;
    clientCourses: ClientCoursesClient;
    accountOwners: AccountOwnerClient;
    questions: QuestionsClient;
    groupMembership: GroupMembershipClient;
    moduleBlocks: ModuleBlocksClient;
    videos: VideosClient;
    videosClient: VideosClient;
    departmentsClient: DepartmentsClient;
    questionOptions: QuestionOptionsClient;
    additionalResources: AdditionalResourcesClient;
    trainingOverViewReport: ReportsClient;
    trainingPerGroupReport: ReportsClient;
    trainingProgressPerDepartmentReport: ReportsClient;
    login: LoginClient;
    answers: AnswersClient;
    answerCategories: AnswerCategoriesClient;
    reports: ReportsClient;
    newReports: ReportClient;
    diplomas: DiplomasClient;
    introductionVideos: IntroductionVideosClient;
    demographicQuestions: DemographicQuestionClient;
    demographicAnswers: DemographicUserDataClient;
    groupTypes: GroupTypesClient;
    tasks: TasksClient;
    groupCourses: GroupCoursesClient;
    transformationTodos: TransformationTodosClient;
    transformationTodoItems: TransformationTodoItemsClient;
    clientCourseUserOnboarding: OnBoardingProgressClient;
    taskCategories: TaskCategoriesClient;
    rounds: RoundsClient;
    sysadmin: SysadminClient;
    allPrograms: AllProgramsClient;
    configuration: ConfigurationClient;
}

export const createApi = (apiToken?: string | null): Api => {
    const getAuthorizationHeader = () => {
        const token = apiToken ?? UserStorage.getCurrentToken();
        return token ? `Bearer ${token}` : null;
    };

    const apiConfig: ApiConfiguration = {
        baseUrl: Constants.NEW_API_URL,
        getAuthorizationHeader: getAuthorizationHeader,
    };

    return {
        home: new HomeClient(apiConfig),
        images: new ImagesClient(apiConfig),
        clients: new ClientsClient(apiConfig),
        participantProgress: new ParticipantProgressClient(apiConfig),
        courses: new CoursesClient(apiConfig),
        users: new UserClient(apiConfig),
        groups: new GroupClient(apiConfig),
        chapters: new ChaptersClient(apiConfig),
        modules: new ModulesClient(apiConfig),
        clientCourses: new ClientCoursesClient(apiConfig),
        accountOwners: new AccountOwnerClient(apiConfig),
        questions: new QuestionsClient(apiConfig),
        groupMembership: new GroupMembershipClient(apiConfig),
        moduleBlocks: new ModuleBlocksClient(apiConfig),
        videos: new VideosClient(apiConfig),
        videosClient: new VideosClient(apiConfig),
        departmentsClient: new DepartmentsClient(apiConfig),
        questionOptions: new QuestionOptionsClient(apiConfig),
        additionalResources: new AdditionalResourcesClient(apiConfig),
        trainingOverViewReport: new ReportsClient(apiConfig),
        trainingPerGroupReport: new ReportsClient(apiConfig),
        trainingProgressPerDepartmentReport: new ReportsClient(apiConfig),
        login: new LoginClient(apiConfig),
        answers: new AnswersClient(apiConfig),
        answerCategories: new AnswerCategoriesClient(apiConfig),
        reports: new ReportsClient(apiConfig),
        diplomas: new DiplomasClient(apiConfig),
        introductionVideos: new IntroductionVideosClient(apiConfig),
        demographicQuestions: new DemographicQuestionClient(apiConfig),
        demographicAnswers: new DemographicUserDataClient(apiConfig),
        groupTypes: new GroupTypesClient(apiConfig),
        tasks: new TasksClient(apiConfig),
        groupCourses: new GroupCoursesClient(apiConfig),
        transformationTodos: new TransformationTodosClient(apiConfig),
        transformationTodoItems: new TransformationTodoItemsClient(apiConfig),
        clientCourseUserOnboarding: new OnBoardingProgressClient(apiConfig),
        taskCategories: new TaskCategoriesClient(apiConfig),
        rounds: new RoundsClient(apiConfig),
        newReports: new ReportClient(apiConfig.baseUrl, () => Promise.resolve(getAuthorizationHeader())),
        sysadmin: new SysadminClient(apiConfig),
        allPrograms: new AllProgramsClient(apiConfig),
        configuration: new ConfigurationClient(apiConfig),
    };
};
