﻿import { ActionIcon, Checkbox, createStyles } from "@mantine/core";
import { MenuItem } from "./MenuItem";
import { useMemo } from "react";
import { Icons } from "../../../../common/Icons";

const useStyles = createStyles(() => ({
    checkboxBody: {
        width: "100%",
    },

    checkboxInput: {
        cursor: "inherit",
        width: "100%",
    },

    checkboxLabel: {
        cursor: "inherit",
        width: "100%",
    },

    checkboxLabelWrapper: {
        cursor: "inherit",
        width: "100%",
    },
}));

const useCheckboxLabelStyles = createStyles((theme, props: { color?: string }) => ({
    checkboxLabelContainer: {
        display: "flex",
        flexDirection: "row",
    },

    categoryColorIndicator: {
        width: "7.5px",
        alignSelf: "stretch",
        borderRadius: "2px",
        padding: "2px",
        margin: "1px 0px",
        marginRight: "3px",
        backgroundColor: props.color,
    },
}));

export type CategoryItem = {
    id: string;
    name: string;
    color: string;
    isSelected: boolean;
    onChange: () => void;
    onDelete: () => void;
    onEdit: () => void;
};

export type DefaultCategoryItem = Omit<CategoryItem, "id" | "onDelete" | "onEdit">;

const RightSection = (props: { item: CategoryItem }): JSX.Element => {
    return (
        <>
            <ActionIcon onClick={() => props.item.onEdit()} color="dark">
                <Icons.Edit />
            </ActionIcon>
            <ActionIcon onClick={() => props.item.onDelete()} color="dark">
                <Icons.Delete />
            </ActionIcon>
        </>
    );
};

const CheckboxLabel = (props: { name: string; color: string }): JSX.Element => {
    const { classes } = useCheckboxLabelStyles({ color: props.color });

    return (
        <div className={classes.checkboxLabelContainer}>
            <div className={classes.categoryColorIndicator} />
            {props.name}
        </div>
    );
};

export const CategoryMenuItem = (props: { item: CategoryItem | DefaultCategoryItem }): JSX.Element => {
    const { classes } = useStyles();

    const rightSection = useMemo(() => {
        if ("onEdit" in props.item) {
            return <RightSection item={props.item} />;
        }

        return null;
    }, [props.item]);

    return (
        <MenuItem rightSection={rightSection}>
            <Checkbox
                classNames={{
                    body: classes.checkboxBody,
                    input: classes.checkboxInput,
                    label: classes.checkboxLabel,
                    labelWrapper: classes.checkboxLabelWrapper,
                }}
                checked={props.item.isSelected}
                onChange={() => props.item.onChange()}
                label={<CheckboxLabel name={props.item.name} color={props.item.color} />}
            ></Checkbox>
        </MenuItem>
    );
};
