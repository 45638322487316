import { createStyles } from "@mantine/core";

export const useStyle = createStyles((theme, { categoryColor }: { categoryColor?: string }, getRef) => {
    const grey10 = "#E9E9E9";

    const blue8 = theme.colors.blue[8];

    return {
        taskCardContainer: {
            position: "relative",
            paddingBottom: "0.8rem", //React beautiful dnd does not support flexbox gap
        },
        taskCard: {
            display: "flex",
            background: "white",
            borderRadius: theme.fn.radius("sm"),
            boxShadow: theme.shadows["xs"],
            overflow: "hidden",
            "&:before": {
                content: "''",
                flex: "0 0 8px",
                background: categoryColor !== undefined ? categoryColor : grey10,
            },
        },
        content: {
            padding: "0.8rem",
            width: "100%",
        },
        textArea: {
            width: "100%",
            minHeight: "calc(5.75rem - -1px) !important",
            "&, &:focus, &:active": {
                border: "none",
                resize: "none",
                outline: "none",
            },
        },
        taskMarkerContainer: {
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(0.6rem, -0.6rem)",
            color: "white",
            display: "flex",
            gap: "0.2rem",
        },
        marker: {
            width: "1.4rem",
            height: "1.4rem",
            borderRadius: "100%",
            fontSize: "0.7rem",
            display: "inline-flex !important",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            boxShadow: theme.shadows["xs"],
        },
        priorityHighMarker: {
            color: "hsla(0, 42%, 48%, 1)",
        },
        groupMarker: {
            color: blue8,
        },
    };
});
