import React, { FunctionComponent, useEffect, useState } from "react";
import "./style.scss";
import { NiklasSignatures } from "../../assets/index";
import { createApi } from "../../common/api";
import { Diploma } from "@/api-client";
import { AppLoader } from "../../components/Spinner";
import moment from "moment";
import { useParams } from "react-router-dom";

/*  eslint-disable react-hooks/exhaustive-deps */

type RouteParams = {
    token: string;
};

type GetDiploma = {
    diploma: Diploma;
    isPending: boolean;
    error: string;
};

const TrainingDiploma = () => {
    const signatures: any = NiklasSignatures;
    const [diploma, setDiploma] = useState<GetDiploma>({
        // @ts-ignore

        diploma: null,
        isPending: false,
        // @ts-ignore

        error: null,
    });
    const params = useParams<RouteParams>();
    const api = createApi();

    useEffect(() => {
        setDiploma({
            ...diploma,
            isPending: true,
        });
        api.diplomas.get(params.token!).then(
            result => {
                setDiploma({
                    ...diploma,
                    isPending: false,
                    diploma: result,
                });
            },
            error => {
                setDiploma({
                    ...diploma,
                    isPending: false,
                    error: error,
                });
            }
        );
    }, []);
    const details = diploma.diploma;
    return (
        <div className="container">
            <AppLoader loading={diploma.isPending} />
            <div className="row">
                <div className="col-md-12 diploma">
                    <div id="p1dimg1" className="col-md-12">
                        <div className="dclr"></div>
                        <p className="completion-text">CERTIFICATE OF COMPLETION</p>
                        <p className="awarded-to">Awarded to</p>
                        <p className="participant-name">{details?.fullName}</p>
                        <p className="has-completed">HAS COMPLETED</p>
                        <p className="course-name">{details?.courseName}</p>
                        <p className="detail-text">
                            and obtained a comprehensive understanding of a flow-oriented and self-improving operations
                            strategy, i.e Hups. his program equals that of similar entry courses in Lean, Agile,
                            Customer Centric Operations Development and Systems Thinking. The participant obtained the
                            knowledge and know-how through individual training, reflection, flipped classroom workshops
                            and a constant adaptation to current operations context
                        </p>
                        <p className="completed-on pt-2">
                            COMPLETED ON {moment(details?.dateOfCompletion)?.format("DD/MM/YYYY")}
                        </p>
                        <img src={signatures} className="signatures pt-4" alt="Hups" />
                        <p className="founder-name">Niklas Modig, CEO &amp; Founder</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingDiploma;
