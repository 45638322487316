import { ModuleBlock } from "@/api-client";
import React, { createRef, useEffect } from "react";
import { useTranslate } from "@tolgee/react";
import scrollToElement from "scroll-to-element";
import { PrimaryButton } from "../../Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

interface SubmitButtonProps {
    isPending: boolean;
    currentProgress: boolean | null;
    disabled: boolean;
    nextModuleBlock: ModuleBlock;
    moduleBlockId: number;
    moduleBlock: ModuleBlock;
    isLast: boolean;
    id: string;
    moduleProgressPending?: boolean;
}

export const QuestionsSubmitButton = (props: SubmitButtonProps) => {
    const { isLast, id } = props;
    const { t } = useTranslate();
    const tempSpace: any = createRef();
    useEffect(() => {
        if (isLast) {
            if (tempSpace.current) {
                tempSpace.current.style.display = "block";
            } else if (tempSpace.current) {
                tempSpace.current.style.display = "none";
            }
        }
        scrollToElement(`${"#" + id}`, {
            offset: -65,
        });
    }, [isLast, id]);
    const { isPending, disabled, nextModuleBlock, moduleBlockId, moduleBlock, moduleProgressPending } = props;
    return (
        <>
            {(moduleBlockId === moduleBlock.id ||
                (nextModuleBlock !== undefined && nextModuleBlock.type !== "ExerciseBlock")) && (
                <div>
                    <PrimaryButton
                        disabled={isPending || disabled}
                        type="submit"
                        loading={isPending || moduleProgressPending}
                        eventName={{ object: "mytraining", action: "submitanswer" }}
                    >
                        {t("MODULE_FOOTER_NEXT")}
                    </PrimaryButton>
                </div>
            )}
            <div ref={tempSpace} style={{ display: "none" }} id="tempSpace"></div>
        </>
    );
};
