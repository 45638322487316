import React, { useEffect, useMemo } from "react";
import { createApi } from "../../common/api";
import { WithChildren } from "../../common/StateWrapper";
import { AppLoader } from "../../components/Spinner";
import { useSysadminContextDispatch } from "../SysadminContext";
import { useCurrentCourseFromParams } from "./helper";

/*  eslint-disable react-hooks/exhaustive-deps */

export const CoursesLayout = (props: WithChildren) => {
    const [currentCourse] = useCurrentCourseFromParams();
    const api = createApi();
    const sysadminDispatch = useSysadminContextDispatch();
    const isCurrentCourseLoaded = useMemo(() => currentCourse !== undefined, [currentCourse]);

    useEffect(() => {
        if (!isCurrentCourseLoaded) {
            return;
        }

        // @ts-ignore
        api.courses.get(currentCourse.id).then(response => {
            // @ts-ignore
            sysadminDispatch(s => ({ ...s, courses: [...s.courses.filter(c => c.id !== currentCourse.id), response] }));
        });
    }, [isCurrentCourseLoaded]);

    return (
        <>
            <AppLoader loading={!isCurrentCourseLoaded} />
            {isCurrentCourseLoaded && props.children}
        </>
    );
};
