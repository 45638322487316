import { Course, CourseAssignmentInfo, ReportsClient } from "@/api-client";
import _ from "lodash";
import { Columns, exportToCsv } from "../csvUtil";
import { IReport } from "../IReport";

export class CourseAssignmentReport implements IReport {
    public name;
    public description;

    constructor(private reportsClient: ReportsClient, private allCourses: Course[]) {
        this.name = "Course assignments";
        this.description = "Statistics on assignment of active users, groups and clients for all courses";
    }

    public downloadReport() {
        return this.reportsClient.getCourseAssignmentReport().then(result => {
            const columns: Columns<CourseAssignmentReportData> = {
                CourseId: "id",
                CourseName: "name",
                CourseAdminName: x => x.adminName ?? "",
                CourseCreatedDate: "createdAt",
                CourseStatus: "status",
                AssignedClientsCount: "assignedClientsCount",
                AssignedUsersCount: "assignedUsersCount",
                AssignedGroupsCount: "assignedGroupsCount",
            };

            var resultsWithCourse = _.orderBy(result, "courseId").map(r => {
                return {
                    ...this.allCourses.find(x => x.id === r.courseId),
                    ...r,
                };
            });

            // @ts-ignore
            exportToCsv(resultsWithCourse, columns, "CourseAssignmentReport.csv");
        });
    }
}

interface CourseAssignmentReportData extends CourseAssignmentInfo, Course {}
