import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { MRT_ColumnDef } from "mantine-react-table";
import { UserDto, UserStatus } from "@/api-client";
import { Table, useModal, RowActions, UserStatusBadge, RowActionConfig } from "@/components";
import { AddUserModal } from "./AddUsers/AddUserModal";
import { Button } from "../../../components/Button/Button";
import { UrlFactory } from "../../../routing/UrlFactory";
import { NameCell } from "../NameCell";
import { translateInviteStatus } from "../../../sysadmin/helper";

interface UserListProps {
    users: UserDto[] | null;
    reloadUsers: () => void;
}

type User = {
    displayName: string;
    email: string;
    phoneNumber: string;
} & Omit<UserDto, "email" | "phoneNumber">;

const acceptedInviteOptions: boolean[] = [true, false];

const statusOptions: UserStatus[] = ["Active", "Inactive"];

const getDisplayName = (user: UserDto) => {
    const displayName: string[] = [];
    if (user.firstName) {
        displayName.push(user.firstName);
    }
    if (user.lastName) {
        displayName.push(user.lastName);
    }

    const joined = displayName.join(" ");
    if (joined.trim() !== "") {
        return joined;
    }

    return "";
};

const UserTable = (props: { data: User[] | null; onUserAdded: () => void }) => {
    const { open } = useModal(AddUserModal);

    const { t } = useTranslate();
    const translatedInviteStatus: { [key: string]: string } = translateInviteStatus(t);
    const translateAcceptedInvite = (value: boolean) => (value ? t("COMMON_YES") : t("COMMON_NO"));
    const translateStatus = (value: UserStatus) => translatedInviteStatus[value];

    const userStatusesSelectItems = statusOptions.map(s => ({
        label: translateStatus(s),
        value: translateStatus(s),
    })) as SelectItem[] & string;

    const invitationStatusesSelectItems = acceptedInviteOptions.map(v => ({
        label: translateAcceptedInvite(v),
        value: translateAcceptedInvite(v),
    })) as SelectItem[] & string;

    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                header: "hidden",
                accessorKey: "email",
            },
            {
                header: "hidden",
                accessorKey: "phoneNumber",
            },
            {
                id: "name",
                accessorKey: "displayName",
                Cell: ({ row }) => {
                    const user = row.original;
                    return (
                        <NameCell
                            id={user.id}
                            email={user.email}
                            phoneNumber={user.phoneNumber}
                            avatarUrl={user.avatarUrl}
                            displayName={user.displayName}
                        />
                    );
                },
                header: t("ACCOUNTOWNER_USERS_NAME"),
                enableColumnFilter: false,
            },
            {
                id: "status",
                header: t("ACCOUNTOWNER_USERS_STATUS"),
                accessorFn: user => translateStatus(user.status),
                Cell: ({ row }) => {
                    const user = row.original;
                    return <UserStatusBadge status={user.status} />;
                },
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: userStatusesSelectItems,
                },
                enableGlobalFilter: false,
                size: 50,
                sortingFn: (a, b) => {
                    const getStatusSortValue = (user: User) => {
                        switch (user.status) {
                            case "Active":
                                return 2;
                            case "Inactive":
                                return 1;
                            default:
                                return -1;
                        }
                    };

                    const aSortValue = getStatusSortValue(a.original);
                    const bSortValue = getStatusSortValue(b.original);

                    if (aSortValue === bSortValue) {
                        return 0;
                    }

                    return aSortValue > bSortValue ? -1 : 1;
                },
            },
            {
                id: "hasAcceptedInvite",
                accessorFn: user => translateAcceptedInvite(user.hasAcceptedInvite),
                header: "Accepted Invite",
                enableGlobalFilter: false,
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: invitationStatusesSelectItems,
                },
                size: 50,
                sortingFn: (a, b) => {
                    if (a.original.hasAcceptedInvite === b.original.hasAcceptedInvite) {
                        return 0;
                    }

                    return a.original.hasAcceptedInvite > b.original.hasAcceptedInvite ? -1 : 1;
                },
            },
        ],
        []
    );

    const rowActions: RowActionConfig<User> = useMemo(
        () => ({
            renderRowActions: ({ row }) => (
                <RowActions.EditLink to={UrlFactory.accountOwner.user.create({ userId: row.original.id })} />
            ),
            size: 10,
        }),
        []
    );

    const openAddUserModal = () => {
        open({ onClose: props.onUserAdded });
    };

    return (
        <Table
            hiddenColumns={["email", "phoneNumber"]}
            initialSort={[
                { id: "status", desc: false },
                { id: "hasAcceptedInvite", desc: false },
                { id: "name", desc: false },
            ]}
            toolbarActions={() => <Button onClick={() => openAddUserModal()}>{t("RESOURCES_ADD_EMPLOYEES")}</Button>}
            columns={columns}
            initialColumnFilterState={[]}
            data={props.data}
            rowActions={rowActions}
        />
    );
};

export const UsersList = (props: UserListProps): JSX.Element => {
    const users: User[] | null = useMemo(() => {
        if (props.users === null) {
            return null;
        }

        //map null to empty string so default search works in table
        return props.users.map(u => ({
            ...u,
            email: u.email !== null ? u.email : "",
            phoneNumber: u.phoneNumber !== null ? u.phoneNumber : "",
            displayName: getDisplayName(u),
        }));
    }, [props.users]);

    return <UserTable data={users} onUserAdded={props.reloadUsers} />;
};
