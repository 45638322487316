import { ActionIcon } from "@mantine/core";
import { NavLink } from "react-router-dom";
import { Icons } from "@/common";

type EditLinkProps = {
    to: string;
};

export const EditLink = (props: EditLinkProps) => {
    return (
        <ActionIcon component={NavLink} to={props.to}>
            <Icons.Edit />
        </ActionIcon>
    );
};
