import { createStyles, MantineTheme } from "@mantine/core";

export const useNavbarStyles = createStyles((theme: MantineTheme) => {
    const sectionBorder = `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`;

    return {
        navbarContentContainer: {
            marginTop: "20px",
        },
        section: {
            marginBottom: theme.spacing.md,
            paddingBottom: theme.spacing.md,
            "&:not(:last-of-type)": {
                borderBottom: sectionBorder,
            },
        },
        settingsSection: {
            borderTop: sectionBorder,
        },
        navLinkRoot: {
            paddingTop: "1px",
            paddingBottom: "1px",
            "&[data-active=true]": {
                color: `${theme.colors.dark[0]}`, //Overwrite color since bootstrap globally sets colors
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
            "&:hover": {
                color: `${theme.colors.dark[0]}`, //Overwrite color since bootstrap globally sets colors
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
            "&:hover[data-active=true]": {
                color: `${theme.colors.dark[0]}`, //Overwrite color since bootstrap globally sets colors
                backgroundColor: "rgba(255, 255, 255, 0.2)",
            },
        },
        parentNavLinkLabel: {
            fontSize: theme.headings.sizes.h5.fontSize,
            color: theme.white,
        },
        navLinkChildren: {
            paddingLeft: 0,
            "& > a": {
                paddingLeft: 28,
            },
        },
        buttonNavLinkLabel: {
            fontSize: theme.fontSizes.sm,
            color: theme.white,
        },
        navLinkBody: {
            display: "flex",
        },
        sectionHeader: {
            paddingLeft: 12,
            paddingRight: 12,
            marginBottom: 5,
            userSelect: "none",
        },
    };
});
