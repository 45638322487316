import { FunctionComponent } from "react";
import { useTranslate } from "@tolgee/react";
import { Link, useLocation } from "react-router-dom";
import { LanguageSwitcher } from "./LanguageSwitcher";
import "./styles.scss";

const Footer: FunctionComponent = (): JSX.Element => {
    const { t } = useTranslate();
    const location = useLocation();
    const noNavPaths = ["/login"];
    const noNav = noNavPaths.includes(location.pathname);

    return (
        <footer className={`w-100 ${noNav ? "no-nav" : ""}`}>
            <div className="container">
                <div className="row g-0">
                    <div className="col-12">
                        <div className="footer-sticky">
                            <ul className="nav me-auto navbar">
                                <li>
                                    <Link target="_blank" className="nav-item" to={"/policies"}>
                                        {t("FOOTER_PRIVACY_POLICY")}
                                    </Link>
                                </li>
                                <li>
                                    <Link target="_blank" className="nav-item" to={"/terms"}>
                                        {t("FOOTER_TERMS_OF_SERVICE")}
                                    </Link>
                                </li>
                                <li>
                                    <LanguageSwitcher />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
