import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { DemoGraphicQuestionsTemplateProps } from ".";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import useDebounce from "../../../components/UseDebounce/UseDebounce";

/*  eslint-disable react-hooks/exhaustive-deps */

export const TextInputTypeQuestion = (props: DemoGraphicQuestionsTemplateProps) => {
    const { question, answer, error } = props.questionAndAnswer;
    const { id, label, required } = question;
    // @ts-ignore
    const [answerValue, setAnswerValue] = useState<string>(answer?.value);
    const answersStateChange = useDebounce(answerValue, 500);
    const [isQuestionTouched, setIsQuestionTouched] = useState<boolean>(false);
    const handleChange = (answerValue: string): void => {
        setAnswerValue(answerValue);
        setIsQuestionTouched(true);
    };

    useEffect(() => {
        if (isQuestionTouched) {
            props.setAnswers(answerValue, answer?.id);
        }
    }, [answersStateChange, isQuestionTouched]);

    return (
        <Col className="mb-3" key={`questions-answers-${id}`}>
            <label htmlFor={`formControl${label}`} className="form-label demographic-questions-label">
                {label}
            </label>
            <input
                onChange={e => handleChange(e.target.value)}
                type="text"
                name="input"
                className="form-control"
                value={answerValue || ""}
            />
            {required === true && error && <DisplayInlineError error={error} />}
        </Col>
    );
};
