import { useModals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";
import { DemographicQuestion } from "@/api-client";
import { RowActions, Table, useModal } from "@/components";
import { createApi, UrlFactory } from "@/common";
import { AddDemographicQuestionModal } from "./AddDemographicQuestionModal";
import { Button } from "../../../components/Button/Button";
import { RowActionConfig } from "../../../components/Table/Table";

export const DemographicQuestionsList = (props: {
    items: DemographicQuestion[] | null;
    onQuestionDeleted: () => void;
}) => {
    const modal = useModals();
    const addModal = useModal(AddDemographicQuestionModal);
    const { t } = useTranslate();

    const openDeleteModal = (id: number) =>
        modal.openConfirmModal({
            title: t("ONBOARDING_QUESTIONS_DELETE_CONFIRM_HEADING"),
            centered: true,
            labels: { confirm: t("ONBOARDING_QUESTTION_DELETE_YES"), cancel: t("ONBOARDING_QUESTTION_DELETE_NO") },
            children: <Text size="sm">{t("ONBOARDING_QUESTTION_DELETE_MESSAGE")}</Text>,
            confirmProps: { miw: "100px", color: "red" },
            cancelProps: { miw: "100px" },
            onConfirm: () => {
                const api = createApi();
                api.demographicQuestions
                    .delete(id)
                    .then(() => props.onQuestionDeleted())
                    .catchWithToast();
            },
        });

    const columns = useMemo<MRT_ColumnDef<DemographicQuestion>[]>(
        () => [
            {
                header: t("COMMON_NAME"),
                accessorKey: "label",
            },
        ],
        [t]
    );

    const rowActions: RowActionConfig<DemographicQuestion> = useMemo(
        () => ({
            renderRowActions: ({ row }) => (
                <>
                    <RowActions.EditLink
                        to={UrlFactory.accountOwner.demographicQuestion.create({ id: row.original.id! })}
                    />
                    <RowActions.DeleteButton onClick={() => openDeleteModal(row.original.id!)} />
                </>
            ),
            size: 10,
        }),
        []
    );

    return (
        <Table
            columns={columns}
            data={props.items}
            initialColumnFilterState={[]}
            enableColumnFilters={false}
            toolbarActions={() => (
                <Button
                    onClick={() =>
                        addModal.open({
                            onClose: props.onQuestionDeleted,
                        })
                    }
                >
                    {t("ONBOARDING_ADD_DEMOGRAPHIC_QUESTIONS")}
                </Button>
            )}
            rowActions={rowActions}
        />
    );
};
