/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access */
import { Container, Flex } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useMatches } from "react-router-dom";
import { ReactNode } from "react";
import { BreadcrumbProps, Breadcrumbs } from "./Breadcrumbs";

export const AccountOwnerLayoutPage = (props: { children: ReactNode }) => {
    const matches = useMatches();
    const { t } = useTranslate();

    const items: BreadcrumbProps["items"] = matches
        .filter(match => (match?.handle as any)?.crumb !== undefined)
        .map((match, index, array) => {
            //Don't link what we assume is the breadcrumb item for the current page
            const isLast = index === array.length - 1;

            return { title: t((match?.handle as any)?.crumb as string), href: isLast ? undefined : match.pathname };
        });

    return (
        <Container size="xl">
            <Flex direction="column" rowGap="xl" mb="xl">
                <Breadcrumbs items={items} />
                {props.children}
            </Flex>
        </Container>
    );
};
