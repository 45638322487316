import { useEffect, useState } from "react";
import { Badge, Flex, Stack, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { createApi } from "@/common";
import { AppLoader, CardBlock, RenderIf, Section } from "@/components";
import { TeamsList } from "./TeamsList";

type ProgramAssignmentPageProps = {
    programId: number;
};

type Program = {
    id: number;
    name: string;
    owner?: string;
};

export const ProgramAssignmentPage = (props: ProgramAssignmentPageProps) => {
    const { t } = useTranslate();
    const [program, setProgram] = useState<Program | null>(null);

    const getProgram = () => {
        return createApi()
            .courses.get(props.programId)
            .then(p => setProgram({ id: p.id, name: p.name, owner: p.owner?.fullName?.toUpperCase() }));
    };

    useEffect(() => {
        getProgram().catchWithToast();
    }, []);

    if (program === null) {
        return <AppLoader loading />;
    }

    return (
        <CardBlock>
            <Stack>
                <Flex>
                    <Text w="6rem" fw="bold">
                        {t("ACCOUNTOWNER_PROGRAMS_PROGRAM")}
                    </Text>
                    <Text>{program.name}</Text>
                </Flex>
                <RenderIf show={program.owner !== undefined}>
                    <Flex>
                        <Text w="6rem" fw="bold">
                            {t("ACCOUNTOWNER_PROGRAMS_EXPERT")}
                        </Text>
                        <Badge>{program.owner}</Badge>
                    </Flex>
                </RenderIf>
                <Section title={t("ACCOUNTOWNER_PROGRAMS_ASSIGNED_TEAMS")}>
                    <TeamsList program={{ id: program.id, name: program.name }} />
                </Section>
            </Stack>
        </CardBlock>
    );
};
