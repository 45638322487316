import React, { useEffect, useState } from "react";
import { Col, Container, Form as bForm, FormControl, FormText, Row } from "react-bootstrap";
import { showToast } from "../Notify";
import { useTranslate } from "@tolgee/react";
import { useCurrentUser } from "../../common/user/context";
import { createApi } from "../../common/api";
import { confirmAlert } from "react-confirm-alert";
import RenderIf from "../render-if/render-if";
import { UserHasSpecificRole } from "../../common/user/utils";
import { SYSADMIN_ROLE } from "../../constants";
import { UserStatuses } from "@/api-client";
import { SecondaryButton } from "../Button/SecondaryButton";
import { translateInviteStatus } from "../../sysadmin/helper";

type StatusElementProps = {
    userId: number;
    onUserDeleted: () => void;
};

const InviteLink = (props: { link: string; dataTip: string; onClick: () => Promise<void> }) => {
    return (
        <>
            <label>Invite Link</label>
            <div className="input-group">
                <bForm.Control size={"sm"} type="text" placeholder={props.link} readOnly />
                <div className=" ms-2">
                    <SecondaryButton onClick={props.onClick} disabled={!props.link}>
                        <i className="fas fa-copy mx-2 ms-0" />
                        Copy
                    </SecondaryButton>
                </div>
            </div>
        </>
    );
};

const StatusElement = (props: StatusElementProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const [userStatus, setUserStatus] = useState<UserStatuses>(null!);

    useEffect(() => {
        api.users.get(props.userId).then(user => {
            setUserStatus(user.status);
        });
    }, [props.userId]);

    //@ts-ignore
    const label = translateInviteStatus(t)[userStatus];

    const getStatusAction = () => {
        if (userStatus === "Active") {
            return {
                buttonLabel: t("ACCOUNTOWNER_DEACTIVATE_USER"),
                command: () => {
                    api.users.deactivate(props.userId).then(async result => {
                        if (result.ok) {
                            setUserStatus("Inactive");
                            showToast(
                                t("ACCOUNTOWNER_USER_HAS_BEEN_DEACTIVATED", "User has been deactivated."),
                                "success"
                            );
                        }
                    });
                },
                helpText: "",
            };
        } else if (userStatus === "Inactive") {
            return {
                buttonLabel: t("ACCOUNTOWNER_ACTIVATE_USER"),
                command: () => {
                    api.users.activate(props.userId).then(async result => {
                        if (result.ok) {
                            setUserStatus("Active");
                            showToast(t("ACCOUNTOWNER_USER_HAS_BEEN_ACTIVATED", "User has been activated."), "success");
                        }
                    });
                },
                helpText: t(
                    "ACCOUNTOWNER_USER_STATUS_HELPTEXT",
                    "Inactive users cannot log in to the platform, but their improvements are visible."
                ),
            };
        }

        return {
            buttonLabel: "",
            helpText: "",
            command: () => {},
        };
    };

    const { buttonLabel, command, helpText } = getStatusAction();

    const DeleteUser = (e: any) => {
        e.preventDefault();
        confirmAlert({
            title: t("SYSADMIN_USER_DELETE_CONFIRM_HEADING"),
            buttons: [
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_YES"),
                    onClick: () => {
                        const usrId = props.userId;
                        api.users.delete(usrId).then(result => {
                            if (result.ok) {
                                showToast(
                                    t("ACCOUNTOWNER.USER_HAS_BEEN_DELETED_SUCCESSFULLY", "User deleted successfully"),
                                    "success"
                                );

                                props.onUserDeleted();
                            } else {
                                showToast(
                                    t("ACCOUNTOWNER_USER_COULD_NOT_BE_DELETED", "User could not be deleted."),
                                    "error"
                                );
                            }
                        });
                    },
                },
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_NO"),
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    return (
        <Container>
            <Row>
                <label>{t("ACCOUNTOWNER_UPDATE_FORM_STATUS")}</label>
            </Row>
            <Row>
                <Col>
                    <FormControl
                        type={"text"}
                        plaintext={false}
                        readOnly={true}
                        placeholder={"User is " + label?.toLowerCase()}
                    />
                </Col>
                <RenderIf show={userStatus !== "Deleted"}>
                    <Col>
                        <SecondaryButton onClick={command}>{buttonLabel}</SecondaryButton>
                    </Col>
                    <Col md={"auto"}>
                        <SecondaryButton danger onClick={e => DeleteUser(e)}>
                            Delete user
                        </SecondaryButton>
                    </Col>
                    {helpText ? <FormText className={"text-muted"}>{helpText}</FormText> : ""}
                </RenderIf>
            </Row>
        </Container>
    );
};

type EditRolesAndStatusProps = {
    onUserDeleted: () => void;
    userId: number;
};

export function EditUserRolesAndStatus(props: EditRolesAndStatusProps) {
    const { t } = useTranslate();
    const api = createApi();
    const currentUser = useCurrentUser();
    const [userStatus, setUserStatus] = useState<UserStatuses>(null!);
    const [tooltipText, setToolTipText] = useState<string>(t("ACCOUNTOWNER_COPY_TO_CLIPBOARD", "Copy to clipboard"));
    const [inviteLink, setInviteLink] = useState<string>(null!);
    const [userHasAcceptedInvite, setUserHasAcceptedInvite] = useState<boolean>(null!);

    useEffect(() => {
        getUser();
    });

    const getUser = () => {
        api.users.get(props.userId).then(user => {
            setUserStatus(user.status);
            setInviteLink(
                `${window.location.protocol}//${window.location.hostname}/accept-invite?email=${encodeURIComponent(
                    user.email!
                )}&token=${encodeURIComponent(user.password!)}`
            );
            setUserHasAcceptedInvite(user.hasAcceptedInvite);
        });
    };

    const copyInviteLink = async () => {
        setToolTipText(t("ACCOUNTOWNER_INVITE_LINK_COPIED", "Invite link copied"));
        await navigator.clipboard.writeText(inviteLink);
    };

    const acceptedString = () => {
        if (userHasAcceptedInvite === null) {
            return null;
        }

        if (userHasAcceptedInvite) {
            return t("ACCOUNTOWNER_USER_HAS_ACCEPTED_INVITE");
        } else {
            return t("ACCOUNTOWNER_USER_HAS_NOT_ACCEPTED_INVITE");
        }
    };

    return (
        <>
            <Row className="mb-4">
                <StatusElement userId={props.userId} onUserDeleted={props.onUserDeleted} />
            </Row>
            <Row className="mb-2">
                <div>{acceptedString()}</div>
            </Row>
            <RenderIf
                //@ts-ignore
                show={currentUser && UserHasSpecificRole(currentUser, SYSADMIN_ROLE) && !userHasAcceptedInvite}
            >
                <Row className="mb-2">
                    <Container>
                        {userStatus === "Active" ? (
                            <InviteLink
                                key={tooltipText}
                                link={inviteLink}
                                dataTip={tooltipText}
                                onClick={async () => await copyInviteLink()}
                            />
                        ) : (
                            <div />
                        )}
                    </Container>
                </Row>
            </RenderIf>
        </>
    );
}
