﻿import { CardContainer } from "../CardContainer/CardContainer";
import { BoardProps } from "./BoardProps";
import { useBoardContext } from "../../BoardContext";
import { ImprovementBoardTask } from "@/api-client";

type Props = Omit<BoardProps, "swimLane" | "displayMode"> & { tasks: ImprovementBoardTask[] };

export const Inbox = ({ tasks, defaultNewTask }: Props) => {
    const boardContext = useBoardContext();
    const categories = boardContext.state.categoryFilters.items.map(f => f.item);

    return (
        <CardContainer
            droppableId={{ column: "New" }}
            allowAdd
            tasks={tasks}
            categories={categories}
            defaultNewTask={defaultNewTask}
        />
    );
};
