import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import PhoneInput, { Value } from "react-phone-number-input";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { DisplayInlineError } from "../../components/DisplayInlineError";
import { createApi } from "../../common/api";
import { showToast } from "../../components/Notify";
import { AppLoader } from "@/components";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { useAuth } from "../../auth/useAuth";
import { showLoginErrorNotification } from "../../components/showLoginErrorNotification";
import { TimerMessage } from "./TimerMessage";

type SignInWithPhoneModel = {
    mobilePhone: string;
    verificationCode: number | null;
};

export const SignInWithPhone = () => {
    const { t } = useTranslate();
    const api = createApi();
    const insights = useAppInsightsContext();
    const [value, setValue] = useState<Value | undefined>(undefined);
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(60);
    const { signIn } = useAuth();

    const initialValues: SignInWithPhoneModel = {
        mobilePhone: "",
        verificationCode: null,
    };

    const handleSubmit = (values: SignInWithPhoneModel) => {
        setIsPending(true);
        api.login.phoneLogin({ verificationCode: values.verificationCode!, phoneNumber: values.mobilePhone }).then(
            async response => {
                setIsPending(false);

                if (response.resultCode === "Ok") {
                    insights.trackEvent({ name: "LOGIN_MOBILE_SUCCESS" }, { mobileNumber: values.mobilePhone });

                    const redirect = await signIn(response.token);
                    redirect();
                } else {
                    insights.trackEvent({ name: "LOGIN_MOBILE_FAILED" }, { mobileNumber: values.mobilePhone });
                    showLoginErrorNotification(response, t, true);
                }
            },
            () => {
                setIsPending(false);
                showToast(t("COMMON_DEFAULT_ERROR"), "error");
            }
        );
    };

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: false,
        initialValues: initialValues,
        validationSchema: yup.object({
            mobilePhone: yup
                .string()
                .required(t("LOGIN_WITH_PHONE_MOBILE_MSG"))
                .test("len", t("LOGIN_WITH_PHONE_MOBILE_VALID_MSG"), val => val !== undefined && val.length > 11)
                .nullable(),
            verificationCode: yup
                .number()
                .test(
                    "len",
                    t("LOGIN_WITH_PHONE_MOBILE_VALID_TOKEN"),
                    val => val !== undefined && val.toString().length > 5 && val.toString().length <= 6
                )
                .required(t("LOGIN_WITH_PHONE_VERIFICATION_CODE"))
                .typeError(t("LOGIN_WITH_PHONE_VERIFICATION_CODE"))
                .nullable(),
        }),
        onSubmit: values => {
            handleSubmit(values);
        },
        enableReinitialize: false,
    });

    useEffect(() => {
        if (codeSent === true) {
            const interval = setTimeout(() => {
                setTimer(s => s - 1);
            }, 1000);

            if (timer === 0) {
                clearInterval(interval);
            }
        }
    }, [timer, codeSent]);

    const requestVerificationCode = () => {
        formik.setErrors({});
        if (formik.values.mobilePhone) {
            setIsPending(true);
            api.login.requestVerificationCode(formik.values.mobilePhone).then(
                () => {
                    insights.trackEvent(
                        { name: "VERFICATION_MOBILE_SUCCESS" },
                        { mobileNumber: formik.values.mobilePhone }
                    );
                    setCodeSent(true);
                    setIsPending(false);
                },
                () => {
                    insights.trackEvent(
                        { name: "VERFICATION_MOBILE_FAILED" },
                        { mobileNumber: formik.values.mobilePhone }
                    );
                    setCodeSent(true);
                    setIsPending(false);
                }
            );
        } else {
            formik.setErrors({ mobilePhone: t("LOGIN_WITH_PHONE_MOBILE_MSG") });
        }
    };

    return (
        <form autoComplete="off" className="px-4" onSubmit={formik.handleSubmit}>
            <AppLoader loading={isPending} />
            <div className="mb-4 mt-4">
                <label htmlFor="mobilePhone">{t("LOGIN_WITH_PHONE_PHONE_NUMBER")}</label>
                <PhoneInput
                    placeholder={t("LOGIN_WITH_PHONE_PLACEHOLDER")}
                    autoComplete="off"
                    country="SE"
                    international
                    defaultCountry="SE"
                    smartCaret
                    value={value}
                    name="mobilePhone"
                    id="mobilePhone"
                    readOnly={!!codeSent}
                    rules={{ required: true, isPossiblePhoneNumber: true }}
                    onChange={val => {
                        setValue(val);
                        formik.setFieldValue("mobilePhone", val).then(
                            () => {},
                            () => {}
                        );
                        formik.setErrors({ mobilePhone: "" });
                    }}
                    countryCallingCodeEditable={false}
                />
                <DisplayInlineError error={formik.errors.mobilePhone ?? ""} />
            </div>

            {!codeSent && (
                <div className="mb-4">
                    <PrimaryButton
                        fullWidth
                        onClick={() => {
                            requestVerificationCode();
                        }}
                    >
                        {t("LOGIN_WITH_PHONE_SMS_BUTTON")}
                    </PrimaryButton>
                </div>
            )}
            {codeSent && (
                <>
                    <TimerMessage
                        timer={timer}
                        messageHandler={() => {
                            requestVerificationCode();
                            setTimer(60);
                        }}
                    />

                    <div className="mb-4 mt-4">
                        <label htmlFor="verificationCode">{t("LOGIN_WITH_PHONE_SMS_CODE")}</label>
                        <input
                            autoFocus
                            type="number"
                            min="0"
                            name="verificationCode"
                            id="verificationCode"
                            placeholder="123456"
                            className="form-control"
                            onChange={e => {
                                formik.setFieldValue("verificationCode", parseInt(e.target.value, 10)).then(
                                    () => {},
                                    () => {}
                                );
                            }}
                        />

                        <DisplayInlineError
                            className={`clearfix w-100 text-danger text-start displayblock ${
                                formik.errors.verificationCode === undefined ? "invisible" : ""
                            }`}
                            error={formik.errors.verificationCode ?? ""}
                        />

                        <PrimaryButton disabled={formik.errors.verificationCode !== undefined} type="submit">
                            {t("LOGIN_WITH_PHONE_VERIFY")}
                        </PrimaryButton>
                    </div>
                </>
            )}
        </form>
    );
};
