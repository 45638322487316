import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../common/api";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { Group } from "@/api-client";
import { showToast } from "../../components/Notify";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import PageHeading from "../../components/PageHeading";
import { GroupsListing } from "../Components/MyOrganisation/GroupsListing";
import GroupMembersList from "../Components/GroupMembersList";
import { AddGroupEmployees } from "../Components/AddGroupEmployees";
import { TrainingAssignments } from "../Components/TrainingAssignments";
import { GroupEditor } from "./Groups/GroupEditor";
import { useHistory } from "@/common";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { CardBlock } from "../../components/CardBlock";

type EditGroupProps = {
    groupId?: number;
    groupTypeId?: number;
    template?: string;
};

export const EditGroup = ({ groupId, groupTypeId }: EditGroupProps): JSX.Element => {
    const api = createApi();
    const { t } = useTranslate();
    const groupTypes = useOrganisationContext().groupTypes;
    const history = useHistory();

    const [groups, setGroups] = useState<Group[]>(null!);

    useEffect(() => {
        api.groups.query(null, null, null, null).then(
            result => {
                const filteredGroups = result
                    .filter(g => g.status === "Active" || g.status === "Inactive")
                    .filter(g => g.groupTypeId === groupTypeId);

                setGroups(filteredGroups);
            },
            () => showToast(t("COMMON_DEFAULT_ERROR"), "error")
        );
    }, [groupTypeId, t]);

    const currentGroup = groups?.find(d => d.id === groupId);
    const subGroups = groups?.filter(s => s.parentId === groupId);

    const breadCrumbs = [
        { name: "Groups", path: `/account-owner/edit/organisation-planning/group-type/${groupTypeId}` },
        {
            name: currentGroup?.name,
            path: `/account-owner/edit/organisation-planning/group-type/${groupTypeId}/group/${currentGroup?.id}`,
            dropdownItems: subGroups?.map(c => {
                return {
                    name: c.name,
                    path: `/account-owner/edit/organisation-planning/group-type/${groupTypeId}/group/${c.id}`,
                };
            }),
        },
    ];

    const groupTypeName = groupTypeId ? groupTypes?.find(t => t.id === groupTypeId)?.name : "";

    const showGroupLeaderView = () => {
        history.push(`/groups/${currentGroup!.id!}`);
    };

    return (
        <>
            <Breadcrumbs
                //@ts-ignore
                breadCrumb={breadCrumbs}
                className="organisation-breadcrumbs"
            />
            <CardBlock mb="xl">
                <div className="d-flex justify-content-between">
                    <PageHeading
                        contentLabel={t("ORGANISATION_EDIT_GROUP", { groupTypeName: groupTypeName })}
                        className="account-settings"
                    />
                    <PrimaryButton onClick={() => showGroupLeaderView()}>Show Group Leader View</PrimaryButton>
                </div>
                <GroupEditor group={currentGroup} groups={groups} />
            </CardBlock>
            <CardBlock mb="xl">
                <PageHeading
                    contentLabel={t("ORGANISATION_SUB_GROUPS", { groupTypeName })}
                    className="Instructional text about department management"
                />
                <GroupsListing
                    groupId={groupId}
                    groups={groups}
                    link="/account-owner/edit/organisation-planning"
                    buttonTitle={t("COMMON_EDIT")}
                    showTableHeaders
                    groupTypeId={groupTypeId}
                />
            </CardBlock>
            <CardBlock mb="xl">
                <GroupMembersList
                    //@ts-ignore
                    groupId={groupId}
                />
                {/* <AddEmployees groups={groups} groupId={groupId} placeholder={t('RESOURCES_GROUP_MEMBERS_ADD_EMPLOYEES_PLACEHOLDER')} /> */}
                <AddGroupEmployees groupId={groupId} className="mt-5" />
            </CardBlock>
            <CardBlock mb="xl">
                <PageHeading
                    contentLabel={"Training Assignments"}
                    className="Instructional text about department management"
                />

                <TrainingAssignments
                    //@ts-ignore
                    groupId={groupId}
                />
            </CardBlock>
        </>
    );
};
