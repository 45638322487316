import React from "react";
import "./sidebar.scss";

interface SidebarProps {
    class?: string;
    style?: string;
    children: React.ReactNode;
}

export const Sidebar = (props: SidebarProps): JSX.Element => {
    return <div className={`sidebar ${props.class}`}>{props.children}</div>;
};
