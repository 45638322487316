import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useAllGroups, useGroupTypes } from "../../../../common/OrganisationContext";
import { createApi } from "@/common";
import { useBoardContext } from "../../BoardContext";
import { showToast, PrimaryButton } from "@/components";
import { OptGroupTypes } from "../../../../components/GroupMembershipEditor/OptGroupsTypes";
import { ImprovementBoardTask, TaskCategory } from "@/api-client";

export const MoveCardPopup = (props: {
    show: boolean;
    hide: () => void;
    task: ImprovementBoardTask;
    hideTaskModal: () => void;
}) => {
    const wrapperRef = useRef(null);
    /**
     * Handle click outside
     */
    useEffect(() => {
        // @ts-ignore
        function handleClickOutside(event) {
            // @ts-ignore
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // @ts-ignore
                props.hide();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const groups = useAllGroups();

    const [categories, setCategories] = useState<TaskCategory[] | null>(null);

    const { t } = useTranslate();

    const api = createApi();

    const boardContext = useBoardContext();

    const groupTypes = useGroupTypes();

    const getCategoriesByGroupId = (selectedGroup: number) => {
        if (selectedGroup) {
            api.taskCategories.query(selectedGroup, null).then(
                response => {
                    setCategories(response);
                },
                error => {
                    showToast("Something going wrong. Please try again later.", "error");
                }
            );
        }
    };

    const initialValues: ImprovementBoardTask = {
        ...props.task,
        groupId: null!,
        taskCategoryId: null!,
        status: "New",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            groupId: yup.number().required(t("BOARD_MOVE_GROUP_TITLE")),
            status: yup.string().required("Please select status"),
        }),
        onSubmit: async values => {
            await moveCard(values);
        },

        // Important to make updates from ex SignalR not wiping form data when re-rendering
        enableReinitialize: false,
    });

    const moveCard = async (values: ImprovementBoardTask) => {
        const newTask = {
            ...values,
            groupId: values.groupId,
            taskCategoryId: values.taskCategoryId,
            status: values.status,
        };

        try {
            await boardContext.addOrUpdateTask(newTask, props.task);

            props.hide();
            props.hideTaskModal();
            showToast("Task moved successfully.", "success");
        } catch {
            showToast(t("COMMON_DEFAULT_ERROR"), "warning");
        }
    };

    return (
        <>
            {props.show ? (
                <div ref={wrapperRef} className="move-card-popup">
                    <Col className="heading-move-card p-3">
                        <h6>{t("BOARD_CARD_EDITOR_MOVE")}</h6>
                        <button
                            onClick={() => {
                                props.hide();
                                formik.resetForm({ values: initialValues });
                            }}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                        />
                    </Col>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row className="p-3">
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("ACCOUNTOWNER_TRAINING_PROGRESS_GROUP")}>
                                        <OptGroupTypes
                                            displayGroupsWithoutType
                                            groups={groups!}
                                            name="groupId"
                                            value={formik.values.groupId}
                                            class="form-control"
                                            optionTitle={t("BOARD_MOVE_GROUP_TITLE")}
                                            onChangeHandler={(value: number) => {
                                                getCategoriesByGroupId(value);
                                                formik.setFieldValue("groupId", value);
                                            }}
                                            groupTypes={groupTypes!}
                                            isInvalid={formik.touched.groupId && formik.errors.groupId !== undefined}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TASK_CATEGORY")}>
                                        <Form.Select name="taskCategoryId" onChange={formik.handleChange}>
                                            <option value="null">{t("BOARDS_GENERAL")}</option>
                                            {categories &&
                                                categories.length > 0 &&
                                                categories.map(c => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TASK_STATUS")}>
                                        <Form.Select name="status" onChange={formik.handleChange}>
                                            <option value="New">{t("BOARD_TASK_STATUS_New")}</option>
                                            <option value="Todo">{t("BOARD_TASK_STATUS_Todo")}</option>
                                            <option value="Active">{t("BOARD_TASK_STATUS_Active")}</option>
                                            <option value="Completed">{t("BOARD_TASK_STATUS_Completed")}</option>
                                            <option value="Archived">{t("BOARD_TASK_STATUS_Archived")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Col className="footer-move-card" />
                        <Row className="p-3">
                            <Col className="col-sm-12 text-end">
                                <PrimaryButton
                                    type="submit"
                                    eventName={{ object: "improvementboard", action: "movetasktootherboard" }}
                                >
                                    {t("BOARD_MOVE")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Form>
                </div>
            ) : null}
        </>
    );
};
