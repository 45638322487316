﻿import { matchPath, useLocation } from "react-router-dom";

export const useMatchRoute = () => {
    const location = useLocation();
    const matchRoute = (path: string, end?: boolean) => {
        return (
            matchPath(
                {
                    path: path,
                    end: end === undefined ? false : end,
                },
                location.pathname
            ) !== null
        );
    };

    return { matchRoute };
};
