import { useCallback, useEffect, useRef } from "react";
import type { InternalLoadedBoardContextState } from "./types";
import { useSignalR } from "../../common/signalr";
import { applyEntityEvents } from "../../common/signalr/stateUtils";
import { OpExEvent } from "@/api-client";

export const useImprovementBoardTaskSignalRListener = (
    loadedDispatch: (updater: (s: InternalLoadedBoardContextState) => InternalLoadedBoardContextState) => void
) => {
    const signalr = useSignalR();

    const eventsToHandle = useRef<OpExEvent[]>([]);
    const eventsToHandleTimeout = useRef<NodeJS.Timeout>();

    const handleEvents = () => {
        if (eventsToHandle.current.length === 0) {
            return;
        }

        loadedDispatch(s => ({
            ...s,
            tasks: applyEntityEvents(
                eventsToHandle.current.filter(x => x.entityType === "ImprovementBoardTask"),
                s.tasks
            ),
        }));

        eventsToHandle.current = [];
    };

    const handleEntityEvent = useCallback((data: OpExEvent) => {
        eventsToHandle.current.push(data);

        // Set a timeout to collect events in 500 ms when first event is received before applying to avoid flicker
        if (!eventsToHandleTimeout.current) {
            eventsToHandleTimeout.current = setTimeout(() => {
                handleEvents();
                eventsToHandleTimeout.current = undefined;
            }, 500);
        }
    }, []);

    useEffect(() => {
        if (signalr) {
            signalr.on("EntityEvent", handleEntityEvent);

            return () => {
                signalr.off("EntityEvent", handleEntityEvent);
            };
        }
    }, [signalr]);
};
