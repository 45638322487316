import { FunctionComponent } from "react";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";

export const TermsPage: FunctionComponent = (): JSX.Element => {
    const { t } = useTranslate();
    return (
        <div className="offset-md-1 col-md-10 offset-md-1 col-sm-12">
            <div className="welcome-block staticPage">
                <ReactMarkdown className="markdown-editor">{t("FOOTER_TERMS_CONTENT")}</ReactMarkdown>
            </div>
        </div>
    );
};
