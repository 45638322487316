import _ from "lodash";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { UrlFactory, useCurrentUser } from "@/common";
import { useAllGroups, useDemographicData, useDemographicQuestions } from "../../common/OrganisationContext";
import { UserHasAccountOwnerRole, UserHasSpecificRole, UserHasSysAdminRole } from "../../common/user/utils";
import { AppLoader } from "@/components";
import { AGENT_ROLE, EXPERT_ROLE } from "../../constants";
import { getCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";
import { useAuth } from "../../auth/useAuth";
import { getLatestTeamOrProgramSelectorRoute } from "./getLatestTeamOrProgramSelectorRoute";
import { Team, User } from "@/api-client";

const HomePage = () => {
    const currentUser = useCurrentUser();
    const allGroups = useAllGroups();
    const demographicQuestions = useDemographicQuestions();
    const demographicData = useDemographicData();
    const { isImpersonating } = useAuth();

    const hasAnsweredDemographicQuestions = useMemo(() => {
        if (demographicData === undefined || demographicQuestions === undefined) {
            return undefined;
        }

        const usersDemographicAnswers = demographicData.filter(dd => dd.userId === currentUser.id);

        // Check that all questions have an answer
        const allQuestionsHaveAnswers = _.every(
            demographicQuestions.filter(dq => dq.required),
            dq => usersDemographicAnswers.find(a => a.demographicQuestionId === dq.id) !== undefined
        );

        // TODO: Check if user has completed all questions by ID to detect changes, non requried etc
        return allQuestionsHaveAnswers;
    }, [currentUser, demographicData, demographicQuestions]);

    const getAccountOwnerRoute = (allGroups: Team[], user: User): string => {
        const isTeamMember = allGroups.any(x => x.members.any(x => x.userId === user.id));

        if (!isTeamMember) {
            return UrlFactory.accountOwner.newHome.create({});
        }

        return getLatestTeamOrProgramSelectorRoute(getCourseGroupInLocalStorage(), allGroups, currentUser.id);
    };

    const getUserRoute = (user: User, allTeams: Team[], hasAnsweredAllDemographicQuestions: boolean): string => {
        const checkPrerequisites = !isImpersonating();

        if (checkPrerequisites) {
            const isProfileCompleted = user.firstName !== null && user.lastName !== null;

            if (!isProfileCompleted) {
                return "/complete-profile";
            }

            if (!hasAnsweredAllDemographicQuestions) {
                return "/demographic";
            }
        }

        if (UserHasAccountOwnerRole(user)) {
            return getAccountOwnerRoute(allTeams, currentUser);
        }

        return getLatestTeamOrProgramSelectorRoute(getCourseGroupInLocalStorage(), allTeams, currentUser.id);
    };

    const getNavigationUrl = () => {
        const isSysadmin =
            UserHasSysAdminRole(currentUser) ||
            UserHasSpecificRole(currentUser, EXPERT_ROLE) ||
            UserHasSpecificRole(currentUser, AGENT_ROLE);

        if (isSysadmin) {
            return UrlFactory.sysadmin.home.create({});
        }

        if (hasAnsweredDemographicQuestions === undefined || allGroups === undefined) {
            return undefined;
        }

        return getUserRoute(currentUser, allGroups, hasAnsweredDemographicQuestions);
    };

    const navigationUrl = getNavigationUrl();

    return navigationUrl === undefined ? <AppLoader loading /> : <Navigate to={navigationUrl} />;
};

export default HomePage;
