import { useEffect, useState } from "react";
import { createApi } from "@/common";
import { DemographicQuestion } from "@/api-client";
import { DemographicQuestionsList } from "./DemographicQuestionsList";

type DemographicQuestionsPageState = {
    isLoading: boolean;
    questions: DemographicQuestion[] | null;
};

export const DemographicQuestionsPage = () => {
    const api = createApi();
    const [state, setState] = useState<DemographicQuestionsPageState>({ isLoading: true, questions: null });

    const getDemographicQuestions = () => {
        setState(s => ({
            ...s,
            isLoading: true,
        }));
        api.demographicQuestions
            .query(null)
            .then(result => {
                setState({
                    isLoading: false,
                    questions: result,
                });
            })
            .catchWithToast();
    };

    useEffect(() => {
        getDemographicQuestions();
    }, []);

    return <DemographicQuestionsList items={state.questions} onQuestionDeleted={getDemographicQuestions} />;
};
