﻿import React, { forwardRef, useRef } from "react";
import { createStyles, DefaultProps, MantineColor, MantineNumberSize, useComponentDefaultProps } from "@mantine/styles";
import { createEventHandler, createPolymorphicComponent } from "@mantine/utils";
// @ts-ignore
import { useMenuContext } from "@mantine/core/esm/Menu/Menu.context";
import { Box } from "@mantine/core";

/* Fork of https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Menu/MenuItem/MenuItem.tsx */

interface MenuItemStylesParams {
    color: MantineColor;
    radius: MantineNumberSize;
}

const useStyles = createStyles((theme, { color, radius }: MenuItemStylesParams) => ({
    item: {
        ...theme.fn.fontStyles(),
        WebkitTapHighlightColor: "transparent",
        fontSize: theme.fontSizes.sm,
        border: 0,
        backgroundColor: "transparent",
        outline: 0,
        width: "100%",
        textAlign: "left",
        textDecoration: "none",
        boxSizing: "border-box",
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px 0 ${theme.spacing.sm}px`,
        cursor: "pointer",
        borderRadius: theme.fn.radius(radius),
        color: color
            ? theme.fn.variant({ variant: "filled", primaryFallback: false, color }).background
            : theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.black,
        display: "flex",
        alignItems: "center",
        "&:last-of-type": {
            paddingBottom: `${theme.spacing.xs}px`,
        },
    },

    itemLabel: {
        flex: 1,
    },

    itemIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing.xs,
    },

    itemRightSection: {
        display: "flex",
        flexDirection: "row",
    },
}));

export interface MenuItemProps extends DefaultProps {
    /** Item label */
    children?: React.ReactNode;

    /** Key of theme.colors */
    color?: MantineColor;

    /** Determines whether menu should be closed when item is clicked, overrides closeOnItemClick prop on Menu component */
    closeMenuOnClick?: boolean;

    /** Icon rendered on the left side of the label */
    icon?: React.ReactNode;

    /** Section rendered on the right side of the label */
    rightSection?: React.ReactNode;
}

const defaultProps: Partial<DefaultProps> = {};

export const _MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>((props, ref) => {
    const { children, className, color, closeMenuOnClick, icon, rightSection, ...others } = useComponentDefaultProps(
        "MenuItem",
        defaultProps,
        props
    );
    const ctx = useMenuContext();
    const { classes, cx, theme } = useStyles(
        // @ts-ignore
        { radius: ctx.radius, color },
        { name: "Menu", classNames: ctx.classNames, styles: ctx.styles, unstyled: ctx.unstyled }
    );
    const itemRef = useRef<HTMLButtonElement>();

    const itemIndex = ctx.getItemIndex(itemRef.current);
    const _others: any = others;

    const handleFocus = createEventHandler(_others.onFocus, () => ctx.setHovered(ctx.getItemIndex(itemRef.current)));

    return (
        <Box
            component="div"
            {...others}
            tabIndex={-1}
            onFocus={handleFocus}
            className={cx(classes.item, className)}
            role="menuitem"
            data-menu-item
            data-hovered={ctx.hovered === itemIndex ? true : undefined}
        >
            {icon && <div className={classes.itemIcon}>{icon}</div>}
            {children && <div className={classes.itemLabel}>{children}</div>}
            {rightSection && <div className={classes.itemRightSection}>{rightSection}</div>}
        </Box>
    );
});

_MenuItem.displayName = "@mantine/core/MenuItem";

export const MenuItem = createPolymorphicComponent<"button", MenuItemProps>(_MenuItem);
