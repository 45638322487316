import { Answer, Chapter, Group, Module, User } from "@/api-client";

export const average = (arr: number[]) => arr.reduce((p, c) => p + c, 0) / arr.length;

export const formatNumber = (value: number) => (isNaN(value) ? "-" : value.toFixed(2));

export interface ModuleData {
    chapterId: number;
    moduleId: number;
    order: number;
    me: number[];
    group: number[];
    company: number[];
}

/**
 * Get module summary
 * @param module
 * @param currentUser
 * @param currentGroup
 * @param answers
 * @returns
 */
export const getModuleData = (
    module: Module,
    currentUser: User,
    currentGroup: Group,
    answers: Answer[]
): ModuleData => {
    //module.exercise.questions.map(q => q.id);
    // @ts-ignore
    const questionsInModule = module.blocks
        .flatMap(b => b.questions)
        .filter(q => q !== null && q !== undefined)
        .map(q => q.id);
    var moduleAnswers = answers.filter(a => questionsInModule.indexOf(a.questionId) !== -1);
    // @ts-ignore
    var usersInCurrentGroup = currentGroup.members.map(m => m.userId);

    return {
        chapterId: module.chapterId,
        // @ts-ignore

        moduleId: module.id,
        // @ts-ignore

        order: module.order,

        me: moduleAnswers
            .filter(ma => ma.userId === currentUser.id && ma.groupId === currentGroup.id)
            // @ts-ignore
            .map(x => parseInt(x.text)),

        group: moduleAnswers
            // @ts-ignore
            .filter(ma => usersInCurrentGroup.indexOf(ma.userId) !== -1 && ma.groupId === currentGroup.id)
            // @ts-ignore
            .map(x => parseInt(x.text)),

        // @ts-ignore
        company: moduleAnswers.map(x => parseInt(x.text)),
    };
};

export interface ChapterData {
    chapterId: number;
    name: string;
    order: number;
    moduleIds: number[];
    me: number[];
    group: number[];
    company: number[];
    meAvg: number;
    groupAvg: number;
    companyAvg: number;
}

/**
 * Summary per chapter
 * @param chapters
 * @param currentUser
 * @param currentGroup
 * @param answers
 * @returns
 */
export const getChapterData = (
    chapters: Chapter[],
    currentUser: User,
    currentGroup: Group,
    answers: Answer[]
): ChapterData[] => {
    // @ts-ignore
    return chapters.map(c => {
        const moduleAnswers = c.modules
            // @ts-ignore

            .sort(m => m.order)
            .flatMap(m => getModuleData(m, currentUser, currentGroup, answers));
        return {
            chapterId: c.id,
            name: c.name,
            order: c.order,
            moduleIds: moduleAnswers.map(ma => ma.moduleId),
            me: moduleAnswers.map(ma => average(ma.me)),
            group: moduleAnswers.map(ma => average(ma.group)),
            company: moduleAnswers.map(ma => average(ma.company)),
            meAvg: average(moduleAnswers.flatMap(ma => ma.me)),
            groupAvg: average(moduleAnswers.flatMap(ma => ma.group)),
            companyAvg: average(moduleAnswers.flatMap(ma => ma.company)),
        };
    });
};

export interface OptionsData {
    optionId: number;
    me: number;
    group: number;
    company: number;
}

export const getQuestionData = (
    module: Module,
    currentUser: User,
    currentGroup: Group,
    answers: Answer[]
): OptionsData[] => {
    //module.exercise.questions.flatMap(q => q.options);
    // @ts-ignore
    const options = module.blocks
        .flatMap(b => b.questions)
        .filter(q => q !== null && q !== undefined)
        .flatMap(q => q.options);
    // @ts-ignore

    const questionsIdsInModule = module.blocks
        .flatMap(b => b.questions)
        .filter(q => q !== null && q !== undefined)
        .map(q => q.id);
    // @ts-ignore

    var usersInCurrentGroup = currentGroup.members.map(m => m.userId);

    var data = answers.filter(a => questionsIdsInModule.indexOf(a.questionId) !== -1);

    // @ts-ignore
    return options.map(opt => ({
        // @ts-ignore

        optionId: opt.id,

        me: average(
            data
                .filter(
                    x => x.optionAnswerId === opt.id && x.userId === currentUser.id && x.groupId === currentGroup.id
                )
                // @ts-ignore
                .map(x => parseInt(x.text))
        ),

        group: average(
            data
                .filter(
                    x =>
                        x.optionAnswerId === opt.id &&
                        // @ts-ignore
                        usersInCurrentGroup.indexOf(x.userId) !== -1 &&
                        x.groupId === currentGroup.id
                )
                // @ts-ignore
                .map(x => parseInt(x.text))
        ),

        // @ts-ignore

        company: average(data.filter(x => x.optionAnswerId === opt.id).map(x => parseInt(x.text))),
    }));
};
