import * as msal from "@azure/msal-browser";
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import * as React from "react";
import { Navigate } from "react-router-dom";
import Constants from "../constants";
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from "@azure/msal-react";
import { logger } from "./Logger";
import { LoginResponse } from "@/api-client";
import { useAuth } from "../auth/useAuth";

export type MsalConfig = {
    clientId: string;
    authority: string;
    resetPassword: {
        name: string;
        authority: string;
    };
    knownAuthorities: string[];
    redirectUri: string;
    loginScopes: string[];
    accessScopes: string[];
};

export const B2CAuthRoot = (props: { children: React.ReactElement }) => {
    const [instance, setInstance] = React.useState<msal.PublicClientApplication | null>(null);
    const [settings, setSettings] = React.useState<MsalConfig | null>(null);

    React.useEffect(() => {
        const work = async () => {
            const response = await fetch(`${Constants.NEW_API_URL}/api/configuration`, { mode: "cors" });

            if (!response.ok) {
                throw Error("Couldn't fetch MSAL config");
            }

            const msalSettings = (await response.json()) as MsalConfig;

            const msalConfig = {
                auth: {
                    clientId: msalSettings.clientId,
                    authority: msalSettings.authority,
                    knownAuthorities: msalSettings.knownAuthorities,
                    redirectUri: msalSettings.redirectUri,
                    navigateToLoginRequestUrl: false,
                },
            };

            const msalInstance = new msal.PublicClientApplication(msalConfig);
            setSettings(msalSettings);
            setInstance(msalInstance);
        };
        work();
    }, []);

    if (instance === null) {
        return null;
    }

    return (
        <MsalProvider instance={instance}>
            <MsalEventHandlers
                // @ts-ignore

                msalConfig={settings}
            >
                {props.children}
            </MsalEventHandlers>
        </MsalProvider>
    );
};

type B2CContextProps = {
    getAccessToken: () => Promise<string>;
    msalSettings: MsalConfig;
};

const B2CContext = React.createContext<B2CContextProps>({
    getAccessToken: async () => {
        throw new Error("User is not logged in");
    },
    // @ts-ignore
    msalSettings: undefined,
});

const MsalEventHandlers = (props: { msalConfig: MsalConfig; children: React.ReactElement }) => {
    const { instance, accounts, inProgress } = useMsal();
    const [userLoginFinished, setUserLoginFinished] = React.useState(false);
    const { signIn } = useAuth();

    const account = useAccount(accounts[0] || {});

    const fetchAndStoreUser = async (token: string) => {
        const response = await fetch(`${Constants.NEW_API_URL}/api/login/UserProfile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        });

        const loginResponse = (await response.json()) as LoginResponse;
        if (loginResponse.resultCode === "Ok") {
            await signIn(loginResponse.token!);
        }
    };

    React.useEffect(() => {
        // @ts-ignore
        const callbackId = instance.addEventCallback(async event => {
            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (event?.payload) {
                    /*
                     * Also see OWASP on password reset flows:
                     *
                     * Once they have set their new password, the user should then login through the usual mechanism.
                     * Don't automatically log the user in, as this introduces additional complexity to the
                     * authentication and session handling code, and increases the likelihood of introducing vulnerabilities.
                     *
                     * https://cheatsheetseries.owasp.org/cheatsheets/Forgot_Password_Cheat_Sheet.html#user-resets-password
                     * */
                    let isForgotPassword =
                        event.payload.idTokenClaims["isForgotPassword"] !== undefined
                            ? event.payload.idTokenClaims["isForgotPassword"]
                            : false;

                    logger.info("Login success");
                    logger.info("isForgotPassword claim: ", isForgotPassword);
                    if (isForgotPassword) {
                        logger.info("Logging out to make sure user can login with new password", isForgotPassword);
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    const getAccessToken = async () => {
        const tokenRequest = {
            scopes: props.msalConfig.accessScopes,
            forceRefresh: false,
            account: account,
        };

        try {
            logger.info("Acquiring token silently");
            // @ts-ignore

            const authenticationResult = await instance.acquireTokenSilent(tokenRequest);
            logger.info("Acquired access token");
            return authenticationResult.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                logger.info("Failed token acquisition, redirecting");
                // @ts-ignore

                return instance.acquireTokenRedirect(tokenRequest);
            }
            logger.error("Acquire token silent failed and error is not 'InteractionRequiredAuthError'", error);
            throw error;
        }
    };

    React.useEffect(() => {
        const work = async () => {
            /*
             * Once the msal status has reached it's end state (None) and an account has been selected
             * a user can be logged in and redirected.
             */
            if (account && inProgress === InteractionStatus.None) {
                const token = await getAccessToken();
                await fetchAndStoreUser(token as string);
                setUserLoginFinished(true);
            }
        };

        work();
    }, [account, inProgress, instance]);

    if (!userLoginFinished) {
        return (
            <B2CContext.Provider value={{ getAccessToken: getAccessToken as any, msalSettings: props.msalConfig }}>
                {props.children}
            </B2CContext.Provider>
        );
    }

    return (
        <B2CContext.Provider value={{ getAccessToken: getAccessToken as any, msalSettings: props.msalConfig }}>
            <Navigate to="/sysadmin" />
        </B2CContext.Provider>
    );
};

export const EnsureB2CAuthenticated = (props: { children: React.ReactElement }) => {
    const b2cContext = React.useContext(B2CContext);

    const authRequest = {
        scopes: b2cContext.msalSettings.accessScopes,
        forceRefresh: false,
    };

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            {props.children}
        </MsalAuthenticationTemplate>
    );
};

export const B2CAuthPlaceHolder = () => {
    return (
        <B2CAuthRoot>
            <EnsureB2CAuthenticated>
                <>Logged in</>
            </EnsureB2CAuthenticated>
        </B2CAuthRoot>
    );
};
