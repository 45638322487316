import { useTranslate } from "@tolgee/react";
import { TaskVisibility } from "@/api-client";

export const useTranslatedTaskVisibilityItems = () => {
    const { t } = useTranslate();

    const taskVisibilityTranslation: {
        [key: string]: string;
    } = {
        All: t("TEAM_TASK_VISIBILITY.All"),
        OnlyMembers: t("TEAM_TASK_VISIBILITY.OnlyMembers"),
    };

    const translateTaskVisibility = (taskVisibility: TaskVisibility) => taskVisibilityTranslation[taskVisibility];

    return [
        {
            label: translateTaskVisibility("All"),
            value: "All" as TaskVisibility,
        },
        {
            label: translateTaskVisibility("OnlyMembers"),
            value: "OnlyMembers" as TaskVisibility,
        },
    ];
};
