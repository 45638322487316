import { User } from "@/api-client";
import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Link, useLocation } from "react-router-dom";
import { useCurrentClient, useCurrentUser } from "../../common/user/context";
import { UserHasSysAdminRole } from "../../common/user/utils";
import UserAvatar from "../../components/UserAvatar";
import "./style.scss";
import { useAuth } from "../../auth/useAuth";
import { useAccessToSysadmin } from "../../sysadmin/useAccessToSysadmin";

interface MainNavbarProps {
    users?: User[];
}

function MainNavbar({ users }: MainNavbarProps) {
    const { t } = useTranslate();
    const location = useLocation();

    const currentClient = useCurrentClient();

    const currentUser = useCurrentUser();

    const { signOut, isImpersonating } = useAuth();

    const accessToSysadmin = useAccessToSysadmin();

    const handleLogout = () => {
        signOut();
    };

    return (
        <>
            {currentUser && (
                <Navbar bg="light" expand="lg" fixed="top" id="main-navbar">
                    <Container>
                        <Navbar.Brand href="/account-owner">
                            {currentClient && currentClient.logo && (
                                <img src={currentClient?.logo} alt="Logo" className="main-logo" />
                            )}
                            <span className="title d-md-none">{currentClient?.name} </span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="d-flex-md justify-content-center">
                            <Nav>
                                <Nav.Link as={Link} to="/account-owner" active={location.pathname === "/account-owner"}>
                                    {t("HEADER_NAV_HOME")}
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to={`/account-owner/edit/organisation-planning/organisation`}
                                    active={location.pathname.startsWith("/account-owner/edit/organisation-planning")}
                                >
                                    {t("HEADER_NAV_ORGANISATION")}
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/account-owner/planning"
                                    active={
                                        location.pathname.startsWith("/account-owner/planning") ||
                                        location.pathname.startsWith("/account-owner/resources/")
                                    }
                                >
                                    {t("HEADER_NAV_PLANNING")}
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="d-none d-md-inline-block">
                            <UserAvatar displayName={false} user={currentUser} />
                        </div>
                        <NavDropdown
                            title={`${currentUser.firstName} ${currentUser.lastName}`}
                            id="collasible-nav-dropdown"
                            className="p-0 ms-0 d-none d-md-block"
                            style={{ width: "176px" }}
                        >
                            <NavDropdown.Item as={Link} to={"/profile"}>
                                {t("HEADER_NAV_EDIT_PROFILE")}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {(UserHasSysAdminRole(currentUser) || isImpersonating()) && (
                                <React.Fragment>
                                    <NavDropdown.Item onClick={accessToSysadmin}>
                                        {t("HEADER_NAV_SYSADMIN")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </React.Fragment>
                            )}

                            <NavDropdown.Item onClick={() => handleLogout()}>{t("HEADER_NAV_LOGOUT")}</NavDropdown.Item>
                        </NavDropdown>
                    </Container>
                </Navbar>
            )}
        </>
    );
}

export default MainNavbar;
