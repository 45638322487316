﻿import { useCourseGroupUserOnboardingProgress, useCurrentCourse, useCurrentGroup } from "../common/courses/context";
import { useEffect, useState } from "react";
import { useCurrentClient, useCurrentUser } from "../common/user/context";
import { UserHasGroupLeaderRole, UserHasParticipantRole, UserHasSpecificRole } from "../common/user/utils";
import { ACCOUNT_OWNER_ROLE, SYSADMIN_ROLE } from "../constants";
import { onBoardVideos } from "../pages/Onboard/helper";
import { useAuth } from "../auth/useAuth";

/*  eslint-disable react-hooks/exhaustive-deps */

export const useOnboardingVideos = (): string | undefined => {
    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();
    const [onBoardingUrl, setOnBoardingUrl] = useState(undefined);
    const currentUser = useCurrentUser();
    const onboardProgress = useCourseGroupUserOnboardingProgress();
    const currentClient = useCurrentClient();
    const { isImpersonating } = useAuth();

    useEffect(() => {
        if (
            currentCourse != null &&
            onboardProgress !== undefined &&
            currentClient &&
            currentUser &&
            !UserHasSpecificRole(currentUser, SYSADMIN_ROLE) &&
            !isImpersonating()
        ) {
            // @ts-ignore
            const isParticipant = UserHasParticipantRole(currentUser, currentGroup);
            // @ts-ignore

            const isGroupLeader = UserHasGroupLeaderRole(currentUser, currentGroup);
            const isManager = UserHasSpecificRole(currentUser, ACCOUNT_OWNER_ROLE);
            const myOnBoardVideos = onBoardVideos(
                currentCourse,
                currentClient,
                isParticipant,
                isGroupLeader,
                isManager
            );

            if (
                !onboardProgress.completed &&
                currentCourse.id === onboardProgress.courseId &&
                myOnBoardVideos &&
                myOnBoardVideos.length > 0
            ) {
                // @ts-ignore
                setOnBoardingUrl(`/groups/${currentGroup.id}/onboarding/courses/${currentCourse.id}/videos/1`);
                return;
            }
        }

        setOnBoardingUrl(undefined);
    }, [currentCourse, onboardProgress, currentClient, currentUser, currentGroup]);

    return onBoardingUrl;
};
