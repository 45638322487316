import { User } from "@/api-client";
import { getColorByIndexRepeatingSeq } from "../../common/colors";

export const getInitials = (name: string | undefined): string | boolean => {
    if (name) {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    return false;
};

interface UserAvatarProps {
    user: User | undefined;
    displayName?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

const UserAvatar = ({ user, displayName, className, style }: UserAvatarProps): JSX.Element => {
    if (user === undefined || user === null) {
        return <></>;
    }

    // @ts-ignore
    const color = getColorByIndexRepeatingSeq(user.id, "dark");
    const colorCode = color.hex;
    const textColor = color.textColorHex;
    const letters = getInitials(user?.fullName);
    return (
        <div style={style} className={`d-flex align-items-center ${className}`}>
            {user?.image ? (
                <img alt={user?.fullName} className="user-image me-2" src={user?.image} />
            ) : (
                <div className="user-image me-2" style={{ background: colorCode }}>
                    <span style={{ color: textColor }}>{letters}</span>
                </div>
            )}
            {(displayName || displayName === undefined) && <div className="user-name">{user?.fullName}</div>}
        </div>
    );
};

export default UserAvatar;
