import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import { useAllUsers } from "../../../common/OrganisationContext";
import Footer from "../../../components/Footer/footer";
import MainNavbar from "../../MainNavbar";

interface ResourcesLayoutProps {
    courseId?: number;
    children: ReactNode;
}

/**
 * This layout is used as container around resources pages to provide common data
 * @param param0
 */
const ResourcesLayout = ({ courseId, children }: ResourcesLayoutProps): JSX.Element => {
    const users = useAllUsers();

    return (
        <Container fluid={true} id="layout-container-resources">
            <MainNavbar users={users} />
            <Container id="layout-container">
                <div id="wrapper">{children}</div>
            </Container>
            <Footer />
        </Container>
    );
};

export default ResourcesLayout;
