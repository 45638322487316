import React from "react";
import { ListGroup } from "react-bootstrap";
import { Question } from "@/api-client";

type SingleChoiceQuestionProps = {
    question: Question;
    placeholder: string;
};

export const SingleChoiceQuestions = React.memo(function SingleChoiceQuestion(props: SingleChoiceQuestionProps) {
    const { question, placeholder } = props;
    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        {question && (
                            <React.Fragment>
                                <p>{question.text}</p>
                                <p>{question.description}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 text-secondary">
                    <div className="block-content-option ratingData w-100 align-items-center">
                        {question && (
                            <div className="block-content-option">
                                <p className="px-5 question_type"></p>
                                <ListGroup>
                                    {question.options &&
                                        question.options.map((o, index) => {
                                            return (
                                                <ListGroup.Item
                                                    id={`question-${index}-${question?.questionTypeId}-questions`}
                                                    key={`question_${index}_${question?.id}`}
                                                    className={"inactive p-1 border mb-2"}
                                                >
                                                    <div className="rasting-value">
                                                        <label className="m-0 d-block" style={{ top: "5px" }}>
                                                            <input
                                                                type="radio"
                                                                className="me-1"
                                                                id={`questions.question-${question?.id}.value`}
                                                                name={`questions.question-${question?.id}.value`}
                                                                disabled={true}
                                                            />
                                                            <span
                                                                className="mb-1 position-absolute"
                                                                style={{ top: "4px" }}
                                                            >
                                                                {o.text}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
