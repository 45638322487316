import { Course } from "@/api-client";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { ContentLabel } from "../ContentLabel";
import "./style.scss";

export type NavbarProps = {
    courses: Course[];
    handleCourse: (courseId: number) => void;
    selectedCourse: number;
};

export const NavBar = (props: NavbarProps) => {
    const { t } = useTranslate();
    return (
        <ul className="account-owner-nav">
            <ContentLabel className="account-owner-heading-nav" text={t("COMMON_COURSES")} />
            {props.courses &&
                props.courses.map(n => (
                    <li
                        className={`${props.selectedCourse === n.id ? "active-li" : ""}`}
                        onClick={() =>
                            // @ts-ignore

                            props.handleCourse(n.id)
                        }
                        key={n.id}
                    >
                        <Link className={`nave ${props.selectedCourse === n.id ? "active" : ""}`} to={`#`}>
                            {n.name}
                        </Link>
                    </li>
                ))}
        </ul>
    );
};
