import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { AddUserMultiSelect } from "./AddUserMultiSelect";
import { exhaustiveCheck } from "../../../../common/exhaustiveCheck";
import { UsernameType } from "../../../../api/clients/UsernameDto";

type Email = {
    type: "email";
    value: string;
};

type MobilePhone = {
    type: "mobilePhone";
    value: string;
};

export type Username = Email | MobilePhone;

type AdduserMultiSelectInputProps = {
    fieldName: string;
    label: string;
    existingUsers: Username[];
    placeholder?: string;
    showExistingUsers?: boolean;
};

export const AddUserMultiSelectInput = (props: AdduserMultiSelectInputProps) => {
    const [field, meta, helpers] = useField<string[]>(props.fieldName);
    const { t } = useTranslate();

    const placeholder = props.placeholder !== undefined ? props.placeholder : props.label;

    const getType = (username: Username): UsernameType => {
        switch (username.type) {
            case "email":
                return "email";
            case "mobilePhone":
                return "mobilePhone";
            default:
                return exhaustiveCheck(username);
        }
    };

    const data = props.existingUsers.map(username => ({
        value: username.value,
        isValid: true,
        label: username.value,
        isExisting: true,
        type: getType(username),
    }));

    return (
        <AddUserMultiSelect
            placeholder={t(placeholder)}
            label={t(props.label)}
            value={field.value}
            data={data}
            onChange={helpers.setValue}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
            onBlur={() => helpers.setTouched(true)}
            name={field.name}
            showSuggestions={props.showExistingUsers}
        />
    );
};
