import { ModuleBlock, Question } from "@/api-client";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { FreeTextQuestions } from "./FreeTextQuestions";
import { MatrixScaleQuestions } from "./MartrixScaleQuestions";
import { MatrixGridQuestions } from "./MatrixGridQuestions";
import { MultipleChoiceQuestions } from "./MultipleChoiceQuestions";
import { RatingQuestions } from "./RatingQuestions";
import { SingleChoiceQuestions } from "./SingleChoice";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";

interface AllQuestionsProps {
    questions: Question[];
    moduleId: number;
    chapterId: number;
    courseId: number;
    onClick: () => void;
    nextModuleBlock: ModuleBlock;
    moduleBlockId: number;
    moduleBlock: ModuleBlock;
    isLastModuleBlock: boolean;
}

export const AllQuestionsPreview = ({
    questions,
    onClick,
    moduleBlockId,
    moduleBlock,
    isLastModuleBlock,
}: AllQuestionsProps): JSX.Element => {
    const { t } = useTranslate();

    const renderQuestions = (typeId: number, question: Question, placeholder: string) => {
        switch (typeId) {
            case 1:
                return <FreeTextQuestions question={question} placeholder={placeholder} />;
            case 2:
                return <SingleChoiceQuestions question={question} placeholder={placeholder} />;
            case 3:
                return <MultipleChoiceQuestions question={question} placeholder={placeholder} />;
            case 4:
                return <RatingQuestions question={question} placeholder={placeholder} />;
            case 5:
                return <MatrixScaleQuestions question={question} placeholder={placeholder} />;
            case 6:
                return <MatrixGridQuestions question={question} placeholder={placeholder} />;
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {questions &&
                questions.map((q, i) => {
                    const placeholder = t("MODULE_QUESTION_NUMBERS", { min: i + 1, max: questions.length });
                    return (
                        <React.Fragment>
                            {renderQuestions(q.questionTypeId, q, placeholder)}
                            {!isLastModuleBlock && questions.length === i + 1 && moduleBlockId === moduleBlock.id && (
                                <div className="text-center nextButton">
                                    <PrimaryButton onClick={() => onClick()}>Next</PrimaryButton>
                                </div>
                            )}
                            {isLastModuleBlock && (
                                <div className="text-center nextButton">
                                    <PrimaryButton onClick={() => onClick()}>Next</PrimaryButton>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
        </React.Fragment>
    );
};
