import { RouteObject } from "react-router-dom";
import {
    PageProtectedProps,
    ProtectedPageRouteComponent,
    ProtectedRouteComponentWithOutlet,
} from "./ProtectedRouteComponent";
import { UserRoles } from "@/api-client";
import { PageRouteComponent } from "./PageRouteComponent";

export const route = (path: string, component: RouteObject["element"]): RouteObject => {
    return {
        path: path,
        element: component,
    };
};

export const indexRoute = (component: RouteObject["element"]): RouteObject => {
    return {
        index: true,
        element: component,
    };
};

export const protectedPageRoute = <Path extends string, TProps>(
    url: PageProtectedProps<Path, TProps>["url"],
    component: PageProtectedProps<Path, TProps>["component"],
    roles?: PageProtectedProps<Path, TProps>["roles"]
): RouteObject => {
    return {
        path: url.pattern,
        element: <ProtectedPageRouteComponent roles={roles} url={url} component={component} />,
    };
};

export const protectedRoute = (path: string, element: RouteObject["element"], roles: UserRoles[]): RouteObject => {
    return {
        element: <ProtectedRouteComponentWithOutlet roles={roles} />,
        children: [
            {
                path: path,
                element: element,
            },
        ],
    };
};

export const pageRoute = <Path extends string, TProps>(
    url: PageProtectedProps<Path, TProps>["url"],
    component: PageProtectedProps<Path, TProps>["component"]
): RouteObject => {
    return {
        path: url.pattern,
        element: <PageRouteComponent url={url} component={component} />,
    };
};
