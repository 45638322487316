import { GroupMember, User } from "@/api-client";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useOrganisationContext } from "../../../../common/OrganisationContext";
import { useCurrentUser } from "../../../../common/user/context";
import { AppLoader } from "../../../../components/Spinner";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import { setDragData } from "../../helper";
import "./style.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface EmployeesList {
    employees: GroupMember[] | null;
    courseId: number;
}

export const EmployeesListDisplay: FunctionComponent<EmployeesList> = (props: EmployeesList) => {
    const { t } = useTranslate();
    const accountOwnerContext = useOrganisationContext();
    const currentUser = useCurrentUser();
    const groupsInCourse = accountOwnerContext.groups;
    const userPending = useOrganisationContext().users === undefined;

    const allEmployess = accountOwnerContext.users;
    const [usersToDisplay, setUsersToDisplay] = useState<User[]>([]);
    const [filterShowOnlyUncategorized, setFilterShowOnlyUncategorized] = useState<boolean>(true);
    /* @ts-ignore */
    const [filterText, setFilterText] = useState<string>(undefined);

    useEffect(() => {
        refreshUsersToDisplay();
    }, [allEmployess, groupsInCourse, filterShowOnlyUncategorized, filterText]);

    const refreshUsersToDisplay = () => {
        if (allEmployess && currentUser) {
            setUsersToDisplay(
                allEmployess
                    .filter(e => e.id !== currentUser.id)
                    .filter(e => e.status === "Active")
                    .filter(x => {
                        let show = true;

                        // Filter based on form here
                        if (filterShowOnlyUncategorized) {
                            // Return false if this user is a member of any group
                            show = show && !groupsInCourse?.some(g => g.members?.some(gm => gm.userId === x.id));
                        }

                        // Filter based on form here
                        if (filterText && filterText !== "") {
                            show =
                                show &&
                                `${x.email}${x.firstName}${x.lastName}${x.mobilePhone}`
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase());
                        }

                        return show;
                    })
            );
        }
    };

    return (
        <div className="table-responsive w-100 employessList">
            <AppLoader loading={userPending} className={"position-absolute"} />
            <p className="content-label mt-3">{t("RESOURCES_ALL_EMPLOYEES")}</p>
            <p className="mt-1">{t("RESOURCES_EMPLOYEES_DRAG_TEXT")}</p>
            <Row className="mb-2">
                <Col>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={t("RESOURCES_FILTER_EMPLOYEES_PLACEHOLDER")}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </Col>
                <Col>
                    <div>
                        <StyledCheckbox
                            name="handleUncategrizedUsers"
                            onChange={checked => setFilterShowOnlyUncategorized(checked)}
                            checked={filterShowOnlyUncategorized}
                            label={t("RESOURCES_UNCATEGORIZED_EMPLOYEES")}
                        />
                    </div>
                </Col>
            </Row>
            {usersToDisplay && usersToDisplay.length > 0 && (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th data-field="email">
                                {t("RESOURCES_EMAIL")}/{t("MEMBERSHIP_MOBILE_PHONE")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersToDisplay &&
                            usersToDisplay.map((emp, i) => {
                                return (
                                    <tr
                                        key={i}
                                        draggable={true}
                                        // @ts-ignore

                                        onDragStart={e => setDragData(e.dataTransfer, emp.id, null)}
                                        onDragEnd={e => refreshUsersToDisplay()}
                                        className={`employeesData draggable drag-allowed`}
                                    >
                                        <td>{emp.email}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            )}

            {usersToDisplay.length === 0 && (
                <p className="alert alert-secondary">{t("RESOURCES_NO_EMPLOYEES_FOUND")}</p>
            )}
        </div>
    );
};
