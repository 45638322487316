import { Group } from "@/api-client";

/**
 * Filter the groups by course id
 * @param groups
 * @param courseId
 * @returns
 */
export const findGroupsByCourseId = (groups: Group[], courseId: number): Group[] => {
    if (!(groups && courseId)) {
        /* @ts-ignore */
        return;
    }

    return groups.filter(g => g.courseIds.includes(courseId));
};

/**
 * Count all users by status
 * @param groups
 * @param inviteStatus
 * @returns
 */
export const countUsersInGroupsByInviteStatus = (groups: Group[] | null, inviteStatus: string): number => {
    if (groups && groups.length > 0) {
        return groups.flatMap(g => g.members).filter(g => g.inviteStatus.toString() === inviteStatus.toString()).length;
    }
    return 0;
};

export interface UserAccessInfo {
    isParticipant?: boolean;
    isGroupLeader?: boolean;
}

/**
 * Get roles for user when assigning to group
 * @param allGroups
 * @param groupId
 * @param courseId
 * @param userId
 * @returns
 */
export const getGroupRolesBeforeAssigningUserToGroup = (
    allGroups: Group[],
    groupId: number,
    courseId: number,
    userId: number
): UserAccessInfo => {
    // Check if user can be participant in this group
    const canBeParicipant = !isParticipantInAnotherGroup(allGroups, groupId, userId);

    // Return access info. No user should ever be group leader automatically.
    return {
        isParticipant: canBeParicipant,
        isGroupLeader: false,
    };
};

/**
 * Disable user if member of another group
 * @param userGroups
 * @param groupId
 * @param userId
 * @returns
 */
export const isParticipantInAnotherGroup = (userGroups: Group[], groupId: number, userId: number) => {
    // Get participant status in all other groups
    const participantInOtherGroups = userGroups
        .filter(g => g.id === groupId)
        .flatMap(ug => ug.members.filter(m => m.userId === userId && m.isParticipant));
    //console.log(participantInOtherGroups,"Particiapfdfsdfsdf")

    // Return true if user is already participant somewhere
    return participantInOtherGroups.length > 0;
};

/**
 * True if user is already member in group
 * @param userGroups
 * @param groupId
 * @param userId
 * @returns
 */
export const isGroupMember = (userGroups: Group[], groupId: number, userId: number) => {
    return (
        userGroups.filter(g => g.id === groupId).flatMap(ug => ug.members.filter(m => m.userId === userId)).length > 0
    );
};

export interface DragData {
    userId: number;
    groupId?: number;
}

export const getDragData = (dataTransfer: DataTransfer): DragData => {
    return {
        userId: parseInt(dataTransfer.getData("userid")),
        /* @ts-ignore */
        groupId: dataTransfer.getData("groupid") ? parseInt(dataTransfer.getData("groupid")) : null,
    };
};

export const setDragData = (dataTransfer: DataTransfer, userId: number, groupId?: number): void => {
    dataTransfer.setData("userid", userId.toString());
    /* @ts-ignore */
    dataTransfer.setData("groupid", groupId?.toString());
};
