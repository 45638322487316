import React from "react";
import { Row } from "react-bootstrap";

interface HeadingProps {
    contentLabel: string;
    heading?: string;
    detailText?: string;
    className?: string;
}

export const PageHeading = (props: HeadingProps) => {
    return (
        <Row className={!props.className ? "p-2" : props.className}>
            <div className="col-md-12 heading">
                {props.contentLabel && <p className="content-label">{props.contentLabel}</p>}
                {props.heading && <h2>{props.heading}</h2>}
            </div>
            {props.detailText && (
                <div className="col-md-12 text-left sub-heading">
                    <p>{props.detailText}</p>
                </div>
            )}
        </Row>
    );
};

export default PageHeading;
