import { Group, GroupMembership } from "@/api-client";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../common/api";
import { ContentLabel } from "../../ContentLabel";
import RenderIf from "../../render-if/render-if";
import { AppLoader } from "../../Spinner";
import { AddMembership, MembershipTypeModel } from "./AddMembership";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { ActionIcon, Flex, Menu } from "@mantine/core";
import { Icons } from "../../../common/Icons";

/*  eslint-disable react-hooks/exhaustive-deps */

type EditUserProps = {
    userId: number;
    componentFor?: string;
    clientId: number;
};

type GroupsModel = {
    result: Group[];
    isPending: boolean;
    error: string;
};

type UserMembershipModel = {
    result: GroupMembership;
    isPending: boolean;
    error: string;
};

export const Membership = (props: EditUserProps) => {
    const { t } = useTranslate();
    const api = createApi();

    const [addGroupMembership, setGroupMembership] = useState<boolean>(false);
    const [userGroupMembership, setUserGroupMembership] = useState<UserMembershipModel>({
        /* @ts-ignore */
        result: null,
        isPending: false,
        /* @ts-ignore */
        error: null,
    });
    const [groups, setGroups] = useState<GroupsModel>({
        result: [],
        isPending: false,
        /* @ts-ignore */
        error: null,
    });

    const getUserGroups = (userId: number) => {
        setGroups({ ...groups, isPending: true });
        api.groups.query(null, null, null, null).then(
            result => {
                setGroups({ ...groups, result, isPending: false });
            },
            error => {
                setGroups({ ...groups, isPending: false, error });
            }
        );
    };

    useEffect(() => {
        if (props.userId) {
            getUserGroups(props.userId);
        }
    }, [props.userId]);

    const groupMembership = [
        ...groups.result
            ?.map(m => {
                return {
                    name: m?.name,
                    groupId: m?.id,
                    ...m?.members?.find(mem => mem.userId === props.userId),
                };
            })
            ?.filter(m => m.user !== undefined),
    ];

    const handleAddMembership = (show: boolean) => {
        setGroupMembership(!show);
    };

    const setGroupToUser = (groupId: number, membershipType: MembershipTypeModel) => {
        if (groupId) {
            setUserGroupMembership({
                ...userGroupMembership,
                isPending: true,
            });
            api.groupMembership
                .post(props.userId, groupId, {
                    userId: props.userId,
                    groupId,
                    inviteStatus: "Queued",
                    isParticipant: membershipType.isParticipant,
                    isGroupLeader: membershipType.isGroupLeader,
                })
                .then(
                    result => {
                        setGroupMembership(false);
                        setUserGroupMembership({
                            ...userGroupMembership,
                            result,
                            isPending: false,
                        });
                        getUserGroups(props.userId);
                    },
                    error => {
                        setGroupMembership(false);
                        setUserGroupMembership({
                            ...userGroupMembership,
                            isPending: false,
                            error,
                        });
                    }
                );
        }
    };

    const resendInvite = (userId: number, groupId: number) => {
        const patch = [
            {
                op: "replace",
                path: "inviteStatus",
                value: "Queued",
            },
        ];
        api.groupMembership.patch(userId, groupId, patch).then(
            result => {
                getUserGroups(userId);
            },
            error => {
                console.log(error, "Error is sending Resend Invite.");
            }
        );
    };

    return (
        <Fragment>
            <ContentLabel text={"Group Membership"} />
            <AppLoader className="position-absolute" loading={groups.isPending} />
            <Row>
                <Col md={12} className="table-response">
                    <Table striped borderless>
                        <thead>
                            <tr>
                                <th>{t("COMMON_NAME")}</th>
                                <th>{t("EDITEMPLOYEE_IS_PARTICIPANT")}</th>
                                <th>{t("EDITEMPLOYEE_IS_GROUPLEADER")}</th>
                                <th>{t("GROUP_PARTICIPANTS_TABLE_STATUS")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <RenderIf show={groupMembership && groupMembership.length > 0}>
                                {groupMembership.map((m, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{m?.name}</td>
                                            <td>{m.isParticipant ? <i className="fas fa-check"></i> : ""}</td>
                                            <td>{m.isGroupLeader ? <i className="fas fa-check"></i> : ""}</td>
                                            <td>{m.inviteStatus}</td>
                                            <td>
                                                <Flex justify="center">
                                                    <Menu id="sysadmin-dropdown">
                                                        <Menu.Target>
                                                            <ActionIcon>
                                                                <Icons.ContextMenu />
                                                            </ActionIcon>
                                                        </Menu.Target>
                                                        <Menu.Dropdown>
                                                            <Menu.Item
                                                                onClick={async () =>
                                                                    /* @ts-ignore */
                                                                    await resendInvite(props.userId, m.groupId)
                                                                }
                                                            >
                                                                <i className="fas fa-user-plus mx-1 ms-0"></i>Resend
                                                                Invite
                                                            </Menu.Item>
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                </Flex>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </RenderIf>
                            <RenderIf
                                show={groups.isPending === false && groupMembership && groupMembership.length === 0}
                            >
                                <tr>
                                    <th className="text-center" colSpan={5}>
                                        {t("EDITEMPLOYEE_NO_MEMBERSHIP")}
                                    </th>
                                </tr>
                            </RenderIf>
                        </tbody>
                    </Table>
                </Col>
                <Col className="mt-4" md={props.componentFor === "account-owner" ? 4 : 3}>
                    <PrimaryButton onClick={() => handleAddMembership(addGroupMembership)}>
                        {t("EDITEMPLOYEE_ADD_GROUP_MEMBERSHIP")}
                    </PrimaryButton>
                </Col>
            </Row>
            {addGroupMembership && (
                <AddMembership
                    show={addGroupMembership != null}
                    /* @ts-ignore */
                    hide={() => setGroupMembership(null)}
                    clientId={props.clientId}
                    groups={groups.result}
                    updateGroup={(groupId: number, membershipType: MembershipTypeModel) =>
                        setGroupToUser(groupId, membershipType)
                    }
                    userId={props.userId}
                />
            )}
        </Fragment>
    );
};
