import { useContext, createContext } from "react";

export type FormContextProps = {
    canSubmit: boolean;
    isSubmitting: boolean;
};

export const FormContext = createContext<FormContextProps | null>(null);

export const useFormContext = (): FormContextProps => {
    const value = useContext(FormContext);
    if (value === null) {
        throw new Error();
    }

    return value;
};
