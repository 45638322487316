export const exportBlob = (fileData: Blob, filename: string): void => {
    const url = window.URL.createObjectURL(fileData);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    // @ts-ignore
    link.addEventListener("click", () => link.parentNode.removeChild(link));
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
};
