import { createBrowserRouter, Outlet, RouteObject, useLocation } from "react-router-dom";
import { SendInvitesPage } from "pages/AccountOwner/SendInvites/SendInvitesPage";
import { TeamLayout } from "components/Layout/Team/TeamLayout";
import { ReactElement, useEffect } from "react";
import posthog from "posthog-js";
import { resetGroupType } from "../analytics/posthog";
import { TransformationTodoPage } from "../pages/TransformationTodoPage";
import { WorkshopCategorisationPage2 } from "../pages/WorkshopCategorisationPage2";
import { ImprovementBoardPage } from "../pages/ImprovementBoard/components/ImprovementBoardPage";
import { BoardProvider } from "../pages/ImprovementBoard/BoardContext";
import CurrentGroupCourseContextProvider, { useCurrentCourse, useCurrentGroup } from "../common/courses/context";
import { OrganisationContextProvider } from "../common/OrganisationContext";
import { PoliciesPage } from "../pages/Policy";
import { TermsPage } from "../pages/Terms/TermsPage";
import { ACCOUNT_OWNER_ROLE } from "../constants";
import { ErrorFallback } from "../pages/ErrorPage";
import { HomePage } from "../pages/HomePage";
import AcceptInvite from "../pages/AcceptInvite";
import CompleteProfile from "../pages/CompleteProfile";
import CourseSelect from "../pages/Home/CourseSelector";
import Login from "../pages/Login";
import Onboard from "../pages/Onboard";
import Profile from "../pages/Profile/EditProfile";
import ResetPassword from "../pages/ResetPassword";
import ChangePassword from "../pages/ResetPassword/ChangePassword";
import { SysAdminRoutes } from "./SysAdminRoutes";
import TrainingDiploma from "../training/Diploma";
import { Presentation } from "../workshop/Presentation/Presentation";
import { UnstartedCoursesProvider } from "../common/UnstartedCoursesContext";
import { B2CAuthPlaceHolder } from "../loginb2c/LoginB2C";
import { CenteredLayout } from "../components/Layout/CenteredLayout";
import { UrlFactory } from "./UrlFactory";
import { Program } from "../pages/Program/Program";
import { OldAccountOwnerRoutes } from "./OldAccountOwnerRoutes";
import { AccountOwnerHomePage } from "../pages/AccountOwner/AccountOwnerHomePage";
import { UsersPage } from "../pages/AccountOwner/Users/UsersPage";
import { EditUserPage } from "../pages/AccountOwner/Users/EditUserPage";
import { TeamsPage } from "../pages/AccountOwner/Teams/TeamsPage";
import { EditTeamPage } from "../pages/AccountOwner/Teams/Edit/EditTeamPage";
import { DemographicQuestionsPage as AODemographicQuestionsPage } from "../pages/AccountOwner/DemographicQuestions/DemographicQuestionsPage";
import { EditDemographicQuestionsPage } from "../pages/AccountOwner/DemographicQuestions/EditDemographicQuestionPage";
import { AllProgramsPage } from "../pages/AllPrograms/AllProgramsPage";
import { App } from "../App";
import { ProtectedRouteComponentWithOutlet, ProtectedRouteComponent } from "./ProtectedRouteComponent";
import { DemographicQuestionsPage } from "../onboarding/DemographicQuestionsPage";
import { TrainingModulePage } from "../pages/TrainingModulePage";
import { TransformationPage } from "../pages/TransformationPage";
import { CategorisationOverviewPage } from "../pages/CategorisationOverviewPage";
import { WorkshopCategorisationPage } from "../pages/WorkshopCategorisationPage";
import { indexRoute, pageRoute, route } from "./routebuilder";
import RoutingHellLandingPage from "../pages/HomeRoot";
import { ProgramsPage } from "../pages/AccountOwner/Programs/ProgramsPage";
import { AllProgramsInvitePage } from "../pages/AllProgramsInvite/AllProgramsInvitePage";
import { ProgramAssignmentPage } from "../pages/AccountOwner/Programs/ProgramAssignment/ProgramAssignmentPage";
import { AccountOwnerLayout } from "../components/Layout/AccountOwner/AccountOwnerLayout";
import { IsAdminUser } from "../common/user/utils";
import { trackPosthogEvent } from "../analytics/customEvents/trackPosthogEvent";

const newAccountOwnerRoutes: RouteObject = {
    handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_ORGANIZATION_SETTINGS" },
    element: (
        <ProtectedRouteComponent roles={[ACCOUNT_OWNER_ROLE]}>
            <AccountOwnerLayout>
                <Outlet />
            </AccountOwnerLayout>
        </ProtectedRouteComponent>
    ),
    path: UrlFactory.accountOwner.newHome.pattern,
    children: [
        indexRoute(<AccountOwnerHomePage />),
        {
            path: UrlFactory.accountOwner.users.pattern,
            handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_USERS" },
            children: [
                indexRoute(<UsersPage />),
                {
                    ...pageRoute(UrlFactory.accountOwner.user, EditUserPage),
                    handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_EDIT_USER" },
                },
            ],
        },
        {
            path: UrlFactory.accountOwner.teams.pattern,
            handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_TEAMS" },
            children: [
                indexRoute(<TeamsPage />),
                {
                    ...pageRoute(UrlFactory.accountOwner.team, EditTeamPage),
                    handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_EDIT_TEAM" },
                },
            ],
        },
        {
            path: UrlFactory.accountOwner.demographicQuestions.pattern,
            handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_DEMOGRAPHIC_QUESTIONS" },
            children: [
                indexRoute(<AODemographicQuestionsPage />),
                {
                    ...pageRoute(UrlFactory.accountOwner.demographicQuestion, EditDemographicQuestionsPage),
                    handle: {
                        crumb: "ACCOUNTOWNER_BREADCRUMB_EDIT_DEMOGRAPHIC_QUESTION",
                    },
                },
            ],
        },
        {
            path: UrlFactory.accountOwner.sendInvites.pattern,
            handle: { crumb: "RESOURCES_SEND_INVITES" },
            element: <SendInvitesPage />,
        },
        {
            path: UrlFactory.accountOwner.programs.pattern,
            handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_PROGRAMS" },
            children: [
                indexRoute(<ProgramsPage />),
                {
                    ...pageRoute(UrlFactory.accountOwner.program, ProgramAssignmentPage),
                    handle: { crumb: "ACCOUNTOWNER_BREADCRUMB_ASSIGN_PROGRAM" },
                },
            ],
        },
    ],
};

const TeamIdandProgramIdListener = (props: { children: ReactElement }) => {
    const currentGroup = useCurrentGroup();
    const currentCourse = useCurrentCourse();

    useEffect(() => {
        if (currentGroup?.id != null) {
            posthog.group("team", currentGroup.id.toString(), {
                name: currentGroup.name,
            });
        }

        trackPosthogEvent("ui", "user", "changeteam", {
            team_id: currentGroup != null ? currentGroup.id : null,
            team_name: currentGroup != null ? currentGroup.name : null,
        });

        return () => {
            resetGroupType("team");
        };
    }, [currentGroup]);

    useEffect(() => {
        if (currentCourse?.id != null) {
            posthog.group("program", currentCourse.id.toString(), {
                name: currentCourse.name,
            });
        }

        trackPosthogEvent("ui", "user", "changeprogram", {
            program_id: currentCourse != null ? currentCourse.id : null,
            program_name: currentCourse != null ? currentCourse.name : null,
        });

        return () => {
            resetGroupType("program");
        };
    }, [currentCourse]);

    return props.children;
};

const UserRoutes: RouteObject = {
    element: <ProtectedRouteComponentWithOutlet policy={user => !IsAdminUser(user)} />,
    children: [
        {
            element: (
                <TeamLayout>
                    <Outlet />
                </TeamLayout>
            ),
            children: [route("/profile", <Profile />)],
        },
        route(
            "/complete-profile",
            <CenteredLayout>
                <CompleteProfile />
            </CenteredLayout>
        ),
        OldAccountOwnerRoutes,
        {
            element: (
                <CurrentGroupCourseContextProvider>
                    <UnstartedCoursesProvider>
                        <TeamIdandProgramIdListener>
                            <Outlet />
                        </TeamIdandProgramIdListener>
                    </UnstartedCoursesProvider>
                </CurrentGroupCourseContextProvider>
            ),
            children: [
                route(
                    "/groups/:groupId/workshop/presentation/*",
                    <BoardProvider>
                        <Presentation />
                    </BoardProvider>
                ),
                route(
                    "/groups/:groupId/onboarding/courses/:courseId/videos/:videoId",
                    <CenteredLayout>
                        <Onboard />
                    </CenteredLayout>
                ),
                {
                    element: (
                        <TeamLayout>
                            <Outlet />
                        </TeamLayout>
                    ),
                    children: [
                        route("/course-selector", <CourseSelect />),
                        pageRoute(UrlFactory.team.allPrograms, AllProgramsPage),
                        route("/groups/:groupId/implementation", <TransformationTodoPage />),
                        route("/groups/:groupId/training/:courseId/*", <TrainingModulePage />),
                        route("/groups/:groupId/transformation/:details?/:moduleId?", <TransformationPage />),
                        route("/groups/:groupId/workshop", <CategorisationOverviewPage />),
                        route(
                            "/groups/:groupId/workshop/chapter/:chapterId/module/:moduleId",
                            <WorkshopCategorisationPage />
                        ),
                        route(
                            "/groups/:groupId/workshop/chapter/:chapterId/module/:moduleId/question/:questionId",
                            <WorkshopCategorisationPage2 />
                        ),
                        route(
                            "/groups/:groupId/boards",
                            <BoardProvider>
                                <ImprovementBoardPage />
                            </BoardProvider>
                        ),
                        pageRoute(UrlFactory.team.home, HomePage),
                        route(UrlFactory.team.program.pattern, <Program />),
                    ],
                },
                newAccountOwnerRoutes,
                route("/demographic", <DemographicQuestionsPage />),
            ],
        },
    ],
};

const authenticatedRoutes: RouteObject[] = [
    SysAdminRoutes,
    {
        element: (
            <OrganisationContextProvider>
                <Outlet />
            </OrganisationContextProvider>
        ),
        children: [
            route("/", <RoutingHellLandingPage />),
            {
                element: (
                    <CenteredLayout>
                        <Outlet />
                    </CenteredLayout>
                ),
                children: [route(UrlFactory.sysadmin.profile.pattern, <Profile />)],
            },
            UserRoutes,
        ],
    },
];

const paths = [
    route("/error", <ErrorFallback />),
    route("/loginb2c", <B2CAuthPlaceHolder />),
    {
        element: (
            <CenteredLayout>
                <Outlet />
            </CenteredLayout>
        ),
        children: [
            route(UrlFactory.login.pattern, <Login />),
            route("/terms", <TermsPage />),
            route("/policies", <PoliciesPage />),
            route("/accept-invite", <AcceptInvite />),
            route("/reset-password", <ResetPassword />),
            route("/change-password", <ChangePassword />),
        ],
    },
    route("/diploma/:token", <TrainingDiploma />),
    {
        element: <ProtectedRouteComponentWithOutlet />,
        children: authenticatedRoutes,
    },

    pageRoute(UrlFactory.allProgramsInvite, AllProgramsInvitePage),
];

const PageViewListener = (props: { children: ReactElement }) => {
    const location = useLocation();

    useEffect(() => {
        posthog.capture("$pageview");
    }, [location.pathname]);

    return props.children;
};

export const router = createBrowserRouter([
    {
        element: (
            <PageViewListener>
                <App>
                    <Outlet />
                </App>
            </PageViewListener>
        ),
        children: paths,
    },
]);
