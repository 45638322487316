import React from "react";

interface ModuleHeaderProps {
    title: string;
    id: string;
    sequence: number;
    navigationLabel?: string;
}

export const ModuleHeaderInformation = (props: ModuleHeaderProps): JSX.Element => {
    const { title, id, navigationLabel } = props;
    return (
        <div className="moduleHeader" id={id}>
            <div className="col-md-12">
                <p>{navigationLabel ? navigationLabel : title}</p>
            </div>
        </div>
    );
};
