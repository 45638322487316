var __extends =
    (this && this.__extends) ||
    (function () {
        var extendStatics = function (d, b) {
            extendStatics =
                Object.setPrototypeOf ||
                ({ __proto__: [] } instanceof Array &&
                    function (d, b) {
                        d.__proto__ = b;
                    }) ||
                function (d, b) {
                    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
                };
            return extendStatics(d, b);
        };
        return function (d, b) {
            extendStatics(d, b);
            function __() {
                this.constructor = d;
            }
            d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
        };
    })();

function throwException(message, status, response, headers, result) {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
}

var ApiException = /** @class */ (function (_super) {
    __extends(ApiException, _super);
    function ApiException(message, status, response, headers, result) {
        var _this = _super.call(this) || this;
        _this.isApiException = true;
        _this.message = message;
        _this.status = status;
        _this.response = response;
        _this.headers = headers;
        _this.result = result;
        return _this;
    }
    ApiException.isApiException = function (obj) {
        return obj.isApiException === true;
    };
    return ApiException;
})(Error);
export { ApiException };

var ApiClientBase = /** @class */ (function () {
    function ApiClientBase(configuration) {
        this.configuration = configuration;
        this.configuration = configuration;
    }
    ApiClientBase.prototype.getBaseUrl = function (defaultUrl, override) {
        // Note: Note clear why this have to parameters compared to documentation just having one
        return override !== null && override !== void 0 ? override : this.configuration.baseUrl;
    };
    ApiClientBase.prototype.transformOptions = function (options) {
        var _this = this;
        return new Promise(function (resolve) {
            // Add jwt
            options.headers["Authorization"] = _this.configuration.getAuthorizationHeader();
            // Resolve
            resolve(options);
        });
    };
    return ApiClientBase;
})();
export { ApiClientBase };
var AccountOwnersClient = /** @class */ (function (_super) {
    __extends(AccountOwnersClient, _super);
    function AccountOwnersClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    AccountOwnersClient.prototype.query = function (clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners?";
        if (clientId !== undefined && clientId !== null) url_ += "ClientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    AccountOwnersClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AccountOwnersClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    AccountOwnersClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AccountOwnersClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    AccountOwnersClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AccountOwnersClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    AccountOwnersClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AccountOwnersClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    AccountOwnersClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AccountOwnersClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/account-owners/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    AccountOwnersClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return AccountOwnersClient;
})(ApiClientBase);
export { AccountOwnersClient };

var AdditionalResourcesClient = /** @class */ (function (_super) {
    __extends(AdditionalResourcesClient, _super);
    function AdditionalResourcesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    AdditionalResourcesClient.prototype.uploadResource = function (file) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/UploadResource";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (file !== null && file !== undefined)
            content_.append("file", file.data, file.fileName ? file.fileName : "file");
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processUploadResource(_response);
            });
    };
    AdditionalResourcesClient.prototype.processUploadResource = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.editUploadedResource = function (id, file) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/EditUploadedResource/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (file !== null && file !== undefined)
            content_.append("file", file.data, file.fileName ? file.fileName : "file");
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processEditUploadedResource(_response);
            });
    };
    AdditionalResourcesClient.prototype.processEditUploadedResource = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.query = function (moduleId, moduleBlockId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources?";
        if (moduleId !== undefined && moduleId !== null) url_ += "ModuleId=" + encodeURIComponent("" + moduleId) + "&";
        if (moduleBlockId !== undefined && moduleBlockId !== null)
            url_ += "ModuleBlockId=" + encodeURIComponent("" + moduleBlockId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    AdditionalResourcesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    AdditionalResourcesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    AdditionalResourcesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    AdditionalResourcesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    AdditionalResourcesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AdditionalResourcesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/additional-resources/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    AdditionalResourcesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return AdditionalResourcesClient;
})(ApiClientBase);
export { AdditionalResourcesClient };

var AnswerCategoriesClient = /** @class */ (function (_super) {
    __extends(AnswerCategoriesClient, _super);
    function AnswerCategoriesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    AnswerCategoriesClient.prototype.query = function (questionId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories?";
        if (questionId !== undefined && questionId !== null)
            url_ += "QuestionId=" + encodeURIComponent("" + questionId) + "&";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    AnswerCategoriesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswerCategoriesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    AnswerCategoriesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswerCategoriesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    AnswerCategoriesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswerCategoriesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    AnswerCategoriesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswerCategoriesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    AnswerCategoriesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswerCategoriesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answer-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    AnswerCategoriesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return AnswerCategoriesClient;
})(ApiClientBase);
export { AnswerCategoriesClient };

var AnswersClient = /** @class */ (function (_super) {
    __extends(AnswersClient, _super);
    function AnswersClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    AnswersClient.prototype.query = function (userId, questionId, courseId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers?";
        if (userId !== undefined && userId !== null) url_ += "UserId=" + encodeURIComponent("" + userId) + "&";
        if (questionId !== undefined && questionId !== null)
            url_ += "QuestionId=" + encodeURIComponent("" + questionId) + "&";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    AnswersClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswersClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    AnswersClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswersClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    AnswersClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswersClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    AnswersClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswersClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    AnswersClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    AnswersClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/answers/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    AnswersClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return AnswersClient;
})(ApiClientBase);
export { AnswersClient };

var ChaptersClient = /** @class */ (function (_super) {
    __extends(ChaptersClient, _super);
    function ChaptersClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ChaptersClient.prototype.query = function (courseId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters?";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ChaptersClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ChaptersClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ChaptersClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ChaptersClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    ChaptersClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ChaptersClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ChaptersClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ChaptersClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    ChaptersClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ChaptersClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Chapters/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    ChaptersClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ChaptersClient;
})(ApiClientBase);
export { ChaptersClient };

var ClientCoursesClient = /** @class */ (function (_super) {
    __extends(ClientCoursesClient, _super);
    function ClientCoursesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ClientCoursesClient.prototype.query = function (clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses?";
        if (clientId !== undefined && clientId !== null) url_ += "ClientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ClientCoursesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientCoursesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ClientCoursesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientCoursesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    ClientCoursesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientCoursesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ClientCoursesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientCoursesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    ClientCoursesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientCoursesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ClientCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    ClientCoursesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ClientCoursesClient;
})(ApiClientBase);
export { ClientCoursesClient };

var ClientsClient = /** @class */ (function (_super) {
    __extends(ClientsClient, _super);
    function ClientsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ClientsClient.prototype.getAllTags = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients/tags";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetAllTags(_response);
            });
    };
    ClientsClient.prototype.processGetAllTags = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.query = function (allClients) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients?";
        if (allClients !== undefined && allClients !== null)
            url_ += "AllClients=" + encodeURIComponent("" + allClients) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ClientsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ClientsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    ClientsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ClientsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    ClientsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ClientsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/clients/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    ClientsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ClientsClient;
})(ApiClientBase);
export { ClientsClient };

var CoursesClient = /** @class */ (function (_super) {
    __extends(CoursesClient, _super);
    function CoursesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    CoursesClient.prototype.getAllTags = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses/tags";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetAllTags(_response);
            });
    };
    CoursesClient.prototype.processGetAllTags = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.query = function (expandCourses) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses?";
        if (expandCourses === null) throw new Error("The parameter 'expandCourses' cannot be null.");
        else if (expandCourses !== undefined) url_ += "ExpandCourses=" + encodeURIComponent("" + expandCourses) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    CoursesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    CoursesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    CoursesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    CoursesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    CoursesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    CoursesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/courses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    CoursesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return CoursesClient;
})(ApiClientBase);
export { CoursesClient };

var DemographicQuestionClient = /** @class */ (function (_super) {
    __extends(DemographicQuestionClient, _super);
    function DemographicQuestionClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    DemographicQuestionClient.prototype.query = function (query) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions?";
        if (query !== undefined && query !== null) url_ += "query=" + encodeURIComponent("" + query) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    DemographicQuestionClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicQuestionClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    DemographicQuestionClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicQuestionClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    DemographicQuestionClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicQuestionClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    DemographicQuestionClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicQuestionClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    DemographicQuestionClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicQuestionClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    DemographicQuestionClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return DemographicQuestionClient;
})(ApiClientBase);
export { DemographicQuestionClient };

var DemographicUserDataClient = /** @class */ (function (_super) {
    __extends(DemographicUserDataClient, _super);
    function DemographicUserDataClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    DemographicUserDataClient.prototype.queryAll = function (userId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/demographic-data?";
        if (userId !== undefined && userId !== null) url_ += "UserId=" + encodeURIComponent("" + userId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQueryAll(_response);
            });
    };
    DemographicUserDataClient.prototype.processQueryAll = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicUserDataClient.prototype.query = function (userId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/demographic-data";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{userId}", encodeURIComponent("" + userId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    DemographicUserDataClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicUserDataClient.prototype.get = function (userId, demographicQuestionId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/demographic-data/{DemographicQuestionId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (demographicQuestionId === undefined || demographicQuestionId === null)
            throw new Error("The parameter 'demographicQuestionId' must be defined.");
        url_ = url_.replace("{DemographicQuestionId}", encodeURIComponent("" + demographicQuestionId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    DemographicUserDataClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DemographicUserDataClient.prototype.put = function (userId, demographicQuestionId, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/demographic-data/{DemographicQuestionId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (demographicQuestionId === undefined || demographicQuestionId === null)
            throw new Error("The parameter 'demographicQuestionId' must be defined.");
        url_ = url_.replace("{DemographicQuestionId}", encodeURIComponent("" + demographicQuestionId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    DemographicUserDataClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return DemographicUserDataClient;
})(ApiClientBase);
export { DemographicUserDataClient };

var DepartmentsClient = /** @class */ (function (_super) {
    __extends(DepartmentsClient, _super);
    function DepartmentsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    DepartmentsClient.prototype.query = function (query) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments?";
        if (query !== undefined && query !== null) url_ += "query=" + encodeURIComponent("" + query) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    DepartmentsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DepartmentsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    DepartmentsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DepartmentsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    DepartmentsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DepartmentsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    DepartmentsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DepartmentsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    DepartmentsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DepartmentsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/departments/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    DepartmentsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return DepartmentsClient;
})(ApiClientBase);
export { DepartmentsClient };

var DiplomasClient = /** @class */ (function (_super) {
    __extends(DiplomasClient, _super);
    function DiplomasClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    DiplomasClient.prototype.get = function (token) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas/{token}";
        if (token === undefined || token === null) throw new Error("The parameter 'token' must be defined.");
        url_ = url_.replace("{token}", encodeURIComponent("" + token));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    DiplomasClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DiplomasClient.prototype.put = function (token, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas/{token}";
        if (token === undefined || token === null) throw new Error("The parameter 'token' must be defined.");
        url_ = url_.replace("{token}", encodeURIComponent("" + token));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    DiplomasClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DiplomasClient.prototype.delete = function (token) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas/{token}";
        if (token === undefined || token === null) throw new Error("The parameter 'token' must be defined.");
        url_ = url_.replace("{token}", encodeURIComponent("" + token));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/octet-stream",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    DiplomasClient.prototype.processDelete = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200 || status === 206) {
            var contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            var fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            var fileName_2 = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob().then(function (blob) {
                return { fileName: fileName_2, data: blob, status: status, headers: _headers };
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DiplomasClient.prototype.patch = function (token, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas/{token}";
        if (token === undefined || token === null) throw new Error("The parameter 'token' must be defined.");
        url_ = url_.replace("{token}", encodeURIComponent("" + token));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    DiplomasClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DiplomasClient.prototype.query = function (userId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas?";
        if (userId !== undefined && userId !== null) url_ += "UserId=" + encodeURIComponent("" + userId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    DiplomasClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    DiplomasClient.prototype.post = function (dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Diplomas";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    DiplomasClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return DiplomasClient;
})(ApiClientBase);
export { DiplomasClient };

var GroupCoursesClient = /** @class */ (function (_super) {
    __extends(GroupCoursesClient, _super);
    function GroupCoursesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    GroupCoursesClient.prototype.query = function (groupId, courseId, roundId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses?";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        if (roundId !== undefined && roundId !== null) url_ += "RoundId=" + encodeURIComponent("" + roundId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    GroupCoursesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupCoursesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    GroupCoursesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupCoursesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    GroupCoursesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupCoursesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    GroupCoursesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupCoursesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    GroupCoursesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupCoursesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/GroupCourses/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    GroupCoursesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return GroupCoursesClient;
})(ApiClientBase);
export { GroupCoursesClient };

var GroupMembershipClient = /** @class */ (function (_super) {
    __extends(GroupMembershipClient, _super);
    function GroupMembershipClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    GroupMembershipClient.prototype.get = function (userId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership/{GroupId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    GroupMembershipClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.put = function (userId, groupId, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership/{GroupId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    GroupMembershipClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.delete = function (userId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership/{GroupId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/octet-stream",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    GroupMembershipClient.prototype.processDelete = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200 || status === 206) {
            var contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            var fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            var fileName_3 = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob().then(function (blob) {
                return { fileName: fileName_3, data: blob, status: status, headers: _headers };
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.patch = function (userId, groupId, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership/{GroupId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    GroupMembershipClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.query = function (userId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    GroupMembershipClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.post = function (userId, groupId, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/group-membership";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    GroupMembershipClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupMembershipClient.prototype.changeGroupId = function (dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/change-group";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processChangeGroupId(_response);
            });
    };
    GroupMembershipClient.prototype.processChangeGroupId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return GroupMembershipClient;
})(ApiClientBase);
export { GroupMembershipClient };

var GroupTypesClient = /** @class */ (function (_super) {
    __extends(GroupTypesClient, _super);
    function GroupTypesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    GroupTypesClient.prototype.query = function (clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types?";
        if (clientId !== undefined && clientId !== null) url_ += "ClientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    GroupTypesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupTypesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    GroupTypesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupTypesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    GroupTypesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupTypesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    GroupTypesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupTypesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    GroupTypesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupTypesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/group-types/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    GroupTypesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return GroupTypesClient;
})(ApiClientBase);
export { GroupTypesClient };

var ImagesClient = /** @class */ (function (_super) {
    __extends(ImagesClient, _super);
    function ImagesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ImagesClient.prototype.post = function (image) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Images";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (image !== null && image !== undefined)
            content_.append("image", image.data, image.fileName ? image.fileName : "image");
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ImagesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ImagesClient.prototype.put = function (id, image) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Images/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (image !== null && image !== undefined)
            content_.append("image", image.data, image.fileName ? image.fileName : "image");
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ImagesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ImagesClient;
})(ApiClientBase);
export { ImagesClient };

var IntroductionVideosClient = /** @class */ (function (_super) {
    __extends(IntroductionVideosClient, _super);
    function IntroductionVideosClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    IntroductionVideosClient.prototype.query = function (courseId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos?";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    IntroductionVideosClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    IntroductionVideosClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    IntroductionVideosClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    IntroductionVideosClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    IntroductionVideosClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    IntroductionVideosClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    IntroductionVideosClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    IntroductionVideosClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    IntroductionVideosClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    IntroductionVideosClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/IntroductionVideos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    IntroductionVideosClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return IntroductionVideosClient;
})(ApiClientBase);
export { IntroductionVideosClient };

var ModuleBlocksClient = /** @class */ (function (_super) {
    __extends(ModuleBlocksClient, _super);
    function ModuleBlocksClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ModuleBlocksClient.prototype.query = function (moduleId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks?";
        if (moduleId !== undefined && moduleId !== null) url_ += "moduleId=" + encodeURIComponent("" + moduleId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ModuleBlocksClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModuleBlocksClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ModuleBlocksClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModuleBlocksClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    ModuleBlocksClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModuleBlocksClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ModuleBlocksClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModuleBlocksClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    ModuleBlocksClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModuleBlocksClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/ModuleBlocks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    ModuleBlocksClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ModuleBlocksClient;
})(ApiClientBase);
export { ModuleBlocksClient };

var ModulesClient = /** @class */ (function (_super) {
    __extends(ModulesClient, _super);
    function ModulesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ModulesClient.prototype.query = function (chapterId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules?";
        if (chapterId !== undefined && chapterId !== null)
            url_ += "ChapterId=" + encodeURIComponent("" + chapterId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ModulesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModulesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    ModulesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModulesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    ModulesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModulesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    ModulesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModulesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    ModulesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ModulesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Modules/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    ModulesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ModulesClient;
})(ApiClientBase);
export { ModulesClient };

var OnBoardingProgressClient = /** @class */ (function (_super) {
    __extends(OnBoardingProgressClient, _super);
    function OnBoardingProgressClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    OnBoardingProgressClient.prototype.getByUserIdAndCourseId = function (userId, courseId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/onboarding/{CourseId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (courseId === undefined || courseId === null) throw new Error("The parameter 'courseId' must be defined.");
        url_ = url_.replace("{CourseId}", encodeURIComponent("" + courseId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetByUserIdAndCourseId(_response);
            });
    };
    OnBoardingProgressClient.prototype.processGetByUserIdAndCourseId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    OnBoardingProgressClient.prototype.putByUserIdAndCourseId = function (userId, courseId, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{UserId}/onboarding/{CourseId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (courseId === undefined || courseId === null) throw new Error("The parameter 'courseId' must be defined.");
        url_ = url_.replace("{CourseId}", encodeURIComponent("" + courseId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/octet-stream",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPutByUserIdAndCourseId(_response);
            });
    };
    OnBoardingProgressClient.prototype.processPutByUserIdAndCourseId = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200 || status === 206) {
            var contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            var fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            var fileName_6 = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob().then(function (blob) {
                return { fileName: fileName_6, data: blob, status: status, headers: _headers };
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return OnBoardingProgressClient;
})(ApiClientBase);
export { OnBoardingProgressClient };

var QuestionOptionsClient = /** @class */ (function (_super) {
    __extends(QuestionOptionsClient, _super);
    function QuestionOptionsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    QuestionOptionsClient.prototype.query = function (questionId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options?";
        if (questionId !== undefined && questionId !== null)
            url_ += "QuestionId=" + encodeURIComponent("" + questionId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    QuestionOptionsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionOptionsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    QuestionOptionsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionOptionsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    QuestionOptionsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionOptionsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    QuestionOptionsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionOptionsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    QuestionOptionsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionOptionsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/question-options/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    QuestionOptionsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return QuestionOptionsClient;
})(ApiClientBase);
export { QuestionOptionsClient };

var QuestionsClient = /** @class */ (function (_super) {
    __extends(QuestionsClient, _super);
    function QuestionsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    QuestionsClient.prototype.query = function (courseId, groupId, expandAnswers, moduleBlockId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions?";
        if (courseId === null) throw new Error("The parameter 'courseId' cannot be null.");
        else if (courseId !== undefined) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        if (expandAnswers !== undefined && expandAnswers !== null)
            url_ += "ExpandAnswers=" + encodeURIComponent("" + expandAnswers) + "&";
        if (moduleBlockId !== undefined && moduleBlockId !== null)
            url_ += "ModuleBlockId=" + encodeURIComponent("" + moduleBlockId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    QuestionsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    QuestionsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    QuestionsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    QuestionsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    QuestionsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    QuestionsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Questions/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    QuestionsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return QuestionsClient;
})(ApiClientBase);
export { QuestionsClient };

var ReportsClient = /** @class */ (function (_super) {
    __extends(ReportsClient, _super);
    function ReportsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ReportsClient.prototype.answerCountPerQuestion = function (courseId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/AnswerCountPerQuestion?";
        if (courseId === null) throw new Error("The parameter 'courseId' cannot be null.");
        else if (courseId !== undefined) url_ += "courseId=" + encodeURIComponent("" + courseId) + "&";
        if (groupId === null) throw new Error("The parameter 'groupId' cannot be null.");
        else if (groupId !== undefined) url_ += "groupId=" + encodeURIComponent("" + groupId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processAnswerCountPerQuestion(_response);
            });
    };
    ReportsClient.prototype.processAnswerCountPerQuestion = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.userModuleCompletionCount = function (courseId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/UserModuleCompletionCount?";
        if (courseId === null) throw new Error("The parameter 'courseId' cannot be null.");
        else if (courseId !== undefined) url_ += "courseId=" + encodeURIComponent("" + courseId) + "&";
        if (groupId === null) throw new Error("The parameter 'groupId' cannot be null.");
        else if (groupId !== undefined) url_ += "groupId=" + encodeURIComponent("" + groupId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processUserModuleCompletionCount(_response);
            });
    };
    ReportsClient.prototype.processUserModuleCompletionCount = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.trainingProgressPerDepartment = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/training-progress-per-department";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processTrainingProgressPerDepartment(_response);
            });
    };
    ReportsClient.prototype.processTrainingProgressPerDepartment = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.trainingProgressPerGroup = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/training-progress-per-group";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processTrainingProgressPerGroup(_response);
            });
    };
    ReportsClient.prototype.processTrainingProgressPerGroup = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.trainingOverview = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/training-overview";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processTrainingOverview(_response);
            });
    };
    ReportsClient.prototype.processTrainingOverview = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.getClientsUsedCourses = function (expertId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/GetClientsUseExpertCourses?";
        if (expertId !== undefined && expertId !== null) url_ += "expertId=" + encodeURIComponent("" + expertId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetClientsUsedCourses(_response);
            });
    };
    ReportsClient.prototype.processGetClientsUsedCourses = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.getTaskCountPerClientAndGroup = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/task-count-client-group";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetTaskCountPerClientAndGroup(_response);
            });
    };
    ReportsClient.prototype.processGetTaskCountPerClientAndGroup = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.taskCountPerClientAndGroupAndUser = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/task-count-client-group-user";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processTaskCountPerClientAndGroupAndUser(_response);
            });
    };
    ReportsClient.prototype.processTaskCountPerClientAndGroupAndUser = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.getTaskCountPerGroup = function (clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/GetTaskCountPerGroup?";
        if (clientId !== undefined && clientId !== null) url_ += "clientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetTaskCountPerGroup(_response);
            });
    };
    ReportsClient.prototype.processGetTaskCountPerGroup = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ReportsClient.prototype.getCourseAssignmentReport = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/reports/course-assignment-report";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetCourseAssignmentReport(_response);
            });
    };
    ReportsClient.prototype.processGetCourseAssignmentReport = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ReportsClient;
})(ApiClientBase);
export { ReportsClient };

var RoundsClient = /** @class */ (function (_super) {
    __extends(RoundsClient, _super);
    function RoundsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    RoundsClient.prototype.query = function (clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds?";
        if (clientId !== undefined && clientId !== null) url_ += "ClientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    RoundsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    RoundsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    RoundsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    RoundsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    RoundsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    RoundsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    RoundsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    RoundsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    RoundsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    RoundsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Rounds/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    RoundsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return RoundsClient;
})(ApiClientBase);
export { RoundsClient };

var TaskCategoriesClient = /** @class */ (function (_super) {
    __extends(TaskCategoriesClient, _super);
    function TaskCategoriesClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    TaskCategoriesClient.prototype.query = function (groupId, clientId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories?";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        if (clientId !== undefined && clientId !== null) url_ += "ClientId=" + encodeURIComponent("" + clientId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    TaskCategoriesClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TaskCategoriesClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    TaskCategoriesClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TaskCategoriesClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    TaskCategoriesClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TaskCategoriesClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    TaskCategoriesClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TaskCategoriesClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    TaskCategoriesClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TaskCategoriesClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/task-categories/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    TaskCategoriesClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return TaskCategoriesClient;
})(ApiClientBase);
export { TaskCategoriesClient };

var TasksClient = /** @class */ (function (_super) {
    __extends(TasksClient, _super);
    function TasksClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    TasksClient.prototype.query = function (assignedToUserId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks?";
        if (assignedToUserId !== undefined && assignedToUserId !== null)
            url_ += "AssignedToUserId=" + encodeURIComponent("" + assignedToUserId) + "&";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    TasksClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TasksClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    TasksClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TasksClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    TasksClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TasksClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    TasksClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TasksClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    TasksClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TasksClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Tasks/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    TasksClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return TasksClient;
})(ApiClientBase);
export { TasksClient };

var TransformationTodoItemsClient = /** @class */ (function (_super) {
    __extends(TransformationTodoItemsClient, _super);
    function TransformationTodoItemsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    TransformationTodoItemsClient.prototype.get = function (transformationTodoId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items/{TransformationTodoId}/{GroupId}";
        if (transformationTodoId === undefined || transformationTodoId === null)
            throw new Error("The parameter 'transformationTodoId' must be defined.");
        url_ = url_.replace("{TransformationTodoId}", encodeURIComponent("" + transformationTodoId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodoItemsClient.prototype.put = function (transformationTodoId, groupId, dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items/{TransformationTodoId}/{GroupId}";
        if (transformationTodoId === undefined || transformationTodoId === null)
            throw new Error("The parameter 'transformationTodoId' must be defined.");
        url_ = url_.replace("{TransformationTodoId}", encodeURIComponent("" + transformationTodoId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodoItemsClient.prototype.delete = function (transformationTodoId, groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items/{TransformationTodoId}/{GroupId}";
        if (transformationTodoId === undefined || transformationTodoId === null)
            throw new Error("The parameter 'transformationTodoId' must be defined.");
        url_ = url_.replace("{TransformationTodoId}", encodeURIComponent("" + transformationTodoId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/octet-stream",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processDelete = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200 || status === 206) {
            var contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            var fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            var fileName_7 = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob().then(function (blob) {
                return { fileName: fileName_7, data: blob, status: status, headers: _headers };
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodoItemsClient.prototype.patch = function (transformationTodoId, groupId, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items/{TransformationTodoId}/{GroupId}";
        if (transformationTodoId === undefined || transformationTodoId === null)
            throw new Error("The parameter 'transformationTodoId' must be defined.");
        url_ = url_.replace("{TransformationTodoId}", encodeURIComponent("" + transformationTodoId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodoItemsClient.prototype.query = function (groupId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items?";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodoItemsClient.prototype.post = function (dto) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todo-items";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    TransformationTodoItemsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return TransformationTodoItemsClient;
})(ApiClientBase);
export { TransformationTodoItemsClient };

var TransformationTodosClient = /** @class */ (function (_super) {
    __extends(TransformationTodosClient, _super);
    function TransformationTodosClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    TransformationTodosClient.prototype.query = function (courseId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos?";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    TransformationTodosClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodosClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    TransformationTodosClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodosClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    TransformationTodosClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodosClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    TransformationTodosClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodosClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    TransformationTodosClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    TransformationTodosClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/transformation-todos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    TransformationTodosClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return TransformationTodosClient;
})(ApiClientBase);
export { TransformationTodosClient };

var VideosClient = /** @class */ (function (_super) {
    __extends(VideosClient, _super);
    function VideosClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    VideosClient.prototype.getAllTags = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/tags";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetAllTags(_response);
            });
    };
    VideosClient.prototype.processGetAllTags = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.videoUsageReport = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}/usage-report";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processVideoUsageReport(_response);
            });
    };
    VideosClient.prototype.processVideoUsageReport = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.putVideoBlock = function (id, blockId, block) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}/put-block?";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        if (blockId !== undefined && blockId !== null) url_ += "blockId=" + encodeURIComponent("" + blockId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (block !== null && block !== undefined)
            content_.append("block", block.data, block.fileName ? block.fileName : "block");
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {},
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPutVideoBlock(_response);
            });
    };
    VideosClient.prototype.processPutVideoBlock = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                return;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.commitVideoBlocks = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}/commit-blocks";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processCommitVideoBlocks(_response);
            });
    };
    VideosClient.prototype.processCommitVideoBlocks = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                return;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.query = function (query) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos?";
        if (query !== undefined && query !== null) url_ += "query=" + encodeURIComponent("" + query) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    VideosClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    VideosClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    VideosClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    VideosClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    VideosClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    VideosClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Videos/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    VideosClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return VideosClient;
})(ApiClientBase);
export { VideosClient };

var ParticipantsProgressClient = /** @class */ (function (_super) {
    __extends(ParticipantsProgressClient, _super);
    function ParticipantsProgressClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    ParticipantsProgressClient.prototype.query = function (userId, groupId, moduleId, moduleBlockId, roundId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/participants-progress?";
        if (userId !== undefined && userId !== null) url_ += "UserId=" + encodeURIComponent("" + userId) + "&";
        if (groupId !== undefined && groupId !== null) url_ += "GroupId=" + encodeURIComponent("" + groupId) + "&";
        if (moduleId !== undefined && moduleId !== null) url_ += "ModuleId=" + encodeURIComponent("" + moduleId) + "&";
        if (moduleBlockId !== undefined && moduleBlockId !== null)
            url_ += "ModuleBlockId=" + encodeURIComponent("" + moduleBlockId) + "&";
        if (roundId !== undefined && roundId !== null) url_ += "RoundId=" + encodeURIComponent("" + roundId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    ParticipantsProgressClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ParticipantsProgressClient.prototype.getByUserIdAndModuleId = function (
        userId,
        moduleId,
        moduleBlockId,
        groupId,
        roundId
    ) {
        var _this = this;
        var url_ =
            this.baseUrl +
            "/api/participants-progress/user/{UserId}/modules/{ModuleId}/module-blocks/{ModuleBlockId}/groups/{GroupId}/rounds/{RoundId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (moduleId === undefined || moduleId === null) throw new Error("The parameter 'moduleId' must be defined.");
        url_ = url_.replace("{ModuleId}", encodeURIComponent("" + moduleId));
        if (moduleBlockId === undefined || moduleBlockId === null)
            throw new Error("The parameter 'moduleBlockId' must be defined.");
        url_ = url_.replace("{ModuleBlockId}", encodeURIComponent("" + moduleBlockId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        if (roundId === undefined || roundId === null) throw new Error("The parameter 'roundId' must be defined.");
        url_ = url_.replace("{RoundId}", encodeURIComponent("" + roundId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGetByUserIdAndModuleId(_response);
            });
    };
    ParticipantsProgressClient.prototype.processGetByUserIdAndModuleId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    ParticipantsProgressClient.prototype.putByUserIdAndModuleId = function (
        userId,
        moduleId,
        moduleBlockId,
        groupId,
        roundId,
        dto
    ) {
        var _this = this;
        var url_ =
            this.baseUrl +
            "/api/participants-progress/user/{UserId}/modules/{ModuleId}/module-blocks/{ModuleBlockId}/groups/{GroupId}/rounds/{RoundId}";
        if (userId === undefined || userId === null) throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{UserId}", encodeURIComponent("" + userId));
        if (moduleId === undefined || moduleId === null) throw new Error("The parameter 'moduleId' must be defined.");
        url_ = url_.replace("{ModuleId}", encodeURIComponent("" + moduleId));
        if (moduleBlockId === undefined || moduleBlockId === null)
            throw new Error("The parameter 'moduleBlockId' must be defined.");
        url_ = url_.replace("{ModuleBlockId}", encodeURIComponent("" + moduleBlockId));
        if (groupId === undefined || groupId === null) throw new Error("The parameter 'groupId' must be defined.");
        url_ = url_.replace("{GroupId}", encodeURIComponent("" + groupId));
        if (roundId === undefined || roundId === null) throw new Error("The parameter 'roundId' must be defined.");
        url_ = url_.replace("{RoundId}", encodeURIComponent("" + roundId));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(dto);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPutByUserIdAndModuleId(_response);
            });
    };
    ParticipantsProgressClient.prototype.processPutByUserIdAndModuleId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return ParticipantsProgressClient;
})(ApiClientBase);
export { ParticipantsProgressClient };

var UsersClient = /** @class */ (function (_super) {
    __extends(UsersClient, _super);
    function UsersClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    UsersClient.prototype.changePassword = function (changePassword) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/change-password";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(changePassword);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processChangePassword(_response);
            });
    };
    UsersClient.prototype.processChangePassword = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.validate = function (user) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/validate";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(user);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processValidate(_response);
            });
    };
    UsersClient.prototype.processValidate = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.uploadProfileImage = function (id, image) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{id}/profile-image";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = new FormData();
        if (image !== null && image !== undefined)
            content_.append("image", image.data, image.fileName ? image.fileName : "image");
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processUploadProfileImage(_response);
            });
    };
    UsersClient.prototype.processUploadProfileImage = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.query = function (skip, take, departmentId, allUsers) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users?";
        if (skip !== undefined && skip !== null) url_ += "Skip=" + encodeURIComponent("" + skip) + "&";
        if (take !== undefined && take !== null) url_ += "Take=" + encodeURIComponent("" + take) + "&";
        if (departmentId !== undefined && departmentId !== null)
            url_ += "DepartmentId=" + encodeURIComponent("" + departmentId) + "&";
        if (allUsers !== undefined && allUsers !== null) url_ += "AllUsers=" + encodeURIComponent("" + allUsers) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    UsersClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    UsersClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    UsersClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    UsersClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    UsersClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    UsersClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/users/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    UsersClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return UsersClient;
})(ApiClientBase);
export { UsersClient };

var LoginClient = /** @class */ (function (_super) {
    __extends(LoginClient, _super);
    function LoginClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    LoginClient.prototype.login = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processLogin(_response);
            });
    };
    LoginClient.prototype.processLogin = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.refresh = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/refresh";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processRefresh(_response);
            });
    };
    LoginClient.prototype.processRefresh = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.acceptInvite = function (signup) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/accept-invite";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(signup);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processAcceptInvite(_response);
            });
    };
    LoginClient.prototype.processAcceptInvite = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.resetPassword = function (resetPassword) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/reset-password";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(resetPassword);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processResetPassword(_response);
            });
    };
    LoginClient.prototype.processResetPassword = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.checkAccountStatus = function (model, validateAccountStatus) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/check-account-status?";
        if (validateAccountStatus === null) throw new Error("The parameter 'validateAccountStatus' cannot be null.");
        else if (validateAccountStatus !== undefined)
            url_ += "validateAccountStatus=" + encodeURIComponent("" + validateAccountStatus) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processCheckAccountStatus(_response);
            });
    };
    LoginClient.prototype.processCheckAccountStatus = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.requestPassword = function (passwordRequest) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/request-password";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(passwordRequest);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processRequestPassword(_response);
            });
    };
    LoginClient.prototype.processRequestPassword = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.inviteData = function () {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/invite-data";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processInviteData(_response);
            });
    };
    LoginClient.prototype.processInviteData = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.impersonate = function (clientId, userId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/impersonate?";
        if (clientId !== undefined && clientId !== null) url_ += "clientId=" + encodeURIComponent("" + clientId) + "&";
        if (userId !== undefined && userId !== null) url_ += "userId=" + encodeURIComponent("" + userId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processImpersonate(_response);
            });
    };
    LoginClient.prototype.processImpersonate = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.phoneLogin = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/phone-login";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPhoneLogin(_response);
            });
    };
    LoginClient.prototype.processPhoneLogin = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.requestVerificationCode = function (mobilePhone) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/request-verification-code?";
        if (mobilePhone !== undefined && mobilePhone !== null)
            url_ += "mobilePhone=" + encodeURIComponent("" + mobilePhone) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "POST",
            headers: {
                Accept: "application/octet-stream",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processRequestVerificationCode(_response);
            });
    };
    LoginClient.prototype.processRequestVerificationCode = function (response) {
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200 || status === 206) {
            var contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            var fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            var fileName_5 = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob().then(function (blob) {
                return { fileName: fileName_5, data: blob, status: status, headers: _headers };
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    LoginClient.prototype.credentials = function (name) {
        var _this = this;
        var url_ = this.baseUrl + "/api/login/credentials?";
        if (name === null) throw new Error("The parameter 'name' cannot be null.");
        else if (name !== undefined) url_ += "name=" + encodeURIComponent("" + name) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processCredentials(_response);
            });
    };
    LoginClient.prototype.processCredentials = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status === 401) {
            return response.text().then(function (_responseText) {
                var result401 = null;
                result401 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return throwException("A server side error occurred.", status, _responseText, _headers, result401);
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return LoginClient;
})(ApiClientBase);
export { LoginClient };

var GroupsClient = /** @class */ (function (_super) {
    __extends(GroupsClient, _super);
    function GroupsClient(configuration, baseUrl, http) {
        var _this = _super.call(this, configuration) || this;
        _this.jsonParseReviver = undefined;
        _this.http = http ? http : window;
        _this.baseUrl = _this.getBaseUrl("", baseUrl);
        return _this;
    }
    GroupsClient.prototype.query = function (userId, allGroups, courseId, groupTypeId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups?";
        if (userId !== undefined && userId !== null) url_ += "UserId=" + encodeURIComponent("" + userId) + "&";
        if (allGroups !== undefined && allGroups !== null)
            url_ += "AllGroups=" + encodeURIComponent("" + allGroups) + "&";
        if (courseId !== undefined && courseId !== null) url_ += "CourseId=" + encodeURIComponent("" + courseId) + "&";
        if (groupTypeId !== undefined && groupTypeId !== null)
            url_ += "GroupTypeId=" + encodeURIComponent("" + groupTypeId) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processQuery(_response);
            });
    };
    GroupsClient.prototype.processQuery = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupsClient.prototype.post = function (model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups";
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPost(_response);
            });
    };
    GroupsClient.prototype.processPost = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupsClient.prototype.get = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processGet(_response);
            });
    };
    GroupsClient.prototype.processGet = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupsClient.prototype.put = function (id, model) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(model);
        var options_ = {
            body: content_,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPut(_response);
            });
    };
    GroupsClient.prototype.processPut = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupsClient.prototype.patch = function (id, patch) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var content_ = JSON.stringify(patch);
        var options_ = {
            body: content_,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processPatch(_response);
            });
    };
    GroupsClient.prototype.processPatch = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    GroupsClient.prototype.delete = function (id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/Groups/{id}";
        if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
        url_ = url_.replace("{id}", encodeURIComponent("" + id));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        };
        return this.transformOptions(options_)
            .then(function (transformedOptions_) {
                return _this.http.fetch(url_, transformedOptions_);
            })
            .then(function (_response) {
                return _this.processDelete(_response);
            });
    };
    GroupsClient.prototype.processDelete = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) {
                return (_headers[k] = v);
            });
        }
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return GroupsClient;
})(ApiClientBase);
export { GroupsClient };
