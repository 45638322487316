import { FileButton } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { PrimaryButton } from "@/components";

export type FileUploaderProps = {
    handleFile: (file: File) => void | Promise<void>;
    acceptedFiles: string;
    loading?: boolean;
};

const FileUploader = (props: FileUploaderProps) => {
    const { t } = useTranslate();
    return (
        <FileButton onChange={file => (file ? props.handleFile(file) : {})} accept={props.acceptedFiles}>
            {inner => {
                return (
                    <PrimaryButton {...inner} loading={props.loading}>
                        {t("ORGANISATION_ADD_DEPARTMENTS_UPLOAD")}
                    </PrimaryButton>
                );
            }}
        </FileButton>
    );
};

export default FileUploader;
