import React from "react";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { scroller } from "react-scroll";
import { useTranslate } from "@tolgee/react";
// @ts-ignore
import textWrap from "svg-text-wrap";
import { AnswerGroup } from "../../data/AnswerGroup";
import { Answer } from "@/api-client";
import { useAllGroups } from "../../../../common/OrganisationContext";
import { Group } from "@/api-client";

type CategoryGraphProps = {
    answerGroups: AnswerGroup[];
};

// Removed typing to make things build. Only breaks when building production.
//@ts-ignore
const RenderTooltip = ({ active, payload, toolTipLabel }) => {
    if (active && payload && payload.length > 0) {
        //@ts-ignore
        const value = Math.floor(payload[0].payload.count);
        return (
            <div className="custom-tooltip">
                {value} {toolTipLabel}
            </div>
        );
    }

    return null;
};

type CategoryData = {
    id: string;
    name: string;
    answers: Answer[];
    color: string;
    count: number;
};

const getUniqueValues = (array: Group[]) => [...new Set(array)];

type LegendPayload = {
    value: string;
    type: "line" | "plainline" | "square" | "rect" | "circle" | "cross" | "diamond" | "star" | "triangle" | "wye";
    id: string;
};

//We cannot use Recharts built-in legend system since the data is not grouped in that way
//Instead we need to find all legends in graph manually
const getLegends = (answerGroups: AnswerGroup[], allGroups: Group[]): LegendPayload[] => {
    let legends = answerGroups.map(group => allGroups.find(x => x.id === group?.answers[0]?.groupId));
    //@ts-ignore
    const unique = getUniqueValues(legends);
    return unique.map(x => {
        return {
            value: x.name,
            type: "rect",
            id: x.name,
            color: x.color,
        };
    });
};

const getCategories = (answerGroups: AnswerGroup[], allGroups: Group[]): CategoryData[] => {
    return answerGroups
        .map(group => {
            const groupGroup = allGroups.find(x => x.id === group.answers[0].groupId);
            return {
                id: group.id,
                name: group.name,
                answers: group.answers,
                //@ts-ignore
                color: groupGroup.color,
                count: group.answers.length,
            };
        })
        .sort((a, b) => b.count - a.count);
};

const getXDomain = (answerGroups: CategoryData[]): number[] => {
    let highestAnswer = answerGroups[0].count;
    const reminder = 4 - (highestAnswer % 4);

    //The graphs has four ticks, so to get even labels we ensure that the last
    //value in the x-domain is dividable by 4
    return [0, highestAnswer + reminder];
};

const getMaxLabelWidth = (wantedWidth: number) => {
    return Math.min(wantedWidth, 400);
};

const AxisLabel = (props: any) => {
    const lines = textWrap(props.payload.value, props.maxWidth, { "letter-spacing": "0.1px" });

    return (
        <g transform={`translate(${0}, ${props.y})`}>
            <text x={0} y={-15} dy={0} textAnchor="start" fill="#666">
                {
                    //@ts-ignore
                    lines.map((line, ix) => (
                        <tspan textAnchor="start" key={ix} x="0" dy="20">
                            {line}
                        </tspan>
                    ))
                }
            </text>
        </g>
    );
};

const CustomBarLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    const margin = 5;

    return <text x={x + width + margin} y={y + height / 2 + margin} fill="#666" textAnchor="start">{`${value}`}</text>;
};

const CategoryGraph = ({ answerGroups }: CategoryGraphProps): JSX.Element => {
    const hasCategories = answerGroups.length > 0;
    const { t } = useTranslate();
    const allGroups = useAllGroups();
    //@ts-ignore
    const categoryData = getCategories(answerGroups, allGroups);

    const handleCategoryScroll = (catId: string) => {
        const elm = document.getElementById(catId);

        scroller.scrollTo(catId, {
            duration: 300,
            smooth: true,
            offset: -60 - 140, //Scrolls to element + 50 pixels down the page. (110 offsets sticky question)
        });

        if (document.getElementsByClassName("fullscreen-enabled")?.item(0) !== null) {
            setTimeout(function () {
                //@ts-ignore
                elm.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
                //@ts-ignore
                elm.scrollTo({
                    top: -60,
                    left: -140,
                    behavior: "smooth",
                });
            }, 800);

            return;
        }
    };

    const longestTextWidth = getLongestWidthFromTexts(categoryData.map(x => x.name));
    const width = getMaxLabelWidth(longestTextWidth);

    return (
        <>
            {hasCategories && (
                <ResponsiveContainer width="100%" height={600}>
                    <BarChart
                        data={categoryData}
                        layout="vertical"
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid horizontal={false} />
                        <YAxis
                            width={width}
                            interval={0}
                            dataKey={entry => {
                                const x = entry as CategoryData;
                                return `${x.name}`;
                            }}
                            tick={<AxisLabel maxWidth={width} />}
                            type="category"
                        />
                        <XAxis domain={getXDomain(categoryData)} type="number" />
                        <Tooltip
                            content={
                                <RenderTooltip
                                    toolTipLabel={t("WORKSHOP_GRAPH_ANSWERS")}
                                    active={undefined}
                                    payload={undefined}
                                />
                            }
                        />
                        //@ts-ignore
                        <Legend
                            payload={getLegends(
                                categoryData,
                                //@ts-ignore
                                allGroups
                            )}
                        />
                        <Bar dataKey="count" label={<CustomBarLabel />}>
                            {categoryData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                    onClick={() => handleCategoryScroll(`datagroup-${entry.id}`)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
        </>
    );
};

export default CategoryGraph;

//https://github.com/recharts/recharts/issues/2027
//https://github.com/recharts/recharts/issues/1127#issuecomment-371759576
const getLongestWidthFromTexts = (texts: string[]) => {
    //@ts-ignore
    let canvas;
    //@ts-ignore
    let root;

    const getFont = () => {
        //@ts-ignore
        const div = root || document.querySelector("#root"); // root could be your react mount node or a node expected to exist
        const style = window.getComputedStyle(div);
        return style.getPropertyValue("font");
    };

    const getTextWidth = (text: string) => {
        //@ts-ignore
        canvas = canvas || document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = getFont();
        const metric = context.measureText(text);
        return Math.ceil(metric.width * 1.1); // measureText method's return value may be slightly underestimated, add some pixels or scale it
    };

    const allWidths = texts.map(x => getTextWidth(x));
    return Math.max(...allWidths);
};
