import { Round } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import scrollToElement from "scroll-to-element";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { useCurrentUser } from "../../../common/user/context";
import { showToast } from "../../../components/Notify";
import PageHeading from "../../../components/PageHeading";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "../../../components/Spinner";
import { CreateRound } from "./CreateRound";
import { RoundsListing } from "./RoundsListing";
import "./style.scss";
import { CardBlock } from "../../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

type RoundProps = {
    roundId?: number;
    template?: string;
};

export const Rounds = ({ template }: RoundProps): JSX.Element => {
    const api = createApi();
    const user = useCurrentUser();
    /* @ts-ignore */
    const [rounds, setRounds] = useState<Round[]>(null);
    /* @ts-ignore */
    const [editRound, setEditRound] = useState<Round>(null);

    useEffect(() => {
        if (user) {
            api.rounds.query(user.clientId).then(
                response => {
                    setRounds(response);
                },
                error => {
                    console.log(error, "Errror in Getting Rounds....");
                }
            );
        }
    }, [user]);

    const saveRound = (values: Round) => {
        if (values.id === undefined) {
            api.rounds.post(values).then(response => {
                setRounds(s => [...s, response]);
                showToast("Round created successfully.", "success");
            });
        } else {
            const patch = createPatch(editRound, s => {
                s.name = values.name;
                s.startDate = values.startDate;
                s.endDate = values.endDate;
            });

            if (patch && patch.length > 0) {
                api.rounds.patch(values.id, patch).then(
                    result => {
                        setRounds(s => s.map(s => (s.id === values.id ? result : s)));
                        /* @ts-ignore */
                        setEditRound(null);
                        showToast("Round updated successfully.", "success");
                    },
                    error => {
                        console.log(error, "Error in updating round.");
                    }
                );
            }
        }
    };

    const handleEditRound = (roundId: number) => {
        /* @ts-ignore */
        setEditRound(rounds.find(r => r.id === roundId));
        scrollToElement("#create-round", {
            offset: -120,
            align: "top",
            duration: 5,
        });
    };

    const handleDeleteRound = (roundId: number) => {
        confirmAlert({
            title: `Are you sure want to delete Round ?`,
            message: "Action can't be undone",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        api.rounds.delete(roundId).then(
                            _ => {
                                setRounds(s => [...s.filter(r => r.id !== roundId)]);
                                showToast("Round removed successfully.", "success");
                            },
                            error => {
                                console.log(error, "Error in delete rounds.");
                            }
                        );
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    return (
        <React.Fragment>
            <RenderIf show={template === "rounds"}>
                <CardBlock mb="xl">
                    <AppLoader loading={false} className="position-absolute" />
                    <PageHeading
                        contentLabel={"Rounds"}
                        detailText={"Create and Manage Rounds."}
                        className="account-settings"
                    />
                    <Row>
                        <Col md={12}>
                            <RoundsListing
                                rounds={rounds}
                                editRound={(roundId: number) => handleEditRound(roundId)}
                                deleteRound={(roundId: number) => handleDeleteRound(roundId)}
                            />
                        </Col>
                    </Row>
                </CardBlock>
                <CardBlock mb="xl">
                    <Row className="department-slide row">
                        <Col md={12}>
                            <PageHeading
                                contentLabel={editRound ? "Edit Round" : "Create Round"}
                                className="account-settings"
                            />
                            {/* Create Rounds */}

                            <CreateRound
                                /* @ts-ignore */
                                clientId={user?.clientId}
                                handleSubmit={(round: Round) => saveRound(round)}
                                editRound={editRound}
                            />
                        </Col>
                    </Row>
                </CardBlock>
            </RenderIf>
        </React.Fragment>
    );
};
