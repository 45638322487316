import React, { useEffect, useState } from "react";

export const StickyQuestionTitle = (props: { moduleName: string; text: string }): JSX.Element => {
    const [sticky, setSticky] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 210) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    });

    const classes = [sticky ? "stiky-question-title fixed-top bg-white" : "sticky-question-hide"];
    return (
        <>
            {sticky ? (
                <div className={classes.join(" ")} style={{ minHeight: "120px", top: "60px", display: "block" }}>
                    <div className="container mt-4 mb-2">
                        <p className="content-label content-label-module me-auto ms-5 ">{props.moduleName}</p>
                        <h1 className="me-auto ms-5 ">{props.text}</h1>
                    </div>
                </div>
            ) : null}
        </>
    );
};
