import { TeamLayout } from "components/Layout/Team/TeamLayout";
import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";

interface OnboardingLayoutProps {
    children: ReactNode;
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps): JSX.Element => {
    return (
        <Container fluid={true} id="layout-container-resources">
            <TeamLayout>
                <div id="wrapper">{children}</div>
            </TeamLayout>
        </Container>
    );
};

export default OnboardingLayout;
