import { useEffect, useState } from "react";
import { Space } from "@mantine/core";
import { ProgramsAndCapabilitiesList } from "./ProgramsAndCapabilitiesList";
import { GetProgramsAndCapabilitiesDto } from "@/api-client";
import { createApi } from "@/common";

export const ProgramsAndCapabilitiesPage = () => {
    const [programsAndCapabilities, setProgramsAndCapabilities] = useState<GetProgramsAndCapabilitiesDto[] | null>(
        null
    );

    useEffect(() => {
        const abortController = new AbortController();

        createApi()
            .sysadmin.getProgramsAndCapabilities(abortController)
            .then(setProgramsAndCapabilities)
            .catchWithToast();

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <ProgramsAndCapabilitiesList programsAndCapabilities={programsAndCapabilities} />
            <Space h="lg" />
        </>
    );
};
