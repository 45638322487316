import { Group, TaskCountPerGroup } from "@/api-client";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../common/api";
import { AppLoader } from "../../components/Spinner";
import { createStyles, Flex, Text } from "@mantine/core";
import { dark, darkPurple, darkSmoke } from "../../common/colors";
import { CardBlock } from "../../components/CardBlock";
import { UrlFactory } from "routing/UrlFactory";
import { HomeLinkButton } from "./HomeLinkButton";
import { TaskSummaryInfo, TaskSummaryInfoData } from "./TaskSummaryInfo";

/*  eslint-disable react-hooks/exhaustive-deps */
const useStyles = createStyles(theme => ({
    card: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },
}));

export interface TaskSummaryProps {
    team: Group;
}

export const TaskSummary = (props: TaskSummaryProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const [taskData, setTaskData] = useState<TaskSummaryInfoData[] | null>(null);
    const { classes } = useStyles();

    useEffect(() => {
        const darkSmokeHex = darkSmoke.hex;
        const darkPurpleHex = darkPurple.hex;

        api.reports.getTaskCountPerGroup(props.team.clientId).then(data => {
            const teamData = data.find(x => x.groupId === props.team.id);

            const getValueOrDefault = (value: number | undefined) => value ?? 0;
            const getSum = (value: (x: TaskCountPerGroup) => number): number => _.sumBy(data, value);

            setTaskData([
                {
                    title: t("BOARDS_INBOX"),
                    teamValue: getValueOrDefault(teamData?.inbox),
                    organisationValue: getSum(x => x.inbox),
                    teamValueColor: darkSmokeHex,
                },
                {
                    title: t("BOARDS_TODO"),
                    teamValue: getValueOrDefault(teamData?.toDo),
                    organisationValue: getSum(x => x.toDo),
                    teamValueColor: darkSmokeHex,
                },
                {
                    title: t("BOARDS_IN_PROGRESS"),
                    teamValue: getValueOrDefault(teamData?.inProgress),
                    organisationValue: getSum(x => x.inProgress),
                    teamValueColor: darkSmokeHex,
                },
                {
                    title: t("HOME_DONE_AND_COMPLETED"),
                    teamValue: getValueOrDefault(teamData?.done) + getValueOrDefault(teamData?.completed),
                    organisationValue: getSum(x => x.done) + getSum(x => x.completed),
                    teamValueColor: darkPurpleHex,
                },
            ]);
        });
    }, [props.team.id, t]);

    if (taskData === null) {
        return (
            <CardBlock classNames={{ card: classes.card }}>
                <AppLoader loading={true} />
            </CardBlock>
        );
    }

    return (
        <CardBlock classNames={{ card: classes.card }}>
            <Flex rowGap={"md"} direction={"column"}>
                <Text size={"xl"}>{t("HOME_IMPROVEMENT_STATUS")}</Text>
                <Flex justify={"space-between"}>
                    {taskData.map((td, i) => (
                        <TaskSummaryInfo key={i} data={td} />
                    ))}
                </Flex>
                <Flex justify={"flex-end"}>
                    <HomeLinkButton
                        textKey={"HOME_IMPROVEMENTS"}
                        link={UrlFactory.board.create({ groupId: props.team.id! })}
                    />
                </Flex>
            </Flex>
        </CardBlock>
    );
};
