import { useTranslate } from "@tolgee/react";
import { useEffect, useMemo, useState } from "react";
import {
    BasicTable,
    BasicTableColumn,
    PrimaryButton,
    RowActions,
    useDeleteConfirmationModal,
    useModal,
} from "@/components";
import { createApi } from "@/common";
import { ProgramAssignmentModal } from "./ProgramAssignmentModal";

type TeamListProps = {
    program: {
        id: number;
        name: string;
    };
};

type Team = {
    id: number;
    name: string;
    teamProgramId: number | null;
};

export const TeamsList = (props: TeamListProps) => {
    const { t } = useTranslate();
    const [teams, setTeams] = useState<Team[] | null>(null);
    const programAssignmentModal = useModal(ProgramAssignmentModal);
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const getTeams = async () => {
        const api = createApi();

        const result = await api.groups.query(null, null, null, null);

        setTeams(
            result
                .filter(x => x.status === "Active")
                .map(x => ({
                    name: x.name,
                    id: x.id!,
                    teamProgramId: x.courseAssignments.find(x => x.courseId === props.program.id)?.id ?? null,
                }))
        );
    };

    useEffect(() => {
        getTeams().catchWithToast();
    }, []);

    const teamsWithProgram = teams?.filter(x => x.teamProgramId !== null);
    const teamsWithoutProgram = teams?.filter(x => x.teamProgramId === null);

    const columns = useMemo<BasicTableColumn<Team>[]>(
        () => [
            {
                header: t("COMMON_NAME"),
                accessor: row => row.name,
            },
        ],
        []
    );

    const openAssignTeamModal = () =>
        programAssignmentModal.open({
            programId: props.program.id,
            teams: teamsWithoutProgram!,
            onClose: () => getTeams().catchWithToast(),
        });

    const openDeleteConfirmationModal = (teamProgramId: number, teamName: string) => {
        const removeProgramFromTeam = () => {
            createApi()
                .groupCourses.delete(teamProgramId)
                .then(() => getTeams())
                .catchWithToast();
        };

        deleteConfirmationModal.open({
            title: t("ACCOUNTOWNER_PROGRAMS_REMOVE_PROGRAM_FROM_TEAM", { team: teamName, program: props.program.name }),
            onConfirm: removeProgramFromTeam,
        });
    };

    return (
        <BasicTable
            columns={columns}
            data={teamsWithProgram}
            renderRowActions={({ row }) => (
                <RowActions.DeleteButton
                    onClick={() => openDeleteConfirmationModal(row.original.teamProgramId!, row.original.name)}
                />
            )}
            toolbarActions={() => (
                <PrimaryButton onClick={openAssignTeamModal} eventName={{ object: "ao_programassign", action: "open" }}>
                    {t("ACCOUNTOWNER_PROGRAMS_ASSIGN_PROGRAM_TO_TEAM")}
                </PrimaryButton>
            )}
        />
    );
};
