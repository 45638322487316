import { Badge } from "@mantine/core";
import React from "react";
import { useTranslate } from "@tolgee/react";

export const CompletedProgram = () => {
    const { t } = useTranslate();
    return (
        <Badge bg="green.0" variant="light" radius="xl" size="sm" color="green.8">
            {t("HOME_COMPLETED")}
        </Badge>
    );
};
