import { useLocation } from "react-router-dom";
import { Row } from "react-bootstrap";
import { ReactNode } from "react";
import Footer from "../Footer/footer";
import { getLocationClassName } from "./LayoutUtils";

export const CenteredLayout = (props: { children: ReactNode }): JSX.Element => {
    const location = useLocation();
    const styles = {
        marginTop: "6rem",
    };

    const locationClass = getLocationClassName(location);

    return (
        <>
            <div style={styles} className={"container"}>
                <Row>
                    <div className={`content_area w-100  ${locationClass}`}>
                        <div className={"w-100"} id="wrapper">
                            {props.children}
                        </div>
                    </div>
                </Row>
            </div>
            <Footer />
        </>
    );
};
