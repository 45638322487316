import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import PageHeading from "../../components/PageHeading";
import { AppLoader } from "../../components/Spinner";
import { OrganisationalValidationsError, OrganisationDetailsForm } from "./OrganisationalDetails";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { CardBlock } from "../../components/CardBlock";
import { ContentBlockButtonContainer } from "../../components/ContentBlock/ContentBlockButtonContainer";

export const EditOrganisation = () => {
    const { t } = useTranslate();
    const childRef = useRef<any>(null);

    const handleSave = () => {
        const current = childRef.current;
        if (null !== current) {
            current.saveOrganisation();
        }
    };
    const [isPending, setIsPending] = useState<boolean>(false);
    return (
        <React.Fragment>
            <CardBlock>
                <AppLoader loading={isPending} className="position-absolute" />
                <PageHeading
                    contentLabel={t("ACCOUNTOWNER_EDIT_ORGANISATION_LABEL")}
                    detailText={t("ACCOUNTOWNER_EDIT_ORGANISATION_DETAILS_TEXT")}
                    className="account-settings"
                />
                <Row>
                    <Col md={12}>
                        <OrganisationDetailsForm
                            error={(error: OrganisationalValidationsError) => console.log(error)}
                            isPending={(isPending: boolean) => setIsPending(isPending)}
                            ref={childRef}
                            redirect={false}
                        />
                        <ContentBlockButtonContainer>
                            <PrimaryButton onClick={handleSave}>{t("COMMON_SAVE")}</PrimaryButton>
                        </ContentBlockButtonContainer>
                    </Col>
                </Row>
            </CardBlock>
        </React.Fragment>
    );
};
