﻿import { NavLink, createStyles, rem, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles(theme => {
    return {
        mainLink: {
            width: "auto",
            borderBottom: `${rem(2)} solid transparent`,

            "&:hover": {
                textDecoration: "none",
            },

            "&:is([data-active])": {
                borderBottomColor: theme.colorScheme === "dark" ? theme.white : theme.colors[theme.primaryColor][5],
                "&:hover": {
                    backgroundColor: theme.colors.gray[0],
                },
            },
        },
        mainLinkBody: {
            overflow: "visible",
        },
    };
});

type HeaderItemProps = {
    label: string | React.ReactNode;
    active: boolean;
    to?: string;
    onClick?: () => void;
};

export const HeaderItem = (props: HeaderItemProps) => {
    const { classes } = useStyles();

    return (
        <NavLink
            classNames={{
                body: classes.mainLinkBody,
                root: classes.mainLink,
            }}
            variant="subtle"
            label={<Text size="1rem"> {props.label} </Text>}
            to={props.to ?? ""}
            component={Link}
            active={props.active}
            onClick={props.onClick}
        />
    );
};
