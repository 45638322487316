﻿import { ActionIcon } from "@mantine/core";
import { Icons } from "@/common";

type EditLinkProps = {
    onClick: () => void;
};

export const EditButton = (props: EditLinkProps) => {
    return (
        <ActionIcon onClick={props.onClick}>
            <Icons.Edit />
        </ActionIcon>
    );
};
