import { ListGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Answer, Question, User } from "@/api-client";
import { QuestionTemplateProps } from "./models/QuestionTemplateProps";
import useDebounce from "../UseDebounce/UseDebounce";
import { useTranslate } from "@tolgee/react";
import { useUserContext } from "../../common/user/context";
import { useCurrentCourseUserAnswers } from "../../common/courses/context";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface SingleChoiceAnswer {
    answer: Answer;
    isValid?: boolean;
    isSelected?: boolean;
    error?: string;
}

interface SingleChoiceAnswersProps extends QuestionTemplateProps {
    index?: number;
    currentProgress?: boolean | null;
    questionsWithAnswers?: Question;
    error?: string[] | undefined;
}

//change this
export const getInitValues = (question: Question, existingAnswers: Answer[], user: User): SingleChoiceAnswer[] => {
    const singleChoiceAnswer = _.takeRight(existingAnswers, 1);
    // @ts-ignore
    const myAnswers = question.options.map(a => {
        const existingAnswer = singleChoiceAnswer.find(x => x.text === a.text);
        const answer = {
            id:
                singleChoiceAnswer && singleChoiceAnswer[0] && singleChoiceAnswer[0].id
                    ? singleChoiceAnswer[0].id
                    : undefined,
            optionAnswerId: a.id,
            text: a.text,
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            userId: user.id,
            order: a.order,
            ...existingAnswer,
        };
        return {
            answer: answer,
            isSelected: existingAnswer !== undefined,
        };
    });
    // @ts-ignore
    return myAnswers;
};

export const SingleChoiceAnswers = (props: SingleChoiceAnswersProps): JSX.Element => {
    const { t } = useTranslate();

    const userContext = useUserContext();
    const [answers, setAnswers] = useState<SingleChoiceAnswer[]>();
    const [errors, setErrors] = useState<string[]>();
    // Initialize answers
    const allAnswers = useCurrentCourseUserAnswers();
    useEffect(() => {
        if (props.question && userContext.user && props.question.options) {
            const initValues = getInitValues(props.question, props.answers, userContext.user);
            setAnswers(initValues);
        }
    }, [props.question, allAnswers]);

    const answersStateChange = useDebounce(answers, 500);

    useEffect(() => {
        if (answers && props.question) {
            const findValidAnswer = answers.filter(a => a.isSelected === true);
            let isValid = true;
            if (findValidAnswer.length === 0) {
                if (!findValidAnswer) {
                    setErrors([t("MODULE_VALIDATE_SINGLE_CHOICE_MIN_REQUIRED")]);
                    isValid = false;
                } else {
                    setErrors([]);
                }
                return;
            }

            // Update parent form
            props.setValidation(isValid, true);
            const selectedAnswer = answers.filter(x => x.isSelected).map(a => a.answer);
            props.setAnswers(selectedAnswer);
        }
    }, [answersStateChange]);

    const setAnswerValue = (answer: SingleChoiceAnswer, value: string) => {
        setAnswers(prevState => {
            if (!prevState) {
                return;
            }
            return prevState?.map(a => {
                if (a.answer.text === value) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            });
        });
    };

    return (
        <div className="row">
            <div className="col-12 col-md-4 ">
                <div className="block-content-text">
                    <h4>{props.question?.text}</h4>
                    <p>{props.question?.text}</p>
                    <p>{props.question?.description}</p>
                </div>
            </div>
            <div className="col-12 col-md-8 text-secondary">
                <div className="block-content-option">
                    <p className="px-5 question_type"></p>
                    <ListGroup>
                        {answers &&
                            answers.map((answer, index) => {
                                return (
                                    <ListGroup.Item
                                        id={`question-${index}-${props.question?.questionTypeId}-questions`}
                                        key={`question_${index}_${props.question?.id}`}
                                        className={
                                            (answer.isSelected ? "active_selection" : "inactive") + " p-1 border mb-2"
                                        }
                                    >
                                        <div className="rasting-value">
                                            <label className="m-0 d-block" style={{ top: "5px" }}>
                                                <input
                                                    type="radio"
                                                    className="me-1"
                                                    id={`questions.question-${props.question?.id}.value`}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setAnswerValue(answer, e.target.value)
                                                    }
                                                    checked={answer.isSelected}
                                                    name={`questions.question-${props.question?.id}.value`}
                                                    value={answer.answer.text}
                                                    //ref={(e)=>refs[index]=e}
                                                    disabled={props.currentProgress ? true : false}
                                                />
                                                <span className="mb-1 position-absolute" style={{ top: "4px" }}>
                                                    {answer.answer.text}
                                                </span>
                                            </label>
                                        </div>
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                    {((errors && errors.length === 0) || errors === undefined) && props.error && (
                        <p className="error learfix w-100 text-danger">
                            {t("MODULE_VALIDATE_SINGLE_CHOICE_MIN_REQUIRED")}
                        </p>
                    )}
                    {errors &&
                        errors.map((error, index) => (
                            <div key={index} className="clearfix w-100 text-danger">
                                {error}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
