import { Question } from "@/api-client";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { Notify } from "../../../../components/Notify";
import { AppLoader } from "../../../../components/Spinner";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () => {
    return Yup.object().shape({
        low: Yup.number().typeError("Low's value must be number type"),
        high: Yup.number().typeError("High's value must be number type"),
        charLimit: Yup.number().typeError("Char Limit's value must be number type"),
    });
};

interface FormModel {
    questionId: number;
    low: number;
    high: number;
    description: string;
}

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

interface EditQuestionResponse {
    question: Question;
    isPending: boolean;
    isSuccess: boolean;
}

const Rating = (props: EditQuestionProps): JSX.Element => {
    const api = createApi();

    const initialValues: FormModel = {
        // @ts-ignore

        questionId: props.question?.id,
        // @ts-ignore

        low: props.question?.minAnswer,
        // @ts-ignore

        high: props.question?.maxAnswer,
        // @ts-ignore

        description: props.question?.description,
    };
    const [editQuestion, setEditQuestion] = useState<EditQuestionResponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        question: null,
        isSuccess: false,
    });
    const [submitted, setSubmitted] = useState(false);
    const isLoading = editQuestion.isPending ? true : false;
    return (
        <>
            <AppLoader loading={isLoading ? true : false} className="position-absolute" />
            {editQuestion.isSuccess && submitted && (
                <Fragment>
                    <Notify message="Question updated successfully." id="registersuccess" type="success" />
                    {/* <Redirect to="/sysadmin/clients" /> */}
                </Fragment>
            )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={values => {
                    setSubmitted(true);
                    setEditQuestion({ ...editQuestion, isPending: true });
                    const patch = createPatch(props.question, x => {
                        x.id = values.questionId;
                        x.minAnswer = values.low;
                        x.maxAnswer = values.high;
                        x.description = values.description;
                    });

                    if (patch && patch.length > 0) {
                        // Update profile
                        // @ts-ignore

                        api.questions.patch(props.question?.id, patch).then(
                            result => {
                                setEditQuestion({
                                    ...editQuestion,
                                    question: result,
                                    isPending: false,
                                    isSuccess: true,
                                });
                                //dispatch(push(`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/exercise/${props.exerciseId}/edit`));
                            },
                            error => {
                                setEditQuestion({ ...editQuestion, isPending: false });
                                console.log(error);
                            }
                        );
                    } else {
                        setEditQuestion({ ...editQuestion, isPending: false });
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="ratingAnswer" className="mt-2 editModuleForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Low</label>
                                    <Field
                                        name="low"
                                        type="text"
                                        className={"form-control" + (errors.low && touched.low ? " is-invalid" : "")}
                                    />
                                    {errors.low && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.low}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>High</label>
                                    <Field
                                        name="high"
                                        type="text"
                                        className={"form-control" + (errors.high && touched.high ? " is-invalid" : "")}
                                    />
                                    {errors.high && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.high}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Description</label>
                                    <Field
                                        name="description"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.description && touched.description ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.description && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.description}
                                        </span>
                                    )}
                                </Col>
                            </Row>

                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={isLoading}>
                                    Update
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default Rating;
