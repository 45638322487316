import React, { useRef, useState } from "react";
import { ModuleBlock } from "@/api-client";
import { useDrag, useDrop } from "react-dnd";
import { createApi } from "../../../common/api";
import { showToast } from "../../../components/Notify";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { CopyToDialog, CopyToDialogProps, LevelEnum } from "../CopyToDialog";
import { CopyAction } from "../CopyAction";
import { EditAction } from "../EditAction";

export const ItemType = {
    CARD: "moduleBlock",
};

export const ModuleBlockCard = (props: {
    moduleBlock: ModuleBlock;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    courseId: number;
    chapterId: number;
    id: number;
}) => {
    const [showCopyDialog, setShowCopyDialog] = useState<boolean>();

    const ref = useRef(null);
    const api = createApi();
    const sysadminContextDispatch = useSysadminContextDispatch();

    // @ts-ignore
    const [{ handlerId }, drop] = useDrop({
        accept: ItemType.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        // @ts-ignore

        hover(item: { id: number; index: number }, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // @ts-ignore

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            // @ts-ignore

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType.CARD,
        item: () => {
            return {
                id: props.id,
                index: props.index,
            };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const containerClass = isDragging ? "dragging-container" : "listing-container";
    drag(drop(ref));

    // QA: This is a poor implementation and should be improved with another way.
    // @ts-ignore
    const copyToProps: CopyToDialogProps = {
        initialSelection: {
            courseId: props.courseId,
            chapterId: props.chapterId,
            moduleId: props.moduleBlock.moduleId,
        },
        level: LevelEnum.Module,
        onSelected: data => {
            // Clone chapter and set Id to zero so we create new instead of updating existing
            // @ts-ignore

            const moduleBlockClone: ModuleBlock = { ...props.moduleBlock, id: 0, moduleId: data.module.id };

            // Update local state
            api.moduleBlocks.post(moduleBlockClone).then(
                x => {
                    sysadminContextDispatch(s => ({
                        ...s,
                        // @ts-ignore

                        courses: s.courses.map(c => {
                            if (c.id === data.course.id) {
                                return {
                                    ...c,
                                    // @ts-ignore

                                    chapters: c.chapters.map(ch => {
                                        // @ts-ignore

                                        if (ch.id === data.chapter.id) {
                                            return {
                                                ...ch,
                                                // @ts-ignore

                                                modules: ch.modules.map(m => {
                                                    if (m.id === x.moduleId) {
                                                        return {
                                                            ...m,
                                                            // @ts-ignore

                                                            blocks: [...m.blocks, x],
                                                        };
                                                    } else {
                                                        return m;
                                                    }
                                                }),
                                            };
                                        } else {
                                            return ch;
                                        }
                                    }),
                                };
                            } else {
                                return c;
                            }
                        }),
                    }));

                    showToast(
                        // @ts-ignore
                        `Module block successfully copied to ${data.module.name}`,
                        "success"
                    );
                },
                error => {
                    console.log(error);
                }
            );

            // Hide modal
            setShowCopyDialog(false);
        },
        // @ts-ignore

        show: showCopyDialog,
        onClose: () => setShowCopyDialog(false),
    };

    return (
        <>
            <tr
                key={`blocks-listing-${props.moduleBlock?.id}`}
                ref={ref}
                className={containerClass}
                data-handler-id={handlerId}
            >
                <td>{props.index + 1}.</td>
                <td>{props.moduleBlock?.title}</td>
                <td>{props.moduleBlock?.type}</td>
                <td className="col-1">
                    <EditAction
                        link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleBlock.moduleId}/block/${props.moduleBlock.id}/edit`}
                    ></EditAction>
                </td>
                <td className="col-1">
                    <CopyAction onClick={() => setShowCopyDialog(true)}></CopyAction>
                </td>
            </tr>
            {showCopyDialog && <CopyToDialog {...copyToProps} />}
        </>
    );
};
