﻿import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import {
    BasicTable,
    BasicTableColumn,
    PrimaryButton,
    RowActions,
    useDeleteConfirmationModal,
    useModal,
    UserStatusBadge,
} from "@/components";
import { createApi, Icons, UrlFactory } from "@/common";
import { AddTeamMemberModal } from "./AddTeamMember";
import { NameCell } from "../../NameCell";
import { EditTeamMembershipModal } from "../../EditTeamMembership";
import { UserStatus, UserStatuses } from "@/api-client";
import { translateInviteStatus } from "../../../../sysadmin/helper";

export type TeamMember = {
    id: number;
    isParticipant: boolean;
    isTeamLeader: boolean;
    email: string | null;
    phoneNumber: string | null;
    avatarUrl: string | null;
    displayName: string | null;
    status: UserStatuses;
};

type MembersListProps = {
    members: TeamMember[];
    teamId: number;
    onMemberAdded: () => void;
};

export const MembersList = (props: MembersListProps) => {
    const { t } = useTranslate();
    const addTeamMemberModal = useModal(AddTeamMemberModal);
    const editMembershipModal = useModal(EditTeamMembershipModal);
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const openEditTeamMembershipModal = (row: TeamMember) => {
        editMembershipModal.open({
            userId: row.id,
            teamId: props.teamId,
            isGroupLeader: row.isTeamLeader,
            isParticipant: row.isParticipant,
            onClose: props.onMemberAdded,
        });
    };

    const openDeleteConfirmationModal = (row: TeamMember) => {
        const removeMembership = () => {
            return createApi()
                .groupMembership.delete(row.id, props.teamId)
                .then(() => props.onMemberAdded())
                .catchWithToast();
        };

        const getMemberInfo = () => {
            if (row.displayName) {
                return row.displayName;
            }
            if (row.email) {
                return row.email;
            }

            return row.phoneNumber;
        };

        deleteConfirmationModal.open({
            onConfirm: () => removeMembership(),
            title: t("ACCOUNTOWNER_EDIT_TEAMS_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_MEMBER_FROM_THE_TEAM", {
                member: getMemberInfo(),
            }),
        });
    };

    const translatedInviteStatus: { [key: string]: string } = translateInviteStatus(t);
    const translateStatus = (value: UserStatus) => translatedInviteStatus[value];

    const columns = useMemo<BasicTableColumn<TeamMember>[]>(
        () => [
            {
                id: "displayName",
                accessor: row => row.displayName,
                header: "COMMON_NAME",
                enableSorting: true,
                render: value => (
                    <NameCell
                        id={value.id}
                        email={value.email}
                        phoneNumber={value.phoneNumber}
                        avatarUrl={value.avatarUrl}
                        displayName={value.displayName}
                    />
                ),
            },
            {
                id: "status",
                header: t("ACCOUNTOWNER_USERS_STATUS"),
                accessor: user => translateStatus(user.status),
                render: member => {
                    return <UserStatusBadge status={member.status} />;
                },
                sortingFn: (a, b) => {
                    const getStatusSortValue = (member: TeamMember) => {
                        switch (member.status) {
                            case "Active":
                                return 2;
                            case "Inactive":
                                return 1;
                            default:
                                return -1;
                        }
                    };

                    const aSortValue = getStatusSortValue(a);
                    const bSortValue = getStatusSortValue(b);

                    if (aSortValue === bSortValue) {
                        return 0;
                    }

                    return aSortValue > bSortValue ? -1 : 1;
                },
            },
            {
                accessor: row => row.isParticipant,
                render: value => (value.isParticipant ? <Icons.Check /> : null),
                header: "EDITEMPLOYEE_PARTICIPANT",
            },
            {
                accessor: row => row.isTeamLeader,
                render: value => (value.isTeamLeader ? <Icons.Check /> : null),
                header: "EDITEMPLOYEE_TEAMLEADER",
            },
        ],
        []
    );

    return (
        <BasicTable
            data={props.members}
            columns={columns}
            toolbarActions={() => (
                <PrimaryButton
                    eventName={{ object: "ao_editteammembership", action: "open" }}
                    onClick={() =>
                        addTeamMemberModal.open({
                            teamId: props.teamId,
                            teamMemberIds: props.members.map(x => x.id),
                            onClose: props.onMemberAdded,
                        })
                    }
                >
                    {t("ACCOUNTOWNER_EDIT_TEAMS_ADD_MEMBER")}
                </PrimaryButton>
            )}
            renderRowActions={({ row }) => (
                <RowActions.ContextMenu.Menu>
                    <RowActions.ContextMenu.Button
                        onClick={() => openEditTeamMembershipModal(row.original)}
                        label="ACCOUNTOWNER_EDIT_TEAMS_EDIT_MEMBERSHIP"
                    />
                    <RowActions.ContextMenu.Button
                        onClick={() => openDeleteConfirmationModal(row.original)}
                        label="ACCOUNTOWNER_EDIT_TEAMS_REMOVE_MEMBERSHIP"
                    />
                    <RowActions.ContextMenu.Link
                        to={UrlFactory.accountOwner.user.create({ userId: row.original.id })}
                        label="ACCOUNTOWNER_EDIT_TEAMS_EDIT_USER"
                    />
                </RowActions.ContextMenu.Menu>
            )}
            initialSort={[
                { id: "status", desc: false },
                { id: "displayName", desc: false },
            ]}
        />
    );
};
