export type PersistedCourseAndGroupSelection = {
    courseId: number;
    groupId: number;
};
const SELECTED_GROUP_COURSE = "selectedGroupCourse";

export const setCourseGroupInLocalStorage = (courseId: number, groupId: number): void => {
    const persistedState: PersistedCourseAndGroupSelection = {
        courseId: courseId,
        groupId: groupId,
    };
    window.localStorage.setItem(SELECTED_GROUP_COURSE, JSON.stringify(persistedState));
};

export const getCourseGroupInLocalStorage = (): PersistedCourseAndGroupSelection | undefined => {
    const item = window.localStorage.getItem(SELECTED_GROUP_COURSE);
    return item !== null ? JSON.parse(item) : undefined;
};
