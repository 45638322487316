import { User } from "@/api-client";

export const cookieName = "platform_user";

type LoggedInUser = {
    user: User;
    token: string;
    expiry: number;
};
const impersonatedUserToken = "impersonated_userToken";

export abstract class UserStorage {
    static clearAll = () => {
        localStorage.removeItem(cookieName);
        UserStorage.clearImpersonationToken();
    };

    static setImpersonationToken = (token: string) => {
        sessionStorage.setItem(impersonatedUserToken, token);
    };

    static clearImpersonationToken = () => {
        sessionStorage.removeItem(impersonatedUserToken);
    };

    static getImpersonationToken = (): { token: string } | null => {
        const token = sessionStorage.getItem(impersonatedUserToken);
        if (token === null) {
            return null;
        }

        return {
            token: token,
        };
    };

    static setLoginToken = (token: string) => {
        UserStorage.setLoggedInUserLocalStorage(token, null!);
    };

    static getLoginToken = (): string | null => {
        const storedUser = UserStorage.getLoggedInUserLocalStorage();
        if (storedUser === undefined) {
            return null;
        }

        return storedUser.token;
    };

    static getCurrentToken = (): string | null => {
        const impersonationToken = UserStorage.getImpersonationToken();
        if (impersonationToken !== null) {
            return impersonationToken.token;
        }

        const loggedInUser = UserStorage.getLoggedInUserLocalStorage();
        if (loggedInUser !== undefined) {
            return loggedInUser.token;
        }

        return null;
    };

    private static setLoggedInUserLocalStorage = (token: string, user: User): void => {
        const days = 30;
        const hoursPerDay = 24;
        const secondsPerHour = 3600;
        const ttlMs = secondsPerHour * hoursPerDay * days * 1000;
        const expiryTime = new Date().getTime() + ttlMs;

        const cookieData: LoggedInUser = {
            token: token,
            user: user,
            expiry: expiryTime,
        };

        localStorage.setItem(cookieName, JSON.stringify(cookieData));
    };

    private static getLoggedInUserLocalStorage = (): LoggedInUser | undefined => {
        const data = localStorage.getItem(cookieName);
        if (data === null) {
            return undefined;
        }
        const now = new Date().getTime();
        const parsed = JSON.parse(data) as LoggedInUser;
        if (now > parsed.expiry) {
            localStorage.removeItem(cookieName);
            return undefined;
        }

        return parsed;
    };
}
