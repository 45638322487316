import { Chapter, Course, Group, Module } from "@/api-client";

/**
 * Return the moduleBlockIds that are existing in a module
 * @param course
 * @param chapterId
 * @param moduleId
 * @returns
 */
export const findModuleBlocksIds = (course: Course, chapterId: number, moduleId: number): number[] => {
    const moduleBlocksIds =
        course &&
        course.chapters &&
        // @ts-ignore
        course.chapters
            .find(c => c.id === chapterId)
            ?.modules.find(m => m.id === moduleId)
            ?.blocks.map(b => b.id);
    // @ts-ignore
    return moduleBlocksIds;
};

/**
 * Method used to check if enable categorisation exists or not
 * @param module
 * @returns
 */
export const hasCategorizeQuestions = (module: Module) => {
    // @ts-ignore
    const questions = module.blocks.flatMap(b => b.questions).filter(q => q !== null);
    const data = questions && questions.length > 0 ? questions.some(q => q?.enableCategorisation === true) : false;
    return data;
};

export const getNextModuleAndChapterLink = (
    course: Course,
    chapterId: number,
    moduleId: number,
    isGroupLeaderOrAccountOwner: boolean
): string => {
    let next = null;
    if (course) {
        const courseData = mapCoursesAndModules(course);
        if (courseData) {
            const currentIndex = courseData.findIndex(d => d.chapterId === chapterId && d.moduleId === moduleId);
            const getNextChapterAndModule = courseData[currentIndex + 1] ? courseData[currentIndex + 1] : undefined;
            if (getNextChapterAndModule && getNextChapterAndModule.chapterId && getNextChapterAndModule.moduleId) {
                next = `/workshop/chapter/${getNextChapterAndModule.chapterId}/module/${getNextChapterAndModule.moduleId}/presentation/slideshow/0`;
            } else if (isGroupLeaderOrAccountOwner) {
                next = `/workshop/chapter/${chapterId}/module/${moduleId}/presentation`;
            } else {
                next = "/";
            }
        }
    }
    // @ts-ignore
    return next;
};

const mapCoursesAndModules = (course: Course) =>
    // @ts-ignore

    course?.chapters
        // @ts-ignore

        .sort((a, b) => a.order - b.order)
        .filter(f => f.status === "Active")
        // @ts-ignore

        .map(chapter =>
            chapter.modules
                // @ts-ignore

                .sort((a, b) => a.order - b.order)
                .filter(modules => modules.status === "Active")
                .map(module => ({
                    chapterId: chapter.id,
                    moduleId: module.id,
                }))
        )
        .flat(1);

/**
 * Helper for creating link to workshop to manage link structure in one place
 * @param group
 * @returns
 */
export const getWorkshopLink = (groupId: Number): string => `/groups/${groupId}/workshop`;

export const getWorkshopPresentationLink = (
    groupId: number,
    chapter: Chapter,
    module: Module
    // @ts-ignore
): string =>
    // @ts-ignore
    chapter && module
        ? `${getWorkshopLink(groupId)}/presentation/chapter/${chapter?.id}/module/${module?.id}`
        : undefined;
