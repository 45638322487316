import { TrainingProgressPerDepartmentReport, TrainingProgressPerGroupReport } from "@/api-client";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import { setCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { useCurrentUser } from "../../common/user/context";
import { NavBar } from "../../components/NavBar";
import { NoCourseMessage } from "../Components/NoCourseMessage";
import { AccountOwnerLayout } from "../Layout";
import TrainingInProgress from "./Components/TrainingInProgress";
import { mapTrainingProgressWithCourse } from "./helper";
import { CardBlock } from "../../components/CardBlock";
import { Flex } from "@mantine/core";
import { useParams } from "react-router-dom";

/*  eslint-disable react-hooks/exhaustive-deps */

type TrainingPerGroup = {
    result: TrainingProgressPerGroupReport[];
    isPending: boolean;
    error: string | null;
};

type TrainingProgressOfDepartment = {
    result: TrainingProgressPerDepartmentReport[];
    isPending: boolean;
    error: string | null;
};

const Planning = (): JSX.Element => {
    const history = useHistory();
    /* @ts-ignore */
    const [courseId, setCourseId] = useState<number>(null);
    // Get course id from routes after url push in redux
    const params = useParams();
    //@ts-ignore
    const routeCourseId = parseInt(params.courseId);

    const aoContext = useOrganisationContext();

    const { courses } = aoContext;
    const { groups } = aoContext;

    const user = useCurrentUser();
    const api = createApi();

    /**
     * Training per group state
     */
    const [trainingPerGroup, setTrainingPerGroup] = useState<TrainingPerGroup>({
        /* @ts-ignore */
        result: null,
        isPending: false,
        error: null,
    });
    /**
     * Training per department state
     */
    const [trainingProgressOfDepartment, setTrainingProgressOfDepartment] = useState<TrainingProgressOfDepartment>({
        /* @ts-ignore */
        result: null,
        isPending: false,
        error: null,
    });

    const [noCourseMessge, setNoCourseMessage] = useState<boolean>(false);

    /**
     * Set the Ist courses as default and push the courseId in url params
     * If url courseId params already exist just set the courseId
     */
    useEffect(() => {
        if (courses && courses.length > 0) {
            if (routeCourseId) {
                setCourseId(routeCourseId);
            } else {
                /* @ts-ignore */
                setCourseId(courses[0].id);
                history.push(`/account-owner/planning/${courses[0].id}`);
            }
        }
        if (user && courses && courses.length === 0) {
            setNoCourseMessage(true);
            //dispatch( refreshUser( '/sysadmin' ) );
        }
    }, [courses]);
    /**
     * API call to get progress per group and progress by department
     */
    useEffect(() => {
        trainingProgressPerGroup();
        trainingProgressByDepartment();
    }, []);

    /**
     * Find Training progress per group
     */
    const trainingProgressPerGroup = () => {
        setTrainingPerGroup({ ...trainingPerGroup, isPending: true });
        api.trainingPerGroupReport.trainingProgressPerGroup().then(
            response => {
                setTrainingPerGroup({
                    ...trainingPerGroup,
                    isPending: false,
                    result: response,
                });
            },
            error => {
                setTrainingPerGroup({ ...trainingPerGroup, isPending: false });
            }
        );
    };
    /**
     * Find training progress by department
     */
    const trainingProgressByDepartment = () => {
        setTrainingProgressOfDepartment({ ...trainingProgressOfDepartment, isPending: true });
        api.trainingProgressPerDepartmentReport.trainingProgressPerDepartment().then(
            response => {
                setTrainingProgressOfDepartment({
                    ...trainingProgressOfDepartment,
                    result: response,
                    isPending: false,
                });
            },
            error => {
                setTrainingProgressOfDepartment({ ...trainingProgressOfDepartment, isPending: false });
            }
        );
    };

    const trainingResult = trainingPerGroup.result;
    const currentCourse = courses && courses.find(c => c.id === courseId);
    /**
     * Map training results progress with current course and get the output
     */
    /* @ts-ignore */
    const data = mapTrainingProgressWithCourse(currentCourse, groups, trainingResult);

    /**
     * Method used to set the current course and current group Id's in local storage
     * NOTE: Find the selected course from course list and groups from groups list
     * Push to home page to view the progress of users in detail
     * @param groupId
     * @param courseId
     */
    const showGroupAndCourseTraining = (groupId: number, courseId: number) => {
        /* @ts-ignore */
        const selectedCourse = courses.find(c => c.id === courseId);
        /* @ts-ignore */
        const selectedGroup = groups.find(g => g.id === groupId);
        // Set course and group in local storage.
        // Note: Set current course and current group this is needed in GL view
        /* @ts-ignore */
        setCourseGroupInLocalStorage(selectedCourse.id, selectedGroup.id);
        // Redirect to group
        /* @ts-ignore */
        history.push(`/groups/${selectedGroup.id}`);
    };

    /**
     * Method used to select the course from sidebar nav
     * @param courseId
     */
    const selectCourse = (courseId: number) => {
        setCourseId(courseId);
        history.push(`/account-owner/planning/${courseId}`);
    };

    return (
        <AccountOwnerLayout show={false} showMainNav>
            <Container>
                {noCourseMessge ? (
                    <NoCourseMessage />
                ) : (
                    <Flex gap="xl">
                        {/** Sidebar */}
                        <Flex h="100%">
                            <CardBlock>
                                <NavBar
                                    /* @ts-ignore */
                                    courses={courses}
                                    handleCourse={courseId => selectCourse(courseId)}
                                    selectedCourse={courseId}
                                />
                            </CardBlock>
                        </Flex>
                        {/** Content area */}
                        <Col md={8} className="content-area">
                            <CardBlock>
                                <TrainingInProgress
                                    courseId={courseId}
                                    isPending={trainingPerGroup?.isPending}
                                    groupWithProgress={data}
                                    /* @ts-ignore */
                                    course={currentCourse}
                                    handleTraining={(groupId, courseId) =>
                                        showGroupAndCourseTraining(groupId, courseId)
                                    }
                                />
                            </CardBlock>
                            {/*
                                 Hide while investigating server-side issue
                                 <ContentBlock>
                                 <CompletedTraining isPending={trainingProgressOfDepartment.isPending}completedTrainingInfo={trainingProgressOfDepartment.result} />
                                 </ContentBlock>
                                 */}
                        </Col>
                    </Flex>
                )}
            </Container>
        </AccountOwnerLayout>
    );
};
export default Planning;
