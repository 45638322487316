import { AnswerCountPerQuestion, UserModuleCompletionCount } from "@/api-client";
import { uniqueId } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import { useCurrentCourse, useCurrentGroup } from "../../common/courses/context";
import { useCurrentUser } from "../../common/user/context";
import { UserHasGroupLeaderRole } from "../../common/user/utils";
import ContentBlock from "../../components/ContentBlock";
import { ContentLabel } from "../../components/ContentLabel";
import { CategorisationOverviewData, CategorisationOverviewModel } from "./models/CategorisationOverview";
import "./styles.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

interface RowRendererProps {
    data: CategorisationOverviewData;
    depth: number;
}

const RowRenderer = ({ data, depth }: RowRendererProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(data.isExpanded);
    const id = uniqueId();
    const percentageCompleted =
        // @ts-ignore
        data.completedUsers > 0 && data.usersInCourse > 0 ? (data.completedUsers / data.usersInCourse) * 100 : 0;

    return (
        <>
            <tr id={id}>
                <td style={{ paddingLeft: `${depth + 1}rem` }}>
                    {data.link && (
                        <Link to={data.link} className="categorisation-link">
                            {data.title}
                        </Link>
                    )}
                    {!data.link && data.children && data.children.length > 0 && (
                        <button type="button" onClick={() => setIsExpanded(!isExpanded)} className="expand-link">
                            <i className={`fas fa-caret-${isExpanded ? "down" : "right"}`} aria-hidden="true" />{" "}
                            {data.title}
                        </button>
                    )}
                    {!data.link && (!data.children || data.children.length === 0) && (
                        <button type="button" className="expand-link disabled">
                            {" "}
                            {data.title}
                        </button>
                    )}
                </td>
                <td className="text-center whitespace-nowrap">
                    {data.completedUsers !== undefined && data.usersInCourse !== undefined && (
                        <>
                            {percentageCompleted.toFixed(0)}% ({data.completedUsers} / {data.usersInCourse})
                        </>
                    )}
                </td>
                <td className="text-center whitespace-nowrap">{data.answerCount && <>{data.answerCount}</>}</td>
                <td className="text-center whitespace-nowrap">
                    {data.uncategorisedAnswers !== undefined && data.uncategorisedAnswers > 0 && (
                        <span className="badge rounded-pill bg-danger">{data.uncategorisedAnswers}</span>
                    )}
                    {data.uncategorisedAnswers !== undefined && data.uncategorisedAnswers === 0 && (
                        <>{data.uncategorisedAnswers}</>
                    )}
                </td>
            </tr>
            {data.children && data.children.length > 0 && isExpanded && (
                <>
                    {data.children.map((item, ix) => (
                        <RowRenderer key={ix} data={item} depth={depth + 1} />
                    ))}
                </>
            )}
        </>
    );
};

export const CategorisationOverview = (): JSX.Element => {
    const api = createApi();
    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();
    const currentUser = useCurrentUser();
    const history = useHistory();
    const { t } = useTranslate();

    // Redirect to group home if group isn't allowed to access implementation/transformation
    useEffect(() => {
        if (!currentGroup) {
            return;
        }

        // @ts-ignore
        if (currentGroup.courseIds.length === 0) {
            console.info("No courses in group, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }

        if (!currentCourse || !currentUser) {
            return;
        }

        const isGroupLeader = UserHasGroupLeaderRole(currentUser, currentGroup);

        if (currentCourse.courseType !== "Training" || !isGroupLeader) {
            console.info("Course is not training or user not group leader, redirecting to start");
            history.push(`/groups/${currentGroup?.id}`);
        }
    }, [currentCourse, currentGroup, currentUser, history]);

    const [answerCountPerQuestion, setAnswerCountPerQuestion] = useState<AnswerCountPerQuestion[]>();
    const [userModuleCompletionCount, setUserModuleCompletionCount] = useState<UserModuleCompletionCount[]>();

    useEffect(() => {
        if (currentCourse && currentGroup) {
            api.reports
                .answerCountPerQuestion(currentCourse.id, currentGroup.id)
                .then(data => setAnswerCountPerQuestion(data));
            api.reports
                .userModuleCompletionCount(currentCourse.id, currentGroup.id)
                .then(data => setUserModuleCompletionCount(data));
        }
    }, [currentCourse, currentGroup]);

    // @ts-ignore
    const [data, setData] = useState<CategorisationOverviewModel>(undefined);

    useEffect(() => {
        if (currentCourse && answerCountPerQuestion && userModuleCompletionCount) {
            const getAnswerCountPerQuestion = (questionId: number) =>
                answerCountPerQuestion
                    .filter(x => x.questionId === questionId)
                    .reduce((prev, x) => x.answersCount + prev, 0);

            const getUncategorizedAnswerCountPerQuestion = (questionId: number) =>
                answerCountPerQuestion
                    .filter(x => x.questionId === questionId)
                    .reduce((prev, x) => x.answersCount - x.categorizedAnswersCount + prev, 0);

            const getAnswerCountPerModule = (moduleId: number) =>
                answerCountPerQuestion
                    .filter(x => x.moduleId === moduleId)
                    .reduce((prev, x) => x.answersCount + prev, 0);

            const getUncategorizedAnswerCountPerModule = (moduleId: number) =>
                answerCountPerQuestion
                    .filter(x => x.moduleId === moduleId)
                    .reduce((prev, x) => x.answersCount - x.categorizedAnswersCount + prev, 0);

            const getAnswerCountPerChapter = (chapterId: number) =>
                answerCountPerQuestion
                    .filter(x => x.chapterId === chapterId)
                    .reduce((prev, x) => x.answersCount + prev, 0);

            const getUncategorizedAnswerCountPerChapter = (chapterId: number) =>
                answerCountPerQuestion
                    .filter(x => x.chapterId === chapterId)
                    .reduce((prev, x) => x.answersCount - x.categorizedAnswersCount + prev, 0);

            const model: CategorisationOverviewModel = {
                // @ts-ignore

                children: currentCourse.chapters.map(chapter => ({
                    title: chapter.name,
                    // @ts-ignore
                    answerCount: getAnswerCountPerChapter(chapter.id),
                    // @ts-ignore
                    uncategorisedAnswers: getUncategorizedAnswerCountPerChapter(chapter.id),
                    usersInCourse: undefined,
                    completedUsers: undefined,
                    // @ts-ignore
                    isExpanded: getUncategorizedAnswerCountPerChapter(chapter.id) > 0,
                    // @ts-ignore
                    children: chapter.modules.map(module => {
                        const moduleCompletionRate = userModuleCompletionCount.find(x => x.moduleId === module.id);

                        // @ts-ignore
                        const childQuestions = module.blocks
                            .filter(b => b.questions)
                            .flatMap(b => b.questions)
                            .filter(x => x.enableCategorisation);

                        return {
                            title: module.name,
                            // @ts-ignore
                            answerCount: getAnswerCountPerModule(module.id),
                            // @ts-ignore
                            uncategorisedAnswers: getUncategorizedAnswerCountPerModule(module.id),
                            usersInCourse: moduleCompletionRate ? moduleCompletionRate.assignedUsers : 0,
                            completedUsers: moduleCompletionRate ? moduleCompletionRate.completedUsersCount : 0,
                            // @ts-ignore
                            isExpanded: getUncategorizedAnswerCountPerModule(module.id) > 0,
                            children: childQuestions.map(question => ({
                                title: question?.text,
                                // @ts-ignore
                                answerCount: getAnswerCountPerQuestion(question.id),
                                // @ts-ignore
                                uncategorisedAnswers: getUncategorizedAnswerCountPerQuestion(question.id),
                                usersInCourse: undefined, // same as module as questions are saved when finishing block
                                completedUsers: undefined, // same as module as questions are saved when finishing block
                                // @ts-ignore
                                link: `/groups/${currentGroup.id}/workshop/chapter/${chapter.id}/module/${module.id}/question/${question.id}`,
                                isExpanded: false,
                            })),
                        };
                    }),
                })),
            };

            setData(model);
        }
    }, [currentCourse, answerCountPerQuestion, userModuleCompletionCount]);

    return (
        <div className="categorization-overview">
            <ContentBlock>
                <ContentLabel text={t("WORKSHOP_INTRODUCTION_HEADER")} />
                <h1>{t("WORKSHOP_CATEGORIZATION_HEADER")}</h1>
                <Row>
                    <Col md={7}>
                        {" "}
                        <ReactMarkdown>{t("WORKSHOP_CATEGORIZATION_TEXT")}</ReactMarkdown>
                    </Col>
                </Row>
            </ContentBlock>
            <ContentBlock>
                <ContentLabel text={t("WORKSHOP_ANALYSIS_HEADER")} />
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>{t("WORKSHOP_SECTION_HEADER")}</th>
                            <th className="text-center whitespace-nowrap">{t("WORKSHOP_COMPLETION_RATE_HEADER")}</th>
                            <th className="text-center whitespace-nowrap">{t("WORKSHOP_ANSWERS_HEADER")}</th>
                            <th className="text-center whitespace-nowrap">{t("WORKSHOP_UNCATEGORIZED_HEADER")}</th>
                        </tr>
                    </thead>
                    {!data && (
                        <tbody>
                            <tr>
                                <td colSpan={4}>Loading...</td>
                            </tr>
                        </tbody>
                    )}
                    <tbody>
                        {data && data.children.map((item, ix) => <RowRenderer key={ix} data={item} depth={0} />)}
                    </tbody>
                    {data && (
                        <>
                            <tfoot>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th className="text-center">&nbsp;</th>
                                    <th className="text-center">
                                        {answerCountPerQuestion && (
                                            <>{answerCountPerQuestion.reduce((a, b) => a + b.answersCount, 0)}</>
                                        )}
                                    </th>
                                    <th className="text-center">
                                        {answerCountPerQuestion && (
                                            <>
                                                {answerCountPerQuestion.reduce((a, b) => a + b.answersCount, 0) -
                                                    answerCountPerQuestion.reduce(
                                                        (a, b) => a + b.categorizedAnswersCount,
                                                        0
                                                    )}
                                            </>
                                        )}
                                    </th>
                                </tr>
                            </tfoot>
                        </>
                    )}
                </table>
            </ContentBlock>
        </div>
    );
};
