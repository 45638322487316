import { useTranslate } from "@tolgee/react";
import { Badge, Box, Flex, Text } from "@mantine/core";
import { Icons } from "common/Icons";
import { RenderIf, SecondaryButton, useDeleteConfirmationModal, showToast } from "@/components";
import { UserStatus } from "@/api-client";
import { createApi } from "@/common";
import { UserStatusBadge } from "./UserStatusBadge";

const AcceptedBadge = (props: { hasAcceptedInvite: boolean }) => {
    const { t } = useTranslate();

    if (props.hasAcceptedInvite) {
        return (
            <Badge color="green" leftSection={<Icons.Check />}>
                {t("COMMON_YES")}
            </Badge>
        );
    }

    return (
        <Badge color="red" leftSection={<Icons.Cross />}>
            {t("COMMON_NO")}
        </Badge>
    );
};

type EditUserStatusProps = {
    user: { id: number; hasAcceptedInvite: boolean; status: UserStatus };
    onUserStatusChanged: () => void;
    inactivateUserHelperText: string;
};

export const EditUserStatus = (props: EditUserStatusProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const deleteUser = (userId: number) =>
        api.users
            .delete(userId)
            .then(result => {
                if (result.ok) {
                    props.onUserStatusChanged();
                } else {
                    showToast(t("ACCOUNTOWNER_USER_COULD_NOT_BE_DELETED", "User could not be deleted."), "error");
                }
            })
            .catchWithToast();

    const openDeleteConfirmationModal = () => {
        deleteConfirmationModal.open({
            onConfirm: () => deleteUser(props.user.id),
            title: "SYSADMIN_USER_DELETE_CONFIRM_HEADING",
        });
    };

    const userStatus = props.user.status;

    const getStatusAction = () => {
        if (userStatus === "Active") {
            return {
                buttonLabel: t("ACCOUNTOWNER_DEACTIVATE_USER"),
                command: () => {
                    api.users
                        .deactivate(props.user.id)
                        .then(async result => {
                            if (result.ok) {
                                props.onUserStatusChanged();
                            }
                        })
                        .catchWithToast();
                },
            };
        }
        if (userStatus === "Inactive") {
            return {
                buttonLabel: t("ACCOUNTOWNER_ACTIVATE_USER"),
                command: () => {
                    api.users
                        .activate(props.user.id)
                        .then(async result => {
                            if (result.ok) {
                                props.onUserStatusChanged();
                            }
                        })
                        .catchWithToast();
                },
            };
        }

        return {
            buttonLabel: "",
            command: () => {},
        };
    };

    const { buttonLabel, command } = getStatusAction();

    return (
        <>
            <Flex>
                <div style={{ width: "200px" }}>
                    <Text size="sm" fw="bold">
                        Status
                    </Text>
                </div>
                <UserStatusBadge status={userStatus} />
            </Flex>
            <Flex>
                <div style={{ width: "200px" }}>
                    <Text size="sm" fw="bold">
                        {t("EDITEMPLOYEE_HAS_ACCEPTED_INVITE")}
                    </Text>
                </div>
                <AcceptedBadge hasAcceptedInvite={props.user.hasAcceptedInvite} />
            </Flex>
            <Box mt="md">
                <RenderIf show={userStatus !== "Deleted"}>
                    <Text c="dimmed" size="sm">
                        {t(props.inactivateUserHelperText)}
                    </Text>

                    <Box mt="xs">
                        <SecondaryButton
                            size="sm"
                            mr="sm"
                            onClick={command}
                            eventName={{ object: "userstatus", action: "setstatus" }}
                        >
                            {buttonLabel}
                        </SecondaryButton>
                        <SecondaryButton
                            size="sm"
                            danger
                            onClick={openDeleteConfirmationModal}
                            eventName={{ object: "userstatus", action: "delete" }}
                        >
                            {t("EDITEMPLOYEE_DELETE_USER")}
                        </SecondaryButton>
                    </Box>
                </RenderIf>
            </Box>
        </>
    );
};
