import { useEffect, useState } from "react";
import { Space } from "@mantine/core";
import { useModal } from "@/components";
import { UserRoles, UserStatus } from "@/api-client";
import { createApi, UrlFactory, useHistory } from "@/common";
import { AddAdminModal } from "../../pages/SysAdmin/Admins/AddAdminModal";
import { UserList } from "./UserList";

export type UserRow = {
    id: number;
    email: string;
    status: UserStatus;
    hasAcceptedInvite: boolean;
    displayName: string | null;
    clientName?: string;
    roles: UserRoles[];
};

export const SysadminUsersListing = (): JSX.Element => {
    const addAdminModal = useModal(AddAdminModal);
    const history = useHistory();
    const [users, setUsers] = useState<UserRow[] | null>(null);

    useEffect(() => {
        const api = createApi();

        Promise.all([api.users.query(null, null, null, true), api.clients.query(true)])
            .then(([users, clients]) => {
                setUsers(
                    users.map(x => ({
                        displayName: x.fullName ?? null,
                        email: x.email!,
                        hasAcceptedInvite: x.hasAcceptedInvite,
                        status: x.status,
                        clientName: clients.find(c => c.id === x.clientId)?.name,
                        roles: x.roles,
                        id: x.id!,
                    }))
                );
            })
            .catchWithToast();
    }, []);

    return (
        <>
            <UserList
                users={users}
                onAddAdminClicked={() => {
                    addAdminModal.open({
                        onSaved: userId => {
                            history.push(UrlFactory.sysadmin.admin.create({ userId }));
                        },
                    });
                }}
            />
            <Space h="lg" />
        </>
    );
};
export default SysadminUsersListing;
