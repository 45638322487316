import type { Identifier, XYCoord } from "dnd-core";
import React, { ReactNode, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DragItem } from "./common";

export const ItemTypes = {
    ITEM: "item",
};

interface SortableTableRowProps {
    dragItem: DragItem;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    children?: ReactNode;
}

export const SortableTableRow = ({ dragItem, moveItem, children }: SortableTableRowProps) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.sortIndex;
            const hoverIndex = dragItem.sortIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.sortIndex = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: () => {
            return { id: dragItem.sortId, sortIndex: dragItem.sortIndex };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    //const opacity = isDragging ? 0 : 1
    drag(drop(ref));

    const containerClass = isDragging ? "dragging-container" : "listing-container";

    return (
        <tr ref={ref} className={containerClass}>
            {children}
        </tr>
    );
};
