import { Course, GroupCourse, ParticipantProgress, Round } from "@/api-client";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { createApi } from "../../../common/api";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import StyledRadioButton from "../../../components/StyledRadioButton";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

type AssignmentModalPros = {
    show: boolean;
    groupId: number;
    rounds: Round[];
    courses: Course[];
    hide: () => void;
    editAssignment: GroupCourse;
    handleSubmit: (values: GroupCourse) => void;
};

export const AssignmentModal = (props: AssignmentModalPros) => {
    const { show, groupId, rounds, courses, handleSubmit, editAssignment } = props;

    /* @ts-ignore */
    const [participantsProgress, setParticipantsProgress] = useState<ParticipantProgress[]>(null);

    const api = createApi();

    useEffect(() => {
        if (editAssignment && editAssignment.id && groupId) {
            api.participantProgress.query(null, groupId, null, null, editAssignment.roundId).then(
                result => {
                    setParticipantsProgress(result);
                },
                error => {
                    console.log(error, "Error in getting participants progress.");
                }
            );
        }
    }, [editAssignment, groupId]);

    const initialValues = {
        id: editAssignment ? editAssignment.id : undefined,
        courseId: editAssignment ? editAssignment.courseId : null,
        roundId: editAssignment ? editAssignment.roundId : null,
        groupId,
        hideUserInfoOnAnswers: editAssignment ? editAssignment.hideUserInfoOnAnswers : false,
        isCompleted: editAssignment ? editAssignment.isCompleted : false,
    };

    const formikValues = useMemo(
        () => ({
            initialValues,
            validationSchema: yup.object({
                courseId: yup.string().required("Please select course.").nullable(),
                //roundId: yup.string().required("Please select round.").nullable()
            }),
            /* @ts-ignore */
            onSubmit: values => {
                handleSubmit(values);
                /* @ts-ignore */
                formik.resetForm({ values: initialValues });
            },
            enableReinitialize: true,
        }),
        [initialValues]
    );

    /* @ts-ignore */
    const formik = useFormik<GroupCourse>(formikValues);

    const radioButtons = [
        { label: "Yes", value: true, index: 1 },
        { label: "No", value: false, index: 0 },
    ];

    return (
        <Modal
            show={show}
            onHide={() => {
                props.hide();
            }}
            size={"lg"}
            animation={false}
        >
            <Modal.Header closeButton aria-label="Hide">
                <Modal.Title>{editAssignment ? "Edit" : "Add"} Training Assignment</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Courses</Form.Label>
                        <Form.Select
                            disabled={participantsProgress && participantsProgress.length > 0}
                            name="courseId"
                            value={formik.values.courseId}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                e.persist();
                                formik.handleChange(e);
                            }}
                        >
                            <option value="">Please Select Course</option>
                            {courses &&
                                courses.map(c => (
                                    <option key={c.id} value={c.id}>
                                        {c.name}
                                    </option>
                                ))}
                        </Form.Select>
                        {/* @ts-ignore */}
                        <DisplayInlineError error={formik.errors.courseId && formik.errors.courseId} />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Round</Form.Label>
                        <Form.Select
                            value={formik.values.roundId}
                            name="roundId"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                e.persist();
                                formik.handleChange(e);
                            }}
                        >
                            <option value="">No Round</option>
                            {rounds &&
                                rounds.map(r => (
                                    <option key={r.id} value={r.id}>
                                        {r.name}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Hide User Answers {formik.values.hideUserInfoOnAnswers}</Form.Label>
                        <Form.Group>
                            {radioButtons &&
                                radioButtons.map((m, i) => (
                                    <StyledRadioButton
                                        label={m.label}
                                        checked={m.value === formik.values.hideUserInfoOnAnswers}
                                        className={i > 0 ? "ms-5" : "fine"}
                                        key={i}
                                        name="hideUserInfoOnAnswers"
                                        value={m.value ? 1 : 0}
                                        id={`hideUserInfoOnAnswers-${m.index}`}
                                        onChange={(value: number | string) => {
                                            formik.setFieldValue("hideUserInfoOnAnswers", +value === 1);
                                        }}
                                    />
                                ))}
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Is Completed</Form.Label>
                        <Form.Group>
                            {radioButtons &&
                                radioButtons.map((m, i) => (
                                    <StyledRadioButton
                                        label={m.label}
                                        checked={m.value === formik.values.isCompleted}
                                        className={i > 0 ? "ms-5" : "fine"}
                                        key={i}
                                        name="isCompleted"
                                        value={m.value ? 1 : 0}
                                        id={`isCompleted-${m.index}`}
                                        onChange={value => formik.setFieldValue("isCompleted", value === 1)}
                                    />
                                ))}
                        </Form.Group>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton disabled={participantsProgress && participantsProgress.length > 0} type="submit">
                        {editAssignment ? "Update Assignment" : "Add Assignment"}
                    </PrimaryButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
