import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { Input } from "@mantine/core";
import PhoneInput from "react-phone-number-input";
import React from "react";

type PhoneNumberInputProps = {
    fieldName: string;
    label: string;
};

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    const [field, meta, helpers] = useField(props.fieldName);
    const { t } = useTranslate();

    return (
        <Input.Wrapper
            id={field.name}
            label={t(props.label)}
            error={meta.error != null && meta.touched ? t(meta.error) : undefined}
        >
            <PhoneInput
                placeholder={props.label}
                autoComplete="off"
                defaultCountry="SE"
                country="SE"
                international
                smartCaret
                value={field.value as string}
                name={field.name}
                readOnly={false}
                rules={{ required: false, isPossiblePhoneNumber: true }}
                onChange={val => {
                    helpers.setValue(val);
                    helpers.setError("");
                }}
                onBlur={e => field.onBlur(e)}
                countryCallingCodeEditable={false}
            />
        </Input.Wrapper>
    );
};
