import { FunctionComponent } from "react";
import "./policy.scss";

export const PoliciesPage: FunctionComponent = (): JSX.Element => {
    return (
        <div className="policy-container col-sm-12 offset-md-1 col-md-10 offset-md-1">
            <div className="welcome-block staticPage">
                <h1>Privacy Policy</h1>
                <p>Last updated: 2 November 2022</p>
                <p>Data controller: HUPS AB</p>
                <p>Registration number: 556936-2717</p>
                <p>Address: PO BOX 19, 761 22 Norrtälje, Sweden</p>
                <p>
                    Contact email: <a href="mailto:info@hups.com">info@hups.com</a>
                </p>
                <p>
                    <strong>How we respect your privacy</strong>
                </p>
                <p>
                    When you use our website and services, and access, subscribe to and attend our courses, (jointly
                    referred to as our &quot; <strong>Sites</strong>&quot;) we work hard to protect your information.
                    This Privacy Policy is meant to help you understand what information we collect, why we collect it,
                    and how you can update, manage, export, and delete your information.
                </p>
                <p>
                    To make our website work properly, we sometimes place small data files called cookies on your
                    device. To find out more, please read our Cookie Policy.
                </p>
                <p>
                    This website and our services are not directed toward children (as defined by local law) nor do we
                    knowingly collect information from children (as defined by local law) without parental consent
                    except where in compliance with applicable laws.
                </p>
                <p>
                    <strong>What personal data we collect and why</strong>
                </p>
                <p>
                    <strong>Types of personal data</strong>
                </p>
                <ul>
                    <li>Name</li>
                    <li>Gender</li>
                    <li>Age</li>
                    <li>Email address</li>
                    <li>City, Country</li>
                    <li>Name of Employer, Company</li>
                    <li>
                        Work title and position When you interact with us, we may collect the following data depending
                        on the situation and your query
                    </li>
                    <li>Forms filled out on the Sites</li>
                    <li>Correspondence with you by phone or email</li>
                    <li>Orders for courses and other products placed</li>
                    <li>
                        Your interaction on the website, such as filling out forms, sending and receiving messages with
                        others.
                    </li>
                    <li>Reporting a problem</li>
                    <li>Requesting support</li>
                    <li>
                        Technical data, such as, IP address, login information, browser type and version, time zone
                        setting, browser plug-in types and versions, operating system and platform
                    </li>
                    <li>Data about your visits on our website (clickstream, date, time etc)</li>
                    <li>
                        Data about page response times, download errors, length of visits to certain pages, page
                        interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse
                        away from the page
                    </li>
                    <li>
                        Information about your devices, device identifiers, and from which devices you visit our website
                        and make use of the service, which may include mobile network information (including phone
                        number), IP address, language and time zone, operating system, hardware version, device
                        locations (including country specific geographic locations)
                    </li>
                    <li>
                        Information that is the result of a combination of the data you provide us and the personal data
                        we automatically obtain about you from your use and interactions with our Site.
                    </li>
                </ul>
                <p>
                    <strong>Source, purpose and legal basis</strong>
                    We collect information in different ways. Some information is collected directly from you, such as
                    your name and contact information. Other types of information are collected from you passively, via
                    tracking tools such as cookies and web beacons (please read our Cookie Policy below). We also
                    collect information about you from third parties, such as our Sales Agents, our partners for
                    providing our products and services to you and our customers.We use your personal data for the
                    following purposes:
                </p>
                <ol>
                    <li>
                        <strong>Providing our services</strong>. We may use some or all of your personal data for the
                        purpose of administering and performing the agreement with you, for example ordering access to
                        courses and giving you access to attend courses;
                    </li>
                    <li>
                        <strong>Promoting our services</strong>. We may use your personal data, such as your name, email
                        address and country, for marketing purposes on the legal basis of our legitimate interest to
                        promote our services and new courses that we deem to be similar to the courses you have attended
                        or shown interest in and that we reasonably believe may be of interest to you;
                    </li>
                    <li>
                        <strong>Developing our services</strong>. We may use your personal data for internal research
                        and service development purposes as well as for historical reference on the legal basis of our
                        legitimate interest to develop our services as well as to keep historical references to see
                        trends over time;
                    </li>
                    <li>
                        <strong>Direct marketing by us</strong>. We may use your personal data, such as your name and
                        email address, for direct marketing purposes in relation to courses you are attending as well as
                        for the purpose of providing you with information about upcoming courses and new products, on
                        the legal basis of your consent.
                    </li>
                </ol>
                <p>
                    <strong>About</strong> <strong>our balance of interest assessment</strong> When we state above that
                    we rely on our legitimate interests as a legal basis for our processing of personal data, we have
                    made a so-called legitimate interest assessment. That is a balance of interest assessment through
                    which we have determined that our legitimate interests for the processing outweighs your interests
                    and rights not to have your personal data processed. Please contact us if you want more information
                    on how we have made this assessment.
                </p>
                <p>
                    <strong>Transfers of your personal data outside the European Economic Area</strong> Our sharing of
                    your personal data in accordance with this Privacy Policy may involve transferring your data outside
                    the European Economic Area (EEA). Whenever we transfer your personal data out of the EEA we ensure a
                    similar degree of protection is afforded to it. As a safeguard, we use specific contracts approved
                    by the European Commission which give personal data the same protection it has in Europe. In
                    addition to this, we will implement requirements and safeguards that may be required by law.You may
                    be entitled, in accordance with applicable law, to request a copy of relevant safeguards
                    implemented. Please contact us at{" "}
                    <a href="mailto:info@operationalexcellence.com">info@operationalexcellence.com</a>for any questions
                    in this regard.
                </p>
                <p>
                    <strong>Who we share your personal data with</strong>
                </p>
                <p>We may share your personal data with our affiliates and with selected third parties:</p>
                <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <td className="col-sm-3">
                                <strong>Affiliates:</strong>
                            </td>
                            <td>Our affiliates and group companies, namely Nimocon AB.</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Service providers:</strong>
                            </td>
                            <td>
                                Specifically approved sub-processors. <br />
                                <br />
                                We commit to entering into data processing agreements with each and every service
                                provider that, when relevant, processes personal data on our behalf.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Business partners:</strong>
                            </td>
                            <td>
                                Our sales agents and co-operation partners with whom we enter into partnership with.We
                                commit to entering into data processing agreements with each and every business partner
                                that, when relevant, processes personal data on our behalf.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Third parties:</strong>
                            </td>
                            <td>
                                Any tentative third-party buyer of our business or assets, in which case we may disclose
                                your personal data to the prospective seller or buyer of such business or assets. <br />
                                <br />
                                All or substantially all of our assets are acquired by a third party, in which case
                                personal data held by us about our customers will be one of the transferred assets.{" "}
                                <br />
                                <br />
                                If we are under a duty to disclose or share your personal data in order to comply with
                                any legal obligation, or in order to enforce or apply our terms of use and other
                                agreements; or to protect the rights, property, or safety of us, our customers, or
                                others. This includes exchanging information with other companies and organisations for
                                the purposes of fraud protection and credit risk reduction. <br />
                                <br />
                                To any other third party where lawful.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    <strong>How we keep your personal data safe</strong>
                </p>
                <p>
                    We protect your personal data from all kinds of threats: loss, alteration, unauthorized disclosure
                    or access. We do this in a variety of ways depending on where we store and use it.
                </p>
                <p>
                    Occasionally, we might have to transfer your personal data to service providers and business
                    partners located outside the European Economic Area. We do our best to keep your personal data safe
                    in these cases. Please read section 2 in this Privacy Policy on how we may transfer data outside of
                    the European Economic Area and the safeguards we apply.
                </p>
                <p>
                    <strong>How long we keep your personal data</strong>
                </p>
                <p>
                    We keep your personal data for as long as the personal data is relevant for use by us for the
                    purposes as indicated in this Privacy Policy.
                </p>
                <p>
                    Please note that personal data that has been published online might be downloaded and shared by
                    individuals. In such cases we do not control the source and will be unable to delete the personal
                    data. We might also store your personal data longer than stated above if it is necessary because of
                    legal requirements that we are subject to.
                </p>
                <p>
                    <strong>Your privacy rights</strong>
                </p>
                <p>
                    You have many rights regarding your personal data. Read more about them below. If you would like to
                    exercise them or learn more about them, feel free to contact us. You can find contact details at the
                    top and end of this Privacy Policy. Please note that some of the rights may not be applicable to
                    you.
                </p>
                <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <td className="col-sm-3">
                                <strong>Right of access</strong>
                            </td>
                            <td>
                                You have the right to obtain confirmation as to whether or not we process your personal
                                data, and, if we do, request access to your personal data as well as information in
                                relation to our processing. If you have any questions or would like to learn more about
                                what personal data we process, you are always welcome to contact us and we will provide
                                you with further information
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to rectification</strong>
                            </td>
                            <td>
                                If you believe we store incorrect personal data, such as misspelt or previous name or
                                address, you can ask us to correct this
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to erasure / right to be forgotten</strong>
                            </td>
                            <td>
                                You have the right to request that we permanently erase your personal data from our
                                records.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to restrict the processing activities</strong>
                            </td>
                            <td>
                                You have the right to restrict our processing activities in certain situations. This
                                means we will continue to store your personal data, but we will temporarily stop any
                                other processing. Why would you want to do this? For example, if you have asked us to
                                fix incorrect personal data. In this situation you may want us to stop processing until
                                the personal data is correct.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to data portability</strong>
                            </td>
                            <td>
                                In certain situations, you have the right to ask us to send your personal data in
                                digital form to you or directly to another controller.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to object</strong>
                            </td>
                            <td>
                                You have the right to object to the processing of your personal data, even when we have
                                a legitimate legal reason to process it. You can do this when we process your personal
                                data on the basis of our legitimate interest, and you believe your personal interest
                                outweighs ours. If you do not want us using your personal data for direct marketing
                                purposes, including profiling, we will comply in any case.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to withdraw consent</strong>
                            </td>
                            <td>
                                In case the processing of your personal data is based on your consent, you have the
                                right to withdraw your consent at any time, without affecting the lawfulness of
                                processing based on consent before this withdrawal. Please note that all aspects of an
                                initiated marketing campaign cannot be stopped once initiated upon a withdrawal of
                                consent.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Right to lodge a complaint with a supervisory authority</strong>
                            </td>
                            <td>
                                If you are not satisfied with the way we treat your personal data, you have the right to
                                lodge a complaint against us to the relevant supervisory authority.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    <strong>Links to third-party websites and social media services</strong>
                </p>
                <p>
                    Our communication may, from time to time, contain links to and from the websites of our partner
                    networks, advertisers and affiliates. We do not accept responsibility or liability for these
                    third-party sites.
                </p>
                <p>
                    Some of the content and functionality on our website may be provided by social media services that
                    are not affiliated with us. These services will authenticate your identity and provide you the
                    option to share certain personal data with us and, depending on the service, you may be given the
                    option to post to your social media profile page about your activities on our website to share with
                    others within your social media presence.
                </p>
                <p>
                    <strong>Changes to this Privacy Policy</strong>
                </p>
                <p>
                    Any material changes that we make to this Privacy Policy will be posted here prior to the changes
                    becoming effective.
                </p>
                <p>
                    <strong>Who to contact</strong>
                </p>
                <p>
                    If you have any questions about this Privacy Policy or our processing of personal data, feel free to
                    contact us at <a href="mailto:info@hups.com">info@hups.com</a>.
                </p>
                <p>
                    <strong>COOKIE POLICY</strong>
                </p>
                <p>Last updated: 2 November 2022</p>
                <p>Data controller: HUPS AB</p>
                <p>Registration number: 556936-2717</p>
                <p>Address: PO BOX 19, 761 22 Norrtälje, Sweden</p>
                <p>
                    Contact email: <a href="mailto:info@hups.com">info@hups.com</a>
                </p>
                <p>
                    <strong>Our use of cookies and tracking technologies</strong>
                </p>
                <p>
                    This website uses cookies and other similar technologies (as described below) when you browse or use
                    our services.
                </p>
                <p>
                    A cookie is a small file of letters and numbers that we store on your browser or on your device if
                    you agree. Cookies contain information that is transferred to your device.
                </p>
                <p>
                    We use these technologies for the purpose of distinguishing you from other users of our website and
                    services. This helps us provide you with a good experience when you visit our website. We also use
                    these technologies to improve our website, identify you and protect your safety.
                </p>
                <p>
                    <strong>Types of cookies that we use</strong>
                </p>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>
                                <strong>Type of Cookie</strong>
                            </th>
                            <th>
                                <strong>Purpose of Cookie</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-sm-3">
                                <strong>Strictly Necessary</strong>
                            </td>
                            <td>
                                These cookies are essential in order to enable you to browse our website and use its
                                features. The information collected by these cookies relate to the operation of our
                                website, for example website scripting language and security tokens to maintain secure
                                areas of our website.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Performance / Analytics</strong>
                            </td>
                            <td>
                                These cookies collect anonymous information about how you use our website, for example
                                which pages you visit most often, whether you receive any error messages, and how you
                                arrived at our website. Information collected by these cookies is used only to improve
                                your use of our website and never to identify you. These cookies are sometimes placed by
                                third-party providers of web traffic analysis services, such as Google Analytics.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Functionality</strong>
                            </td>
                            <td>
                                These cookies assist us with ensuring that you receive assistance with any support
                                requests that you may have during you user journey on our website. These cookies
                                remember choices you make, for example the country you visit our website from, your
                                language and any changes you have made to text size or other parts of web pages that you
                                can customise, in order to improve your experience of our website and to make your
                                visits more enjoyable. The information these cookies collect may be anonymised and
                                cannot be used to track your browsing activity on other websites.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    Please note that third parties (including, for example, external services like web traffic analysis
                    services) may also use cookies, over which we have no control. These cookies are likely to be
                    analytical/performance cookies.
                </p>
                <p>
                    We may use cookies to collect anonymised data on our visitors&#39; behaviour on the website. We may
                    capture what website a visitor to our website has come from and what website they go to afterwards.
                    We may also capture demographic data (e.g. the visitor&#39;s location) and we may use that data to
                    analyse visitor behaviour across websites.
                </p>
                <p>
                    Almost all internet browsers are automatically set up to accept cookies. If you want to refuse or
                    delete any cookies (or similar technologies), please refer to the help and support area on your
                    internet browser for instructions on how to block or delete cookies. Limiting, blocking or deleting
                    cookies may impact your user experience of our website, including access to certain personalised
                    features.
                </p>
                <p>
                    <strong>Web beacons</strong>
                </p>
                <p>
                    We, or our partners, may use a software technology called web beacons (also known as pixels) to help
                    us understand what content and material is interesting and effective. Web beacons are similar to
                    cookies and are used to let us know when content is viewed. Web beacons are embedded on web pages,
                    and e-mail. We, or our third-party partners, may tie the information gathered by web beacons to the
                    other information we collect about you.
                </p>
            </div>
        </div>
    );
};
