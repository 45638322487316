import { Course, Group } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../common/api";
import { useOrganisationContextDispatch } from "../../common/OrganisationContext";
import StyledCheckbox from "../StyledCheckbox";
import { PrimaryButton } from "../Button/PrimaryButton";

interface GroupCourseSettingsProps {
    show: boolean;
    handleClose: () => void;
    group: Group;
    course: Course;
}

const GroupCourseSettings = ({ show, handleClose, group, course }: GroupCourseSettingsProps): JSX.Element => {
    const { t } = useTranslate();

    const [hideUserInfo, setHideUserInfo] = useState<boolean>(false);

    const currentGroupCourseDispatch = useOrganisationContextDispatch();

    const api = createApi();

    useEffect(() => {
        if (group && course) {
            setHideUserInfo(
                // @ts-ignore
                group?.courseAssignments.find(c => c.courseId === course?.id && c.groupId === group.id)
                    ?.hideUserInfoOnAnswers
            );
        }
    }, [group, course]);

    const handleSave = (): void => {
        const groupCourseId = group?.courseAssignments.find(
            c => c.courseId === course?.id && c.groupId === group.id
        )?.id;
        const data = [
            {
                path: "/hideUserInfoOnAnswers",
                op: "replace",
                value: hideUserInfo,
            },
        ];

        api.groupCourses
            .patch(
                // @ts-ignore
                groupCourseId,
                data
            )
            .then(response => {
                currentGroupCourseDispatch(s => ({
                    ...s,
                    // @ts-ignore
                    groups: s.groups.map(g => {
                        if (g.id === group.id) {
                            g.courseAssignments = [
                                ...g.courseAssignments.map(c => {
                                    if (c.courseId === course.id && c.groupId === group.id) {
                                        return response;
                                    } else {
                                        return c;
                                    }
                                }),
                            ];
                        }
                        return g;
                    }),
                }));
                setHideUserInfo(response.hideUserInfoOnAnswers);
                handleClose();
            });
    };

    return (
        <Modal show={show} onHide={handleClose} size={"lg"} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("HOME_GROUP_SETTINGS")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <StyledCheckbox
                            checked={hideUserInfo}
                            onChange={checked => setHideUserInfo(checked)}
                            label={t("HOME_HIDE_USER_INFO")}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton onClick={() => handleSave()} eventName={{ object: "teamsettings", action: "update" }}>
                    {t("COMMON_SAVE")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};

export default GroupCourseSettings;
