import React from "react";

export const QuestionTitle = (props: { moduleName: string; text?: string }): JSX.Element => {
    return (
        <div className="p-4 mb-3">
            <p className="content-label content-label-module">{props.moduleName}</p>
            {props.text && <h1>{props.text}</h1>}
        </div>
    );
};
