import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { TolgeeProvider } from "@tolgee/react";
import { ReactNode } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import {
    AppInsightsContextProvider,
    onAuthenticatedUserChanged as onAuthenticatedUserChangedAI,
} from "./analytics/AppInsights";
import { AuthContextProvider, AuthenticatedUser } from "./auth/AuthContextProvider";
import { theme } from "./common/theme";
import { modalProps } from "./CommonModalProps";
import { SignalRProvider } from "./common/signalr";
import { tolgee } from "./translation";

posthog.init("phc_hAjTTYogVJZTcOhuj1TL4aySq3MOFlze6ZxGqCUGf9P", {
    api_host: "https://eu.posthog.com",
});

export const onAuthenticatedUserChanged = (authenticatedUser: AuthenticatedUser | null) => {
    onAuthenticatedUserChangedAI(authenticatedUser);
    if (authenticatedUser != null) {
        const { user } = authenticatedUser;

        const userId = user.id.toString();

        posthog.identify(userId, {
            user_id: userId,
        });

        if (user.clientId != null) {
            posthog.group("organisation", user.clientId.toString(), {
                name: authenticatedUser.client?.name,
            });
        } else {
            posthog.resetGroups();
        }

        posthog.register_for_session({
            is_impersonated: authenticatedUser.isImpersonated,
        });
    } else {
        posthog.reset();
        posthog.unregister_for_session("is_impersonated");
    }
};

export const App = (props: { children: ReactNode }) => {
    return (
        <TolgeeProvider tolgee={tolgee}>
            <PostHogProvider client={posthog}>
                <AppInsightsContextProvider>
                    <AuthContextProvider onUserChanged={onAuthenticatedUserChanged}>
                        <MantineProvider
                            theme={{
                                colors: { darkSmoke: theme.colors.darkSmoke },
                                colorScheme: "light",
                                primaryColor: "darkSmoke",
                            }}
                        >
                            <Notifications position="top-center" zIndex={9999} />
                            <ModalsProvider modalProps={modalProps}>
                                <SignalRProvider>{props.children}</SignalRProvider>
                            </ModalsProvider>
                        </MantineProvider>
                    </AuthContextProvider>
                </AppInsightsContextProvider>
            </PostHogProvider>
        </TolgeeProvider>
    );
};
