import { ReactNode, useState } from "react";
import { useBoardContext } from "../../BoardContext";
import { CardContainer } from "../CardContainer/CardContainer";
import { TaskStack } from "../TaskStack";
import { TasksByStatus } from "../../types";
import "./styles.scss";
import { ImprovementBoardTask, TaskPriority } from "@/api-client";

interface BoardLaneProps {
    children: ReactNode;
    tasks: TasksByStatus;
    defaultNewTask: ImprovementBoardTask;
    swimLane?:
        | ImprovementBoardTask["assignedToUserId"]
        | ImprovementBoardTask["taskCategoryId"]
        | TaskPriority
        | "null";
}

export const BoardLane = (props: BoardLaneProps) => {
    const boardContext = useBoardContext();
    const [expandArchived, setExpandArchived] = useState(false);

    const categories = boardContext.state.categoryFilters.items.map(f => f.item);

    return (
        <>
            <div className="board-lane board-lane-grouping-title grid-col-2 text-center p-1">
                <div className="wrapper vertical-center">
                    <div className="content  p-3">{props.children}</div>
                </div>
            </div>

            {!expandArchived && (
                <>
                    <div className="board-lane-todo grid-col-3 p-1">
                        <CardContainer
                            droppableId={{ column: "Todo", swimLane: props.swimLane }}
                            tasks={props.tasks.Todo}
                            categories={categories}
                            defaultNewTask={{ ...props.defaultNewTask, status: "Todo" }}
                        />
                    </div>
                    <div className="board-lane-in-progress grid-col-4 p-1">
                        <CardContainer
                            droppableId={{ column: "Active", swimLane: props.swimLane }}
                            tasks={props.tasks.Active}
                            categories={categories}
                            defaultNewTask={{ ...props.defaultNewTask, status: "Active" }}
                        />
                    </div>
                    <div className="board-lane-done grid-col-5 p-1">
                        <CardContainer
                            droppableId={{ column: "Completed", swimLane: props.swimLane }}
                            tasks={props.tasks.Completed}
                            categories={categories}
                            defaultNewTask={{ ...props.defaultNewTask, status: "Completed" }}
                        />
                    </div>
                </>
            )}

            <div className={`column-total grid-col-6 p-1 ${expandArchived ? "expand" : ""}`}>
                <div className={`hide-expand-container ${expandArchived ? "expanded" : "not-expanded"}`}>
                    {expandArchived && (
                        <>
                            <CardContainer
                                maxHeight={250}
                                droppableId={{ column: "Archived", swimLane: props.swimLane }}
                                tasks={props.tasks.Archived}
                                categories={categories}
                                defaultNewTask={{ ...props.defaultNewTask, status: "Archived" }}
                            />
                        </>
                    )}

                    <button className="btn btn-toggle-archived" onClick={() => setExpandArchived(x => !x)}>
                        {expandArchived ? (
                            <i className="fas fa-angle-double-right"></i>
                        ) : (
                            <i className="fas fa-angle-double-left"></i>
                        )}
                    </button>

                    {!expandArchived && (
                        <>
                            <div className="archive-container">
                                <TaskStack
                                    droppableId={{ column: "Archived", swimLane: props.swimLane }}
                                    tasks={props.tasks.Archived}
                                    onClick={() => setExpandArchived(true)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
