import React from "react";
import { ListGroup } from "react-bootstrap";
import { Question } from "@/api-client";

type RatingQuestionProps = {
    question: Question;
    placeholder: string;
};

export const RatingQuestions = React.memo(function FreeTextAnswers(props: RatingQuestionProps) {
    const { question, placeholder } = props;
    //@ts-ignore
    const maxValue = question.maxAnswer + 1;
    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        {question && (
                            <React.Fragment>
                                <p>{question.text}</p>
                                <p>{question.description}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 text-secondary">
                    <div className="block-content-option ratingData w-100 align-items-center">
                        {question && (
                            <div className="block-content-option">
                                <ListGroup
                                    id={`question-${question.questionTypeId}-questions`}
                                    className="w-100"
                                    horizontal
                                >
                                    {
                                        //@ts-ignore
                                        [...Array(maxValue - question.minAnswer).keys()].map((q, index) => {
                                            return (
                                                <ListGroup.Item
                                                    className="border-0 flex-grow-1  w-100 p-3 text-center"
                                                    key={`question_${index}-${props.question.questionTypeId}`}
                                                >
                                                    <div className="rating-value">
                                                        <input
                                                            type="radio"
                                                            id={`questions.question-${index}-${props.question.questionTypeId}-${index}`}
                                                            name={`questions.question-${index}-${props.question.questionTypeId}.text`}
                                                            disabled={true}
                                                        />
                                                        <label
                                                            htmlFor={`questions.question-${index}-${question.questionTypeId}.value`}
                                                        >
                                                            {
                                                                //@ts-ignore
                                                                question.minAnswer + index
                                                            }
                                                        </label>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })
                                    }
                                </ListGroup>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
