import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import SlideshowBlock from "../../SlideshowBlock";
import { AnswerCategory } from "@/api-client";
import AltBlocks from "../../../../components/AltBlocks";
import { usePresentationContext } from "../../PresentationContext";
import { createApi } from "../../../../common/api";
import { useOrganisationContext } from "../../../../common/OrganisationContext";
import { AppLoader } from "../../../../components/Spinner";
import {
    groupAnswersByCategory,
    groupAnswersByDemographicData,
    groupAnswersByGroup,
    groupAnswersByUser,
} from "../../data/aggregation";
import { AnswerGroup } from "../../data/AnswerGroup";
import { QuestionTitle } from "../../QuestionTitle";
import { StickyQuestionTitle } from "../../StickyQuestionTitle";
import { AnswersLayout } from "./AnswersLayout";
import CategoryGraph from "./CategoryGraph";
import "./TextQuestionSlide.scss";
import RenderIf from "../../../../components/render-if/render-if";
import { Group } from "@/api-client";

/*  eslint-disable react-hooks/exhaustive-deps */

const TextQuestionSlide = () => {
    const { question, module } = usePresentationContext();

    if (!question) {
        return <AppLoader loading={true} />;
    }

    return (
        <>
            <StickyQuestionTitle
                // @ts-ignore
                text={question.text}
                // @ts-ignore
                moduleName={module?.name}
            />
            {question && question.enableCategorisation ? (
                <TextQuestionCategoriesSlide />
            ) : (
                <TextQuestionWithoutCategoriesSlide />
            )}
        </>
    );
};

const TextQuestionWithoutCategoriesSlide = () => {
    const { t } = useTranslate();

    const { module, question, answers, isLoading } = usePresentationContext();

    const showResults = !isLoading && answers && answers.length > 0;
    const showNoResults = !isLoading && answers && answers.length === 0;

    return (
        <AltBlocks>
            <SlideshowBlock elementId={`graph`} className="alt-blocks-first">
                {question && question.text && (
                    <QuestionTitle
                        text={question.text}
                        // @ts-ignore

                        moduleName={module?.name}
                    />
                )}

                {showNoResults && !isLoading && (
                    <div className="alert alert-info">{t("WORKSHOP_PRESENTATION_NOANSWER")}</div>
                )}

                {showResults && <AnswersLayout answers={answers} />}
            </SlideshowBlock>
        </AltBlocks>
    );
};

const GroupLabel = (props: { dataGroup: AnswerGroup; groups: Group[] }) => {
    const group = props.groups?.find(x => x.id === props.dataGroup.answers[0].groupId);
    const color = group?.color;
    return (
        <div className={"d-flex align-items-center group-label"}>
            <div className="rectangle mx-1" style={{ backgroundColor: color }} />
            <h4 className="text mx-1">{group?.name}</h4>
        </div>
    );
};

const TextQuestionCategoriesSlide = () => {
    const api = createApi();
    const { t } = useTranslate();

    const { module, question, answers, isLoading, filters } = usePresentationContext();
    const { users, demographicQuestions, demographicData, groups } = useOrganisationContext();

    const [categories, setCategories] = useState<AnswerCategory[]>();
    useEffect(() => {
        setCategories(undefined);

        if (!question) {
            return;
        }

        api.answerCategories.query(question.id, null).then(result => setCategories(result));
    }, [question]);

    const [dataGroups, setDataGroups] = useState<AnswerGroup[]>();

    useEffect(() => {
        if (!(categories && answers)) {
            return;
        }

        const answersToShow = answers
            ? answers.filter(x => x.answerCategoryId != null && x.hidden === false)
            : undefined;

        if (filters.groupBy === "Group") {
            // @ts-ignore
            groupAnswersByGroup(answersToShow, groups).then(data => {
                setDataGroups(data);
            });
        } else if (filters.groupBy === "Category") {
            // @ts-ignore
            groupAnswersByCategory(answersToShow, categories).then(data => {
                setDataGroups(data);
            });
        } else if (filters.groupBy === "DemographicData") {
            // @ts-ignore
            const demographicQuestion = demographicQuestions.find(x => x.id === filters.groupById);
            // @ts-ignore
            groupAnswersByDemographicData(answersToShow, users, demographicQuestion, demographicData).then(data => {
                setDataGroups(data);
            });
        } else if (filters.groupBy === "User") {
            // @ts-ignore
            groupAnswersByUser(answersToShow, users).then(data => {
                setDataGroups(data);
            });
        }
    }, [categories, answers, filters?.groupBy, filters.groupById, users]);

    return (
        <>
            <AltBlocks>
                <SlideshowBlock elementId={`graph`} className="alt-blocks-first">
                    {question && question.text && (
                        <QuestionTitle
                            text={question.text}
                            // @ts-ignore

                            moduleName={module?.name}
                        />
                    )}

                    {dataGroups && !isLoading && <CategoryGraph answerGroups={dataGroups} />}

                    {dataGroups && dataGroups.length === 0 && !isLoading && (
                        <div className="alert alert-info">{t("WORKSHOP_PRESENTATION_NOANSWER")}</div>
                    )}
                </SlideshowBlock>
                {dataGroups &&
                    dataGroups
                        .sort((a, b) => b.answers.length - a.answers.length)
                        .map((dataGroup, ix) => {
                            return (
                                <SlideshowBlock
                                    elementId={`datagroup-${dataGroup.id}`}
                                    key={dataGroup.id}
                                    showScrollToTop={true}
                                >
                                    <>
                                        <h2 className="mb-3">{dataGroup?.name}</h2>
                                        <RenderIf show={filters?.groupBy === "Category"}>
                                            <GroupLabel
                                                dataGroup={dataGroup}
                                                // @ts-ignore

                                                groups={groups}
                                            />
                                        </RenderIf>

                                        <AnswersLayout answers={dataGroup.answers} />
                                    </>
                                </SlideshowBlock>
                            );
                        })}
            </AltBlocks>
        </>
    );
};

export default TextQuestionSlide;
