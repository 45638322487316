﻿import { Indicator, Flex } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useUnstartedCourses } from "common/UnstartedCoursesContext";
import { HeaderItem } from "./HeaderItem";
import { SecondaryButton } from "@/components";
import { useMatchRoute } from "./useMatchRoute";
import { UrlFactory } from "@/common";

const MyProgramsLabel = () => {
    const { t } = useTranslate();
    const { hasUnstartedCourses, numberOfUnstartedCourses } = useUnstartedCourses();

    return (
        <Indicator color="red" label={numberOfUnstartedCourses} size="xs" disabled={!hasUnstartedCourses}>
            {t("HEADER_NAV_MY_PROGRAMS")}
        </Indicator>
    );
};

export const ProgramsLinks = () => {
    const { t } = useTranslate();
    const { matchRoute } = useMatchRoute();

    return (
        <>
            <Flex align="center">
                <Link to={UrlFactory.team.allPrograms.create({})}>
                    <SecondaryButton eventName={{ object: "header", action: "allprograms" }}>
                        {t("HEADER_NAV_BROWSE_HUPS_PROGRAMS")}
                    </SecondaryButton>
                </Link>
            </Flex>
            <HeaderItem
                to="/course-selector"
                active={matchRoute("/course-selector", true)}
                label={<MyProgramsLabel />}
            />
        </>
    );
};
