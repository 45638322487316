import { useNavigate } from "react-router-dom";

export const useHistory = () => {
    const navigate = useNavigate();

    return {
        push: (url: string) => navigate(url),
        replace: (url: string) => navigate(url, { replace: true }),
    };
};
