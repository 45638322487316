import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ContentLabel } from "../../../components/ContentLabel";
import * as Yup from "yup";
import { Statuses } from "./Statuses";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../common/api";
import { GroupType } from "@/api-client";
import { createPatch } from "../../../common/patchHelper";
import { AppLoader } from "../../../components/Spinner";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { SecondaryButton } from "../../../components/Button/SecondaryButton";
import { CardBlock } from "../../../components/CardBlock";
import { ActionIcon } from "@mantine/core";
import { Icons } from "common/Icons";
import { translateInviteStatus } from "../../helper";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Name is required"),
        statusId: Yup.string().required("Status is required"),
    });
};

export interface GetGroupsModel {
    isPending: boolean;
    result: GroupType[];
}

//@ts-ignore
const GroupTypes = props => {
    //@ts-ignore
    const [editGroupType, setEditGroupType] = useState<GroupType>(null);
    const [getGroupTypes, setGroupTypes] = useState<GetGroupsModel>({
        isPending: false,
        result: [],
    });
    const api = createApi();

    useEffect(() => {
        setGroupTypes(g => ({
            ...g,
            isPending: true,
        }));
        api.groupTypes.query(props.clientId).then(
            result => {
                setGroupTypes(g => ({
                    ...g,
                    isPending: false,
                    result,
                }));
            },
            error => {
                setGroupTypes(g => ({
                    ...g,
                    isPending: false,
                }));
                console.log("Error in getting groups...");
            }
        );
    }, []);

    const { t } = useTranslate();
    const UserStatuses = [
        //@ts-ignore
        { id: 1, label: translateInviteStatus(t)["Active"], value: "Active" },
        //@ts-ignore
        { id: 2, label: translateInviteStatus(t)["Inactive"], value: "Inactive" },
        //@ts-ignore
        { id: 4, label: translateInviteStatus(t)["Deleted"], value: "Deleted" },
    ];

    const initialValues = {
        id: editGroupType?.id || undefined,
        name: editGroupType?.name || "",
        statusId: editGroupType?.status || "Active",
    };

    // @ts-ignore
    const saveGroupType = values => {
        api.groupTypes.post(values).then(
            response => {
                setGroupTypes({
                    ...getGroupTypes,
                    isPending: false,
                    result: [...getGroupTypes.result, response],
                });
            },
            error => {
                console.log(error, "Error in save group.");
            }
        );
    };

    const handleEdit = (groupType: GroupType) => {
        setEditGroupType(groupType);
    };

    return (
        <Row>
            {/* <div className='col-md-4'>
             </div> */}
            <div className="col-md-12">
                <CardBlock mb="xl">
                    <AppLoader className="position-absolute" loading={getGroupTypes.isPending} />
                    <ContentLabel text="Group Types" />
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validate()}
                        onSubmit={(values, helpers) => {
                            if (editGroupType === null) {
                                const allValues = {
                                    ...values,
                                    statusId: values.statusId,
                                    clientId: props.clientId,
                                    createdAt: new Date(),
                                    updatedAt: new Date(),
                                };
                                saveGroupType(allValues);
                                helpers.resetForm();
                            } else {
                                var patch = createPatch(editGroupType, x => {
                                    x.name = values.name;
                                    x.status = values.statusId;
                                });

                                if (patch.length > 0) {
                                    //@ts-ignore
                                    api.groupTypes.patch(editGroupType.id, patch).then(response => {
                                        setGroupTypes(s => ({
                                            ...s,
                                            result: getGroupTypes.result.map(g =>
                                                g.id === response.id
                                                    ? {
                                                          ...g,
                                                          name: response.name,
                                                          status: response.status,
                                                      }
                                                    : g
                                            ),
                                        }));
                                    });
                                }
                            }
                        }}
                    >
                        {formikProps => {
                            let { errors, touched } = formikProps;
                            return (
                                <Form action="#" name="addCourseForm" className="mt-2 editCourseForm">
                                    <Row className="mb-4">
                                        <Col md={4}>
                                            <label>Name</label>
                                            <Field
                                                name="name"
                                                type="text"
                                                className={
                                                    "form-control" + (errors.name && touched.name ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.name && (
                                                <span className="clearfix w-100 text-danger text-left displayblock">
                                                    {" "}
                                                    {errors.name}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={4}>
                                            <label>Status</label>
                                            <Statuses name={`statusId`} statuses={UserStatuses} />
                                        </Col>
                                        <Col md={4} className="align-self-end">
                                            <PrimaryButton type="submit">
                                                {editGroupType !== null ? "Update Group Type" : "Add Group Type"}
                                            </PrimaryButton>
                                            {editGroupType !== null && (
                                                <SecondaryButton
                                                    //@ts-ignore
                                                    onClick={() => setEditGroupType(null)}
                                                >
                                                    Cancel
                                                </SecondaryButton>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                    <table className="table table-borderless table-striped">
                        <thead>
                            <tr>
                                <th>Group Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getGroupTypes &&
                                getGroupTypes.result &&
                                getGroupTypes.result.length > 0 &&
                                getGroupTypes.result.map(g => {
                                    return (
                                        <tr>
                                            <td>{g.name}</td>
                                            <td>{g.status}</td>
                                            <td>
                                                <ActionIcon onClick={() => handleEdit(g)}>{Icons.Edit()}</ActionIcon>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </CardBlock>
            </div>
        </Row>
    );
};

export default GroupTypes;
