export const createTagsSelectItems = (tags: string[] | undefined) => {
    if (tags === undefined) {
        return [];
    }

    const uniqueTags = tags.unique();

    return uniqueTags.map(tag => ({
        label: tag.trim(),
        value: tag,
    }));
};
