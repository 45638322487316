import { Location } from "react-router-dom";
import { Course } from "@/api-client";

export interface PreviewNavItem {
    title: string;
    subtitle?: string;
    link: string;
    active?: boolean;
    isFirstChapter?: boolean;
    chapterProgress?: boolean[] | undefined;
    progress?: boolean | undefined;
    status?: string;
    isAllModulesLocked?: boolean;
    items?: Array<PreviewNavItem>;
}

/**
 *
 * @param currentCourse
 * @param moduleName
 * @param location
 * @param t
 * @return PreviewNavItem
 */

export const PreviewNavHelper = (
    currentCourse: Course,
    moduleName: string,
    location: Location
    //@ts-ignore
): PreviewNavItem[] => {
    if (currentCourse && currentCourse.chapters) {
        //@ts-ignore
        const navItems = currentCourse.chapters
            .filter(a => a.status === "Active" || a.status === "Disabled")
            //@ts-ignore
            .sort((a, b) => a.order - b.order)
            .map((c, i) => {
                return {
                    title: c.name,
                    subtitle: c.navigationLabel ? c.navigationLabel : `Chapter ${i + 1}`,
                    link: `/${moduleName}/${currentCourse.id}/chapter/${c.id}1/chapter-detail`,
                    active: location.pathname.startsWith(`/${moduleName}/${currentCourse.id}/chapter/${c.id}`),
                    status: c.status,
                    isFirstChapter: i === 0 ? true : false,
                    //checkif all moudles are locked
                    isAllModulesLocked: c.modules.every(m => m.status === "Active"),
                    /**
                     * Get Modules and create a nav
                     */
                    //@ts-ignore
                    items: c.modules
                        .filter(m => m.status === "Active" || m.status === "Disabled")
                        //@ts-ignore
                        .sort((a, b) => a.order - b.order)
                        .map((m, mi) => {
                            const activeBlocks = m.blocks.filter(m => m.status === "Active" || m.status === "Disabled");
                            return {
                                title: m.name,
                                status: m.status,
                                link:
                                    m.blocks && m.blocks[0]
                                        ? `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}/block/${activeBlocks[0].id}`
                                        : "",
                                active: location.pathname.startsWith(
                                    `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}`
                                ),
                                /**
                                 * In training section it will be story, exercise & summary
                                 */
                                items:
                                    m.blocks &&
                                    m.blocks
                                        .filter(b => b.status === "Active" || b.status === "Disabled")
                                        .sort((a, b) => a.sortIndex - b.sortIndex)
                                        .map(n => {
                                            return {
                                                title: n.navigationLabel ? n.navigationLabel : n.title,
                                                link: `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}/block/${n.id}`,
                                                active: location.pathname.startsWith(
                                                    `/${moduleName}/${currentCourse.id}/chapter/${c.id}/module/${m.id}/block/${n.id}`
                                                ),
                                                status: n.status,
                                            };
                                        })
                                        .filter(x => x !== undefined),
                            };
                        }),
                };
            });
        //@ts-ignore
        return navItems;
    }
};
