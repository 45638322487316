import React from "react";

interface Props {
    text: string;
    className?: string;
}

export const ContentLabel = ({ text, className }: Props) => {
    return <p className={`content-label ${className}`}>{text}</p>;
};
