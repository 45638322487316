﻿import { useTranslate } from "@tolgee/react";
import { UrlFactory } from "../../../routing/UrlFactory";
import { useCurrentGroup } from "../../../common/courses/context";
import { useMatchRoute } from "../useMatchRoute";
import { HeaderItem } from "../HeaderItem";

export const TeamSpecificLinks = () => {
    const { t } = useTranslate();
    const currentGroup = useCurrentGroup();
    const { matchRoute } = useMatchRoute();

    if (currentGroup === undefined) {
        return null;
    }

    return (
        <>
            <HeaderItem
                label={t("HEADER_NAV_HOME")}
                to={UrlFactory.team.home.create({ groupId: currentGroup.id! })}
                active={matchRoute(UrlFactory.team.home.pattern, true)}
            />
            <HeaderItem
                label={t("HEADER_NAV_PROGRAM")}
                to={UrlFactory.team.program.create({ groupId: currentGroup.id! })}
                active={
                    matchRoute(UrlFactory.team.program.pattern, true) ||
                    matchRoute("/groups/:groupId/training") ||
                    matchRoute("/groups/:groupId/workshop") ||
                    matchRoute("/groups/:groupId/transformation/:details?/:moduleId?", true) ||
                    matchRoute("/groups/:groupId/implementation", true)
                }
            />
            <HeaderItem
                label={t("BOARDS_NAV")}
                to={UrlFactory.board.create({ groupId: currentGroup.id! })}
                active={matchRoute(UrlFactory.board.pattern)}
            />
        </>
    );
};
