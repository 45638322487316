import { Fragment, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { ColorScaleFromValue, evenOddClassName } from "../helpers/helpers";
import { ChapterData, formatNumber } from "./helper";
import "./Transformation.scss";
import { CardBlock } from "@/components";
import { trackPosthogEvent } from "../analytics/customEvents/trackPosthogEvent";

interface HoveredItems {
    moduleIndex: number;
    chapterDataIndex: number;
}

interface TransformationOverviewProps {
    moduleId: number;
    groupId: number;
    chapterData: ChapterData[];
}

export const TransformationOverview = (props: TransformationOverviewProps): JSX.Element => {
    const { t } = useTranslate();
    const history = useHistory();

    const moduleNames: string[] = [
        t("TRANSFORMATION_UNDERSTANDING"),
        t("TRANSFORMATION_STANDARDIZATION"),
        t("TRANSFORMATION_VISUALIZATION"),
        t("TRANSFORMATION_ROUTINIZATION"),
    ];

    const chapterData = props.chapterData;

    const [hoveredItems, setHoveredItems] = useState<HoveredItems>();
    const hoveredItemsClassName = (moduleIndex: number, chapterDataIndex: number) =>
        hoveredItems && moduleIndex === hoveredItems.moduleIndex && chapterDataIndex === hoveredItems.chapterDataIndex
            ? "hovered"
            : "not-hovered";

    const showDetails = (moduleId: number) => {
        trackPosthogEvent("ui", "assessment", "showdetails", { moduleId: moduleId });
        history.push(`/groups/${props.groupId}/transformation/details/${moduleId}`);
    };

    return (
        <CardBlock>
            <div className="transformation-overview">
                <h3>Operational Excellence Index</h3>

                <div className="table-responsive-lg">
                    <table className="table table-borderless text-center">
                        <thead>
                            <tr>
                                <th className="text-left" />
                                {chapterData.map((chapter, ix) => (
                                    <th className={evenOddClassName(ix)} key={chapter.chapterId} colSpan={3}>
                                        {chapter.name}
                                    </th>
                                ))}
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th className="text-left" />
                                {chapterData.map((x, ix) => (
                                    <Fragment key={x.chapterId}>
                                        <th className={evenOddClassName(ix)}>{t("TRANSFORMATION_ME")}</th>
                                        <th className={evenOddClassName(ix)}>{t("TRANSFORMATION_GROUP")}</th>
                                        <th className={evenOddClassName(ix)}>{t("TRANSFORMATION_ALL")}</th>
                                    </Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {moduleNames.map((moduleName, moduleIndex) => (
                                <tr className="text-left" key={moduleIndex}>
                                    <th>{moduleName}</th>
                                    {chapterData.map((chapterData, chapterDataIndex) => {
                                        const tranformationClasses = [
                                            "data",
                                            hoveredItemsClassName(moduleIndex, chapterDataIndex),
                                            evenOddClassName(chapterDataIndex),
                                            props.moduleId === chapterData.moduleIds[moduleIndex] ? "active" : "",
                                        ];
                                        const classes = tranformationClasses.join(" ");
                                        return (
                                            <Fragment key={chapterData.chapterId}>
                                                <td
                                                    onClick={() => showDetails(chapterData.moduleIds[moduleIndex])}
                                                    style={
                                                        !isNaN(chapterData.me[moduleIndex])
                                                            ? {
                                                                  backgroundColor: ColorScaleFromValue(
                                                                      chapterData.me[moduleIndex]
                                                                  ),
                                                              }
                                                            : {}
                                                    }
                                                    // @ts-ignore
                                                    onMouseOut={() => setHoveredItems(null)}
                                                    onMouseOver={() =>
                                                        setHoveredItems({ moduleIndex, chapterDataIndex })
                                                    }
                                                    className={classes}
                                                >
                                                    {formatNumber(chapterData.me[moduleIndex])}
                                                </td>
                                                <td
                                                    onClick={() => showDetails(chapterData.moduleIds[moduleIndex])}
                                                    style={
                                                        !isNaN(chapterData.group[moduleIndex])
                                                            ? {
                                                                  backgroundColor: ColorScaleFromValue(
                                                                      chapterData.group[moduleIndex]
                                                                  ),
                                                              }
                                                            : {}
                                                    }
                                                    // @ts-ignore
                                                    onMouseOut={() => setHoveredItems(null)}
                                                    onMouseOver={() =>
                                                        setHoveredItems({ moduleIndex, chapterDataIndex })
                                                    }
                                                    className={classes}
                                                >
                                                    {formatNumber(chapterData.group[moduleIndex])}
                                                </td>
                                                <td
                                                    onClick={() => showDetails(chapterData.moduleIds[moduleIndex])}
                                                    style={
                                                        !isNaN(chapterData.company[moduleIndex])
                                                            ? {
                                                                  backgroundColor: ColorScaleFromValue(
                                                                      chapterData.company[moduleIndex]
                                                                  ),
                                                              }
                                                            : {}
                                                    }
                                                    // @ts-ignore
                                                    onMouseOut={() => setHoveredItems(null)}
                                                    onMouseOver={() =>
                                                        setHoveredItems({ moduleIndex, chapterDataIndex })
                                                    }
                                                    className={classes}
                                                >
                                                    {formatNumber(chapterData.company[moduleIndex])}
                                                </td>
                                            </Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th className="text-left">{t("TRANSFORMATION_AVG")}</th>
                                {chapterData.map((chapterData, chapterDataIndex) => (
                                    <Fragment key={chapterData.chapterId}>
                                        <td
                                            style={
                                                !isNaN(chapterData.meAvg)
                                                    ? {
                                                          backgroundColor: ColorScaleFromValue(chapterData.meAvg),
                                                      }
                                                    : {}
                                            }
                                            className={`data  ${evenOddClassName(chapterDataIndex)}`}
                                        >
                                            {formatNumber(chapterData.meAvg)}
                                        </td>
                                        <td
                                            style={
                                                !isNaN(chapterData.groupAvg)
                                                    ? {
                                                          backgroundColor: ColorScaleFromValue(chapterData.groupAvg),
                                                      }
                                                    : {}
                                            }
                                            className={`data ${evenOddClassName(chapterDataIndex)}`}
                                        >
                                            {formatNumber(chapterData.groupAvg)}
                                        </td>
                                        <td
                                            style={
                                                !isNaN(chapterData.companyAvg)
                                                    ? {
                                                          backgroundColor: ColorScaleFromValue(chapterData.companyAvg),
                                                      }
                                                    : {}
                                            }
                                            className={`data  ${evenOddClassName(chapterDataIndex)}`}
                                        >
                                            {formatNumber(chapterData.companyAvg)}
                                        </td>
                                    </Fragment>
                                ))}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </CardBlock>
    );
};
