﻿import { route, number, string, RouteWithChildren } from "react-router-typesafe-routes/dom";

export const Routes = {
    Home: route(""),
    Login: route("login"),
    Team: route(
        "groups/:groupId",
        {
            params: { groupId: number().defined() },
        },
        {
            Program: route("program"),
            Board: route("boards"),
        }
    ),
    AllPrograms: route("all-programs"),
    AllProgramsInvite: route("programoverview/:data", {
        params: { data: string().defined() },
    }),
    ProgramSelector: route("course-selector"),
    SysAdmin: route(
        "sysadmin",
        {},
        {
            Clients: route("clients", {}, { Add: route("add") }),
            Users: route(
                "users",
                {},
                {
                    Edit: route("edit/:userId", {
                        params: { userId: number().defined() },
                    }),
                }
            ),
            Admins: route(
                "admins",
                {},
                {
                    Add: route("add"),
                    Edit: route("edit/:userId", {
                        params: { userId: number().defined() },
                    }),
                }
            ),
            Programs: route("programs"),
            Capabilities: route("capability"),
            Module: route("module/:moduleId?"),
            Modules: route("modules"),
            Profile: route("profile"),
            VideoLibrary: route("video-library"),
            ProgramsAndCapabilities: route(
                "courses",
                {},
                { Edit: route(":programId/edit", { params: { programId: number().defined() } }) }
            ),
        }
    ),
    AccountOwner: route(
        "account-owner",
        {},
        {
            Employees: route("edit/organisation-planning/employees"),
        }
    ),
    NewAccountOwner: route(
        "account-owner2",
        {},
        {
            Users: route(
                "users",
                {},
                {
                    Edit: route(":userId", {
                        params: { userId: number().defined() },
                    }),
                }
            ),
            DemographicQuestions: route(
                "demographic-questions",
                {},
                {
                    Edit: route("edit/:id", {
                        params: { id: number().defined() },
                    }),
                }
            ),
            Teams: route(
                "teams",
                {},
                {
                    Edit: route(":teamId", {
                        params: { teamId: number().defined() },
                    }),
                }
            ),
            SendInvites: route("sendinvites"),
            Programs: route(
                "programs",
                {},
                { Program: route(":programId", { params: { programId: number().defined() } }) }
            ),
        }
    ),
};

const fromRoute = <TChildren, TPath extends string, TPathTypes, TSearchTypes, THash extends string[], TStateTypes>(
    route: RouteWithChildren<TChildren, TPath, TPathTypes, TSearchTypes, THash, TStateTypes>
) => {
    return {
        pattern: route.path,
        create: route.buildPath,
        parse: route.getTypedParams,
    };
};

export const UrlFactory = {
    home: fromRoute(Routes.Home),
    allProgramsInvite: fromRoute(Routes.AllProgramsInvite),
    programSelector: fromRoute(Routes.ProgramSelector),
    login: fromRoute(Routes.Login),
    team: {
        home: fromRoute(Routes.Team),
        program: fromRoute(Routes.Team.Program),
        allPrograms: fromRoute(Routes.AllPrograms),
    },
    board: fromRoute(Routes.Team.Board),
    sysadmin: {
        home: fromRoute(Routes.SysAdmin),
        clients: fromRoute(Routes.SysAdmin.Clients),
        addClient: fromRoute(Routes.SysAdmin.Clients.Add),
        users: fromRoute(Routes.SysAdmin.Users),
        user: fromRoute(Routes.SysAdmin.Users.Edit),
        admins: fromRoute(Routes.SysAdmin.Admins),
        admin: fromRoute(Routes.SysAdmin.Admins.Edit),
        programs: fromRoute(Routes.SysAdmin.Programs),
        capability: fromRoute(Routes.SysAdmin.Capabilities),
        module: fromRoute(Routes.SysAdmin.Module),
        modules: fromRoute(Routes.SysAdmin.Modules),
        profile: fromRoute(Routes.SysAdmin.Profile),
        videoLibrary: fromRoute(Routes.SysAdmin.VideoLibrary),
        programsAndCapabilities: fromRoute(Routes.SysAdmin.ProgramsAndCapabilities),
        program: fromRoute(Routes.SysAdmin.ProgramsAndCapabilities.Edit),
    },
    accountOwner: {
        home: fromRoute(Routes.AccountOwner),
        organisationPlanning: {
            employees: fromRoute(Routes.AccountOwner.Employees),
        },
        newHome: fromRoute(Routes.NewAccountOwner),
        users: fromRoute(Routes.NewAccountOwner.Users),
        user: fromRoute(Routes.NewAccountOwner.Users.Edit),
        demographicQuestions: fromRoute(Routes.NewAccountOwner.DemographicQuestions),
        demographicQuestion: fromRoute(Routes.NewAccountOwner.DemographicQuestions.Edit),
        teams: fromRoute(Routes.NewAccountOwner.Teams),
        team: fromRoute(Routes.NewAccountOwner.Teams.Edit),
        sendInvites: fromRoute(Routes.NewAccountOwner.SendInvites),
        programs: fromRoute(Routes.NewAccountOwner.Programs),
        program: fromRoute(Routes.NewAccountOwner.Programs.Program),
    },
} as const;
