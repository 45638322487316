import { useParams } from "react-router-dom";
import { ProgramChild } from "./Program/ProgramChild";
import { TransformationTodo } from "../transformationTodo";

export const TransformationTodoPage = () => {
    const params = useParams();

    return (
        <ProgramChild
            //@ts-ignore
            teamId={params.groupId}
            breadCrumbTitleKey={"PROGRAM_IMPLEMENTATION"}
        >
            <TransformationTodo />
        </ProgramChild>
    );
};
