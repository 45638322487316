﻿import { memo, useMemo } from "react";

export const ColumnHeader = memo((props: { label: string; column: number; value?: number }) => {
    const label = useMemo(() => {
        if (props.value === undefined) {
            return props.label;
        }

        return `${props.label} (${props.value})`;
    }, [props.label, props.value]);

    return (
        <div className={`grid-col-${props.column} grid-row-1 p-1 text-center column-header`}>
            <span>{label}</span>
        </div>
    );
});
