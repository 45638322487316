import { useCallback, useState } from "react";

export const useStateWithListener = <T extends object>(
    initialState: T,
    onChange: (state: T) => void
): [T, (next: T | ((s: T) => T)) => void] => {
    const [state, setStateInternal] = useState<T>(initialState);

    const setState = useCallback(
        (nextState: T | ((s: T) => T)) => {
            if (typeof nextState === "function") {
                setStateInternal(s => {
                    const ns = nextState(s);
                    onChange(ns);
                    return ns;
                });

                return;
            }

            setStateInternal(nextState);
            onChange(nextState);
        },
        [setStateInternal, onChange]
    );

    return [state, setState];
};
