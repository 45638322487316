import { ModuleBlock, ModuleBlockStatuses, ModuleBlocksType } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../../common/api";
import { createPatch } from "../../../../common/patchHelper";
import { ContentLabel } from "../../../../components/ContentLabel";
import { Notify } from "../../../../components/Notify";
import ReactMdeEditor from "../../../../components/ReactMdeEditor";
import { AppLoader } from "../../../../components/Spinner";
import { PreviewHandler } from "../../../components/PreviewHandler";
import { CoursesLayout } from "../../CoursesLayout";
import { useUpdateCurrentCourseFromParams } from "../../helper";
import ModuleBlockSharedProperties from "./ModuleBlockSharedProperties";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () => {
    return Yup.object().shape({
        title: Yup.string().required("Title is required"),
        navigationLabel: Yup.string().required("Navigation Label is required"),
        type: Yup.string().oneOf(["ContentBlock", "ExerciseBlock"], "Block type is required"),
        // sortIndex: Yup.number().typeError("Sort Index's value must be number type")
        //     .required("Sort Index is required").nullable(),
        completionTime: Yup.string()
            .matches(/^(?:(?:([01]\d|2[0-9]):)([0-5]\d):)([0-5]\d)$/, "It should be in hh:mm:ss")
            .nullable(),
        videoBreakPoints: Yup.string()
            .matches(
                /^((((?:(?:([01]\d|2[0-9]):)([0-5]\d):)([0-5]\d)),?\s?)+)$/,
                "It should be in time format(hh:mm:ss, n) with comma separatation"
            )
            .nullable(),
    });
};

interface FormModel {
    moduleBlocksId: number;
    moduleId: number;
    type: ModuleBlocksType;
    title: string;
    sortIndex: number;
    videoId: number;
    statusId: ModuleBlockStatuses;
    description: string;
    completionTime: string;
    videoBreakPoints: string;
    navigationLabel: string;
}

interface EditModuleBlocksResponse {
    moduleBlock: ModuleBlock;
    isPending: boolean;
    isSuccess: boolean;
}

type EditContentModuleBlockProps = {
    courseId: number;
    chapterId: number;
    moduleId: number;
    moduleBlockId: number;
    moduleBlock: ModuleBlock;
};

const ContentModuleBlock = (props: EditContentModuleBlockProps): JSX.Element => {
    const history = useHistory();

    const forAdd: boolean = props.moduleBlock === null;

    const [editModuleBlocks, setEditModuleBlocks] = useState<EditModuleBlocksResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        moduleBlock: null,
        isSuccess: false,
    });

    const [submitted, setSubmitted] = useState(false);
    const api = createApi();
    const updateCourse = useUpdateCurrentCourseFromParams();

    const initialValues: FormModel = {
        //@ts-ignore
        moduleBlocksId: forAdd ? 0 : props.moduleBlock?.id,
        moduleId: props.moduleId,
        sortIndex: forAdd ? 0 : props.moduleBlock?.sortIndex,
        statusId: forAdd ? "Active" : props.moduleBlock?.status,
        //@ts-ignore
        title: forAdd ? "" : props.moduleBlock?.title,
        //@ts-ignore
        type: forAdd ? 0 : props.moduleBlock?.type,
        //@ts-ignore
        videoId: forAdd ? 0 : props.moduleBlock?.videoId,
        //@ts-ignore
        description: forAdd ? "" : props.moduleBlock?.description,
        //@ts-ignore
        completionTime: forAdd ? "" : props.moduleBlock?.completionTime,
        //@ts-ignore
        videoBreakPoints: forAdd ? "" : props.moduleBlock?.videoBreakPoints,
        navigationLabel: forAdd ? "" : props.moduleBlock?.navigationLabel ? props.moduleBlock?.navigationLabel : "",
    };

    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        if (props.moduleBlock?.id && props.moduleBlock?.description) {
            setDescription(props.moduleBlock?.description);
        } else {
            setDescription("");
        }
    }, [props.moduleBlock, props.moduleBlockId]);

    const isLoading = editModuleBlocks.isPending;

    //@ts-ignore
    const updateModuleBlock = (moduleBlockId: number, patch) => {
        api.moduleBlocks.patch(moduleBlockId, patch).then(
            result => {
                setSubmitted(true);
                updateCourse(s => ({
                    ...s,
                    chapters: s.chapters.map(c => {
                        if (c.id === props.chapterId) {
                            return {
                                ...c,
                                modules: c.modules.map(m => {
                                    if (m.id === props.moduleId) {
                                        return {
                                            ...m,
                                            blocks: m.blocks.map(b => {
                                                if (b.id === moduleBlockId) {
                                                    return {
                                                        ...result,
                                                    };
                                                } else {
                                                    return b;
                                                }
                                            }),
                                        };
                                    } else {
                                        return m;
                                    }
                                }),
                            };
                        }
                        return c;
                    }),
                }));
                setEditModuleBlocks({ ...editModuleBlocks, moduleBlock: result, isPending: false, isSuccess: true });
                // dispatch(push(`/sysadmin/courses/${props.courseId}/edit`));
            },
            error => {
                setEditModuleBlocks({ ...editModuleBlocks, isPending: false });
                history.push("/error");
                console.log(error);
            }
        );
    };

    return (
        <CoursesLayout>
            <AppLoader loading={isLoading ? true : false} className="position-absolute" />
            {editModuleBlocks.isSuccess && submitted && (
                <Fragment>
                    <Notify
                        message={forAdd ? "Module Block saved successfully." : "Module Block updated successfully."}
                        id="registersuccess"
                        type="success"
                    />
                </Fragment>
            )}

            <Col className="preview-button-display">
                <ContentLabel text={forAdd ? "Add Module Block" : "Edit Module Block"} />
                {!forAdd && (props?.moduleBlock.status === "Active" || props?.moduleBlock.status === "Disabled") && (
                    <PreviewHandler
                        link={`/sysadmin/course-preview/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}`}
                    />
                )}
            </Col>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={values => {
                    if (forAdd) {
                        setEditModuleBlocks({ ...editModuleBlocks, isPending: true });
                        api.moduleBlocks
                            .post({
                                id: 0,
                                moduleId: props.moduleId,
                                sortIndex: values.sortIndex,
                                type: values.type,
                                title: values.title,
                                status: values.statusId,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                description: values.description,
                                completionTime: values.completionTime,
                                videoBreakPoints: values.videoBreakPoints,
                                navigationLabel: values.navigationLabel,
                                //@ts-ignore
                                videoId: values.videoId === 0 ? null : values.videoId,
                            })
                            .then(
                                x => {
                                    setSubmitted(true);
                                    updateCourse(s => ({
                                        ...s,
                                        chapters: s.chapters.map(c => {
                                            if (c.id === props.chapterId) {
                                                return {
                                                    ...c,
                                                    modules: c.modules.map(m => {
                                                        if (m.id === props.moduleId) {
                                                            return {
                                                                ...m,
                                                                blocks: [...m.blocks, x],
                                                            };
                                                        } else {
                                                            return m;
                                                        }
                                                    }),
                                                };
                                            }
                                            return c;
                                        }),
                                    }));
                                    setEditModuleBlocks({
                                        ...editModuleBlocks,
                                        moduleBlock: x,
                                        isPending: false,
                                        isSuccess: true,
                                    });
                                    history.push(
                                        `/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${x.id}/edit`
                                    );
                                },
                                error => {
                                    setEditModuleBlocks({ ...editModuleBlocks, isPending: false });
                                    history.push("/error");
                                    console.log(error);
                                }
                            );
                    } else {
                        const patch = createPatch(props.moduleBlock, x => {
                            x.id = props.moduleBlockId;
                            x.moduleId = props.moduleId;
                            x.type = values.type;
                            x.status = values.statusId;
                            x.title = values.title;
                            x.sortIndex = values.sortIndex;
                            x.videoId = values.videoId;
                            x.description = values.description;
                            x.completionTime = values.completionTime;
                            x.videoBreakPoints = values.videoBreakPoints;
                            x.navigationLabel = values.navigationLabel;
                        });

                        if (patch && patch.length > 0) {
                            // Update profile
                            if (values.statusId === "Deleted") {
                                confirmAlert({
                                    title: `Are you sure to want to delete Module Block?`,
                                    message: "Action can be undone",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                setEditModuleBlocks({ ...editModuleBlocks, isPending: true });
                                                updateModuleBlock(props.moduleBlockId, patch);
                                            },
                                        },
                                        {
                                            label: "No",
                                            onClick: () => console.log("No pressed."),
                                        },
                                    ],
                                });
                            } else {
                                setEditModuleBlocks({ ...editModuleBlocks, isPending: true });
                                updateModuleBlock(props.moduleBlockId, patch);
                            }
                        } else {
                            setEditModuleBlocks({ ...editModuleBlocks, isPending: false });
                        }
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched, setFieldValue, values } = formikProps;
                    return (
                        <Form name="editModuleBlockForm" className="mt-2 editChapterForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Title</label>
                                    <Field
                                        name="title"
                                        type="text"
                                        className={
                                            "form-control" + (errors.title && touched.title ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="title"
                                        component="span"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Navigation Label</label>
                                    <Field
                                        name="navigationLabel"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.navigationLabel && touched.navigationLabel ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="navigationLabel"
                                        component="span"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Type</label>

                                    <Field name="type" as="select" className="form-select" disabled={!forAdd}>
                                        <option value="0">Please Select Block Type</option>
                                        <option value="ContentBlock">Content Block</option>
                                        <option value="ExerciseBlock">Exercise Block</option>
                                    </Field>
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="type"
                                        component="span"
                                    />
                                </Col>
                            </Row>

                            <ModuleBlockSharedProperties
                                activeVideoId={values.videoId}
                                forAdd={forAdd}
                                selectVideo={val => setFieldValue("videoId", val)}
                            />
                            {errors.videoId && (
                                <span className="clearfix w-100 text-danger text-left displayblock">
                                    {" "}
                                    {errors.videoId}
                                </span>
                            )}

                            <Row className="mb-4">
                                <Col>
                                    <label>Video Breakpoint Time</label>
                                    <Field
                                        name="videoBreakPoints"
                                        type="text"
                                        placeholder="hh:mm:ss, hh:mm:ss(n times)"
                                        className={
                                            "form-control" +
                                            (errors.videoBreakPoints && touched.videoBreakPoints ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="videoBreakPoints"
                                        component="span"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Description</label>
                                    <ReactMdeEditor
                                        onChange={value => {
                                            setDescription(value);
                                            setFieldValue("description", value);
                                        }}
                                        initialValue={description}
                                    />
                                    {/* {errors.description && <span className="clearfix w-100 text-danger text-left displayblock"> {errors.description}</span>} */}
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <label>Status</label>
                                    <Field name="statusId" as="select" className="form-select">
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="InProgress">InProgress</option>
                                        <option value="Deleted">Deleted</option>
                                        <option value="Invited">Invited</option>
                                        <option value="Disabled">Disabled (Locked)</option>
                                    </Field>
                                </Col>
                                <Col>
                                    <label>Completion Time</label>
                                    <Field
                                        name="completionTime"
                                        type="text"
                                        placeholder="hh:mm:ss"
                                        className={
                                            "form-control" +
                                            (errors.completionTime && touched.completionTime ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        className="clearfix w-100 text-danger text-left displayblock"
                                        name="completionTime"
                                        component="span"
                                    />
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" loading={isLoading}>
                                    {forAdd ? "Save" : "Update"}
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </CoursesLayout>
    );
};

export default ContentModuleBlock;
