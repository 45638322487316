import { createStyles, Flex, Text } from "@mantine/core";
import React from "react";
import { Chapter } from "api/clients/GetLatestProgramsResponse";
import { useTranslate } from "@tolgee/react";
import { CompletedProgram } from "./CompletedProgram";
import { Progress } from "./Progress";
import RenderIf from "../../../components/render-if/render-if";
import { exhaustiveCheck } from "../../../common/exhaustiveCheck";

export type Completed = {
    type: "completed";
    name: string;
};

export type Uncompleted = {
    type: "uncompleted";
    name: string;
    chapter: Chapter[];
};

export type ProgramsProps = {
    progress: (Completed | Uncompleted)[];
};

const useProgramProgressStyles = createStyles(theme => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "fit-content(230px) auto",
        columnGap: theme.spacing.md,
        rowGap: theme.spacing.md,
    },
}));

const NoAssignedProgramsText = () => {
    const { t } = useTranslate();
    return (
        <Flex justify="center">
            <Text color="gray.5">{t("HOME_TEAM_HAS_NO_ASSIGNED_PROGRAMS")}</Text>
        </Flex>
    );
};

const ProgramName = (props: { name: string }) => (
    <Flex align="center">
        <Text size="sm">{props.name}</Text>
    </Flex>
);

export const ProgramProgress = (props: ProgramsProps) => {
    const { classes } = useProgramProgressStyles();

    const programs = props.progress.map(p => {
        switch (p.type) {
            case "completed":
                return (
                    <React.Fragment key={p.name}>
                        <Flex align="center">
                            <ProgramName name={p.name} key={p.name} />
                        </Flex>
                        <Flex align="center">
                            <CompletedProgram />
                        </Flex>
                    </React.Fragment>
                );
            case "uncompleted":
                return (
                    <React.Fragment key={p.name}>
                        <Flex align="center">
                            <ProgramName name={p.name} key={p.name} />
                        </Flex>
                        <Flex align="center">
                            <Progress chapters={[...p.chapter]} />
                        </Flex>
                    </React.Fragment>
                );
            default:
                return exhaustiveCheck(p);
        }
    });

    return (
        <Flex rowGap="md" direction="column">
            <RenderIf show={programs.length === 0}>
                <Flex align="center" justify="center">
                    <NoAssignedProgramsText />
                </Flex>
            </RenderIf>
            <RenderIf show={programs.length > 0}>
                <div className={classes.grid}>{programs}</div>
            </RenderIf>
        </Flex>
    );
};
