﻿import { BaseClient } from "./BaseClient";

export type Areas = {
    name: string;
    description: string | null;
    subareas: { name: string; capabilities: Program[] }[];
};

export type Program = {
    id: number;
    published: boolean;
    imageUrl: string;
    title: string;
    shortDescription: string | null;
    experts: string[];
    description: string;
    videoUrl: string | null;
    languages: string[];
    numberOfModules: number;
    breadText: { title: string; text: string }[];
};

type ProgramSuggestion = {
    column: number;
    row: number;
    id: number | null;
};

export class AllProgramsClient extends BaseClient {
    public getAll = async (blobStorageBaseUrl: string, abortController: AbortController): Promise<Areas[]> => {
        const programImagesUrl = `${blobStorageBaseUrl}program-images`;

        const capabilities = await fetch(`${programImagesUrl}/programoverview.json`, {
            mode: "cors",
            method: "get",
            headers: {
                Accept: "application/json",
            },
            signal: abortController?.signal,
        })
            .then(x => x.json())
            .then(
                x =>
                    x as {
                        areas: Areas[];
                    }
            );

        return capabilities.areas.map(a => ({
            ...a,
            subareas: a.subareas.map(s => ({
                ...s,
                capabilities: s.capabilities
                    .filter(x => x.published)
                    .map(c => ({ ...c, imageUrl: `${programImagesUrl}${c.imageUrl}` })),
            })),
        }));
    };

    public getAllPrograms = async (
        blobStorageBaseUrl: string,
        abortController: AbortController
    ): Promise<Program[]> => {
        return (await this.getAll(blobStorageBaseUrl, abortController)).flatMap(a =>
            a.subareas.flatMap(s => s.capabilities)
        );
    };

    public getAssessmentSuggestions = async (
        blobStorageBaseUrl: string,
        abortController: AbortController
    ): Promise<ProgramSuggestion[]> => {
        const programImagesUrl = `${blobStorageBaseUrl}program-images`;

        const suggestions = await fetch(`${programImagesUrl}/programoverview.json`, {
            mode: "cors",
            method: "get",
            headers: {
                Accept: "application/json",
            },
            signal: abortController?.signal,
        })
            .then(x => x.json())
            .then(
                x =>
                    x as {
                        opexAssessmentSuggestions: ProgramSuggestion[];
                    }
            );

        return suggestions.opexAssessmentSuggestions;
    };
}
