import { ModalConfig, ModalProps } from "@/components";
import { EditQuestion } from "./EditQuestion";

type AddDemographicQuestionModalProps = ModalProps<object>;

const AddDemographicQuestion = (props: AddDemographicQuestionModalProps) => {
    return <EditQuestion onSaved={props.onClose} />;
};

export const AddDemographicQuestionModal: ModalConfig<AddDemographicQuestionModalProps> = {
    content: AddDemographicQuestion,
    title: "ONBOARDING_ADD_DEMOGRAPHIC_QUESTIONS",
};
