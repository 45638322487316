import { GroupCourse, Round } from "@/api-client";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { createApi } from "../../../common/api";
import { useAllCourses, useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { createPatch } from "../../../common/patchHelper";
import { useCurrentUser } from "../../../common/user/context";
import { showToast } from "../../../components/Notify";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "../../../components/Spinner";
import { AssignmentModal } from "./AssignmentModal";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ActionIcon, createStyles, Flex, Menu } from "@mantine/core";
import { Icons } from "../../../common/Icons";

/*  eslint-disable react-hooks/exhaustive-deps */

type TrainingAssignmentsProps = {
    groupId: number;
};

type TrainingAssigmentStateModel = {
    isPending: boolean;
    result: TrainingAssesmentModel[];
    error: string | null;
    tempResult: TrainingAssesmentModel[];
};

interface TrainingAssesmentModel extends GroupCourse {
    courseName?: string;
    roundName?: string;
}

const useStyles = createStyles(theme => ({ buttonContainer: { marginBottom: theme.spacing.md } }));

export const TrainingAssignments = (props: TrainingAssignmentsProps) => {
    const { classes } = useStyles();
    const api = createApi();

    const courses = useAllCourses();

    const currentUser = useCurrentUser();

    const organisationDispatch = useOrganisationContextDispatch();

    const [trainingAssigment, setTrainingAssignment] = useState<TrainingAssigmentStateModel>({
        isPending: false,
        /* @ts-ignore */
        result: null,
        /* @ts-ignore */
        tempResult: null,
        error: null,
    });

    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState<boolean>(false);

    /* @ts-ignore */
    const [rounds, setRounds] = useState<Round[]>(null);

    /* @ts-ignore */
    const [editAssignment, setEditAssignment] = useState<TrainingAssesmentModel>(null);

    /* @ts-ignore */
    const [searchKeyword, setSearchKeyword] = useState<string>(null);

    useEffect(() => {
        if (props.groupId && courses && rounds) {
            setTrainingAssignment({ ...trainingAssigment, isPending: true });
            api.groupCourses.query(props.groupId, null, null).then(
                result => {
                    const embedCourseAndRoundNames = result.map(t => ({
                        ...t,
                        courseName: courses.find(c => c.id === t.courseId)?.name,
                        roundName: rounds.find(r => r.id === t.roundId)?.name,
                    }));
                    setTrainingAssignment({
                        ...trainingAssigment,
                        isPending: false,
                        result: embedCourseAndRoundNames,
                        tempResult: embedCourseAndRoundNames,
                    });
                },
                error => {
                    setTrainingAssignment({ ...trainingAssigment, isPending: false, error });
                    console.log(error, "Error in getting traning assignments.");
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupId, courses, rounds]);

    useEffect(() => {
        if (currentUser) {
            /* @ts-ignore */
            getRounds(currentUser.clientId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const getRounds = (userId: number) => {
        api.rounds.query(userId).then(
            result => {
                setRounds(result);
            },
            error => {
                console.log(error, "Error in getting rounds.");
            }
        );
    };

    const findCourseName = (courseId: number): string => {
        /* @ts-ignore */
        return courses.find(c => c.id === courseId)?.name;
    };

    const findRoundName = (roundId: number): string => {
        /* @ts-ignore */
        return rounds.find(c => c.id === roundId)?.name;
    };

    const saveGroupCourseAssignments = (values: GroupCourse) => {
        if (values.id === undefined) {
            api.groupCourses.post(values).then(
                response => {
                    showToast("Training assignment saved successfully.", "success");
                    setTrainingAssignment(s => ({
                        ...s,

                        result: [
                            ...s.result,
                            {
                                ...response,
                                courseName: findCourseName(response.courseId),
                                /* @ts-ignore */
                                roundName: findRoundName(response.roundId),
                            },
                        ],

                        tempResult: [
                            ...s.result,
                            {
                                ...response,
                                courseName: findCourseName(response.courseId),
                                /* @ts-ignore */
                                roundName: findRoundName(response.roundId),
                            },
                        ],
                    }));
                    organisationDispatch(s => {
                        return {
                            ...s,
                            groups: [
                                /* @ts-ignore */
                                ...s.groups.map(gr =>
                                    gr.id === response.groupId
                                        ? {
                                              ...gr,
                                              courseAssignments: [...gr.courseAssignments, response],
                                              courseIds: [...gr.courseIds, response.courseId],
                                          }
                                        : gr
                                ),
                            ],
                        };
                    });
                    setIsAssignmentModalOpen(false);
                    /* @ts-ignore */
                    setEditAssignment(null);
                },
                error => {
                    console.log(error, "Error in posting group assignments.");
                }
            );
        } else {
            const patch = createPatch(editAssignment, s => {
                s.courseId = values.courseId;
                s.groupId = values.groupId;
                s.roundId = values.roundId;
                s.isCompleted = values.isCompleted;
                s.hideUserInfoOnAnswers = values.hideUserInfoOnAnswers;
            });
            if (patch && patch.length > 0) {
                api.groupCourses.patch(values.id, patch).then(
                    response => {
                        setTrainingAssignment(s => ({
                            ...s,

                            result: s.result.map(r =>
                                r.id === response.id
                                    ? {
                                          ...response,
                                          courseName: findCourseName(response.courseId),
                                          /* @ts-ignore */
                                          roundName: findRoundName(response.roundId),
                                      }
                                    : r
                            ),
                            tempResult: s.tempResult.map(r =>
                                r.id === response.id
                                    ? {
                                          ...response,
                                          courseName: findCourseName(response.courseId),
                                          /* @ts-ignore */
                                          roundName: findRoundName(response.roundId),
                                      }
                                    : r
                            ),
                        }));
                        organisationDispatch(s => {
                            return {
                                ...s,
                                groups: [
                                    /* @ts-ignore */
                                    ...s.groups.map(gr =>
                                        gr.id === response.groupId
                                            ? {
                                                  ...gr,
                                                  courseAssignments: [
                                                      ...gr.courseAssignments.filter(ca => ca.id !== response.id),
                                                      response,
                                                  ],
                                                  courseIds: [
                                                      ...gr.courseIds.filter(ci => ci !== response.courseId),
                                                      response.courseId,
                                                  ],
                                              }
                                            : gr
                                    ),
                                ],
                            };
                        });
                        /* @ts-ignore */
                        setEditAssignment(null);
                        setIsAssignmentModalOpen(false);
                    },
                    error => {
                        console.log(error, "Error in patch group courses.");
                    }
                );
            }
        }
    };

    const handleEdit = (trainingAssigmentId: number) => {
        setIsAssignmentModalOpen(true);
        const currentTrainingAssignment = trainingAssigment.result.find(t => t.id === trainingAssigmentId);
        /* @ts-ignore */
        setEditAssignment(currentTrainingAssignment);
    };

    const deleteAssignment = (trainingAssigmentId: number, courseId: number, groupId: number) => {
        confirmAlert({
            title: `Are you sure want to delete Assignment ?`,
            message: "Action can't be undone",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        api.groupCourses.delete(trainingAssigmentId).then(
                            _ => {
                                setTrainingAssignment(s => ({
                                    ...s,
                                    result: [...s.result.filter(r => r.id !== trainingAssigmentId)],
                                    tempResult: [...s.tempResult.filter(r => r.id !== trainingAssigmentId)],
                                }));
                                organisationDispatch(s => {
                                    return {
                                        ...s,
                                        groups: [
                                            /* @ts-ignore */
                                            ...s.groups.map(gr =>
                                                gr.id === groupId
                                                    ? {
                                                          ...gr,
                                                          courseAssignments: [
                                                              ...gr.courseAssignments.filter(
                                                                  ca => ca.id !== trainingAssigmentId
                                                              ),
                                                          ],
                                                          courseIds: [...gr.courseIds.filter(ci => ci !== courseId)],
                                                      }
                                                    : gr
                                            ),
                                        ],
                                    };
                                });
                                showToast("Assignment removed successfully.", "success");
                            },
                            error => {
                                console.log(error, "Error in delete asignments.");
                            }
                        );
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    const coursesIds: number[] = trainingAssigment.result?.map(t => t.courseId);

    useMemo(() => {
        if (trainingAssigment.result) {
            if (searchKeyword) {
                setTrainingAssignment(s => ({
                    ...s,
                    tempResult: [
                        ...s.result.filter(
                            r =>
                                r.courseName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                                r.roundName?.toLowerCase()?.includes(searchKeyword.toLowerCase())
                        ),
                    ],
                }));
            } else {
                setTrainingAssignment(s => ({
                    ...s,
                    tempResult: [...s.result],
                }));
            }
        }
    }, [searchKeyword]);

    return (
        <React.Fragment>
            <AppLoader className="position-absolute" loading={trainingAssigment.isPending} />
            <div className={classes.buttonContainer}>
                <PrimaryButton
                    onClick={() => {
                        setIsAssignmentModalOpen(true);
                    }}
                >
                    {" "}
                    Add Assignment
                </PrimaryButton>
            </div>
            <Row className="filter-data p-2 g-0">
                <Col md="5" sm="6">
                    <input
                        value={searchKeyword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value)}
                        type="text"
                        name="search"
                        placeholder="Type to search..."
                        className="form-control"
                    />
                </Col>
                <Col md="7"></Col>
            </Row>
            <Table striped={true} borderless={true} className="mt-4">
                <tr>
                    <th>Name</th>
                    <th>Round</th>
                    <th></th>
                </tr>
                <tbody>
                    <RenderIf
                        show={
                            trainingAssigment.tempResult === null ||
                            (trainingAssigment.tempResult && trainingAssigment.tempResult.length === 0)
                        }
                    >
                        <tr>
                            <th colSpan={3} className="text-center">
                                No Training Assignment Found
                            </th>
                        </tr>
                    </RenderIf>
                    {trainingAssigment.tempResult &&
                        trainingAssigment.tempResult.map(t => {
                            return (
                                <tr key={t.courseId}>
                                    <td>{t.courseName}</td>
                                    <td>{t.roundName ? t.roundName : "---"}</td>
                                    <td>
                                        <Flex justify="center">
                                            <Menu id="training-assignment-dropdown">
                                                <Menu.Target>
                                                    <ActionIcon>
                                                        <Icons.ContextMenu />
                                                    </ActionIcon>
                                                </Menu.Target>
                                                <Menu.Dropdown>
                                                    {/* @ts-ignore */}
                                                    <Menu.Item onClick={() => handleEdit(t.id)}>{"Edit"}</Menu.Item>

                                                    <Menu.Item
                                                        /* @ts-ignore */
                                                        onClick={() => deleteAssignment(t.id, t.courseId, t.groupId)}
                                                    >
                                                        {"Remove"}
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </Flex>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
                <AssignmentModal
                    show={isAssignmentModalOpen}
                    hide={() => {
                        setIsAssignmentModalOpen(false);
                        /* @ts-ignore */
                        setEditAssignment(null);
                    }}
                    groupId={props.groupId}
                    rounds={rounds}
                    /* @ts-ignore */
                    courses={editAssignment ? courses : courses?.filter(c => coursesIds?.indexOf(c.id) === -1)}
                    handleSubmit={(values: GroupCourse) => saveGroupCourseAssignments(values)}
                    editAssignment={editAssignment}
                />
            </Table>
        </React.Fragment>
    );
};
