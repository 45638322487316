import _ from "lodash";
import { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useHistory } from "@/common";
import { useCurrentCourse, useCurrentGroup, useCurrentGroupCourseContextDispatch } from "../common/courses/context";
import { useCourse } from "../common/OrganisationContext";
import { useCurrentUser } from "../common/user/context";
// import { ProtectedRoute } from "../routing/ProtectedRoute";
import ChapterDetails from "./ChapterDetails";
import Modules from "./Modules";
import "./style.scss";

export type TrainingRouteParams = {
    courseId: string;
    moduleId?: string;
    chapterId: string;
    moduleBlockId?: string;
    groupId: string;
    type?: string;
};

export const TrainingModule = (props: { courseId: number; groupId: number }) => {
    const groupContextDispatch = useCurrentGroupCourseContextDispatch();
    const currentGroup = useCurrentGroup();
    const currentCourse = useCurrentCourse();
    const currentUser = useCurrentUser();
    const params = useParams<TrainingRouteParams>();
    const history = useHistory();

    const courseFromParams = useCourse(props.courseId);

    // Use cannot see training if not participant in group
    useEffect(() => {
        if (!currentGroup || !currentUser) {
            return;
        }

        // @ts-ignore
        if (!currentGroup.members.flatMap(m => m.userId).includes(currentUser.id)) {
            console.info("User not participant in group, redirecting.");
            history.replace(`/groups/${params.groupId}`);
        }
    }, [currentGroup, currentGroup?.members, currentUser, currentUser?.id, history, params?.groupId]);

    // Check if we're switching to a route with a course that does exist for this group, change course for group in that case
    useEffect(() => {
        if (!currentGroup || !courseFromParams) {
            return;
        }

        if (props.courseId === currentCourse?.id) {
            // Already same course, do nothing
            return;
        }

        if (_.includes(currentGroup.courseIds, props.courseId)) {
            // Update current course
            groupContextDispatch(s => ({
                ...s,
                course: courseFromParams,
            }));
        } else {
            // Update current course
            groupContextDispatch(s => ({
                ...s,
                // set current course to undefined
                course: undefined,
            }));

            // Current group does not have this course, redirect to home
            console.info("Course not avilable in group, redirecting.");
            history.replace(`/groups/${params.groupId}`);
        }
    }, [
        currentGroup,
        props.courseId,
        groupContextDispatch,
        courseFromParams,
        history,
        params?.groupId,
        currentCourse?.id,
    ]);

    return (
        <Routes>
            <Route path="chapter/:chapterId/:keys" element={<ChapterDetails />} />
            <Route path="chapter/:chapterId/module/:moduleId/:moduleBlockId" element={<Modules />} />
            <Route path=":type" element={<Modules />} />
        </Routes>
    );
};
