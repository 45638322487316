import React, { Fragment } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Question } from "@/api-client";

type FreeTextQuestionProps = {
    question: Question;
    placeholder: string;
};

export const FreeTextQuestions = React.memo(function FreeTextAnswers(props: FreeTextQuestionProps) {
    const { t } = useTranslate();
    const { question, placeholder } = props;
    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        {question && (
                            <React.Fragment>
                                <p>{question.text}</p>
                                <p>{question.description}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 text-secondary">
                    {question && (
                        <div className="block-content-option">
                            <p className="question_type">
                                {question && question.minAnswer === question.maxAnswer
                                    ? t("MODULE_TEXT_FIELD_DESCRIPTION_SAME", {
                                          max: question.maxAnswer,
                                      })
                                    : t("MODULE_TEXT_FIELD_DESCRIPTION", {
                                          min: question.minAnswer === 0 ? 1 : question.minAnswer,
                                          max: question.maxAnswer,
                                      })}
                            </p>
                            {[...Array(question.maxAnswer).keys()].map((q, i) => {
                                return (
                                    <Fragment key={`question_${i}-${question.id}`}>
                                        <InputGroup
                                            id={`question-${question.questionTypeId}-${i}-questions`}
                                            className="mb-3"
                                        >
                                            <FormControl
                                                className="border border-info "
                                                as="textarea"
                                                name={`questions.question--${question.questionTypeId}`}
                                                minLength={5}
                                                autoComplete="off"
                                                //maxLength={question.char_limit_answer}
                                                disabled={true}
                                                readOnly={true}
                                                id={`questions.question-${question.questionTypeId}`}
                                            />
                                        </InputGroup>
                                    </Fragment>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
