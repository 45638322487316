import { useEffect, useState } from "react";
import { createApi } from "../../../common/api";
import { GetLatestProgramsResponse } from "@/api-client";
import { GetLatestProgramsState } from "./getLatestProgramsState";

const mapLatestPrograms = (latestPrograms: GetLatestProgramsResponse): GetLatestProgramsState => ({
    type: "Success",
    programs: latestPrograms.progress.map(x => {
        if (x.isCompleted) {
            return { name: x.name, type: "completed" };
        }
        return {
            name: x.name,
            type: "uncompleted",
            chapter: x.chapters.map(c => ({ moduleProgress: c.moduleProgress })),
        };
    }),
});

export const useGetLatestPrograms = (
    teamId: number,
    clientId: number,
    onError: (e: Error) => void
): GetLatestProgramsState => {
    const [latestPrograms, setLatestPrograms] = useState<GetLatestProgramsState>({ type: "Loading" });
    const api = createApi();
    useEffect(() => {
        const abortController = new AbortController();

        setLatestPrograms({ type: "Loading" });
        api.home
            .getLatestPrograms(teamId, clientId, abortController)
            .then(lp => setLatestPrograms(mapLatestPrograms(lp)))
            .catch((e: Error) => {
                if (abortController.signal.aborted) {
                    return;
                }
                setLatestPrograms({ type: "Error" });
                onError(e);
            });

        return () => {
            abortController.abort();
        };
    }, [teamId]);

    return latestPrograms;
};
