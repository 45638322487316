﻿import { Course, ParticipantProgress } from "@/api-client";
import React from "react";
import { mapProgressChapterAndModules } from "../../training/helpers/TrainingNavHelper";
import { AttendeesProgress } from "../Progress";

type UserProgressProps = {
    userProgress: ParticipantProgress[];
    currentCourse: Course;
    i: number;
};

export const UserProgress = (props: UserProgressProps): JSX.Element => {
    const mappedProgress = mapProgressChapterAndModules(props.currentCourse, props.userProgress);
    let progress = false;
    const result = props.currentCourse.chapters
        /* @ts-ignore */
        .sort((a, b) => a.order - b.order)
        .map(c => {
            return c.modules
                .filter(mod => mod.status === "Active" || mod.status === "Disabled")
                .map(m =>
                    m.blocks
                        .filter(b => b.status === "Active")
                        .map(b => mappedProgress.find(p => p.moduleBlockId === b.id)?.isCompleted)
                )
                .map(p => {
                    if (p && p.length > 0) {
                        if (p.every(a => a === true)) {
                            progress = true;
                        } else if (p.every(a => a === null)) {
                            /* @ts-ignore */
                            progress = null;
                        } else {
                            progress = false;
                        }
                        return {
                            isCompleted: progress,
                        };
                    } else {
                        return {
                            isCompleted: null,
                        };
                    }
                });
        });

    return <AttendeesProgress progress={result} key={`${props.currentCourse?.id}-progress-${props.i}`} />;
};
