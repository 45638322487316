import React, { useCallback, useMemo, useRef, useState } from "react";
import { Col, Dropdown, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useTranslate, useTolgee } from "@tolgee/react";
import { createApi } from "../../../../common/api";
import { useCurrentClient, useCurrentUser } from "../../../../common/user/context";
import { useBoardContext } from "../../BoardContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAnswers, useCurrentGroup } from "../../../../common/courses/context";
import { useAllGroups } from "../../../../common/OrganisationContext";
import { showToast } from "../../../../components/Notify";
import { AnswerCard } from "../../../../workshop/Presentation/templates/TextQuestionSlide/AnswersLayout";
import { createTaskCategory } from "../TaskCategoriesEditor/TaskCategoryEditor";
import { fromNow } from "../../../../common/date";
import "./TaskCardEditor.scss";
import { MoveCardPopup } from "./MoveCardPopup";
import { ImprovementBoardTask } from "@/api-client";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";

interface TaskCardEditorProps {
    create?: boolean;
    task: ImprovementBoardTask;
    show: boolean;
    handleClose: () => void;
    onSave?: () => void;
}

export const TaskCardEditor = (props: TaskCardEditorProps) => {
    const { t } = useTranslate();
    const tolgee = useTolgee(["language"]);
    const api = createApi();
    const currentUser = useCurrentUser();
    const currentGroup = useCurrentGroup();
    const allGroups = useAllGroups();
    const client = useCurrentClient();
    const answers = useAnswers();
    const boardContext = useBoardContext();
    const [showAddCategory, setShowAddCategory] = useState<boolean>(false);
    const [newCategoryName, setNewCategoryName] = useState<string>();
    const [moveCard, setMoveCard] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    const formik = useFormik<ImprovementBoardTask>({
        initialValues: props.task,
        validationSchema: yup.object({
            title: yup.string().required("Please add title"),
        }),
        onSubmit: async values => {
            await handleSave(values);
        },

        // Important to make set so updates from ex SignalR isn't wiping the form data when re-rendering
        enableReinitialize: false,
    });

    const isNewCategoryNameValid = (): boolean => {
        if (!showAddCategory) {
            return true;
        }

        return newCategoryName !== undefined && newCategoryName !== null && newCategoryName.length > 0;
    };

    const createNewCategoryIfNeeded = useCallback(async () => {
        if (!showAddCategory) {
            return formik.values.taskCategoryId;
        }

        const newCategory = createTaskCategory(
            newCategoryName,
            /* @ts-ignore */
            currentGroup.id,
            /* @ts-ignore */
            client.id,
            boardContext.state.categoryFilters.items.map(f => f.item)
        );

        const savedCategory = await boardContext.addOrUpdateCategory(newCategory);

        await formik.setValues(s => ({ ...s, taskCategoryId: savedCategory.id }));

        // Hide category editor for next task open
        setShowAddCategory(false);
        setNewCategoryName(undefined);

        return savedCategory.id;
    }, [api?.taskCategories, client?.id, currentGroup?.id, newCategoryName, showAddCategory, formik]);

    const handleSave = useCallback(
        async (valuesToSave: ImprovementBoardTask) => {
            const categoryId = await createNewCategoryIfNeeded();

            // set category id on task, waiting for state using setState might not finish before the code below executes
            const taskToSave: ImprovementBoardTask = {
                ...valuesToSave,
                taskCategoryId: categoryId,
                updatedAt: new Date(),
            };

            try {
                await boardContext.addOrUpdateTask(taskToSave, props.task);
            } catch {
                console.error("Failed to add task");
            }

            props.handleClose();
            if (props.onSave) {
                props.onSave();
            }
        },
        [api?.tasks, createNewCategoryIfNeeded, props, formik.values]
    );

    const deleteTask = async () => {
        if (window.confirm("Do you really want to delete this task?")) {
            await boardContext.deleteTask(formik.values.id);
            props.handleClose();
        }
    };

    const relatedAnswer = useMemo(() => {
        return answers?.find(a => a.id === formik.values.answerId);
    }, [answers, formik.values?.answerId]);

    const cloneTask = async () => {
        const task = { ...props.task };
        delete task.id;
        try {
            await boardContext.cloneTask(task);
            showToast("Task cloned successfully", "success");
            props.handleClose();
        } catch {
            showToast(t("COMMON_DEFAULT_ERROR"), "warning");
        }
    };

    const copyAndAssignToAll = async () => {
        /* @ts-ignore */
        await boardContext.copyAndAssignToAll(currentUser.id, props.task);
        props.handleClose();
    };

    return (
        <div ref={ref} style={{ position: "absolute" }}>
            {/* @ts-ignore */}
            <Modal centered show={props.show} onHide={props.handleClose} size={"lg"} container={ref} animation={false}>
                <Modal.Header closeButton>
                    <h5>
                        {formik.values?.title && formik.values?.title.length > 0
                            ? formik.values.title
                            : props.create
                            ? t("BOARD_NEW_TASK")
                            : t("COMMON_EDIT")}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={8}>
                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TITLE")}>
                                        <Form.Control
                                            autoFocus
                                            name="title"
                                            onChange={formik.handleChange}
                                            value={formik.values?.title ?? ""}
                                            isInvalid={formik.touched.title && formik.errors.title !== undefined}
                                            onBlur={formik.handleBlur}
                                            maxLength={55}
                                        />
                                    </FloatingLabel>
                                    <Form.Text className="text-muted">
                                        {t(
                                            "COMMON_CHARSLEFT",
                                            /* @ts-ignore */
                                            { number: 55 - formik.values?.title.length ?? 0 }
                                        )}
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3 task-description-container">
                                    <FloatingLabel label={t("BOARDS_TASK_DESCRIPTION_PLACEHOLDER")}>
                                        <Form.Control
                                            className="description-box"
                                            as="textarea"
                                            name="description"
                                            cols={15}
                                            rows={5}
                                            placeholder={t("BOARDS_TASK_DESCRIPTION_PLACEHOLDER")}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.description ?? ""}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                {!showAddCategory && (
                                    <Form.Group className="mb-3">
                                        <FloatingLabel label={t("BOARD_TASK_CATEGORY")}>
                                            <Form.Select
                                                onChange={e =>
                                                    formik.setFieldValue("taskCategoryId", parseInt(e.target.value, 10))
                                                }
                                                name="taskCategoryId"
                                                value={formik.values.taskCategoryId ?? ""}
                                            >
                                                <option value="">{t("BOARD_COMMON_UNCATEGORISED")}</option>
                                                {
                                                    /* @ts-ignore */
                                                    boardContext.state.categoryFilters.items
                                                        .map(f => f.item)
                                                        /* @ts-ignore */
                                                        .sort((a, b) => a.name?.localeCompare(b?.name))
                                                        .map(category => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))
                                                }
                                                ;
                                            </Form.Select>
                                        </FloatingLabel>
                                        <button
                                            className="btn btn-link p-0"
                                            type="button"
                                            onClick={() => setShowAddCategory(true)}
                                        >
                                            {t("BOARD_CREATE_NEW_CATEGORY")}
                                        </button>
                                    </Form.Group>
                                )}

                                {showAddCategory && (
                                    <Form.Group className="mb-3">
                                        <FloatingLabel label={t("BOARD_TASK_CATEGORY")}>
                                            <Form.Control
                                                autoFocus
                                                isInvalid={!isNewCategoryNameValid()}
                                                onChange={e => {
                                                    e.persist();
                                                    setNewCategoryName(e.target.value);
                                                }}
                                                value={newCategoryName}
                                            />
                                            <button
                                                className="btn btn-link p-0"
                                                type="button"
                                                onClick={() => setShowAddCategory(false)}
                                            >
                                                {t("COMMON_CANCEL")}
                                            </button>
                                        </FloatingLabel>
                                    </Form.Group>
                                )}

                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TASK_PRIORITY")}>
                                        <Form.Select
                                            name="priority"
                                            onChange={formik.handleChange}
                                            value={formik.values?.priority ?? "Normal"}
                                        >
                                            <option value="Normal">{t("BOARD_TASK_PRIORITY_Normal")}</option>
                                            <option value="High">{t("BOARD_TASK_PRIORITY_High")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TASK_STATUS")}>
                                        <Form.Select
                                            name="status"
                                            onChange={formik.handleChange}
                                            value={formik.values?.status.toString() ?? "New"}
                                        >
                                            <option value="New">{t("BOARD_TASK_STATUS_New")}</option>
                                            <option value="Todo">{t("BOARD_TASK_STATUS_Todo")}</option>
                                            <option value="Active">{t("BOARD_TASK_STATUS_Active")}</option>
                                            <option value="Completed">{t("BOARD_TASK_STATUS_Completed")}</option>
                                            <option value="Archived">{t("BOARD_TASK_STATUS_Archived")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel label={t("BOARD_TASK_ASSIGNED_TO")}>
                                        <Form.Select
                                            name="assignedToUserId"
                                            onChange={e =>
                                                formik.setFieldValue("assignedToUserId", parseInt(e.target.value, 10))
                                            }
                                            value={formik.values?.assignedToUserId ?? ""}
                                        >
                                            <option value="">{t("BOARDS_GENERAL")}</option>
                                            {
                                                /* @ts-ignore */
                                                currentGroup &&
                                                    currentGroup.members
                                                        .map(x => x.user)
                                                        /* @ts-ignore */
                                                        .filter(u => u.status === "Active")
                                                        /* @ts-ignore */
                                                        .sort((a, b) => a.fullName.localeCompare(b?.fullName))
                                                        .map(user => (
                                                            <>
                                                                <option
                                                                    /* @ts-ignore */
                                                                    key={user.id}
                                                                    /* @ts-ignore */
                                                                    value={user.id}
                                                                >
                                                                    {
                                                                        /* @ts-ignore */
                                                                        user.fullName
                                                                    }
                                                                </option>
                                                            </>
                                                        ))
                                            }
                                            ;
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                                {props.task?.id && (
                                    <React.Fragment>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                className={`w-100`}
                                                variant="outline-primary"
                                                id="training-assignment-dropdown"
                                            >
                                                {t("BOARD_ACTIONS")}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="drop-down-menu">
                                                <Dropdown.Item onClick={() => setMoveCard(true)}>
                                                    <i className="far fa-arrow-alt-square-right" />{" "}
                                                    {t("BOARD_CARD_EDITOR_MOVE")}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => cloneTask()}>
                                                    <i className="fa fa-clone" /> {t("BOARD_CARD_EDITOR_DUPLICATE")}
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => copyAndAssignToAll()}>
                                                    <i className="fa-regular fa-rectangle-history-circle-plus" />{" "}
                                                    {t("BOARD_COPY_AND_ASSIGN_ALL")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Form>

                    <MoveCardPopup
                        show={moveCard}
                        hide={() => setMoveCard(false)}
                        task={props.task}
                        hideTaskModal={props.handleClose}
                    />

                    {relatedAnswer && (
                        <>
                            <span>{t("BOARD_TASK_RELATED_ANSWER")}</span>
                            <Row className="mt-5">
                                <Col sm={6}>
                                    <div className="pt-3 answer-wrapper">
                                        <AnswerCard answer={relatedAnswer} showRelatedTask={false} />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <div className="row w-100 mx-0">
                        <div className="col-sm-8">
                            <span className="d-block">
                                {formik.values?.id && (
                                    <>
                                        {t("BOARD_CREATED")}: {fromNow(formik.values?.createdAt, tolgee.getLanguage()!)}
                                    </>
                                )}{" "}
                                {formik.values?.updatedAt !== formik.values?.createdAt && (
                                    <>
                                        ({t("BOARD_UPDATED")}:{" "}
                                        {fromNow(formik.values?.updatedAt, tolgee.getLanguage()!)})
                                    </>
                                )}
                            </span>
                            {formik.values?.createdByUser && (
                                <span>
                                    {t("BOARD_TASK_FOOTER_CREATEDBY", { name: formik.values?.createdByUser?.fullName })}
                                </span>
                            )}
                            {formik.values?.createdInGroupId !== formik.values?.groupId && (
                                <span>
                                    {" "}
                                    {t("BOARD_TASK_FOOTER_CREATED_IN_GROUP", {
                                        /* @ts-ignore */
                                        name: allGroups.find(x => x.id === formik.values?.createdInGroupId)?.name,
                                    })}
                                </span>
                            )}
                        </div>
                        <div className="col-sm-4 text-end">
                            {!props.create && (
                                <button
                                    type="button"
                                    className="btn btn-link me-1 ms-auto"
                                    onClick={() => deleteTask()}
                                >
                                    {t("COMMON_DELETE")}
                                </button>
                            )}
                            <PrimaryButton
                                type="submit"
                                disabled={!formik.isValid || !isNewCategoryNameValid()}
                                onClick={() => formik.handleSubmit()}
                                eventName={{ object: "improvementboard", action: "savetask" }}
                            >
                                {t("COMMON_SAVE")}
                            </PrimaryButton>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
