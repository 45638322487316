import { Course, Module } from "@/api-client";

interface ChapterItem {
    id?: number;
    name?: string;
    description?: string;
    moduleUrl: string;
}

const getFirstPublishedBlockId = (module: Module): number | null => {
    const publishedBlocks = module.blocks.filter(b => b.status === "Active");
    if (publishedBlocks.length > 0) {
        return publishedBlocks[0].id as number;
    }
    return null;
};

export const getChapterDetailByChapterId = (
    currentCourse: Course,
    chapterId: number,
    courseId: number,
    groupId: number
): ChapterItem => {
    const chapter = currentCourse.chapters.find(x => {
        return x.id === chapterId;
    });
    if (chapter !== undefined) {
        const getPublishedModules = chapter.modules.filter(c => c.status === "Active");
        if (getPublishedModules.length > 0) {
            const firstPublishedBlockId = getFirstPublishedBlockId(getPublishedModules[0]);
            return {
                id: chapter.id,
                name: chapter.name,

                description: chapter.description,

                moduleUrl:
                    firstPublishedBlockId !== null
                        ? `/groups/${groupId}/training/${courseId}/chapter/${chapterId}/module/${
                              getPublishedModules[0].id as number
                          }/${firstPublishedBlockId}`
                        : `/groups/${groupId}/training/${courseId}/chapter/${chapterId}/chapter-detail`,
            };
        }
    }
    return {
        moduleUrl: "/",
    };
};
