import { UrlFactory } from "routing/UrlFactory";

type AccountOwnerNavigationItem = {
    label: string;
    to: string;
    description: string;
};

export const accountOwnerNavigationItems: AccountOwnerNavigationItem[] = [
    {
        label: "ACCOUNTOWNER_HOME_USERS",
        to: UrlFactory.accountOwner.users.create({}),
        description: "ACCOUNTOWNER_HOME_USER_DESCRIPTION",
    },
    {
        label: "ACCOUNTOWNER_HOME_TEAMS",
        to: UrlFactory.accountOwner.teams.create({}),
        description: "ACCOUNTOWNER_HOME_TEAM_DESCRIPTION",
    },
    {
        label: "ACCOUNTOWNER_HOME_PROGRAMS",
        to: UrlFactory.accountOwner.programs.create({}),
        description: "ACCOUNTOWNER_HOME_PROGRAM_DESCRIPTION",
    },
    {
        label: "ACCOUNTOWNER_HOME_INVITE_USERS",
        to: UrlFactory.accountOwner.sendInvites.create({}),
        description: "ACCOUNTOWNER_HOME_INVITE_USERS_DESCRIPTION",
    },
    {
        label: "ACCOUNTOWNER_HOME_DEMOGRAPHIC_QUESTIONS",
        to: UrlFactory.accountOwner.demographicQuestions.create({}),
        description: "ACCOUNTOWNER_HOME_DEMOGRAPHIC_QUESTIONS_DESCRIPTION",
    },
];

export type AccountOwnerNavbarNavigationItem = Omit<AccountOwnerNavigationItem, "description">;
export const accountOwnerNavbarNavigationItems: AccountOwnerNavbarNavigationItem[] = [
    { label: "ACCOUNTOWNER_HOME_OVERVIEW", to: UrlFactory.accountOwner.newHome.create({}) },
    ...accountOwnerNavigationItems,
];
