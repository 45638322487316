﻿import React from "react";
import { NavButton } from "@/components";

export const ImplementationShortcut = (props: { groupId: number }) => {
    return (
        <NavButton
            eventName={{ object: "program", action: "implementationclick" }}
            link={`/groups/${props.groupId}/implementation`}
            textKey={"PROGRAM_IMPLEMENTATION"}
        />
    );
};
