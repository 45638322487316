import { Course, CourseStatuses, CourseTypes } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { useUserContext } from "../../../common/user/context";
import { UserHasOneOfRoles, UserHasSpecificRole } from "../../../common/user/utils";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify } from "../../../components/Notify";
import ReactMdeEditor from "../../../components/ReactMdeEditor";
import { AppLoader } from "../../../components/Spinner";
import { EXPERT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import Sidebar from "../../components/Sidebar";
import { CoursesMenu } from "../../components/Sidebar/NavBarHelper";
import { useSysadminContextDispatch, useSysAdminCourses } from "../../SysadminContext";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { useTranslate } from "@tolgee/react";
import { CardBlock } from "../../../components/CardBlock";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = () =>
    Yup.object().shape({
        name: Yup.string().required("Course Name is required"),
    });

interface AddCourseResponse {
    course: Course;
    isPending: boolean;
    isSuccess: boolean;
}

interface FormModel {
    courseId: number;
    name: string;
    description: string;
    status: CourseStatuses;
    enforceSequence: boolean;
    adminName: string;
    courseType: CourseTypes;
    enableDiploma: boolean;
    ownerId: number;
    tags: string[];
}

function AddCourse() {
    const courses = useSysAdminCourses();
    const sysadminDispatch = useSysadminContextDispatch();
    const api = createApi();
    const history = useHistory();
    const userContext = useUserContext();
    //@ts-ignore
    const isExpert: boolean = UserHasSpecificRole(userContext.user, EXPERT_ROLE);
    //@ts-ignore
    const isSysadmin: boolean = UserHasOneOfRoles(userContext.user, [SYSADMIN_ROLE]);
    const [addCourse, setAddCourse] = useState<AddCourseResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        course: null,
        isSuccess: false,
    });
    const { t } = useTranslate();

    const initialValues: FormModel = {
        courseId: 0,
        name: "",
        adminName: "",
        description: "",
        status: "Active",
        //order: 0,
        enforceSequence: true,
        courseType: "Training",
        enableDiploma: false,
        //@ts-ignore
        ownerId: null,
        //@ts-ignore
        tags: null,
    };

    //@ts-ignore
    const navMenu = CoursesMenu(courses);

    const isLoading = !!addCourse.isPending;
    const [description, setDescription] = useState<string>("");
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: "Add Program or Capability", path: null },
    ];

    return (
        <>
            <Breadcrumbs
                //@ts-ignore
                breadCrumb={links}
            />
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar children={navMenu} name={t("SYSADMIN_PROGRAMS_AND_CAPABILITIES")} />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <AppLoader loading={!!isLoading} className="position-absolute" />
                        {addCourse.isSuccess && (
                            <>
                                <Notify
                                    message="Program or Capability added successfully."
                                    id="registersuccess"
                                    type="success"
                                />
                                {/* <Redirect to="/sysadmin/clients" /> */}
                            </>
                        )}
                        <ContentLabel text="Add Program or Capability" />
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validate()}
                            onSubmit={(values: FormModel) => {
                                setAddCourse({ ...addCourse, isPending: true });
                                api.courses
                                    .post({
                                        id: 0,
                                        name: values.name,
                                        adminName: values.adminName,
                                        description: values.description,
                                        status: values.status,
                                        // order: values.order,
                                        enforceSequence: values.enforceSequence,
                                        createdAt: new Date(),
                                        updatedAt: new Date(),
                                        courseType: values.courseType,
                                        enableDiploma: values.enableDiploma,
                                        //@ts-ignore
                                        ownerId: values.ownerId
                                            ? values.ownerId
                                            : isExpert
                                            ? userContext.user.id
                                            : null,
                                        tags: values.tags,
                                    })
                                    .then(
                                        x => {
                                            sysadminDispatch(s => ({
                                                ...s,
                                                //@ts-ignore
                                                courses: [...s.courses, x],
                                            }));
                                            setAddCourse({
                                                ...addCourse,
                                                course: x,
                                                isPending: false,
                                                isSuccess: true,
                                            });
                                            history.push(`/sysadmin/courses/${x?.id}/edit`);
                                        },
                                        error => {
                                            setAddCourse({ ...addCourse, isPending: false });
                                            history.push("/error");
                                            console.log(error);
                                        }
                                    );
                            }}
                        >
                            {formikProps => {
                                const { errors, touched, setFieldValue } = formikProps;
                                return (
                                    <Form name="addCourseForm" className="mt-2 editCourseForm">
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Name</label>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    className={`form-control${
                                                        errors.name && touched.name ? " is-invalid" : ""
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    className="clearfix w-100 text-danger text-left displayblock"
                                                    name="name"
                                                    component="span"
                                                />
                                            </Col>
                                            <Col />
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Admin Name</label>
                                                <Field
                                                    name="adminName"
                                                    type="text"
                                                    className={`form-control${
                                                        errors.adminName && touched.adminName ? " is-invalid" : ""
                                                    }`}
                                                />
                                            </Col>
                                            <Col />
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Description</label>
                                                <ReactMdeEditor
                                                    onChange={value => {
                                                        setDescription(value);
                                                        setFieldValue("description", value);
                                                    }}
                                                    initialValue={description}
                                                />
                                                {/* <ErrorMessage className="clearfix w-100 text-danger text-left displayblock" name="description" component="span" /> */}
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col>
                                                <label>Enforce Sequence</label>
                                                <Field name="enforceSequence" as="select" className="form-select">
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Field>
                                            </Col>

                                            <Col>
                                                <label>Status</label>
                                                {/** Please move the option in const I think we can reuse this eiter we have to
                                                 * write it eveywhere
                                                 */}
                                                <Field name="status" as="select" className="form-select">
                                                    <option value="Active">Active</option>
                                                    <option value="Inprogress">Inprogress</option>
                                                    <option value="Deleted">Deleted</option>
                                                    <option value="Disabled">Disabled</option>
                                                    <option value="Template">Template</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>Enable Diploma</label>
                                                <Field name="enableDiploma" as="select" className="form-select">
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Field>
                                            </Col>
                                            <Col>
                                                <label>Type</label>
                                                <Field name="courseType" as="select" className="form-select">
                                                    <option value="Training">Training</option>
                                                    <option value="ImplementationCourse">Implementation Course</option>
                                                    <option value="AssessmentCourse">AssessmentCourse</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        {isSysadmin && (
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Course Expert</label>
                                                    <RoleBasedUser roleName={EXPERT_ROLE} fieldName="ownerId" />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="mb-4">
                                            <Col>
                                                <label className="form-label">Tags</label>
                                                <AutocompleteTags
                                                    module="courses"
                                                    //@ts-ignore
                                                    defaultValue={null}
                                                    selectedValue={(value: string[]) => setFieldValue("tags", value)}
                                                />
                                            </Col>
                                        </Row>
                                        <ContentBlockButtonContainer>
                                            <PrimaryButton type="submit">{t("COMMON_SAVE")}</PrimaryButton>
                                        </ContentBlockButtonContainer>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBlock>
                </div>
            </div>
        </>
    );
}

export default AddCourse;
