import React, { ChangeEvent, ReactNode } from "react";
import "./style.scss";

interface StyledCheckboxProps {
    children?: ReactNode;
    className?: string;
    onChange: (value: string | number) => void;
    name?: string;
    disabled?: boolean;
    value?: string | number;
    checked?: boolean;
    label?: string;
    id: string;
}

const StyledRadioButton = (props: StyledCheckboxProps): JSX.Element => {
    return (
        <div className={`radio-item ${props.className}`}>
            <input
                type="radio"
                name={props.name}
                value={props.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                checked={props.checked}
                disabled={props.disabled}
                id={props.id}
            />
            <label htmlFor={`${props.id}`}>{props.label}</label>
        </div>
    );
};

export default StyledRadioButton;
