import { Chapter, Course, Module, Question } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route, useParams } from "react-router-dom";
import { useCurrentCourse } from "../../common/courses/context";
import Slideshow from "../../components/Slideshow";
// import { ProtectedRoute } from "../../routing/ProtectedRoute";
import { QuestionTypes } from "../QuestionTypes";
import { getPresentationLink } from "./helpers";
import "./slideshow.scss";
import IntroSlide from "./templates/IntroSlide";
import TextQuestionSlide from "./templates/TextQuestionSlide/TextQuestionSlide";
import {
    PresentationContextState,
    PresentationProvider,
    usePresentationContext,
    usePresentationContextDispatch,
} from "./PresentationContext";
import { PresentationNav, PresentationNavProps } from "./PresentationNav";
import MatrixGridQuestionSlide from "./templates/MatrixGridQuestionSlide.tsx";
import MatrixScaleQuestionSlide from "./templates/MatrixScaleQuestionSlide";
import RatingQuestionSlide from "./templates/RatingQuestionSlide";
import { useFullscreen } from "@mantine/hooks";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

type RouteParams = {
    groupId: string;
    questionId?: string;
    chapterId: string;
    moduleId: string;
};

const RenderTemplate = () => {
    const context = usePresentationContext();

    if (!context.question) {
        return <IntroSlide />;
    } else {
        switch (context.question.questionTypeId) {
            case QuestionTypes.TEXT:
                return <TextQuestionSlide />;
            case QuestionTypes.RATING:
                return <RatingQuestionSlide />;
            case QuestionTypes.MATRIX_SCALE:
                return <MatrixScaleQuestionSlide />;
            case QuestionTypes.MATRIX_GRID:
                return <MatrixGridQuestionSlide />;
            default:
                return null;
        }
    }
};

type RenderPresentationProps = PresentationNavProps;

const RenderPresentation = (props: RenderPresentationProps) => {
    const currentCourse = useCurrentCourse();
    const context = usePresentationContext();
    const contextDispatch = usePresentationContextDispatch();

    const [nextQuestionLink, setNextQuestionLink] = useState<string>();
    const [prevQuestionLink, setPrevQuestionLink] = useState<string>();

    const params = useParams<RouteParams>();
    //@ts-ignore
    const chapterId = parseInt(params.chapterId);
    // @ts-ignore
    const questionId = parseInt(params.questionId);
    //@ts-ignore
    const moduleId = parseInt(params.moduleId);

    useEffect(() => {
        if (!currentCourse) {
            return;
        }

        // @ts-ignore
        const currentChapter = currentCourse?.chapters.find(x => x.id === chapterId);
        // @ts-ignore
        const currentModule =
            currentCourse && currentCourse?.chapters.flatMap(c => c?.modules).find(m => m?.id === moduleId);
        // @ts-ignore
        const currentQuestion = currentModule?.blocks
            .filter(x => x.questions)
            .flatMap(x => x.questions)
            .find(x => x.id === questionId);

        contextDispatch(x => ({
            ...x,
            chapter: currentChapter,
            module: currentModule,
            question: currentQuestion,
        }));
    }, [currentCourse, chapterId, moduleId, questionId, contextDispatch]);

    useEffect(() => {
        if (!currentCourse) {
            return;
        }

        const courseContent = getCourseContentFlat(currentCourse);

        const links = getLinks(courseContent, context, params as any);

        setPrevQuestionLink(links?.prevLink);
        setNextQuestionLink(links?.nextLink);
    }, [currentCourse, context, params]);

    return (
        <Container className="workshop-slideshow">
            <Slideshow nextLink={nextQuestionLink} previousLink={prevQuestionLink}>
                <>
                    <PresentationNav
                        exitFullscreen={props.exitFullscreen}
                        enterFullscreen={props.enterFullscreen}
                        isFullscreen={props.isFullscreen}
                    />
                    <RenderTemplate />
                </>
            </Slideshow>
        </Container>
    );
};

interface CourseContentFlat {
    chapter: Chapter;
    module: Module;
    question: Question;
}

export const getCourseContentFlat = (course: Course): CourseContentFlat[] => {
    // @ts-ignore
    return course?.chapters
        ?.filter(c => c.status === "Active")
        .flatMap(chapter =>
            // @ts-ignore

            chapter?.modules
                .filter(m => m.status === "Active")
                .flatMap(module =>
                    // @ts-ignore

                    module?.blocks
                        .filter(b => b.status === "Active")
                        .flatMap(block =>
                            block?.questions
                                ?.filter(q => q.status === "Active")
                                .map(question => ({
                                    chapter: chapter,
                                    module: module,
                                    question: question,
                                }))
                        )
                )
        )
        .filter(x => x !== undefined);
};

const getLinks = (courseContent: CourseContentFlat[], context: PresentationContextState, params: RouteParams) => {
    if (!courseContent || !context.module || !context.chapter) {
        return;
    }

    // @ts-ignore
    let currentMatch = context.question
        ? // @ts-ignore

          courseContent.find(x => x.question.id === context.question.id)
        : // @ts-ignore

          courseContent.find(x => x.module.id === context.module.id);

    let nextLink: string;

    const groupIdFromParams = params.groupId ? parseInt(params.groupId) : undefined;

    // On a module start screen

    // If no question is set, current match is the first question but we show module screen, so only advance one if no question is set
    const nextIndex = context.question
        ? // @ts-ignore
          courseContent.indexOf(currentMatch) + 1
        : // @ts-ignore

          courseContent.indexOf(currentMatch);

    const next = courseContent.length >= nextIndex ? courseContent[nextIndex] : null;

    if (next) {
        // @ts-ignore
        if (next.module.id !== currentMatch.module.id) {
            // Another chapter, show intro
            // @ts-ignore
            nextLink = getPresentationLink(next.chapter.id, next.module.id, null, groupIdFromParams); //`/workshop/presentation/chapter/${next.chapter.id}/module/${next.module.id}`;
        } else {
            // Same chapter, show question
            nextLink = getPresentationLink(
                next.chapter.id,
                // @ts-ignore
                next.module.id,
                next.question.id,
                groupIdFromParams
            ); //`/workshop/presentation/chapter/${next.chapter.id}/module/${next.module.id}/question/${next.question.id}`;
        }
    } else {
        // @ts-ignore
        nextLink = null;
    }

    let prevLink: string;

    currentMatch = context.question
        ? // @ts-ignore
          courseContent.find(x => x.question.id === context.question.id)
        : // @ts-ignore
          courseContent.find(x => x.module.id === context.module.id);

    // On a module start screen

    // @ts-ignore
    const prevIndex = courseContent.indexOf(currentMatch) - 1;
    const prev = prevIndex >= 0 ? courseContent[prevIndex] : null;

    // @ts-ignore
    if (courseContent.indexOf(currentMatch) === 0 && context.question) {
        // First question in first module
        prevLink = getPresentationLink(
            // @ts-ignore
            currentMatch.chapter.id,
            // @ts-ignore
            currentMatch.module.id,
            null,
            groupIdFromParams
        ); //`/workshop/presentation/chapter/${currentMatch.chapter.id}/module/${currentMatch.module.id}`;
    } else if (prev) {
        // @ts-ignore
        if (prev.module.id !== currentMatch.module.id && context.question) {
            // Another chapter is the previous, show intro from currentMatch
            prevLink = getPresentationLink(
                // @ts-ignore
                currentMatch.chapter.id,
                // @ts-ignore
                currentMatch.module.id,
                null,
                groupIdFromParams
            ); //`/workshop/presentation/chapter/${currentMatch.chapter.id}/module/${currentMatch.module.id}`;
        } else {
            // Same chapter, show question
            prevLink = getPresentationLink(
                prev.chapter.id,
                // @ts-ignore
                prev.module.id,
                prev.question.id,
                groupIdFromParams
            ); //`/workshop/presentation/chapter/${prev.chapter.id}/module/${prev.module.id}/question/${prev.question.id}`;
        }
    } else {
        // @ts-ignore
        prevLink = null;
    }

    return { prevLink, nextLink };
};

export const Presentation = () => {
    const { toggle, fullscreen, ref } = useFullscreen();

    const enterFullscreen = async () => {
        if (!fullscreen) {
            trackPosthogEvent("ui", "presentation", "fullscreen");
            await toggle();
        }
    };

    const exitFullScreen = async () => {
        if (fullscreen) {
            await toggle();
        }
    };

    return (
        <div ref={ref} className={fullscreen ? "fullscreen-enabled fullscreen" : "fullscreen"}>
            <PresentationProvider>
                <Routes>
                    <Route
                        path="chapter/:chapterId/module/:moduleId"
                        element={
                            <RenderPresentation
                                isFullscreen={fullscreen}
                                enterFullscreen={enterFullscreen}
                                exitFullscreen={exitFullScreen}
                            />
                        }
                    />
                    <Route
                        path="chapter/:chapterId/module/:moduleId/question/:questionId"
                        element={
                            <RenderPresentation
                                isFullscreen={fullscreen}
                                enterFullscreen={enterFullscreen}
                                exitFullscreen={exitFullScreen}
                            />
                        }
                    />
                </Routes>
            </PresentationProvider>
        </div>
    );
};
