import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { Checkbox, Group } from "@mantine/core";

type CheckboxGroupInputProps = {
    fieldName: string;
    label: string;
    withAsterisk?: boolean;
    options: { value: string; label: string }[];
};

export const CheckboxGroupInput = (props: CheckboxGroupInputProps) => {
    const [field, meta, helpers] = useField<string[]>(props.fieldName);
    const { t } = useTranslate();

    return (
        <Checkbox.Group
            mt="md"
            withAsterisk={props.withAsterisk}
            label={t(props.label)}
            value={field.value}
            onChange={values => helpers.setValue(values)}
            onBlur={() => {
                helpers.setTouched(true);
            }}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
        >
            <Group mt="xs">
                {props.options.map(option => (
                    <Checkbox key={option.value} label={option.label} value={option.value} />
                ))}
            </Group>
        </Checkbox.Group>
    );
};
