import { useField } from "formik";
import { useTranslate } from "@tolgee/react";
import { TextInput } from "@mantine/core";
import React from "react";

export type FormTextInputProps = {
    fieldName: string;
    label: string;
    description?: string;
};

export const FormTextInput = (props: FormTextInputProps) => {
    const [field, meta] = useField(props.fieldName);
    const { t } = useTranslate();

    return (
        <TextInput
            {...field}
            label={t(props.label)}
            description={props.description !== undefined ? t(props.description) : undefined}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
        />
    );
};
