import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import scrollToElement from "scroll-to-element";
import { useCurrentUser } from "../../common/user/context";
import { UserHasSpecificRole } from "../../common/user/utils";
import ContentBlock from "../../components/ContentBlock";
import { ContentLabel } from "../../components/ContentLabel";
import { EXPERT_ROLE } from "../../constants";
import MyCoursesClients from "./../components/MyCoursesClients";
import { CardBlock } from "../../components/CardBlock";

/*  eslint-disable react-hooks/exhaustive-deps */

export const SysadminHomePage = () => {
    useEffect(() => {
        scrollToElement("#sysadmin-home-page", {
            offset: -150,
        });
    }, []);
    const user = useCurrentUser();

    return (
        <>
            <CardBlock mb="xl">
                <ContentLabel text="Administration Home" />

                <Row>
                    <Col md={8}>
                        <h4>Courses</h4>
                        <p>
                            Create and administrate your courses on our courses page. Decide on chapters, content and
                            how to interact with your participants. Choose between multiple variations such as
                            reflection questions, matrix questions, statements, assessment and implementation
                            activities.{" "}
                        </p>
                        <p>
                            Decide on how much of the course is available or hidden and choose the right video from our
                            growing library.
                        </p>

                        <h4>Clients</h4>
                        <p>
                            Create and administrate your clients. Start with a digital twin of the client`&lsquo;`s
                            organization and involve the client during their transformation to add or decrease new teams
                            or unique flow teams.{" "}
                        </p>

                        <h4>Video Library</h4>

                        <p>
                            Upload your unique video content. Label and tag all videos in order to easily filter and
                            administrate our rapidly growing library.
                        </p>
                        <h4>Employees</h4>
                        <p>Search and filter for all available employees at your client`&lsquo;`s company. </p>

                        <h4>Feedback</h4>
                        <p>
                            Please provide continuous feedback regarding issues and/or improvements to our support team
                            via e-mail to info@hups.com or using the support button on each screen.
                        </p>
                    </Col>
                </Row>
            </CardBlock>
            {
                // @ts-ignore

                UserHasSpecificRole(user, EXPERT_ROLE) ? (
                    <ContentBlock>
                        <ContentLabel text="Courses Used By Clients" />
                        <MyCoursesClients />
                    </ContentBlock>
                ) : null
            }
        </>
    );
};
