import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { DisplayChaptersProgress, NavItemProps, NavTypeProps, TrainingSidebarProps } from "./SideNav";
import "./sidenav.scss";
import { LockedNav } from "./LockedNav";

const NavTypeDisplay: FunctionComponent<NavTypeProps> = ({ item, level }: NavTypeProps): JSX.Element => {
    const isActiveMenu = !!(item.isFirstChapter || item.active);
    let nav: JSX.Element | string = "";

    if (item.status === "Disabled") {
        nav = <LockedNav text={item.title} level={level} withBullet={level > 0} />;
    } else if (level === 0 && item.status === "Active") {
        nav = (
            <div className="firstLevel chapter-display-progress">
                <Link to={item.link}>{item.title}</Link>
                <div>
                    <DisplayChaptersProgress progress={item.chapterProgress as boolean[]} />
                </div>
            </div>
        );
    } else {
        const classNameProgress = isActiveMenu || item.completed ? "" : "enforce-sequence-nav";
        nav = (
            <Link className={classNameProgress} to={item.link}>
                {item.title}
            </Link>
        );
    }
    return nav;
};

const NavItemDisplay: FunctionComponent<NavItemProps> = ({ item, level }: NavItemProps) => {
    if (item) {
        const itemClasses = [
            `navitem-level-${level}`,
            item.completed == null ? "" : item.completed ? "state-completed" : "state-not-completed",
            item.active ? "active visited" : "",
            item.items && item.items.length > 0 ? "has-children" : "",
            item.items && item.items.find(x => x.active) ? "has-active-child" : "",
        ];
        return (
            <li className={itemClasses.join(" ")}>
                <div>
                    <span className="subtitle">{item.subtitle}</span>
                    <NavTypeDisplay item={item} level={level} />
                </div>
                {item.items && item.active && (
                    <ul>
                        {item.items.map((item, ix) => {
                            return <NavItemDisplay key={ix} item={item} level={level + 1} />;
                        })}
                    </ul>
                )}
            </li>
        );
    } else {
        return <></>;
    }
};

const TrainingSideNavNotEnforcedSequence: FunctionComponent<TrainingSidebarProps> = ({
    items,
}: TrainingSidebarProps): JSX.Element => {
    return (
        <div>
            <ul className="training-sidenav">
                {items &&
                    items.map((item, ix) => {
                        return <NavItemDisplay key={ix} item={item} level={0} />;
                    })}
            </ul>
        </div>
    );
};

export default React.memo(TrainingSideNavNotEnforcedSequence);
