import "core-js";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/custom-bootstrap.scss";
import * as serviceWorker from "./serviceWorker";
import "./App.scss";
import "./scss/App.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/Routes";
import "./common/extensions/index";

const rootElement = document.getElementById("root");

const root = ReactDOMClient.createRoot(rootElement!);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
