import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ReactElement } from "react";
import constants, { APP_DOMAIN } from "../constants";
import { AuthenticatedUser } from "../auth/AuthContextProvider";

export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: `InstrumentationKey=${constants.APP_INSIGHTS_KEY}`,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderDomains: [APP_DOMAIN, "localhost:5001"],
        loggingLevelConsole: 2, // log everything to console
        extensions: [reactPlugin],
    },
});
appInsights.loadAppInsights();
export const getAppInsights = () => appInsights;

export const onAuthenticatedUserChanged = (authenticatedUser: AuthenticatedUser | null) => {
    if (authenticatedUser != null) {
        const user = authenticatedUser.user;
        appInsights.setAuthenticatedUserContext(user.id.toString(), user.clientId?.toString(), true);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }
};

export const AppInsightsContextProvider = (props: { children: ReactElement }) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
};
