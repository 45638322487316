import { GroupMembership } from "@/api-client";
import React, { useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../common/api";
import { useAllGroups, useGroupTypes, useOrganisationContextDispatch } from "../../common/OrganisationContext";
import { applyEntityEvent } from "../../common/signalr/stateUtils";
import { ContentLabel } from "../ContentLabel";
import { DisplayInlineError } from "../DisplayInlineError";
import { showToast } from "../Notify";
import { AppLoader } from "../Spinner";
import { OptGroupTypes } from "./OptGroupsTypes";
import { PrimaryButton } from "../Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

type GroupMembershipEditorProps = {
    userId: number;
    currentGroupId?: number;
    show: boolean;
    hide: () => void;
};

type MoveUserGroupResultModel = {
    result: GroupMembership;
    isPending: boolean;
    error: string;
};

export const GroupMembershipEditor = (props: GroupMembershipEditorProps) => {
    const api = createApi();
    const organisationDispatch = useOrganisationContextDispatch();
    const groupTypes = useGroupTypes();
    const { t } = useTranslate();
    // @ts-ignore
    const [newGroupId, setNewGroupId] = useState<number>(null);
    // @ts-ignore
    const [error, setError] = useState<string>(null);
    const allGroups = useAllGroups();

    const [moveUserGroupResult, setMoveUserGroupResult] = useState<MoveUserGroupResultModel>({
        isPending: false,
        // @ts-ignore
        result: null,
        // @ts-ignore
        error: null,
    });

    const groupMembershipValidations = () => {
        if (isNaN(newGroupId) || newGroupId === null) {
            setError("Please select group");
        } else {
            setError("");
        }
    };

    const handleMoveGroups = () => {
        if (newGroupId) {
            confirmAlert({
                title: "Are you sure to move the user to other group?",
                message: t("COMMON_DELETE_MESSAGE"),
                buttons: [
                    {
                        label: t("COMMON_YES"),
                        onClick: () => {
                            moveUserToOtherGroup();
                        },
                    },
                    {
                        label: t("COMMON_NO"),
                        onClick: () => console.log("No pressed."),
                    },
                ],
                overlayClassName: "overlay-confirmalert-custom",
            });
        } else {
            groupMembershipValidations();
        }
    };

    useMemo(() => {
        if (newGroupId !== null) {
            groupMembershipValidations();
        }
    }, [newGroupId]);

    const moveUserToOtherGroup = () => {
        setMoveUserGroupResult(s => ({ ...s, isPending: true }));
        api.groupMembership
            .changeGroupId({
                userId: props.userId,
                // @ts-ignore
                currentGroupId: props.currentGroupId,
                newGroupId,
            })
            .then(
                response => {
                    response.groups.forEach(updatedGroup => {
                        organisationDispatch(s => ({
                            ...s,
                            groups: applyEntityEvent(
                                updatedGroup,
                                // @ts-ignore
                                s.groups
                            ),
                        }));
                    });

                    props.hide();

                    showToast("User moved", "success");
                },
                error => {
                    setMoveUserGroupResult(s => ({ ...s, isPending: false, error }));
                    setError(
                        "User data could not be moved automatically. Please contact support for help moving this user."
                    );
                }
            );
    };

    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.hide();
            }}
            size={"lg"}
            animation={false}
        >
            <AppLoader loading={moveUserGroupResult.isPending} />
            <Modal.Header closeButton aria-label="Hide">
                <Modal.Title>
                    <ContentLabel text={"MOVE USER AND DATA"} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    This action will move the user to the destination group along with answers, progress and other
                    related data, If the user have categorized answers, the categories will be duplicated to the new
                    group.
                </p>
                <Form.Group>
                    <Form.Label>Destination Group</Form.Label>
                    <OptGroupTypes
                        displayGroupsWithoutType={false}
                        // @ts-ignore
                        groups={allGroups}
                        name={"groups"}
                        value={0}
                        class={"form-control"}
                        onChangeHandler={(value: number) => setNewGroupId(value)}
                        // @ts-ignore
                        groupTypes={groupTypes}
                    />
                    {/* <Form.Select name="groups" onChange={ ( e: React.ChangeEvent<HTMLSelectElement> ) => {
                     e.persist();
                     setNewGroupId( parseInt( e.target.value ) );
                     }}>
                     <option value="">Please select group</option>
                     {groups.result && groups.result.map( g => <option key={g.id} value={g.id}>{g.name}</option>)}
                     </Form.Select> */}
                    {error && <DisplayInlineError error={error} />}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton type="button" onClick={() => handleMoveGroups()}>
                    Move
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
