import { useMemo } from "react";
import { Course, ModuleBlock, Module, Chapter } from "@/api-client";
import { type TrainingRouteParams } from ".";

export interface ModuleBlockSlide {
    current: ModuleBlock[];
    next: ModuleBlock | undefined;
}

export const getCurrentModuleBlocks = (
    currentCourse: Course,
    chapterId: number,
    moduleId: number
): ModuleBlock[] | undefined => {
    if (currentCourse) {
        // @ts-ignore
        const data = currentCourse.chapters
            .find(c => c.id === chapterId)
            ?.modules.find(m => m.id === moduleId)
            ?.blocks.filter(b => b.status === "Active" || b.status === "Disabled");
        return data;
    }
};

export const createSlidesForModuleBlocks = (
    currentCourse: Course,
    chapterId: number,
    moduleId: number,
    moduleBlockId: number
    // @ts-ignore
): ModuleBlockSlide => {
    if (currentCourse) {
        // @ts-ignore

        const blocks = getCurrentModuleBlocks(currentCourse, chapterId, moduleId).filter(b => b.status === "Active");
        const currentBlockIndex = blocks.findIndex(m => m.id === moduleBlockId);
        return {
            current: blocks.filter((m, i) => i <= currentBlockIndex),
            next: blocks[currentBlockIndex + 1] ? blocks[currentBlockIndex + 1] : undefined,
        };
    }
};

export const isLastModuleBlock = (
    currentCourse: Course,
    chapterId: number,
    moduleId: number,
    // @ts-ignore
    moduleBlockId
): boolean | undefined => {
    if (currentCourse) {
        // @ts-ignore

        const blocks = getCurrentModuleBlocks(currentCourse, chapterId, moduleId);
        const isLastModuleBlock = !!(
            blocks != null &&
            blocks.length > 0 &&
            blocks[blocks.length - 1]?.id === moduleBlockId
        );
        return isLastModuleBlock;
    }
};

const isLastOfModuleBlocks = (blocks: ModuleBlock[], blockId: number): boolean => {
    return blocks.length > 0 && blocks[blocks.length - 1]?.id === blockId;
};

const isLastOfModules = (modules: Module[], moduleId: number): boolean => {
    return modules.length > 0 && modules[modules.length - 1].id === moduleId;
};

export const getNextChapterAndModuleAndModuleBlock = (
    currentCourse: Course | undefined,
    chapterId: number,
    moduleId: number,
    moduleBlockId: number
): { nextChapter?: Chapter; nextModule?: Module; nextModuleBlock?: ModuleBlock } => {
    if (currentCourse == null) {
        return {};
    }

    const chapters = currentCourse.chapters
        .map(chapter => ({
            ...chapter,
            modules: chapter.modules
                .map(module => ({
                    ...module,
                    blocks: module.blocks.filter(block => block.status === "Active"),
                }))
                .filter(module => module.status === "Active" && module.blocks.length > 0),
        }))
        .filter(chapter => chapter.status === "Active" && chapter.modules.length > 0);

    const currentChapter = chapters.find(c => c.id === chapterId);
    if (currentChapter == null) {
        return {};
    }

    const modules = currentChapter.modules;
    const currentModule = modules.find(m => m.id === moduleId);

    if (currentModule == null) {
        return {};
    }

    if (isLastOfModuleBlocks(currentModule.blocks, moduleBlockId)) {
        if (isLastOfModules(modules, moduleId)) {
            const currentChapterIndex = chapters.indexOf(currentChapter);
            const nextChapter = chapters[currentChapterIndex + 1];
            return {
                nextChapter: nextChapter,
                nextModule: nextChapter?.modules[0],
                nextModuleBlock: nextChapter?.modules[0]?.blocks[0],
            };
        }

        const currentModuleIndex = modules.findIndex(module => module.id === moduleId);
        const nextModule = modules[currentModuleIndex + 1];
        return {
            nextChapter: currentChapter,
            nextModule: nextModule,
            nextModuleBlock: nextModule?.blocks[0],
        };
    }

    const currentModuleBlockIndex = currentModule.blocks.findIndex(block => block.id === moduleBlockId);
    return {
        nextChapter: currentChapter,
        nextModule: currentModule,
        nextModuleBlock: currentModule.blocks[currentModuleBlockIndex + 1],
    };
};

export const checkChapterModuleAndBlockStatus = (
    currentCourse: Course,
    chapterId: number,
    moduleId: number,
    // @ts-ignore
    moduleBlockId
): boolean => {
    if (currentCourse && currentCourse.chapters) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let query: any = "";

        if (chapterId) {
            query = currentCourse?.chapters.find(c => c.id === chapterId && c.status === "Active");
            if (query) {
                return true;
            }
            return false;
        }
        if (chapterId && moduleId) {
            // @ts-ignore

            query = query.modules?.find(m => m.id === moduleId && m.status === "Active");
            if (query) {
                return true;
            }
            return false;
        }

        if (chapterId && moduleId && moduleBlockId) {
            // @ts-ignore

            query = query.blocks.find(m => m.id === moduleBlockId && m.status !== "Deleted");
            if (query) {
                return true;
            }
            return false;
        }
    }
    return true;
};

/**
 * Validate if the requested route is valid for the current course
 */
export const useIsValidCourseRoute = (course: Course, params: TrainingRouteParams) => {
    // Check if route params is valid within course
    return useMemo(() => {
        if (!course || !params) {
            return;
        }

        const courseId = parseInt(params.courseId);
        // @ts-ignore

        const moduleId = parseInt(params.moduleId);
        const chapterId = parseInt(params.chapterId);
        // @ts-ignore

        const moduleBlockId = parseInt(params.moduleBlockId);

        if (course.id !== courseId) {
            return false;
        }
        // @ts-ignore

        if (!course.chapters.map(c => c.id).includes(chapterId)) {
            return false;
        }
        // @ts-ignore

        if (!course.chapters.flatMap(c => c.modules.map(m => m.id)).includes(moduleId)) {
            return false;
        }
        // @ts-ignore

        if (!course.chapters.flatMap(c => c.modules.flatMap(m => m.blocks.map(mb => mb.id))).includes(moduleBlockId)) {
            return false;
        }

        // All validations passed
        return true;
    }, [course, params]);
};
