import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { createApi, Icons } from "@/common";
import { AddMembershipModal } from "./AddMembership";
import { Team } from "@/api-client";
import {
    PrimaryButton,
    BasicTable,
    BasicTableColumn,
    RowActions,
    useDeleteConfirmationModal,
    useModal,
} from "@/components";
import { EditTeamMembershipModal } from "../../EditTeamMembership";
import { MembershipTypeModel } from "../../MembershipTypeInput";

type EditTeamMembershipProps = {
    userId: number;
};

type EditTeamMembershipState = {
    teams: Team[] | null;
};

type TeamMembershipRow = {
    name: string;
    isParticipant?: boolean;
    groupId?: number;
    userId?: number;
    isGroupLeader?: boolean;
};

export const TeamMembership = (props: EditTeamMembershipProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const deleteConfirmationModal = useDeleteConfirmationModal();
    const editMembershipModal = useModal(EditTeamMembershipModal);
    const addMembershipModal = useModal(AddMembershipModal);

    const [state, setState] = useState<EditTeamMembershipState>({
        teams: null,
    });

    const loadTeams = () => {
        api.groups
            .query(null, null, null, null)
            .then(result => setState({ ...state, teams: result }))
            .catchWithToast();
    };

    useEffect(() => {
        loadTeams();
    }, []);

    const teamMemberships = state.teams
        ?.map(t => ({
            name: t?.name,
            groupId: t?.id,
            ...t?.members?.find(mem => mem.userId === props.userId),
        }))
        .filter(m => m.user !== undefined);

    const addUserToTeam = (teamId: number, membershipType: MembershipTypeModel) =>
        api.groupMembership
            .post(props.userId, teamId, {
                userId: props.userId,
                groupId: teamId,
                inviteStatus: "NotSent",
                isParticipant: membershipType.isParticipant,
                isGroupLeader: membershipType.isGroupLeader,
            })
            .then(() => loadTeams());

    const openAddTeamMembershipModal = () => {
        addMembershipModal.open({
            teams: state.teams!,
            updateTeam: (teamId: number, membershipType: MembershipTypeModel) => {
                return addUserToTeam(teamId, membershipType);
            },
            userId: props.userId,
        });
    };

    const openEditTeamMembershipModal = (row: TeamMembershipRow) => {
        editMembershipModal.open({
            userId: row.userId!,
            teamId: row.groupId!,
            isGroupLeader: row.isGroupLeader!,
            isParticipant: row.isParticipant!,
            onClose: () => loadTeams(),
        });
    };

    const openDeleteConfirmationModal = (row: TeamMembershipRow) => {
        const removeMembership = () => {
            return api.groupMembership
                .delete(row.userId!, row.groupId!)
                .then(() => loadTeams())
                .catchWithToast();
        };

        deleteConfirmationModal.open({
            onConfirm: () => removeMembership(),
            title: t("EDITEMPLOYEE_REMOVE_TEAM_MEMBERSHIP", {
                team: state.teams?.find(t => t.id === row.groupId)?.name,
            }),
        });
    };

    const resendInvite = (row: TeamMembershipRow) => {
        api.accountOwners.sendInvite(row.userId!, row.groupId!).catchWithToast();
    };

    const basicColumns: BasicTableColumn<ArrayElement<TeamMembershipRow[]>>[] = [
        {
            accessor: row => row.name,
            header: "COMMON_NAME",
        },
        {
            accessor: row => row.isParticipant,
            render: (value: TeamMembershipRow) => (value.isParticipant ? <Icons.Check /> : null),
            header: "EDITEMPLOYEE_PARTICIPANT",
        },
        {
            accessor: row => row.isGroupLeader,
            render: (value: TeamMembershipRow) => (value.isGroupLeader ? <Icons.Check /> : null),
            header: "EDITEMPLOYEE_TEAMLEADER",
        },
    ];

    return (
        <BasicTable
            columns={basicColumns}
            data={teamMemberships}
            emptyPlaceHolder="EDITEMPLOYEE_NO_MEMBERSHIP"
            toolbarActions={() => (
                <PrimaryButton
                    onClick={openAddTeamMembershipModal}
                    eventName={{ object: "ao_addteammembership", action: "open" }}
                >
                    {t("EDITEMPLOYEE_ADD_TEAM_MEMBERSHIP")}
                </PrimaryButton>
            )}
            renderRowActions={({ row }) => (
                <>
                    <RowActions.EditButton onClick={() => openEditTeamMembershipModal(row.original)} />
                    <RowActions.DeleteButton onClick={() => openDeleteConfirmationModal(row.original)} />
                    <RowActions.ContextMenu.Menu>
                        <RowActions.ContextMenu.Button
                            onClick={() => resendInvite(row.original)}
                            label="EDITEMPLOYEE_RESEND_INVITATION"
                        />
                    </RowActions.ContextMenu.Menu>
                </>
            )}
        />
    );
};
