﻿import { ApiConfiguration } from "@/api-client";
import { Get, PostJson } from "./utility";
import { GetLatestProgramsResponse } from "./GetLatestProgramsResponse";
import { SetTeamMessageRequest } from "./SetTeamMessageRequest";

export class HomeClient {
    constructor(private config: ApiConfiguration) {}

    public setTeamMessage = (clientId: number, teamId: number, message: string | null): Promise<Response> =>
        PostJson(
            `${this.config.baseUrl}/api/home/setTeamMessage`,
            new SetTeamMessageRequest(clientId, teamId, message),
            this.config.getAuthorizationHeader()
        );

    public getLatestPrograms = async (teamId: number, clientId: number, abortController: AbortController) => {
        const queryString = new URLSearchParams({
            teamId: teamId.toString(),
            clientId: clientId.toString(),
        }).toString();
        
        const response = await Get(
            `${this.config.baseUrl}/api/home/latestprograms?${queryString}`,
            this.config.getAuthorizationHeader(),
            abortController
        );

        if (response.ok) {
            return (await response.json()) as GetLatestProgramsResponse;
        }

        if (response.status === 404 || response.status === 401) {
            const error = (await response.json()) as { title: string };
            throw new Error(error.title);
        }

        throw new Error(response.statusText);
    };
}
