import { ProgramChild } from "./Program/ProgramChild";
import { CategorisationOverview } from "../workshop/categorisation/CategorisationOverview";
import { useParams } from "react-router-dom";

export const CategorisationOverviewPage = () => {
    const params = useParams();

    return (
        <ProgramChild
            //@ts-ignore
            teamId={params.groupId}
            useFullWidth={true}
            breadCrumbTitleKey={"PROGRAM_PREPAREWORKSHOP"}
        >
            <CategorisationOverview />
        </ProgramChild>
    );
};
