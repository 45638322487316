import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { Question, QuestionStatuses } from "@/api-client";
import { createApi } from "@/common";
import { createPatch } from "../../../../common/patchHelper";
import { ContentLabel } from "../../../../components/ContentLabel";
import { Notify } from "../../../../components/Notify";
import { AppLoader, PrimaryButton } from "@/components";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
    moduleBlockId: number;
};
const validate = () =>
    Yup.object().shape({
        text: Yup.string().required("Question's text is required"),
    });

interface FormModel {
    id: number;
    text: string;
    questionTypeId: number;
    status: QuestionStatuses;
}

interface AddEditQuestionresponse {
    question: Question;
    isPending: boolean;
    isSuccess: boolean;
}

function QuestionSharedProperties(props: EditQuestionProps): JSX.Element {
    const api = createApi();
    const history = useHistory();
    const forAdd: boolean = props.question === null;
    const [addEditQuestion, setAddEditQuestion] = useState<AddEditQuestionresponse>({
        // @ts-ignore

        isPending: null,
        // @ts-ignore

        question: null,
        isSuccess: false,
    });
    const [submitted, setSubmitted] = useState(false);

    const initialValues: FormModel = {
        // @ts-ignore

        id: forAdd ? 0 : props.question?.id,
        // @ts-ignore

        text: forAdd ? "" : props.question?.text,
        questionTypeId: forAdd ? 1 : props.question?.questionTypeId,
        status: forAdd ? "Active" : props.question?.status,
    };
    const isLoading: boolean = !!addEditQuestion.isPending;

    // @ts-ignore

    const updateQuestion = (questionId: number, patch) => {
        api.questions.patch(questionId, patch).then(
            result => {
                setAddEditQuestion({
                    ...addEditQuestion,
                    question: result,
                    isPending: false,
                    isSuccess: true,
                });
                // dispatch(push(`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/edit`));
            },
            error => {
                setAddEditQuestion({ ...addEditQuestion, isPending: false });
                console.log(error);
            }
        );
    };

    return (
        <>
            <AppLoader loading={!!isLoading} className="position-absolute" />

            {addEditQuestion.isSuccess && submitted && (
                <>
                    <Notify
                        message={forAdd ? "Question saved successfully." : "Question updated successfully."}
                        id="registersuccess"
                        type="success"
                    />
                    {/* <Redirect to="/sysadmin/clients" /> */}
                </>
            )}
            <ContentLabel text={forAdd ? "Add Question" : "Edit Question"} />
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={values => {
                    if (forAdd) {
                        setSubmitted(true);
                        setAddEditQuestion({ ...addEditQuestion, isPending: true });
                        api.questions
                            .post({
                                id: 0,
                                moduleBlockId: props.moduleBlockId,
                                // moduleId: props.moduleId,
                                text: values.text,
                                questionTypeId: values.questionTypeId,
                                status: values.status,
                                allowSkip: false,
                                answerRequired: false,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                answers: [],
                                options: [],
                                answerCategories: [],
                            })
                            .then(
                                x => {
                                    setAddEditQuestion({
                                        ...addEditQuestion,
                                        question: x,
                                        isPending: false,
                                        isSuccess: true,
                                    });
                                    history.push(
                                        `/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.moduleId}/block/${props.moduleBlockId}/question/${x?.id}/edit`
                                    );
                                },
                                error => {
                                    setAddEditQuestion({ ...addEditQuestion, isPending: false });
                                    console.log(error);
                                }
                            );
                    } else {
                        const patch = createPatch(props.question, x => {
                            x.id = values.id;
                            x.questionTypeId = values.questionTypeId;
                            x.text = values.text;
                            x.status = values.status;
                        });

                        if (patch && patch.length > 0) {
                            // Update profile
                            if (values.status === "Deleted") {
                                confirmAlert({
                                    title: "Are you sure to want to delete the Question?",
                                    message: "Action can't be undone!",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                setSubmitted(true);
                                                setAddEditQuestion({ ...addEditQuestion, isPending: true });
                                                // @ts-ignore

                                                updateQuestion(props.question?.id, patch);
                                            },
                                        },
                                        {
                                            label: "No",
                                            onClick: () => console.log("No pressed."),
                                        },
                                    ],
                                });
                            } else {
                                // @ts-ignore

                                updateQuestion(props.question?.id, patch);
                                setSubmitted(true);
                                setAddEditQuestion({ ...addEditQuestion, isPending: true });
                            }
                        } else {
                            setAddEditQuestion({ ...addEditQuestion, isPending: false });
                        }
                    }
                }}
            >
                {formikProps => {
                    const { errors, touched } = formikProps;
                    return (
                        <Form name="addEditQuestion" className="mt-2 addCHapterForm">
                            <Row className="mb-4">
                                <Col>
                                    <label>Text</label>
                                    <Field
                                        name="text"
                                        component="textarea"
                                        rows={1}
                                        cols={1}
                                        className={`form-control${errors.text && touched.text ? " is-invalid" : ""}`}
                                    />
                                    {errors.text && (
                                        <span className="clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.text}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <label>Question Type</label>
                                    <Field name="questionTypeId" as="select" className="form-select" disabled={!forAdd}>
                                        <option value="1" key="1">
                                            Free Text Answers
                                        </option>
                                        <option value="2" key="2">
                                            Single Choice
                                        </option>
                                        <option value="3" key="3">
                                            Multiple Choice
                                        </option>
                                        <option value="4" key="4">
                                            Rating
                                        </option>
                                        <option value="5" key="5">
                                            Matrix - Scale
                                        </option>
                                        <option value="6" key="6">
                                            Matrix - Grid
                                        </option>
                                    </Field>
                                </Col>
                                <Col>
                                    <label>Status</label>
                                    {/** Please move the option in const I think we can reuse this eiter we have to
                                     * write it eveywhere
                                     */}
                                    <Field name="status" as="select" className="form-select">
                                        <option key="1" value="Active">
                                            Active
                                        </option>
                                        <option key="2" value="Inactive">
                                            Inactive
                                        </option>
                                        <option key="3" value="Inprogress">
                                            Inprogress
                                        </option>
                                        <option key="4" value="Deleted">
                                            Deleted
                                        </option>
                                        <option key="5" value="Invited">
                                            Invited
                                        </option>
                                        <option key="6" value="Disabled">
                                            Disabled
                                        </option>
                                    </Field>
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton type="submit" disabled={isLoading}>
                                    {forAdd ? "Save" : "Update"}
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default QuestionSharedProperties;
