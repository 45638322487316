﻿import type { ModalSettings } from "@mantine/modals/lib/context";
import { TFunction } from "@/common";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslate } from "@tolgee/react";
import { Group, Stack } from "@mantine/core";
import { FormInput, PrimaryButton } from "@/components";
import { showToast } from "../../../components/Notify";
import { createApi } from "../../../common/api";

type AddBlockProps = {
    modalId: string;
    moduleId: string;
    onClose: (modalId: string) => void;
};

const validator = yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
});

export type FormSchema = yup.InferType<typeof validator>;

const initialValues: FormSchema = {
    title: "",
    description: "",
};

const AddBlock = (props: AddBlockProps) => {
    const { t } = useTranslate();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={async values => {
                showToast(`${props.moduleId}:${JSON.stringify(values)}`, "success");

                const api = createApi();
                await api.sysadmin.addBlock(props.moduleId, values.title, values.description);

                props.onClose(props.modalId);
            }}
        >
            {formikProps => {
                const { isSubmitting } = formikProps;
                const isTouched = Object.keys(formikProps.touched).length > 0;
                const canSubmit = !isTouched || formikProps.isValid;
                return (
                    <Form>
                        <Stack>
                            <FormInput.Text fieldName="title" label="SYSADMIN_MODULE_TITLE" />
                            <FormInput.Text fieldName="description" label="SYSADMIN_MODULE_DESCRIPTION" />
                        </Stack>

                        <Group position="right" mt="md">
                            <PrimaryButton loading={isSubmitting} disabled={!canSubmit} type="submit">
                                {t("SYSADMIN_MODULE_ADD_BLOCK")}
                            </PrimaryButton>
                        </Group>
                    </Form>
                );
            }}
        </Formik>
    );
};

export type AddBlockModalProps = {
    modalId: string;
    moduleId: string;
    onClose: AddBlockProps["onClose"];
};

export const addBlockModal = (t: TFunction, props: AddBlockModalProps): ModalSettings => {
    return {
        modalId: props.modalId,
        title: t("SYSADMIN_MODULE_ADD_BLOCK"),
        children: <AddBlock modalId={props.modalId} moduleId={props.moduleId} onClose={props.onClose} />,
        size: "xl",
        closeOnClickOutside: false,
        closeOnEscape: true,
    };
};
