import { Flex, Text, Box } from "@mantine/core";
import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useHistory, createApi, UrlFactory, createPatch } from "@/common";
import { TeamStatus } from "@/api-client";
import { TeamStatusBadge } from "../TeamStatusBadge";
import { SecondaryButton, showToast, useDeleteConfirmationModal } from "@/components";

type EditTeamStatusProps = {
    team: { id: number; status: TeamStatus };
};

export const EditTeamStatus = (props: EditTeamStatusProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const [teamStatus, setTeamStatus] = useState<TeamStatus>(props.team.status);
    const history = useHistory();
    const deleteConfirmationModal = useDeleteConfirmationModal();

    const deleteTeam = () => {
        return createApi()
            .groups.delete(props.team.id)
            .then(() => {
                showToast(t("ACCOUNTOWNER_EDIT_TEAMS_TEAM_DELETED"), "success");
                history.push(UrlFactory.accountOwner.teams.create({}));
            })
            .catchWithToast();
    };

    const openDeleteConfirmationModal = () => {
        deleteConfirmationModal.open({
            title: "ACCOUNTOWNER_DELETE_TEAM_CONFIRMATION",
            onConfirm: deleteTeam,
        });
    };

    const getStatusAction = () => {
        if (teamStatus === "Active") {
            return {
                buttonLabel: t("ACCOUNTOWNER_EDIT_TEAMS_DEACTIVATE_TEAM"),
                command: () => {
                    api.groups
                        .patch(
                            props.team.id,
                            createPatch(props.team, x => {
                                x.status = "Inactive";
                            })
                        )
                        .then(() => {
                            setTeamStatus("Inactive");
                            showToast(
                                t("ACCOUNTOWNER_TEAM_HAS_BEEN_DEACTIVATED", "Team has been deactivated."),
                                "success"
                            );
                        })
                        .catchWithToast();
                },
            };
        }
        if (teamStatus === "Inactive") {
            return {
                buttonLabel: t("ACCOUNTOWNER_EDIT_TEAMS_ACTIVATE_TEAM"),
                command: () => {
                    api.groups
                        .patch(
                            props.team.id,
                            createPatch(props.team, x => {
                                x.status = "Active";
                            })
                        )
                        .then(() => {
                            setTeamStatus("Active");
                            showToast(t("ACCOUNTOWNER_TEAM_HAS_BEEN_ACTIVATED", "Team has been activated."), "success");
                        })
                        .catchWithToast();
                },
            };
        }

        return {
            buttonLabel: "",
            command: () => {},
        };
    };

    const { buttonLabel, command } = getStatusAction();

    return (
        <Flex columnGap="lg" direction="column" rowGap="lg">
            <Flex align="center">
                <Text fw="bold" w={200}>
                    {t("RESOURCES_STATUS")}
                </Text>
                <TeamStatusBadge status={teamStatus} />
            </Flex>
            <Box>
                <SecondaryButton
                    size="sm"
                    mr="sm"
                    onClick={command}
                    eventName={{ object: "teamstatus", action: "setstatus" }}
                >
                    {buttonLabel}
                </SecondaryButton>
                <SecondaryButton
                    danger
                    size="sm"
                    onClick={openDeleteConfirmationModal}
                    eventName={{ object: "teamstatus", action: "delete" }}
                >
                    {t("COMMON_DELETE")}
                </SecondaryButton>
            </Box>
        </Flex>
    );
};
