import { DemographicQuestion } from "@/api-client";
import React from "react";
import { QuestionsWithAnswers } from "../../DemographicQuestionsPage";
import { CheckboxTypeQuestion } from "./CheckboxTypeQuestion";
import { RadioTypeQuestion } from "./RadioTypeQuestion";
import { SelectOptionTypeQuestion } from "./SelectOptionTypeQuestion";
import { TextInputTypeQuestion } from "./TextInputTypeQuestion";

export interface DemoGraphicQuestionsTemplateProps {
    questionAndAnswer: QuestionsWithAnswers;
    setAnswers: (answerValue: string | undefined, answerId: number | undefined) => void;
}

interface DemographicQuestionsFormProps {
    userId: number;
    questionsWithAnswers: QuestionsWithAnswers[];
    setQuestionsWithAnswers: (questionsWithAnswers: QuestionsWithAnswers[]) => void;
}

export const DemographicQuestionsForm = (props: DemographicQuestionsFormProps) => {
    const handleAnswers = (
        question: DemographicQuestion,
        answerValue: string | undefined,
        answerId: number | undefined
    ): void => {
        const answer = {
            id: answerId,
            demographicQuestionId: question.id,
            value: answerValue,
            userId: props.userId,
        };
        if (question.required && (answerValue === undefined || answerValue === "")) {
            /* @ts-ignore */
            props.setQuestionsWithAnswers([
                ...props.questionsWithAnswers.map(q =>
                    q.question.id === question.id
                        ? { ...q, answer, isValid: false, error: "Please select " + question.label }
                        : q
                ),
            ]);
        } else {
            /* @ts-ignore */
            props.setQuestionsWithAnswers([
                ...props.questionsWithAnswers.map(q =>
                    q.question.id === question.id ? { ...q, answer, isValid: true, error: "" } : q
                ),
            ]);
        }
    };

    return (
        <>
            {props.questionsWithAnswers &&
                props.questionsWithAnswers.map(questionAndAnswer => {
                    const templateProps: DemoGraphicQuestionsTemplateProps = {
                        questionAndAnswer,
                        setAnswers: (answerValue, answerId) =>
                            handleAnswers(questionAndAnswer.question, answerValue, answerId),
                    };

                    switch (questionAndAnswer.question.type) {
                        case "Select":
                            return (
                                <SelectOptionTypeQuestion
                                    {...templateProps}
                                    key={`question-${questionAndAnswer.question.id}`}
                                />
                            );
                        case "Checkbox":
                            return (
                                <CheckboxTypeQuestion
                                    {...templateProps}
                                    key={`question-${questionAndAnswer.question.id}`}
                                />
                            );
                        case "Radio":
                            return (
                                <RadioTypeQuestion
                                    {...templateProps}
                                    key={`question-${questionAndAnswer.question.id}`}
                                />
                            );
                        case "Text":
                            return (
                                <TextInputTypeQuestion
                                    {...templateProps}
                                    key={`question-${questionAndAnswer.question.id}`}
                                />
                            );
                        default:
                            return <></>;
                    }
                })}
        </>
    );
};
