import { useState } from "react";
import { Formik } from "formik";
import { Col, Form, ProgressBar, Row, Table } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useHistory } from "@/common";
import { Video } from "@/api-client";
import { useUserContext } from "../../../common/user/context";
import { UserHasOneOfRoles } from "../../../common/user/utils";
import { ContentLabel } from "../../../components/ContentLabel";
import { showToast } from "../../../components/Notify";
import Constants, { EXPERT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import { AutocompleteTags } from "../../components/AutoCompleteTags";
import { RoleBasedUser } from "../../components/RoleBasedUser";
import { useSysadminContextDispatch } from "../../SysadminContext";
import "./styles.scss";
import { UploadProgress } from "./VideoUploader";
import { ProgressMessage, StartUploadMessage, VideoUploadedMessage, WorkerMessage } from "./VideoUploadWorker";
import { useAuth } from "../../../auth/useAuth";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { CardBlock } from "../../../components/CardBlock";
import { uuid } from "@/common";

// Info about a video to be uploaded
interface VideoInfo {
    id: string;
    video: Video;
    file: File;
    progress?: UploadProgress;
    isLoading: boolean;
    hasError: boolean;
    workerRef?: Worker;
}

// Shared props
interface VideoCommonProps {
    ownerId?: number;
}

export const AddVideos = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userContext = useUserContext();
    const sysadminVideosDispatch = useSysadminContextDispatch();
    // @ts-ignore
    const isSysadmin: boolean = UserHasOneOfRoles(userContext.user, [SYSADMIN_ROLE]);
    const history = useHistory();
    const [selectedVideos, setSelectedVideos] = useState<VideoInfo[]>();
    const { token } = useAuth();

    const removeVideo = (video: VideoInfo) => {
        // @ts-ignore

        setSelectedVideos(sv => [...sv.filter(x => x !== video)]);
    };

    // Create state entities for ui before starting upload
    const handleDrop = (acceptedFiles: File[]) => {
        var newVideos: VideoInfo[] = acceptedFiles.map(vf => ({
            id: uuid(),
            video: {
                name: vf.name,
                externalId: 0,
                status: "Active",
                url: "", // TODO: Remove not null in API
                tags: [],
            },
            file: vf,
            isLoading: false,
            hasError: false,
        }));

        // Add dropped file to array of videos
        setSelectedVideos(s => [...(s ?? []), ...newVideos]);
    };

    // Shorthand for updating single state item
    const updateVideoInfo = (info: VideoInfo, update: (videoInfo: VideoInfo) => VideoInfo) => {
        // @ts-ignore

        setSelectedVideos(s => s.map(sv => (sv.id !== info.id ? sv : update(sv))));
    };

    const retryUpload = (videoInfo: VideoInfo) => {
        // Reset error state
        updateVideoInfo(videoInfo, sv => ({
            ...sv,
            hasError: false,
        }));

        var msg: WorkerMessage = {
            type: "ResumeUpload",
        };
        videoInfo.workerRef?.postMessage(msg);
    };

    const startUpload = async (values: VideoCommonProps) => {
        setIsLoading(true);
        // @ts-ignore

        var callbacks = selectedVideos.map(x => {
            return new Promise<Video>((accept, reject) => {
                // Create worker instance
                const worker = new Worker(new URL("./VideoUploadWorker", import.meta.url));

                // Set status to loading for upading ui while processing files
                updateVideoInfo(x, sv => ({
                    ...sv,
                    isLoading: true,
                    workerRef: worker,
                }));

                // Setup message handler before starting uploiad
                worker.onmessage = msg => {
                    const workerMessage: WorkerMessage = msg.data as WorkerMessage;

                    switch (workerMessage.type) {
                        case "Progress":
                            const progressMsg: ProgressMessage = msg.data as ProgressMessage;

                            updateVideoInfo(x, sv => ({
                                ...sv,
                                progress: progressMsg.progress,
                            }));

                            break;
                        case "Completed":
                            const uploadedMessage: VideoUploadedMessage = msg.data as VideoUploadedMessage;

                            // Set to 100% since other videos might still be uploading
                            updateVideoInfo(x, sv => ({
                                ...sv,
                                // @ts-ignore

                                progress: { ...sv.progress, now: sv.progress.max },
                            }));

                            accept(uploadedMessage.video);

                            break;
                        case "Error":
                            showToast("Video upload failed, please retry to continue uploading the file.", "warning");

                            updateVideoInfo(x, sv => ({
                                ...sv,
                                hasError: true,
                            }));
                            break;
                    }
                };

                // Start the upload
                var startUploadMessage: StartUploadMessage = {
                    type: "StartUpload",
                    apiConfig: {
                        baseUrl: Constants.NEW_API_URL,
                        token: `Bearer ${token}`,
                    },
                    videoFile: x.file,
                    video: { ...x.video, ownerId: values.ownerId },
                };

                worker.postMessage(startUploadMessage);
            });
        });

        Promise.all(callbacks).then(videos => {
            // All videos uploaded
            showToast("All vides uploaded successfully!", "success");
            sysadminVideosDispatch(s => ({
                ...s,
                // @ts-ignore

                videos: [...s.videos, ...videos],
            }));

            history.push("/sysadmin/video-library");
        });
    };

    const initValues: VideoCommonProps = {};

    return (
        <div className="add-videos">
            <div className="row">
                <div className="col-md-12">
                    <CardBlock mb="xl">
                        <Formik initialValues={initValues} onSubmit={v => startUpload(v)}>
                            {({ handleSubmit }) => {
                                return (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <ContentLabel text="Add Videos" />

                                            {!isLoading && (
                                                <Row className="mb-4">
                                                    <Col md={12}>
                                                        <label>Videos to upload</label>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Dropzone onDrop={handleDrop} accept="video/*" maxFiles={25}>
                                                            {({
                                                                getRootProps,
                                                                getInputProps,
                                                                isDragActive,
                                                                isDragAccept,
                                                                isDragReject,
                                                            }) => {
                                                                const additionalClass = isDragAccept
                                                                    ? "accept"
                                                                    : isDragReject
                                                                    ? "reject"
                                                                    : "";

                                                                return (
                                                                    <div
                                                                        {...getRootProps({
                                                                            className: `dropzone ${additionalClass}`,
                                                                        })}
                                                                        style={{ height: "300px", width: "100%" }}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <span>
                                                                            <i className="fas fa-upload video-upload" />
                                                                        </span>
                                                                        <p>
                                                                            Drag and drop video, or click to select
                                                                            video files
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Dropzone>
                                                    </Col>
                                                </Row>
                                            )}

                                            {selectedVideos && (
                                                <Table striped={true}>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            {!isLoading && (
                                                                <>
                                                                    <th>Tags</th>
                                                                </>
                                                            )}
                                                            {isLoading && (
                                                                <>
                                                                    <th colSpan={2}>Progress</th>
                                                                </>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedVideos.map((sv, ix) => (
                                                            <tr key={ix}>
                                                                {!sv.isLoading && (
                                                                    <>
                                                                        <td className="mb-4">
                                                                            <Form.Control
                                                                                value={sv.video.name} //BROKEN!!!!
                                                                                onChange={e => {
                                                                                    const name = e.target?.value;
                                                                                    updateVideoInfo(sv, x => ({
                                                                                        ...x,
                                                                                        video: {
                                                                                            ...x.video,
                                                                                            name: name,
                                                                                        },
                                                                                    }));
                                                                                }}
                                                                                name="name"
                                                                            />
                                                                        </td>

                                                                        <td className="mb-4">
                                                                            <AutocompleteTags
                                                                                module={`videos`}
                                                                                // @ts-ignore

                                                                                defaultValue={null}
                                                                                selectedValue={(value: string[]) =>
                                                                                    updateVideoInfo(sv, x => ({
                                                                                        ...x,
                                                                                        video: {
                                                                                            ...x.video,
                                                                                            tags: value,
                                                                                        },
                                                                                    }))
                                                                                }
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-primary d-block ml-auto"
                                                                                onClick={e => removeVideo(sv)}
                                                                            >
                                                                                <i className="fas fa-times"></i>
                                                                            </button>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {sv.isLoading && (
                                                                    <>
                                                                        <td>{sv.video.name}</td>
                                                                        <td colSpan={2}>
                                                                            {sv.progress && (
                                                                                <ProgressBar
                                                                                    {...sv.progress}
                                                                                    animated={
                                                                                        // @ts-ignore
                                                                                        sv.progress.now <
                                                                                        // @ts-ignore
                                                                                        sv.progress.max
                                                                                    }
                                                                                    label={`${Math.round(
                                                                                        // @ts-ignore
                                                                                        (sv.progress?.now /
                                                                                            // @ts-ignore
                                                                                            sv.progress.max) *
                                                                                            100
                                                                                    )} %`}
                                                                                />
                                                                            )}
                                                                            {!sv.progress && (
                                                                                <ProgressBar
                                                                                    min={0}
                                                                                    max={100}
                                                                                    now={0}
                                                                                    animated
                                                                                    label="Starting..."
                                                                                />
                                                                            )}

                                                                            {sv.hasError && (
                                                                                <>
                                                                                    <span className="text-danger">
                                                                                        Upload was unsuccessful, please
                                                                                        try again.
                                                                                    </span>
                                                                                    <PrimaryButton
                                                                                        onClick={() => retryUpload(sv)}
                                                                                    >
                                                                                        Retry upload
                                                                                    </PrimaryButton>
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}

                                            {isSysadmin && (
                                                <Row className="mb-4">
                                                    <Col>
                                                        <label>Expert</label>
                                                        <div className="col-sm-6">
                                                            {
                                                                <RoleBasedUser
                                                                    roleName={EXPERT_ROLE}
                                                                    fieldName="ownerId"
                                                                    disabled={isLoading}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}

                                            <ContentBlockButtonContainer>
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={
                                                        isLoading || !selectedVideos || selectedVideos?.length === 0
                                                    }
                                                >
                                                    {isLoading && "Uploading..."}
                                                    {!isLoading && "Start upload"}
                                                </PrimaryButton>
                                            </ContentBlockButtonContainer>
                                        </form>
                                    </>
                                );
                            }}
                        </Formik>
                    </CardBlock>
                </div>
            </div>
        </div>
    );
};
