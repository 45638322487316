import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { PreviewNavItem } from "./helper";
import "./style.scss";

export interface NavItemProps {
    item: PreviewNavItem;
    level: number;
}

export interface NavTypeProps {
    item: PreviewNavItem;
    level: number;
}

export interface DisplayChaptersProgressProps {
    progress: boolean[];
}

export interface TrainingSidebarProps {
    items: Array<PreviewNavItem> | undefined;
}

export const DisplayChaptersProgress: FunctionComponent<DisplayChaptersProgressProps> = ({
    progress,
}: DisplayChaptersProgressProps): JSX.Element => {
    return (
        <>
            {progress &&
                progress.map((p, i) => {
                    return <i key={i} className={`fas fa-circle chapter-progress progress-${p}`} />;
                })}
        </>
    );
};

const NavTypeDisplay: FunctionComponent<NavTypeProps> = ({ item, level }: NavTypeProps): JSX.Element => {
    let nav: JSX.Element | string = "";
    if (level === 0) {
        if (item.status === "Active") {
            nav = (
                <div className="firstLevel chapter-display-progress">
                    <Link to={item.link}>{item.title}</Link>
                    <div>{<DisplayChaptersProgress progress={item.chapterProgress as boolean[]} />}</div>
                </div>
            );
        } else if (item.status === "Disabled") {
            nav = (
                <div className={`locked-chapter`}>
                    {item.title}
                    <span style={{ float: "right" }}>
                        <i className="fas fa-lock" />
                    </span>
                </div>
            );
        }
    } else if (level === 1) {
        if (item.status === "Disabled") {
            nav = (
                <div className={`locked-chapter level2-create-nav bullet`}>
                    {item.title}
                    <span style={{ float: "right" }}>
                        <i className="fas fa-lock" />
                    </span>
                </div>
            );
        } else {
            nav = (
                <Link to={item.link} className={`bullet`}>
                    {item.title}
                </Link>
            );
        }
    } else {
        if (item.status === "Disabled") {
            nav = (
                <div className={`bullet`}>
                    {item.title}
                    <span style={{ float: "right" }}>
                        <i className="fas fa-lock" />
                    </span>
                </div>
            );
        } else if (item.status === "Active") {
            nav = (
                <Link to={item.link} className={`bullet`}>
                    {item.title}
                </Link>
            );
        }
    }
    return <React.Fragment>{nav}</React.Fragment>;
};

const NavItemDisplay: FunctionComponent<NavItemProps> = ({ item, level }: NavItemProps) => {
    if (item) {
        const itemClasses = [
            `navitem-level-${level}`,
            item.active ? "active visited" : "",
            item.items && item.items.length > 0 ? "has-children" : "",
            item.items && item.items.find(x => x.active) ? "has-active-child" : "",
        ];
        return (
            <li className={itemClasses.join(" ")}>
                <div>
                    <span className="subtitle">{item.subtitle}</span>
                    <NavTypeDisplay item={item} level={level} />
                </div>
                {item.items && item.active && (
                    <ul>
                        {item.items.map((item, ix) => {
                            return <NavItemDisplay key={ix} item={item} level={level + 1} />;
                        })}
                    </ul>
                )}
            </li>
        );
    } else {
        return <></>;
    }
};

const PreviewNav: FunctionComponent<TrainingSidebarProps> = ({ items }: TrainingSidebarProps): JSX.Element => {
    return (
        <div className="preview-sidebar">
            <ul className="preview-sidenav">
                {items &&
                    items.map((item, ix) => {
                        return <NavItemDisplay key={ix} item={item} level={0} />;
                    })}
            </ul>
        </div>
    );
};

export default React.memo(PreviewNav);
