import React, { useCallback, useEffect, useState } from "react";
import { createApi } from "../../common/api";
import { useCurrentGroup } from "../../common/courses/context";
import { createPatch } from "../../common/patchHelper";
import { DragResult } from "./dragAndDrop";
import * as jsonPatch from "fast-json-patch";
import {
    BoardContextContent,
    BoardContextFunctions,
    InternalBoardContextState,
    InternalLoadedBoardContextState,
    InternalLoadingBoardContextState,
    ToggleOption,
} from "./types";
import { getFilteredAndGroupedTasks } from "./model";
import { useImprovementBoardTaskSignalRListener } from "./useImprovementBoardTaskSignalRListener";
import { ImprovementBoardTask, TaskCategory } from "@/api-client";
import { customEvents } from "../../analytics/customEvents/customEvents";
import { convertFromColumn } from "../../analytics/customEvents/uiImprovementCreated";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

// @ts-ignore
export const BoardContext = React.createContext<BoardContextContent>(undefined);
export const useBoardContext = () => React.useContext(BoardContext);

interface BoardProviderProps {
    children: React.ReactNode;
}

const isLoading = (s: InternalBoardContextState): s is InternalLoadingBoardContextState => {
    return s.isLoading;
};

export const BoardProvider = ({ children }: BoardProviderProps) => {
    const api = createApi();

    const group = useCurrentGroup();

    const [state, setState] = useState<InternalBoardContextState>({
        isLoading: true,
    });

    const dispatch = useCallback(
        (updater: (s: InternalBoardContextState) => InternalBoardContextState) => {
            setState(oldState => {
                let nextState = updater(oldState);
                if (isLoading(nextState)) {
                    return nextState;
                }

                nextState = {
                    ...nextState,
                    filteredTasksGrouped: getFilteredAndGroupedTasks(nextState),
                };

                return nextState;
            });
        },
        [setState]
    );

    const loadedDispatch = useCallback(
        (updater: (s: InternalLoadedBoardContextState) => InternalLoadedBoardContextState) => {
            dispatch(state => {
                if (isLoading(state)) {
                    throw new Error("Not executable when loading");
                }

                return updater(state);
            });
        },
        [dispatch]
    );

    useImprovementBoardTaskSignalRListener(loadedDispatch);

    useEffect(() => {
        if (!group) {
            return;
        }

        const fetchData = async () => {
            const taskPromise = api.tasks.query(null, group.id);
            const categoriesPromise = api.taskCategories.query(group.id, null);

            const tasks = await taskPromise;
            const categories = await categoriesPromise;

            dispatch(_ => ({
                isLoading: false,
                group: group,
                // @ts-ignore
                activeUsers: group.members.filter(x => x.user.status === "Active").map(gm => gm.user),
                tasks: tasks,
                categoryFilters: {
                    items: categories.map(x => ({ item: x, enabled: true })),
                    nullItemsEnabled: true,
                },
                filteredTasksGrouped: {
                    User: { swimLaneCount: 0, tasks: {}, swimLanes: [] },
                    Priority: { swimLaneCount: 0, tasks: {}, swimLanes: [] },
                    Category: { swimLaneCount: 0, tasks: {}, swimLanes: [] },
                    all: {
                        New: [],
                        Active: [],
                        Archived: [],
                        Completed: [],
                        Todo: [],
                    },
                },
            }));
        };

        fetchData().finally();
    }, [group]);

    const toggleCategory = (filter: ToggleOption) => {
        loadedDispatch(s => ({
            ...s,
            categoryFilters: {
                ...s.categoryFilters,
                items: [
                    ...s.categoryFilters.items.filter(y => y.item.id !== filter.item.id),
                    { ...filter, enabled: !filter.enabled },
                ],
            },
        }));

        trackPosthogEvent("ui", "improvementboard", "togglecategory");
    };

    const toggleUncategorized = () => {
        loadedDispatch(s => ({
            ...s,
            categoryFilters: {
                ...s.categoryFilters,
                nullItemsEnabled: !s.categoryFilters.nullItemsEnabled,
            },
        }));
    };

    const deleteCategory = async (category: TaskCategory) => {
        if (isLoading(state)) {
            throw new Error("Not available when loading");
        }

        const getCategoryTasks = state.tasks.filter(t => t.taskCategoryId === category.id);

        await Promise.all(
            getCategoryTasks.map(t => {
                const patch = createPatch(t, g => {
                    g.taskCategoryId = null;
                });

                // @ts-ignore
                return api.tasks.patch(t.id, patch);
            })
        );

        // @ts-ignore
        await api.taskCategories.delete(category.id);

        loadedDispatch(s => ({
            ...s,
            categoryFilters: {
                ...s.categoryFilters,
                items: s.categoryFilters.items.filter(f => f.item.id !== category.id),
            },
            // @ts-ignore
            tasks: [...s.tasks.map(t => (t.taskCategoryId === category.id ? { ...t, taskCategoryId: null } : t))],
        }));
    };

    const handleDragEnd = async (dragResult: DragResult) => {
        // @ts-ignore

        const promises = dragResult.patches.map(async taskPatch =>
            api.tasks
                // @ts-ignore

                .patch(taskPatch.task.id, taskPatch.patch)
                .catch(() => {
                    console.error("Error while patching task");
                })
        );

        dispatch(b => ({
            ...b,
            // @ts-ignore

            tasks: dragResult.tasks,
        }));

        await Promise.allSettled(promises);
    };

    const addTask = async (task: ImprovementBoardTask) => {
        loadedDispatch(s => ({
            ...s,
            tasks: [...s.tasks, { ...task, id: -1 }],
        }));

        await api.tasks.post(task).then(
            createdTask => {
                // Do nothing, optmistic update covers
                // Update and remove temp task with id -1
                loadedDispatch(s => ({
                    ...s,
                    tasks: [...s.tasks.filter(x => x.id !== -1), createdTask],
                }));
                customEvents.raiseUiImprovementCreatedFrom(convertFromColumn(createdTask.status));
            },
            () => {
                console.error("Failed to add task");
            }
        );
    };

    const addOrUpdateCategory = async (category: TaskCategory) => {
        const isNew = !category.id;

        if (isNew) {
            const savedCategory = await api.taskCategories.post(category);
            loadedDispatch(b => ({
                ...b,
                categoryFilters: {
                    ...b.categoryFilters,
                    items: [...b.categoryFilters.items, { item: savedCategory, enabled: true }],
                },
            }));
            return savedCategory;
        } else {
            // @ts-ignore

            const savedCategory = await api.taskCategories.put(category.id, category);
            loadedDispatch(b => ({
                ...b,
                categoryFilters: {
                    ...b.categoryFilters,
                    items: [
                        ...b.categoryFilters.items.filter(x => x.item.id !== category.id),
                        { item: savedCategory, enabled: true },
                    ],
                },
            }));
            return savedCategory;
        }
    };

    const addOrUpdateDateTask = async (taskToSave: ImprovementBoardTask, initialTask: ImprovementBoardTask) => {
        if (taskToSave.id) {
            // Compare props.task with copy in state
            const patch = jsonPatch.compare(initialTask, taskToSave);
            // @ts-ignore

            const updatedTask = await api.tasks.patch(initialTask.id, patch);

            loadedDispatch(s => ({
                ...s,
                tasks: [...s.tasks.filter(x => x.id !== initialTask.id), updatedTask],
            }));
        } else {
            const createdTask = await api.tasks.post(taskToSave);
            loadedDispatch(s => ({
                ...s,
                tasks: [...s.tasks.filter(x => x.id !== -1), createdTask],
            }));
        }
    };

    const deleteTask: BoardContextFunctions["deleteTask"] = async taskId => {
        // @ts-ignore

        await api.tasks.delete(taskId);
        loadedDispatch(s => ({
            ...s,
            tasks: [...s.tasks.filter(x => x.id !== taskId)],
        }));

        trackPosthogEvent("ui", "improvementboard", "deletecategory");
    };

    const cloneTask: BoardContextFunctions["cloneTask"] = async task => {
        const clonedTask = { ...task };
        delete clonedTask.id;

        const response = await api.tasks.post(task);
        loadedDispatch(s => ({
            ...s,
            tasks: [...s.tasks, response],
        }));

        trackPosthogEvent("ui", "improvementboard", "clonetask");
    };

    const copyAndAssignToAll: BoardContextFunctions["copyAndAssignToAll"] = async (createdBy, taskToClone) => {
        if (isLoading(state)) {
            throw new Error("Not available when loading");
        }

        const task = { ...taskToClone };
        delete task.id;

        const assignedToUserId = task.assignedToUserId;
        const currentGroupMembers = state.activeUsers.filter(user => user.id !== assignedToUserId);

        const createdTasks = await Promise.all(
            currentGroupMembers.map(user => {
                const newTask = {
                    ...task,
                    assignedToUserId: user.id,
                    createdByUserId: createdBy,
                };
                return api.tasks.post(newTask);
            })
        );

        loadedDispatch(s => ({
            ...s,
            tasks: [...s.tasks, ...createdTasks],
        }));

        trackPosthogEvent("ui", "improvementboard", "copyandassigntoall");
    };

    if (isLoading(state)) {
        return (
            <BoardContext.Provider
                // @ts-ignore

                value={null}
            ></BoardContext.Provider>
        );
    }

    return (
        <BoardContext.Provider
            value={{
                state,
                toggleCategory: toggleCategory,
                toggleUncategorized: toggleUncategorized,
                deleteCategory: deleteCategory,
                handleDragEnd: handleDragEnd,
                addTask: addTask,
                addOrUpdateCategory: addOrUpdateCategory,
                addOrUpdateTask: addOrUpdateDateTask,
                deleteTask: deleteTask,
                cloneTask: cloneTask,
                copyAndAssignToAll: copyAndAssignToAll,
            }}
        >
            {children}
        </BoardContext.Provider>
    );
};
