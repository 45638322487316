import { ReactElement } from "react";
import { useTranslate } from "@tolgee/react";
import { SelectItem, Stack } from "@mantine/core";
import { useField } from "formik";
import * as yup from "yup";
import { DemographicQuestion, DemographicQuestionType } from "@/api-client";
import { createApi, createPatchFromDiff, useCurrentClient, yupExtensions } from "@/common";
import { FormInput, Form, InitialFormValue, box } from "@/components";

interface AddQuestionProps {
    question?: DemographicQuestion;
    onSaved: () => void;
}

const validator = yup.object({
    name: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
    selectionType: yup.mixed<DemographicQuestionType>().required("COMMON_FIELD_REQUIRED"),
    isRequired: yup.boolean().required(),
    options: yup.string().when("selectionType", (value, schema) => {
        const selectionType = value as DemographicQuestionType | "";

        if (selectionType !== "Text") {
            return schema.required("COMMON_FIELD_REQUIRED");
        }

        return schema;
    }),
});

type Schema = yup.InferType<typeof validator>;

const RenderIfFormValue = <T extends string>(props: {
    field: string;
    expected: (value: T) => boolean;
    children: ReactElement;
}) => {
    const [{ value }] = useField<T>(props.field);

    if (props.expected(value)) {
        return props.children;
    }

    return null;
};

const getInitialValues = (question?: DemographicQuestion): InitialFormValue<Schema> => {
    if (question === undefined) {
        return { name: "", isRequired: false, selectionType: "", options: "" };
    }

    return {
        name: box(question.label),
        isRequired: question.required,
        selectionType: box(question.type),
        options: box(question.options),
    };
};

export const EditQuestion = (props: AddQuestionProps) => {
    const { t } = useTranslate();
    const currentClient = useCurrentClient();

    const options: SelectItem[] = [
        { value: "Select", label: t("ONBOARDING_QUESTIONS_OPTIONS_SELECT") },
        { value: "Checkbox", label: t("ONBOARDING_QUESTIONS_OPTIONS_CHECKBOX") },
        { value: "Radio", label: t("ONBOARDING_QUESTIONS_OPTIONS_RADIO") },
        { value: "Text", label: t("ONBOARDING_QUESTIONS_OPTIONS_TEXTINPUT") },
    ];

    return (
        <Form<Schema>
            initialValues={getInitialValues(props.question)}
            validationSchema={validator}
            eventName={{ object: "ao_demograpicquestion", action: "save" }}
            onSubmit={values => {
                const save = async () => {
                    const api = createApi();

                    if (props.question) {
                        const patch = createPatchFromDiff(props.question, {
                            type: values.selectionType,
                            options: values.options,
                            label: values.name,
                            required: values.isRequired,
                        });

                        if (patch.length > 0) {
                            await api.demographicQuestions.patch(props.question.id!, patch);
                        }
                    } else {
                        await api.demographicQuestions.post({
                            type: values.selectionType,
                            options: values.options,
                            label: values.name,
                            required: values.isRequired,
                            clientId: currentClient.id,
                        });
                    }
                };

                return save()
                    .then(() => props.onSaved())
                    .catchWithToastAsync();
            }}
            submitLabel="COMMON_SAVE"
        >
            <Stack>
                <FormInput.Text fieldName="name" label="ORGANISATION_DETAILS_NAME" />
                <FormInput.NativeSelect
                    fieldName="selectionType"
                    label="ONBOARDING_QUESTION_SELECTION_TYPE"
                    options={options}
                />
                <RenderIfFormValue<DemographicQuestionType>
                    field="selectionType"
                    expected={value => value === "Select" || value === "Radio" || value === "Checkbox"}
                >
                    <FormInput.Textarea fieldName="options" label="ONBOARDING_QUESTION_OPTION" />
                </RenderIfFormValue>
                <FormInput.Checkbox fieldName="isRequired" label="ONBOARDING_QUESTION_REQUIRED" />
            </Stack>
        </Form>
    );
};
