import React, { useState } from "react";
import { trackPosthogEvent } from "../../../../analytics/customEvents/trackPosthogEvent";

export const useZoom = () => {
    const [zoom, setZoom] = useState<number>(100);
    const reset = React.useCallback(() => {
        trackPosthogEvent("ui", "improvementboard", "resetzoom");
        return setZoom(_ => 100);
    }, [setZoom]);
    const zoomIn = React.useCallback(() => {
        trackPosthogEvent("ui", "improvementboard", "zoomin");
        return setZoom(s => (s === 400 ? s : s + 10));
    }, [setZoom]);
    const zoomOut = React.useCallback(() => {
        trackPosthogEvent("ui", "improvementboard", "zoomout");
        return setZoom(s => (s === 10 ? s : s - 10));
    }, [setZoom]);

    return { zoom, reset, zoomIn, zoomOut };
};
