﻿import React from "react";
import { Textarea } from "@mantine/core";
import { useField } from "formik";
import { useTranslate } from "@tolgee/react";

export type FormTextInputProps = {
    fieldName: string;
    label: string;
};

export const TextareaInput = (props: FormTextInputProps) => {
    const [field, meta] = useField(props.fieldName);
    const { t } = useTranslate();

    return (
        <Textarea {...field} label={t(props.label)} error={meta.error && meta.touched ? t(meta.error) : undefined} />
    );
};
