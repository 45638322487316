import { Radio, Group } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useField } from "formik";

type RadioGroupInputProps = {
    fieldName: string;
    label: string;
    options: { label: string; value: string }[];
};

export const RadioGroupInput = (props: RadioGroupInputProps) => {
    const [field, meta, helpers] = useField<string>(props.fieldName);
    const { t } = useTranslate();

    return (
        <Radio.Group
            label={t(props.label)}
            name={props.fieldName}
            value={field.value}
            onChange={value => helpers.setValue(value)}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        >
            <Group mt="xs">
                {props.options.map(option => (
                    <Radio key={option.value} value={option.value} label={option.label} />
                ))}
            </Group>
        </Radio.Group>
    );
};
