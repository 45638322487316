import { useFullscreen as useFullscreenMantine } from "@mantine/hooks";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

export type FullscreenProps = {
    enter: () => Promise<void>;
    exit: () => Promise<void>;
    isEnabled: boolean;
};

export const useFullscreen = (): FullscreenProps & { ref: (element: HTMLElement | null) => void } => {
    const { toggle, fullscreen: isFullscreen, ref } = useFullscreenMantine();

    const enableFullScreen = () => {
        if (!isFullscreen) {
            trackPosthogEvent("ui", "improvementboard", "fullscreen");
            return toggle();
        }

        return Promise.resolve();
    };

    const disableFullScreen = () => {
        if (isFullscreen) {
            return toggle();
        }

        return Promise.resolve();
    };

    return {
        enter: enableFullScreen,
        exit: disableFullScreen,
        isEnabled: isFullscreen,
        ref,
    };
};
