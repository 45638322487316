import React from "react";
import { Link } from "react-router-dom";
import SlideshowButton from "./SlideshowButton";
import "./styles.scss";

interface Props {
    // The slide content
    children: JSX.Element;

    // Undefined means disabled
    previousLink?: string;

    // Undefined means disabled
    nextLink?: string;

    // Slideshow button
    button?: JSX.Element;
}

const Slideshow = ({ children, previousLink, nextLink, button }: Props) => {
    return (
        <div className="slideshow">
            <div className="slide-content d-flex">
                <div className="slideshow-nav-arrow left">
                    {previousLink && previousLink && <Link to={previousLink}>&#8249;</Link>}
                </div>
                <div className="content-template w-100 p-4">
                    {children}
                    {button && <SlideshowButton>{button}</SlideshowButton>}
                </div>
                <div className="slideshow-nav-arrow right">
                    {nextLink && nextLink && <Link to={nextLink}>&#8250;</Link>}
                </div>
            </div>
        </div>
    );
};

export default Slideshow;
