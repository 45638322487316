import { SelectItem, Stack, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { AppLoader, Form, FormInput, ModalConfig, ModalProps } from "@/components";
import { AddUserMultiSelectInput, Username } from "./AddUserMultiSelectInput";
import { createApi, createEmailOrPhoneNumberValidator, useCurrentClient } from "@/common";
import { UsernameDto } from "@/api-client";
import { getUsernames } from "../../getUsernames";

const validator = yup.object({
    usernames: yup
        .array()
        .of(createEmailOrPhoneNumberValidator("ACCOUNT_OWNER_SOME_EMAILS_ARE_NOT_CORRECTLY_FORMATTED").required())
        .min(1, "ACCOUNT_OWNER_AT_LEAST_ONE_EMAIL_IS_REQUIRED")
        .required(),
    teamId: yup.string().required("EDITEMPLOYEE_TEAM_IS_REQUIRED"),
});

type FormSchema = yup.InferType<typeof validator>;

const initialValues: FormSchema = {
    usernames: [],
    teamId: "",
};

type AddUserProps = ModalProps<object>;

const AddUser = (props: AddUserProps) => {
    const { t } = useTranslate();

    const api = createApi();
    const clientId = useCurrentClient().id;
    const [state, setState] = useState<{ teams: SelectItem[] | undefined; existingUsernames: Username[] }>({
        teams: undefined,
        existingUsernames: [],
    });

    useEffect(() => {
        const getExistingUsernames = () =>
            api.users
                .query(undefined, undefined, undefined, undefined)
                .then(u => getUsernames(u.filter(x => x.clientId === clientId)));

        const op = async () => {
            const teamDtos = await api.accountOwners.getTeams(clientId);
            const usernames = await getExistingUsernames();

            setState({
                existingUsernames: usernames,
                teams: teamDtos
                    .filter(t => t.status === "Active")
                    .map(t => ({
                        value: t.id.toString(),
                        label: t.name,
                    }))
                    .orderBy(x => x.label),
            });
        };

        op().catchWithToast();
    }, []);

    const teams = state.teams;

    if (!teams) {
        return <AppLoader loading />;
    }

    return (
        <Form<FormSchema>
            initialValues={initialValues}
            validationSchema={validator}
            submitLabel="RESOURCES_ADD_EMPLOYEES"
            eventName={{ object: "ao_adduser", action: "save" }}
            onSubmit={async values => {
                api.accountOwners
                    .addUsers(
                        clientId,
                        parseInt(values.teamId),
                        values.usernames.map(x => new UsernameDto(x))
                    )
                    .then(() => props.onClose())
                    .catchWithToast();
            }}
        >
            <Stack>
                <div>
                    <Text color="dimmed" size="xs" mb="sm">
                        {t("ACCOUNTOWNER_ADD_EMPLOYEES_TIP_TEXT")}
                    </Text>
                    <AddUserMultiSelectInput
                        label="ACCOUNT_OWNER_ADD_EMAILS_OR_PHONE_NUMBERS"
                        fieldName="usernames"
                        existingUsers={state.existingUsernames}
                    />
                </div>

                <FormInput.NativeSelect
                    fieldName="teamId"
                    label="COMMON_TEAM"
                    placeholder="EDITEMPLOYEE_SELECT_TEAM"
                    options={teams}
                />
            </Stack>
        </Form>
    );
};

export const AddUserModal: ModalConfig<AddUserProps> = {
    content: AddUser,
    title: "RESOURCES_ADD_EMPLOYEES",
};
