import { useMemo } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_Row } from "mantine-react-table";
import { Badge, SelectItem } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useModals } from "@mantine/modals";
import { createTagsSelectItems } from "common/createTagsSelectItems";
import { NavButton, RowActionConfig, RowActions, Table } from "@/components";
import { CourseStatuses } from "@/api-client";
import { UrlFactory } from "../../../routing/UrlFactory";
import { createApi, useHistory } from "@/common";
import { useSysadminContextDispatch } from "../../../sysadmin/SysadminContext";

export type ProgramsAndCapabilitiesRow = {
    id: number;
    name: string;
    status: CourseStatuses;
    expertName: string | null;
    tags: string[];
};

type ProgramsAndCapabilitiesListProps = {
    programsAndCapabilities: ProgramsAndCapabilitiesRow[] | null;
};

const StatusBadge = (props: { status: CourseStatuses }) => {
    if (props.status === "Active") {
        return <Badge color="green">{props.status}</Badge>;
    }

    return <Badge>{props.status}</Badge>;
};

const StatusCell = (props: { row: MRT_Row<ProgramsAndCapabilitiesRow> }) => {
    return <StatusBadge status={props.row.original.status} />;
};

const ToolbarActions = () => {
    return <NavButton link="/sysadmin/courses/add" textKey="SYSADMIN_ADD_PROGRAM_OR_CAPABILITY" />;
};

const programStatus: readonly CourseStatuses[] = ["Active", "InProgress", "Deleted", "Disabled", "Template"] as const;

const statusSelectItems: SelectItem[] = programStatus.map(s => ({ label: s, value: s }));

export const ProgramsAndCapabilitiesList = (props: ProgramsAndCapabilitiesListProps) => {
    const { t } = useTranslate();
    const history = useHistory();
    const modal = useModals();
    const sysadminContextDispatch = useSysadminContextDispatch();

    const initialFilterState: MRT_ColumnFiltersState = [{ id: "status", value: ["Active"] }];

    const tagsSelectItems = useMemo(
        () => createTagsSelectItems(props.programsAndCapabilities?.flatMap(x => x.tags)),
        [props.programsAndCapabilities]
    );

    const cloneProgram = (programId: number) => {
        const api = createApi();

        modal.openConfirmModal({
            title: t("SYSADMIN_PROGRAM_CLONE_CONFIRM_HEADING"),
            labels: {
                confirm: t("ONBOARDING_QUESTTION_DELETE_YES"),
                cancel: t("ONBOARDING_QUESTTION_DELETE_NO"),
            },
            onConfirm: () =>
                api.courses
                    .get(programId)
                    .then(fullCourse =>
                        api.courses.post(fullCourse).then(result => {
                            sysadminContextDispatch(s => ({
                                ...s,
                                courses: [...s.courses!, result],
                            }));
                            return result.id;
                        })
                    )
                    .then(newProgram => history.push(UrlFactory.sysadmin.program.create({ programId: newProgram })))
                    .catchWithToast(),
        });
    };

    const columns = useMemo<MRT_ColumnDef<ProgramsAndCapabilitiesRow>[]>(
        () => [
            {
                header: t("SYSADMIN_NAME"),
                accessorKey: "name",
                size: 300,
            },
            {
                id: "status",
                header: t("SYSADMIN_STATUS"),
                accessorKey: "status",
                filterVariant: "multi-select",
                Cell: StatusCell,
                mantineFilterMultiSelectProps: {
                    data: statusSelectItems,
                },
            },
            {
                header: t("SYSADMIN_EXPERT"),
                accessorKey: "expertName",
            },
            {
                header: t("SYSADMIN_TAGS"),
                accessorFn: ({ tags }) => tags.map(t => t.trim()).join(", "),
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: tagsSelectItems,
                },
            },
        ],
        [tagsSelectItems]
    );

    const rowActions: RowActionConfig<ProgramsAndCapabilitiesRow> = {
        renderRowActions: ({ row }) => (
            <>
                <RowActions.EditLink to={UrlFactory.sysadmin.program.create({ programId: row.original.id })} />
                <RowActions.ContextMenu.Menu>
                    <RowActions.ContextMenu.Button
                        onClick={() => cloneProgram(row.original.id)}
                        label="SYSADMIN_CLONE"
                    />
                    <RowActions.ContextMenu.Link
                        to={`/sysadmin/course-preview/${row.original.id}`}
                        label="SYSADMIN_PREVIEW"
                    />
                </RowActions.ContextMenu.Menu>
            </>
        ),
        size: 50,
    };

    return (
        <Table
            toolbarActions={ToolbarActions}
            columns={columns}
            data={props.programsAndCapabilities}
            initialColumnFilterState={initialFilterState}
            rowActions={rowActions}
            initialSort={[{ id: "name", desc: false }]}
        />
    );
};
