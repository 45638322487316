import { Client, Course, Video } from "@/api-client";
import React, { useEffect, useState } from "react";
import { createApi } from "../common/api";
import { StateWrapper2, WithChildren } from "../common/StateWrapper";
import { AppLoader } from "../components/Spinner";
import { User } from "@/api-client";
import { useAuth } from "../auth/useAuth";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface SysadminContextState {
    users?: User[];
    courses?: Course[];
    clients?: Client[];
    videos?: Video[];
}

const SysadminContext = React.createContext<StateWrapper2<SysadminContextState, WithChildren>>(
    // @ts-ignore

    undefined
);

export const useSysAdminCourses = () => React.useContext(SysadminContext)?.state?.courses;
export const useSysAdminClients = () => React.useContext(SysadminContext)?.state?.clients;
export const useSysAdminVideos = () => React.useContext(SysadminContext)?.state?.videos;
export const useSysAdminUsers = () => React.useContext(SysadminContext)?.state?.users;
export const useSysadminContextDispatch = () => React.useContext(SysadminContext)?.setState;

// Helper to get a course by id and update it easily
export const useSysadminCourse = (courseId: number): [Course, (callback: (course: Course) => Course) => void] => {
    const courses = useSysAdminCourses();
    const course = courses?.find(c => c.id === courseId);

    const update = useUpdateSysadminCourse(courseId);

    // @ts-ignore
    return [course, update];
};

export const useUpdateSysadminCourse = (courseId: number): ((callback: (course: Course) => Course) => void) => {
    const dispatch = useSysadminContextDispatch();
    return (callback: (course: Course) => Course) => {
        dispatch(s => ({
            ...s,
            // @ts-ignore

            courses: [...s.courses.filter(c => c.id !== courseId), callback(s.courses.find(c => c.id === courseId))],
        }));
    };
};

export class SysadminContextProvider extends React.Component<WithChildren, SysadminContextState> {
    constructor(props: WithChildren) {
        super(props);

        this.state = {
            users: undefined,
            courses: undefined,
            clients: undefined,
            videos: undefined,
        };
    }

    render() {
        const stateWrapper: StateWrapper2<SysadminContextState, WithChildren> = {
            state: this.state,
            setState: this.setState.bind(this),
        };
        return (
            <SysadminContext.Provider value={stateWrapper}>
                <SysadminContextProviderInner>{this.props.children}</SysadminContextProviderInner>
            </SysadminContext.Provider>
        );
    }
}

const SysadminContextProviderInner = (props: WithChildren) => {
    const { setState } = React.useContext(SysadminContext);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const api = createApi();
    const { clearImpersonation } = useAuth();

    const ensureNotImpersonated = async () => {
        await clearImpersonation();
    };

    useEffect(() => {
        const op = async () => {
            await ensureNotImpersonated();

            const users = api.users.query(null, null, null, true);
            const courses = api.courses.query(false);
            const clients = api.clients.query(true);
            const videos = api.videos.query(true);

            Promise.all([users, courses, clients, videos]).then(x => {
                setState(() => ({
                    users: x[0],
                    courses: x[1],
                    clients: x[2],
                    videos: x[3],
                }));

                setIsInitialized(true);
            });
        };

        op();
    }, []);

    if (isInitialized === false) {
        return <AppLoader loading={true} />;
    }

    return <>{props.children}</>;
};
