﻿import { Container, Title, ActionIcon, Flex } from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import { MRT_ColumnDef } from "mantine-react-table";
import { useTranslate } from "@tolgee/react";
import { Icons } from "common/Icons";
import { NavLink } from "react-router-dom";
import { UrlFactory } from "../../../routing/UrlFactory";
import { CardBlock, NavButton, Table } from "@/components";
import { createApi } from "../../../common/api";
import { GetModulesDto } from "@/api-client";
import { Breadcrumbs } from "../../AccountOwner/Breadcrumbs";
import { RowActionConfig } from "../../../components/Table/Table";

export const ModulesPage = () => {
    const { t } = useTranslate();
    const [modules, setModules] = useState<GetModulesDto[]>([]);

    useEffect(() => {
        const abortController = new AbortController();
        const op = async () => {
            const api = createApi();
            const modules = await api.sysadmin.getModules(abortController);
            setModules(modules);
        };

        op().catchWithToast();

        return () => abortController.abort();
    }, []);

    const columns = useMemo<MRT_ColumnDef<GetModulesDto>[]>(
        () => [
            {
                id: "name",
                accessorKey: "name",
                header: t("SYSADMIN_MODULE_NAME"),
                enableColumnFilter: false,
            },
        ],
        []
    );

    const rowActions: RowActionConfig<GetModulesDto> = useMemo(
        () => ({
            renderRowActions: ({ row }) => (
                <ActionIcon component={NavLink} to={UrlFactory.sysadmin.module.create({ moduleId: row.original.id })}>
                    <Icons.Edit />
                </ActionIcon>
            ),
            size: 10,
        }),
        []
    );

    const items = [{ title: t("SYSADMIN_MODULE_MODULES") }];

    return (
        <Container>
            <Flex direction="column" rowGap="xl" mb="xl">
                <Breadcrumbs items={items} />
                <CardBlock>
                    <Title size={20} mb="md">
                        {t("SYSADMIN_MODULE_MODULES")}{" "}
                    </Title>
                    <Table
                        columns={columns}
                        data={modules}
                        initialColumnFilterState={[]}
                        toolbarActions={() => (
                            <NavButton link={UrlFactory.sysadmin.module.create({})} textKey="Add Module" />
                        )}
                        rowActions={rowActions}
                    />
                </CardBlock>
            </Flex>
        </Container>
    );
};
