import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import "./scss/matrix.scss";
import useDebounce from "../UseDebounce/UseDebounce";
import { Answer, Question, User } from "@/api-client";
import { ExtendAnswer, QuestionTemplateProps } from "./models/QuestionTemplateProps";
import { useUserContext } from "../../common/user/context";
import { MaterialUiStyledSlider } from "../MaterialUiStyledSlider";
import "./scss/Questions.scss";
import { darkSmoke } from "../../common/colors";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

/*  eslint-disable react-hooks/exhaustive-deps */

interface MatrixScaleAnswer {
    answer: ExtendAnswer;
    isValid?: boolean;
    isSkipped?: boolean;
    value?: number;
    label?: string;
    hasValue?: boolean;
    isTouched?: boolean;
}

export const getInitValues = (
    question: Question,
    existingAnswers: Answer[],
    user: User,
    currentProgress: boolean
): MatrixScaleAnswer[] => {
    /* @ts-ignore */
    return question.options.map(a => {
        const existingAnswer = existingAnswers.find(x => x.optionAnswerId === a.id);
        const textValue = question.minAnswer === null ? 0 : question.minAnswer;
        const answer = {
            id: undefined,
            optionAnswerId: a.id,
            text: `${textValue}`,
            questionId: question.id,
            isPredefined: false,
            hidden: false,
            userId: user.id,
            ...existingAnswer,
        };
        const isSkipped =
            existingAnswers.length === 0 ? false : !(existingAnswers.length > 0 && existingAnswer != null);

        return {
            answer: answer,
            isSkipped: !!(currentProgress && !existingAnswer?.text),
            value: parseInt(answer.text, 10),
            label: a.text,
            hasValue: true,
            isTouched: false,
        };
    });
};

interface MatrixScaleAnswersProps extends QuestionTemplateProps {
    currentProgress: boolean | null;
    index: number;
    question: Question;
    placeholder: string;
    error: string[] | undefined;
}

export const MatrixScaleAnswers = (props: MatrixScaleAnswersProps): JSX.Element => {
    const { t } = useTranslate();
    const { allowSkip, maxAnswerLabel, minAnswerLabel } = props.question;

    const userContext = useUserContext();
    const [answers, setAnswers] = useState<MatrixScaleAnswer[]>();
    const [errors, setErrors] = useState<string[]>();

    useEffect(() => {
        if (props.question && userContext.user && props.question.options) {
            const initValues = getInitValues(
                props.question,
                props.answers,
                userContext.user,
                /* @ts-ignore */
                props.currentProgress
            );
            setAnswers(initValues);
        }
    }, [props.question, userContext, props.currentProgress]);
    const answersStateChange = useDebounce(answers, 500);
    useEffect(() => {
        if (answers != null && props.question) {
            if (props.question && answers.filter(x => x.isValid === undefined).length === answers.length) {
                return;
            }
            /* @ts-ignore */
            const error = [];
            const isValid = true;
            if (answers) {
                answers.forEach((a, i) => {
                    // if(!a.isSkipped && ( !a.hasValue ) ){
                    //     isValid = false;
                    // }
                });
                /* @ts-ignore */
                setErrors(error);
                props.setValidation(isValid, true);
                props.setAnswers(answers.filter(a => a.hasValue === true).map(x => x.answer));
            }
        }
    }, [answersStateChange]);

    const handleSkipChange = (answer: MatrixScaleAnswer, e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        answer.isSkipped = e.target.checked;
        /* @ts-ignore */
        const previousState = [...answers];
        previousState[index].isSkipped = e.target.checked;
        previousState[index].hasValue = true;
        previousState[index].isValid = false;
        if (e.target.checked) {
            previousState[index].answer.isSkipped = true;
            previousState[index].isValid = true;
            trackPosthogEvent("ui", "mytraining", "skipquestion");
        }
        setAnswers(previousState);
    };

    const setAnswerValue = (answer: MatrixScaleAnswer, value: number, index: number) => {
        answer.value = value;
        /* @ts-ignore */
        const previousState = [...answers];
        previousState[index].isValid = false;
        previousState[index].hasValue = true;
        if (value) {
            previousState[index].isValid = true;
        }
        previousState[index].answer.text = `${value}`;
        setAnswers(previousState);
    };
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="block-content-text">
                        <div className="moduleHeader">{props.placeholder}</div>
                        <p>{props.question.text}</p>
                        <p>{props.question.description}</p>
                    </div>
                </div>
            </div>
            <div className="matrix-options block-content-option">
                {answers != null &&
                    answers.map((answer, ix) => {
                        return (
                            <React.Fragment key={`${ix}_${answer.answer.id}`}>
                                <div className={`row ${ix > 0 ? "mt-5" : ""}`}>
                                    <div className="col-sm-12 top-container">
                                        <div className="matrix-title">{answer.label}</div>
                                        {allowSkip === true && (
                                            <div className="matrix-skip-button" style={{ fontSize: "14px" }}>
                                                <label>
                                                    <input
                                                        disabled={!!props.currentProgress}
                                                        checked={answer.isSkipped}
                                                        type="checkbox"
                                                        onChange={e => handleSkipChange(answer, e, ix)}
                                                        name={`skip[${ix}]`}
                                                        value={1}
                                                    />{" "}
                                                    {t("PROGRAM_MY_TRAINING_SKIP_THIS_QUESTION")}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`row matrix-layout-border d-block ${
                                        answer.isSkipped ? "change-matrix-style" : ""
                                    }`}
                                >
                                    <div className="col-md-12">
                                        <MaterialUiStyledSlider
                                            labelColor={darkSmoke.hex}
                                            value={answer.value === undefined ? 0 : answer.value}
                                            min={props.question.minAnswer === undefined ? 0 : props.question.minAnswer}
                                            max={props.question.maxAnswer === undefined ? 0 : props.question.maxAnswer}
                                            onChange={value => {
                                                setAnswerValue(answer, value, ix);
                                            }}
                                            /* @ts-ignore */
                                            disabled={props.currentProgress || answer.isSkipped}
                                        />
                                    </div>
                                    <div className="col-md-12 bottom-container mt-4 mb-3">
                                        <div className="bottom-left-item bottom-item">{minAnswerLabel}</div>
                                        <div className="bottom-right-item bottom-item">{maxAnswerLabel}</div>
                                    </div>
                                    {errors != null && errors.length > 0 && (
                                        <div key={`error_${ix}`} className="ms-md-3">
                                            <div className="clearfix w-100 text-danger">{errors[ix]}</div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                {((errors != null && errors.length === 0) || errors === undefined) && props.error != null && (
                    <p className="error learfix w-100 text-danger">{t("MODULE_VALIDATE_MATRIX_REQUIRED")}</p>
                )}
            </div>
        </>
    );
};
