import React from "react";
import { useTranslate } from "@tolgee/react";
import { ModalsProvider } from "@mantine/modals";
import { Box, Container, Global } from "@mantine/core";
import { useCurrentGroup } from "../../../common/courses/context";
import { useCurrentUser } from "../../../common/user/context";
import { ContentLabel } from "../../../components/ContentLabel";
import { ReactSignalRStatusMessage } from "./SignalRStatusMessage";
import { AppLoader } from "../../../components/Spinner";
import { Board } from "./Board/Board";
import { modalProps } from "../../../CommonModalProps";
import { useFullscreen } from "../useFullscreen";
import { hasImprovementBoardPermission } from "../../../auth/permissions";

export const ImprovementBoardPage = () => {
    const { t } = useTranslate();

    const currentGroup = useCurrentGroup()!;
    const currentUser = useCurrentUser()!;

    const fullscreen = useFullscreen();

    const hasPermission = hasImprovementBoardPermission(currentUser, currentGroup);

    return (
        <>
            <Global
                styles={() => ({
                    body: {
                        //Set global background in improvement board view only
                        background: "unset",
                    },
                })}
            />
            <Container fluid bg="white" p="xl">
                {!currentGroup && <AppLoader loading />}

                {!hasPermission && (
                    <>
                        <ContentLabel text={t("BOARD_IMPROVEMENTS_LABEL")} className="flex-grow-1" />
                        <p>{t("BOARD_NO_VIEW_PERMISSION")}</p>
                    </>
                )}

                {hasPermission && (
                    <>
                        <ReactSignalRStatusMessage />

                        <Box
                            component="div"
                            ref={fullscreen.ref}
                            sx={theme => {
                                if (!fullscreen.isEnabled) {
                                    return {};
                                }

                                return {
                                    overflowY: "auto",
                                    backgroundColor: "white",
                                    padding: theme.spacing.xl,
                                };
                            }}
                        >
                            <ModalsProvider
                                modalProps={{
                                    ...modalProps,
                                    target: fullscreen.isEnabled ? "#modalTarget" : undefined,
                                }}
                            >
                                <div id="modalTarget" />
                                <Board fullscreen={fullscreen} />
                            </ModalsProvider>
                        </Box>
                    </>
                )}
            </Container>
        </>
    );
};
