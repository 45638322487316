import { Answer } from "@/api-client";
import { useTranslate } from "@tolgee/react";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

export interface EditAnswerProps {
    answer: Answer;
    show: boolean;
    hide: () => void;
    renameAnswer: (text: string) => void;
}

export const EditAnswer = ({ answer, show, hide, renameAnswer }: EditAnswerProps) => {
    const { t } = useTranslate();

    // @ts-ignore
    const [name, setName] = useState<string>(answer.text);

    return (
        <Modal show={show} size={"lg"} onHide={hide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("WORKSHOP_UPDATE_ANSWER")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-inline">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                        {t("WORKSHOP_RATING_NAME")}
                    </label>
                    <input
                        type="text"
                        className="form-control mb-2 me-sm-2 col"
                        id="inlineFormInputName2"
                        placeholder={t("WORKSHOP_RATING_PLACEHOLDER")}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton
                    eventName={{ object: "categorize", action: "editanswer" }}
                    type="button"
                    onClick={() => renameAnswer(name)}
                    disabled={!name || name.length === 0 ? true : false}
                >
                    {t("WORKSHOP_UPDATE_ANSWER")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
