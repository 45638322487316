import { DemographicQuestion, DemographicUserData, DemographicUserDataClient } from "@/api-client";
import { QuestionsWithAnswers } from "./DemographicQuestionsPage";

/**
 * Combine questions and answers for a specific user
 * @param userId
 * @param demographicQuestions
 * @param demographicData
 * @returns
 */
export const combineDemographicQuestionsWithAnswers = (
    userId: number,
    demographicQuestions: DemographicQuestion[],
    demographicData: DemographicUserData[]
) => {
    if (!demographicQuestions || !demographicData) {
        return undefined;
    }

    return demographicQuestions.map(q => {
        const answer = demographicData.filter(a => a.userId === userId).find(a => a.demographicQuestionId === q.id);
        return {
            question: q,
            answer,
            isValid: q.required && answer ? true : false,
        };
    });
};

/**
 * Returns an updated array with validation errors, if any
 * @param questionsWithAnswers
 * @returns
 */
export const validateDemographicAnswers = (questionsWithAnswers: QuestionsWithAnswers[]): QuestionsWithAnswers[] => {
    return questionsWithAnswers.map(x => {
        const question = x.question;

        if (question.required && (x?.answer?.value === undefined || x?.answer?.value === "")) {
            return { ...x, isValid: false, error: "Please select " + question.label };
        } else {
            return { ...x, isValid: true, error: "" };
        }
    });
};

/**
 * True if any data has validation errors
 * @param questionsWithAnswers
 * @returns
 */
export const hasValidationError = (questionsWithAnswers: QuestionsWithAnswers[]): boolean => {
    return (
        questionsWithAnswers &&
        questionsWithAnswers.filter(q => q.question.required === true && q.error !== undefined && q.error !== "")
            .length > 0
    );
};

/**
 * Map updates and save all questions correlated within a promise
 * @param userId
 * @param questionsWithAnswers
 * @param apiClient
 * @returns
 */
export const saveDemographicDataForUser = (
    userId: number,
    questionsWithAnswers: QuestionsWithAnswers[],
    apiClient: DemographicUserDataClient
): Promise<DemographicUserData[]> => {
    return new Promise((resolve, reject) => {
        if (hasValidationError(questionsWithAnswers)) {
            reject();
        } else {
            Promise.allSettled(
                questionsWithAnswers
                    .filter(q => q.answer !== undefined)
                    .map(q => {
                        // @ts-ignore
                        return apiClient.put(userId, q.question.id, q.answer);
                    })
            )
                .then(results => {
                    const savedAnswers: DemographicUserData[] = results
                        .filter(r => r.status === "fulfilled")
                        .map(r => r as PromiseFulfilledResult<DemographicUserData>)
                        .map(r => r.value);

                    resolve(savedAnswers);
                })
                .catch(err => {
                    reject();
                });
        }
    });
};
