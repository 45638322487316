import { OpExEvent } from "@/api-client";
import * as jsonpatch from "fast-json-patch";

export function updateArrayEntity<T extends { id?: number }>(
    array: T[],
    id: number,
    patch: jsonpatch.Operation[]
): T[] {
    return array.map(x => (x.id === id ? jsonpatch.applyPatch(x, patch, false, true).newDocument : x));
}

export function replaceArrayEntity<T extends { id?: number }>(array: T[], id: number, replacedItem: T): T[] {
    // Skip existing with same id in case it's added already by optmistic update or similar.
    // This event is triggered by PUT and should work like it, i.e essentially add or replace
    return [...array.filter(x => x.id !== id), replacedItem];
}

export function deleteArrayEntity<T extends { id?: number }>(array: T[], id: number): T[] {
    return array.filter(x => x.id !== id);
}

/**
 * Apply an SignalR Entity Event to an array
 * @param entityEvent
 * @param array
 * @returns
 */
/* @ts-ignore */
export function applyEntityEvent<T extends { id?: number }>(entityEvent: OpExEvent, array: T[]): T[] {
    switch (entityEvent.eventType) {
        case "Added":
        case "Replaced":
            /* @ts-ignore */
            return replaceArrayEntity<T>(array, entityEvent.entityId, entityEvent.data as T);
        case "Deleted":
            /* @ts-ignore */
            return deleteArrayEntity<T>(array, entityEvent.entityId);
        case "Updated":
            const patch: jsonpatch.Operation[] = entityEvent.data.operations
                ? entityEvent.data.operations
                : entityEvent.data;
            /* @ts-ignore */
            return updateArrayEntity<T>(array, entityEvent.entityId, patch);
    }
}

/**
 * Apply multiple events
 * @param entityEvents
 * @param array
 * @returns
 */
export function applyEntityEvents<T extends { id?: number }>(entityEvents: OpExEvent[], array: T[]): T[] {
    let arr = [...array];

    for (var i = 0, len = entityEvents.length; i < len; i++) {
        arr = applyEntityEvent(entityEvents[i], arr);
    }

    // Return as immutable
    return arr;
}
