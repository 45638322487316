﻿import { showToast } from "@/components";
import { tolgee } from "../../translation";

export {};

/* eslint-disable no-extend-native */

declare global {
    interface Promise<T> {
        catchWithToast(): void;

        catchWithToastAsync(): Promise<void>;
    }
}

if (!Promise.prototype.catchWithToast) {
    Promise.prototype.catchWithToast = function () {
        this.catch(error => {
            if (error.name === "AbortError") {
                return;
            }

            showToast(tolgee.t("COMMON_DEFAULT_ERROR"), "error");
        });
    };
}

if (!Promise.prototype.catchWithToastAsync) {
    Promise.prototype.catchWithToastAsync = function () {
        return this.then(() => {}).catch(error => {
            if (error.name === "AbortError") {
                return;
            }

            showToast(tolgee.t("COMMON_DEFAULT_ERROR"), "error");
        });
    };
}
