import { DemographicQuestion, User } from "@/api-client";
import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../../common/api";
import { useOrganisationContextDispatch } from "../../../../common/OrganisationContext";
import { DisplayInlineError } from "../../../../components/DisplayInlineError";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface SelectionType {
    type: string;
    option: string;
}

interface AddQuestionProps {
    user: User | undefined;
    question?: DemographicQuestion;
    handleQuestion?: (data: DemographicQuestion) => void;
    demographicQuestionId?: number;
}

export const AddQuestions = ({ user, question, handleQuestion, demographicQuestionId }: AddQuestionProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const accountOwnerDispatch = useOrganisationContextDispatch();
    const questionSelectionType: SelectionType[] = [
        { type: "Select", option: t("ONBOARDING_QUESTIONS_OPTIONS_SELECT") },
        { type: "Checkbox", option: t("ONBOARDING_QUESTIONS_OPTIONS_CHECKBOX") },
        { type: "Radio", option: t("ONBOARDING_QUESTIONS_OPTIONS_RADIO") },
        { type: "Text", option: t("ONBOARDING_QUESTIONS_OPTIONS_TEXTINPUT") },
    ];

    const [addQuestionState, setAddQuestionState] = useState(question);
    useEffect(() => {
        const questions: DemographicQuestion = {
            /* @ts-ignore */
            id: question && question.id ? question.id : undefined,
            /* @ts-ignore */
            label: question && question.label ? question.label : null,
            /* @ts-ignore */
            type: question && question.type ? question.type : null,
            /* @ts-ignore */
            options: question && question.options ? question.options : null,
            /* @ts-ignore */
            required: question && question.required ? question.required : false,
            /* @ts-ignore */
            clientId: user?.clientId,
        };
        setAddQuestionState(questions);
    }, [question]);

    const [validateQuestionState, setValidateQuestionState] = useState({
        label: null,
        type: null,
        options: null,
    });

    const handleSetQuestionValues = (value: string | boolean, name: string) => {
        /* @ts-ignore */
        setAddQuestionState(prevState => ({ ...prevState, [name]: value }));
        if (value !== "" && value !== null) {
            setValidateQuestionState(prevState => ({ ...prevState, [name]: null }));
        } else {
            setValidateQuestionState(prevState => ({ ...prevState, [name]: `${name} is required.` }));
        }
    };

    const saveQuestions = () => {
        const question = { ...addQuestionState };
        delete question.required;
        delete question.id;
        /* @ts-ignore */
        if (question.type !== null && question.type.toString() === "Text") {
            delete question.options;
        }
        const updateValidationState = Object.entries(question).map(([key, value]) =>
            value === null || value === ""
                ? { [key]: t("ONBOARDING_QUESTIONS_VALIDATION_ERRORS", { key }) }
                : { [key]: null }
        );
        const data = Object.assign({}, ...updateValidationState);
        const hasValidationErrors = Object.values(data).every(o => o === null);
        setValidateQuestionState(prevState => ({ ...prevState, ...data }));
        if (!hasValidationErrors) {
            return false;
        } else {
            /* @ts-ignore */
            const data = { ...addQuestionState, clientId: user.clientId };
            // Post request to add the questions
            /* @ts-ignore */
            handleQuestion(data);
            if (data.id === undefined) {
                /* @ts-ignore */
                setAddQuestionState(question);
            }
        }
    };

    const deleteQuestion = (questionId: number, demographicQuestionId: number | undefined) => {
        confirmAlert({
            title: t("ONBOARDING_QUESTIONS_DELETE_CONFIRM_HEADING"),
            message: t("ONBOARDING_QUESTTION_DELETE_MESSAGE"),
            buttons: [
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_YES"),
                    onClick: () => {
                        api.demographicQuestions.delete(questionId).then(response => {
                            accountOwnerDispatch(s => ({
                                ...s,
                                demographicQuestions: [
                                    /* @ts-ignore */
                                    ...s.demographicQuestions.filter(d => d.id !== questionId),
                                ],
                            }));
                        });
                        if (demographicQuestionId && !isNaN(demographicQuestionId)) {
                            //dispatch( push(`/account-owner/edit/organisation-planning/demographic-questions`));
                        }
                    },
                },
                {
                    label: t("ONBOARDING_QUESTTION_DELETE_NO"),
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    const error = validateQuestionState;
    const showOptions = addQuestionState?.type !== null && addQuestionState?.type?.toString() === "Text" ? false : true;
    return (
        <Fragment>
            {addQuestionState?.id && (
                <button
                    className="w-100 btn btn-link text-end mb-2"
                    /* @ts-ignore */
                    onClick={() => deleteQuestion(addQuestionState?.id, demographicQuestionId)}
                    type="button"
                >
                    <i className="fas fa-trash"></i> {t("COMMON_DELETE")}
                </button>
            )}
            <Form className="mt-2">
                <Form.Group className="mb-3">
                    <Form.Label className="form-label">{t("ORGANISATION_DETAILS_NAME")}</Form.Label>
                    <Form.Control
                        name="label"
                        type="text"
                        onChange={e => handleSetQuestionValues(e.target.value, "label")}
                        placeholder={t("ORGANISATION_DETAILS_NAME")}
                        value={addQuestionState?.label || ""}
                    />
                    {error && error["label"] && <DisplayInlineError error={error["label"]} />}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="form-label">{t("ONBOARDING_QUESTION_SELECTION_TYPE")}</Form.Label>
                    <Form.Control
                        className="form-select"
                        as="select"
                        defaultValue={addQuestionState?.type || ""}
                        onChange={e => handleSetQuestionValues(e.target.value, "type")}
                        name="type"
                    >
                        <option value="">{t("ONBOARDING_QUESTION_SELECT_SELECTION_TYPE")}</option>
                        {questionSelectionType &&
                            questionSelectionType.map((qt, i) => (
                                <option key={i} value={qt.type} selected={addQuestionState?.type === qt.type}>
                                    {qt.option}
                                </option>
                            ))}
                    </Form.Control>
                    {error && error["type"] && <DisplayInlineError error={error["type"]} />}
                </Form.Group>
                {showOptions && (
                    <Form.Group controlId="options">
                        <Form.Label className="form-label">{t("ONBOARDING_QUESTION_OPTION")}</Form.Label>
                        <textarea
                            value={addQuestionState?.options || ""}
                            onChange={e => handleSetQuestionValues(e.target.value, "options")}
                            className="form-control"
                            rows={3}
                            cols={10}
                            name="groupName"
                            placeholder={t("ONBOARDING_QUESTION_ENTER_OPTION")}
                        ></textarea>
                        {error && error["options"] && <DisplayInlineError error={error["options"]} />}
                    </Form.Group>
                )}
                <Form.Group controlId="isRequired" className="mb-3 mt-3">
                    <StyledCheckbox
                        checked={addQuestionState?.required}
                        onChange={checked => handleSetQuestionValues(checked, "required")}
                        label={t("ONBOARDING_QUESTION_REQUIRED")}
                    />
                </Form.Group>
                <ContentBlockButtonContainer>
                    <PrimaryButton onClick={() => saveQuestions()}>
                        {addQuestionState && addQuestionState?.id ? t("COMMON_UPDATE") : t("COMMON_SAVE")}
                    </PrimaryButton>
                </ContentBlockButtonContainer>
            </Form>
        </Fragment>
    );
};
