import { Group, User } from "@/api-client";
import { UserHasAccountOwnerRole, UserHasGroupLeaderRole } from "../common/user/utils";

export const hasImprovementBoardPermission = (currentUser: User, currentGroup: Group) => {
    if (currentGroup.taskVisibility === "All") {
        return true;
    }

    const isAO = UserHasAccountOwnerRole(currentUser);
    if (isAO) {
        return true;
    }

    const isMemberOfGroup = currentGroup.members!.find(x => x.userId === currentUser.id) !== undefined;
    return currentGroup.taskVisibility === "OnlyMembers" && isMemberOfGroup;
};

export const hasSetTeamMessagePermission = (user: User, team: Group) => {
    return UserHasGroupLeaderRole(user, team);
};

export const hasOrganizationSettingsPermission = (user: User) => {
    return UserHasAccountOwnerRole(user);
};
