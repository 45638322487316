import { useTranslate } from "@tolgee/react";
import { Chapter, ChapterStatuses } from "@/api-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import { ContentLabel } from "../../../components/ContentLabel";
import { Notify } from "../../../components/Notify";
import ReactMdeEditor from "../../../components/ReactMdeEditor";
import { AppLoader } from "../../../components/Spinner";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { CoursesLayout } from "../CoursesLayout";
import { useCurrentCourseFromParams } from "../helper";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { CardBlock } from "../../../components/CardBlock";
import { useParams } from "react-router-dom";

const validate = () => {
    return Yup.object().shape({
        name: Yup.string().required("Chapter Name is required"),
        //description: Yup.string()
        //.required("Description is required")
    });
};

interface AddChapterResponse {
    chapter: Chapter;
    isPending: boolean;
    isSuccess: boolean;
}

interface FormModel {
    chapterId: number;
    name: string;
    description: string;
    order: number;
    status: ChapterStatuses;
    navigationLabel: string;
}

type RouteParams = {
    courseId: string;
};

const AddChapter = (): JSX.Element => {
    const params = useParams<RouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId: number = parseInt(params.courseId);
    const [course, updateCourse] = useCurrentCourseFromParams();
    const api = createApi();
    const history = useHistory();

    const [addChapter, setAddChapter] = useState<AddChapterResponse>({
        //@ts-ignore
        isPending: null,
        //@ts-ignore
        chapter: null,
        isSuccess: false,
    });
    const [submitted, setSubmitted] = useState(false);

    const initialValues: FormModel = {
        chapterId: 0,
        name: "",
        description: "",
        status: "Active",
        order: 0,
        navigationLabel: "",
    };
    const [description, setDescription] = useState<string>("");
    const isLoading = addChapter.isPending ? true : false;
    const nav = CourseMenu(course);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: "Add Chapter", path: null },
    ];
    return (
        <>
            <CoursesLayout>
                {
                    //@ts-ignore
                    course && <Breadcrumbs breadCrumb={links} />
                }
                <div className="row">
                    <div className="col-md-4">
                        <CardBlock>
                            <Sidebar children={nav} name={course?.name} />
                        </CardBlock>
                    </div>
                    <div className="col-md-8">
                        <CardBlock mb="xl">
                            <AppLoader loading={isLoading ? true : false} className="position-absolute" />
                            {addChapter.isSuccess && submitted && (
                                <Fragment>
                                    <Notify message="Chapter added successfully." id="registersuccess" type="success" />
                                </Fragment>
                            )}

                            <ContentLabel text="Add Chapter" />
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validate()}
                                onSubmit={values => {
                                    setSubmitted(true);
                                    setAddChapter({ ...addChapter, isPending: true });
                                    api.chapters
                                        //@ts-ignore
                                        .post({
                                            name: values.name,
                                            description: values.description,
                                            status: values.status,
                                            order: values.order,
                                            courseId: courseId,
                                            navigationLabel: values.navigationLabel,
                                            createdAt: new Date(),
                                            updatedAt: new Date(),
                                        })
                                        .then(
                                            x => {
                                                //@ts-ignore
                                                const chapterId: number = x?.id;
                                                setAddChapter({
                                                    ...addChapter,
                                                    chapter: x,
                                                    isPending: false,
                                                    isSuccess: true,
                                                });
                                                //After success get the updated course for nav
                                                updateCourse(s => ({
                                                    ...s,
                                                    chapters: [...s.chapters, x],
                                                }));
                                                history.push(`/sysadmin/courses/${courseId}/chapter/${chapterId}/edit`);
                                            },
                                            error => {
                                                setAddChapter({ ...addChapter, isPending: false });
                                                history.push("/error");
                                                console.log(error);
                                            }
                                        );
                                }}
                            >
                                {formikProps => {
                                    const { errors, touched, setFieldValue } = formikProps;
                                    return (
                                        <Form name="addCHapterForm" className="mt-2 addCHapterForm">
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Name</label>
                                                    <Field
                                                        name="name"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (errors.name && touched.name ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="name"
                                                        component="span"
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Navigation Label</label>
                                                    <Field
                                                        name="navigationLabel"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (errors.navigationLabel && touched.navigationLabel
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="clearfix w-100 text-danger text-left displayblock"
                                                        name="name"
                                                        component="span"
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Description</label>

                                                    <ReactMdeEditor
                                                        onChange={value => {
                                                            setDescription(value);
                                                            setFieldValue("description", value);
                                                        }}
                                                        initialValue={description}
                                                    />
                                                    {/* <ErrorMessage className="clearfix w-100 text-danger text-left displayblock" name="description" component="span" /> */}
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <label>Status</label>
                                                    {/** Please move the option in const I think we can reuse this eiter we have to
                                                     * write it eveywhere
                                                     */}
                                                    <Field name="status" as="select" className="form-select">
                                                        <option key="1" value="Active">
                                                            Active
                                                        </option>
                                                        <option key="2" value="Inactive">
                                                            Inactive
                                                        </option>
                                                        <option key="3" value="Inprogress">
                                                            Inprogress
                                                        </option>
                                                        <option key="4" value="Deleted">
                                                            Deleted
                                                        </option>
                                                        <option key="5" value="Invited">
                                                            Invited
                                                        </option>
                                                        <option key="6" value="Disabled">
                                                            Disabled
                                                        </option>
                                                    </Field>
                                                </Col>
                                            </Row>
                                            <ContentBlockButtonContainer>
                                                <PrimaryButton type="submit" disabled={isLoading}>
                                                    Save
                                                </PrimaryButton>
                                            </ContentBlockButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardBlock>
                    </div>
                </div>
            </CoursesLayout>
        </>
    );
};

export default AddChapter;
