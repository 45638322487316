import React, { ReactElement } from "react";
import { useTranslate } from "@tolgee/react";
import { Box, Divider, Text, MantineStyleSystemProps } from "@mantine/core";

type SectionProps = {
    title: string;
    children: ReactElement | ReactElement[];
} & MantineStyleSystemProps;

export const Section = (allProps: SectionProps) => {
    const { title, children, ...boxProps } = allProps;
    const { t } = useTranslate();

    return (
        <Box {...boxProps}>
            <Divider mb="md" label={<Text size="lg">{t(title)}</Text>} />
            {children}
        </Box>
    );
};
