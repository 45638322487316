import React from "react";
import { useTranslate } from "@tolgee/react";
import { NavBarWithLinks } from "./Components/NavBarWithLinks";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { CardBlock } from "../../components/CardBlock";
import { useLocation } from "react-router-dom";

/* @ts-ignore */
export const EditOrganisationSidebar = () => {
    const groupTypes = useOrganisationContext().groupTypes;
    const { t } = useTranslate();
    const location = useLocation();

    const staticNavHeader = [
        {
            label: t("HEADER_NAV_ORGANISATION"),
            link: `/account-owner/edit/organisation-planning/organisation`,
            active: location.pathname.startsWith(`/account-owner/edit/organisation-planning/organisation`),
        },
    ];

    const staticNav = [
        {
            label: t("HEADER_NAV_EMPLOYEES"),
            link: `/account-owner/edit/organisation-planning/employees`,
            active:
                location.pathname.startsWith(`/account-owner/edit/organisation-planning/employees`) ||
                location.pathname.startsWith(`/account-owner/users/edit`),
        },
        {
            label: t("ONBOARDING_DEMOGRAPHIC_SUB_HEADING"),
            link: `/account-owner/edit/organisation-planning/demographic-questions`,
            active: location.pathname.startsWith(`/account-owner/edit/organisation-planning/demographic-questions`),
        },
        {
            label: "Rounds",
            link: `/account-owner/edit/organisation-planning/rounds`,
            active: location.pathname.startsWith(`/account-owner/edit/organisation-planning/rounds`),
        },
    ];

    const groupTypeNav =
        groupTypes?.map(gt => {
            return {
                label: gt.name,
                link: `/account-owner/edit/organisation-planning/group-type/${gt.id}`,
                active: location.pathname.startsWith(`/account-owner/edit/organisation-planning/group-type/${gt.id}`),
            };
        }) || [];

    const navigation = [...staticNavHeader, ...groupTypeNav, ...staticNav];

    return (
        <CardBlock>
            {/* @ts-ignore */}
            <NavBarWithLinks items={navigation} />
        </CardBlock>
    );
};
