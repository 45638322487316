﻿import { ColorPicker, ColorSwatch, Popover } from "@mantine/core";
import React from "react";

type ColorSelectProps = {
    value: string;
    onChange: (value: string) => void;
    palette: string[];
};

const getSwatchesPerRow = (numberOfColors: number) => {
    //Try to pick a row length with an even number of swatches per row
    const order = [5, 6, 7].map(x => x % numberOfColors).sort((a, b) => b - a);

    return order[0];
};

export const ColorSelect = (props: ColorSelectProps) => {
    return (
        <Popover position={"right-start"}>
            <Popover.Target>
                <ColorSwatch radius={"sm"} color={props.value} size={40} />
            </Popover.Target>
            <Popover.Dropdown>
                <ColorPicker
                    format={"hex"}
                    swatches={props.palette}
                    value={props.value}
                    onChange={value => props.onChange(value)}
                    swatchesPerRow={getSwatchesPerRow(props.palette.length)}
                />
            </Popover.Dropdown>
        </Popover>
    );
};
