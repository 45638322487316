import { Round } from "@/api-client";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { createStyles } from "@mantine/core";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

type CreateRoundProps = {
    handleSubmit: (values: Round) => void;
    clientId: number;
    editRound: Round | null;
};

const useStyles = createStyles(() => ({ margin: { marginBottom: "1.5rem" } }));

export const CreateRound = (props: CreateRoundProps) => {
    const { classes } = useStyles();

    const { handleSubmit, clientId, editRound } = props;

    const initialValues = {
        id: editRound ? editRound.id : undefined,
        name: editRound ? editRound.name : "",
        startDate: editRound ? editRound.startDate : new Date(),
        endDate: editRound ? editRound.endDate : new Date(),
        clientId: editRound ? editRound.clientId : clientId,
        createdAt: editRound ? editRound.createdAt : new Date(),
        updatedAt: editRound ? editRound.updatedAt : new Date(),
    };

    const formikValues = useMemo(
        () => ({
            initialValues,
            validationSchema: yup.object({
                name: yup.string().required("Round Name is required.").nullable(),
            }),
            /* @ts-ignore */
            onSubmit: values => {
                handleSubmit(values);
                formik.resetForm({ values: initialValues });
            },
            enableReinitialize: true,
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        [initialValues.id]
    );

    const formik = useFormik<Round>(formikValues);

    return (
        <Form onSubmit={formik.handleSubmit} id="create-round">
            <Col md={6} className={classes.margin}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control name="name" onChange={formik.handleChange} value={formik.values.name} />
                    {/* @ts-ignore */}
                    <DisplayInlineError error={formik.errors.name && formik.errors.name} />
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Group>
                        <Form.Control
                            type="date"
                            name="startDate"
                            onChange={e => {
                                formik.setFieldValue("startDate", e.target.value);
                                formik.handleChange(e);
                            }}
                            value={new Date(formik.values.startDate).toISOString().substring(0, 10)}
                        />
                    </Form.Group>
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>End Date</Form.Label>
                    <Form.Group>
                        <Form.Control
                            type="date"
                            name="endDate"
                            onChange={e => {
                                formik.setFieldValue("endDate", e.target.value);
                                formik.handleChange(e);
                            }}
                            value={new Date(formik.values.endDate).toISOString().substring(0, 10)}
                        />
                    </Form.Group>
                </Form.Group>
            </Col>
            <ContentBlockButtonContainer>
                <PrimaryButton type="submit">{editRound ? "Update Round" : "Create Round"}</PrimaryButton>
            </ContentBlockButtonContainer>
        </Form>
    );
};
