﻿import { getCourseContentFlat } from "../../workshop/Presentation/Presentation";
import { getWorkshopPresentationLink } from "../../workshop/helpers";
import React from "react";
import { Course } from "@/api-client";
import { NavButton } from "@/components";

export const PresentationShortcut = (props: { course: Course; groupId: number }): JSX.Element => {
    const flatCourse = getCourseContentFlat(props.course);
    const presentationLink =
        flatCourse && flatCourse[0]
            ? getWorkshopPresentationLink(props.groupId, flatCourse[0]?.chapter, flatCourse[0]?.module)
            : undefined;

    if (presentationLink === undefined) {
        return <></>;
    }

    return (
        <NavButton
            eventName={{ object: "program", action: "presentationclick" }}
            link={presentationLink}
            textKey={"PROGRAM_PRESENTATION"}
        />
    );
};
