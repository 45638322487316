import { createStyles } from "@mantine/core";

interface ScrollAreaProps {
    maxHeight?: number;
}

export const useStyles = createStyles((theme, { maxHeight }: ScrollAreaProps) => ({
    scrollArea: {
        height: maxHeight,
        overflow: "visible",
    },
    scrollbar: {
        '&[data-orientation="horizontal"]': {
            height: 0,
            width: 0,
        },
    },

    corner: {
        visibility: "hidden",
    },
}));
