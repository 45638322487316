import { useEffect, useMemo, useState } from "react";
import { Table as BTable } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useExpanded, useTable, TableOptions as TableOptionsT } from "react-table";
import { ColorSwatch } from "@mantine/core";
import { Icons } from "common/Icons";
import {
    createHierachicalGroupsForToggleByParentId,
    GroupsForToggleByParentId,
} from "../../OrganisationPlanning/helper";
import { Group } from "@/api-client";
import { LinkAction } from "../../../components/LinkAction";

/*  eslint-disable react-hooks/exhaustive-deps */

type ColumnOptions<T extends object> = Pick<TableOptionsT<T>, "columns">;

type TableOptionsProps<T extends object> = ColumnOptions<T> & {
    data: T[];
    showTableHeaders: boolean;
};

const TableOptions = <T extends object>(props: TableOptionsProps<T>) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: props.columns,
            data: props.data,
        },
        useExpanded // Use the useExpanded plugin hook
    );

    return (
        <BTable className="organisation-groups-table-details table-borderless" striped hover {...getTableProps()}>
            <thead>
                {props.showTableHeaders &&
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </BTable>
    );
};

type GroupsListingProps = {
    link?: string;
    buttonTitle?: string;
    groupId?: number;
    groups: Group[];
    showTableHeaders: boolean;
    groupTypeId?: number;
};

export const GroupsListing = (props: GroupsListingProps): JSX.Element => {
    const { t } = useTranslate();

    const [groupsTreeStructure, setGroupsTreeStructure] = useState<GroupsForToggleByParentId[]>([]);

    useEffect(() => {
        if (props.groupId !== undefined) {
            const currentGroup = props.groups?.find(f => f.id === props.groupId)!;
            const currentGroupChildren = props.groups?.filter(f => f.parentId === props.groupId);
            if (currentGroupChildren && currentGroupChildren.length > 0) {
                setGroupsTreeStructure(
                    createHierachicalGroupsForToggleByParentId([currentGroup, ...currentGroupChildren])
                );
            } else {
                setGroupsTreeStructure([]);
            }
        } else {
            setGroupsTreeStructure(createHierachicalGroupsForToggleByParentId(props.groups));
        }
    }, [props.groupId, props.groups]);

    let headerColumns: TableOptionsProps<GroupsForToggleByParentId>["columns"] = [
        {
            accessor: "color",
            Cell: ({ cell }) => <ColorSwatch color={cell.row.values["color"] as string} radius="sm" />,
        },
        {
            Header: t("COMMON_GROUPS"),
            accessor: "name",
            Cell: ({ row, cell }: any) => {
                if (row.canExpand) {
                    return (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            {...row.getToggleRowExpandedProps({ style: { paddingLeft: `${row.depth * 10}px` } })}
                        >
                            {cell.row.values.name}
                            {row.isExpanded ? (
                                <i className="organisation-report-expand fas fa-caret-down" />
                            ) : (
                                <i className="organisation-report-expand fas fa-caret-right" />
                            )}
                        </span>
                    );
                }

                return <span style={{ paddingLeft: `${row.depth * 10}px` }}>{cell.row.values.name}</span>;
            },
        },
        {
            Header: t("COMMON_EMPLOYEES"),
            accessor: "employees",
            Cell: ({ cell }) => cell.row.values["employees"],
        },
        {
            Header: t("ORGANISATION_GROUP_TYPES_ACCEPTED_INVITATIONS"),
            accessor: "acceptedInvitations",
            Cell: ({ cell }) => cell.row.values["acceptedInvitations"],
        },
        {
            Header: t("ORGANISATION_GROUP_STATUS_HEADER"),
            accessor: "status",
            Cell: ({ cell }) => cell.row.values["status"],
        },
    ];

    if (props.buttonTitle) {
        headerColumns = [
            ...headerColumns,
            {
                Header: "",
                accessor: "action",
                Cell: ({ cell }) =>
                    props.buttonTitle ? (
                        <LinkAction link={`${props.link!}/${cell.row.values["action"]}`}> {Icons.Edit()}</LinkAction>
                    ) : null,
            },
        ];
    }

    const columns = useMemo(() => headerColumns, []);

    return (
        <>
            <TableOptions columns={columns} data={groupsTreeStructure} showTableHeaders={props.showTableHeaders} />
            {groupsTreeStructure && groupsTreeStructure.length === 0 && (
                <p className="organisation-not-found text-center mb-5">{t("ORGANISATION_NO_GROUP_FOUND")}</p>
            )}
        </>
    );
};
