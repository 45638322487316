import * as Yup from "yup";
import { SelectItem } from "@mantine/core";
import { AppLoader, Form, FormInput, ModalConfig, ModalProps } from "@/components";
import { createApi, yupExtensions } from "@/common";

type Program = {
    name: string;
    id: number;
};

const validator = Yup.object({
    programId: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
});

type AssignProgramForm = Yup.InferType<typeof validator>;

type AssignProgramProps = {
    programs: Program[] | null;
    teamId: number;
};

const initialValues: AssignProgramForm = {
    programId: "",
};

const AssignProgram = (props: ModalProps<AssignProgramProps>) => {
    if (props.programs === null) {
        return <AppLoader loading />;
    }

    const handleSubmit = (values: AssignProgramForm) =>
        createApi()
            .groupCourses.post({
                courseId: parseInt(values.programId),
                groupId: props.teamId,
                hideUserInfoOnAnswers: false,
                isCompleted: false,
            })
            .then(() => props.onClose())
            .catchWithToastAsync();

    const selectItems: SelectItem[] = props.programs.map(x => ({ label: x.name, value: x.id.toString() }));

    return (
        <Form<AssignProgramForm>
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={handleSubmit}
            submitLabel="COMMON_SAVE"
            eventName={{ object: "ao_assignprogram", action: "save" }}
        >
            <FormInput.NativeSelect
                placeholder="ACCOUNTOWNER_EDIT_TEAMS_SELECT_PROGRAM"
                fieldName="programId"
                label="ACCOUNTOWNER_EDIT_TEAMS_PROGRAM"
                options={selectItems}
            />
        </Form>
    );
};

export const AssignProgramModal: ModalConfig<AssignProgramProps> = {
    content: AssignProgram,
    title: "ACCOUNTOWNER_EDIT_TEAMS_ASSIGN_PROGRAM",
};
