import React from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { AdditionalResource } from "@/api-client";

interface AdditionalResourcesProps {
    resources: AdditionalResource[];
}

export const AdditionalResources = (props: AdditionalResourcesProps): JSX.Element => {
    const { t } = useTranslate();
    const resources = props.resources?.filter(a => a.url !== null && a.url !== "");
    const getIconsForAdditionalResources = (value: string): JSX.Element => {
        const extension = value.split(".").pop();
        switch (extension) {
            case "pdf":
                return <i className="fas fa-file-pdf" />;
            case "png":
            case "jpg":
                return <i className="fas fa-image" />;
            case "docx":
            case "doc":
                return <i className="fas fa-file-word" />;
            case "xlsx":
                return <i className="fas fa-file-excel" />;
            default:
                return <i className="fas fa-file-word" />;
        }
    };
    return (
        <>
            {/* <p className="largeFontBold">{name}</p> */}
            {resources && resources.length > 0 && (
                <div className="relatedMaterial mt-5">
                    <h2>{t("MODULE_RELATED_MATERIAL")}</h2>
                    <ul>
                        {resources.map((resource, index: number) => {
                            // @ts-ignore
                            const icons = getIconsForAdditionalResources(resource.url);
                            return (
                                <li key={index} className="addtContent">
                                    <Link className="materialLinks" target="_blank" to={resource.url ?? ""}>
                                        {icons} {resource.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

export const Resources = React.memo(AdditionalResources);
