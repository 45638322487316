import React, { useRef, useState } from "react";
import { Module } from "@/api-client";
import { useDrag, useDrop } from "react-dnd";
import { createApi } from "../../../common/api";
import { showToast } from "../../../components/Notify";
import { useSysadminContextDispatch } from "../../SysadminContext";
import { CopyToDialog, CopyToDialogProps, LevelEnum } from "../CopyToDialog";
import { LinkAction } from "../../../components/LinkAction";
import { Icons } from "common/Icons";
import { CopyAction } from "../CopyAction";

export const ItemType = {
    CARD: "chapter",
};

export const ModuleCard = (props: {
    module: Module;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    courseId: number;
    chapterId: number;
    id: number;
    //cloneModule: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, module: Module) => void;
}) => {
    const [showCopyDialog, setShowCopyDialog] = useState<boolean>();

    const ref = useRef(null);
    //@ts-ignore
    const [{ handlerId }, drop] = useDrop({
        accept: ItemType.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        //@ts-ignore
        hover(item: { id: number; index: number }, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }

            //@ts-ignore
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            //@ts-ignore
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemType.CARD,
        item: () => {
            return {
                id: props.id,
                index: props.index,
            };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const containerClass = isDragging ? "dragging-container" : "listing-container";
    drag(drop(ref));

    const api = createApi();
    const sysadminContextDispatch = useSysadminContextDispatch();

    const copyToProps: CopyToDialogProps = {
        initialSelection: {
            courseId: props.courseId,
            chapterId: props.chapterId,
        },
        level: LevelEnum.Chapter,
        onSelected: data => {
            // Clone chapter and set Id to zero so we create new instead of updating existing
            //@ts-ignore
            const moduleClone: Module = { ...props.module, id: 0, chapterId: data.chapter.id };

            // // Update local state
            api.modules.post(moduleClone).then(
                x => {
                    sysadminContextDispatch(s => ({
                        ...s,
                        //@ts-ignore
                        courses: s.courses.map(c => {
                            if (c.id === data.course.id) {
                                return {
                                    ...c,
                                    chapters: c.chapters.map(ch => {
                                        if (ch.id === x.chapterId) {
                                            return {
                                                ...ch,
                                                modules: [...ch.modules, x],
                                            };
                                        } else {
                                            return ch;
                                        }
                                    }),
                                };
                            } else {
                                return c;
                            }
                        }),
                    }));

                    //@ts-ignore
                    showToast(`Module successfully copied to ${data.chapter.name}`, "success");
                },
                error => {
                    console.log(error);
                }
            );

            // Hide modal
            setShowCopyDialog(false);
        },
        //@ts-ignore
        show: showCopyDialog,
        onClose: () => setShowCopyDialog(false),
    };

    return (
        <>
            <tr ref={ref} className={containerClass} data-handler-id={handlerId}>
                <td className="whitespace-nowrap">{props.index + 1}.</td>
                <td>{props.module?.name}</td>
                <td className="whitespace-nowrap">{props.module.status}</td>
                <td className="whitespace-nowrap col-1">
                    <LinkAction
                        link={`/sysadmin/courses/${props.courseId}/chapter/${props.chapterId}/module/${props.module?.id}/edit`}
                    >
                        {Icons.Edit()}
                    </LinkAction>
                </td>
                <td className="whitespace-nowrap col-1">
                    <CopyAction onClick={() => setShowCopyDialog(true)} />
                </td>
            </tr>
            {showCopyDialog && <CopyToDialog {...copyToProps} />}
        </>
    );
};
