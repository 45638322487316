import { useState, useEffect } from "react";
import { randomId } from "@mantine/hooks";
import { useTranslate } from "@tolgee/react";
import { useModals } from "@mantine/modals";
import { createApi, useCurrentClient } from "@/common";
import { TeamsDto, TeamTypeDto } from "@/api-client";
import { TeamsList } from "./TeamsList";
import { addTeamModal } from "./Add/AddTeamModal";

export const TeamsPage = () => {
    const [teams, setTeams] = useState<TeamsDto[] | null>(null);
    const { t } = useTranslate();
    const [teamTypes, setTeamTypes] = useState<TeamTypeDto[]>([]);
    const modals = useModals();

    const clientId = useCurrentClient().id;
    const api = createApi();

    const updateTeams = (clientId: number) => {
        return api.accountOwners.getTeams(clientId).then(setTeams);
    };

    useEffect(() => {
        updateTeams(clientId).catchWithToast();
        api.groupTypes
            .query(clientId)
            .then(t => setTeamTypes(t.filter(t => t.status === "Active").map(t => ({ name: t.name!, id: t.id! }))))
            .catchWithToast();
    }, []);

    const onSaveTeam = (modalId: string) => {
        modals.closeModal(modalId);
        updateTeams(clientId).catchWithToast();
    };

    const openAddTeamModal = () => {
        const modalId = randomId();

        modals.openModal(
            addTeamModal(t, {
                modalId: modalId,
                teamTypes: teamTypes,
                clientId: clientId,
                usedColors: teams!.map(t => t.color),
                onSave: onSaveTeam,
            })
        );
    };

    return <TeamsList teams={teams} onAddTeamClicked={openAddTeamModal} />;
};
