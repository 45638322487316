﻿import React from "react";
import { NavButton } from "@/components";

export const AssessmentShortcut = (props: { groupId: number }) => {
    return (
        <NavButton
            eventName={{ object: "program", action: "assessmentclick" }}
            link={`/groups/${props.groupId}/transformation`}
            textKey={"PROGRAM_ASSESSMENT"}
        />
    );
};
