import React from "react";

interface RenderIfProps {
    show: boolean;
    children: React.ReactNode;
}

const RenderIf = (props: RenderIfProps): JSX.Element | null => {
    const { show, children } = props;
    if (show) {
        return <>{children}</>;
    }
    return null;
};

export default RenderIf;
