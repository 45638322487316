import { useEffect, useState } from "react";
import { UsersList } from "./UsersList";
import { createApi, useCurrentClient } from "@/common";
import { UserDto } from "@/api-client";

export const UsersPage = () => {
    const [users, setUsers] = useState<UserDto[] | null>(null);
    const clientId = useCurrentClient().id;

    const reload = () => {
        const api = createApi();
        api.accountOwners.getUsers(clientId).then(setUsers).catchWithToast();
    };

    useEffect(() => {
        reload();
    }, []);

    return <UsersList users={users} reloadUsers={reload} />;
};
