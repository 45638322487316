import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import "./style.scss";
import { PrimaryButton } from "../../../../../components/Button/PrimaryButton";

type DemoGraphicQuestionProps = {
    /* @ts-ignore */
    showQuestionsScreen: (value) => void;
};
export const NoDemographicQuestions = (props: DemoGraphicQuestionProps) => {
    const { t } = useTranslate();
    return (
        <Row className="no-demograpic-questions bg mb-4">
            <Col md={8} className="text-center content mx-auto">
                <p>{t("ONBOARDING_DEMOGRAPHIC_NO_QUESTIONS_FOUND")}</p>
                <PrimaryButton onClick={() => props.showQuestionsScreen(true)}>
                    {t("ONBOARDING_ADD_DEMOGRAPHIC_QUESTIONS")}
                </PrimaryButton>
            </Col>
        </Row>
    );
};
