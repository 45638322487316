import { Avatar, Flex, MantineSize, Text } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { FallBackUserImage } from "../../../components/UserAvatar/FallbackUserImage";
import { useUserCardStyles } from "./useUserCardStyles";

type UserCardProps = {
    image: string | null;
    fullName: string | null;
    userId: number;
    phoneNumber: string | null;
    email: string | null;
    hasAcceptedInvite: boolean;
    isTeamLeader: boolean;
};

const TeamLeaderText = (props: { isTeamLeader: boolean }) => {
    const { t } = useTranslate();

    if (!props.isTeamLeader) {
        return null;
    }

    return (
        <Text size="xs" align="center" color="#25262B">
            {t("HOME_TEAM_LEADER")}
        </Text>
    );
};

const Card = (props: UserCardProps) => {
    const { classes } = useUserCardStyles();
    const { t } = useTranslate();
    const avatarSize: MantineSize = "lg";

    if (!props.hasAcceptedInvite) {
        return (
            <>
                <Avatar
                    size={avatarSize}
                    className={classes.image}
                    color="gray.5"
                    classNames={{ placeholderIcon: classes.placeHolder }}
                />

                <div>
                    <Text size="sm" color="gray" align="center">
                        {props.email ?? props.phoneNumber ?? "-"}
                    </Text>
                    <Text size="xs" color="gray" align="center" italic>
                        {t("COMMON_INVITED")}
                    </Text>
                </div>
            </>
        );
    }

    return (
        <>
            <Avatar src={props.image} className={classes.image} size={avatarSize}>
                <FallBackUserImage userId={props.userId} fullName={props.fullName ?? undefined} />
            </Avatar>

            <div>
                <Text size="sm" align="center">
                    {props.fullName}
                </Text>
                <TeamLeaderText isTeamLeader={props.isTeamLeader} />
            </div>
        </>
    );
};

export const UserCard = (props: UserCardProps) => (
    <Flex direction="column" align="center" gap="xs">
        <Card
            userId={props.userId}
            fullName={props.fullName}
            hasAcceptedInvite={props.hasAcceptedInvite}
            isTeamLeader={props.isTeamLeader}
            image={props.image}
            email={props.email}
            phoneNumber={props.phoneNumber}
        />
    </Flex>
);
