﻿import { Container, Flex } from "@mantine/core";
import React from "react";
import { UrlFactory } from "../../routing/UrlFactory";
import { Breadcrumb } from "./BreadCrumb";
import { useTranslate } from "@tolgee/react";

type ProgramChildProps = {
    teamId: number;
    breadCrumbTitleKey: string;
    children: React.ReactNode;
    useFullWidth?: boolean;
};

export const ProgramChild = (props: ProgramChildProps) => {
    const { t } = useTranslate();

    const linkItems = [
        { title: t("HEADER_NAV_PROGRAM"), href: UrlFactory.team.program.create({ groupId: props.teamId }) },
        { title: t(props.breadCrumbTitleKey), href: "#" },
    ];

    return (
        <Container size="xl" fluid={props.useFullWidth} pl={"xs"}>
            <Flex mb={"xs"} fz={"xl"}>
                <Breadcrumb items={linkItems} />
            </Flex>

            {props.children}
        </Container>
    );
};
