﻿import { Flex } from "@mantine/core";
import React from "react";
import { PresentationShortcut } from "./PresentationShortcut";
import RenderIf from "../../components/render-if/render-if";
import { MyTrainingShortcut } from "./MyTrainingShortcut";
import { CategorizeShortcut } from "./CategorizeShortcut";
import { ImplementationShortcut } from "./ImplementationShortcut";
import { AssessmentShortcut } from "./AssessmentShortcut";
import { Course, Group, ParticipantProgress, User } from "@/api-client";
import { UserHasGroupLeaderRole, UserHasParticipantRole } from "../../common/user/utils";
import { exhaustiveCheck } from "../../common/exhaustiveCheck";

const NavigationLinksInner = ({
    user,
    course,
    group,
    userProgress,
}: {
    user: User;
    course: Course;
    group: Group;
    userProgress: ParticipantProgress[];
}) => {
    const isTeamMember = UserHasParticipantRole(user, group);
    const isTeamLeader = UserHasGroupLeaderRole(user, group);
    const groupId = group.id!;

    switch (course.courseType) {
        case "Training":
            return (
                <>
                    <PresentationShortcut groupId={groupId} course={course} />
                    <RenderIf show={isTeamMember}>
                        <MyTrainingShortcut group={group} course={course} user={user} userProgress={userProgress} />
                    </RenderIf>
                    <RenderIf show={isTeamLeader}>
                        <CategorizeShortcut groupId={groupId} />
                    </RenderIf>
                </>
            );
        case "ImplementationCourse":
            return (
                <>
                    <RenderIf show={isTeamMember}>
                        <MyTrainingShortcut group={group} course={course} user={user} userProgress={userProgress} />
                    </RenderIf>
                    <ImplementationShortcut groupId={groupId} />
                </>
            );
        case "AssessmentCourse":
            return (
                <>
                    <PresentationShortcut groupId={groupId} course={course} />
                    <RenderIf show={isTeamMember}>
                        <MyTrainingShortcut group={group} course={course} user={user} userProgress={userProgress} />
                    </RenderIf>
                    <AssessmentShortcut groupId={groupId} />
                </>
            );
        default:
            return exhaustiveCheck(course.courseType);
    }
};

export const NavigationLinks = ({
    group,
    user,
    course,
    userProgress,
}: {
    group: Group;
    user: User;
    course: Course;
    userProgress: ParticipantProgress[];
}) => (
    <Flex gap="md" direction="row" my="lg">
        <NavigationLinksInner group={group} user={user} course={course} userProgress={userProgress} />
    </Flex>
);
