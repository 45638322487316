import { Badge } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { TeamStatus } from "@/api-client";
import { translateTeamStatus } from "../../../sysadmin/helper";

export const TeamStatusBadge = (props: { status: TeamStatus }) => {
    const { t } = useTranslate();
    const translatedStatus = translateTeamStatus(t)[props.status as never];

    if (props.status === "Active") {
        return <Badge color="green">{translatedStatus}</Badge>;
    }

    return <Badge>{translatedStatus}</Badge>;
};
