import React from "react";
import { Container } from "react-bootstrap";
import CourseSelectorPage from "../../components/CourseSelector/CourseSelectorPage";

const CourseSelect = () => {
    return (
        <Container>
            <CourseSelectorPage />
        </Container>
    );
};

export default CourseSelect;
