import * as React from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMarkdown from "react-markdown";

interface ReactMdeEditorProps {
    onChange: (value: string) => void;
    initialValue: string;
}

export default function ReactMdeEditor(props: ReactMdeEditorProps) {
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");
    let commands = ["bold", "italic", "link", "quote", "ordered-list", "unordered-list", "checked-list"];

    return (
        <ReactMde
            toolbarCommands={[commands]}
            value={props.initialValue}
            onChange={value => props.onChange(value)}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown children={markdown} />)}
            childProps={{
                writeButton: {
                    tabIndex: -1,
                },
            }}
        />
    );
}
