import { randomId } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { ModalSettings } from "@mantine/modals/lib/context";
import { FC, useMemo, ReactNode } from "react";
import { useTranslate } from "@tolgee/react";

export type ModalConfig<T> = {
    title: string | ReactNode;
    content: FC<ModalProps<T>>;
    mantineModalProps?: ModalSettings;
};

export type ModalProps<T> = T & {
    onClose: () => void;
};

export const useModal = <T extends object>(
    modalSettings: ModalConfig<T>
): {
    open: (props: Omit<ModalProps<T>, "onClose"> & { onClose?: () => void }) => void;
} => {
    const modals = useModals();
    const { t } = useTranslate();
    const getTitle = () => {
        if (typeof modalSettings.title === "string") {
            return t(modalSettings.title);
        }
        return modalSettings.title;
    };

    return useMemo(() => {
        const open = ({ onClose, ...props }: Omit<T, "onClose"> & { onClose?: () => void }) => {
            const modalId = randomId();

            const close = () => {
                modals.closeModal(modalId);
                if (onClose) {
                    onClose();
                }
            };

            modals.openModal({
                modalId: modalId,
                title: getTitle(),
                // @ts-ignore
                children: <modalSettings.content {...props} onClose={close} />,
                size: "xl",
                closeOnClickOutside: false,
                closeOnEscape: true,
                ...(modalSettings.mantineModalProps === undefined ? {} : modalSettings.mantineModalProps),
            });
        };

        return { open };
    }, [modals]);
};
