import { Group, User, UserRoles } from "@/api-client";
import { ACCOUNT_OWNER_ROLE, SYSADMIN_ROLE } from "../../constants";

/**
 * True if the user is a participant in the group
 * @param user
 * @param group
 */
export const UserHasParticipantRole = (user: User, group: Group): boolean => {
    if (!user) {
        //console.warn('Undefined user in UserHasParticipantRole');
        return false;
    }

    if (!group) {
        //console.warn('Undefined group in UserHasParticipantRole');
        return false;
    }

    /* @ts-ignore */
    return group.members.findIndex(x => x.isParticipant && x.user.id === user.id) !== -1;
};

/**
 * True if the user is group leader in the group
 * @param user
 * @param group
 */
export const UserHasGroupLeaderRole = (user: User, group: Group): boolean => {
    // Account owners always have group leader access
    if (UserHasAccountOwnerRole(user)) {
        return true;
    }

    // Account owners always have group leader access
    if (UserHasAccountOwnerRole(user)) {
        return true;
    }

    if (!user) {
        console.warn("Undefined user in UserHasGroupLeaderRole");
        return false;
    }

    if (!group) {
        console.warn("Undefined group in UserHasGroupLeaderRole");
        return false;
    }

    /* @ts-ignore */
    return group.members.findIndex(x => x.isGroupLeader && x.user.id === user.id) !== -1;
};

/**
 * True if the user is group leader in the group
 * @param user
 * @param group
 */
export const UserHasAccountOwnerRole = (user: User): boolean => {
    if (!user) {
        console.error("Undefined user in UserHasAccountOwnerRole");
        return false;
    }

    if (!user.roles) {
        console.error("Null or undefined user.roles in UserHasAccountOwnerRole");
        return false;
    }
    return user?.roles?.indexOf(ACCOUNT_OWNER_ROLE) !== -1 ? true : false;
};

/**
 * True if the user is sysadmin
 * @param user
 */
export const UserHasSysAdminRole = (user: User): boolean => {
    if (!user) {
        console.error("Undefined user in UserHasAccountOwnerRole");
        return false;
    }
    if (!user.roles) {
        console.error("Null or undefined user.roles in UserHasAccountOwnerRole");
        return false;
    }
    const result = user?.roles?.indexOf(SYSADMIN_ROLE) !== -1 ? true : false;
    return result;
};

/**
 * True if user as any of the supplied roles
 * @param user
 * @param roles
 * @returns
 */
export const UserHasOneOfRoles = (user: User, roles: UserRoles[]): boolean => {
    if (!user) {
        return false;
    }
    if (!user.roles) {
        return false;
    }

    // Intersect user roles and needed roles
    const roleIntersection = roles.filter(value => user.roles.includes(value));

    // Authorized if user have one or more of the required roles
    return roleIntersection.length > 0;
};

export const UserHasSpecificRole = (user: User, role: string): boolean => {
    if (!user) {
        return false;
    }
    if (!user.roles) {
        return false;
    }

    return user.roles.find(r => r === role) ? true : false;
};

export const IsAdminUser = (user: User) => {
    return UserHasOneOfRoles(user, ["Admin", "Agent", "Expert"]);
};
