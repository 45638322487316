﻿import { Menu, ActionIcon } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { Icons } from "@/common";
import { WithChildren } from "../../common/StateWrapper";

export const ContextMenuButton = (props: { onClick: () => void; label: string }) => {
    const { t } = useTranslate();

    return <Menu.Item onClick={props.onClick}>{t(props.label)}</Menu.Item>;
};

export const ContextMenuLink = (props: { to: string; label: string }) => {
    const { t } = useTranslate();

    return (
        <Menu.Item component={Link} to={props.to}>
            {t(props.label)}
        </Menu.Item>
    );
};

export const ContextMenu = (props: WithChildren) => {
    return (
        <Menu withinPortal>
            <Menu.Target>
                <ActionIcon>
                    <Icons.ContextMenu />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>{props.children}</Menu.Dropdown>
        </Menu>
    );
};
