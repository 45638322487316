import React, { ReactElement, useMemo, useState } from "react";
import { Droppable } from "@hello-pangea/dnd";
import { ActionIcon, ScrollArea as MantineScrollArea, Transition } from "@mantine/core";
import { useBoardContext } from "../../BoardContext";
import { TaskCard } from "../TaskCard/TaskCard";
import { CreateCard } from "../TaskCard/CreateCard";
import "./CardContainer.scss";
import { Icons } from "../../../../common/Icons";
import { DroppableId, serializeDroppableId } from "../../dragAndDrop";
import { ImprovementBoardTask, TaskCategory } from "@/api-client";
import { useStyles } from "./cardContainerStyles";

export const sortTasks = (taskList: ImprovementBoardTask[]) => {
    // @ts-ignore
    return taskList.sort((a, b) => a.sortIndex - b.sortIndex);
};

interface CardContainerProps {
    tasks: ImprovementBoardTask[];
    defaultNewTask: ImprovementBoardTask;
    allowAdd?: boolean;
    categories: TaskCategory[];
    droppableId: DroppableId;
    maxHeight?: number;
}

const ConditionalScrollArea = (props: { maxHeight?: number; children: ReactElement }) => {
    const { classes } = useStyles({ maxHeight: props.maxHeight });

    if (!props.maxHeight) {
        return props.children;
    }

    return (
        <MantineScrollArea
            classNames={{
                root: classes.scrollArea,
                corner: classes.corner,
                scrollbar: classes.scrollbar,
            }}
        >
            {props.children}
        </MantineScrollArea>
    );
};

export const CardContainer = React.memo((props: CardContainerProps) => {
    const boardContext = useBoardContext();
    const tasks = useMemo(() => sortTasks(props.tasks), [props.tasks]);
    const [showAddCard, setShowAddCard] = useState<boolean>(false);

    const saveTask = async (task: ImprovementBoardTask) => {
        if (task.title === "" || task.title === null) {
            setShowAddCard(false);
        } else {
            setShowAddCard(false);
            await boardContext.addTask(task);
        }
    };

    return (
        <div className="card-container">
            <ConditionalScrollArea maxHeight={props.maxHeight}>
                <Droppable droppableId={serializeDroppableId(props.droppableId)}>
                    {provided => (
                        <div ref={provided.innerRef} className={`card-grid`} {...provided.droppableProps}>
                            {tasks.map((t, ix) => (
                                <TaskCard
                                    key={`t${t.id}`}
                                    index={ix}
                                    task={t}
                                    // @ts-ignore
                                    category={props.categories?.find(x => x.id === t.taskCategoryId)}
                                />
                            ))}
                            {provided.placeholder}

                            <Transition mounted={showAddCard} transition={"scale"}>
                                {styles => {
                                    return (
                                        <div style={styles}>
                                            <CreateCard
                                                onMouseOut={title =>
                                                    saveTask({ ...props.defaultNewTask, title: title })
                                                }
                                                onPressEnter={title =>
                                                    saveTask({ ...props.defaultNewTask, title: title })
                                                }
                                            />
                                        </div>
                                    );
                                }}
                            </Transition>
                        </div>
                    )}
                </Droppable>
            </ConditionalScrollArea>
            <div className="btn-container">
                <ActionIcon className="btn-add" onClick={() => setShowAddCard(true)}>
                    <Icons.Add />
                </ActionIcon>
            </div>
        </div>
    );
});
