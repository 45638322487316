import { Client } from "@/api-client";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";
import { createApi } from "../../../common/api";
import { UserHasSpecificRole } from "../../../common/user/utils";
import { ContentLabel } from "../../../components/ContentLabel";
import { showToast } from "../../../components/Notify";
import RenderIf from "../../../components/render-if/render-if";
import { AppLoader } from "../../../components/Spinner";
import { ACCOUNT_OWNER_ROLE } from "../../../constants";
import { Select } from "@mantine/core";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { CardBlock } from "../../../components/CardBlock";
import "../style.scss";

/*  eslint-disable react-hooks/exhaustive-deps */

const validate = () => {
    return Yup.object().shape({
        email: Yup.string().email("Please enter valid email").required("Please enter email").nullable(),
    });
};

type FetchClientManagersResponse = {
    isPending: boolean;
    managers: any;
};

export const AddAccountOwner = (props: { clientId: number }) => {
    const [managersResponse, setManagersResponse] = useState<FetchClientManagersResponse>({
        isPending: false,
        managers: null,
    });
    // @ts-ignore
    const [searchUsers, setSearchUsers] = useState<{ email: string }[]>(null);
    const api = createApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchClientManagers = () => {
        setManagersResponse({ ...managersResponse, isPending: true });
        api.accountOwners.query(props.clientId).then(
            response => {
                setManagersResponse({ ...managersResponse, managers: response, isPending: false });
            },
            error => {
                setManagersResponse({ ...managersResponse, isPending: false });
                console.log(error);
            }
        );
    };

    const getAllUsers = () => {
        api.users.query(null, null, null, null).then(
            response => {
                const users = response
                    .filter(u => !UserHasSpecificRole(u, ACCOUNT_OWNER_ROLE) && u.clientId === props.clientId)
                    .map(u => ({ email: u.email }));
                // @ts-ignore
                setSearchUsers(users);
            },
            error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        fetchClientManagers();
        getAllUsers();
    }, []);

    let initialValues = {
        email: null,
    };

    // @ts-ignore
    const assignAccountOwnerToClient = (values: any, resetForm: () => void) => {
        setIsLoading(true);

        api.accountOwners.addAccountOwner(props.clientId, values.email).then(
            result => {
                if (result === "ok") {
                    resetForm();
                    fetchClientManagers();
                } else {
                    showToast("User dont belong to this client.", "warning");
                }

                setIsLoading(false);
            },
            error => {
                setIsLoading(false);
                showToast("Something going wrong. Please try again later.", "warning");
                console.log(error);
            }
        );
    };

    return (
        <Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate()}
                onSubmit={(values, { resetForm }) => {
                    assignAccountOwnerToClient(values, resetForm);
                }}
            >
                {formikProps => {
                    let { errors, touched, setFieldValue, values } = formikProps;

                    const options = searchUsers
                        ? searchUsers
                              .filter(x => x.email !== null)
                              .map((m, i) => ({
                                  value: i as unknown as string,
                                  label: m.email,
                              }))
                        : [];

                    return (
                        <Form autoComplete="off" action="#" name="addAccountOwner" className="mt-3 mb-3">
                            <Row className="mb-4">
                                <Col md={5} className="autocomplete">
                                    <label className="form-label">Enter Email</label>
                                    <Select
                                        id="email_ao"
                                        className="email-select"
                                        searchable
                                        value={options.findIndex(x => x.label === values.email) as unknown as string}
                                        creatable
                                        getCreateLabel={query => `Add email: ${query}`}
                                        onCreate={query => {
                                            const item = { value: options.length as unknown as string, label: query };
                                            const newUsers = [...searchUsers, { email: query }];
                                            setSearchUsers(newUsers);
                                            return item;
                                        }}
                                        placeholder="Select or Enter Email"
                                        onChange={(item: any) => {
                                            setFieldValue("email", options[item].label);
                                        }}
                                        error={errors.email && touched.email}
                                        data={options}
                                    ></Select>
                                    {errors.email && (
                                        <span className="mt-3 clearfix w-100 text-danger text-left displayblock">
                                            {" "}
                                            {errors.email}
                                        </span>
                                    )}
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <PrimaryButton type="submit" disabled={isLoading}>
                                        Invite Account Owner
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
            <AccountOwnersList clientManagerResponse={managersResponse} />
        </Fragment>
    );
};

type AssignAccountOwnerProps = {
    clientId: number;
    client: Client;
};

const AccountOwnersList = (props: { clientManagerResponse: FetchClientManagersResponse }) => {
    const { isPending } = props.clientManagerResponse;
    const [managers, setManagers] = useState<any[]>(props.clientManagerResponse.managers);
    const api = createApi();

    useEffect(() => {
        if (props.clientManagerResponse.managers) {
            setManagers(props.clientManagerResponse.managers);
        }
    }, [props.clientManagerResponse]);

    const removeAccountOwner = (userRoleId: number, fullName: string, email: string) => {
        const title =
            fullName !== " "
                ? `Are you sure to remove "${fullName}" from the list of account owners?`
                : `Are you sure to remove "${email}" from the list of account owners?`;
        confirmAlert({
            title: title,
            message: "This action cannot be undone",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        api.accountOwners.delete(userRoleId).then(
                            response => {
                                setManagers(managers.filter(m => m.userRoleId !== userRoleId));
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No pressed."),
                },
            ],
        });
    };

    return (
        <Fragment>
            <AppLoader loading={isPending} className="position-absolute" />
            <table className="table table-borderless table-striped list-client-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <RenderIf show={isPending === false && (managers === null || managers.length === 0)}>
                        <tr>
                            <th colSpan={4} className="text-center">
                                Please assign Account Owner.
                            </th>
                        </tr>
                    </RenderIf>
                    <RenderIf show={managers !== null && managers && managers.length > 0}>
                        {managers &&
                            managers.map((m, i) => (
                                <tr key={i}>
                                    {m.user.fullName !== " " ? (
                                        <td>{m.user.fullName}</td>
                                    ) : (
                                        <td>&ndash; &ndash; &ndash;</td>
                                    )}
                                    <td>{m.user.email}</td>
                                    <td>{m.user.status}</td>
                                    <td>
                                        <button
                                            onClick={() =>
                                                removeAccountOwner(m.userRoleId, m.user.fullName, m.user.email)
                                            }
                                            className="client_btn"
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </RenderIf>
                </tbody>
            </table>
        </Fragment>
    );
};

const AssignAccountOwner = (props: AssignAccountOwnerProps) => {
    return (
        <Row>
            <div className="col-md-12">
                <CardBlock mb="xl">
                    <ContentLabel text="Assign Account Owner" />
                    <p>
                        The "Account Owner" is the client's single representative on the platform, and has full access
                        to invite participants to groups and assign available courses.
                    </p>
                    <AddAccountOwner clientId={props.clientId} />
                </CardBlock>
            </div>
        </Row>
    );
};

export default AssignAccountOwner;
