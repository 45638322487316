import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslate } from "@tolgee/react";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import { TFunction } from "@/common";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { showToast } from "../../../components/Notify";
import { PostUserDto, User } from "@/api-client";
import { PrimaryButton } from "@/components";
import { useAuth } from "../../../auth/useAuth";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

const validate = (t: TFunction, initialValues: FormModel) =>
    Yup.object().shape({
        firstName: Yup.string()
            .min(2, t("PROFILE_FIRST_NAME_MIN_LENGTH"))
            .max(40, t("PROFILE_FIRST_NAME_MAX_LENGTH"))
            .required(t("PROFILE_FIRST_NAME_REQUIRED")),

        lastName: Yup.string()
            .min(2, t("PROFILE_LAST_NAME_MIN_LENGTH"))
            .max(40, t("PROFILE_LAST_NAME_MAX_LENGTH"))
            .required(t("PROFILE_LAST_NAME_REQUIRED")),

        email: Yup.string()
            .email(t("PROFILE_INVALID_EMAIL"))
            .test("Ensure unique email", "", async (value, context) => {
                if (value === initialValues.email) {
                    return true;
                }

                const api = createApi();

                const user: PostUserDto = {
                    email: value,
                    status: "Active",
                    hasAcceptedInvite: true,
                    roles: [],
                    lastName: null,
                    firstName: null,
                };

                const validatedUser = await api.users.validate(user);

                if (!validatedUser.isValid) {
                    // @ts-ignore
                    return context.createError({ message: t(validatedUser.errorMessage) });
                }

                return true;
            }),

        mobilePhone: Yup.string()
            .nullable()
            .test("Ensure unique phone number", "", async (value, context) => {
                //check for undefined here so api not return an error, since phone number is optional
                if (value === undefined || value === initialValues.mobilePhone) {
                    return true;
                }

                const api = createApi();

                const user: User = {
                    // @ts-ignore
                    mobilePhone: value,
                    status: "Active",
                    hasAcceptedInvite: true,
                };

                const validatedUser = await api.users.validate(user);
                if (!validatedUser.isValid) {
                    // @ts-ignore
                    return context.createError({ message: t(validatedUser.errorMessage) });
                }

                return true;
            }),
    });

interface Props {
    userProfile: User;
}

interface FormModel {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
}

const ProfileForm = ({ userProfile }: Props) => {
    const { t } = useTranslate();
    const api = createApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { refresh } = useAuth();

    const initialValues: FormModel = {
        // @ts-ignore
        title: userProfile.title,
        // @ts-ignore
        firstName: userProfile.firstName,
        // @ts-ignore
        lastName: userProfile.lastName,
        // @ts-ignore
        email: userProfile.email,
        // @ts-ignore
        mobilePhone: userProfile?.mobilePhone,
    };

    return (
        <div className="profile_change_profile">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validate(t, initialValues)}
                onSubmit={async values => {
                    setIsLoading(true);

                    const patch = createPatch(userProfile, x => {
                        x.title = values.title;
                        x.firstName = values.firstName;
                        x.lastName = values.lastName;
                        x.email = values.email;
                        x.mobilePhone = values.mobilePhone;
                    });

                    await api.users
                        // @ts-ignore
                        .patch(userProfile.id, patch)
                        .then(user => {
                            refresh(user);
                        })
                        .then(() => showToast(t("PROFILE_SAVED"), "success"))
                        .catch(() => showToast(t("COMMON_DEFAULT_ERROR"), "error"));

                    setIsLoading(false);
                }}
            >
                {props => {
                    let { errors, touched } = props;
                    return (
                        <Form>
                            <div className="my-3 p-4">
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">
                                        {t("ACCOUNTOWNER_UPDATE_FORM_TITLE")}
                                    </label>
                                    <Field
                                        type="text"
                                        name="title"
                                        placeholder={t("ACCOUNTOWNER_UPDATE_FORM_TITLE")}
                                        className={"form-control"}
                                    />
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">{t("PROFILE_FIRST_NAME")}</label>
                                    <Field
                                        type="text"
                                        name="firstName"
                                        placeholder={t("PROFILE_FIRST_NAME")}
                                        className={
                                            "form-control" +
                                            (errors.firstName && touched.firstName ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.firstName && (
                                        <span className="clearfix w-100 text-danger text-start displayblock">
                                            {" "}
                                            {errors.firstName}
                                        </span>
                                    )}
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">{t("PROFILE_LAST_NAME")}</label>
                                    <Field
                                        type="text"
                                        name="lastName"
                                        placeholder={t("PROFILE_LAST_NAME")}
                                        className={
                                            "form-control" + (errors.lastName && touched.lastName ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.lastName && (
                                        <span className="clearfix w-100 text-danger text-start displayblock">
                                            {errors.lastName}
                                        </span>
                                    )}
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">{t("PROFILE_EMAIL")}</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder={t("PROFILE_EMAIL")}
                                        className={
                                            "form-control" + (errors.email && touched.email ? " is-invalid" : "")
                                        }
                                    />
                                    {errors.email && (
                                        <span className="clearfix w-100 text-danger text-start displayblock">
                                            {errors.email}{" "}
                                        </span>
                                    )}
                                </div>
                                <div className="signinform mb-4">
                                    <label className="text-secondary text-uppercase">
                                        {t("ACCOUNTOWNER_UPDATE_FORM_MOBILEPHONE")}
                                    </label>
                                    <Field name="mobilePhone" placeholder={t("LOGIN_WITH_PHONE_PLACEHOLDER")}>
                                        {
                                            // @ts-ignore
                                            ({ field }) => (
                                                <PhoneInput
                                                    onChange={val => props.setFieldValue(field.name, val)}
                                                    onBlur={() => props.handleBlur(field.name)}
                                                    autoComplete={"off"}
                                                    country={"SE"}
                                                    international={true}
                                                    defaultCountry={"SE"}
                                                    smartCaret={true}
                                                    readOnly={false}
                                                    rules={{ required: false, isPossiblePhoneNumber: true }}
                                                    countryCallingCodeEditable={false}
                                                    value={field.value}
                                                />
                                            )
                                        }
                                    </Field>
                                    {errors.mobilePhone && (
                                        <span className="clearfix w-100 text-danger text-start displayblock">
                                            {errors.mobilePhone}
                                        </span>
                                    )}
                                </div>
                                <ContentBlockButtonContainer>
                                    <PrimaryButton
                                        type="submit"
                                        loading={isLoading || (errors.email !== undefined && errors.email.length > 0)}
                                        eventName={{ object: "editprofile", action: "save" }}
                                    >
                                        {t("PROFILE_BUTTON")}
                                    </PrimaryButton>
                                </ContentBlockButtonContainer>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ProfileForm;
