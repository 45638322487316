import { SelectItem, Group } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Team } from "@/api-client";
import { FormInput, PrimaryButton, ModalProps, ModalConfig } from "@/components";
import {
    convertToMembershipTypeModel,
    MembershipTypeInput,
    MembershipTypeModel,
    membershipTypeSchema,
} from "../../MembershipTypeInput";

type AddMembershipProps = ModalProps<{
    teams: Team[];
    updateTeam: (teamId: number, membershipType: MembershipTypeModel) => Promise<void>;
    userId: number;
}>;

const addTeamMembershipSchema = Yup.object({
    teamId: Yup.number().required("EDITEMPLOYEE_TEAM_IS_REQUIRED"),
    membershipTypes: membershipTypeSchema(),
});

export const AddMembership = (props: AddMembershipProps) => {
    const { updateTeam } = props;
    const { t } = useTranslate();

    const teamItems: SelectItem[] = props.teams
        .filter(team => team.status === "Active")
        .filter(team => !team.members.find(member => member.userId === props.userId))
        .map(team => ({
            value: team.id!.toString(),
            label: team.name,
        }));

    const initialValues = {
        teamId: "",
        membershipTypes: [],
    };

    const onSubmit = (values: typeof initialValues) => {
        updateTeam(parseInt(values.teamId), convertToMembershipTypeModel(values.membershipTypes))
            .then(() => props.onClose())
            .catchWithToast();
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={addTeamMembershipSchema}>
            <Form>
                <FormInput.NativeSelect
                    fieldName="teamId"
                    label="COMMON_TEAM"
                    placeholder="EDITEMPLOYEE_SELECT_TEAM"
                    options={teamItems}
                />
                <MembershipTypeInput fieldName="membershipTypes" />
                <Group position="right" mt="md">
                    <PrimaryButton eventName={{ object: "ao_addteammembership", action: "save" }} type="submit">
                        {t("EDITEMPLOYEE_ADD_TEAM_MEMBERSHIP")}
                    </PrimaryButton>
                </Group>
            </Form>
        </Formik>
    );
};

export const AddMembershipModal: ModalConfig<AddMembershipProps> = {
    content: AddMembership,
    title: "EDITEMPLOYEE_ADD_TEAM_MEMBERSHIP",
};
