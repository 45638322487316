import { Link } from "react-router-dom";
import "../../../../components/NavBar/style.scss";

interface Item {
    label: string;
    link: string;
    active: boolean;
}

export type NavBarWithLinksProps = {
    items: Item[];
};

export const NavBarWithLinks = (props: NavBarWithLinksProps) => {
    return (
        <ul className="account-owner-nav">
            {props.items &&
                props.items.map((n, i) => (
                    <li key={i} className={n.active ? "active-li" : ""}>
                        <Link className={n.active ? "active" : ""} to={`${n.link}`}>
                            {n.label}
                        </Link>
                    </li>
                ))}
        </ul>
    );
};
