import React from "react";
import { getColorByIndexRepeatingSeq } from "../common/colors";

interface UserCardProps {
    userId: number;
    name?: string;
    className?: string;
}

export const getInitials = (name: string): string => {
    const names = name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

const UserCard = ({ userId, name, className }: UserCardProps): JSX.Element => {
    // Note: The userId lookup is meant to be used with local user storage in the future.

    const displayName = name ?? `Implement name lookup for user from id ${userId}`;

    const color = getColorByIndexRepeatingSeq(userId, "dark");
    const colorCode = color.hex;
    const letters = getInitials(displayName);

    const style = {
        backgroundColor: colorCode,
        fontSize: "0.6rem",
        height: "1.5rem",
        width: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: "1rem",
        color: color.textColorHex,
    };

    return (
        <span className={className} style={{ display: "flex", alignItems: "center", height: "1.5rem" }}>
            <span className="rounded-circle" style={style}>
                {letters}
            </span>
            <span className="mx-1" style={{ fontSize: "0.8rem" }}>
                {displayName}
            </span>
        </span>
    );
};

export default UserCard;
