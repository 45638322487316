import React from "react";
import RenderIf from "../../render-if/render-if";

export const QuestionLabel = (props: {
    min_answer_label: string | undefined;
    max_answer_label: string | undefined;
}): JSX.Element => {
    const { min_answer_label, max_answer_label } = props;
    return (
        <RenderIf show={min_answer_label && max_answer_label ? true : false}>
            <div className="col-md-12 bottom-container mt-1 mb-4">
                <div className="bottom-left-item bottom-item">{min_answer_label}</div>
                <div className="bottom-right-item bottom-item">{max_answer_label}</div>
            </div>
        </RenderIf>
    );
};
