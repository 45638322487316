import { Flex, Stack, Text } from "@mantine/core";
import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import { Course, Group, GroupMember } from "@/api-client";
import { useParticipantsProgress } from "../../common/courses/context";
import { useAllUsers } from "../../common/OrganisationContext";
import { Attendees } from "./Attendees";
import { Completion } from "./Completion";
import { mapUsersWithProgress } from "./helper";
import { CardBlock } from "@/components";

type TeamProgressDisplayProps = {
    course: Course;
    team: Group;
};
const TeamProgressDisplay = (props: TeamProgressDisplayProps) => {
    const { t } = useTranslate();
    const participantsProgress = useParticipantsProgress() ?? [];
    const users = useAllUsers();

    const chapterCount = useMemo(
        () =>
            props.course.chapters
                .filter(c => c.status === "Active" || c.status === "Disabled")
                .map(c => c.modules.filter(m => m.status === "Active" || m.status === "Disabled").length),
        [props.course.id]
    );

    const teamMembers: GroupMember[] = props.team.members
        .map(m => {
            const { user, ...rest } = m;
            return {
                ...rest,
                user: users?.find(u => u.id === m.userId),
            };
        })
        .filter(x => x !== undefined);

    const teamMembersWithProgress = mapUsersWithProgress(participantsProgress, teamMembers);

    return (
        <CardBlock>
            <Stack>
                <Text size="xl">{t("PROGRAM_TEAM_PROGRESS")}</Text>
            </Stack>
            {teamMembersWithProgress.length > 0 ? (
                <Stack>
                    <Completion
                        progress={participantsProgress}
                        countChapters={chapterCount}
                        currentCourse={props.course}
                        members={props.team.members}
                    />
                    <Attendees participantsProgress={teamMembersWithProgress} currentCourse={props.course} />
                </Stack>
            ) : (
                <Flex align="center" justify="center" direction="column" h={200}>
                    <Text c="dimmed">{t("HOME_NO_TEAM_MEMBERS")}</Text>
                </Flex>
            )}
        </CardBlock>
    );
};

export default TeamProgressDisplay;
