import { useEffect } from "react";
import { notifications } from "@mantine/notifications";

interface NotifyProps {
    message: string;
    id: string;
    type: NotificationType;
}

type NotificationType = "information" | "success" | "warning" | "error";

export const showToast = (message: string, type: NotificationType): void => {
    if (type === "warning") {
        notifications.show({
            message: message,
            color: "orange",
        });
    } else if (type === "error") {
        notifications.show({
            message: message,
            color: "red",
        });
    } else if (type === "information") {
        notifications.show({
            message: message,
            color: "blue",
        });
    } else {
        notifications.show({
            message: message,
            color: "green",
        });
    }
};

export const Notify = (props: NotifyProps): JSX.Element => {
    const { message, type } = props;
    useEffect(() => {
        showToast(message, type);
    }, []);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
