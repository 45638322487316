import React, { useEffect, useState } from "react";
import { SpinnerImg, SpinnerImgWhite } from "../../assets/index";

export const AppLoader = (props: { loading: boolean; className?: string; light?: boolean }): JSX.Element => {
    const image: any = props.light ? SpinnerImgWhite : SpinnerImg;
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 1000);
        return () => clearTimeout(timer);
    });
    return showSpinner && props.loading ? (
        <div className={`spinnerImg ${props.className ? props.className : ""}`}>
            <img src={image} alt="Hups" />
        </div>
    ) : (
        <></>
    );
};
