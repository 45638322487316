import { useEffect, useState } from "react";
import { SelectItem, Stack } from "@mantine/core";
import * as Yup from "yup";
import { useTranslate } from "@tolgee/react";
import { AppLoader, CardBlock, Form, FormInput, Section, showToast } from "@/components";
import { createApi, createPatch, useCurrentClient, yupExtensions } from "@/common";
import { EditTeamCommandDto, TaskVisibility, TeamMemberDto, TeamStatus, TeamTypeDto } from "@/api-client";
import { useTranslatedTaskVisibilityItems } from "./useTranslatedTaskVisibilityItems";
import { EditTeamStatus } from "./EditTeamStatus";
import { AssignPrograms } from "./AssignPrograms";
import { MembersList, TeamMember } from "./MembersList";

type EditTeamProps = {
    teamId: number;
};

const validator = Yup.object({
    name: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
    color: Yup.string().required("COMMON_FIELD_REQUIRED"),
    status: Yup.mixed<TeamStatus>().required("COMMON_FIELD_REQUIRED"),
    taskVisibility: Yup.mixed<TaskVisibility>().required("COMMON_FIELD_REQUIRED"),
    teamTypeId: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
});

type EditTeamForm = Yup.InferType<typeof validator>;

type Team = {
    id: number;
    name: string;
    color: string;
    status: TeamStatus;
    taskVisibility: TaskVisibility;
    teamTypeId: number;
};

const getInitialValues = (team: Team | null): EditTeamForm => {
    if (team === null) {
        return { name: "", color: "", status: "Active", taskVisibility: "All", teamTypeId: "" };
    }

    return {
        name: team.name,
        color: team.color,
        status: team.status,
        taskVisibility: team.taskVisibility,
        teamTypeId: team.teamTypeId.toString(),
    };
};

export const EditTeam = (props: EditTeamProps) => {
    const taskVisibilityItems = useTranslatedTaskVisibilityItems();
    const [team, setTeam] = useState<Team | null>(null);
    const [teamTypes, setTeamTypes] = useState<TeamTypeDto[]>([]);
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const { t } = useTranslate();
    const clientId = useCurrentClient().id;

    const getTeam = async (teamId: number) => {
        const api = createApi();
        const team = await api.accountOwners.getTeam(teamId, clientId, new AbortController());
        const teamTypes = await api.groupTypes.query(clientId);

        const getDisplayName = (user: TeamMemberDto): string | null => {
            if (user.firstName !== null && user.lastName !== null) {
                return `${user.firstName} ${user.lastName}`;
            }
            return null;
        };

        setTeam({
            name: team.name,
            id: team.id,
            color: team.color,
            status: team.status,
            taskVisibility: team.taskVisibility,
            teamTypeId: team.teamTypeId,
        });

        setTeamMembers(
            team.members.map(member => ({
                id: member.id,
                displayName: getDisplayName(member),
                isParticipant: member.isParticipant,
                isTeamLeader: member.isTeamLeader,
                email: member.email,
                phoneNumber: member.phoneNumber,
                avatarUrl: member.avatarUrl,
                status: member.status,
            }))
        );

        setTeamTypes(teamTypes.filter(x => x.status === "Active").map(x => ({ id: x.id!, name: x.name! })));
    };

    useEffect(() => {
        getTeam(props.teamId).then().catchWithToast();
    }, []);

    if (team === null || teamTypes === null) {
        return <AppLoader loading />;
    }

    const teamTypeSelectItems: SelectItem[] = teamTypes.map(x => ({ value: x.id.toString(), label: x.name }));

    const updateTeam = async (values: EditTeamForm) => {
        const patch = createPatch<EditTeamCommandDto>({ ...team, groupTypeId: team.teamTypeId }, x => {
            x.name = values.name;
            x.status = values.status;
            x.color = values.color;
            x.taskVisibility = values.taskVisibility;
            x.groupTypeId = parseInt(values.teamTypeId);
        });

        return createApi()
            .groups.patch(props.teamId, patch)
            .then(() => showToast(t("ACCOUNTOWNER_EDIT_TEAMS_TEAM_UPDATED"), "success"))
            .then(() => getTeam(props.teamId));
    };

    const initialValues = getInitialValues(team);

    return (
        <CardBlock>
            <Stack>
                <Form<EditTeamForm>
                    initialValues={initialValues}
                    validationSchema={validator}
                    onSubmit={updateTeam}
                    submitLabel="COMMON_SAVE"
                    eventName={{ object: "ao_editteam", action: "save" }}
                >
                    <Stack>
                        <FormInput.Text fieldName="name" label="ACCOUNTOWNER_EDIT_TEAMS_NAME" />
                        <FormInput.NativeSelect
                            fieldName="teamTypeId"
                            label="ACCOUNTOWNER_EDIT_TEAMS_TEAM_TYPE"
                            options={teamTypeSelectItems}
                            placeholder="ACCOUNTOWNER_SELECT_TEAM_TYPE_PLACEHOLDER"
                        />
                        <FormInput.RadioGroup
                            options={taskVisibilityItems}
                            fieldName="taskVisibility"
                            label="ACCOUNTOWNER_EDIT_TEAMS_TASK_VISIBILITY"
                        />
                        <FormInput.ColorInput fieldName="color" label="ACCOUNTOWNER_EDIT_TEAMS_COLOR" />
                    </Stack>
                </Form>
                <Section title="ACCONTOWNER_EDIT_TEAMS_STATUS">
                    <EditTeamStatus team={{ id: team.id, status: team.status }} />
                </Section>
                <Section title="ACCOUNTOWNER_TEAMS_MEMBERS">
                    <MembersList
                        members={teamMembers}
                        teamId={props.teamId}
                        onMemberAdded={() => getTeam(props.teamId).catchWithToast()}
                    />
                </Section>
                <Section title="ACCOUNTOWNER_EDIT_TEAMS_PROGRAMS">
                    <AssignPrograms teamId={props.teamId} />
                </Section>
            </Stack>
        </CardBlock>
    );
};
