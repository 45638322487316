﻿import { useTranslate } from "@tolgee/react";
import React from "react";
import { PrimaryButton } from "../../components/Button/PrimaryButton";

type TimerMessageProps = {
    timer: number;
    messageHandler: () => void;
};

export const TimerMessage = ({ timer, messageHandler }: TimerMessageProps) => {
    const { t } = useTranslate();
    return (
        <div className="alert-secondary p-2 mt-3">
            {t(
                "LOGIN_WITH_PHONE_IF_THE_ENTERED_MOBILE_NUMBER_IS_REGISTRERED_WITH_US_AN_SMS_WITH_A_VERIFICATION_CODE_WILL_ARRIVE_SHORTLY"
            )}
            <div>
                {timer > 0 && (
                    <span>
                        {t("LOGIN_WITH_PHONE_RESEND_SMS")} <strong>00:{timer}</strong>
                    </span>
                )}
                {timer === 0 && (
                    <PrimaryButton
                        onClick={() => {
                            messageHandler();
                        }}
                    >
                        {t("LOGIN_WITH_PHONE_SEND_AGAIN")}
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};
