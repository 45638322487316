import { useTolgee } from "@tolgee/react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Language, LanguageMap, Languages } from "../../../translation";

export const LanguageSwitcher = () => {
    const tolgee = useTolgee(["language"]);

    const handleSelect = (language: Language) => {
        tolgee.changeLanguage(language).catchWithToast();
    };

    const selectedLanguage = tolgee.getLanguage() as Language;
    const currentLanguage = LanguageMap[selectedLanguage].fullName;

    return (
        <Nav
            bsPrefix="lang_switcher_nav"
            activeKey={selectedLanguage}
            onSelect={eventKey => handleSelect(eventKey as Language)}
        >
            <NavDropdown
                bsPrefix="lang_switcher"
                title={
                    <>
                        <i className="fa fa-globe"></i>
                        <span className="ms-1">{currentLanguage}</span>
                    </>
                }
                id="nav-dropdown"
            >
                {Languages.map(l => (
                    <NavDropdown.Item key={l.value} eventKey={l.value}>
                        {l.fullName}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
        </Nav>
    );
};
