﻿import React, { ReactNode } from "react";
import { createStyles } from "@mantine/core";
import { CardBlock } from "../../components/CardBlock";

const useStyles = createStyles(theme => ({
    card: {
        position: "relative",
        overflow: "visible",
        marginBottom: theme.spacing.xl,
    },
    buttonContainer: {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
    },
}));

export const CardBlockWithButtonContainer = ({ children, buttons }: { children: ReactNode; buttons?: ReactNode }) => {
    const { classes } = useStyles();

    return (
        <CardBlock classNames={{ card: classes.card }}>
            {children}
            <div className={classes.buttonContainer}>{buttons}</div>
        </CardBlock>
    );
};
