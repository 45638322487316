import React from "react";
import { Flex, Menu, Text } from "@mantine/core";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { useAuth } from "../../../auth/useAuth";
import { showToast } from "../../../components/Notify";
import { Icons } from "@/common";

interface ClientLoginProps {
    clientId: number;
    componentType: string;
}

export const ClientLogin = ({ clientId, componentType }: ClientLoginProps) => {
    const { impersonate } = useAuth();

    const loginByClientId = async (clientId: number) => {
        impersonate({ type: "ao", clientId: clientId }).catch(r => showToast(`Cannot impersonate user ${r}`, "error"));
    };

    return (
        <React.Fragment>
            {componentType === "dropdown-menu" ? (
                <Menu.Dropdown className="drop-down-menu">
                    <Menu.Item onClick={() => loginByClientId(clientId)}>
                        <Flex align="center" gap=".5rem">
                            <Icons.SignIn />
                            <Text>Sign in as Account Owner</Text>
                        </Flex>
                    </Menu.Item>
                </Menu.Dropdown>
            ) : (
                <PrimaryButton onClick={async () => await loginByClientId(clientId)}>
                    <Icons.SignIn />
                    <Text ml=".5rem">Sign in as Account Owner</Text>
                </PrimaryButton>
            )}
        </React.Fragment>
    );
};
