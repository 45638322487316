﻿import { User } from "@/api-client";

export const getUserByEmailOrPhone = (
    email: string | null,
    mobilePhone: string | null,
    users: User[]
): User | undefined => {
    if (email !== null) {
        // @ts-ignore
        const emailUser = users.filter(x => x.email !== null).find(u => u.email.toLowerCase() === email.toLowerCase());
        if (emailUser !== undefined) {
            return emailUser;
        }
    }
    if (mobilePhone !== null) {
        const phoneUser = users.filter(x => x.mobilePhone !== null).find(u => u.mobilePhone === mobilePhone);
        if (phoneUser !== undefined) {
            return phoneUser;
        }
    }

    return undefined;
};
