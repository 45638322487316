import { useTranslate } from "@tolgee/react";
import { Module } from "@/api-client";
import type { CourseRouteParams } from "../RouteProps";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { useCurrentCourseFromParams } from "../helper";
import EditChapter from "./EditChapter";
import Modules from "./Modules";
import { CardBlock } from "../../../components/CardBlock";
import { useParams } from "react-router-dom";

export type ChapterRouteParams = CourseRouteParams & {
    chapterId: string;
};

const Index = (): JSX.Element => {
    const params = useParams<ChapterRouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore

    const chapterId = parseInt(params.chapterId);
    const [course] = useCurrentCourseFromParams();
    const nav = CourseMenu(course);
    //@ts-ignore
    const modules: Module[] = course && course.chapters.find(x => x.id === chapterId).modules;

    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: null },
    ];

    return (
        <>
            {
                //@ts-ignore
                chapterDetails && <Breadcrumbs breadCrumb={links} />
            }
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar children={nav} courseId={courseId} chapterId={chapterId} name={course?.name} />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <EditChapter courseId={courseId} chapterId={chapterId} />
                    </CardBlock>
                    <CardBlock mb="xl">
                        <Modules courseId={courseId} chapterId={chapterId} modules={modules} />
                    </CardBlock>
                </div>
            </div>
        </>
    );
};

export default Index;
