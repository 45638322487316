import "./styles.scss";
import React, { useState } from "react";
import { HoverCard } from "@mantine/core";
import { Draggable } from "@hello-pangea/dnd";
import { TaskCardEditor } from "../TaskCardEditor/TaskCardEditor";
import { TaskPreview } from "../TaskPreview/TaskPreview";
import { Icons } from "../../../../common/Icons";
import { useStyle } from "./taskCardStyles";
import { Task } from "../../dragAndDrop";
import { ImprovementBoardTask, TaskCategory } from "@/api-client";
import { trackPosthogEvent } from "../../../../analytics/customEvents/trackPosthogEvent";

export interface TaskCardProps {
    task: ImprovementBoardTask;
    text?: string;
    category: TaskCategory;
    index: number;
}

const TaskCardEditorInner = (props: {
    showDetails: boolean;
    task: ImprovementBoardTask;
    setShowDetails: (show: boolean) => void;
}) => {
    if (!props.showDetails) {
        return <></>;
    }

    return (
        <TaskCardEditor task={props.task} show={props.showDetails} handleClose={() => props.setShowDetails(false)} />
    );
};

export const TaskCard = (props: TaskCardProps) => {
    const categoryColor = props.category?.color;
    const categoryName = props.category?.name;

    const { classes } = useStyle({ categoryColor });
    const [showDetails, setShowDetails] = useState<boolean>(false);

    return (
        <>
            <HoverCard openDelay={1200} disabled={showDetails} withArrow arrowSize={26} shadow="md">
                <HoverCard.Target>
                    <div>
                        <Draggable draggableId={Task.toDraggableId(props.task.id)} index={props.index}>
                            {provided => (
                                <div
                                    className={classes.taskCardContainer}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <div
                                        className={classes.taskCard}
                                        onClickCapture={() => {
                                            // Prevent temporary items from being edited as they don't exist yet
                                            if (props.task.id !== -1) {
                                                trackPosthogEvent("ui", "improvementboard", "opentask");
                                                setShowDetails(true);
                                            }
                                        }}
                                    >
                                        <div className={classes.content}>
                                            <div className="text">
                                                {/* If id == -1 it's temporary while doing optimistic updates*/}
                                                {props.task.id === -1 && "Saving..."}
                                                {props.task.id !== -1 && (props.text ?? props.task.title)}
                                            </div>
                                        </div>
                                    </div>
                                    <span className={classes.taskMarkerContainer}>
                                        {props.task?.createdInGroupId !== props.task?.groupId && (
                                            <Icons.Envelope classes={`${classes.marker} ${classes.groupMarker}`} />
                                        )}
                                        {props.task?.priority === "High" && (
                                            <Icons.Exclamation
                                                classes={`${classes.marker} ${classes.priorityHighMarker}`}
                                            />
                                        )}
                                    </span>
                                </div>
                            )}
                        </Draggable>
                    </div>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <TaskPreview
                        status={props.task.status}
                        priority={props.task.priority}
                        createdAt={props.task.createdAt}
                        updatedAt={props.task.updatedAt}
                        createdBy={props.task.createdByUser?.fullName ?? "-"}
                        category={categoryName}
                        title={props.task.title}
                        assigneeId={props.task.assignedToUserId}
                        description={props.task.description}
                    />
                </HoverCard.Dropdown>
            </HoverCard>
            <TaskCardEditorInner showDetails={showDetails} task={props.task} setShowDetails={setShowDetails} />
        </>
    );
};
