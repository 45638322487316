import { TrainingProgressPerGroupReport } from "@/api-client";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "@/common";
import { createApi } from "../../common/api";
import { useOrganisationContext } from "../../common/OrganisationContext";
import ContentBlock from "../../components/ContentBlock";
import { NavBar } from "../../components/NavBar";
import TrainingOverview from "../Components/TrainingOverview";
import { AccountOwnerLayout } from "../Layout";

/*  eslint-disable react-hooks/exhaustive-deps */

type TrainingPerGroup = {
    result: TrainingProgressPerGroupReport[];
    isPending: boolean;
    error: string | null;
};

const AccountOwnwerTraining = () => {
    // @ts-ignore
    const [courseId, setCourseId] = useState<number>(null);
    const [trainingPerGroup, setTrainingPerGroup] = useState<TrainingPerGroup>({
        // @ts-ignore
        result: null,
        isPending: false,
        error: null,
    });

    const courses = useOrganisationContext().courses;
    const api = createApi();
    const history = useHistory();

    useEffect(() => {
        if (courses && courses.length > 0) {
            // @ts-ignore
            setCourseId(courses[0].id);
        }
    }, [courses]);

    useEffect(() => {
        trainingProgressPerGroup();
    }, []);

    const trainingProgressPerGroup = () => {
        setTrainingPerGroup({ ...trainingPerGroup, isPending: true });
        api.trainingPerGroupReport.trainingProgressPerGroup().then(
            response => {
                setTrainingPerGroup({ ...trainingPerGroup, isPending: false, result: response });
            },
            _ => {
                history.push("/error");
                setTrainingPerGroup({ ...trainingPerGroup, isPending: false });
            }
        );
    };

    return (
        <AccountOwnerLayout show={false} showMainNav={true}>
            <Container className="account-owner-area">
                <Row>
                    {/** Sidebar */}
                    <Col md={4}>
                        <ContentBlock>
                            <NavBar
                                // @ts-ignore
                                courses={courses}
                                handleCourse={courseId => setCourseId(courseId)}
                                selectedCourse={courseId}
                            />
                        </ContentBlock>
                    </Col>
                    {/** Content area */}
                    <Col md={8} className="content-area p-4">
                        {/* <HomeCard selectedCourse={currentCourse} /> */}
                        <TrainingOverview showButtons={false} />
                    </Col>
                </Row>
            </Container>
        </AccountOwnerLayout>
    );
};
export default AccountOwnwerTraining;
