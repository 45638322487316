import { ReactNode, useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import { MantineReactTable, MRT_TableOptions, MRT_ColumnDef } from "mantine-react-table";
import { Flex, Group } from "@mantine/core";
import { useTableLocalization } from "./Table/useTableLocalization";
import { TFunction } from "@/common";

type BasicTableProps<T extends Record<string, unknown>> = {
    emptyPlaceHolder?: string;
    data: T[] | null | undefined;
    columns: BasicTableColumn<T>[];
    renderRowActions?: MRT_TableOptions<T>["renderRowActions"];
    toolbarActions?: MRT_TableOptions<T>["renderTopToolbarCustomActions"];
    initialSort?: { id: string; desc: boolean }[];
};

export type BasicTableColumn<T extends Record<string, unknown>> = {
    id?: string;
    header: string;
    accessor: (rowObject: T) => string | number | boolean | undefined | null;
    render?: (rowObject: T) => ReactNode | null;
    sortingFn?: (rowObjectA: T, rowObjectB: T) => 1 | 0 | -1;
    enableSorting?: boolean;
};

const toMrtColumnDef = <T extends Record<string, unknown>>(
    t: TFunction,
    column: BasicTableColumn<T>
): MRT_ColumnDef<T> => {
    const sorting: Partial<MRT_ColumnDef<T>> =
        column.sortingFn !== undefined
            ? { sortingFn: (a, b) => column.sortingFn!(a.original, b.original) }
            : {
                  sortingFn: "alphanumeric",
                  enableSorting: column.enableSorting ?? false,
              };

    return {
        id: column.id,
        accessorFn: column.accessor,
        Cell: column.render ? ({ row }) => (column.render ? column.render(row.original) : undefined) : undefined,
        header: t(column.header),
        ...sorting,
    };
};

export const BasicTable = <T extends Record<string, unknown>>(props: BasicTableProps<T>) => {
    const { t } = useTranslate();
    const localization = useTableLocalization();

    const columns = useMemo<MRT_ColumnDef<T>[]>(() => {
        return props.columns.map(c => toMrtColumnDef(t, c));
    }, []);

    const rowActionProps: Partial<MRT_TableOptions<T>> =
        props.renderRowActions !== undefined
            ? {
                  enableRowActions: true,
                  positionActionsColumn: "last",
                  renderRowActions: cellProps => <Flex justify="flex-end">{props.renderRowActions!(cellProps)}</Flex>,
                  displayColumnDefOptions: {
                      "mrt-row-actions": {
                          header: "",
                          size: 10,
                      },
                  },
              }
            : {};

    return (
        <MantineReactTable
            columns={columns}
            data={props.data === undefined || props.data === null ? [] : props.data}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={props.initialSort !== undefined}
            enableBottomToolbar={false}
            enableTopToolbar
            enableGlobalFilter={false}
            enableFilters={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableFullScreenToggle={false}
            mantinePaperProps={{
                shadow: undefined,
                withBorder: false,
            }}
            mantineTableProps={{
                highlightOnHover: false,
                striped: true,
            }}
            initialState={{
                density: "xs",
                ...(props.initialSort !== undefined
                    ? {
                          sorting: props.initialSort,
                      }
                    : {}),
            }}
            state={{
                isLoading: props.data === null || props.data === undefined,
            }}
            localization={{
                ...localization,
                ...(props.emptyPlaceHolder !== undefined ? { noRecordsToDisplay: t(props.emptyPlaceHolder) } : {}),
            }}
            {...rowActionProps}
            mantineTopToolbarProps={{
                //This is pretty brittle, but the padding cannot be set currently.
                //https://github.com/KevinVandy/mantine-react-table/blob/e8710b1abd0ed7a92d63ce44bca79535cff5aeaf/packages/mantine-react-table/src/toolbar/MRT_TopToolbar.tsx#L92
                sx: () => {
                    return {
                        "& > div:nth-child(2)": {
                            marginBottom: 0,
                            padding: 0,
                        },
                    };
                },
            }}
            renderTopToolbarCustomActions={
                props.toolbarActions !== undefined
                    ? toolbarProps => <Group>{props.toolbarActions!(toolbarProps)}</Group>
                    : undefined
            }
        />
    );
};
