import React, { FormEvent, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Space } from "@mantine/core";
import { GroupStatuses, TaskVisibility, Group } from "@/api-client";
import { createApi } from "../../../common/api";
import { useOrganisationContext, useOrganisationContextDispatch } from "../../../common/OrganisationContext";
import { createPatch } from "../../../common/patchHelper";
import { useCurrentUser } from "../../../common/user/context";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import { AppLoader } from "../../../components/Spinner";
import { DropDown } from "../../Components/DropDown";
import "../Components/DepartmentsListDisplay/style.scss";
import { createHierachicalGroupsByParentId } from "../helper";
import "./departments.scss";
import { ColorSelect } from "../../../components/ColorSelect";
import { getColorsUnusedFirst, light } from "../../../common/colors";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";

type GroupEditorProps = {
    refreshOrganisationReport?: boolean;
    groupTypeId?: number;
    group?: Group;
    groups: Group[];
};

const colors = light.map(x => x.hex);

export const GroupEditor = (props: GroupEditorProps): JSX.Element => {
    const groups = props.groups;
    const groupTypes = useOrganisationContext().groupTypes;
    const user = useCurrentUser();
    const { t } = useTranslate();
    const [groupState, setGroupState] = useState(null);
    const [parentGroupId, setParentGroupId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [groupTypeError, setGroupTypeError] = useState<string | null>(null);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [groupTypeId, setGroupTypeId] = useState<number>();
    const [groupStatus, setGroupStatus] = useState<GroupStatuses>("Active");
    const [color, setColor] = useState<string | null>(null);
    const [taskVisibility, setTaskVisibility] = useState<TaskVisibility>("All");
    const courses = useOrganisationContext().courses;
    const groupsDispatch = useOrganisationContextDispatch();
    const api = createApi();

    useEffect(() => {
        if ((groupTypes && groupTypes.length > 0) || props.groupTypeId) {
            setGroupTypeId(props.groupTypeId || groupTypes?.[0]?.id);
        }
    }, [groupTypes, courses, props.groupTypeId, props.group]);

    useEffect(() => {
        if (props.group) {
            setGroupTypeId(props.group.groupTypeId);
            // @ts-ignore
            setGroupState(props.group.name);
            // @ts-ignore
            setParentGroupId(props.group.parentId);
            setGroupStatus(props.group.status);
            setTaskVisibility(props.group.taskVisibility);
            setColor(props.group.color);
        }
    }, [props.group]);

    const getNGroupColors = (numberOfColors: number) => {
        const takenColors = groups.map(g => g.color);
        return getColorsUnusedFirst(colors, takenColors, numberOfColors);
    };

    const saveNewGroup = async (groups: string[]) => {
        const colors = getNGroupColors(groups.length);

        const createGroups = groups.map(async (group, index) => {
            return await api.groups.post({
                name: group.trim(),
                color: colors[index],
                // @ts-ignore
                clientId: user.clientId,
                // @ts-ignore
                parentId: parentGroupId,
                groupTypeId,
                courseIds: [],
                status: groupStatus,
                taskVisibility: taskVisibility,
            });
        });

        await Promise.allSettled(createGroups)
            .then(results => {
                const finalResult: Group[] = results
                    .filter(result => result.status === "fulfilled")
                    .map(r => (r as PromiseFulfilledResult<Group>).value);

                groupsDispatch(s => ({
                    ...s,
                    groups: [
                        // @ts-ignore
                        ...s.groups,
                        ...finalResult.filter(g => g.status === "Active"), //Only add active groups to the context
                    ],
                }));
            })
            .catch(err => {
                console.log(err, "Error");
            })
            .finally(() => {
                setIsPending(false);
            });

        setGroupState(null);
        setParentGroupId(null);
    };

    const updateGroup = async (groupName: string) => {
        const patch = createPatch(
            // @ts-ignore
            props.group,
            g => {
                // @ts-ignore
                g.name = groupName;
                // @ts-ignore
                g.color = color;
                // @ts-ignore
                g.parentId = parentGroupId;
                // @ts-ignore
                g.status = groupStatus;
                // @ts-ignore
                g.taskVisibility = taskVisibility;
            }
        );

        api.groups
            .patch(
                // @ts-ignore
                props.group.id,
                patch
            )
            .then(
                result => {
                    groupsDispatch(s => ({
                        ...s,
                        groups: [
                            // @ts-ignore
                            ...s.groups.filter(g => g.id !== result.id),
                            ...(result.status === "Active" ? [result] : []),
                        ],
                    }));
                    setIsPending(false);
                },
                error => {
                    setIsPending(false);
                    console.log(error);
                }
            );
    };

    const validateForm = (): boolean => {
        setGroupTypeError("");
        setError("");
        if (!groupTypeId) {
            setGroupTypeError("Please select Group Type");
        }
        if (!groupState) {
            setError("Please enter Groups.");
        }

        if (!groupTypeId || !groupState) {
            return false;
        }
        return true;
    };

    const addGroup = (e: FormEvent) => {
        e.preventDefault();
        const isValidForm = validateForm();
        if (isValidForm) {
            setIsPending(true);
            setError(null);
            // @ts-ignore
            const tempDepartment = groupState.replace(/^\s*[\r\n]/gm, "");
            if (props.group) {
                const group = tempDepartment;
                updateGroup(group);
            } else {
                const groups = tempDepartment.split("\n");
                saveNewGroup(groups);
            }
        }
    };

    /**
     * Set the state for groups
     * @param e
     */
    // @ts-ignore
    const handleChange = e => {
        e.preventDefault();
        setError(null);
        const groups = e.target.value;
        setGroupState(groups);
    };

    const handleGroupType = (val: number) => {
        setGroupTypeId(val);
    };

    const hierarchicalGroups = createHierachicalGroupsByParentId(groups?.filter(g => g.groupTypeId === groupTypeId));

    return (
        <>
            <AppLoader loading={isPending} className="position-aboslute" />
            <Form onSubmit={e => addGroup(e)}>
                <Row>
                    {props.group ? (
                        <Col className="col-md-6">
                            <label className="form-label">{t("COMMON_NAMES")}</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={e => handleChange(e)}
                                value={groupState || ""}
                                name="groups"
                                placeholder={t("ORGANISATION_ENTER_GROUP_NAMES")}
                            />
                            <div>{error !== null && <DisplayInlineError error={error} />}</div>
                        </Col>
                    ) : (
                        <Col className="col-md-6">
                            <label className="form-label">{t("COMMON_NAMES")}</label>
                            <textarea
                                className="form-control"
                                onChange={e => handleChange(e)}
                                rows={3}
                                cols={10}
                                value={groupState || ""}
                                name="groups"
                                placeholder={t("ORGANISATION_ENTER_GROUP_NAMES")}
                            ></textarea>
                            <div>{error !== null && <DisplayInlineError error={error} />}</div>
                        </Col>
                    )}
                    {!props.group && (
                        <Col className="mt-3">
                            <p className="mt-5">
                                <small>{t("ORGANISATION_ADD_MORE_GROUPS")}</small>
                            </p>
                        </Col>
                    )}
                </Row>
                {!props.groupTypeId && !props.group && (
                    <Row className="mt-3">
                        <Col className="col-md-6">
                            <label className="form-label">Group Types</label>
                            <select
                                value={groupTypeId}
                                onChange={e => handleGroupType(Number(e.target.value))}
                                name="groupType"
                                className="form-select"
                            >
                                {groupTypes &&
                                    groupTypes.map(gt => (
                                        <option key={gt.id} value={gt.id}>
                                            {gt.name}
                                        </option>
                                    ))}
                            </select>
                            <div>{groupTypeError !== null && <DisplayInlineError error={groupTypeError} />}</div>
                        </Col>
                    </Row>
                )}

                {props.group && (
                    <>
                        <Row className="mt-3">
                            <Col className="col-md-6">
                                <label className="form-label">{t("COMMON_PARENT")}</label>
                                <DropDown
                                    data={hierarchicalGroups}
                                    placeholder={t("ORGANISATION_SELECT_PARENT_GROUP")}
                                    className={"form-control"}
                                    defaultValue={parentGroupId}
                                    getSelectedValue={(val: number) => setParentGroupId(val)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-6">
                                <label className="form-label">Status</label>
                                <select
                                    name="status"
                                    className="form-select"
                                    value={groupStatus}
                                    onChange={e => setGroupStatus(e.target.value as GroupStatuses)}
                                >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                    <option value="Deleted">Deleted</option>
                                </select>
                            </Col>
                        </Row>

                        <Col className="col-md-6">
                            <label className="form-label">{t("ORGANISATION_GROUPS_TASK_VISIBILITY")}</label>
                            <select
                                name="status"
                                className="form-select"
                                value={taskVisibility}
                                onChange={e => setTaskVisibility(e.target.value as TaskVisibility)}
                            >
                                <option value="All">All</option>
                                <option value="OnlyMembers">Only members</option>
                            </select>
                            <div>{error !== null && <DisplayInlineError error={error} />}</div>
                        </Col>

                        <Col className="col-md-6">
                            <label className="form-label">{t("ORGANISATION_GROUPS_COLOR")}</label>
                            <ColorSelect
                                // @ts-ignore
                                value={color}
                                onChange={setColor}
                                palette={colors}
                            />
                        </Col>
                    </>
                )}
                <Space h="md" />
                <ContentBlockButtonContainer>
                    <PrimaryButton type="submit">
                        {props.group ? t("ORGANISATION_UPDATE_GROUP") : t("ORGANISATION_ADD_GROUP")}
                    </PrimaryButton>
                </ContentBlockButtonContainer>
            </Form>
        </>
    );
};
