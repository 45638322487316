import { Anchor, Text, Breadcrumbs as MBreadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

export type BreadcrumbProps = {
    items: {
        title: string;
        href?: string;
    }[];
};

/* eslint-disable react/no-array-index-key */
export const Breadcrumbs = (props: BreadcrumbProps) => {
    const items = props.items.map((item, index) => {
        if (!item.href) {
            return <Text key={`${index}`}>{item.title}</Text>;
        }

        return (
            <Anchor to={item.href} component={Link} key={index} color="dimmed">
                {item.title}
            </Anchor>
        );
    });

    return <MBreadcrumbs separator={<i className="fa-solid fa-chevron-right fa-sm" />}>{items}</MBreadcrumbs>;
};
