import React, { useMemo, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { Module } from "@/api-client";
import { useHistory } from "@/common";
import { useCurrentCourse, useCurrentGroup } from "../../common/courses/context";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { useCurrentClient, useCurrentUser } from "../../common/user/context";
import { MultiSelectToggle } from "../../components/MultiSelectToggle";
import { getPresentationLink } from "./helpers";
import {
    PresentationGroupings,
    useGroupsInCourse,
    usePresentationContext,
    usePresentationContextDispatch,
} from "./PresentationContext";
import { TaskCardEditor } from "../../pages/ImprovementBoard/components/TaskCardEditor/TaskCardEditor";
import { showToast } from "../../components/Notify";
import { UrlFactory } from "../../routing/UrlFactory";
import { customEvents } from "../../analytics/customEvents/customEvents";
import { trackPosthogEvent } from "../../analytics/customEvents/trackPosthogEvent";

const GroupByOptions = () => {
    const currentClient = useCurrentClient();

    const { filters } = usePresentationContext();
    const presentationContextDispatch = usePresentationContextDispatch();

    const { demographicQuestions, demographicData } = useOrganisationContext();

    const setGroupBy = (groupBy: PresentationGroupings, groupById?: number) => {
        trackPosthogEvent("ui", "presentation", "groupby", { groupBy: groupBy });
        presentationContextDispatch(x => ({
            ...x,
            filters: {
                ...x.filters,
                groupBy,
                groupById,
            },
        }));
    };

    const getGroupByTitle = () => {
        if (filters.groupBy === "DemographicData") {
            return demographicQuestions ? demographicQuestions.find(x => x.id === filters.groupById)?.label : undefined;
        }

        return filters.groupBy;
    };

    const getAvailableGroupings = (): PresentationGroupings[] => {
        let groupings: PresentationGroupings[] = ["Category", "Group"];

        // @ts-ignore
        if (currentClient && currentClient.tags.indexOf("presentation-usergrouping") !== -1) {
            groupings = [...groupings, "User"];
        }

        return groupings;
    };

    return (
        <>
            <div className="d-flex align-items-center">Group By:</div>

            <NavDropdown
                title={getGroupByTitle()}
                id="collasible-nav-dropdown justified"
                className="workshop-modules-list pl-0 d-flex"
            >
                {getAvailableGroupings().map((groupBy: PresentationGroupings, ix) => (
                    <NavDropdown.Item
                        key={`navitem_${ix}`}
                        active={filters.groupBy === groupBy}
                        onClick={() => setGroupBy(groupBy)}
                    >
                        {groupBy}
                    </NavDropdown.Item>
                ))}
                {demographicQuestions && demographicData && demographicData.length > 0 && (
                    <>
                        <NavDropdown.Divider />
                        {demographicQuestions.map((demographicQuestion, ix) => (
                            <NavDropdown.Item
                                key={`demographicQuestion-${ix}`}
                                active={demographicQuestion.id === filters.groupById}
                                onClick={() => setGroupBy("DemographicData", demographicQuestion.id)}
                            >
                                {demographicQuestion.label}
                            </NavDropdown.Item>
                        ))}
                    </>
                )}
            </NavDropdown>
        </>
    );
};

export type PresentationNavProps = {
    enterFullscreen: () => void;
    exitFullscreen: () => void;
    isFullscreen: boolean;
};

export const PresentationNav = (props: PresentationNavProps) => {
    const { t } = useTranslate();
    const currentCourse = useCurrentCourse();
    const { module, chapter, presentationGroups } = usePresentationContext();
    const presentationContextDispatch = usePresentationContextDispatch();
    const history = useHistory();
    const [showAddImprovement, setShowAddImprovement] = useState(false);
    const currentUser = useCurrentUser();
    const courseGroups = useGroupsInCourse();

    const currentGroup = useCurrentGroup();

    const groupsInCourse = useMemo(() => {
        // Create obj for multiselect using course groups
        return courseGroups
            ?.filter(t => t !== undefined)
            ?.map((t, _) => ({
                id: t.id,
                label: t.name,
                checked: !!presentationGroups?.find(g => g.id === t.id),
            }));
    }, [courseGroups, presentationGroups]);

    const switchModule = (chapterId: number, module: Module) => {
        trackPosthogEvent("ui", "presentation", "changemodule");
        // @ts-ignore
        history.push(getPresentationLink(chapterId, module.id, null, currentGroup?.id));
    };

    const modulesInChapters = useMemo(() => {
        // @ts-ignore
        return (
            currentCourse?.chapters
                // @ts-ignore
                .sort((a, b) => a.order - b.order)
                .filter(c => c.status === "Active")
                .map(c => ({
                    id: c.id,
                    name: c.name,
                    // @ts-ignore
                    modules: c.modules.sort((a, b) => a.order - b.order).filter(m => m.status === "Active"),
                }))
        );
    }, [currentCourse]);

    const selectedModuleChapter = useMemo(() => {
        return (
            modulesInChapters &&
            chapter &&
            module &&
            modulesInChapters?.find(c => c.id === chapter?.id)?.modules.find(m => m.id === module?.id)
        );
    }, [modulesInChapters, chapter, module]);

    const selectedModuleName = (name: string) => {
        return <span>{name}</span>;
    };

    const updatePresentationGroups = (groupId: number, checked: boolean) => {
        trackPosthogEvent("ui", "presentation", "filtergroups");

        // @ts-ignore
        let selectedGroups = [...groupsInCourse?.filter(x => x?.checked)?.map(x => x.id), groupId];
        if (checked) {
            selectedGroups = [...selectedGroups, groupId];
        } else {
            selectedGroups = [...selectedGroups.filter(x => x !== groupId)];
        }
        presentationContextDispatch(s => ({
            ...s,
            presentationGroups: courseGroups?.filter(x => selectedGroups?.includes(x.id)),
        }));
    };

    const resetPresentationGroups = () => {
        presentationContextDispatch(s => ({
            ...s,
            presentationGroups: [],
        }));
    };

    const allSelectedTags = groupsInCourse && groupsInCourse?.filter(t => t?.checked);
    const selectedAndOtherTagsCount = allSelectedTags?.length === groupsInCourse?.length;
    const getFirstSelectedItem = allSelectedTags && allSelectedTags.length > 0 ? allSelectedTags[0] : null;

    const currentGroupOutsidePresentation = useCurrentGroup();

    return (
        <div className="navbar-custom-container">
            {showAddImprovement && (
                <TaskCardEditor
                    create={true}
                    task={{
                        // @ts-ignore

                        clientId: currentGroup.clientId,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        // @ts-ignore

                        createdInGroupId: currentGroup.id,
                        // @ts-ignore

                        groupId: currentGroup.id,
                        sortIndex: 0,
                        status: "New",
                        title: "",
                        description: "",

                        priority: "Normal",
                        // @ts-ignore

                        createdByUserId: currentUser.id,
                    }}
                    show={showAddImprovement}
                    handleClose={() => setShowAddImprovement(false)}
                    onSave={() => {
                        customEvents.raiseUiImprovementCreatedFrom("NavBar");
                        showToast(t("WORKSHOP_IMPROVEMENT_SENT_TO_TEAM_BOARD"), "information");
                    }}
                />
            )}

            <Navbar bg="light" expand="lg" fixed="top" id="ws-footer-navbar">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="d-flex-md" style={{ wordBreak: "normal" }}>
                        <Nav className="me-auto ms-5">
                            <div>
                                <i className="icon fa fa-users" />
                            </div>
                            <MultiSelectToggle
                                // @ts-ignore
                                buttonText={
                                    selectedAndOtherTagsCount
                                        ? t("COMMON_ALL")
                                        : getFirstSelectedItem
                                        ? `${getFirstSelectedItem?.label} ${
                                              // @ts-ignore
                                              allSelectedTags.length > 1
                                                  ? // @ts-ignore
                                                    `(+${allSelectedTags.length - 1})`
                                                  : ""
                                          }`
                                        : t("WORKSHOP_PRESENTATION_SELECT_GROUP")
                                }
                                // @ts-ignore
                                options={groupsInCourse}
                                onChange={(value: string | number, checked?: boolean) =>
                                    // @ts-ignore
                                    updatePresentationGroups(value as number, checked)
                                }
                                onClear={() => resetPresentationGroups()}
                                toggleClassName="dropdown-toggle"
                            />

                            {(!groupsInCourse || (groupsInCourse && groupsInCourse.length === 0)) && (
                                <Nav.Link as={Link} to="#" className="">
                                    {currentGroup?.name}
                                </Nav.Link>
                            )}
                            <div className="ms-3">
                                <i className="icon fa fa-clipboard" />
                            </div>
                            {modulesInChapters && modulesInChapters.length > 0 && (
                                <NavDropdown
                                    // @ts-ignore
                                    title={selectedModuleName(module?.name)}
                                    id="collasible-nav-dropdown justified"
                                    className="workshop-modules-list pl-0 d-flex"
                                >
                                    <span>{selectedModuleChapter?.name}</span>
                                    {modulesInChapters?.map(c =>
                                        c.modules.map((m, ix) => (
                                            <NavDropdown.Item
                                                key={`navitem_${ix}`}
                                                active={!!(c.id === chapter?.id && m.id === module?.id)}
                                                // @ts-ignore
                                                onClick={() => switchModule(c.id, m)}
                                            >
                                                <span>{m.name}</span>
                                            </NavDropdown.Item>
                                        ))
                                    )}
                                </NavDropdown>
                            )}

                            <GroupByOptions />
                        </Nav>
                        <Nav className="me-4">
                            <button onClick={() => setShowAddImprovement(true)} className="btn add-improvement">
                                <i className="fa fa-plus me-1" />
                                {t("WORKSHOP_ADD_IMPROVEMENT")}
                            </button>
                        </Nav>
                        <Nav className="me-5">
                            {!props.isFullscreen && (
                                <Nav.Link onClick={() => props.enterFullscreen()}>
                                    <i className="fas fa-expand me-2" />
                                    {t("WORSKHOP_PRESENTATION_ENTER_FULLSCREEN")}
                                </Nav.Link>
                            )}

                            {props.isFullscreen && (
                                <Nav.Link onClick={() => props.exitFullscreen()}>
                                    <i className="fas fa-compress me-2" /> {t("WORSKHOP_PRESENTATION_EXIT_FULLSCREEN")}
                                </Nav.Link>
                            )}

                            {props.isFullscreen === false && (
                                <Nav.Link
                                    as={Link}
                                    to={UrlFactory.team.program.create({
                                        groupId: currentGroupOutsidePresentation!.id!,
                                    })}
                                >
                                    <i className="fa fa-times" /> {t("WORKSHOP_PRESENTATION_EXIT")}
                                </Nav.Link>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
