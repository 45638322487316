﻿import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { FormInput } from "@/components";

export interface MembershipTypeModel {
    isParticipant: boolean;
    isGroupLeader: boolean;
}

export const MembershipType = {
    participant: "participant",
    leader: "leader",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MembershipType = ConstEnum<typeof MembershipType>;

export const convertToMembershipTypeModel = (membershipTypes: MembershipType[]): MembershipTypeModel => {
    return {
        isGroupLeader: membershipTypes.any(x => x === MembershipType.leader),
        isParticipant: membershipTypes.any(x => x === MembershipType.participant),
    };
};

export const convertToMembershipType = (membershipTypes: MembershipTypeModel): MembershipType[] => {
    const result: MembershipType[] = [];

    if (membershipTypes.isGroupLeader) {
        result.push(MembershipType.leader);
    }
    if (membershipTypes.isParticipant) {
        result.push(MembershipType.participant);
    }

    return result;
};

export const membershipTypeSchema = () => {
    return Yup.array().required("EDITEMPLOYEE_TEAM_ROLE_IS_REQUIRED").min(1, "EDITEMPLOYEE_TEAM_ROLE_IS_REQUIRED");
};

export const MembershipTypeInput = (props: { fieldName: string }) => {
    const { t } = useTranslate();

    return (
        <FormInput.CheckboxGroup
            withAsterisk
            fieldName={props.fieldName}
            label="EDITEMPLOYEE_TEAM_ROLES"
            options={[
                { label: t("EDITEMPLOYEE_PARTICIPANT"), value: MembershipType.participant },
                {
                    label: t("EDITEMPLOYEE_TEAMLEADER"),
                    value: MembershipType.leader,
                },
            ]}
        />
    );
};
