import { ExpertCoursesUseByClients } from "@/api-client";
import React, { useEffect, useState } from "react";
import { createApi } from "../../../common/api";
import { useCurrentUser } from "../../../common/user/context";
import { AppLoader } from "../../../components/Spinner";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface ClientCoursesState {
    result: ExpertCoursesUseByClients[];
    isPending: boolean;
    error: string;
}

const MyCoursesClients = () => {
    const user = useCurrentUser();
    const api = createApi();

    const [clientCoursesReport, setClientCoursesReport] = useState<ClientCoursesState>({
        //@ts-ignore
        result: null,
        isPending: false,
        //@ts-ignore
        error: null,
    });
    useEffect(() => {
        if (user !== null) {
            getClientCoursesReport();
        }
    }, [user]);

    const getClientCoursesReport = () => {
        setClientCoursesReport({ ...clientCoursesReport, isPending: true });
        api.reports.getClientsUsedCourses(user?.id).then(
            result => {
                setClientCoursesReport({ ...clientCoursesReport, isPending: false, result });
            },
            error => {
                console.log(error, "Error in getting client courses report");
            }
        );
    };

    return (
        <>
            <AppLoader loading={clientCoursesReport.isPending} className="position-absolute" />
            <table className="table table-borderless table-striped">
                <thead>
                    <tr className="border-bottom">
                        <th>Client Name</th>
                        <th>Course Name</th>
                        <th>Users Assigned</th>
                    </tr>
                </thead>
                <tbody>
                    {clientCoursesReport.result &&
                        clientCoursesReport.result &&
                        clientCoursesReport.result.length > 0 &&
                        clientCoursesReport.result.map(m => {
                            return (
                                <tr>
                                    <td>{m.clientName}</td>
                                    <td>{m.courseName}</td>
                                    <td>{m.assignedUsersCount}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {clientCoursesReport &&
                clientCoursesReport.result &&
                clientCoursesReport.result.length === 0 &&
                clientCoursesReport.isPending === false && (
                    <p className="organisation-not-found text-center mb-5">No course is assigned to Client</p>
                )}
        </>
    );
};
export default MyCoursesClients;
