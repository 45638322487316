﻿/* eslint-disable react/prop-types */
import { Answer, Group, Question } from "@/api-client";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../common/api";
import { useCurrentGroupCourseContextDispatch } from "../../../common/courses/context";
import { createPatch } from "../../../common/patchHelper";
import { ContentLabel } from "../../../components/ContentLabel";
import UserCard from "../../../components/UserCard";
import stringToColor from "../../../lib/StringToColor";
import { SplitAnswer } from "./SplitAnswer";
import "./style.scss";
import { ColoredCategory } from "../QuestionCategoriesEditor/ColoredCategory";
import { QuestionCategory } from "./QuestionCategory";

interface QuestionRatingBlockProps {
    question: Question;
    categories: ColoredCategory[];
    currentGroup: Group;
    questionAnswers: Answer[];
}

const QuestionRatingBlock = ({ question, categories, questionAnswers }: QuestionRatingBlockProps) => {
    const { t } = useTranslate();
    const api = createApi();
    const [answers, setAnswers] = useState(questionAnswers);

    const answersDispatch = useCurrentGroupCourseContextDispatch();

    const setAnswerCategory = (answer: Answer, newCategoryId: string | null) => {
        const patch = createPatch(answer, a => {
            a.answerCategoryId = newCategoryId;
        });

        api.answers.patch(answer.id, patch);
        setAnswers(state => [...state.filter(a => a.id !== answer.id), answer]);
        answersDispatch(s => ({
            ...s,
            // @ts-ignore
            questions: s.questions.filter(q =>
                q.id === answer.questionId
                    ? {
                          ...q,
                          // @ts-ignore
                          answers: q.answers.filter(a =>
                              a.id === answer.id
                                  ? {
                                        ...a,
                                        ...answer,
                                    }
                                  : a
                          ),
                      }
                    : q
            ),
        }));
    };

    useEffect(() => {
        if (question) {
            // @ts-ignore

            setAnswers(question.answers);
        }
    }, [question]);

    const setAnswerVisibility = (answer: Answer, hidden: boolean) => {
        const patch = createPatch(answer, a => {
            a.hidden = hidden;
        });
        answersDispatch(s => ({
            ...s,
            // @ts-ignore

            questions: s.questions.filter(q =>
                q.id === answer.questionId
                    ? {
                          ...q,
                          // @ts-ignore

                          answers: q.answers.filter(a =>
                              a.id === answer.id
                                  ? {
                                        ...a,
                                        ...answer,
                                    }
                                  : a
                          ),
                      }
                    : q
            ),
        }));
        api.answers.patch(answer.id, patch);
    };

    const categoriesCommon = {
        categories: categories,
        setAnswerCategory: setAnswerCategory,
        setAnswerVisibility: setAnswerVisibility,
    };

    const filteredAnswers = {
        uncategorized: answers?.filter(a => a.answerCategoryId === null),
        categorized: answers
            ?.filter(a => a.answerCategoryId != null)
            .map((m, i) => ({
                ...m,
                order: m.order > 30000 ? i : m.order,
            })),
    };

    return (
        <div className="question-rating-block">
            {answers.length === 0 && <div className="alert alert-info">{t("WORKSHOP_RATING_NOQUESTIONS")}</div>}

            {answers.length > 0 && (
                <>
                    {filteredAnswers.uncategorized.length > 0 && (
                        <>
                            <ContentLabel text={t("WORKSHOP_UNCATEGORIZED_HEADER")} />
                            <QuestionCategory
                                // @ts-ignore
                                name={null}
                                // @ts-ignore
                                color={null}
                                answers={answers
                                    .filter(a => a.answerCategoryId === null)
                                    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())}
                                {...categoriesCommon}
                                allowDelete={true}
                            />
                        </>
                    )}

                    {filteredAnswers.categorized.length > 0 && (
                        <>
                            <ContentLabel text={t("WORKSHOP_CATEGORIES_CATEGORISED_HEADER")} />
                            {categories.map(category => {
                                const categoryAnswers = answers.filter(a => a.answerCategoryId === category.id);
                                return (
                                    categoryAnswers.length > 0 && (
                                        <QuestionCategory
                                            categoryId={category.id}
                                            key={category.id}
                                            name={category.name}
                                            color={category.color}
                                            answers={categoryAnswers}
                                            {...categoriesCommon}
                                            allowDelete={true}
                                        />
                                    )
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default QuestionRatingBlock;
