import { useTranslate } from "@tolgee/react";
import { Text, SimpleGrid, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { CardBlock, Form, FormInput, AppLoader, box, InitialFormValue } from "@/components";
import { createApi, createPatchFromDiff, useCurrentClient, yupExtensions } from "@/common";
import { Client } from "@/api-client";
import { ImageInput } from "../ImageInput";

const validator = Yup.object({
    name: yupExtensions.requiredNonEmptyString("ORGANISATION_DETAILS_NAME_ERROR"),
    image: yupExtensions.requiredNonEmptyString("ORGANISATION_DETAILS_LOGO_ERROR"),
});

type Schema = Yup.InferType<typeof validator>;

export const EditOrganisationForm = () => {
    const clientId = useCurrentClient().id;
    const [client, setClient] = useState<Client | null>(null);

    const api = createApi();

    useEffect(() => {
        api.clients.get(clientId).then(setClient).catchWithToast();
    }, []);

    if (!client) {
        return <AppLoader loading />;
    }

    const initialValues: InitialFormValue<Schema> = {
        image: box(client.logo),
        name: box(client.name),
    };

    const saveOrganisationDetails = async (values: Schema) => {
        const patch = createPatchFromDiff(client, {
            logo: values.image,
            name: values.name,
        });

        if (patch.length > 0) {
            await api.clients.patch(client.id, patch);
        }
    };

    return (
        <Form<Schema>
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={async values => saveOrganisationDetails(values).catchWithToastAsync()}
            eventName={{ object: "ao_editorganization", action: "save" }}
            submitLabel="COMMON_SAVE"
        >
            <SimpleGrid cols={2}>
                <Stack>
                    <FormInput.Text fieldName="name" label="ORGANISATION_DETAILS_NAME" />
                    <ImageInput fieldName="image" label="ORGANISATION_DETAILS_LOGO" imageProps={{ height: 100 }} />
                </Stack>
            </SimpleGrid>
        </Form>
    );
};

export const EditOrganisation = () => {
    const { t } = useTranslate();

    return (
        <CardBlock>
            <Stack>
                <Text size="xl">{t("ACCOUNTOWNER_EDIT_ORGANISATION_LABEL")}</Text>
                <EditOrganisationForm />
            </Stack>
        </CardBlock>
    );
};
