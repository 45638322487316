import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { useAllGroups } from "../common/OrganisationContext";
import { ContentLabel } from "../components/ContentLabel";
import OrganisationReport from "./Components/MyOrganisation/OrganisationReport";
import TrainingOverview from "./Components/TrainingOverview";
import { AccountOwnerLayout } from "./Layout";
import "./style.scss";
import { Space } from "@mantine/core";
import { CardBlock, NavButton } from "@/components";

function AccountOwnwer() {
    const { t } = useTranslate();

    const groups = useAllGroups();

    return (
        <AccountOwnerLayout show={false} showMainNav={true}>
            <Container>
                <Row>
                    {/** Content area */}
                    <Col md={12} className="content-area p-2">
                        <CardBlock mb="xl">
                            <ContentLabel text={t("ACCOUNTOWNER_MYORGANISATION_HEADING")} />
                            <NavButton
                                link="/account-owner/edit/organisation-planning/organisation"
                                textKey="ACCOUNTOWNER_MYORGANISATION_MANAGE_ORGANISATIONS"
                            />
                            <Space h="sm" />
                            <OrganisationReport
                                buttonTitle={t("ACCOUNTOWNER_MYORGANISATION_VIEW_DETAILS")}
                                link={`/account-owner/edit/organisation-planning`}
                                // @ts-ignore

                                groups={groups
                                    ?.filter(g => g.groupTypeId !== null)
                                    ?.sort((a, b) =>
                                        a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
                                    )}
                            />
                        </CardBlock>
                        <TrainingOverview showButtons />
                    </Col>
                </Row>
            </Container>
        </AccountOwnerLayout>
    );
}

export default AccountOwnwer;
