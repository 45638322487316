import { useTranslate, useTolgee } from "@tolgee/react";
import { useMemo } from "react";
import { Divider, Flex, Group, Text } from "@mantine/core";
import { TaskPriority, TasksStatus } from "@/api-client";
import { fromNow } from "../../../../common/date";
import { useStyles } from "./taskPreviewStyles";
import { Avatar } from "@/components";
import { Row } from "./Row";
import { UpdatedAt } from "./UpdatedAt";
import { useBoardContext } from "../../BoardContext";

type TaskPreviewProps = {
    title?: string;
    description?: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: string;
    category?: string;
    status: TasksStatus;
    priority: TaskPriority;
    assigneeId: number | undefined | null;
};

export const TaskPreview = (props: TaskPreviewProps) => {
    const { t } = useTranslate();
    const tolgee = useTolgee(["language"]);
    const { classes } = useStyles();
    const boardContext = useBoardContext();

    const assignee = useMemo(() => {
        const user = boardContext.state.activeUsers.find(x => x.id === props.assigneeId);
        if (user === undefined) {
            return null;
        }
        return { id: user.id, fullName: user.fullName, image: user.image };
    }, [boardContext.state.activeUsers, props.assigneeId]);

    const getStatusTranslationKey = {
        New: "BOARD_TASK_STATUS_New",
        Todo: "BOARD_TASK_STATUS_Todo",
        Active: "BOARD_TASK_STATUS_Active",
        Completed: "BOARD_TASK_STATUS_Completed",
        Archived: "BOARD_TASK_STATUS_Archived",
    };

    const getPriorityTranslationKey = {
        Normal: "BOARD_TASK_PRIORITY_Normal",
        High: "BOARD_TASK_PRIORITY_High",
    };

    return (
        <Flex direction="column" align="flex-start" className={classes.container}>
            <Text className={classes.title}>{props.title}</Text>
            {props.description ? <Text lineClamp={4}>{props.description}</Text> : null}
            <Flex direction="column" mt="xl">
                <Group>
                    <Flex gap="0.5rem" align="center">
                        <Text weight="bold">{t("BOARD_TASK_ASSIGNED")}</Text>
                        <Flex gap="0.2rem" align="center">
                            {assignee !== null ? (
                                <>
                                    <Avatar userId={assignee.id} image={assignee.image} fullName={assignee.fullName} />
                                    <Text>{assignee.fullName}</Text>
                                </>
                            ) : (
                                "-"
                            )}
                        </Flex>
                    </Flex>
                    <Row label={t("BOARD_TASK_CATEGORY")} text={props.category ?? "-"} />
                    <Row label={t("BOARD_TASK_PRIORITY")} text={t(getPriorityTranslationKey[props.priority])} />
                    <Row label={t("BOARD_TASK_STATUS")} text={t(getStatusTranslationKey[props.status])} />
                </Group>
                <Divider className={classes.divider} />
                <Flex align="flex-start" direction="column" gap="0.5rem">
                    <UpdatedAt updatedAt={props.updatedAt} />
                    <Flex direction="column">
                        <Row
                            label={t("BOARD_TASK_CREATED")}
                            text={`${fromNow(props.createdAt, tolgee.getLanguage()!)}`}
                        />
                        <Row label={t("BOARD_TASK_BY")} text={`${props.createdBy ?? ""}`} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
