import { createStyles, Divider, Text } from "@mantine/core";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { Course } from "@/api-client";
import UserAvatar from "../UserAvatar";
import { GroupMembersWithProgress } from "./helper";
import { UserProgress } from "./UserProgress";

const useAttendeesStyles = createStyles(theme => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "50% auto",
        columnGap: theme.spacing.md,
        rowGap: theme.spacing.md,
    },
    userProgress: {
        display: "flex",
        alignItems: "center",
    },
    divider: {
        gridColumn: "1 / 3",
    },
}));

interface AttendeesProps {
    participantsProgress: GroupMembersWithProgress[];
    currentCourse: Course;
}

export const Attendees = ({ participantsProgress, currentCourse }: AttendeesProps): JSX.Element => {
    const { t } = useTranslate();
    const { classes } = useAttendeesStyles();
    return (
        <div className={classes.grid}>
            <Text size="sm" tt="uppercase" className="text-muted">
                {t("GROUP_PARTICIPANTS_TABLE_NAME")}
            </Text>
            <Text size="sm" tt="uppercase" className="text-muted">
                {t("GROUP_PARTICIPANTS_PROGRESS")}
            </Text>
            <Divider className={classes.divider} />
            {participantsProgress.map(o => (
                <React.Fragment key={o.userId.toString()}>
                    <div>
                        <UserAvatar user={o.user} />
                    </div>
                    <div className={classes.userProgress}>
                        <UserProgress userProgress={o.progress} currentCourse={currentCourse} i={o.userId} />
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};
