import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { DemoGraphicQuestionsTemplateProps } from ".";
import { DisplayInlineError } from "../../../components/DisplayInlineError";
import StyledRadioButton from "../../../components/StyledRadioButton";

export const RadioTypeQuestion = (props: DemoGraphicQuestionsTemplateProps) => {
    const { question, answer, error } = props.questionAndAnswer;
    const { id, label, options, required } = question;

    // @ts-ignore
    const questionOptions = options.split("\n");
    // @ts-ignore
    const [answerValues, setAnswerValues] = useState<string>(answer?.value);

    const handleChange = (answerValue: string): void => {
        setAnswerValues(answerValue);
        props.setAnswers(answerValue, answer?.id);
    };

    return (
        <Col className="mb-3" key={`radio-type-question-${id}`}>
            <label htmlFor={`formControl${label}`} className="form-label demographic-questions-label">
                {label}
            </label>
            {questionOptions &&
                questionOptions.map((o, i) => (
                    <div className="radio-type-question mt-2" key={`radio-question-layout-${i}`}>
                        <StyledRadioButton
                            key={`radio-question-${i}`}
                            onChange={value => handleChange(value as unknown as string)}
                            value={o}
                            checked={o === answerValues}
                            name={o}
                            id={`radio_type_question_${o.split(/\s/).join("")}-${i}`}
                            className="form-check-input"
                            label={o}
                        />
                    </div>
                ))}
            {required === true && error && <DisplayInlineError error={error} />}
        </Col>
    );
};
