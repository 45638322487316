import { Answer, AnswerCategory } from "@/api-client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import scrollToElement from "scroll-to-element";
import { ContentLabel } from "../../../components/ContentLabel";
import "./style.scss";
import { CategoryCard } from "./CategoryCard";
import { Button } from "@mantine/core";
import { Icons } from "../../../common/Icons";
import { ColoredCategory } from "./ColoredCategory";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";
import { SecondaryButton } from "../../../components/Button/SecondaryButton";

interface AddCategoryProps {
    addCategory: (name: string) => void;
    showModal: boolean;
    onHide: () => void;
}

const AddCategory = ({ addCategory, showModal, onHide }: AddCategoryProps) => {
    const { t } = useTranslate();
    const [name, setName] = useState("");

    const add = () => {
        addCategory(name);
        setName("");

        // Hide modal after add
        onHide();
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            add();
        }
    };

    return (
        <Modal show={showModal} size={"lg"} onHide={onHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("WORKSHOP_CATEGORIZATION_ADD_CATEGORY")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-inline">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                        {t("COMMON_NAME")}
                    </label>
                    <input
                        autoFocus
                        type="text"
                        className="form-control mb-2 me-sm-2 w-100"
                        id="inlineFormInputName2"
                        placeholder={t("WORKSHOP_RATING_PLACEHOLDER")}
                        value={name}
                        onKeyDown={onKeyDown}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton
                    eventName={{ object: "categorize", action: "addcategory" }}
                    disabled={!name || name.length === 0 ? true : false}
                    onClick={() => add()}
                >
                    {t("WORKSHOP_RATING_SUBMIT")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};

interface EditCategoryProps {
    show: boolean;
    category: AnswerCategory;
    rename: (name: string) => void;
    remove: () => void;
    hide: () => void;
}

const EditCategory = ({ show, category, rename, remove, hide }: EditCategoryProps) => {
    const { t } = useTranslate();
    const [name, setName] = useState(category.name);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 && name && name.length > 0) {
            rename(name);
            e.preventDefault();
        }
    };

    return (
        <Modal show={show} onHide={hide} size={"lg"} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Rename category {category.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-inline">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                        {t("WORKSHOP_RATING_NAME")}
                    </label>
                    <input
                        type="text"
                        className="form-control mb-2 me-sm-2 col"
                        id="inlineFormInputName2"
                        placeholder={t("WORKSHOP_RATING_PLACEHOLDER")}
                        onKeyDown={onKeyDown}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton
                    danger
                    onClick={() => remove()}
                    eventName={{ object: "categorize", action: "removecategory" }}
                >
                    <i className="fa fa-trash"></i> {t("WORKSHOP_REMOVE")}
                </SecondaryButton>
                <PrimaryButton
                    eventName={{ object: "categorize", action: "renamecategory" }}
                    disabled={!name || name.length === 0}
                    onClick={() => rename(name)}
                >
                    {t("WORKSHOP_RENAME")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};

interface QuestionCategoriesEditorProps {
    categories: ColoredCategory[];
    answers: Answer[];
    addCategory: (name: string) => void;
    removeCategory: (category: AnswerCategory) => void;
    renameCategory: (category: AnswerCategory, name: string) => void;
}

export const QuestionCategoriesEditor = ({
    categories,
    answers,
    addCategory,
    removeCategory,
    renameCategory,
}: QuestionCategoriesEditorProps) => {
    const { t } = useTranslate();
    const coloredCategories = categories.filter(x => x.name !== null);
    const [categoryToEdit, setCategoryToEdit] = useState<AnswerCategory>();
    const [showAddCategoryModal, setShowAddCategoryModal] = useState<boolean>(false);

    const onRename = (newName: string) => {
        if (categoryToEdit) {
            renameCategory(categoryToEdit, newName);
            setCategoryToEdit(undefined);
        }
    };

    const onRemove = () => {
        if (categoryToEdit) {
            removeCategory(categoryToEdit);
            setCategoryToEdit(undefined);
        }
    };

    const categoryAnswerCount = categories.map(c => ({
        category: c,
        answerCount: answers.filter(a => a.answerCategoryId === c.id).length,
    }));

    const scrollToCategory = (catId: string) => {
        scrollToElement(`#c${catId}`, {
            offset: -65,
        });
    };

    return (
        <>
            <ContentLabel text={t("WORKSHOP_CATEGORIES_AVAILABLE_CATEGORIES_HEADER")} />
            <div className="categories-editor border p-3 rounded mb-4 p-4">
                <div className="row">
                    <div className="col">
                        {coloredCategories.length === 0 && (
                            <p className="empty-message">{t("WORKSHOP_RATING_CREATECATEGORIES")}</p>
                        )}
                        <div className="categories-container">
                            {coloredCategories
                                .alphabeticSort(t => t.name)
                                .map((category, i) => {
                                    return (
                                        <CategoryCard
                                            color={category.color.hex}
                                            counter={
                                                // @ts-ignore
                                                categoryAnswerCount.find(x => x.category.id === category.id).answerCount
                                            }
                                            label={category.name}
                                            editIcon={true}
                                            editCallback={() => setCategoryToEdit(category)}
                                            onClick={() => scrollToCategory(category.id)}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                    <div className="col col-sm-2 justify-content-end">
                        <Button
                            leftIcon={<Icons.Add />}
                            type="button"
                            variant={"default"}
                            onClick={() => setShowAddCategoryModal(true)}
                        >
                            {t("WORKSHOP_CATEGORIZATION_ADD_CATEGORY")}
                        </Button>
                    </div>
                </div>

                {categoryToEdit && (
                    <EditCategory
                        show={categoryToEdit != null}
                        hide={() => setCategoryToEdit(undefined)}
                        category={categoryToEdit}
                        rename={onRename}
                        remove={onRemove}
                    />
                )}
            </div>
            <AddCategory
                addCategory={addCategory}
                showModal={showAddCategoryModal}
                onHide={() => setShowAddCategoryModal(false)}
            />
        </>
    );
};
