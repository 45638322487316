﻿import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useHistory } from "@/common";
import { FullLogo } from "../../assets/index";
import RenderIf from "../../components/render-if/render-if";
import { LoginContainer } from "../../login/LoginContainer";
import { SignInWithEmail } from "./SignInWithEmail";
import { SignInWithPhone } from "./SignInWithPhone";
import { useAuth } from "../../auth/useAuth";
import "./style.scss";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { useStyles } from "./useStyles";
import { redirectUserAfterLogin } from "../../auth/redirectUserAfterLogin";

export interface LoginTypeModel {
    signInUsingEmail: boolean;
}

const Login = (): JSX.Element | null => {
    const { user } = useAuth();
    const { t } = useTranslate();
    const { classes } = useStyles();
    const history = useHistory();

    const LoginLogo: any = FullLogo;

    //Used to get redirected to correct url when logged in and navigated to /login
    useEffect(() => {
        if (user !== null) {
            return redirectUserAfterLogin(user, history);
        }
    }, []);

    const [loginType, setLoginType] = useState<LoginTypeModel>({ signInUsingEmail: true });

    return (
        <LoginContainer>
            <div className="container">
                <div className="d-flex justify-content-center">
                    <a href="https://www.hups.com" title="www.hups.com">
                        <img src={LoginLogo} className="main-login-logo  " alt="Hups" />
                    </a>
                </div>
                <div className="d-flex justify-content-center h-100">
                    <div className="login-card">
                        <h5 className="text-center mt-3">{t("LOGIN_TITLE")}</h5>
                        <RenderIf show={loginType.signInUsingEmail}>
                            <SignInWithEmail />
                        </RenderIf>
                        <RenderIf show={!loginType.signInUsingEmail}>
                            <SignInWithPhone />
                        </RenderIf>

                        <hr />
                        <div className={classes.otherSigninOptions}>
                            <p>{t("LOGIN_OTHER_OPTIONS")}</p>
                            <PrimaryButton
                                fullWidth
                                onClick={() => {
                                    setLoginType({ signInUsingEmail: !loginType.signInUsingEmail });
                                }}
                            >
                                {loginType.signInUsingEmail ? t("LOGIN_WITH_PHONE") : t("LOGIN_WITH_EMAIL")}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className="login-message">
                    <span>{t("LOGIN_VISIT_US")} </span>
                    <a href="https://www.hups.com">hups.com</a>
                </div>
            </div>
        </LoginContainer>
    );
};

export default Login;
