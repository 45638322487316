import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { createApi } from "../../../common/api";
import { createPatch } from "../../../common/patchHelper";
import { AppLoader } from "../../../components/Spinner";
import profileImagePlaceholder from "../images/profile-image-placeholder.svg";
import { User } from "@/api-client";
import { useAuth } from "../../../auth/useAuth";

interface Props {
    userProfile: User;
    imageUpdatedCallback: (newImage: string) => void;
}

export const ProfileImageEditor = (props: Props) => {
    const Profile_pic = props.userProfile?.image ? props.userProfile.image : profileImagePlaceholder;

    const [isPending, setIsPending] = useState(false);

    // @ts-ignore
    let inputfile = null;

    const { t } = useTranslate();

    const api = createApi();

    const { refresh } = useAuth();

    // @ts-ignore
    function uploadProfileImage(e) {
        const file = e.target.files[0];
        setIsPending(true);

        return api.users
            .uploadProfileImage(
                // @ts-ignore
                props?.userProfile?.id,
                { fileName: file.name, data: file }
            )
            .then(userImageUrl => {
                var patch = createPatch(props.userProfile, x => {
                    x.image = userImageUrl + "?t=" + new Date().getTime();
                });

                api.users
                    .patch(
                        // @ts-ignore
                        props.userProfile.id,
                        patch
                    )
                    .then(
                        response => {
                            refresh(response);
                            setIsPending(false);
                        },
                        error => {
                            console.log(error, "Error in updating user profile image.");
                            setIsPending(false);
                        }
                    );
            });
    }

    function deleteUserImage() {
        var patch = createPatch(props.userProfile, x => {
            // @ts-ignore
            x.image = null;
        });
        api.users
            .patch(
                // @ts-ignore
                props.userProfile.id,
                patch
            )
            .then(
                response => {
                    refresh(response);
                },
                error => {
                    console.log(error, "Error in updating user profile image.");
                }
            );
    }

    return (
        <div className="img_container">
            <div>
                <AppLoader loading={isPending} />
                {/* <BeatLoader size={15} color={"#fff"} loading={isPending} /> */}
                <img src={Profile_pic} alt="Profile" width="100%" className="profile_pic" />
            </div>
            <input
                className="d-none"
                accept="image/jpg, image/png, image/jpeg"
                onChange={uploadProfileImage}
                type="file"
                ref={e => (inputfile = e)}
            />
            <div className="upload_avatar_buttons_container text-center position-relative">
                <Button
                    onClick={() => {
                        // @ts-ignore
                        inputfile.click();
                    }}
                    className="me-1 opex_button_font"
                    variant="success"
                >
                    <i className="fa fa-user me-1 opex_button_font"></i>
                    {t("PROFILE_UPLOAD_NEW_AVATAR")}
                </Button>
                <Button onClick={deleteUserImage} className="me-1 opex_button_font" variant="danger">
                    {t("PROFILE_DELETE_AVATAR")}
                </Button>
            </div>
        </div>
    );
};
