import _ from "lodash";
import { ReportsClient, TaskCountPerClientAndGroupAndUser, User } from "@/api-client";
import { Columns, exportToCsv } from "../csvUtil";
import { IReport } from "../IReport";

/**
 * Implementation of improvements task per user report
 */
export class TaskPerUserReport implements IReport {
    public name;
    public description;

    constructor(private reportsClient: ReportsClient, private users: User[]) {
        this.name = "Improvement tasks per user";
        this.description = "Statistics on improvement tasks for all clients and groups by user and status";
    }

    public downloadReport() {
        return this.reportsClient.taskCountPerClientAndGroupAndUser().then(result => {
            const columns: Columns<ReportWithUser> = {
                ClientName: "clientName",
                GroupName: "groupName",
                ClientId: "clientId",
                GroupId: "groupId",
                AssignedUserId: x => x.assignedToUserId ?? "",
                AssignedUserName: x => x.fullName ?? "",
                Inbox: "inbox",
                "To-Do": "toDo",
                "In-Progress": "inProgress",
                Done: "done",
                Completed: "completed",
            };

            //@ts-ignore
            var resultsWithCourse = _.orderBy(result, "courseId").map(r => {
                return {
                    //@ts-ignore
                    ...r,
                    //@ts-ignore
                    fullName: this.users.find(x => x.id === r.assignedToUserId)?.fullName,
                };
            });

            // @ts-ignore
            exportToCsv(resultsWithCourse, columns, "TaskCountPerClientAndGroup.csv");
        });
    }
}

interface ReportWithUser extends TaskCountPerClientAndGroupAndUser {
    fullName: string;
}
