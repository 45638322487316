import { Course, Group, GroupType, TrainingOverviewReport, TrainingProgressPerGroupReport } from "@/api-client";
import { MapTrainingWithCourseModel, TrainingOverViewReportMapWithCourses } from "./Model";

export const mapTrainingProgressWithCourse = (
    course: Course,
    groups: Group[],
    trainingPerGroup: TrainingProgressPerGroupReport[]
    /* @ts-ignore */
): MapTrainingWithCourseModel[] => {
    if (course && course.chapters && groups && groups.length > 0 && trainingPerGroup && trainingPerGroup.length > 0) {
        const courseGroups = groups
            ?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }))
            /* @ts-ignore */
            .filter(g => g.courseIds.includes(course.id));

        /* @ts-ignore */
        return courseGroups.map((g, i) => {
            return {
                groupName: g.name,
                groupId: g.id,
                groupLeader: trainingPerGroup.find(d => d.groupId === g.id)?.groupLeader,
                courseId: course.id,
                progress: course.chapters.map(c =>
                    c.modules.map(m => {
                        const findDetails = trainingPerGroup.find(p => p.moduleId === m.id && p.groupId === g.id);
                        return {
                            isCompleted:
                                findDetails?.totalCompletedModules === 0
                                    ? null
                                    : findDetails?.isCompletedByAllGroupUsers,
                        };
                    })
                ),
            };
        });
    }
};

export const trainingOverViewReportMapWithCourses = (
    courses: Course[],
    trainingOverViewReport: TrainingOverviewReport[]
    /* @ts-ignore */
): TrainingOverViewReportMapWithCourses[] => {
    if (courses && courses.length > 0 && trainingOverViewReport) {
        const result = trainingOverViewReport.map(training => {
            const course = courses?.find(c => c.id === training.courseId);
            return {
                name: training.courseName,
                courseId: training.courseId,
                totalUsersInOrganisation: training?.totalUsersInOrganisation,
                totalUsersAssignedToCourse: training?.totalUsersAssignedToCourse,
                activeInTraining: training.activeInTraining,
                courseCompletedByUsers: training.courseCompletedByUsers,
                progress: course?.chapters?.map(c =>
                    c?.modules?.map(m => {
                        const findDetails = training?.progresses.find(d => d.moduleId === m.id);
                        return {
                            isCompleted:
                                findDetails && findDetails.totalCompletedModules === 0
                                    ? null
                                    : findDetails?.isCompletedByAllGroupUsers ?? null,
                        };
                    })
                ),
            };
        });

        /* @ts-ignore */
        return result;
    }
};

export interface GroupWithGroupTypeModel {
    groupTypeId: number;
    groupTypeName: string;
    groups: Group[];
}

export const GroupsWithGroupTypes = (groups: Group[], groupTypes: GroupType[]): GroupWithGroupTypeModel[] => {
    if (groups && groupTypes) {
        /* @ts-ignore */
        return (
            groups &&
            groups
                ?.map(g => g.groupTypeId)
                ?.filter((val, idx, arr) => arr.indexOf(val) === idx)
                ?.map(groupTypeId => {
                    return {
                        groupTypeId,
                        groupTypeName: groupTypes?.find(gt => gt.id === groupTypeId)?.name || null,
                        groups: groups?.filter(g => g.groupTypeId === groupTypeId),
                    };
                })
        );
    }
    return [];
};
