import { Avatar as MantineAvatar } from "@mantine/core";
import { FallBackUserImage } from "./UserAvatar/FallbackUserImage";

export type AvatarProps = {
    image?: string;
    fullName?: string;
    userId?: number;
};

export const Avatar = (props: AvatarProps) => (
    <MantineAvatar src={props.image} radius="xl" size="md">
        <FallBackUserImage userId={props.userId} fullName={props.fullName} />
    </MantineAvatar>
);
