import { Flex } from "@mantine/core";
import { Chapter } from "api/clients/GetLatestProgramsResponse";
import React from "react";
import { Icons } from "../../../common/Icons";

type ProgressProps = {
    chapters: Chapter[];
};

export const Progress = (props: ProgressProps) => {
    const progress = props.chapters.map((c, index) => {
        const progressIndicator = c.moduleProgress.map(m => {
            if (m.status === "Completed") {
                return <Icons.ProgramProgress.Completed />;
            }
            if (m.status === "NotStarted") {
                return <Icons.ProgramProgress.NotStarted />;
            }
            if (m.status === "Started") {
                return <Icons.ProgramProgress.InProgress />;
            }
            return null;
        });

        return (
            <Flex key={index} m={4}>
                {progressIndicator.map((pi, idx) => (
                    <Flex key={idx} direction="row" m={1}>
                        {pi}
                    </Flex>
                ))}
            </Flex>
        );
    });

    return (
        <Flex columnGap="xl">
            <Flex align="center" columnGap="2px" fz={8} wrap="wrap">
                {progress}
            </Flex>
        </Flex>
    );
};
