import { useEffect, useState } from "react";
import { Space } from "@mantine/core";
import { GetVideosDto } from "@/api-client";
import { createApi } from "@/common";
import { VideosList } from "./VideosList";

export const VideoLibraryPage = () => {
    const [videos, setVideos] = useState<GetVideosDto[] | null>(null);

    useEffect(() => {
        const abortController = new AbortController();

        createApi().sysadmin.getVideos(abortController).then(setVideos).catchWithToast();

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <VideosList videos={videos} />
            <Space h="lg" />
        </>
    );
};
