﻿export const alphabeticSort = (a: string, b: string) => {
    return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base",
    });
};

type Primitive<T> = T extends string | number | boolean ? T : never;

export {};

/* eslint-disable no-extend-native */

declare global {
    interface Array<T> {
        orderBy(selector: (item: T) => number): T[];

        orderBy(selector: (item: T) => string): T[];

        alphabeticSort(selector: (item: T) => string): T[];

        any(predicate: (item: T) => boolean): boolean;

        uniqueBy(selector: (item: T) => number): T[];

        take(count: number): T[];

        unique(this: Array<Primitive<T>>): T[];
    }
}

if (!Array.prototype.orderBy) {
    const compareFn = (a: any, b: any) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        // a must be equal to b
        return 0;
    };

    Array.prototype.orderBy = function (selector) {
        const copy = [...this];
        copy.sort((a, b) => compareFn(selector(a), selector(b)));
        return copy;
    };
}

if (!Array.prototype.alphabeticSort) {
    Array.prototype.alphabeticSort = function (selector) {
        const copy = [...this];
        copy.sort((a, b) => alphabeticSort(selector(a), selector(b)));
        return copy;
    };
}

if (!Array.prototype.any) {
    Array.prototype.any = function (predicate) {
        return this.some(predicate);
    };
}

if (!Array.prototype.uniqueBy) {
    Array.prototype.uniqueBy = function (selector) {
        return this.filter((item, index, self) => self.findIndex(x => selector(x) === selector(item)) === index);
    };
}

if (!Array.prototype.take) {
    Array.prototype.take = function (count) {
        return this.slice(0, count);
    };
}

if (!Array.prototype.unique) {
    Array.prototype.unique = function <T>() {
        return [...new Set<T>(this)];
    };
}
