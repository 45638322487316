import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import { Link, useLocation } from "react-router-dom";
import { LogoWhite } from "../../../assets";
import { useCurrentClient, useCurrentUser } from "../../../common/user/context";
import { UserHasOneOfRoles, UserHasSpecificRole } from "../../../common/user/utils";
import UserAvatar from "../../../components/UserAvatar";
import { AGENT_ROLE, EXPERT_ROLE, SYSADMIN_ROLE } from "../../../constants";
import "./styles.scss";
import { useAuth } from "../../../auth/useAuth";
import { UrlFactory } from "../../../routing/UrlFactory";

export const MainNavbar = () => {
    const { t } = useTranslate();
    const currentClient = useCurrentClient();
    const currentUser = useCurrentUser();
    const { signOut } = useAuth();

    const handleLogout = () => {
        signOut();
    };

    const location = useLocation();

    const isSysadmin: boolean = UserHasOneOfRoles(currentUser, [SYSADMIN_ROLE]);
    const isAgent: boolean = UserHasSpecificRole(currentUser, AGENT_ROLE);
    const isExpert: boolean = UserHasSpecificRole(currentUser, EXPERT_ROLE);

    if (!currentUser) {
        //return null;
    }

    return (
        <>
            {currentUser && (
                <Navbar className="sysadmin-nav" bg="dark" expand="lg" fixed="top" id="main-navbar">
                    <Container>
                        <Navbar.Brand href="/sysadmin">
                            {isSysadmin || isExpert || isAgent ? (
                                <img src={LogoWhite} alt="Logo" className="main-logo" />
                            ) : (
                                currentClient &&
                                currentClient.logo && <img src={currentClient?.logo} alt="Logo" className="main-logo" />
                            )}
                            <span className="title d-md-none">{currentClient?.name} </span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="d-flex-md justify-content-center">
                            <Nav>
                                {(isSysadmin || isExpert || isAgent) && (
                                    <Nav.Link as={Link} to="/sysadmin" active={location.pathname === "/sysadmin"}>
                                        {t("SYSADMIN_HOME")}
                                    </Nav.Link>
                                )}
                                {(isSysadmin || isExpert) && (
                                    <Nav.Link
                                        as={Link}
                                        to="/sysadmin/courses"
                                        active={location.pathname.startsWith("/sysadmin/courses")}
                                    >
                                        {t("SYSADMIN_PROGRAMS_AND_CAPABILITIES")}
                                    </Nav.Link>
                                )}
                                ,
                                {(isSysadmin || isExpert) && (
                                    <Nav.Link
                                        as={Link}
                                        to={UrlFactory.sysadmin.programs.pattern}
                                        active={location.pathname.startsWith(UrlFactory.sysadmin.programs.pattern)}
                                    >
                                        {t("SYSADMIN_PROGRAM_OVERVIEW")}
                                    </Nav.Link>
                                )}
                                {(isSysadmin || isAgent) && (
                                    <Nav.Link
                                        as={Link}
                                        active={location.pathname.startsWith("/sysadmin/clients")}
                                        to="/sysadmin/clients"
                                    >
                                        {t("SYSADMIN_CLIENTS")}
                                    </Nav.Link>
                                )}
                                {(isSysadmin || isExpert) && (
                                    <Nav.Link
                                        as={Link}
                                        to="/sysadmin/video-library"
                                        active={location.pathname.startsWith("/sysadmin/video-library")}
                                    >
                                        Video Library
                                    </Nav.Link>
                                )}
                                {isSysadmin && (
                                    <Nav.Link
                                        as={Link}
                                        to="/sysadmin/users"
                                        active={location.pathname.startsWith("/sysadmin/users")}
                                    >
                                        {t("SYSADMIN_USERS")}
                                    </Nav.Link>
                                )}
                                {isSysadmin && (
                                    <Nav.Link
                                        as={Link}
                                        to="/sysadmin/statistics"
                                        active={location.pathname.startsWith("/sysadmin/statistics")}
                                    >
                                        {t("SYSADMIN_STATISTICS")}
                                    </Nav.Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                        <div className="d-none d-md-inline-block">
                            <UserAvatar displayName={false} user={currentUser} />
                        </div>
                        <NavDropdown
                            title={`${currentUser?.firstName} ${currentUser?.lastName}`}
                            id="collasible-nav-dropdown"
                            className="p-0 ms-0 d-none d-md-block"
                            style={{ width: "176px" }}
                        >
                            <NavDropdown.Item as={Link} to={UrlFactory.sysadmin.profile.create({})}>
                                {t("HEADER_NAV_EDIT_PROFILE")}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => handleLogout()}>{t("HEADER_NAV_LOGOUT")}</NavDropdown.Item>
                        </NavDropdown>
                    </Container>
                </Navbar>
            )}
        </>
    );
};

export default MainNavbar;
