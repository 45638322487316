import React from "react";
import ReactMarkdown from "react-markdown";
import { OnboardingVideo } from "../../pages/Onboard/helper";

interface MyContentProps {
    video?: OnboardingVideo;
    description?: string;
    animated: boolean;
}

export function OnboardContent(props: MyContentProps): JSX.Element {
    const { video, description } = props;
    return (
        <div className={video && video.url ? "col-12 col-md-4" : "col-12 col-md-12"}>
            {/* <Animated
             // animationIn="bounceInUp"
             // animationOut="bounceInDown"
             isVisible={animated}
             className="block-content"
             // animationInDelay={animated? 100 : 0}
             // animationOutDelay={animated? 100 : 0}
             // animationInDuration={animated? 3000 : 0}
             // animationOutDuration={animated? 3000 : 0}
             > */}
            <div className="block-content">
                <div className="mediumFont">
                    <ReactMarkdown className="markdown-editor">{description ?? ""}</ReactMarkdown>
                </div>
            </div>
            {/* </Animated> */}
        </div>
    );
}

export const OnboardCard = React.memo(OnboardContent);
