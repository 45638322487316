import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useHistory, createApi } from "@/common";
import { Client, User } from "@/api-client";
import { AuthContext, AuthContextProps } from "./authContext";
import { UrlFactory } from "../routing/UrlFactory";
import { UserStorage } from "./UserStorage";
import { AppLoader } from "@/components";
import { useStateWithListener } from "./useStateWithListener";
import { redirectUserAfterLogin } from "./redirectUserAfterLogin";

type AuthContextProviderState =
    | {
          isLoading: false;
          authenticatedUser: AuthenticatedUser | null;
      }
    | {
          isLoading: true;
      };

export type AuthenticatedUser = {
    user: User;
    token: string;
    client: Client | null;
    isImpersonated: boolean;
};

const getAuthenticatedUser = async (
    token: string,
    impersonation: {
        token: string;
    } | null
): Promise<AuthenticatedUser> => {
    const hasImpersonatedUser = impersonation !== null;

    if (hasImpersonatedUser) {
        const api = createApi(impersonation.token);
        const loginResponse = await api.login.refresh();
        const clientId = loginResponse.user.clientId;
        const client = clientId !== null ? await api.clients.get(clientId!) : null;
        return {
            user: loginResponse.user,
            token: impersonation.token,
            client: client,
            isImpersonated: true,
        };
    }

    const api = createApi(token);
    const loginResponse = await api.login.refresh();
    const clientId = loginResponse.user.clientId;
    const client = clientId !== null ? await api.clients.get(clientId!) : null;
    return {
        user: loginResponse.user,
        token: token,
        client: client,
        isImpersonated: false,
    };
};

const getInitialState = (): [AuthContextProviderState, null | (() => Promise<AuthContextProviderState>)] => {
    const loginToken = UserStorage.getLoginToken();
    if (loginToken === null) {
        return [
            {
                isLoading: false,
                authenticatedUser: null,
            },
            null,
        ];
    }

    return [
        {
            isLoading: true,
        },
        async () => {
            const impersonationToken = UserStorage.getImpersonationToken();
            const authenticatedUser = await getAuthenticatedUser(loginToken, impersonationToken);
            return {
                isLoading: false,
                authenticatedUser: authenticatedUser,
            };
        },
    ];
};

export type AuthContextProviderProps = {
    children?: ReactNode;
    onUserChanged: (state: AuthenticatedUser | null) => void;
};

export const AuthContextProvider = ({ children, ...props }: AuthContextProviderProps) => {
    const onStateChanged = useCallback(
        (state: AuthContextProviderState) => {
            if (state.isLoading) {
                props.onUserChanged(null);
            } else {
                props.onUserChanged(state.authenticatedUser);
            }
        },
        [props]
    );

    const [state, setState] = useStateWithListener<AuthContextProviderState>({ isLoading: true }, onStateChanged);
    const history = useHistory();

    useEffect(() => {
        const [initialState, next] = getInitialState();
        setState(initialState);
        if (next === null) {
            return;
        }

        next().then(s => setState(s));
    }, []);

    const provider = useMemo((): AuthContextProps => {
        const refresh = (user: User) => {
            //Use provided state and not closured state to avoid stale updates
            setState(s => {
                if (s.isLoading) {
                    return s;
                }

                return {
                    ...s,
                    authenticatedUser: {
                        ...s.authenticatedUser!,
                        user: user,
                    },
                };
            });
        };

        const isImpersonating = () => UserStorage.getImpersonationToken() !== null;

        const signIn = async (token: string): Promise<() => void> => {
            const user = await getAuthenticatedUser(token, null);
            UserStorage.setLoginToken(token);
            setState({
                isLoading: false,
                authenticatedUser: user,
            });

            return () => redirectUserAfterLogin(user.user, history);
        };

        const impersonate = async (
            data:
                | {
                      type: "user";
                      userId: number;
                  }
                | {
                      type: "ao";
                      clientId: number;
                  }
        ) => {
            const loginToken = UserStorage.getLoginToken()!;
            const api = createApi(loginToken);

            const getImpersonationToken = async () => {
                if (data.type === "user") {
                    const impersonationResponse = await api.login.impersonate(data.userId);
                    return impersonationResponse.token;
                }

                const impersonationResponse = await api.login.elevateToAccountOwner(data.clientId);
                return impersonationResponse.token;
            };

            const impersonationToken = await getImpersonationToken();

            const user = await getAuthenticatedUser(loginToken, {
                token: impersonationToken,
            });

            UserStorage.setImpersonationToken(impersonationToken);

            setState({
                isLoading: false,
                authenticatedUser: user,
            });

            history.push(UrlFactory.home.create({}));
        };

        const clearImpersonation = async () => {
            UserStorage.clearImpersonationToken();
            const user = await getAuthenticatedUser(UserStorage.getLoginToken()!, null);

            setState({
                isLoading: false,
                authenticatedUser: user,
            });
        };

        const signOut = () => {
            UserStorage.clearAll();
            setState({
                isLoading: false,
                authenticatedUser: null,
            });

            history.push(UrlFactory.login.create({}));
        };

        if (state.isLoading) {
            return {
                user: null,
                token: null,
                client: null,
                signIn: signIn,
                clearImpersonation: clearImpersonation,
                signOut: signOut,
                impersonate: impersonate,
                refresh: refresh,
                isImpersonating: isImpersonating,
            };
        }

        return {
            user: state.authenticatedUser !== null ? state.authenticatedUser.user : null,
            token: state.authenticatedUser !== null ? state.authenticatedUser.token : null,
            client: state.authenticatedUser !== null ? state.authenticatedUser.client : null,
            signIn: signIn,
            clearImpersonation: clearImpersonation,
            signOut: signOut,
            impersonate: impersonate,
            refresh: refresh,
            isImpersonating: isImpersonating,
        };
    }, [history, setState, state]);

    if (state.isLoading) {
        return <AppLoader loading />;
    }

    return <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>;
};
