﻿import { Popover, Divider, UnstyledButton, createStyles } from "@mantine/core";
import { useTranslate } from "@tolgee/react";
import { useAuth } from "auth/useAuth";
import { useAccessToSysadmin } from "sysadmin/useAccessToSysadmin";
import { UserHasSysAdminRole } from "common/user/utils";
import UserAvatar from "components/UserAvatar";
import { User } from "@/api-client";
import { HeaderItem } from "./HeaderItem";

const useStyles = createStyles(theme => {
    return {
        userButton: {
            paddingLeft: theme.spacing.sm,
            paddingRight: theme.spacing.sm,
            "&:hover": {
                backgroundColor: theme.colors.gray[0],
            },
        },
    };
});

export const ProfileHeaderItem = (props: { user: User }) => {
    const { classes } = useStyles();
    const { t } = useTranslate();
    const accessToSysadmin = useAccessToSysadmin();
    const { signOut, isImpersonating } = useAuth();

    const currentUser = props.user;

    const handleLogout = () => {
        signOut();
    };

    return (
        <Popover width={200} offset={0} position="bottom">
            <Popover.Target>
                <UnstyledButton className={classes.userButton}>
                    <UserAvatar user={props.user} displayName={false} />
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <HeaderItem to="/profile" label={t("HEADER_NAV_EDIT_PROFILE")} active={false} />

                {(UserHasSysAdminRole(currentUser) || isImpersonating()) && (
                    <HeaderItem
                        active={false}
                        onClick={() => accessToSysadmin().catchWithToast()}
                        label={t("HEADER_NAV_SYSADMIN")}
                    />
                )}
                <Divider />
                <HeaderItem active={false} onClick={() => handleLogout()} label={t("HEADER_NAV_LOGOUT")} />
            </Popover.Dropdown>
        </Popover>
    );
};
