import { useTranslate, useTolgee } from "@tolgee/react";
import { Row } from "./Row";
import { fromNow } from "../../../../common/date";

export const UpdatedAt = (props: { updatedAt?: Date }) => {
    const { t } = useTranslate();
    const tolgee = useTolgee(["language"]);

    return props.updatedAt ? (
        <Row label={t("BOARD_UPDATED")} text={fromNow(props.updatedAt, tolgee.getLanguage()!)} />
    ) : null;
};
