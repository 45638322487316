import { TFunction } from "@/common";
import { ErrorLoginResult } from "@/api-client";
import { showToast } from "./Notify";
import { exhaustiveCheck } from "../common/exhaustiveCheck";

export const showLoginErrorNotification = (loginResult: ErrorLoginResult, t: TFunction, mobileLogin = false) => {
    switch (loginResult.resultCode) {
        case "UserNotActive":
            showToast(
                `${t("LOGIN_USER_IS_INACTIVE")} ${t("LOGIN_CONTACT_THE_ORGANISATIONS_ADMINISTRATOR_OR_HUPS_SUPPORT")}`,
                "error"
            );
            break;

        case "AuthenticationFailed":
            if (mobileLogin) {
                showToast(t("LOGIN_WITH_PHONE_MOBILE_NUMBER_OR_VERIFICATION_CODE_IS_NOT_CORRECT"), "error");
            } else {
                showToast(t("LOGIN_E-MAIL_OR_PASSWORD_IS_NOT_CORRECT"), "error");
            }
            break;

        case "ClientNotActive":
            showToast(
                `${t("LOGIN_YOUR_ORGANISATION_IS_INACTIVE")} ${t(
                    "LOGIN_CONTACT_THE_ORGANISATIONS_ADMINISTRATOR_OR_HUPS_SUPPORT"
                )}`,
                "error"
            );
            break;

        case "NoGroupMembership":
            showToast(
                `${t("LOGIN_USER_IS_NOT_MEMBER_OF_A_TEAM")} ${t(
                    "LOGIN_CONTACT_THE_ORGANISATIONS_ADMINISTRATOR_OR_HUPS_SUPPORT"
                )}`,
                "error"
            );
            break;

        case "NoActiveGroup":
            showToast(
                `${t("LOGIN_USER_IS_NOT_MEMBER_OF_AN_ACTIVE_TEAM")} ${t(
                    "LOGIN_CONTACT_THE_ORGANISATIONS_ADMINISTRATOR_OR_HUPS_SUPPORT"
                )}`,
                "error"
            );
            break;
        default:
            exhaustiveCheck(loginResult.resultCode);
    }
};
