import { Formik, FormikConfig, FormikValues, Form as FormikForm, FormikProps } from "formik";
import { ReactNode, useMemo } from "react";
import { Submit } from "./Submit";
import { FormContext, FormContextProps } from "./FormContext";
import { EventName } from "./Button/Button";

/* Boxes values into forms. null, undefined are coerced into empty strings since React considers null and undefined inputs to uncontrolled */
export const box = <T,>(value: T | null) => {
    if (value === null || value === undefined) {
        return "";
    }

    if (typeof value === "string") {
        return value.trim();
    }

    throw new Error(`Unsupported type ${typeof value}`);
};

type FormContentProps<T> = {
    initialValues: FormikConfig<InitialFormValue<T>>["initialValues"];
    validationSchema: FormikConfig<InitialFormValue<T>>["validationSchema"];
    onSubmit: ((values: T) => void) | ((values: T) => Promise<void>);
    submitLabel: string;
    children: ReactNode;
    formikProps: FormikProps<InitialFormValue<T>>;
    eventName?: EventName;
};

type FormProps<T> = Omit<FormContentProps<T>, "formikProps">;

const FormContent = <T extends FormikValues>({ formikProps, ...props }: FormContentProps<T>) => {
    const { isSubmitting } = formikProps;
    const hasTouchedFieldsWithErrors = () => {
        const fieldsWithErrors = Object.keys(formikProps.errors);
        return fieldsWithErrors.any(key => formikProps.touched[key] === true);
    };

    //Allow clicking on submit when the form is valid (obviously) or when there aren't errors in the fields the user has touched.
    //The intention is to not bother the user with errors until a field has been touched, and since there are no visible errors the submit button should be enabled
    //pressing submit when the form is otherwise invalid will touch all fields and thus disable submit
    const canSubmit = formikProps.isValid || (formikProps.submitCount === 0 && !hasTouchedFieldsWithErrors());

    const formContextValue: FormContextProps = useMemo(() => {
        return {
            canSubmit: canSubmit,
            isSubmitting: isSubmitting,
        };
    }, [isSubmitting, canSubmit]);

    return (
        <FormikForm>
            <FormContext.Provider value={formContextValue}>
                <>
                    {props.children}
                    <Submit label={props.submitLabel} eventName={props.eventName} />
                </>
            </FormContext.Provider>
        </FormikForm>
    );
};

/*
 * Maps the schema type to a type that has form values that can be gracefully handled by form inputs
 * */
export type InitialFormValue<T> = {
    [Property in keyof T]: T[Property] extends object
        ? InitialFormValue<T[Property]>
        : T[Property] extends boolean | undefined
        ? boolean
        : string;
};

/*
 * Notes on Formik and Yup.
 * Yup is used for data validation, that means that Formik validates that the data CAN be parsed into whatever schema is supplied
 * it doesn't mean that the data IS parsed during onSubmit.
 *
 * Values going into a form presented in a React controlled DOM shouldn't ever be null or undefined.
 * Also, values in DOM nodes are always strings, so any value that's put into an Input that isn't a string will be toString:ed any way.
 *
 * Thus, we chose to coerce values to strings during form initialization (using InitialFormValue<T>) and parse them on submit.
 *
 */
export const Form = <T extends FormikValues>({ children, ...props }: FormProps<T>) => {
    return (
        <Formik<InitialFormValue<T>>
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            onSubmit={async x => {
                //Run validation to force yup parsing before passing value to the parent.
                const value = await props.validationSchema.validate(x);
                await props.onSubmit(value as T);
            }}
        >
            {formikProps => (
                <FormContent {...props} formikProps={formikProps}>
                    {children}
                </FormContent>
            )}
        </Formik>
    );
};
