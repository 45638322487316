import React, { ReactNode } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const SortableTableBody = ({ children }: { children: ReactNode }) => {
    return (
        <tbody className="sysadmin-drag">
            <DndProvider backend={HTML5Backend}>{children}</DndProvider>
        </tbody>
    );
};
