import React from "react";
import { Question } from "@/api-client";
import stringToColor from "../../../../lib/StringToColor";
import { MaterialUiStyledSlider } from "../../../../components/MaterialUiStyledSlider";
import { useTranslate } from "@tolgee/react";

type MatrixScaleQuestionsProps = {
    question: Question;
    placeholder: string;
};

//@ts-ignore
export const MatrixScaleQuestions = React.memo(function MatrixScaleQuestion(props: MatrixScaleQuestionsProps) {
    const { question, placeholder } = props;
    const { allowSkip, maxAnswerLabel, minAnswerLabel } = question;
    const { t } = useTranslate();

    return (
        <div className="block-section">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="block-content-text">
                        <h4>{placeholder}</h4>
                        {question && (
                            <React.Fragment>
                                <p>{question.text}</p>
                                <p>{question.description}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 text-secondary">
                    <div className="block-content-option">
                        {question && (
                            <div className="block-content-option">
                                <p className="px-5 question_type"></p>
                                {question &&
                                    question.options &&
                                    question.options.map((option, ix) => {
                                        return (
                                            <React.Fragment key={`${ix}_${option.id}`}>
                                                <div className={"row " + (ix > 0 ? "mt-5" : "")}>
                                                    <div className="col-sm-12 top-container">
                                                        <div className="matrix-title">{option.text}</div>
                                                        {allowSkip === true && (
                                                            <div
                                                                className="matrix-skip-button"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                <label>
                                                                    <input
                                                                        disabled={true}
                                                                        type="checkbox"
                                                                        onChange={e => console.log("false")}
                                                                        name={`skip[${ix}]`}
                                                                        value={1}
                                                                    />{" "}
                                                                    {t("PROGRAM_MY_TRAINING_SKIP_THIS_QUESTION")}
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={"row matrix-layout-border d-block"}>
                                                    <div className="col-md-12">
                                                        <MaterialUiStyledSlider
                                                            //@ts-ignore
                                                            labelColor={stringToColor(option.text)}
                                                            //@ts-ignore
                                                            value={question.minAnswer === null ? 0 : question.minAnswer}
                                                            //@ts-ignore
                                                            min={question.minAnswer}
                                                            //@ts-ignore
                                                            max={question.maxAnswer}
                                                            onChange={() => {}}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 bottom-container mt-4 mb-3">
                                                        <div className="bottom-left-item bottom-item">
                                                            {minAnswerLabel}
                                                        </div>
                                                        <div className="bottom-right-item bottom-item">
                                                            {maxAnswerLabel}
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
