import React, { useEffect, useMemo, useState } from "react";
import { Chapter, Course, Module, ModuleBlock } from "@/api-client";
import { useFormik } from "formik";
import { Form, Modal } from "react-bootstrap";
import { createApi } from "../../../common/api";
import { AppLoader } from "../../../components/Spinner";
import { useSysadminContextDispatch, useSysAdminCourses } from "../../SysadminContext";
import { PrimaryButton } from "../../../components/Button/PrimaryButton";

/*  eslint-disable react-hooks/exhaustive-deps */

export interface CopyToSelectionData {
    courseId: number;
    chapterId?: number;
    moduleId?: number;
    contentBlockId?: number;
}

export interface SelectionData {
    course: Course;
    chapter?: Chapter;
    module?: Module;
    contentBlock?: ModuleBlock;
}

export enum LevelEnum {
    "Course" = 1,
    "Chapter" = 2,
    "Module" = 3,
    "ContentBlock" = 4,
}

export interface CopyToDialogProps {
    initialSelection: CopyToSelectionData;
    level: LevelEnum;
    onSelected: (data: SelectionData) => void;
    show: boolean;
    onClose: () => void;
}

export const CopyToDialog = (props: CopyToDialogProps) => {
    const courses = useSysAdminCourses();
    const sysadminDispatch = useSysadminContextDispatch();
    const api = createApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik<CopyToSelectionData>({
        initialValues: props.initialSelection,

        onSubmit: values => {
            setIsLoading(true);
            props.onSelected({
                // @ts-ignore

                course: selectedCourse,
                chapter: selectedChapter,
                module: selectedModule,
                contentBlock: selectedContentBlock,
            });
        },

        // Important to make updats from ex SignalR not wiping form data when re-rendering
        enableReinitialize: true,
    });

    const selectedCourse = useMemo(() => {
        if (!courses || !formik?.values?.courseId) {
            return;
        }
        return courses.find(x => x.id === formik.values.courseId);
    }, [courses, formik.values.courseId]);

    const selectedChapter = useMemo(() => {
        if (!selectedCourse?.chapters || !formik?.values?.chapterId) {
            return;
        }
        return selectedCourse.chapters.find(x => x.id === formik.values.chapterId);
    }, [selectedCourse?.chapters, formik.values.chapterId]);

    const selectedModule = useMemo(() => {
        if (!selectedChapter?.modules || !formik?.values?.moduleId) {
            return;
        }
        return selectedChapter.modules.find(x => x.id === formik.values.moduleId);
    }, [selectedChapter?.modules, formik.values.moduleId]);

    const selectedContentBlock = useMemo(() => {
        if (!selectedModule?.blocks || !formik?.values?.contentBlockId) {
            return;
        }
        return selectedModule?.blocks.find(x => x.id === formik.values.contentBlockId);
    }, [selectedModule?.blocks, formik.values.contentBlockId]);

    useEffect(() => {
        if (!selectedCourse) {
            return;
        }

        // Course is just shallow-fetched, fetch entire course
        if (selectedCourse.chapters == null) {
            // @ts-ignore
            api.courses.get(selectedCourse.id).then(response => {
                sysadminDispatch(s => ({
                    ...s,
                    // @ts-ignore
                    courses: [...s.courses.filter(c => c.id !== selectedCourse.id), response],
                }));
            });
        }
    }, [selectedCourse]);

    const hasRequiredSelection = useMemo(() => {
        switch (props.level) {
            case LevelEnum.Course:
                return selectedCourse;
            case LevelEnum.Chapter:
                return selectedCourse && selectedChapter;
            case LevelEnum.Module:
                return selectedCourse && selectedChapter && selectedModule;
            case LevelEnum.ContentBlock:
                return selectedCourse && selectedChapter && selectedModule && selectedContentBlock;
            default:
                return false;
        }
    }, [props.level, selectedCourse, selectedChapter, selectedModule, selectedContentBlock]);

    console.log(formik.values);

    return (
        <>
            <AppLoader loading={isLoading} className="position-absolute" />
            <Modal show={props.show} onHide={props.onClose} centered animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Copy to </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        {courses && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Course</Form.Label>
                                <Form.Select
                                    onChange={e => formik.setFieldValue("courseId", parseInt(e.target.value, 10))}
                                    name="courseId"
                                    value={formik.values.courseId ?? ""}
                                >
                                    {courses
                                        .sort((a, b) => (a.adminName ?? a.name)?.localeCompare(b.adminName ?? b.name))
                                        .map(course => (
                                            <option key={course.id} value={course.id}>
                                                {course.adminName && course.adminName.length > 0
                                                    ? course.adminName
                                                    : course.name}
                                            </option>
                                        ))}
                                    ;
                                </Form.Select>
                            </Form.Group>
                        )}

                        {selectedCourse && selectedCourse.chapters && props.level >= LevelEnum.Chapter && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Chapter</Form.Label>
                                <Form.Select
                                    onChange={e => formik.setFieldValue("chapterId", parseInt(e.target.value, 10))}
                                    name="chapterId"
                                    value={formik.values.chapterId ?? ""}
                                >
                                    {selectedCourse.chapters
                                        // @ts-ignore
                                        .sort((a, b) => a.order - b.order)
                                        .map(chapter => (
                                            <option key={chapter.id} value={chapter.id}>
                                                {chapter.name}
                                            </option>
                                        ))}
                                    ;
                                </Form.Select>
                            </Form.Group>
                        )}

                        {selectedChapter && selectedChapter.modules && props.level >= LevelEnum.Module && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Module</Form.Label>
                                <Form.Select
                                    onChange={e => formik.setFieldValue("moduleId", parseInt(e.target.value, 10))}
                                    name="moduleId"
                                    value={formik.values.moduleId ?? ""}
                                >
                                    {selectedChapter.modules
                                        // @ts-ignore
                                        .sort((a, b) => a.order - b.order)
                                        .map(module => (
                                            <option key={module.id} value={module.id}>
                                                {module.name}
                                            </option>
                                        ))}
                                    ;
                                </Form.Select>
                            </Form.Group>
                        )}

                        {selectedModule && selectedModule.blocks && props.level >= LevelEnum.ContentBlock && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Content block</Form.Label>
                                <Form.Select
                                    onChange={e => formik.setFieldValue("contentBlockId", parseInt(e.target.value, 10))}
                                    name="contentBlockId"
                                    value={formik.values.contentBlockId ?? ""}
                                >
                                    {selectedModule.blocks
                                        .filter(x => x.type === "ExerciseBlock")
                                        .sort((a, b) => a.sortIndex - b.sortIndex)
                                        .map(block => (
                                            <option key={block.id} value={block.id}>
                                                {block.title}
                                            </option>
                                        ))}
                                    ;
                                </Form.Select>
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton
                        disabled={!hasRequiredSelection || isLoading}
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                    >
                        Copy
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};
