import { Question, QuestionOption } from "@/api-client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createApi } from "../../../../common/api";
import { AddQuestionOptions } from "./AddQuestionOptions";
import { handleUpdateOptions } from "./MatrixGrid";
import { PrimaryButton } from "../../../../components/Button/PrimaryButton";
import { ContentBlockButtonContainer } from "../../../../components/ContentBlock/ContentBlockButtonContainer";

type EditQuestionProps = {
    question: Question;
    courseId: number;
    chapterId: number;
    moduleId: number;
};

interface FormModel {
    questionId: number;
}

const MultipleChoice = (props: EditQuestionProps): JSX.Element => {
    let initialValues: FormModel = {
        // @ts-ignore

        questionId: props.question?.id,
    };

    const [updatedOptions, setUpdatedOptions] = useState<QuestionOption[]>([]);
    const api = createApi();

    const getOptions = (options: QuestionOption[]) => {
        setUpdatedOptions(options);
    };

    // @ts-ignore
    const updateOptions = async (updatedOptions, options) => {
        await handleUpdateOptions(api, updatedOptions, options);
    };

    return (
        <>
            <Formik
                // enableReinitialize={true}
                initialValues={initialValues}
                // validationSchema={validate()}
                onSubmit={(values, helpers) => {}}
            >
                {() => {
                    return (
                        <Form name="matrixGridForm" className="mt-2 editModuleForm">
                            <Row>
                                <Col>
                                    <h6>
                                        <b>Answer Options</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <AddQuestionOptions
                                        question={props.question}
                                        updatedOptions={options => getOptions(options)}
                                    />
                                </Col>
                            </Row>
                            <ContentBlockButtonContainer>
                                <PrimaryButton
                                    onClick={async () => {
                                        await updateOptions(updatedOptions, props.question.options);
                                    }}
                                    type="submit"
                                >
                                    Update
                                </PrimaryButton>
                            </ContentBlockButtonContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default MultipleChoice;
