﻿import { Container, Divider, Grid, Stack, Title, Flex } from "@mantine/core";
import { Form, Formik } from "formik";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { useHistory } from "@/common";
import { useModals } from "@mantine/modals";
import { randomId } from "@mantine/hooks";
import { useState, useMemo, useEffect } from "react";
import { MRT_ColumnDef } from "mantine-react-table";
import { CardBlock, FormInput, PrimaryButton, RenderIf, Table, showToast } from "@/components";
import { ContentBlockButtonContainer } from "../../../components/ContentBlock/ContentBlockButtonContainer";
import { BlockDto, LanguageCode } from "@/api-client";
import { UrlFactory } from "../../../routing/UrlFactory";
import { addBlockModal } from "./AddBlock";
import { Button } from "../../../components/Button/Button";
import { createApi } from "@/common";
import { Breadcrumbs } from "../../AccountOwner/Breadcrumbs";
import { useParams } from "react-router-dom";

type Language = { displayName: string; languageCode: LanguageCode };

const languages: Language[] = [
    { displayName: "LOGIN_SELECT_ENGLISH", languageCode: "en" },
    { displayName: "LOGIN_SELECT_SWEDISH", languageCode: "sv" },
    { displayName: "LOGIN_SELECT_NORWEGIAN", languageCode: "no" },
    { displayName: "COMMON_LANGUAGE_GERMAN", languageCode: "de" },
];

const moduleSchema = Yup.object({
    name: Yup.string().required(),
    languageCode: Yup.mixed<LanguageCode>().required(),
    title: Yup.string().required(),
    description: Yup.string(),
});

type FormObject = Yup.InferType<typeof moduleSchema>;

type ModuleEditorPageProps = {
    moduleId?: string;
};

export const ModuleEditorPage = () => {
    const props = useParams<ModuleEditorPageProps>();
    const { t } = useTranslate();
    const modals = useModals();
    const history = useHistory();
    const [blocks, setBlocks] = useState<BlockDto[]>([]);
    const [initialModuleValues, setInitialModuleValues] = useState<FormObject>({
        title: "",
        description: "",
        languageCode: "en",
        name: "",
    });

    const languageOptions = languages.map(x => ({ label: t(x.displayName), value: x.languageCode }));
    const isEdit = props.moduleId !== undefined;

    const updateBlocks = async () => {
        const api = createApi();
        setBlocks(await api.sysadmin.getBlocks(props.moduleId!));
    };

    const getInitialValues = async (abortController: AbortController) => {
        const api = createApi();
        const module = await api.sysadmin.getModule(props.moduleId!, abortController);

        setInitialModuleValues({
            title: module.language.title,
            description: module.language.description,
            languageCode: module.language.code.toLowerCase() as LanguageCode,
            name: module.name,
        });
    };

    useEffect(() => {
        const abortController = new AbortController();
        if (isEdit) {
            updateBlocks().catchWithToast();
            getInitialValues(abortController).catchWithToast();
        }
        return () => abortController.abort();
    }, []);

    const handleSubmit = async (values: FormObject): Promise<void> => {
        const op = async () => {
            const api = createApi();

            const moduleId = await api.sysadmin.createModule(values.name, props.moduleId);
            await api.sysadmin.addModuleLanguage(moduleId, values.languageCode, values.title, values.description);
            history.push(UrlFactory.sysadmin.module.create({ moduleId: moduleId }));

            showToast(t("SYSADMIN_MODULE_MODULE_SUCCESSFULLY_CREATED"), "success");
        };

        op().catchWithToast();
    };

    const openAddBlockModal = (moduleId: string) => {
        const modalId = randomId();

        modals.openModal(
            addBlockModal(t, {
                modalId: modalId,
                moduleId: moduleId,
                onClose: modalId => {
                    updateBlocks().catchWithToast();
                    modals.closeModal(modalId);
                },
            })
        );
    };

    const columns = useMemo<MRT_ColumnDef<BlockDto>[]>(
        () => [
            {
                accessorKey: "title",
                header: t("SYSADMIN_MODULE_TITLE"),
                enableColumnFilter: false,
            },
            {
                accessorKey: "description",
                header: t("SYSADMIN_MODULE_DESCRIPTION"),
                enableColumnFilter: false,
            },
        ],
        [t]
    );
    const items = [
        { title: t("SYSADMIN_MODULE_MODULES"), href: UrlFactory.sysadmin.modules.create({}) },
        { title: t("SYSADMIN_MODULE_MODULE") },
    ];

    return (
        <Container>
            <Flex direction="column" rowGap="xl" mb="xl">
                <Breadcrumbs items={items} />
                <CardBlock>
                    <Title size={20}>
                        {isEdit ? t("SYSADMIN_MODULE_EDIT_MODULE") : t("SYSADMIN_MODULE_CREATE_MODULE")}
                    </Title>
                    <Formik
                        initialValues={initialModuleValues}
                        validationSchema={moduleSchema}
                        enableReinitialize
                        onSubmit={async values => {
                            return handleSubmit(values);
                        }}
                    >
                        {formikProps => {
                            const { isSubmitting } = formikProps;

                            return (
                                <Form>
                                    <Grid>
                                        <Grid.Col span={6}>
                                            <Stack mb="md">
                                                <Divider mt="sm" label={t("SYSADMIN_MODULE_METADATA")} />
                                                <FormInput.Text fieldName="name" label="SYSADMIN_MODULE_NAME" />

                                                <Divider mt="sm" label={t("SYSADMIN_MODULE_MODULE")} />
                                                <FormInput.NativeSelect
                                                    disabled={isEdit}
                                                    fieldName="languageCode"
                                                    label="SYSADMIN_MODULE_LANGUAGE"
                                                    options={languageOptions}
                                                />
                                                <FormInput.Text fieldName="title" label="SYSADMIN_MODULE_TITLE" />
                                                <FormInput.Textarea
                                                    fieldName="description"
                                                    label="SYSADMIN_MODULE_DESCRIPTION"
                                                />
                                            </Stack>
                                            <ContentBlockButtonContainer>
                                                <PrimaryButton type="submit" loading={isSubmitting}>
                                                    {isEdit ? t("COMMON_UPDATE") : t("COMMON_SAVE")}
                                                </PrimaryButton>
                                            </ContentBlockButtonContainer>
                                        </Grid.Col>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </CardBlock>

                <RenderIf show={isEdit}>
                    <CardBlock>
                        <Title size={20} mb="md">
                            Blocks
                        </Title>
                        <Table
                            toolbarActions={() => (
                                <Button onClick={() => openAddBlockModal(props.moduleId!)}>
                                    {t("SYSADMIN_MODULE_ADD_BLOCK")}
                                </Button>
                            )}
                            initialColumnFilterState={[]}
                            columns={columns}
                            data={blocks}
                        />
                    </CardBlock>
                </RenderIf>
            </Flex>
        </Container>
    );
};
