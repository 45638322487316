import { Answer } from "@/api-client";
import { ColoredCategory } from "../QuestionCategoriesEditor/ColoredCategory";
import { Color } from "../../../common/colors";
import { useTranslate } from "@tolgee/react";
import React, { useEffect, useState } from "react";
import { AnswerCard } from "./AnswerCard";

export interface QuestionCategoryProps {
    name: string;
    answers: Answer[];
    categories: ColoredCategory[];
    setAnswerCategory: (answer: Answer, newCategoryId: string | null) => void;
    setAnswerVisibility: (answer: Answer, hidden: boolean) => void;
    allowDelete: boolean;
    categoryId?: string;
    color: Color;
}

export const QuestionCategory = ({
    name,
    answers,
    categories,
    setAnswerCategory,
    setAnswerVisibility,
    allowDelete,
    categoryId,
    color,
}: QuestionCategoryProps) => {
    const { t } = useTranslate();

    const [showHiddenAnswers, setShowHiddenAnswers] = useState(false);
    const [answersToShow, setAnswersToShow] = useState(answers);
    const [hiddenAnswersCount, setHiddenAnswersCount] = useState(answers.filter(x => x.hidden).length);

    useEffect(() => {
        setAnswersToShow(showHiddenAnswers ? answers : answers.filter(x => x.hidden === false));
        setHiddenAnswersCount(answers.filter(x => x.hidden).length);
    }, [answers, showHiddenAnswers]);

    const updateAnswerText = (answer: Answer) => {
        const updatedAnswers = [...answers.map(a => (a.id === answer.id ? answer : a))];
        setAnswersToShow(updatedAnswers);
    };

    const setNewlyAddedAnswer = (newAnswers: Answer[]) => {
        setAnswersToShow(
            showHiddenAnswers
                ? [...answers, ...newAnswers]
                : [...answers.filter(x => x.hidden === false), ...newAnswers]
        );
    };

    const isAOHeaderExist = document.getElementsByClassName("context-nav");

    return (
        <div className="mb-3">
            {name && (
                <div
                    id={categoryId ? `c${categoryId}` : "uncategorized"}
                    className="category-badge"
                    style={{
                        marginBottom: "10px",
                        padding: "6px",
                        borderLeft: "8px solid " + color.hex,
                        borderRadius: "4px",
                        top: isAOHeaderExist && isAOHeaderExist.item(0) === null ? "-34px" : "34px",
                    }}
                >
                    {name}
                </div>
            )}
            {answersToShow &&
                answersToShow.length > 0 &&
                answersToShow
                    .sort((a, b) => a.order - b.order)
                    .map((answer, i) => {
                        return (
                            <AnswerCard
                                answer={answer}
                                setAnswerText={(answer: Answer) => updateAnswerText(answer)}
                                setNewlyAddedAnswer={(answer: Answer[]) => setNewlyAddedAnswer(answer)}
                                key={answer?.id}
                                onCategoryChange={newCategoryId => setAnswerCategory(answer, newCategoryId)}
                                categories={categories}
                                setAnswerVisibility={setAnswerVisibility}
                                allowDelete={allowDelete}
                                className={answer?.answerCategoryId ? "draggable drag-allowed" : ""}
                                answerIndex={i}
                            />
                        );
                    })}

            {hiddenAnswersCount > 0 && (
                <button className="btn btn-link" onClick={() => setShowHiddenAnswers(!showHiddenAnswers)}>
                    {" "}
                    {showHiddenAnswers
                        ? t("WORKSHOP_HIDE_HIDDEN_ANSWERS_BUTTON", { hiddenAnswersCount: hiddenAnswersCount })
                        : t("WORKSHOP_SHOW_HIDDEN_ANSWERS_BUTTON", { hiddenAnswersCount })}
                </button>
            )}
            {answersToShow.length < 2 && name && (
                <p className="alert alert-secondary">{t("WORKSHOP_CATEGORIES_ALERT_TEXT")}</p>
            )}
        </div>
    );
};
