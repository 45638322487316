import React from "react";
import { Col } from "react-bootstrap";
import { DemoGraphicQuestionsTemplateProps } from ".";
import { DisplayInlineError } from "../../../components/DisplayInlineError";

export const SelectOptionTypeQuestion = (props: DemoGraphicQuestionsTemplateProps) => {
    const { question, answer, error } = props.questionAndAnswer;
    const { id, label, options, required } = question;
    // @ts-ignore
    const questionOptions = options.split("\n");
    const handleChange = (value: string, answerId: number): void => {
        props.setAnswers(value, answerId);
    };
    return (
        <Col className="mb-3" key={`questions-answers-${id}`}>
            <label htmlFor={`formControl${label}`} className="form-label demographic-questions-label">
                {label}
            </label>
            <select
                onChange={e =>
                    // @ts-ignore
                    handleChange(e.target.value, answer?.id)
                }
                defaultValue={answer?.value}
                name={label}
                className={`form-select form-control ${required === true && error ? " is-invalid" : ""}`}
            >
                <option value="">Please select {label}</option>
                {questionOptions &&
                    questionOptions.map((o, i) => (
                        <option key={i} value={o}>
                            {o}
                        </option>
                    ))}
            </select>
            {required === true && error && <DisplayInlineError error={error} />}
        </Col>
    );
};
