import { Component, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Client, Course, DemographicQuestion, DemographicUserData, GroupType, Group, User } from "@/api-client";
import { StateWrapper2, WithChildren } from "./StateWrapper";
import { useCurrentUser } from "./user/context";
import { createApi } from "./api";
import { AppLoader } from "@/components";

export interface OrganisationContextState {
    groups: Group[];
    users: User[];
    groupTypes: GroupType[];
    courses: Course[];
    demographicQuestions: DemographicQuestion[];
    demographicData: DemographicUserData[];
    /** @deprecated use useCurrentClient instead */
    client: Client | null;
}

const defaultState: OrganisationContextState = {
    groups: [],
    users: [],
    groupTypes: [],
    courses: [],
    demographicQuestions: [],
    demographicData: [],
    client: null,
};

const OrganisationContext = createContext<StateWrapper2<OrganisationContextState, WithChildren>>({
    state: defaultState,
    setState: () => {},
});

export const useOrganisationContext = () => useContext(OrganisationContext).state;
export const useOrganisationContextDispatch = () => useContext(OrganisationContext).setState;

export const useAllCourses = () => useOrganisationContext().courses;
export const useAllGroups = () => useOrganisationContext().groups;
export const useAllUsers = () => useOrganisationContext().users;
export const useDemographicQuestions = () => useOrganisationContext().demographicQuestions;
export const useDemographicData = () => useOrganisationContext().demographicData;
export const useGroupTypes = () => useOrganisationContext().groupTypes;

/**
 * Get a course from context by id, useful when you need to get another course than currentCourse
 * @param courseId
 * @returns
 */
export const useCourse = (courseId: number) => {
    const courses = useAllCourses();
    return useMemo(() => courses.find(c => c.id === courseId), [courses, courseId]);
};

export const OrganisationContextProviderRenderer = (props: WithChildren): JSX.Element => {
    const api = createApi();
    const currentUser = useCurrentUser();
    const dispatch = useOrganisationContextDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const getGroupTypes = () =>
        api.groupTypes.query(null).then(result => {
            dispatch(s => ({
                ...s,
                groupTypes: result?.filter(r => r.status === "Active"),
            }));
        });

    const getGroups = () =>
        api.groups.query(null, null, null, null).then(
            result => {
                dispatch(s => ({
                    ...s,
                    groups: result.filter(g => g.status === "Active"),
                }));
            },
            error => {
                // TODO: Log errors with appInsights?

                console.log(error, "Error in getting group types.");
            }
        );

    const getUsers = () =>
        api.users.query(null, 10000, null, true).then(result => {
            dispatch(s => ({
                ...s,
                users: result,
            }));
        });

    const getCourses = () =>
        api.courses.query(true).then(result => {
            dispatch(s => ({
                ...s,
                courses: result,
            }));
        });

    const getDemographicQuestions = () =>
        api.demographicQuestions.query(null).then(result => {
            dispatch(s => ({
                ...s,
                demographicQuestions: result,
            }));
        });

    const getDemographicData = () =>
        api.demographicAnswers.queryAll(null).then(demographicData => {
            dispatch(s => ({
                ...s,
                demographicData,
            }));
        });

    const getClient = () => {
        if (currentUser.clientId !== null && currentUser.clientId !== undefined) {
            return api.clients.get(currentUser.clientId).then(response => {
                dispatch(s => ({
                    ...s,
                    client: response,
                }));
            });
        }

        return Promise.resolve(
            dispatch(s => ({
                ...s,
                client: null,
            }))
        );
    };

    useEffect(() => {
        Promise.allSettled([
            // Run all initial loading here
            getGroupTypes(),
            getGroups(),
            getUsers(),
            getCourses(),
            getDemographicQuestions(),
            getDemographicData(),
            getClient(),
        ])
            .then(() => {
                setIsLoading(false);
            })
            .catchWithToast();
    }, [currentUser]);

    if (isLoading) {
        return <AppLoader loading />;
    }

    return <>{props.children}</>;
};

// NOTE: React functional components doesn't have callback in setState so we wrap the actual state a old-style class component
export class OrganisationContextProvider extends Component<WithChildren, OrganisationContextState> {
    constructor(props: WithChildren) {
        super(props);
        this.state = defaultState;
    }

    render() {
        const stateWrapper: StateWrapper2<OrganisationContextState, WithChildren> = {
            state: this.state,
            setState: this.setState.bind(this),
        };

        return (
            <OrganisationContext.Provider value={stateWrapper}>
                <OrganisationContextProviderRenderer {...this.props} />
            </OrganisationContext.Provider>
        );
    }
}
