import * as Yup from "yup";
import { Form, FormInput, ModalConfig, ModalProps } from "@/components";
import { createApi, yupExtensions } from "@/common";

type ProgramAssignmentProps = { programId: number; teams: { id: number; name: string }[] };

const validator = Yup.object({
    teamId: yupExtensions.requiredNonEmptyString("COMMON_FIELD_REQUIRED"),
});

type FormValues = Yup.InferType<typeof validator>;

const ProgramAssignment = (props: ModalProps<ProgramAssignmentProps>) => {
    const initialValues: FormValues = {
        teamId: "",
    };

    const handleSubmit = (values: FormValues) => {
        return createApi()
            .groupCourses.post({
                courseId: props.programId,
                groupId: parseInt(values.teamId),
                isCompleted: false,
                hideUserInfoOnAnswers: false,
            })
            .then(() => props.onClose())
            .catchWithToastAsync();
    };

    const teamSelectItems = props.teams.map(x => ({ value: x.id.toString(), label: x.name }));

    return (
        <Form<FormValues>
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={handleSubmit}
            submitLabel="COMMON_SAVE"
            eventName={{ object: "ao_programassignment", action: "save" }}
        >
            <FormInput.NativeSelect
                fieldName="teamId"
                label="ACCOUNTOWNER_PROGRAMS_TEAM"
                options={teamSelectItems}
                placeholder="ACCOUNTOWNER_PROGRAMS_SELECT_TEAM"
            />
        </Form>
    );
};

export const ProgramAssignmentModal: ModalConfig<ProgramAssignmentProps> = {
    content: ProgramAssignment,
    title: "ACCOUNTOWNER_PROGRAMS_ASSIGN_PROGRAM_TO_TEAM",
};
