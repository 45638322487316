import { useContext } from "react";
import { AuthContext } from "./authContext";

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === null) {
        throw Error("Invalid auth context");
    }

    return context;
};
