import React from "react";
import { useAuth } from "../../auth/useAuth";
import { showToast } from "../Notify";
import { Icons } from "@/common";

interface UserLoginProps {
    userId: number;
}

export const UserLoginDropdownItem = (props: UserLoginProps) => {
    const { impersonate } = useAuth();

    const loginByUserId = async (userId: number) => {
        impersonate({ type: "user", userId: userId }).catch(r => showToast(`Cannot impersonate user ${r}`, "error"));
    };

    return (
        <div onClick={async () => loginByUserId(props.userId)}>
            <Icons.SignIn />
        </div>
    );
};
