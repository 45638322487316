import { Icons } from "common/Icons";
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "mantine-react-table";
import { ActionIcon, Badge, SelectItem, Tooltip } from "@mantine/core";
import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import { ClientStatuses } from "@/api-client";
import { NavButton, RowActionConfig, RowActions, Table } from "@/components";
import { UrlFactory } from "@/common";
import { useAuth } from "../../../auth/useAuth";
import { createTagsSelectItems } from "../../../common/createTagsSelectItems";

export type ClientRow = {
    id: number;
    name: string;
    status: ClientStatuses;
    agent: string | null;
    tags: string[];
    activeUsers: number;
};

type ClientListProps = {
    clients: ClientRow[] | null;
};
const ClientStatusBadge = (props: { status: ClientStatuses }) => {
    if (props.status === "Active") {
        return <Badge color="green">{props.status}</Badge>;
    }

    return <Badge>{props.status}</Badge>;
};

export const ClientList = (props: ClientListProps) => {
    const { impersonate } = useAuth();
    const { t } = useTranslate();

    const loginByClientId = async (clientId: number) => {
        impersonate({ type: "ao", clientId: clientId }).catchWithToast();
    };

    const clientStatusOptions: readonly ClientStatuses[] = ["Active", "Inactive", "Deleted"];

    const clientStatusSelectItems = clientStatusOptions.map(status => ({
        label: status,
        value: status,
    })) as SelectItem[] & string;

    const tagsSelectItems: SelectItem[] = useMemo(
        () => createTagsSelectItems(props.clients?.flatMap(x => x.tags)),
        [props.clients]
    );

    const initialFilterState: MRT_ColumnFiltersState = [{ id: "status", value: ["Active"] }];

    const columns = useMemo<MRT_ColumnDef<ClientRow>[]>(
        () => [
            {
                header: t("SYSADMIN_NAME"),
                accessorKey: "name",
            },
            {
                id: "status",
                header: t("SYSADMIN_STATUS"),
                accessorFn: ({ status }) => status.toString(),
                filterVariant: "multi-select",
                Cell: ({ row }) => <ClientStatusBadge status={row.original.status} />,
                mantineFilterMultiSelectProps: {
                    data: clientStatusSelectItems,
                },
            },
            {
                header: t("SYSADMIN_AGENT"),
                accessorKey: "agent",
            },
            {
                header: t("SYSADMIN_TAGS"),
                accessorFn: ({ tags }) => tags.map(t => t.trim()).join(", "),
                filterVariant: "multi-select",
                mantineFilterMultiSelectProps: {
                    data: tagsSelectItems,
                },
            },
            {
                header: t("SYSADMIN_ACTIVE_USERS"),
                accessorKey: "activeUsers",
            },
        ],
        [tagsSelectItems]
    );

    const rowActions: RowActionConfig<ClientRow> = {
        renderRowActions: ({ row }) => (
            <>
                <Tooltip label={t("SYSADMIN_SIGN_IN_AS_ACCOUNT_OWNER")}>
                    <ActionIcon onClick={() => loginByClientId(row.original.id)}>
                        <Icons.SignIn />
                    </ActionIcon>
                </Tooltip>
                <RowActions.EditLink to={`/sysadmin/clients/edit/${row.original.id}`} />
            </>
        ),
        size: 75,
    };

    return (
        <Table
            columns={columns}
            data={props.clients}
            initialSort={[{ id: "name", desc: false }]}
            initialColumnFilterState={initialFilterState}
            rowActions={rowActions}
            toolbarActions={() => (
                <NavButton link={UrlFactory.sysadmin.addClient.create({})} textKey="SYSADMIN_ADD_CLIENT" />
            )}
        />
    );
};
