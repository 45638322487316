import { RouteObject } from "react-router-dom";
import { AGENT_ROLE, EXPERT_ROLE, SYSADMIN_ROLE } from "../constants";
import AddClient from "../sysadmin/clients/AddClient";
import EditClient from "../sysadmin/clients/EditClient";
import CoursePreview from "../sysadmin/coursePreview";
import ContentIntroductionVideos from "../sysadmin/courses/IntroductionVideos/ContentIntroductionVideos";
import { SysadminHomePage } from "../sysadmin/dashboard";
import { SysadminContextProvider } from "../sysadmin/SysadminContext";
import { SysadminUsersListing } from "../sysadmin/users";
import { SysadminUsers } from "../sysadmin/users/users";
import EditVideo from "../sysadmin/videoLibrary/EditVideo";
import SysadminLayout from "../sysadmin/components/SysadminLayout";
import { SysAdminCourseRoutes } from "./SysAdminCourseRoutes";
import { Statistics } from "../sysadmin/statistics/Statistics";
import { AddVideos } from "../sysadmin/videoLibrary/AddVideos";
import { ProgramsPage } from "../pages/SysAdmin/Programs/ProgramsPage";
import { UrlFactory } from "./UrlFactory";
import { CapabilityEditorPage } from "../pages/SysAdmin/Capabilities/CapabilityEditorPage";
import { ModuleEditorPage } from "../pages/SysAdmin/Modules/ModuleEditorPage";
import { ModulesPage } from "../pages/SysAdmin/Modules/ModulesPage";
import { ProtectedRouteComponentWithOutlet } from "./ProtectedRouteComponent";
import { protectedPageRoute, protectedRoute, route } from "./routebuilder";
import { EditAdminPage } from "../pages/SysAdmin/Admins/EditAdminPage";
import { ClientsPage } from "../sysadmin/clients/ClientsPage";
import { VideoLibraryPage } from "../pages/SysAdmin/Videos/VideoLibraryPage";

export const SysAdminRoutes: RouteObject = {
    element: (
        <SysadminContextProvider>
            <SysadminLayout>
                <ProtectedRouteComponentWithOutlet roles={[SYSADMIN_ROLE, AGENT_ROLE, EXPERT_ROLE]} />
            </SysadminLayout>
        </SysadminContextProvider>
    ),
    children: [
        route("/sysadmin", <SysadminHomePage />),
        SysAdminCourseRoutes,
        protectedRoute("/sysadmin/course-preview/:courseId", <CoursePreview />, [SYSADMIN_ROLE, EXPERT_ROLE]),
        protectedRoute("/sysadmin/course-preview/:courseId/chapter/:chapterId/:key", <CoursePreview />, [
            SYSADMIN_ROLE,
            EXPERT_ROLE,
        ]),
        protectedRoute(
            "/sysadmin/course-preview/:courseId/chapter/:chapterId/module/:moduleId/block/:moduleBlockId",
            <CoursePreview />,
            [SYSADMIN_ROLE, EXPERT_ROLE]
        ),
        protectedRoute(UrlFactory.sysadmin.clients.pattern, <ClientsPage />, [SYSADMIN_ROLE, AGENT_ROLE]),
        protectedRoute(UrlFactory.sysadmin.addClient.pattern, <AddClient />, [SYSADMIN_ROLE, AGENT_ROLE]),
        protectedRoute("/sysadmin/clients/edit/:clientId", <EditClient />, [SYSADMIN_ROLE, AGENT_ROLE]),
        protectedRoute(UrlFactory.sysadmin.videoLibrary.pattern, <VideoLibraryPage />, [SYSADMIN_ROLE, EXPERT_ROLE]),
        protectedRoute("/sysadmin/video-library/add", <AddVideos />, [SYSADMIN_ROLE, EXPERT_ROLE]),
        protectedRoute("/sysadmin/video-library/edit/:videoId", <EditVideo />, [SYSADMIN_ROLE, EXPERT_ROLE]),
        protectedRoute("/sysadmin/courses/:courseId/introduction-videos/add", <ContentIntroductionVideos />, [
            SYSADMIN_ROLE,
        ]),
        protectedRoute(
            "/sysadmin/courses/:courseId/introduction-videos/edit/:introductionVideoId",
            <ContentIntroductionVideos />,
            [SYSADMIN_ROLE, EXPERT_ROLE]
        ),
        protectedRoute("/sysadmin/users", <SysadminUsersListing />, [SYSADMIN_ROLE]),
        protectedRoute(UrlFactory.sysadmin.user.pattern, <SysadminUsers />, [SYSADMIN_ROLE]),
        protectedPageRoute(UrlFactory.sysadmin.admin, EditAdminPage, [SYSADMIN_ROLE]),
        protectedRoute(UrlFactory.sysadmin.programs.pattern, <ProgramsPage />, [SYSADMIN_ROLE]),
        protectedRoute(UrlFactory.sysadmin.capability.pattern, <CapabilityEditorPage />, [SYSADMIN_ROLE]),
        protectedRoute(UrlFactory.sysadmin.module.pattern, <ModuleEditorPage />, [SYSADMIN_ROLE]),
        protectedRoute(UrlFactory.sysadmin.modules.pattern, <ModulesPage />, [SYSADMIN_ROLE]),
        protectedRoute("/sysadmin/statistics", <Statistics />, [SYSADMIN_ROLE]),
    ],
};
