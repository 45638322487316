import React from "react";
import { getColorByIndexRepeatingSeq } from "../../common/colors";
import { getInitials } from "./index";
import { useStyles } from "./fallbackUserImageStyles";

export const FallBackUserImage = (props: { userId?: number; fullName?: string }) => {
    const color = getColorByIndexRepeatingSeq(props.userId ?? 0, "dark");
    const colorCode = color.hex;
    const textColor = color.textColorHex;
    const letters = getInitials(props.fullName);
    const { classes } = useStyles({ background: colorCode, textColor });

    return (
        <div className={classes.fallbackImage}>
            <span className={classes.letters}>{letters}</span>
        </div>
    );
};
