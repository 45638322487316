﻿import { createStyles } from "@mantine/core";

export const useTeamMessageStyles = createStyles(theme => ({
    card: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        width: "100%",
    },
    contentContainer: {
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSizes.sm,
        flex: 1,
    },
    noMessageContainer: {
        flexGrow: 1,
    },
    message: {
        maxHeight: "350px",
        a: {
            color: theme.colors.blue,
            "&:hover": {
                color: theme.colors.blue,
                textDecoration: "underline",
            },
        },
    },
}));
