import { AdditionalResource, Course } from "@/api-client";
import React from "react";
import { Row } from "react-bootstrap";
import { useHistory } from "@/common";
import { ContentBlock } from "../../../training/ContentModuleBlocks/ContentBlock";
import { createSlidesForModuleBlocks, isLastModuleBlock } from "../../../training/helper";
import { handleNextModuleBlockAndChapter } from "../helper";
import { AllQuestionsPreview } from "./Questions/AllQuestionsPreview";

type ChapterPreviewProps = {
    course: Course;
    chapterId: number;
    moduleBlockId: number;
    moduleId: number;
    courseId: number;
    additionalResources: AdditionalResource[];
};

export const BlockPreview = (props: ChapterPreviewProps) => {
    const history = useHistory();
    const moduleBlockSlides = createSlidesForModuleBlocks(
        props.course as Course,
        props.chapterId as number,
        props.moduleId as number,
        props.moduleBlockId as number
    );

    const moduleBlocks =
        moduleBlockSlides && moduleBlockSlides.current && moduleBlockSlides.current.length > 0
            ? moduleBlockSlides.current
            : null;

    const nextModuleBlock = moduleBlockSlides && moduleBlockSlides.next ? moduleBlockSlides.next : undefined;

    const nextLinks = handleNextModuleBlockAndChapter(
        props.course as Course,
        props.courseId as number,
        props.chapterId as number,
        props.moduleId as number,
        props.moduleBlockId as number
    );

    const handleClick = (nextBlockId: number | undefined) => {
        if (nextLinks) {
            history.push(nextLinks);
        } else {
            history.push(`/sysadmin/courses`);
        }
    };
    return (
        <Row>
            <div className="col-12">
                {moduleBlocks &&
                    moduleBlocks.map((m, i) => {
                        return (
                            <React.Fragment key={`module-wrapper-${m.moduleId}-${m.id}`}>
                                <ContentBlock
                                    onClick={() => handleClick(nextModuleBlock?.id)}
                                    key={`module-block-${m.moduleId}-${m.id}`}
                                    moduleBlock={m}
                                    isLast={false}
                                    id={`module-block-${m.id}`}
                                    sequence={i}
                                    moduleBlockId={props.moduleBlockId}
                                    additionalResources={props.additionalResources}
                                    //@ts-ignore
                                    currentModuleBlockId={m.id}
                                />
                                {/* NOTE:- Let's add the common props to pass in course and questions */}
                                {m.questions && m.questions.length > 0 && (
                                    <AllQuestionsPreview
                                        questions={m.questions}
                                        moduleId={props.moduleId}
                                        chapterId={props.chapterId}
                                        //@ts-ignore
                                        nextModuleBlock={nextModuleBlock}
                                        moduleBlockId={props.moduleBlockId}
                                        //@ts-ignore
                                        isLastModuleBlock={isLastModuleBlock(
                                            props.course,
                                            props.chapterId,
                                            props.moduleId,
                                            m.id
                                        )}
                                        moduleBlock={m}
                                        onClick={() => handleClick(nextModuleBlock?.id)}
                                        courseId={props.courseId}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
            </div>
        </Row>
    );
};
