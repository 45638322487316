import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslate } from "@tolgee/react";
import Slideshow from "../../../components/Slideshow";
import "./style.scss";
import { createStyles } from "@mantine/core";
import { NavButton } from "@/components";

interface SendInvitesProps {
    courseId: number;
}

const useStyles = createStyles(() => ({ buttonContainer: { position: "absolute", top: 0 } }));

const SendInvites = (props: SendInvitesProps): JSX.Element => {
    const { classes } = useStyles();
    const { t } = useTranslate();
    const courseId = props.courseId;
    const button = (
        <div className={classes.buttonContainer}>
            <NavButton link="/account-owner" textKey="COMMON_FINISH" />
        </div>
    );
    return (
        <Slideshow previousLink={`/account-owner/resources/plan-course-group-members/${courseId}`} button={button}>
            <Row className="sendinvites-slide">
                <Col md={12} className="heading">
                    <p className="content-label">{t("RESOURCES_PLAN_A_NEW_COURSE")}</p>
                </Col>
                <Col md={12} className="inner-text p-5">
                    <h2>{t("RESOURCES_INVITESENT")}</h2>
                    <p>{t("RESOURCES_INVITESENT_MSG_1")}</p>
                    <p>{t("RESOURCES_INVITESENT_MSG_2")}</p>
                </Col>
            </Row>
        </Slideshow>
    );
};

export default SendInvites;
