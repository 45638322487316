import { TeamMember } from "./TeamMember";
import { UserCard } from "./UserCard";

type UserCardsProps = {
    activeMembers: TeamMember[];
};

export const UserCards = (props: UserCardsProps) => (
    <>
        {props.activeMembers.map(m => (
            <div key={m.user.id}>
                <UserCard
                    image={m.user.image ?? null}
                    fullName={m.user.fullName ?? null}
                    phoneNumber={m.user.mobilePhone ?? null}
                    email={m.user.email ?? null}
                    hasAcceptedInvite={m.user.hasAcceptedInvite}
                    isTeamLeader={m.isTeamLeader}
                    userId={m.user.id!}
                />
            </div>
        ))}
    </>
);
