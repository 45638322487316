import React from "react";
import MultiMenus from "./MultiMenus";
import { CoursesNav } from "./NavBarHelper";
import "./style.scss";

interface SidebarProps {
    className?: string;
    style?: string;
    //Children any is used to support  where nav is not used yet e.g. Clients, VideoLibary etc...
    children: CoursesNav[] | any;
    courseId?: number;
    chapterId?: number;
    moduleId?: number;
    name?: string;
    moduleBlockId?: number;
    activeId?: number; //NOTE: Active id is used to show the active nav in single navigation without children
}

const index = (props: SidebarProps) => {
    return (
        <div className={`${props.className} sysadmin-sidebar-menu`}>
            {props.name && <div className="heading">{props.name}</div>}
            {props.children && props.children.length > 0 && (
                <MultiMenus
                    menus={props.children}
                    courseId={props.courseId}
                    //@ts-ignore
                    chapterId={props.chapterId}
                    moduleId={props.moduleId}
                    moduleBlockId={props.moduleBlockId}
                    //@ts-ignore
                    activeId={props.activeId}
                />
            )}
        </div>
    );
};

export default index;
