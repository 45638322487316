﻿import * as Yup from "yup";
import { Form, ModalConfig, ModalProps } from "@/components";
import { createApi, createPatchFromDiff } from "@/common";
import { GroupMembership } from "@/api-client";
import {
    convertToMembershipType,
    convertToMembershipTypeModel,
    MembershipTypeModel,
    MembershipTypeInput,
    membershipTypeSchema,
    MembershipType,
} from "./MembershipTypeInput";

const membershipSchema = Yup.object({
    membershipTypes: membershipTypeSchema(),
});

type FormMembership = Yup.InferType<typeof membershipSchema>;

type EditTeamMembershipModalProps = ModalProps<{
    teamId: number;
    userId: number;
}> &
    MembershipTypeModel;

const EditTeamMembership = (props: EditTeamMembershipModalProps) => {
    const initialValues = {
        membershipTypes: convertToMembershipType(props),
    };

    const onSubmit = (values: FormMembership) => {
        const membershipTypes = convertToMembershipTypeModel(values.membershipTypes as MembershipType[]);

        const patchSource: Pick<GroupMembership, "isGroupLeader" | "isParticipant"> = {
            isGroupLeader: props.isGroupLeader,
            isParticipant: props.isParticipant,
        };

        const patch = createPatchFromDiff(patchSource, membershipTypes);

        createApi()
            .groupMembership.patch(props.userId, props.teamId, patch)
            .then(() => props.onClose())
            .catchWithToast();
    };

    return (
        <Form<FormMembership>
            submitLabel="COMMON_SAVE"
            initialValues={initialValues}
            validationSchema={membershipSchema}
            eventName={{ object: "ao_editteammembership", action: "save" }}
            onSubmit={values => {
                onSubmit(values);
            }}
        >
            <MembershipTypeInput fieldName="membershipTypes" />
        </Form>
    );
};

export const EditTeamMembershipModal: ModalConfig<EditTeamMembershipModalProps> = {
    content: EditTeamMembership,
    title: "EDITEMPLOYEE_EDIT_TEAM_MEMBERSHIP",
};
