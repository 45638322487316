import { Group, GroupType } from "@/api-client";
import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import RenderIf from "../render-if/render-if";

type OptGroupTypesProps = {
    name: string;
    value: number;
    class: string;
    defaultValue?: number;
    onChangeHandler: (value: number) => void;
    groupTypes: GroupType[];
    isInvalid?: boolean;
    optionTitle?: string;
    groups: Group[];
    displayGroupsWithoutType: boolean;
};

export const OptGroupTypes = (props: OptGroupTypesProps) => {
    const groups = props.groups && props.groups.length > 0 ? props.groups : [];
    const groupsWithoutGroupType = groups.filter(g => g.groupTypeId === null);
    return (
        <Form.Select
            isInvalid={props.isInvalid}
            className="form-select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                e.persist();
                props.onChangeHandler(parseInt(e.target.value));
            }}
        >
            <option value="">{props.optionTitle}</option>
            {props.groupTypes &&
                props.groupTypes.length > 0 &&
                props.groupTypes.map(gt => {
                    return (
                        <Fragment key={gt.id}>
                            <optgroup label={gt.name}>
                                {props.groups &&
                                    props.groups.length > 0 &&
                                    props.groups
                                        .filter(g => g.groupTypeId === gt.id)
                                        .sort((a, b) =>
                                            a.name.localeCompare(b.name, undefined, {
                                                numeric: true,
                                                sensitivity: "base",
                                            })
                                        )
                                        .map(gr => {
                                            return (
                                                <option key={gr.id} value={gr.id}>
                                                    {"\xA0"}
                                                    {gr.name}
                                                </option>
                                            );
                                        })}
                            </optgroup>
                        </Fragment>
                    );
                })}

            <RenderIf show={props.displayGroupsWithoutType && groupsWithoutGroupType.length > 0 ? true : false}>
                <optgroup label={"Other Groups"}>
                    {groupsWithoutGroupType
                        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }))
                        .map(g => {
                            return (
                                <option key={g.id} value={g.id}>
                                    {"\xA0"}
                                    {g.name}
                                </option>
                            );
                        })}
                </optgroup>
            </RenderIf>
        </Form.Select>
    );
};
