﻿import { BaseClient } from "./BaseClient";
import { BlockDb } from "../../pages/SysAdmin/Modules/BlockDb";
import { Areas } from "./AllProgramsClient";
import { ClientStatuses, CourseStatuses, VideoStatuses } from "./contracts";

export type AreaFromJson = {
    name: string;
    description: string | null;
    subareas: { name: string; capabilities: Program[] }[];
};

type Program = {
    id: number;
    published: boolean;
    imageUrl: string;
    title: string;
    shortDescription: string | null;
    experts: string[];
    description: string;
    videoUrl: string | null;
    languages: string[];
    numberOfModules: number;
    breadText: { title: string; text: string }[];
};

export type AreaDto = {
    name: string;
    subareas: SubareaDto[];
};
type SubareaDto = {
    name: string;
    capabilities: CapabilityDto[];
};
type CapabilityDto = {
    name: string;
};

export type GetAreasDto = {
    name: string;
    id: string;
    subareas: { name: string; id: string }[];
};

export type GetModulesDto = {
    name: string;
    id: string;
};

export type CreateCapabilityCommandDto = {
    name: string;
    subareaId: string;
    areaId: string;
};

export type LanguageCode = "sv" | "no" | "en" | "de";

export type AddCapabilityLanguageCommandDto = {
    capabilityId: string;
    languageCode: LanguageCode;
    title: string;
    description: string;
};

export type CreateModuleCommandDto = {
    name: string;
    moduleId?: string;
};

export type AddModuleLanguageCommandDto = {
    moduleId: string;
    languageCode: LanguageCode;
    title: string;
    description?: string;
};

export type BlockDto = {
    title: string;
    description?: string;
};

export type GetModuleDto = {
    name: string;
    language: {
        code: LanguageCode;
        title: string;
        description: string;
    };
};

export type GetClientsDto = {
    id: number;
    name: string;
    status: ClientStatuses;
    agent: string | null;
    numberOfActiveUsers: number;
    tags: string[];
};

export type GetProgramsAndCapabilitiesDto = {
    id: number;
    name: string;
    status: CourseStatuses;
    expertName: string;
    tags: string[];
};

export type GetVideosDto = {
    id: number;
    name: string;
    status: VideoStatuses;
    expert: string | null;
    tags: string[];
    url: string;
};

export class SysadminClient extends BaseClient {
    public getCapabilities = async (
        blobStorageBaseUrl: string,
        abortController: AbortController
    ): Promise<AreaFromJson[]> => {
        const programImagesUrl = `${blobStorageBaseUrl}program-images`;

        const capabilities = await fetch(`${programImagesUrl}/programoverview.json`, {
            mode: "cors",
            method: "get",
            headers: {
                Accept: "application/json",
            },
            signal: abortController?.signal,
        })
            .then(x => x.json())
            .then(
                x =>
                    x as {
                        areas: Areas[];
                    }
            );

        return capabilities.areas.map(a => ({
            ...a,
            subareas: a.subareas.map(s => ({
                ...s,
                capabilities: s.capabilities.map(c => ({ ...c, imageUrl: `${programImagesUrl}${c.imageUrl}` })),
            })),
        }));
    };

    public getAreas = async (abortController: AbortController): Promise<GetAreasDto[]> => {
        return this.get("/api/sysadmin/GetAreas", abortController);
    };

    public getModules = async (abortController: AbortController): Promise<GetModulesDto[]> => {
        return this.get("/api/sysadmin/GetModules", abortController);
    };

    public createCapability = async (name: string, subareaId: string, areaId: string): Promise<string> => {
        const dto: CreateCapabilityCommandDto = {
            name: name,
            subareaId: subareaId,
            areaId: areaId,
        };

        return this.post<CreateCapabilityCommandDto, string>("/api/sysadmin/CreateCapability", dto);
    };

    public addCapabilityLanguage = async (
        capabilityId: string,
        languageCode: LanguageCode,
        title: string,
        description: string
    ): Promise<void> => {
        const dto: AddCapabilityLanguageCommandDto = {
            capabilityId: capabilityId,
            languageCode: languageCode,
            title: title,
            description: description,
        };

        return this.postVoid("/api/sysadmin/AddCapabilityLanguage", dto);
    };

    public createModule = async (name: string, moduleId?: string): Promise<string> => {
        const dto: CreateModuleCommandDto = {
            name: name,
            moduleId: moduleId,
        };

        return this.post<CreateModuleCommandDto, string>("/api/sysadmin/CreateModule", dto);
    };

    public addModuleLanguage = async (
        moduleId: string,
        languageCode: LanguageCode,
        title: string,
        description?: string
    ): Promise<void> => {
        const dto: AddModuleLanguageCommandDto = {
            moduleId: moduleId,
            languageCode: languageCode,
            title: title,
            description: description,
        };

        return this.postVoid("/api/sysadmin/AddModuleLanguage", dto);
    };

    public addBlock = async (moduleId: string, title: string, description: string): Promise<void> => {
        const block: BlockDto = {
            title: title,
            description: description,
        };

        BlockDb.save(moduleId, block);
    };

    public getBlocks = async (moduleId: string): Promise<BlockDto[]> => {
        return BlockDb.get(moduleId);
    };

    public getModule = async (moduleId: string, abortController: AbortController): Promise<GetModuleDto> => {
        const queryString = new URLSearchParams({
            moduleId: moduleId,
        }).toString();

        return this.get(`/api/sysadmin/GetModule?${queryString}`, abortController);
    };

    public getClients = async (abortController: AbortController): Promise<GetClientsDto[]> => {
        return this.get("/api/sysadmin/GetClients", abortController);
    };

    public getVideos = async (abortController: AbortController): Promise<GetVideosDto[]> => {
        return this.get("/api/sysadmin/GetVideos", abortController);
    };

    public getProgramsAndCapabilities = async (
        abortController: AbortController
    ): Promise<GetProgramsAndCapabilitiesDto[]> => {
        return this.get("/api/sysadmin/GetProgramsAndCapabilities", abortController);
    };
}
