import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Answer, Chapter, Group, Module, Question } from "@/api-client";
import { createApi } from "../../common/api";
import { useOrganisationContext } from "../../common/OrganisationContext";
import { useCurrentCourse, useCurrentGroup } from "../../common/courses/context";
import { StateWrapper } from "../../common/StateWrapper";

/*  eslint-disable react-hooks/exhaustive-deps */

export type PresentationGroupings = "Group" | "DemographicData" | "Category" | "User";

export interface PresentationFilters {
    groupBy: PresentationGroupings;
    groupById?: number;
}

export interface PresentationContextState {
    chapter?: Chapter;
    module?: Module;
    question?: Question;
    answers?: Answer[];
    allAnswers?: Answer[];
    presentationGroups?: Group[] | null;
    isLoading: boolean;
    filters: PresentationFilters;
}

// @ts-ignore
export const PresentationContext = React.createContext<StateWrapper<PresentationContextState>>(undefined);
export const usePresentationContext = () => React.useContext(PresentationContext).state;
export const usePresentationContextDispatch = () => React.useContext(PresentationContext).dispatch;

export const useGroupsInCourse = () => {
    const currentCourse = useCurrentCourse();
    const { groups } = useOrganisationContext();

    return useMemo(() => {
        if (!currentCourse || !groups) {
            return;
        }

        // @ts-ignore
        return groups.filter(g => g.courseIds.includes(currentCourse?.id) && g.status === "Active");
    }, [currentCourse, groups]);
};

interface SlideshowProviderProps {
    children: ReactNode;
}

export const PresentationProvider = (props: SlideshowProviderProps) => {
    const api = createApi();
    const [state, setState] = useState<PresentationContextState>({
        isLoading: false,
        filters: {
            groupBy: "Category",
        },
    });

    const currentCourse = useCurrentCourse();
    const currentGroup = useCurrentGroup();

    useEffect(() => {
        if (!currentGroup) {
            return;
        }

        setState(s => ({
            ...s,
            presentationGroups: [currentGroup],
        }));
    }, [currentGroup]);

    const [allAnswers, setAllAnswers] = useState<Answer[]>();
    const { groups } = useOrganisationContext();

    useEffect(() => {
        if (!currentCourse) {
            return;
        }

        api.answers.query(null, null, currentCourse.id).then(answers => {
            setAllAnswers(answers);
            setState(x => ({ ...x, allAnswers: answers }));
        });
    }, [currentCourse]);

    useEffect(() => {
        if (!state?.question || !allAnswers || !groups) {
            return;
        }

        let answers = allAnswers;

        if (state.presentationGroups) {
            // @ts-ignore
            answers = answers.filter(x => state.presentationGroups.map(k => k.id).includes(x.groupId));
        } else {
            const allGroupIds = groups.map(x => x.id);
            answers = answers.filter(x => allGroupIds.includes(x.groupId));
        }

        // @ts-ignore
        answers = answers.filter(a => a.questionId === state.question.id && a.hidden === false);
        setState(x => ({ ...x, answers }));
    }, [state.presentationGroups, state.question, allAnswers, groups]);

    return (
        <PresentationContext.Provider
            value={{
                state: {
                    ...state,
                },
                dispatch: setState,
            }}
        >
            {props.children}
        </PresentationContext.Provider>
    );
};
