﻿import { getWorkshopLink } from "../../workshop/helpers";
import React from "react";
import { NavButton } from "@/components";

export const CategorizeShortcut = (props: { groupId: number }) => {
    const workshopLink = getWorkshopLink(props.groupId);
    return (
        <NavButton
            eventName={{ object: "program", action: "categorizeclick" }}
            link={workshopLink}
            textKey={"PROGRAM_PREPAREWORKSHOP"}
        />
    );
};
