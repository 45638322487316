import { Course, GroupMember, ParticipantProgress } from "@/api-client";

export interface ConsolidatedUsersProgress {
    isCompleted: boolean | null;
    break?: boolean;
}

export const AllGroupProgress = (
    currentCourse: Course,
    groupProgress: ParticipantProgress[],
    members: GroupMember[]
): ConsolidatedUsersProgress[] => {
    const data = mapUserProgressChapterAndModules(currentCourse, groupProgress, members);
    /* @ts-ignore */
    var results = [];
    for (let i = 0; i < data.length; i++) {
        for (let k = 0; k < data[i].length; k++) {
            for (let l = 0; l < data[i][k].length; l++) {
                /* @ts-ignore */
                results[k] = results[k] || [];
                results[k][l] = results[k][l] || [];
                results[k][l][i] = data[i][k][l];
            }
        }
    }

    return results.flat(1).map((moduleProgress, i) => {
        let progress = false;
        /* @ts-ignore */
        if (moduleProgress.every(up => up.isCompleted === true)) {
            progress = true;
        }
        /* @ts-ignore */
        if (moduleProgress.every(up => up.isCompleted === null)) {
            /* @ts-ignore */
            progress = null;
        }

        return { isCompleted: progress };
    });
};

/**
 *
 * @param currentCourse
 * @param userProgress
 * @param members
 */
export const mapUserProgressChapterAndModules = (
    currentCourse: Course,
    userProgress: ParticipantProgress[],
    members: GroupMember[]
): ConsolidatedUsersProgress[][][] => {
    let chapterModules = [];
    for (let i = 0; i < members.length; i++) {
        let progressByUser = userProgress?.filter(user => user.userId === members[i].userId);
        chapterModules[i] =
            currentCourse &&
            currentCourse.chapters &&
            currentCourse.chapters
                /* @ts-ignore */
                .sort((a, b) => a.order - b.order)
                .map(chapter =>
                    chapter.modules
                        /* @ts-ignore */
                        .sort((a, b) => a.order - b.order)
                        .filter(m => m.status === "Active" || m.status === "Disabled")
                        .map((module, i) => {
                            // QA: Rewrite after IsCompleted is removed from from userProgress. Treat an existing progress entity as completed

                            let progress = false;
                            const moduleProgress = module.blocks
                                .filter(m => m.status === "Active")
                                .map(b => {
                                    const moduleBlockProgress = progressByUser?.find(p => p.moduleBlockId === b.id);
                                    return {
                                        //isCompleted: moduleBlockProgress && moduleBlockProgress.isCompleted ? moduleBlockProgress.isCompleted : null
                                        isCompleted: moduleBlockProgress ? true : null,
                                    };
                                });
                            if (moduleProgress.every(up => up.isCompleted === true)) {
                                progress = true;
                            }
                            if (moduleProgress.every(up => up.isCompleted === null)) {
                                /* @ts-ignore */
                                progress = null;
                            }
                            return {
                                isCompleted: progress,
                            };
                        })
                );
    }
    return chapterModules;
};

export interface GroupMembersWithProgress extends GroupMember {
    progress: ParticipantProgress[];
}

export const mapUsersWithProgress = (
    userProgress: ParticipantProgress[],
    members: GroupMember[]
): GroupMembersWithProgress[] =>
    members
        .filter(x => x.isParticipant)
        .map(member => ({
            ...member,
            progress: userProgress.filter(p => p.userId === member.userId && p.moduleBlockId > 0),
        }));
