import { useTranslate } from "@tolgee/react";
import { ModuleBlock } from "@/api-client";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../../components/BreadCrumbs";
import Sidebar from "../../components/Sidebar";
import { CourseMenu } from "../../components/Sidebar/NavBarHelper";
import { ChapterRouteParams } from "../EditChapters";
import { useCurrentCourseFromParams } from "../helper";
import Blocks from "./Blocks";
import EditModule from "./EditModule";
import { ImplementationTodosEditor } from "./ImplementationTodosEditor";
import { CardBlock } from "../../../components/CardBlock";

type ModuleRouteParams = ChapterRouteParams & {
    moduleId: string;
};

const Index = (): JSX.Element => {
    const params = useParams<ModuleRouteParams>();
    const { t } = useTranslate();

    //@ts-ignore
    const courseId = parseInt(params.courseId);
    //@ts-ignore
    const chapterId = parseInt(params.chapterId);
    //@ts-ignore
    const moduleId = parseInt(params.moduleId);
    const [course] = useCurrentCourseFromParams();
    const nav = CourseMenu(course);
    const chapterDetails = course?.chapters?.find(c => c.id === chapterId);
    const moduleDetails = chapterDetails?.modules?.find(m => m.id === moduleId);
    const links = [
        { name: t("SYSADMIN_HOME"), path: "/sysadmin" },
        { name: t("SYSADMIN_PROGRAMS_AND_CAPABILITIES"), path: "/sysadmin/courses" },
        { name: course?.name, path: `/sysadmin/courses/${course?.id}/edit` },
        { name: chapterDetails?.name, path: `/sysadmin/courses/${course?.id}/chapter/${chapterDetails?.id}/edit` },
        { name: moduleDetails?.name, path: null },
    ];

    const moduleBlocks: ModuleBlock[] =
        // @ts-ignore
        course && course.chapters.find(x => x.id === chapterId).modules.find(x => x.id === moduleId).blocks;

    return (
        <>
            {moduleDetails && (
                <Breadcrumbs
                    // @ts-ignore
                    breadCrumb={links}
                />
            )}
            <div className="row">
                <div className="col-md-4">
                    <CardBlock>
                        <Sidebar
                            children={nav}
                            name={course?.name}
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                        />
                    </CardBlock>
                </div>
                <div className="col-md-8">
                    <CardBlock mb="xl">
                        <EditModule courseId={courseId} chapterId={chapterId} moduleId={moduleId} />
                    </CardBlock>
                    <CardBlock mb="xl">
                        <Blocks
                            courseId={courseId}
                            chapterId={chapterId}
                            moduleId={moduleId}
                            moduleBlocks={moduleBlocks}
                        />
                    </CardBlock>

                    {course.courseType === "ImplementationCourse" && (
                        <ImplementationTodosEditor courseId={courseId} chapterId={chapterId} moduleId={moduleId} />
                    )}
                </div>
            </div>
        </>
    );
};
export default Index;
