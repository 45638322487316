import React from "react";
import "./styles.scss";

interface Props {
    children: JSX.Element[] | JSX.Element;
}

const SlideshowButton = (props: Props) => {
    return <div className="slideshow-bottom-button">{props.children}</div>;
};

export default SlideshowButton;
