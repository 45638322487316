import { MouseEventHandler } from "react";
import { MantineSize, DefaultProps } from "@mantine/core";
import { WithChildren } from "../../common/StateWrapper";
import { Button, EventName } from "./Button";

type SecondaryButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    fullWidth?: boolean;
    danger?: boolean;
    size?: MantineSize;
    eventName?: EventName;
} & WithChildren &
    DefaultProps;

export const SecondaryButton = (props: SecondaryButtonProps) => (
    <Button
        size={props.size}
        loading={props.loading}
        disabled={props.disabled}
        onClick={props.onClick}
        fullWidth={props.fullWidth}
        danger={props.danger}
        variant="outline"
        {...props}
    >
        {props.children}
    </Button>
);
