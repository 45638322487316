﻿import React from "react";
import { NativeSelect } from "@mantine/core";
import { useAllCourses } from "../../common/OrganisationContext";
import { useCurrentCourse, useCurrentGroup, useCurrentGroupCourseContextDispatch } from "../../common/courses/context";
import { useCurrentUser } from "../../common/user/context";
import { setCourseGroupInLocalStorage } from "../../common/LocalStorageAdapter";

export const CourseSelectorDropdown = () => {
    const allCourses = useAllCourses();
    const group = useCurrentGroup();
    const dispatch = useCurrentGroupCourseContextDispatch();
    const user = useCurrentUser();
    const groupsCourses = allCourses?.filter(course => group?.courseIds.includes(course.id!));
    const currentCourse = useCurrentCourse();

    const setCourse = (courseId: string) => {
        const selectedCourse = allCourses?.find(c => c.id?.toString() === courseId);
        dispatch(state => ({ ...state, course: selectedCourse }));

        if (user === undefined) {
            return;
        }

        if (!group?.members.map(m => m.userId).includes(user.id!)) {
            return;
        }

        if (selectedCourse !== undefined && group !== undefined) {
            setCourseGroupInLocalStorage(selectedCourse.id!, group.id!);
        }
    };

    const courses = groupsCourses?.alphabeticSort(c => c.name);

    return (
        <NativeSelect
            data={courses?.map(x => ({ label: x.name, value: x.id!.toString() })) ?? []}
            onChange={e => setCourse(e.target.value)}
            value={currentCourse?.id?.toString()}
        />
    );
};
