import { Question } from "@/api-client";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts";
import { useOrganisationContext } from "../../../../common/OrganisationContext";
import RenderIf from "../../../../components/render-if/render-if";
import { AppLoader } from "../../../../components/Spinner";
import stringToColor from "../../../../lib/StringToColor";
import {
    groupAnswersByDemographicData,
    groupAnswersByGroup,
    groupAnswersByQuestionOption,
} from "../../data/aggregation";
import { AnswerGroup } from "../../data/AnswerGroup";
import { usePresentationContext } from "../../PresentationContext";
import { QuestionTitle } from "../../QuestionTitle";
import "./styles.scss";
import { Group } from "@/api-client";

/*  eslint-disable react-hooks/exhaustive-deps */

interface DistributionValue {
    x: number;
    y: number;
}

interface AverageValue {
    label: string;
    color: string;
    x: number;
    y: number;
    z: number;
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const getDistributionData = (question: Question, answerGroup: AnswerGroup): DistributionValue[] => {
    var distribution = _.chain(answerGroup.answers)
        .groupBy(x => x.text)
        .map<DistributionValue>((value, key) => ({
            x: parseInt(key),
            y: value.length,
        }))
        .value();

    // @ts-ignore
    const scale = range(question.minAnswer, question.maxAnswer, 1);

    return scale.map(x => ({
        x: x,
        y: distribution.find(d => d.x === x)?.y ?? 0,
    }));
};

const getAverageData = (question: Question, answerGroups: AnswerGroup[], allGroups: Group[]): AverageValue[] => {
    return answerGroups.map(group => {
        let groupGroup = allGroups.find(x => x.id === group?.answers[0]?.groupId);

        return {
            label: group.name,
            // @ts-ignore
            color: groupGroup.color,
            // @ts-ignore
            x: _.meanBy(group.answers, x => parseInt(x.text)),
            y: 0,
            z: 100,
        };
    });
};

// @ts-ignore
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.label} : ${payload[0].payload.x.toFixed(2)}`}</p>
            </div>
        );
    }

    return null;
};

interface MatrixScaleBlockProps {
    optionGroup: AnswerGroup;
    question: Question;
}

const MatrixScaleBlock = ({ optionGroup, question }: MatrixScaleBlockProps) => {
    const [distributionData, setDistributionData] = useState<DistributionValue[]>();
    const [averageData, setAverageData] = useState<AverageValue[]>();

    const { filters } = usePresentationContext();
    const { users, demographicQuestions, demographicData, groups } = useOrganisationContext();

    useEffect(() => {
        if (!(optionGroup && optionGroup?.answers && groups && demographicQuestions && demographicData)) {
            return;
        }

        // Distribution is calculated for all together
        setDistributionData(getDistributionData(question, optionGroup));

        if (filters.groupBy === "DemographicData") {
            var demographicQuestion = demographicQuestions.find(x => x.id === filters.groupById);

            // @ts-ignore
            groupAnswersByDemographicData(optionGroup.answers, users, demographicQuestion, demographicData).then(
                data => {
                    setAverageData(getAverageData(question, data, groups));
                }
            );
        } else {
            // Default to Groups

            groupAnswersByGroup(optionGroup.answers, groups).then(data => {
                setAverageData(getAverageData(question, data, groups));
            });
        }
    }, [optionGroup, optionGroup?.answers, groups, demographicQuestions, demographicData]);

    // @ts-ignore
    const distributionDataYMax = distributionData ? _.maxBy(distributionData, x => x.y).y : 0;

    return (
        <div className="scale-graph p-5">
            <h3 className="mb-2">{optionGroup.name}</h3>

            <div className="chart-wrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={distributionData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 0,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis
                            type="number"
                            dataKey="x"
                            style={{ marginBottom: "1rem" }}
                            name={question.description}
                            unit=""
                            interval={0}
                            // @ts-ignore
                            tickCount={question.maxAnswer - question.minAnswer + 1}
                            // @ts-ignore
                            domain={[question.minAnswer, question.maxAnswer]}
                        >
                            <Label value={question.minAnswerLabel} offset={-10} position="insideBottomLeft" />
                            <Label value={question.maxAnswerLabel} offset={-10} position="insideBottomRight" />
                        </XAxis>
                        {/*
                         Note: Width on YAxis adjust left margin for some reason; https://github.com/recharts/recharts/issues/843
                         */}
                        <YAxis
                            type="number"
                            dataKey="y"
                            width={10}
                            unit=""
                            tick={false}
                            axisLine={false}
                            tickCount={10}
                            domain={[0, distributionDataYMax + 1]}
                        />
                        <ZAxis type="number" dataKey="z" range={[60, 400]} />

                        <Tooltip
                            cursor={{ strokeDasharray: "3 3" }}
                            content={<CustomTooltip active={undefined} payload={undefined} />}
                        />
                        <Legend wrapperStyle={{ position: "relative" }} />
                        <Area
                            type="monotone"
                            dataKey="y"
                            name=" "
                            fill={stringToColor(optionGroup.name)}
                            stroke={stringToColor(optionGroup.name)}
                        />
                        {averageData && (
                            <>
                                {averageData.map(entry => (
                                    <Scatter
                                        key={`scatter_${entry.label}`}
                                        name={entry.label}
                                        data={[entry]}
                                        fill={entry.color}
                                    />
                                ))}
                            </>
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const MatrixScaleQuestionSlide = () => {
    const { t } = useTranslate();

    const { question, module, answers, filters } = usePresentationContext();
    const [optionGroups, setOptionGroups] = useState<AnswerGroup[]>();

    useEffect(() => {
        if (!(answers && question)) {
            return;
        }

        groupAnswersByQuestionOption(question, answers).then(data => {
            setOptionGroups(data);
        });
    }, [filters?.groupBy, filters.groupById, answers, question]);

    const containsAnswers: boolean = optionGroups !== undefined && optionGroups?.length > 0;

    return (
        <div className="matrix-scale">
            <QuestionTitle
                // @ts-ignore
                text={question.text}
                // @ts-ignore
                moduleName={module?.name}
            />

            {!containsAnswers && optionGroups !== undefined && (
                <div className="alert alert-info">{t("WORKSHOP_PRESENTATION_NOANSWER")}</div>
            )}

            <AppLoader loading={optionGroups === undefined} />

            <RenderIf show={containsAnswers}>
                <div className="bg-even-odd">
                    {optionGroups &&
                        optionGroups.map(optionGroup => (
                            <MatrixScaleBlock
                                optionGroup={optionGroup}
                                // @ts-ignore
                                question={question}
                                key={`gridblock_${optionGroup.id}`}
                            />
                        ))}
                </div>

                <div>
                    {answers && (
                        <p className="mt-2 text-center">
                            {t("WORKSHOP_MATRIX_NUMBEROFANSWER", {
                                numberOfAnswers: _.uniq(answers.map(x => x.userId)).length,
                            })}
                        </p>
                    )}
                </div>
            </RenderIf>
        </div>
    );
};

export default MatrixScaleQuestionSlide;
