import { useField, useFormikContext } from "formik";
import { useTranslate } from "@tolgee/react";
import { useState } from "react";
import { Grid, Stack, Image, Input } from "@mantine/core";
import FileUploader from "../../account-owner/Components/FileUploader";
import { SecondaryButton } from "@/components";
import { createApi } from "@/common";

type ProfileImageInputProps = {
    label: string;
    fieldName: string;
    imageProps?: ProfileImageProps["imageProps"];
};

type ProfileImageProps = {
    image: string | undefined;
    handleFile: (uploadFile: File) => Promise<void>;
    onDelete: () => void;
    deleteLabel: string;
    isLoading: boolean;
    imageProps?: {
        width?: number;
        height?: number;
    };
};

const ImageDisplay = (props: ProfileImageProps) => (
    <Grid>
        <Grid.Col span="content">
            <Image
                radius="sm"
                height={props.imageProps?.height}
                width={props.imageProps?.width}
                src={props.image}
                alt="Profile image"
                withPlaceholder
            />
        </Grid.Col>
        <Grid.Col span="content">
            <Stack>
                <FileUploader
                    loading={props.isLoading}
                    acceptedFiles="image/jpg, image/png, image/jpeg"
                    handleFile={props.handleFile}
                />

                {props.image !== null && (
                    <SecondaryButton loading={props.isLoading} onClick={props.onDelete}>
                        {props.deleteLabel}
                    </SecondaryButton>
                )}
            </Stack>
        </Grid.Col>
    </Grid>
);

export const ImageInput = (props: ProfileImageInputProps) => {
    const formik = useFormikContext();
    const [field, meta] = useField<string | undefined>(props.fieldName);
    const { t } = useTranslate();

    const [isSavingImage, setIsSavingImage] = useState<boolean>(false);

    const fileUploader = (uploadFile: File) => {
        const api = createApi();

        setIsSavingImage(true);
        formik.setSubmitting(true);

        return api.images.post({ fileName: uploadFile.name, data: uploadFile }).then(
            result => {
                formik.setFieldTouched(props.fieldName, true);
                formik.setFieldValue(props.fieldName, result);
                setIsSavingImage(false);
                formik.setSubmitting(false);
            },
            () => {
                formik.setSubmitting(false);
                setIsSavingImage(false);
            }
        );
    };

    return (
        <Input.Wrapper
            label={props.label ? t(props.label) : undefined}
            error={meta.error && meta.touched ? t(meta.error) : undefined}
        >
            <ImageDisplay
                isLoading={isSavingImage}
                image={field.value}
                handleFile={uploadFile => fileUploader(uploadFile)}
                onDelete={() => {
                    formik.setFieldTouched(props.fieldName, true);
                    formik.setFieldValue(props.fieldName, undefined);
                }}
                deleteLabel={t("COMMON_DELETE")}
                imageProps={props.imageProps}
            />
        </Input.Wrapper>
    );
};
