﻿// eslint-disable-next-line import/no-cycle
import { BlockDto } from "@/api-client";

export class BlockDb {
    static save = (moduleId: string, block: BlockDto) => {
        const blocks: BlockDto[] = this.get(moduleId);
        blocks.push(block);

        localStorage.setItem(moduleId, JSON.stringify(blocks));
    };

    static get = (moduleId: string): BlockDto[] => {
        const currentItems = localStorage.getItem(moduleId);

        if (currentItems) {
            return JSON.parse(currentItems) as BlockDto[];
        }

        return [];
    };
}
