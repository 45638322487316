import React from "react";

const AltBlocks = (props: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
    return (
        <div {...props} className={`alt-blocks ${props.className}`}>
            {props.children}
        </div>
    );
};

export default AltBlocks;
